import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { DropResult } from 'react-beautiful-dnd';
import {
  IReading,
  IReadingEdit,
  IReadingExercise,
  IReadingExerciseId,
  IReadingId,
  IReadingText,
  IReadingTextId,
} from '../types/reading';
import * as lodash from 'lodash';
import store from '../store/store';
import { cacheDelete } from '../store/actions/cache';
import { getCacheKey } from '../helpers/cache';

export default class ReadingModel extends BaseModel<IReading, IReadingEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.reading;
    this.gridName = 'Readings';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<IReading[]>(`courses/${this.courseId}/readings`, data);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IReading>(`courses/${this.courseId}/readings/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const item = await get<IReadingEdit>(`/readings/new`);
    item.exercises = [];
    item.texts = [];
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IReadingEdit;
    const itemId = itemToSave.id;

    await put<IReading>(`/readings/${itemId}`, { ...itemToSave });

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IReadingEdit;
    const result = await post<IReadingId>(
      `/courses/${this.courseId}/readings`,
      lodash.omit(itemToCreate, ['targets', 'texts']),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`readings/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async getExercises(itemId: number) {
    const exercises = await get<IReadingExercise[]>(`readings/${itemId}/exercises`);
    return exercises.sort((a, b) => a.position - b.position);
  }

  async getTexts(itemId: number) {
    const texts = await get<IReadingText[]>(`readings/${itemId}/texts`);
    return texts.sort((a, b) => a.position - b.position);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, exercises, texts] = await Promise.all([
      get<IReadingEdit>(`readings/${itemId}`),
      this.getExercises(itemId),
      this.getTexts(itemId),
    ]);

    item.exercises = exercises;
    item.texts = texts.sort((a, b) => a.position - b.position);
    await this.setItem(item);
    this.render();
  }

  async forceReloadReadingTexts() {
    const texts = await this.getTexts(this.itemId);
    await this.setItem({ ...this.itemUpdated, texts });
    this.render();
    this.invalidateCache();
  }

  async forceReloadReadingExercises() {
    const exercises = await this.getExercises(this.itemId);
    await this.setItem({ ...this.itemUpdated, exercises });
    this.render();
    await this.updateGridRow(this.itemId);
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  public async newExercise() {
    this.loadingItem = true;
    this.render();
    const readingItem = this.itemUpdated as IReadingEdit;
    await post<IReadingExerciseId>(`readings/${readingItem.id}/exercises/new`, {});
    await this.loadItemEdit(readingItem.id);
    this.loadingItem = false;
    this.render();
  }

  public async newText() {
    this.loadingItem = true;
    this.render();
    const readingItem = this.itemUpdated as IReadingEdit;
    await post<IReadingTextId>(`readings/${readingItem.id}/texts/new`, {});
    await this.loadItemEdit(readingItem.id);
    this.loadingItem = false;
    this.render();
    this.invalidateCache();
  }

  public async onDragEndExercise(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const exerciseId = parseInt(draggableId);
    await this.moveExercise(exerciseId, source.index, destination.index);
  }

  public async onDragEndText(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const textId = parseInt(draggableId);
    await this.moveText(textId, source.index, destination.index);
  }

  private async moveExercise(exerciseId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as IReadingEdit;
    this.arrayMove(item.exercises, prevPos, newPos);
    this.render();
    await put(`readings/exercises/${exerciseId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
  }

  private async moveText(textId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as IReadingEdit;
    this.arrayMove(item.texts, prevPos, newPos);
    this.render();
    await put(`readings/texts/${textId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
    await this.forceReloadReadingTexts();
  }

  private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.readingText, this.itemUpdated.id)));
  }

  async checkReadings() {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/readings/check`);
    this.gridData = await get<IReading[]>(`courses/${this.courseId}/readings/with-error`);
    this.loadingGrid = false;
    this.render();
  }
}
