import React, { useContext } from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { Button } from '@material-ui/core';
import CATModel from '../../../models/CATModel';

type IProps = {
  onClose: () => void;
};

const CATValidateAll: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as CATModel;
  const handleValidateAll = () => {
    module.validateAll();
    props.onClose();
  };

  const handleCheckFormatAll = () => {
    module.checkFormatAll();
    props.onClose();
  };

  return (
    <ModalWrapper onClose={props.onClose} title={`CAT`} width={300} height={100}>
      <Button variant="contained" size="small" color="primary" onClick={handleValidateAll}>
        Validate All
      </Button>
      <br />
      <Button variant="contained" size="small" color="primary" onClick={handleCheckFormatAll}>
        Check Format All
      </Button>
    </ModalWrapper>
  );
};

export default CATValidateAll;
