import { get } from 'services/request';
import { ITtsVoiceSelect } from '../types/ttsLab';

export default class TtsVoiceSelectModel {
  async get() {
    const voices = await get<ITtsVoiceSelect[]>(`ttsLab/voices`);
    return voices.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (b.name > a.name) {
        return 1;
      }
      return 0;
    });
  }
}
