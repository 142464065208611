import ModuleContext from 'CMFW/context/ModuleContext';
import PanelCard from 'components/common/PanelCard';
import React, { useContext } from 'react';

const PanelUses: React.FC = () => {
  const module = useContext(ModuleContext);
  const panelIds = module.itemUpdated.panels as number[];

  if (panelIds.length === 0) {
    return null;
  }
  return (
    <>
      <p>Used in:</p>
      {panelIds.map((id, index) => (
        <PanelCard key={id + '.' + index} id={id} />
      ))}
    </>
  );
};

export default PanelUses;
