import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { WrapperChangedTransparent } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';
import styled from 'styled-components';
import BlockSelect from '../../../components/selects/BlockSelect';

type IProps = {
  blockId: number;
  isNote: boolean;
  onClose: () => void;
  onEditItem: (blockId: number, isNote: boolean) => void;
};

const BlockEditModal: React.FC<IProps> = (props) => {
  const [isNote, setIsNote] = useState(props.isNote);
  const [block, setBlock] = useState(props.blockId);

  const handleClose = () => {
    props.onClose();
  };

  const handleUpdate = () => {
    props.onEditItem(block, isNote);
    props.onClose();
  };

  const handleSelectChange = (blockId: number) => {
    setBlock(blockId);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNote(event.target.checked);
  };

  return (
    <ModalWrapper width={238} height={220} onClose={handleClose} title="Edit Block">
      <WrapperChangedTransparent $changed={props.blockId !== block}>
        <BlockSelect blockId={block} onChange={handleSelectChange} />
      </WrapperChangedTransparent>
      <WrapperChangedTransparent $changed={props.isNote !== isNote}>
        <FormControlLabelStyled
          control={<Checkbox checked={isNote} onChange={handleCheckboxChange} name="isNote" />}
          label="Note"
        />
      </WrapperChangedTransparent>
      <Button variant="contained" size="small" color="primary" onClick={handleUpdate}>
        Update
      </Button>
    </ModalWrapper>
  );
};

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-bottom: 10px;
  & .MuiCheckbox-colorSecondary.Mui-checked {
    color: gray;
  }
`;

export default BlockEditModal;
