import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorRow, ModuleContainer, TextFieldStyled } from 'components/common/styles';
import DialectSelect, { IDialectOption } from 'components/selects/DialectSelect';
import { useForceRender } from 'hooks/useForceRender';
import ConceptLinkerCheckerModel, { ICheckerRef } from 'models/ConceptLinkerCheckerModel';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ConceptLinkerCheckerRef from '../components/ConceptLinkerCheckerRef';

export type IProps = {
  courseId: number;
};

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

const ConceptLinkerChecker: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new ConceptLinkerCheckerModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const [startPos, setStartPos] = useState(DEFAULT_START_POS);
  const [startPosLoad, setStartPosLoad] = useState(DEFAULT_START_POS);
  const [endPos, setEndPos] = useState(DEFAULT_END_POS);
  const [endPosLoad, setEndPosLoad] = useState(DEFAULT_END_POS);
  const [targetDialectId, setTargetDialectId] = useState(null as number | null);
  const [targetDialectLoadId, setTargetDialectLoadId] = useState(null as number | null);

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue < DEFAULT_START_POS) {
      newValue = DEFAULT_START_POS;
    }
    setStartPos(newValue);
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue > DEFAULT_END_POS) {
      newValue = DEFAULT_END_POS;
    }
    setEndPos(newValue);
  };

  const handleTargetDialectChange = (dialect: IDialectOption) => {
    setTargetDialectId(dialect.dialectId);
  };

  const handleLoadClick = () => {
    setStartPosLoad(startPos);
    setEndPosLoad(endPos);
    setTargetDialectLoadId(targetDialectId);
    moduleModel.loadItemEdit();
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerRight>
          <EditorRowStyled>
            <TextFieldPos
              value={startPos}
              variant="outlined"
              label={'Start'}
              type={'number'}
              onChange={handleChangeStarPos}
            />
            <TextFieldPos
              value={endPos}
              variant="outlined"
              label={'End'}
              type={'number'}
              onChange={handleChangeEndPos}
            />
            <DialectSelect onlyTarget handleDialectChange={handleTargetDialectChange} />
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Checkers>
            {targetDialectLoadId &&
              moduleModel
                .getItemUpdatedValue('', [])
                .map((checker: ICheckerRef) => (
                  <ConceptLinkerCheckerRef
                    key={checker.ref}
                    courseId={props.courseId}
                    refId={checker.ref}
                    targetDialectId={targetDialectLoadId}
                    name={checker.name}
                    batchSize={checker.batchSize}
                    startLesson={startPosLoad}
                    endLesson={endPosLoad}
                  />
                ))}
          </Checkers>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const Checkers = styled.div`
  padding: 10px;
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 66px;
  && {
    margin-right: 10px;
  }
`;

export default ConceptLinkerChecker;
