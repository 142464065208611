import { del, postMultiFile } from 'services/request';
import { IIllustration } from '../types/illustration';

type IIllustrationUpload = {
  status: number;
  error: string;
};

export default class IllustrationModel {
  async uploadFile(data: IIllustration, fileSVG: File, filePNG: File) {
    const formData = [];
    formData.push({
      fileName: 'svg',
      file: fileSVG,
    });
    formData.push({
      fileName: 'png',
      file: filePNG,
    });

    const response = await postMultiFile<IIllustrationUpload>(`/svg/${data.ref}/${data.id}`, formData);
    return response.data;
  }

  async delete(data: IIllustration) {
    await del(`/svg/${data.ref}/${data.id}`);
  }
}
