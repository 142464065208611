import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerRight, EditorRow, FormContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import Grid from '../../../CMFW/grid/Grid';
import AIReviewDashboardModel from '../../../models/AIReviewDashboardModel';
import { progressRenderer } from '../../../CMFW/grid/cellRenderers/progressRenderer';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers/rootReducer';

export type IProps = {
  courseId: number;
};

const TranslationDashboard: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new AIReviewDashboardModel({ render: forceRender, courseId }), [
    forceRender,
    courseId,
  ]);

  const sourceDialects = useSelector((state: IAppState) =>
    state.courseConfig[moduleModel.courseId].sourceDialects.map((d) => d.dialectCode),
  );
  const targetDialects = useSelector((state: IAppState) =>
    state.courseConfig[moduleModel.courseId].targetDialects.map((d) => d.dialectCode),
  );

  const dialects = [...new Set(['ALL', ...targetDialects, ...sourceDialects])];
  console.log(dialects);
  let cellRenderers = {};
  dialects.forEach((d) => {
    cellRenderers = {
      ...cellRenderers,
      [d]: progressRenderer,
    };
  });

  console.log(cellRenderers);

  const handleLoadClick = async () => {
    await moduleModel.getDashboard();
  };

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <FormContainerStyled>
          <EditorRowStyled>
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Grid defaultColumnWidth={120} cellRenderers={cellRenderers} />
        </FormContainerStyled>
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default TranslationDashboard;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const FormContainerStyled = styled(FormContainer)`
  max-width: initial;
`;
