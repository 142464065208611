import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ListDialects } from 'CMFW/layout/ListDialects';
import { LoadingItem } from 'CMFW/layout/Loading';
import { Uses } from 'CMFW/layout/Uses';
import Id from 'components/common/Id';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerLeftHeader,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import VocabularyModel from 'models/VocabularyModel';
import Note from 'modules/Note/Note';
import LevelSelect from 'modules/vocabulary/LevelSelect';
import VocSearchBar from 'modules/vocabulary/VocSearchBar';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Dialect from './Dialect';
import ListIds from '../../components/common/ListIds';
import { IRefIds } from '../../constants/refs';
import VocTree from './components/tree/VocTree';
import VocCategories from './components/VocCategories';
import { IFields } from '../../CMFW/grid/fields';
import { illustrationRenderer } from '../../CMFW/grid/cellRenderers/illustration';
import { illustrationAiScoreRenderer } from '../../CMFW/grid/cellRenderers/illustrationAiScore';
import Illustration from '../compounds/Illustration';
import { ConceptUse } from '../../CMFW/layout/Use';

export type IProps = {
  courseId: number;
  search?: string;
};

const Vocabulary: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(
    () => new VocabularyModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  const [dialect, setDialect] = useState('');

  const handleDialectChange = (dialect: string) => {
    setDialect(dialect);
  };

  const isVocMain = model.courseId !== 0;

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <VocSearchBar handleDialectChange={handleDialectChange} />
            </ContainerLeftHeader>
            <Grid
              showColumns={[dialect]}
              cellRenderers={{
                [IFields.illustration]: illustrationRenderer,
                [IFields.illustrationAiScore]: illustrationAiScoreRenderer,
              }}
            />
            <ContainerLeftFooter>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={['Edit', 'Uses']}>
                <Container>
                  <Container2 style={{ overflow: 'auto' }}>
                    <EditorRow>
                      <Id />
                      <EditorGap />
                      <SaveButton />
                    </EditorRow>
                    <EditorRow>
                      <TextInput keyName={'title'} label={'title'} />
                    </EditorRow>
                    <EditorRowStyled>
                      <WordClassSelectStyled isLinked />
                      <LevelSelect />
                    </EditorRowStyled>
                    {props.courseId !== 0 && (
                      <>
                        <EditorGap />
                        <ListIds keyName={'compounds'} refId={IRefIds.compounds} label={'Compounds'} />
                      </>
                    )}
                    <EditorRow>
                      <VocCategories />
                    </EditorRow>
                    <EditorRowTop>
                      <Column1>
                        <Illustration />
                      </Column1>
                      <Column2>
                        <VocTree />
                      </Column2>
                    </EditorRowTop>
                    <ListDialects keyName={'dialects'}>
                      <Dialect />
                    </ListDialects>
                  </Container2>
                  <DeleteButton />
                </Container>
                {isVocMain && <Uses />}
                {!isVocMain && <ConceptUse dialectId={0} />}
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

const Column1 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const EditorRowTop = styled(EditorRow)`
  align-items: flex-start;
`;

const WordClassSelectStyled = styled(WordClassSelect)`
  && {
    margin-right: 0;
    flex-shrink: 0;
  }
`;

export default Vocabulary;
