import React, { useContext } from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementChart } from '../../../../types/panelEstructure';
import PanelEstructureContext from '../context/PanelEstructureContext';

export type IProps = {
  data: IPanelEstructureElementChart;
};

const PanelBlockElementChart: React.FC<IProps> = ({ data }) => {
  const panelEstructureContext = useContext(PanelEstructureContext);

  const getSource = (): string => {
    const source = data.sources.find((d) => d.dialectId === panelEstructureContext.sourceDialectId);
    if (source) {
      return source.html;
    } else {
      return '';
    }
  };

  const html = getSource();

  return <Wrapper dangerouslySetInnerHTML={{ __html: html }} />;
};

export default PanelBlockElementChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
