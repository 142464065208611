import { get } from 'services/request';
import { IUser } from '../types/users';

export type IUserSelect = {
  id: number;
  name: string;
};

export default class UsersModel {
  async get() {
    const users: IUserSelect[] = (await get<IUser[]>(`users`)).map((user) => ({
      id: user.id,
      name: user.name,
    }));
    return users.sort((a, b) => a.name.localeCompare(b.name));
  }
}
