import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IGridData } from '../types/models';

export type IAudioSetsItem = IGridData & {
  text: string;
  note: string;
  status: number;
  audioPath: string;
};

export type IAudioSetsItemReviewResult = {
  allReviewed: boolean;
  newSetId: number;
  newSetName: string;
};

type IAudioSetsItemEdit = {};

export default class AudioSetsItemModel extends BaseModel<IAudioSetsItem, IAudioSetsItemEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.audio;
    this.gridName = 'AudioSetItem';
  }

  async getSet(setId: number) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    this.gridData = await get<IAudioSetsItem[]>(`/audio-sets/${setId}/items`);

    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IAudioSetsItem>(`/audio-sets/items/${itemId}`);
  }
}
