import { createActionNoPayload } from 'store/helpers';

export enum IUserActionTypes {
  LOGGED = 'USER: LOGGED',
  NOT_LOGGED = 'USER: NOT LOGGED',
}

type IUserActionPayloads = {};

export const userLogged = createActionNoPayload<IUserActionTypes.LOGGED>(IUserActionTypes.LOGGED);
export const userNotLogged = createActionNoPayload<IUserActionTypes.NOT_LOGGED>(IUserActionTypes.NOT_LOGGED);

export type IUserActions = ReturnType<typeof userLogged> | ReturnType<typeof userNotLogged>;
