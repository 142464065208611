import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import NoteContext from '../../models/NoteContext';

const NoteNew: React.FC = () => {
  const [note, setNote] = useState('');
  const noteModel = useContext(NoteContext);
  const saveNote = async () => {
    await noteModel.saveNote(note);
    setNote('');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  return (
    <Container>
      <TextareaAutosizeStyled rowsMin={4} onChange={onChange} value={note} />
      <ButtonStyled variant="contained" size="small" onClick={saveNote} disabled={note === ''}>
        Add
      </ButtonStyled>
    </Container>
  );
};

export default NoteNew;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px orange;
  border-top-width: 5px;
  background-color: #ffeece;
  padding: 2px;
`;

const TextareaAutosizeStyled = styled(TextareaAutosize)`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 4px;
  font-size: 16px;
  padding: 4px;
`;

const ButtonStyled = styled(Button)`
  align-self: flex-end;
  && {
    background-color: white;
    padding: 0;
  }
  &&:hover {
    background-color: white;
    padding: 0;
    box-shadow: none;
  }
`;
