import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';
import { IAIReviewEdit } from '../../../types/aiReview';
import KeyContext from '../../../CMFW/context/KeyContext';
import { createKeyPath } from '../../../CMFW/context/helper/keyContext';

const KEY = 'aiReview';

const AIReviewIcon: React.FC = () => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);

  const moduleModel = useContext(ModuleContext);

  const aiReview = moduleModel.getItemValue(aggregatedKeyName) as IAIReviewEdit | undefined;

  const getRobotColor = () => {
    if (aiReview) {
      switch (aiReview.status) {
        case 1:
          return 'red';
        case 2:
          return 'green';
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  if (!aiReview) {
    return null;
  }

  return <FontAwesomeIcon icon={faRobot} size={'1x'} className={getRobotColor()} />;
};

export default AIReviewIcon;
