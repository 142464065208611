import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { ITableLiteral } from './TableLiteralModel';

export type ITableLiteralSelect = {
  id: number;
  name: string;
};

export default class TableLiteralsModel {
  ref = IRefIds.tableLiterals;
  courseId = 0;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    const Literals: ITableLiteralSelect[] = (
      await get<ITableLiteral[]>(`courses/${this.courseId}/literals`)
    ).map((literal) => ({ id: literal.id, name: literal.code }));
    return Literals.sort((a, b) => a.name.localeCompare(b.name));
  }
}
