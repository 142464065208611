import React, { useState } from 'react';
import { ListDialects } from '../../CMFW/layout/ListDialects';
import styled from 'styled-components';
import { IRefIds } from '../../constants/refs';
import { FormControlLabel, Switch } from '@material-ui/core';
import TranslationsDialect from './TranslationsDialect';

type IProps = {
  keyName: string;
  refId: IRefIds;
};

const Translations: React.FC<IProps> = ({ keyName, refId }) => {
  const [useHTMLSourceEditor, setUseHTMLSourceEditor] = useState(true);

  const handleChangeUseHTMLSourceEditor = () => {
    setUseHTMLSourceEditor(!useHTMLSourceEditor);
  };

  const hasHTMLSourceEditor = (): boolean => {
    switch (refId) {
      case IRefIds.lessonTitle:
      case IRefIds.panelTitle:
      case IRefIds.tableLiterals:
      case IRefIds.cultural:
      case IRefIds.paragraph:
      case IRefIds.trainer:
        return true;
      default:
        return false;
    }
  };

  return (
    <Container>
      {hasHTMLSourceEditor() && (
        <FormControlLabelStyled
          control={
            <Switch
              checked={useHTMLSourceEditor}
              onChange={handleChangeUseHTMLSourceEditor}
              color="secondary"
              size="small"
            />
          }
          label={'CAT mode'}
        />
      )}
      <ListDialects keyName={keyName} key={refId}>
        <TranslationsDialect refId={refId} useHTMLSourceEditor={useHTMLSourceEditor} />
      </ListDialects>
    </Container>
  );
};

export default Translations;

const Container = styled.div`
  margin-top: 20px;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    margin-left: 5px;
  }
`;
