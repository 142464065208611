import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import TreeItem from '@material-ui/lab-old/TreeItem';
import TreeView from '@material-ui/lab-old/TreeView';
import { encodeModule } from 'helpers/helpers';
import React, { memo, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'services/request';
import { courseConfigAdd } from 'store/actions/courseConfig';
import { tabsNamesLoaded, tabsOpened } from 'store/actions/tabs';
import { ICourseConfig } from 'store/reducers/courseConfig';
import styled from 'styled-components';
import { IMenuItem, IMenuItemApi } from 'types/menu';

const addItem = (items: IMenuItem[], item: IMenuItemApi): IMenuItem[] => {
  let current = items;
  for (let i = 0; i < item.path.length; i++) {
    const found = current.find((c) => c.title === item.path[i]);
    if (!found) {
      const active = i === item.path.length - 1;
      //las node, add to children
      const newNode: IMenuItem = {
        ref: active ? item.ref : 0,
        sourceDialectId: active ? item.sourceDialectId : 0,
        path: item.path,
        title: item.path[i],
        children: [],
        courseId: active ? item.courseId : 0,
      };
      current.push(newNode);
      current = newNode.children;
    } else {
      addItem(found.children, { ...item, path: item.path.slice(i + 1) });
      break;
    }
  }
  return items;
};

const formatMenuItems = (items: IMenuItemApi[]): IMenuItem[] => {
  let menuItems: IMenuItem[] = [];

  items.forEach((item) => {
    menuItems = addItem(menuItems, item);
  });

  return menuItems;
};

const moduleNames: { [key: string]: string } = {};

const getModuleNames = (items: IMenuItem[], moduleId?: string) => {
  items.forEach((item) => {
    const currentModuleId = moduleId ? moduleId + ' - ' + item.title : item.title;

    if (item.children.length === 0) {
      moduleNames[encodeModule({ ...item, refId: item.ref })] = currentModuleId;
      return;
    }

    getModuleNames(item.children, currentModuleId);
  });

  return moduleNames;
};

export const LeftMenuNav: React.FC = memo(() => {
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const [res, courseConfigs] = await Promise.all([
        get<IMenuItemApi[]>(`permissions-tree`),
        get<ICourseConfig[]>(`courses/config`),
      ]);
      setMenuItems(formatMenuItems(res));
      courseConfigs.forEach((courseConfig) => dispatch(courseConfigAdd({ [courseConfig.id]: courseConfig })));
    })();
  }, [dispatch]);

  useEffect(() => {
    const moduleNames = getModuleNames(menuItems);
    dispatch(tabsNamesLoaded(moduleNames));
  }, [dispatch, menuItems]);

  const handleItemClick = (item: IMenuItem) => {
    dispatch(
      tabsOpened({
        refId: item.ref,
        courseId: item.courseId,
      }),
    );
  };

  const renderTreeNode = (items: IMenuItem[], moduleId?: string): ReactNode =>
    items.map((item) => {
      const currentModuleId = moduleId ? moduleId + ' - ' + item.title : item.title;

      if (item.children.length === 0) {
        return (
          <TreeItem
            key={currentModuleId}
            nodeId={currentModuleId}
            label={<MenuLabel>{item.title}</MenuLabel>}
            onClick={() => handleItemClick(item)}
          />
        );
      }

      const sortedItemsFirstFolders = item.children.sort((a, b) => {
        const sameType = b.children.length === a.children.length || (b.children.length > 0 && a.children.length > 0);
        if (sameType) {
          return a.title < b.title ? -1 : 1;
        }
        return b.children.length - a.children.length;
      });

      return (
        <TreeItemStyled key={currentModuleId} nodeId={currentModuleId} label={<MenuLabel>{item.title}</MenuLabel>}>
          {renderTreeNode(sortedItemsFirstFolders, currentModuleId)}
        </TreeItemStyled>
      );
    });

  const sortedItemsFirstModules = menuItems.sort((a, b) => {
    const sameType = b.children.length === a.children.length || (b.children.length > 0 && a.children.length > 0);
    if (sameType) {
      return a.title < b.title ? -1 : 1;
    }
    return a.children.length - b.children.length;
  });

  const treeNode = renderTreeNode(sortedItemsFirstModules);

  return menuItems.length ? (
    <Wrapper>
      <TreeView
        defaultCollapseIcon={<MenuIcon icon={faFolderOpen} />}
        defaultExpandIcon={<MenuIcon icon={faFolder} />}
        defaultEndIcon={<MenuIcon icon={faFile} className="fas" />}
      >
        {treeNode}
      </TreeView>
    </Wrapper>
  ) : (
    <LinearProgress />
  );
});

const TreeItemStyled = styled(TreeItem)`
  && {
    .MuiTreeItem-content {
      background: none !important;
    }
  }
`;

const MenuIcon = styled(FontAwesomeIcon)`
  padding-right: 10px;
  width: 30px;
  color: white;
  opacity: 0.8;
`;

const MenuLabel = styled.span`
  color: white;
  opacity: 0.8;
`;

const Wrapper = styled.div`
  margin-left: 15px;
`;
