import { IllustrationAiScore } from '../../../models/VocabularyModel';

export function illustrationAiScoreRenderer(param: { value: IllustrationAiScore }) {
  const { value } = param;

  if (value.score === 0) {
    return '';
  }
  return `<span class='${value.class}'>${value.score}</span>`;
}
