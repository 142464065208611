import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import BookChapterList from '../components/BookChapterList';
import BookChapterText from './BookChapterText';
import { useForceRender } from '../../../hooks/useForceRender';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import BookModel from '../../../models/BookModel';
import BookChapterModel from '../../../models/BookChapterModel';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { LoadingItem } from '../../../CMFW/layout/Loading';

const BookChapter: React.FC = () => {
  const [bookChapterId, setBookChapterId] = useState(0);

  const forceRender = useForceRender();

  const bookModel = useContext(ModuleContext) as BookModel;

  const bookChapterModel = useMemo(() => {
    const rem = new BookChapterModel({ render: forceRender, courseId: bookModel.courseId });
    if (bookChapterId) {
      rem.loadItemEdit(bookChapterId);
      rem.bookModel = bookModel;
    }
    return rem;
  }, [forceRender, bookChapterId, bookModel]);

  const onEditChapter = (chapterId: number) => {
    setBookChapterId(chapterId);
  };

  return (
    <Container>
      <BookChapterList onEditChapter={onEditChapter} />
      <ModuleContext.Provider value={bookChapterModel}>
        <ItemLayout>
          <BookChapterText />
        </ItemLayout>
        <LoadingItem />
      </ModuleContext.Provider>
    </Container>
  );
};

export default BookChapter;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
