import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';

type IProps = {
  onClose: () => void;
  title: string;
  width?: number;
  height: number;
};

export const ModalWrapper: React.FC<IProps> = (props) => {
  return (
    <Dialog maxWidth={'xl'} fullWidth={!props.width} open={true} onClose={props.onClose}>
      <DialogTitle>
        {props.title}
        <IconWrapper onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconWrapper>
      </DialogTitle>

      <DialogContentStyled width={props.width} height={props.height}>
        {props.children}
      </DialogContentStyled>
    </Dialog>
  );
};

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-block;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 20px;
`;

const DialogContentStyled = styled(DialogContent)<{ width?: number; height: number }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
`;
