import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import InflectView from './InflectView';

type IProps = {
  onClose: () => void;
  isSource?: boolean;
};

const InflectViewModal: React.FC<IProps> = (props) => {
  return (
    <ModalWrapper onClose={props.onClose} title={'Inflect Viewer'} width={1100} height={635}>
      <InflectView isSource={props.isSource} />
    </ModalWrapper>
  );
};

export default InflectViewModal;
