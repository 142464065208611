import { IUserActions, IUserActionTypes } from 'store/actions/user';

const logged = !!localStorage.getItem('user'); // TODO

const initialState = {
  logged,
};

export type IUser = typeof initialState;

export function userReducer(state = initialState, action: IUserActions): IUser {
  switch (action.type) {
    case IUserActionTypes.LOGGED:
      return { ...state, logged: true };
    case IUserActionTypes.NOT_LOGGED:
      return { ...state, logged: false };
    default:
      return state;
  }
}
