import ModuleContext from 'CMFW/context/ModuleContext';
import { Loading } from 'components/common/Loading';
import React, { useContext } from 'react';

export const LoadingItem: React.FC = () => {
  const { loadingItem } = useContext(ModuleContext);
  if (loadingItem) {
    return <Loading />;
  } else {
    return null;
  }
};
