import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  widthLeft?: number;
  widthRight?: number;
};

export const Split: React.FC<IProps> = (props) => {
  const containerRef = React.createRef<HTMLDivElement>();
  const leftRef = React.createRef<HTMLDivElement>();
  const rightRef = React.createRef<HTMLDivElement>();

  const [isResizing, setIsResizing] = useState(false);
  const [paneWidths, setPaneWidths] = useState(null as [number, number] | null);

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeEnd = () => {
    if (isResizing) {
      setIsResizing(false);
    }
  };

  const resizePanes = useCallback(
    (options: { mouseX?: number; widthLeft?: number; widthRight?: number }) => {
      if (containerRef.current && leftRef.current && rightRef.current) {
        const availableWidth = containerRef.current.clientWidth;
        const offsetLeft = containerRef.current.getBoundingClientRect().left;

        let leftWidth = 0;
        let rightWidth = 0;

        if (options.widthLeft) {
          leftWidth = options.widthLeft;
          rightWidth = availableWidth - leftWidth;
        } else if (options.widthRight) {
          rightWidth = options.widthRight;
          leftWidth = availableWidth - rightWidth;
        } else {
          leftWidth = options.mouseX !== undefined ? options.mouseX - offsetLeft : Math.round(availableWidth * 0.5);
          rightWidth = availableWidth - leftWidth;
        }

        setPaneWidths([leftWidth, rightWidth]);
      }
    },
    [containerRef, leftRef, rightRef],
  );

  useEffect(
    () => {
      resizePanes({ widthLeft: props.widthLeft, widthRight: props.widthRight });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.widthLeft, props.widthRight /* resizePanes */],
  );

  const handleContainerMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isResizing) {
      resizePanes({ mouseX: event.clientX });
    }
  };
  const components = React.Children.toArray(props.children);
  if (components.length !== 2) {
    throw new Error('Split must have always two Children');
  }

  return (
    <Container
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseUp={handleResizeEnd}
      onMouseLeave={handleResizeEnd}
    >
      <Pane ref={leftRef} $width={paneWidths && paneWidths[0]} $isLocked={!!props.widthLeft}>
        {components[0]}
      </Pane>
      <Splitter onMouseDown={handleResizeStart} />
      <Pane ref={rightRef} $width={paneWidths && paneWidths[1]} $isLocked={!!props.widthRight}>
        {components[1]}
      </Pane>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Splitter = styled.div`
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: col-resize;

  &:hover {
    background-color: #c2e1ff;
  }

  &::after {
    content: '';
    width: 2px;
    height: 20px;
    background-color: #0e85e8;
  }
`;

const Pane = styled.div<{ $width: number | null; $isLocked: boolean }>`
  width: ${(props) => (props.$width ? props.$width + 'px' : '50%')};
  overflow: hidden;
  flex-grow: ${(props) => (props.$isLocked ? 0 : 1)};
  flex-shrink: ${(props) => (props.$isLocked ? 0 : 1)};
`;
