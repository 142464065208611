import { get } from 'services/request';

type IItem = {
  id: number;
  checked: boolean;
};

type ICheckerResult = {
  id: number;
  review: boolean;
};

type IModelOptions = {
  courseId: number;
  ref: number;
  batchSize: number;
  render: () => void;
};

export default class AITranslationCheckerRefModel {
  items: number[] = [];
  fail: number[] = [];
  done: number[] = [];
  courseId: number;
  ref: number;
  batchSize: number;
  ready = false;
  render: () => void;
  running = false;
  completed = false;

  constructor(options: IModelOptions) {
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.batchSize = options.batchSize;
    this.render = options.render;
    this.getItems();
  }

  private async getItems() {
    const elements = await get<IItem[]>(
      `translations/course/${this.courseId}/refs/${this.ref}/ai/checker-ref-elements`,
    );

    this.items = elements.map((element) => element.id);
    this.done = elements.filter((e) => e.checked).map((e) => e.id);
    this.ready = true;
    this.render();
  }

  public toggle() {
    if (this.running) {
      this.stop();
    } else {
      this.start();
    }
  }

  private start() {
    this.running = true;
    this.render();
    this.continue();
  }

  private async continue() {
    if (this.running && !this.completed) {
      const pending = this.items.filter((item) => !this.done.includes(item));
      const batchIds = pending.slice(0, this.batchSize);

      if (batchIds.length > 0) {
        const results = await get<ICheckerResult[]>(
          `translations/course/${this.courseId}/refs/${this.ref}/ai/checker-ref-elements-check?ids=${batchIds.join(
            ',',
          )}`,
        );
        results.forEach((result) => {
          this.done.push(result.id);
          if (result.review) {
            this.fail.push(result.id);
          }
        });
        this.render();
        await this.continue();
      } else {
        this.completed = true;
        this.stop();
      }
    }
  }

  private stop() {
    this.running = false;
    this.render();
  }
}
