import React, { useCallback, useContext, useMemo } from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel from '../../../models/CATModel';
import SourceAdaptationQARef from '../../sourceAdaptation/components/SourceAdaptationQARef';
import { IQAResponse } from '../../../models/QAResultsModel';

type IProps = {
  onClose: () => void;
};

const CATCheckQA: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as CATModel;

  const onResults = useCallback(
    (results: IQAResponse[]) => {
      module.updateQAStatus.bind(module)(
        results.map((r) => ({
          itemId: r.parentId,
          errors: r.errors,
        })),
      );
    },
    [module],
  );

  const itemIds = useMemo(() => module.getItemIds(), [module]);
  const contextRef = module.getContextRef();
  const sourceDialect = module.getSourceDialect();

  if (!contextRef || !sourceDialect) {
    return null;
  }

  return (
    <ModalWrapper onClose={props.onClose} title={'Check QA'} width={370} height={200}>
      <SourceAdaptationQARef
        courseId={module.courseId}
        refId={contextRef}
        sourceDialectId={sourceDialect}
        name={'Elements'}
        batchSize={1}
        startLesson={0}
        endLesson={1000}
        onGetResult={onResults}
        loadItems={itemIds}
      />
    </ModalWrapper>
  );
};

export default CATCheckQA;
