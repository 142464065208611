import { get } from 'services/request';
import { IFetchModel, ITooltipOptions } from 'types/models';

type ITooltipKline = {
  name: string;
  visible: boolean;
};

export type IConceptLinkerPanel = {
  id: number;
  nUses: number;
  title: string;
  concept: string;
  kLines: ITooltipKline[];
};

export class TooltipPanelsModel implements IFetchModel<IConceptLinkerPanel[], ITooltipOptions> {
  get(options: ITooltipOptions) {
    return get<IConceptLinkerPanel[]>(`tooltips-panel?panels=${options.ids.join(',')}`);
  }
}
