import Checkbox from 'CMFW/input/Checkbox';
import TextEditor from 'CMFW/input/TextEditor';
import { AudioPlayer } from 'CMFW/layout/AudioPlayer';
import { EditorRow } from 'components/common/styles';
import React from 'react';
import styled from 'styled-components';
import AIReviewPanel from '../AIReview/components/AIReviewPanel';
import AIReviewIcon from '../AIReview/components/AIReviewIcon';

const TargetDialect: React.FC = () => {
  return (
    <Wrapper>
      <AIReviewPanel hidable />
      <Container>
        <AIReviewIcon />
        <TextEditor
          keyName={'text'}
          clKeyName={'conceptLinkerConcepts'}
          config={{
            toolBarInline: [['Bold']],
          }}
          autoGrow
        />
        <AudioPlayerStyled />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Container = styled(EditorRow)`
  flex-grow: 1;
`;

const AudioPlayerStyled = styled(AudioPlayer)`
  && {
    margin: 0 4px;
  }
`;

export default TargetDialect;
