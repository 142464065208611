import React, { useContext } from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementSentence } from '../../../../types/panelEstructure';
import PanelEstructureContext from '../context/PanelEstructureContext';
import { ISentenceTarget } from '../../../../models/SentenceModel';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store/reducers/rootReducer';
import PanelEstructureModel from '../../../../models/PanelEstructureModel';
import { AudioPlayer } from '../../../../CMFW/layout/AudioPlayer';

export type IProps = {
  data: IPanelEstructureElementSentence;
};

const PanelBlockElementSentence: React.FC<IProps> = ({ data }) => {
  const panelEstructureModel = useContext(ModuleContext) as PanelEstructureModel;
  const courses = useSelector((state: IAppState) => state.courseConfig);
  const courseConfig = courses[panelEstructureModel.courseId];
  const panelEstructureContext = useContext(PanelEstructureContext);
  const defaultTargetId = courseConfig.targetDialectDefaultId;

  const getTargets = (): ISentenceTarget[] => {
    const targetDefault = data.targets.find((d) => d.dialectId === defaultTargetId);
    if (!targetDefault) {
      return [];
    }
    const targetsNoDefault = data.targets.filter(
      (d) => d.dialectId !== defaultTargetId && d.text !== targetDefault.text,
    );

    return [targetDefault, ...targetsNoDefault].map((t) => ({ ...t, text: t.text.replace(/<(\/)*p[^>]*>/g, '') }));
  };

  const getSource = (): string => {
    const source = data.sources.find((d) => d.dialectId === panelEstructureContext.sourceDialectId);
    if (source) {
      return source.text.replace(/\r\n|\r|\n/g, '<br>');
    } else {
      return '';
    }
  };

  const targets = getTargets();
  const source = getSource();
  return (
    <Wrapper>
      {targets.map((t) => (
        <RowTarget key={t.dialectId} default={t.dialectId === defaultTargetId}>
          <AudioPlayer audioPath={t.audioPath} />
          <Target dangerouslySetInnerHTML={{ __html: t.text }} />
        </RowTarget>
      ))}
      <Source dangerouslySetInnerHTML={{ __html: source }} />
    </Wrapper>
  );
};

export default PanelBlockElementSentence;

const Wrapper = styled.div`
  font-size: 16px;
`;
const RowTarget = styled.div<{ default: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  opacity: ${(props) => (props.default ? 1 : 0.5)};
`;
const Target = styled.p`
  margin: 2px 4px 0;
`;

const Source = styled.p`
  color: #00859b;
  padding-left: 28px;
  margin: 0;
`;
