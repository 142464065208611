import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftHeader, ModuleContainer } from 'components/common/styles';
import SearchBarDialect from 'components/search/SearchBarDialect';
import { useForceRender } from 'hooks/useForceRender';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MemoryListModel from '../../models/MemoryListModel';
import TranslationEditModal from '../../components/translation/TranslationEditModal';
import { ITranslation } from '../../types/Translation';
import { IGridData } from '../../types/models';

export type IProps = {
  search?: string;
  dialect?: string;
};

const Memory: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const [editTranslationId, setEditTranslationID] = useState<number | null>(null);

  const model = useMemo(() => new MemoryListModel({ render: forceRender }), [forceRender]);

  useEffect(() => {
    if (props.search && props.dialect) {
      setEditTranslationID(null);
      model.getGridSearch(props.search, props.dialect);
    }
  }, [props.search, props.dialect]);

  const handleCloseModal = useCallback(() => {
    setEditTranslationID(null);
  }, []);

  const handleUpdate = useCallback((translationId: number, dialects: ITranslation[]) => {
    setEditTranslationID(null);
    model.update(translationId);
  }, []);

  const handleRowClick = useCallback((row: IGridData) => {
    setEditTranslationID(row.id);
  }, []);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <ContainerLeft>
          <ContainerLeftHeader>
            <SearchBarDialect onlyTarget searchText={props.search} searchDialectId={props.dialect} />
          </ContainerLeftHeader>
          <Grid handleRowClick={handleRowClick} />
        </ContainerLeft>
      </ModuleContext.Provider>
      {editTranslationId && (
        <TranslationEditModal onClose={handleCloseModal} translationId={editTranslationId} onUpdated={handleUpdate} />
      )}
    </ModuleContainer>
  );
};

export default Memory;
