import React from 'react';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import LessonOutlineSearchExercise from './search/LessonOutlineSearchExercise';
import { IRefIds } from '../../constants/refs';
import LessonOutlineSearchVoc from './search/LessonOutlineSearchVoc';
import LessonOutlineSearchPanel from './search/LessonOutlineSearchPanel';
import LessonOutlineSearchPanelIntro from './search/LessonOutlineSearchPanelIntro';
import LessonOutlineSearchDialog from './search/LessonOutlineSearchDialog';
import LessonOutlineSearchReading from './search/LessonOutlineSearchReading';

type IProps = {
  courseId: number;
};

const LessonOutlineSearch: React.FC<IProps> = (props) => {
  const [selectedTab, setSelectedTab] = React.useState(IRefIds.vocs);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(parseInt(newValue));
  };
  return (
    <Wrapper>
      <ToggleButtonGroupStyled value={selectedTab} exclusive onChange={handleChange}>
        <ToggleButtonStyled value={IRefIds.vocs}>Voc</ToggleButtonStyled>
        <ToggleButtonStyled value={IRefIds.panelIntro}>PI</ToggleButtonStyled>
        <ToggleButtonStyled value={IRefIds.panels}>Panel</ToggleButtonStyled>
        <ToggleButtonStyled value={IRefIds.dialog}>Dia</ToggleButtonStyled>
        <ToggleButtonStyled value={IRefIds.reading}>Read</ToggleButtonStyled>
        <ToggleButtonStyled value={IRefIds.exercise}>Ex</ToggleButtonStyled>
      </ToggleButtonGroupStyled>
      <TabWrapper isActive={selectedTab === IRefIds.vocs}>
        <LessonOutlineSearchVoc courseId={props.courseId} />
      </TabWrapper>
      <TabWrapper isActive={selectedTab === IRefIds.panels}>
        <LessonOutlineSearchPanel courseId={props.courseId} />
      </TabWrapper>
      <TabWrapper isActive={selectedTab === IRefIds.reading}>
        <LessonOutlineSearchReading courseId={props.courseId} />
      </TabWrapper>
      <TabWrapper isActive={selectedTab === IRefIds.exercise}>
        <LessonOutlineSearchExercise courseId={props.courseId} />
      </TabWrapper>
      <TabWrapper isActive={selectedTab === IRefIds.panelIntro}>
        <LessonOutlineSearchPanelIntro courseId={props.courseId} />
      </TabWrapper>
      <TabWrapper isActive={selectedTab === IRefIds.dialog}>
        <LessonOutlineSearchDialog courseId={props.courseId} />
      </TabWrapper>
    </Wrapper>
  );
};

export default LessonOutlineSearch;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const TabWrapper = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  && {
    margin: 4px;
  }
`;

const ToggleButtonStyled = styled(ToggleButton)`
  && {
    padding: 2px 10px;
  }
`;
