import React from 'react';
import styled from 'styled-components';

type IProps = {
  studied: boolean;
};

const InflectStudied: React.FC<IProps> = (props) => {
  return <Wrapper studied={props.studied}>IID</Wrapper>;
};

const Wrapper = styled.div<{ studied: boolean }>`
  display: inline-block;
  margin: 0 2px;
  padding: 2px;
  font-size: 14px;
  vertical-align: top;
  height: fit-content;
  background-color: ${(props) => (props.studied ? '#45f745' : '#f78b8b')};
`;

export default InflectStudied;
