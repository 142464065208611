import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useMemo } from 'react';
import { EditorRow } from 'components/common/styles';
import Button from '@material-ui/core/Button';
import TranslationModel from '../../../models/TranslationModel';
import { IItemId } from '../../../types/content';
import SearchBarDialectRef from '../../../components/search/SearchBarDialectRef';
import { IRefIds } from '../../../constants/refs';

type IProps = {
  onClose: () => void;
  onChooseTranslation?: (translationId: number) => void;
  onNewParagraph: () => void;
};

const ParagraphSelectNewModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext);

  const translationModel = useMemo(
    () => new TranslationModel({ render: forceRender, courseId: moduleModel.courseId }),
    [forceRender, moduleModel.courseId],
  );

  const handleRowClick = (data: IItemId) => {
    props.onChooseTranslation && props.onChooseTranslation(data.id);
  };

  const handleNew = () => {
    props.onNewParagraph();
  };

  return (
    <ModalWrapper title="Search Paragraph" width={700} height={600} onClose={props.onClose}>
      <ModuleContext.Provider value={translationModel}>
        <EditorRow>
          <p>
            <b>Select a Paragraph or create a new one</b>
          </p>
        </EditorRow>
        <SearchBarDialectRef onlyRef={IRefIds.paragraph} onlySource />
        <Grid handleRowClick={handleRowClick} />
        <EditorRow>
          <Button variant="contained" size="small" color="primary" onClick={handleNew}>
            new
          </Button>
        </EditorRow>
      </ModuleContext.Provider>
    </ModalWrapper>
  );
};

export default ParagraphSelectNewModal;
