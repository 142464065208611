import React, { useState } from 'react';
import styled from 'styled-components';
import ReadingTextList from '../components/ReadingTextList';
import ReadingTextEdit from '../components/ReadingTextEdit';

const ReadingText: React.FC = () => {
  const [editText, setEditText] = useState(0);

  const onEditText = (textId: number) => {
    setEditText(textId);
  };

  return (
    <Container>
      <ReadingTextList onEditText={onEditText} />
      <ReadingTextEdit readingTextId={editText} />
    </Container>
  );
};

export default ReadingText;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
