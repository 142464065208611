import { BaseModel, IModelCourseOptions } from 'models/BaseModel';
import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export type ITranslationDashboardGrid = {
  id: number;
  ref: number;
  refName: string;
} & {
  [prop: string]: ITranslationDashboardCell;
};

export interface ITranslationDashboardCell {
  pending: number;
  doing: number;
  done: number;
  dialect: number;
}

interface ITranslationDashboard {
  dashboard: ITranslationDashboardGrid[];
}

export default class TranslationDashboardModel extends BaseModel {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.translation;
    this.gridName = 'TranslationDashboard';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getDashboard(startLesson: number, endLesson: number) {
    this.loadingGrid = true;
    this.render();
    const response = await get<ITranslationDashboard>(`/courses/${this.courseId}/translations/dashboard`, {
      startLesson,
      endLesson,
    });
    this.gridData = response.dashboard;
    this.loadingGrid = false;
    this.render();
  }

  async createProject(data: {
    startLesson: number;
    endLesson: number;
    ref: number;
    dialect: number;
    freelance: number;
  }) {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/translations/dashboard/create-project`, data);
    await this.getDashboard(data.startLesson, data.endLesson);
  }
}
