import { RowNode } from '@ag-grid-enterprise/all-modules';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftHeader, EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import CLUtilsVocModel, { ICLUtilsVoc } from '../../../models/CLUtilsVocModel';
import { VocCategorySelect } from '../../../components/selects/VocCategorySelect';
import { TextFieldStyled } from '../../../components/common/SelectInput';
import UsesModal from '../modals/UsesModal';
import { IFields } from '../../../CMFW/grid/fields';
import { underlineRenderer } from '../../../CMFW/grid/cellRenderers/underline';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers/rootReducer';

export type IProps = {
  courseId: number;
  lessonPos: number;
};

let fText = '';
const CLUtilsVoc: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const [isUsesModalOpen, setIsUsesModalOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  fText = filterText;

  const isFilterActive = () => {
    return fText !== '';
  };

  const moduleModel = useMemo(() => new CLUtilsVocModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const targetDialect = useSelector(
    (state: IAppState) => state.courseConfig[moduleModel.courseId].targetDialectDefaultId,
  );

  useEffect(() => {
    if (props.lessonPos > 0) {
      moduleModel.getGrid({ lessonPos: props.lessonPos });
    }
  }, [moduleModel, props.lessonPos]);

  const handleFilterTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterText(event.target.value);
  };

  const isRowVisible = (node: RowNode) => {
    const data: ICLUtilsVoc = node.data;
    const regExp = new RegExp(fText, 'i');
    return data.title.match(regExp) !== null || data.word.match(regExp) !== null || data.id.toString() === fText;
  };

  const handleRowClick = (row: IGridData, selectedRows: IGridData[], columnField: string | undefined) => {
    if (columnField === 'nUses' && (row as ICLUtilsVoc).nUses > 0) {
      moduleModel.setItemId(row.id);
      setIsUsesModalOpen(true);
    }
  };

  const handleUsesModalClose = () => {
    setIsUsesModalOpen(false);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerLeft>
          <ContainerLeftHeader>
            <EditorRow>
              <TextFieldStyled variant="outlined" onChange={handleFilterTextChanged} placeholder="Filter" />
            </EditorRow>
          </ContainerLeftHeader>
          <Grid
            isFilterActive={isFilterActive}
            isRowVisible={isRowVisible}
            handleRowClick={handleRowClick}
            cellRenderers={{
              [IFields.nUses]: underlineRenderer,
            }}
          />
        </ContainerLeft>
        {isUsesModalOpen && <UsesModal onClose={handleUsesModalClose} />}
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const VocCategorySelectStyled = styled(VocCategorySelect)`
  width: 100%;
`;

export default CLUtilsVoc;
