import { IRefIds } from '../constants/refs';
import { ITranslation } from './Translation';
import { ISentenceTarget } from '../models/SentenceModel';
import { IItemSource, IItemTarget } from '../models/ItemModel';
import { IVocDialect } from '../models/VocabularyModel';
import { IDialectAware } from './content';

export type IPanelEstructure = {
  blocks: IPanelEstructureBlock[];
};

export type IPanelEstructureBlock = {
  id: number;
  block: number;
  name: string;
  note: boolean;
  elements: IPanelEstructureBlockElement[];
};

export type IPanelEstructureBlockElement = {
  id: number;
  elementId: number;
  itemId: number;
  dialect: number;
  ref: IRefIds;
  data: IPanelEstructureElementData;
};

export enum IPanelEstructureCard {
  BLOCK = 'block',
  ELEMENT = 'element',
}

type IPanelEstructureElementData =
  | IPanelEstructureElementParagraph
  | IPanelEstructureElementVoc
  | IPanelEstructureElementGridVoc
  | IPanelEstructureElementSentence
  | IPanelEstructureElementItem
  | IPanelEstructureElementConj
  | IPanelEstructureElementTable
  | IPanelEstructureElementChart
  | IPanelEstructureElementGridWords
  | IPanelEstructureElementQuoteSentence
  | IPanelEstructureElementImg;

export type IPanelEstructureElementParagraph = {
  sources: ITranslation[];
  translationId: number;
};

export type IPanelEstructureElementVoc = {
  id: number;
  img: string;
  dialects: IVocDialect[];
};

export type IPanelEstructureElementGridVoc = {
  vocs: IPanelEstructureElementVoc[];
};

export type IPanelEstructureElementSentence = {
  sources: ITranslation[];
  targets: ISentenceTarget[];
};

export type IPanelEstructureElementItem = {
  sources: IItemSource[];
  targets: IItemTarget[];
};

export type IPanelEstructureElementConj = {
  tableId: number;
  ref: IRefIds;
  itemId: number;
  sources: (IDialectAware & {
    html: string;
  })[];
};

export type IPanelEstructureElementTable = {
  name: string;
  sources: (IDialectAware & {
    html: string;
  })[];
};

export type IPanelEstructureElementChart = {
  name: string;
  sources: (IDialectAware & {
    html: string;
  })[];
};

export type IPanelEstructureElementGridWords = {
  gridWords: string[];
};

export type IPanelEstructureElementQuoteSentence = {
  quoteSentence: string;
};

export type IPanelEstructureElementImg = {
  src: string;
};
