import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import styled from 'styled-components';
import SourceAdaptationQA from './tabs/SourceAdaptationQA';
import Translation from './tabs/Translation';
import TranslationDashboard from './tabs/TranslationDashboard';
import TranslationProject from './tabs/TranslationProject';
import TranslationAIChecker from '../AIReview/tabs/TranslationAIChecker';

export type IProps = {
  courseId: number;
  search?: string;
};

const SourceAdaptation: React.FC<IProps> = (props) => {
  return (
    <Container>
      <ItemTabs tabNames={['Translation', 'Dashboard', 'Project', 'QA', 'AI Review']}>
        <Translation courseId={props.courseId} search={props.search} />
        <TranslationDashboard courseId={props.courseId} />
        <TranslationProject courseId={props.courseId} />
        <SourceAdaptationQA courseId={props.courseId} />
        <TranslationAIChecker courseId={props.courseId} />
      </ItemTabs>
    </Container>
  );
};

const Container = styled(ModuleContainer)`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5px;
`;

export default SourceAdaptation;
