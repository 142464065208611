import { IRefIds } from 'constants/refs';
import { get, put } from 'services/request';
import { ITranslation, ITranslationStatus } from '../types/Translation';

export default class TranslationManagerModel {
  ref: IRefIds;
  translations: ITranslation[] = [];
  constructor(options: { ref: IRefIds }) {
    this.ref = options.ref;
  }

  async get(itemId: number, filterTargets = true): Promise<ITranslation[]> {
    this.translations = await get<ITranslation[]>(`/translations/refs/${this.ref}/items/${itemId}`);
    if (filterTargets) {
      this.translations = this.filterTargets(this.translations);
    }
    return this.translations;
  }

  async update(itemId: number, translations: ITranslation[]) {
    translations = translations.map((t) => ({
      ...t,
      text: this.cleanText(t.text),
    }));

    const translationsToUpdate = translations.filter(
      (t: ITranslation, index: number) => JSON.stringify(t) !== JSON.stringify(this.translations[index]),
    );

    await Promise.all(
      translationsToUpdate.map((t: ITranslation) =>
        put(`/translations/refs/${this.ref}/refs/${this.ref}/items/${itemId}/dialects/${t.dialectId}`, t),
      ),
    );

    await put(`/translations/refs/${this.ref}/items/${itemId}/check`, {});
  }

  async new(courseId?: number, filterTargets = true): Promise<ITranslation[]> {
    let translations: ITranslation[];
    if (courseId) {
      translations = await get<ITranslation[]>(`/courses/${courseId}/translation-source/new`);
    } else {
      translations = await get<ITranslation[]>(`translation-source/new`);
    }
    if (filterTargets) {
      translations = this.filterTargets(translations);
    }
    return translations;
  }

  cleanText(text: string): string {
    switch (this.ref) {
      case IRefIds.lessonTitle:
        text = this.stripTags(text, '<target><glossary>');
        text = this.removeNewLines(text);
        break;
    }

    return text;
  }

  stripTags(str: string, allow: string) {
    // making sure allow arg is a string containing only tags in lowercase (<a><b><c>)
    allow = (((allow || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return str.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
      return allow.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
  }

  removeNewLines(str: string): string {
    return str.replace(/(\r\n|\n|\r)/gm, '');
  }

  filterTargets(translation: ITranslation[]): ITranslation[] {
    return translation.filter((t) => t.status !== ITranslationStatus.TRANSLATION_FROM_TARGET);
  }
}
