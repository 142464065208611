import Button from '@material-ui/core/Button';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useForceRender } from 'hooks/useForceRender';
import InflectsModel from 'models/InflectsModel';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import InflectTableCellInflectConj from './table/cells/InflectTableCellInflectConj';
import InflectTables from './table/InflectTables';
import InflectTableCell from './table/cells/InflectTableCell';

type IProps = {
  isSource?: boolean;
};

const InflectView: React.FC<IProps> = ({ isSource }) => {
  const forceRender = useForceRender();

  const module = useContext(ModuleContext);
  const ref = module.getRef();
  const itemId = module.itemId;

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, 'dialectId');
  const targetDialectId = module.getItemValue.bind(module)(aggregatedKeyName) as number;

  const inflectsModel = useMemo(
    () =>
      new InflectsModel({
        render: forceRender,
        courseId: module.courseId,
        ref: ref,
        refId: itemId,
        targetDialectId: targetDialectId,
        isSource: isSource,
      }),
    [forceRender, module.courseId, ref, itemId, targetDialectId, isSource],
  );

  const handleSaveClick = () => {
    inflectsModel.save();
  };

  const handleInflectClick = () => {
    inflectsModel.autoInflect();
  };

  return (
    <ModuleContext.Provider value={inflectsModel}>
      <Scroll>
        <InflectTables
          cellRenderer={isSource ? <InflectTableCell /> : <InflectTableCellInflectConj />}
          showTrash={true}
        />
      </Scroll>
      <Footer>
        <Button variant="contained" size="small" onClick={handleInflectClick}>
          inflect
        </Button>
        <Button variant="contained" size="small" color="primary" onClick={handleSaveClick}>
          save
        </Button>
      </Footer>
    </ModuleContext.Provider>
  );
};

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
`;

export default InflectView;
