import InflectTablesModel from 'models/InflectTablesModel';
import { BaseModel } from './BaseModel';
import { IGridData } from '../types/models';
import * as lodash from 'lodash';

export type IInflectBase = {
  index: number;
  inflectId: number;
};

export default class BaseInflectsModel<IModelInflects extends IInflectBase> extends BaseModel<
  IGridData,
  IModelInflects[]
> {
  inflectTablesModel!: InflectTablesModel;
  withInitials = true;

  getTables() {
    return this.inflectTablesModel ? this.inflectTablesModel.getTables({ withInitials: this.withInitials }) : [];
  }

  getTableHeaders() {
    return this.inflectTablesModel ? this.inflectTablesModel.getTableHeaders({ withInitials: this.withInitials }) : [];
  }

  getTableCells() {
    return this.inflectTablesModel ? this.inflectTablesModel.getTableCells({ withInitials: this.withInitials }) : [];
  }

  protected createEmptyItem(inflectId: number, index = 0): IModelInflects {
    return {
      index,
      inflectId,
    } as IModelInflects;
  }

  protected fillInflects(items: IModelInflects[]) {
    const allInflects = this.getTableCells().map((cell) => cell.id);
    const loadedIds = items.map((item) => item.inflectId);
    allInflects.forEach((inflectId) => {
      if (!loadedIds.includes(inflectId)) {
        items.push(this.createEmptyItem(inflectId));
      }
    });
    return items.map((item, index) => ({ ...item, index }));
  }

  isInflectEmpty(item: IModelInflects): boolean {
    return false;
  }

  emptyInflects(inflectIds: number[]) {
    const items = this.itemUpdated.map((item: IModelInflects, index: number) =>
      inflectIds.includes(item.inflectId) ? this.createEmptyItem(item.inflectId, index) : item,
    );
    this.itemUpdated = lodash.cloneDeep(items);
    this.render();
  }
}
