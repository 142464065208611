import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useEffect, useMemo } from 'react';
import ConjugationRuleEndingsModel from '../../../models/ConjugationRuleEndingsModel';
import InflectTables from '../../../components/inflect/table/InflectTables';
import TextInput from '../../../CMFW/input/TextInput';
import styled from 'styled-components';
import ConjugationRuleModel from '../../../models/ConjugationRuleModel';

export type IProps = {
  courseId: number;
  ruleId: number;
  wordClassId: number;
};

const ConjRuleEndings: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const moduleModel = useContext(ModuleContext) as ConjugationRuleModel;
  const conjRuleEndingsModel = useMemo(
    () =>
      new ConjugationRuleEndingsModel({
        render: forceRender,
        courseId: props.courseId,
      }),
    [props.courseId, forceRender],
  );

  moduleModel.onSave = async (ruleId) => {
    conjRuleEndingsModel.setRuleId(ruleId);
    await conjRuleEndingsModel.save();
  };

  useEffect(() => {
    conjRuleEndingsModel.loadEndings(props.ruleId, props.wordClassId);
  }, [conjRuleEndingsModel, props.ruleId, props.wordClassId]);

  if (moduleModel.getItemId() <= 0) {
    return <ContainerRight />;
  }

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={conjRuleEndingsModel}>
        <ContainerRight>
          <Scroll>
            <InflectTables cellRenderer={<TextInput keyName={'ending'} />} showTrash={false} />
          </Scroll>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export default ConjRuleEndings;
