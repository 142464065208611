import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import TextInput from '../../CMFW/input/TextInput';
import { TranslationStatusSelect } from './TranslationStatusSelect';
import styled from 'styled-components';
import { IRefIds } from '../../constants/refs';
import TextEditor from '../../CMFW/input/TextEditor';
import ErrorDisplay from '../common/ErrorDisplay';
import HTMLSourceInput from '../../CMFW/input/HTMLSourceInput';
import AIReviewPanel from '../../modules/AIReview/components/AIReviewPanel';
import AIReviewIcon from '../../modules/AIReview/components/AIReviewIcon';
import { ModalWrapper } from '../ModalWrapper';
import TranslationMemory from './TranslationMemory';
import { ICATMemoryGuest } from '../../models/CATModel';
import { faSearch, faThList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from '../../CMFW/context/ModuleContext';
import TranslationModel from '../../models/TranslationModel';
import KeyContext from '../../CMFW/context/KeyContext';
import { ITranslation } from '../../types/Translation';
import { tabsOpened } from '../../store/actions/tabs';
import { useDispatch } from 'react-redux';

type IProps = {
  refId: IRefIds;
  useHTMLSourceEditor: boolean;
};

const TranslationsDialect: React.FC<IProps> = ({ useHTMLSourceEditor, refId }) => {
  const dispatch = useDispatch();
  const module = useContext(ModuleContext) as TranslationModel;

  const [showMemory, setShowMemory] = useState(false);
  const [memory, setMemory] = useState<ICATMemoryGuest[] | null>(null);

  const parentKey = useContext(KeyContext);
  const moduleItemValue = module.getItemUpdatedValue.bind(module)(parentKey) as ITranslation;

  useEffect(() => {
    if (moduleItemValue.memory) {
      setMemory(moduleItemValue.memory);
    } else {
      setMemory(null);
    }
  }, [moduleItemValue.memory]);

  const handleCloseModal = useCallback(() => {
    setShowMemory(false);
  }, []);

  const handleOpenModal = useCallback(async () => {
    setShowMemory(true);
    setMemory(null);
    await module.guestMemory(moduleItemValue.dialectId);
  }, []);

  const handleRowSelect = useCallback(async (row: ICATMemoryGuest) => {
    await module.updateFromGuestMemory(row.text, moduleItemValue.dialectId);
    setShowMemory(false);
  }, []);

  const handleSearch = useCallback((event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const value = module.getItemUpdatedValue.bind(module)(parentKey) as ITranslation;
    console.log(value);
    dispatch(
      tabsOpened({
        refId: IRefIds.memory,
        courseId: 0,
        search: value.text,
        dialect: value.dialectCode + '',
      }),
    );
  }, []);

  const hasHTMLSourceEditor = (): boolean => {
    switch (refId) {
      case IRefIds.lessonTitle:
      case IRefIds.panelTitle:
      case IRefIds.tableLiterals:
      case IRefIds.cultural:
      case IRefIds.paragraph:
      case IRefIds.trainer:
        return true;
      default:
        return false;
    }
  };

  const renderEditor = () => {
    switch (refId) {
      case IRefIds.lessonTitle:
      case IRefIds.panelTitle:
      case IRefIds.tableLiterals:
      case IRefIds.trainer:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['lang_target', 'lang_glossary']],
            }}
            autoGrow
          />
        );
      case IRefIds.cultural:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['Bold', 'Italic']],
            }}
            autoGrow
          />
        );
      case IRefIds.paragraph:
        return (
          <TextEditor
            keyName={'text'}
            config={{
              toolbar: [['Undo', 'Redo'], ['RemoveFormat']],
              toolBarInline: [['Bold', 'Italic', 'lang_target', 'lang_glossary', 'wVocInline', 'wPanelInline']],
            }}
            autoGrow
          />
        );
      default:
        return <TextInputStyled keyName={'text'} multiline />;
    }
  };

  const renderCATEditor = () => {
    return (
      <WrapperBorder>
        <HTMLSourceInput keyName={'text'} />
      </WrapperBorder>
    );
  };

  return (
    <Wrapper>
      <ErrorDisplay />
      <AIReviewPanel hidable />
      <Row>
        <TranslationStatusSelect />
        <AIReviewIcon />
        {hasHTMLSourceEditor() && useHTMLSourceEditor ? renderCATEditor() : renderEditor()}
        <MemoryIcon icon={faThList} size={'1x'} onClick={handleOpenModal} />
        <MemoryIcon icon={faSearch} size={'1x'} onClick={handleSearch} />
      </Row>
      {showMemory && (
        <ModalWrapper onClose={handleCloseModal} title={'Memory'} height={600} width={1000}>
          <Scroll>
            <TranslationMemory memory={memory} onSelectMemory={handleRowSelect} />
          </Scroll>
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default TranslationsDialect;

const TextInputStyled = styled(TextInput)`
  && {
    margin: 2px 0;
    width: 100%;
  }
  & .MuiInputBase-root {
    padding: 0 0 0 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const MemoryIcon = styled(FontAwesomeIcon)`
  && {
    cursor: pointer;
  }
  padding: 4px;
  color: #3d3d3d;
`;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
`;

const WrapperBorder = styled.div`
  width: 100%;
  border-color: #0000003b;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 4px;

  &:hover {
    border-color: #282828;
  }
`;
