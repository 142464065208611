import ModuleContext from 'CMFW/context/ModuleContext';
import { ConceptUse } from 'CMFW/layout/Use';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/reducers/rootReducer';
import { TabsDialectAware } from './TabsDialectAware';

export const Uses: React.FC = () => {
  const { courseId } = useContext(ModuleContext);

  const dialects = useSelector((state: IAppState) => state.courseConfig[courseId].targetDialects);
  const [dialect, setDialect] = useState(dialects[0].dialectId);

  const handleSelectedDialect = (event: any, dialect: number) => {
    setDialect(dialect);
  };

  return (
    <TabsDialectAware dialects={dialects} selectedDialect={dialect} handleSelectedDialect={handleSelectedDialect}>
      <ConceptUse key={dialect} dialectId={dialect} />
    </TabsDialectAware>
  );
};
