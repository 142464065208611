import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import VocabularyModel, { IVoc } from 'models/VocabularyModel';
import VocSearchBar from 'modules/vocabulary/VocSearchBar';
import React, { useContext, useMemo, useState } from 'react';
import { IGridData } from 'types/models';

type IProps = {
  onClose: () => void;
  onChooseVoc: (data: IVoc) => void;
};

const VocSearchModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext);

  const vocModel = useMemo(() => new VocabularyModel({ render: forceRender, courseId: moduleModel.courseId }), [
    forceRender,
    moduleModel.courseId,
  ]);

  const [dialect, setDialect] = useState('');

  const handleDialectChange = (dialect: string) => {
    setDialect(dialect);
  };

  const handleRowClick = (data: IGridData) => {
    props.onChooseVoc(data as IVoc);
  };

  return (
    <ModalWrapper title="Search Vocabulary" width={468} height={555} onClose={props.onClose}>
      <ModuleContext.Provider value={vocModel}>
        <VocSearchBar handleDialectChange={handleDialectChange} />
        <Grid handleRowClick={handleRowClick} showColumns={[dialect]} />
      </ModuleContext.Provider>
    </ModalWrapper>
  );
};

export default VocSearchModal;
