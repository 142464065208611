import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerLeft, EditorGapGreedyH, EditorRow } from 'components/common/styles';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Translations from '../../../components/translation/Translations';

const HeadingEdit: React.FC = () => {
  const module = useContext(ModuleContext);

  return (
    <ContainerLeft>
      <EditorRow>
        <EditorGapGreedyH />
        <SaveButton />
      </EditorRow>
      <Scroll>
        <Translations keyName={'dialects'} refId={module.ref} />
      </Scroll>
      <DeleteButton />
    </ContainerLeft>
  );
};

export default HeadingEdit;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
`;
