import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import AIContentCheckerRefModel from '../../../models/AIContentCheckerRefModel';

export type IProps = {
  courseId: number;
  refId: number;
  name: string;
  batchSize: number;
};

const ContentAICheckerRef: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const checkerRefModel = useMemo(
    () =>
      new AIContentCheckerRefModel({
        courseId: props.courseId,
        ref: props.refId,
        batchSize: props.batchSize,
        render: forceRender,
      }),
    [props.courseId, props.refId, props.batchSize, forceRender],
  );

  const isLoading = !checkerRefModel.ready;
  const failed = checkerRefModel.fail.length;
  const done = checkerRefModel.done.length;
  const total = checkerRefModel.items.length;
  const showButton = !isLoading && !checkerRefModel.completed && total > 0;
  const isEmpty = total === 0;
  const percent = (done * 100) / total;

  if (!isLoading && total === 0) {
    return null;
  }

  return (
    <Checker>
      <div>
        <span>{props.name}</span>
        {!!failed && <Failed>Fail: {failed}</Failed>}
      </div>
      <Progress variant="determinate" value={isLoading ? 0 : percent} />
      <Indicator>{isLoading ? 'Loading ...' : isEmpty ? '-' : `${done}/${total}`}</Indicator>
      {showButton && (
        <ToggleRun
          icon={checkerRefModel.running ? faPause : faPlay}
          onClick={checkerRefModel.toggle.bind(checkerRefModel)}
        />
      )}
    </Checker>
  );
};

const Checker = styled.div`
  position: relative;
  width: 300px;
  margin-bottom: 10px;
`;

const Failed = styled.span`
  float: right;
  font-size: 14px;
  margin-right: 20px;
  color: darkslategray;
`;

const Progress = styled(LinearProgress)`
  margin-top: 2px;
  && {
    height: 20px;
  }
`;

const Indicator = styled.span`
  position: absolute;
  bottom: 2px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-shadow: 0.1em 0.1em 1px #424242;
`;

const ToggleRun = styled(FontAwesomeIcon)`
  padding: 5px;
  color: darkslategray;
  position: absolute;
  right: -30px;
  top: 17px;
  cursor: pointer;
`;

export default ContentAICheckerRef;
