import { get } from '../services/request';

export type IWordClassSelect = {
  id: number;
  name: string;
  hasGender: boolean;
};

export default class WordClassSelectModel {
  courseId: number;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    return await get<IWordClassSelect[]>(`/courses/${this.courseId}/word-classes`);
  }
}
