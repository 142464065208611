import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorRow, ModuleContainer } from 'components/common/styles';
import DialectSelect, { IDialectOption } from 'components/selects/DialectSelect';
import { useForceRender } from 'hooks/useForceRender';
import { ICheckerRef } from 'models/ConceptLinkerCheckerModel';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import InflectTargetBatchModel from '../../../models/InflectTargetBatchModel';
import InflectBatchTargetRef from '../components/InflectBatchTargetRef';

export type IProps = {
  courseId: number;
};

const InflectBatchTarget: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new InflectTargetBatchModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const [targetDialectId, setTargetDialectId] = useState(null as number | null);
  const [targetDialectLoadId, setTargetDialectLoadId] = useState(null as number | null);

  const handleTargetDialectChange = (dialect: IDialectOption) => {
    setTargetDialectId(dialect.dialectId);
  };

  const handleLoadClick = () => {
    setTargetDialectLoadId(targetDialectId);
    moduleModel.loadItemEdit();
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerRight>
          <EditorRowStyled>
            <DialectSelect onlyTarget handleDialectChange={handleTargetDialectChange} />
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Checkers>
            {targetDialectLoadId &&
              moduleModel
                .getItemUpdatedValue('', [])
                .map((checker: ICheckerRef) => (
                  <InflectBatchTargetRef
                    key={checker.ref}
                    courseId={props.courseId}
                    refId={checker.ref}
                    targetDialectId={targetDialectLoadId}
                    name={checker.name}
                  />
                ))}
          </Checkers>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const Checkers = styled.div`
  padding: 10px;
`;

export default InflectBatchTarget;
