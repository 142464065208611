import {
  faArrowDown,
  faArrowUp,
  faFolder,
  faPlusSquare,
  faTimesCircle,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import ModuleContext from 'CMFW/context/ModuleContext';
import { IChangeAwareProps } from 'components/common/styles';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import VocCategoriesAddModal from '../modal/VocCategoriesAddModal';
import VocCategorySelectModel from '../../../models/VocCategorySelectModel';

const KEY = 'vocCategories';

const VocCategories: React.FC = () => {
  const moduleModel = useContext(ModuleContext);

  const modelCategories = moduleModel.getItemValue(KEY) as number[];
  const [categories, setCategories] = useState(modelCategories);
  const [categoriesDeleted, setCategoriesDeleted] = useState([] as number[]);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);

  const modelValueUpdated = moduleModel.getItemUpdatedValue(KEY) as number[];

  const cacheKey = getCacheKey(IRefIds.vocabularyCategories);
  const vocCategoryData = useMemo(() => new VocCategorySelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, vocCategoryData.get.bind(vocCategoryData));

  useEffect(() => {
    setCategories([...new Set([...modelValueUpdated, ...categoriesDeleted])]);
  }, [categoriesDeleted, modelValueUpdated]);

  useEffect(() => {
    setCategoriesDeleted([]);
  }, [modelCategories]);

  const handleAddClick = () => {
    setShowModal(true);
    setToggle(true);
  };

  const handleCloseAdd = () => {
    setShowModal(false);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleAddCategory = (catId: number) => {
    const newCategories = categories.filter((id) => id !== catId);
    newCategories.push(catId);
    moduleModel.updateItemValue.bind(moduleModel)(KEY, newCategories);
    if (categoriesDeleted.indexOf(catId) > -1) {
      setCategoriesDeleted(categoriesDeleted.filter((id) => id !== catId));
    } else {
      setCategories(newCategories);
    }
  };

  const handleRemoveCategory = (catId: number) => {
    const isRestoringDeleted = categoriesDeleted.indexOf(catId) > -1;
    if (isRestoringDeleted) {
      handleAddCategory(catId);
      return;
    }

    const newCategories = categories.filter((cat) => cat !== catId);
    moduleModel.updateItemValue.bind(moduleModel)(KEY, newCategories);
    if (modelCategories.indexOf(catId) === -1) {
      setCategories(newCategories);
    } else {
      setCategoriesDeleted([...categoriesDeleted, catId]);
    }
  };

  const getCategoryName = (catId: number): string => {
    if (cacheSelectData !== null) {
      const cat = cacheSelectData.filter((cache) => cache.id === catId);
      if (cat.length === 1) {
        return cat[0].fullPath;
      }
    }

    return catId.toString();
  };

  const getCategoryShortName = (catId: number): string => {
    if (cacheSelectData !== null) {
      const cat = cacheSelectData.filter((cache) => cache.id === catId);
      if (cat.length === 1) {
        return cat[0].name;
      }
    }

    return catId.toString();
  };

  const categoriesSorted = categories.sort();

  return (
    <Container>
      <ContainerTitle>
        <Button onClick={handleAddClick}>
          <FontAwesomeIcon icon={faPlusSquare} size={'1x'} />
        </Button>
        <Title $opacity={toggle ? 0.5 : 1}>{categories.map((id) => getCategoryShortName(id)).join(', ')}</Title>
        <Button onClick={handleToggle}>
          {toggle ? (
            <FontAwesomeIcon icon={faArrowUp} size={'1x'} />
          ) : (
            <FontAwesomeIcon icon={faArrowDown} size={'1x'} />
          )}
        </Button>
      </ContainerTitle>
      {toggle &&
        categoriesSorted.map((cat) => {
          const isNewCategory = modelCategories.indexOf(cat) === -1;
          const isDeleted = categoriesDeleted.indexOf(cat) > -1;
          return (
            <CategoryChip
              key={cat}
              icon={<FontAwesomeIcon icon={faFolder} />}
              label={getCategoryName(cat)}
              onDelete={() => handleRemoveCategory(cat)}
              $changed={isNewCategory}
              $deleted={isDeleted}
              deleteIcon={isDeleted ? <FontAwesomeIcon icon={faUndo} /> : <FontAwesomeIcon icon={faTimesCircle} />}
            />
          );
        })}
      {showModal && <VocCategoriesAddModal handleAddCategory={handleAddCategory} onClose={handleCloseAdd} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px #bfc6cb;
  padding: 10px;
`;

const Button = styled.div`
  display: inline-block;
  margin: 5px;
  cursor: pointer;
`;

const CategoryChip = styled(Chip)<IChangeAwareProps & { $deleted: boolean }>`
  && {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    background-color: ${(props) => (props.$deleted ? 'tomato' : 'transparent')} !important;
    height: auto;
    border-style: solid;
    border-color: ${(props) => (props.$changed ? 'tomato' : 'transparent')};
    border-width: 2px;
    border-radius: 2px;
    justify-content: flex-start;
    font-size: 1em;
    font-weight: normal;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  && span {
    flex-grow: 1;
  }
`;

const ContainerTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.p<{$opacity: number}>`
  display: flex;
  flex-grow: 1;
  margin: 0 10px;
  opacity: ${(props) => props.$opacity};
`;

export default VocCategories;
