import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { IItemId } from 'types/content';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import * as lodash from 'lodash';
import TranslationManagerModel from './TranslationManagerModel';
import { IVocCategory, IVocCategoryEdit } from '../types/vocCategories';

export default class VocCategoryModel extends BaseModel<IVocCategory, IVocCategoryEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.vocabularyCategories;
    this.gridName = 'VocCategory';
    this.getGridColumns();
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IVocCategory[]>(`Voc-Categories`);
    this.gridData = this.gridData.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IVocCategory>(`Voc-Categories/grid/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const [item, sources] = await Promise.all([get<IVocCategoryEdit>(`Voc-Categories/new`), this.translations.new()]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  async updateItem() {
    const { id, sources } = this.itemUpdated as IVocCategoryEdit;
    await put(`Voc-Categories/${id}`, lodash.omit(this.itemUpdated, 'sources'));
    await this.translations.update(id, sources);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const { sources } = this.itemUpdated as IVocCategoryEdit;
    const result = await post<IItemId>(`Voc-Categories`, lodash.omit(this.itemUpdated, 'sources'));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await this.translations.update(newItemId, sources);
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    await super.delete();
    const { id } = this.itemUpdated;

    const result = await del(`Voc-Categories/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateCache();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.vocabularyCategories)));
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, sources] = await Promise.all([
      get<IVocCategoryEdit>(`Voc-Categories/${itemId}`),
      this.translations.get(itemId),
    ]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }
}
