import { get, post } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';

type IFreelanceUnassigned = {
  id: number;
  name: string;
  freelance: boolean;
};

export default class BoardsFreelancesUnassignedModel extends BaseModel {
  boardId: number;

  constructor(options: IModelOptions & { boardId: number }) {
    super(options);
    this.boardId = options.boardId;
    this.gridName = 'Users';
    this.getGridColumns();
    this.getGrid();
  }

  async getGrid() {
    await super.getGrid();

    this.gridData = await get<IFreelanceUnassigned[]>(`boards/${this.boardId}/freelances/unassigned`);

    this.loadingGrid = false;
    this.render();
  }

  async assignFreelance(userId: number) {
    this.loadingGrid = true;
    this.render();
    await post(`boards/${this.boardId}/freelances/${userId}`, {});
    this.getGrid();
  }
}
