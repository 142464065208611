import InflectTablesModel from 'models/InflectTablesModel';
import { get, put } from 'services/request';
import BaseInflectsModel, { IInflectBase } from './BaseInflectsModel';

type IInflectEnding = IInflectBase & {
  ending: string;
};

export default class ConjugationRuleEndingsModel extends BaseInflectsModel<IInflectEnding> {
  withInitials = false;
  ruleId = 0;
  wordClassId = 0;

  setRuleId(ruleId: number) {
    this.ruleId = ruleId;
  }

  async loadEndings(ruleId: number, wordClassId: number) {
    if (ruleId <= 0) {
      return;
    }
    await super.loadItemEdit(wordClassId);

    this.ruleId = ruleId;
    this.wordClassId = wordClassId;

    this.inflectTablesModel = new InflectTablesModel({
      courseId: this.courseId,
      wordClass: wordClassId,
    });

    await Promise.all([
      this.inflectTablesModel.getInflectTables(),
      this.inflectTablesModel.getInflectTableHeaders(),
      this.inflectTablesModel.getInflectTableCells(),
    ]);

    const items = await get<IInflectEnding[]>(`conj-rules/${ruleId}/endings`);
    await this.setItem(this.fillInflects(items));

    this.loadingItem = false;
    this.render();
  }

  async save() {
    this.loadingItem = true;
    this.render();
    await put(`conj-rules/${this.ruleId}/endings`, {
      endings: JSON.stringify(this.itemUpdated),
    });

    await this.loadEndings(this.ruleId, this.wordClassId);
  }

  protected createEmptyItem(inflectId: number, index = 0): IInflectEnding {
    return {
      index,
      inflectId,
      ending: '',
    };
  }
}
