import '@fortawesome/fontawesome-free/css/all.css';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { AppContext } from 'AppContext';
import ConceptLinkerGuessedTooltip from 'components/conceptLink/ConceptLinkerGuessedTooltip';
import ConceptLinkerTooltip from 'components/conceptLink/ConceptLinkerTooltip';
import { initTooltipLoader } from 'components/conceptLink/TooltipLoader';
import { Dashboard } from 'components/Dashboard';
import { Login } from 'components/Login';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initRequestErrorNotification } from 'services/request';
import { IAppState } from 'store/reducers/rootReducer';
import { IConceptLinkerGuessedTooltip, IConceptLinkerTooltip } from 'types/conceptLinker';

export const App: React.FC = () => {
  const userLogged = useSelector((state: IAppState) => state.user.logged);
  const dispatch = useDispatch();

  const [notification, setNotification] = useState(null as string | null);
  const [conceptLinkerTooltip, setConceptLinkerTooltip] = useState(null as IConceptLinkerTooltip | null);
  const [conceptLinkerGuessedTooltip, setConceptLinkerGuessedTooltip] = useState(
    null as IConceptLinkerGuessedTooltip | null,
  );

  useEffect(() => {
    const closeTooltips = () => {
      setConceptLinkerGuessedTooltip(null);
      setConceptLinkerTooltip(null);
    };

    const clickListener = () => {
      closeTooltips();
    };

    const keyListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeTooltips();
      }
    };
    window.addEventListener('click', clickListener);
    window.addEventListener('keydown', keyListener);

    const isUnrelatedGuessedTooltipOpen =
      conceptLinkerGuessedTooltip &&
      conceptLinkerTooltip &&
      !conceptLinkerGuessedTooltip.concepts.find(
        (concept) =>
          concept.ref === conceptLinkerTooltip.concept.ref && concept.refId === conceptLinkerTooltip.concept.refId,
      );
    if (isUnrelatedGuessedTooltipOpen) {
      setConceptLinkerGuessedTooltip(null);
    }

    return () => {
      window.removeEventListener('click', clickListener);
      window.removeEventListener('keydown', keyListener);
    };
  }, [conceptLinkerGuessedTooltip, conceptLinkerTooltip]);

  useEffect(() => {
    initRequestErrorNotification(setNotification);
    initTooltipLoader(dispatch);
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({
      notification,
      setNotification,
      conceptLinkerTooltip,
      setConceptLinkerTooltip,
      conceptLinkerGuessedTooltip,
      setConceptLinkerGuessedTooltip,
    }),
    [notification, conceptLinkerTooltip, conceptLinkerGuessedTooltip],
  );

  return (
    <AppContext.Provider value={contextValue}>
      {userLogged ? <Dashboard /> : <Login />}
      <Snackbar open={notification !== null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert variant="filled" severity="error" onClose={() => setNotification(null)}>
          {notification}
        </Alert>
      </Snackbar>
      {conceptLinkerTooltip && <ConceptLinkerTooltip {...conceptLinkerTooltip} />}
      {conceptLinkerGuessedTooltip && <ConceptLinkerGuessedTooltip {...conceptLinkerGuessedTooltip} />}
    </AppContext.Provider>
  );
};
