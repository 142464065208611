import { BaseModel, IModelCourseOptions } from 'models/BaseModel';
import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export type IIAReviewDashboardGrid = {
  id: number;
  ref: number;
  refName: string;
  none: number;
  review: number;
  done: number;
  error: number;
} & {
  [prop: string]: IIAReviewDashboardCell;
};

export interface IIAReviewDashboardCell {
  pending: number;
  doing: number;
  done: number;
}

interface IIAReviewDashboard {
  dashboard: IIAReviewDashboardGrid[];
}

export default class AIReviewDashboardModel extends BaseModel {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.aIReview;
    this.gridName = 'AIReviewDashboard';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getDashboard() {
    this.loadingGrid = true;
    this.render();
    const response = await get<IIAReviewDashboard>(`/ai/courses/${this.courseId}/review-dashboard`);
    this.gridData = response.dashboard;
    this.loadingGrid = false;
    this.render();
  }
}
