import { Button } from '@material-ui/core';
import React from 'react';

type IProps = {
  handleFile: (file: File) => void;
  className?: string;
};

const FileUpload: React.FC<IProps> = (props) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileUploaded = event.target.files[0];
      props.handleFile(fileUploaded);
    }
  };

  return (
    <div className={props.className}>
      <Button variant="contained" size="small" onClick={handleClick}>
        Select File
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default FileUpload;
