import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { put } from '../../../../services/request';
import { IBoardTypes } from '../../../../models/BoardsModel';

export type IProps = {
  courseId: number;
  startPos: number;
  endPos: number;
  boardType: IBoardTypes;
  onDoing?: () => void;
  onDone?: () => void;
};

const WizardChangeStateToDo: React.FC<IProps> = (props) => {
  const doChangeState = async () => {
    props.onDoing && props.onDoing();
    await put(`/courses/${props.courseId}/wizard/board-types/${props.boardType}/states/move-todo`, {
      startPos: props.startPos,
      endPos: props.endPos,
    });
    props.onDone && props.onDone();
  };

  return (
    <Wrapper>
      <ButtonStyled variant="contained" size="small" onClick={doChangeState}>
        Change States to Todo
      </ButtonStyled>
    </Wrapper>
  );
};

export default WizardChangeStateToDo;

const Wrapper = styled.div`
  padding: 10px;
`;

const ButtonStyled = styled(Button)`
  && {
    margin-right: 16px;
  }
`;
