import { ModuleContainer, EditorRow, ContainerRight } from 'components/common/styles';
import React, { useState } from 'react';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import CLUtilsPanel from './tabs/CLUtilsPanel';
import { LessonSelect } from '../../components/selects/LessonSelect';
import { Button } from '@material-ui/core';
import CLUtilsVoc from './tabs/CLUtilsVoc';

export type IProps = {
  courseId: number;
};

const CLUtils: React.FC<IProps> = (props) => {
  const [lessonPos, setLessonPos] = useState(0);
  const [lessonPosLoad, setLessonPosLoad] = useState(0);
  const handleLessonChanged = (lessonId: number, lessonPos: number) => {
    console.log(lessonPos);
    setLessonPos(lessonPos);
  };

  const handleLoadClick = () => {
    setLessonPosLoad(lessonPos);
  };

  return (
    <ModuleContainer>
      <ContainerRight>
        <EditorRow>
          <LessonSelect handleLessonChanged={handleLessonChanged} courseId={props.courseId} />
          <Button
            variant="contained"
            size="small"
            onClick={handleLoadClick}
            disabled={lessonPos === 0 || lessonPos === lessonPosLoad}
          >
            load
          </Button>
        </EditorRow>
        <ItemTabs tabNames={['Panels', 'Vocabulary']}>
          <CLUtilsPanel courseId={props.courseId} lessonPos={lessonPosLoad} />
          <CLUtilsVoc courseId={props.courseId} lessonPos={lessonPosLoad} />
        </ItemTabs>
      </ContainerRight>
    </ModuleContainer>
  );
};

export default CLUtils;
