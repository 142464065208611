import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextEditor from 'CMFW/input/TextEditor';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import ConjugationTemplateModel from '../../../models/ConjugationTemplateModel';
import { IFields } from '../../../CMFW/grid/fields';
import BooleanIcon from '../../../CMFW/input/BooleanIcon';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { WordClassSelect } from '../../../components/selects/WordClassSelect';
import ConjLiteralSelect from '../../../components/selects/ConjLiteralSelect';
import styled from 'styled-components';
import { booleanEyeRenderer } from '../../../CMFW/grid/cellRenderers/booleanEye';
import TextSearch from '../../../components/search/TextSearch';

export type IProps = {
  courseId: number;
  search?: string;
};

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['winflecttabletemplate', 'wMultiTarget'],
  ],
  toolBarInline: [['Bold', 'Italic']],
};

const ConjTemplate: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const conjTemplateModel = useMemo(
    () =>
      new ConjugationTemplateModel({
        render: forceRender,
        courseId: props.courseId,
        search: props.search
      }),
    [props.courseId, forceRender, props.search],
  );
  const showToUsers = conjTemplateModel.getItemUpdatedValue('showToUser');
  return (
    <ModuleContainer>
      <ModuleContext.Provider value={conjTemplateModel}>
        <Split widthLeft={424}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.showToUser]: booleanEyeRenderer,
              }}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'name'} />
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <EditorRowStart>
                <BooleanIcon icon={faEye} keyName={'showToUser'} onChange={forceRender} />
                <TextInputOrder disabled={!showToUsers} type={'number'} keyName={'order'} label={'order'} />
                <WordClassSelect disabled={!showToUsers} isLinked />
                <ConjLiteralSelect disabled={!showToUsers} isLinked noneOption />
              </EditorRowStart>
              <TextEditor keyName={'html'} config={CK_EDITOR_CONFIG} autoGrow />
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const TextInputOrder = styled(TextInput)`
  width: 50px;
`;

const EditorRowStart = styled(EditorRow)`
  justify-content: flex-start;
`;

export default ConjTemplate;
