import { get } from 'services/request';
import { IVocCard } from 'types/content';
import { BaseModel, IModelOptions } from './BaseModel';

export default class VocCardModel extends BaseModel {
  constructor(options: IModelOptions & { vocId: number }) {
    super(options);
    this.loadItemEdit(options.vocId);
  }

  async loadItemEdit(id: number) {
    await super.loadItemEdit(id);
    const vocCards = await get<IVocCard[]>(`vocs/${id}/card`);
    this.setItem({ cardData: vocCards[0] });
    this.render();
  }
}
