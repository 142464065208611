import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TrashLabel } from './cells/TrashLabel';
import { IConjugationState, IInflectId, IInflectTable, IInflectTableCellAugmented } from 'models/InflectsModel';
import { IInflectIdName } from 'models/InflectTablesModel';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import KeyContext from '../../../CMFW/context/KeyContext';
import { createKeyPath } from '../../../CMFW/context/helper/keyContext';
import BaseInflectsModel, { IInflectBase } from '../../../models/BaseInflectsModel';

type IProps = {
  table: IInflectTable;
  cellRenderer: ReactNode;
  showTrash: boolean;
};

const removedInflect: Partial<IInflectId> = {
  word: '',
  conjState: IConjugationState.NO_CONJ,
};

const InflectTable: React.FC<IProps> = (props) => {
  const { table } = props;
  const [inflects, setInflects] = useState(table.inflects);

  const parentKey = useContext(KeyContext);
  const inflectsModel = useContext(ModuleContext) as BaseInflectsModel<IInflectBase>;

  useEffect(() => {
    if (table.inflects) {
      setInflects(table.inflects);
    }
  }, [table.inflects]);

  const updateRemovedInflects = (inflects: IInflectTableCellAugmented[]) => {
    const inflectIds: number[] = [];
    inflects.forEach((inflect) => inflect.values.forEach((value) => inflectIds.push(value.inflectId)));
    inflectsModel.emptyInflects(inflectIds);
  };

  const handleTableTrashClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();

    const updatedInflects = inflects.map((inflect) => ({
      ...inflect,
      values: inflect.values.map((value) => ({ ...value, ...removedInflect })),
    }));

    setInflects(updatedInflects);
    updateRemovedInflects(inflects);
  };

  const handleColumnTrashClick = (columnId: number) => {
    const columnInflects: IInflectTableCellAugmented[] = [];

    const updatedInflects = inflects.map((inflect) => {
      if (inflect.column === columnId) {
        columnInflects.push(inflect);
        return {
          ...inflect,
          values: inflect.values.map((value) => ({ ...value, ...removedInflect })),
        };
      }
      return inflect;
    });

    setInflects(updatedInflects);
    updateRemovedInflects(columnInflects);
  };

  const handleRowTrashClick = (rowId: number) => {
    const rowInflects: IInflectTableCellAugmented[] = [];

    const updatedInflects = inflects.map((inflect) => {
      if (inflect.row === rowId) {
        rowInflects.push(inflect);
        return {
          ...inflect,
          values: inflect.values.map((value) => ({ ...value, ...removedInflect })),
        };
      }
      return inflect;
    });

    setInflects(updatedInflects);
    updateRemovedInflects(rowInflects);
  };

  const isEmpty =
    table.inflects.find((inflect) => inflect.values.find((value) => !inflectsModel.isInflectEmpty(value))) ===
    undefined;

  const columnHeaders: IInflectIdName[] = [];
  const rowHeaders: IInflectIdName[] = [];

  table.inflects.forEach((inflect) => {
    if (inflect.columnHeader && !columnHeaders.find((header) => header.id === inflect.column)) {
      if (inflect.columnHeader) {
        columnHeaders.push(inflect.columnHeader);
      }
    }
    if (!rowHeaders.find((header) => header.id === inflect.row)) {
      if (inflect.rowHeader) {
        rowHeaders.push(inflect.rowHeader);
      }
    }
  });

  if (columnHeaders.length) {
    columnHeaders.unshift({ id: 0, name: '' });
  }

  return (
    <TableWrapper>
      <div>
        <TrashLabel
          handleTrashClick={handleTableTrashClick}
          onFocus={(event) => event.stopPropagation()}
          label={<TableTitle>{table.name}</TableTitle>}
          showTrash={!isEmpty && props.showTrash}
        />
      </div>
      <TableContainer>
        <TableStyled size="small">
          <TableHead>
            <TableRow>
              {columnHeaders.map((header) => (
                <Header key={header.id} component="th">
                  <TrashLabel
                    onlyHover
                    handleTrashClick={() => handleColumnTrashClick(header.id)}
                    label={header.name}
                    showTrash={props.showTrash}
                  />
                </Header>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowHeaders.map((rowHeader) => (
              <TableRow key={rowHeader.id}>
                <Header key={rowHeader.id + '-' + rowHeader.name} component="th" scope="row">
                  <TrashLabel
                    onlyHover
                    handleTrashClick={() => handleRowTrashClick(rowHeader.id)}
                    label={rowHeader.name}
                    showTrash={props.showTrash}
                  />
                </Header>
                {inflects
                  .filter((inflect) => inflect.row === rowHeader.id)
                  .map((cell) => (
                    <TableCellStyled key={cell.id} component="td" scope="row">
                      {cell.values.map((value, index) => (
                        <CellWrapper key={value.inflectId + '-' + index}>
                          <KeyContext.Provider value={createKeyPath(parentKey, '', value.index)}>
                            {props.cellRenderer}
                          </KeyContext.Provider>
                        </CellWrapper>
                      ))}
                    </TableCellStyled>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </TableStyled>
      </TableContainer>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin: 20px 10px;
`;

const TableStyled = styled(Table)`
  && {
    width: auto;
  }
`;

const TableCellStyled = styled(TableCell)`
  && {
    width: 160px;
    padding: 2px 4px;
    vertical-align: top;
  }
`;

const Header = styled(TableCellStyled)`
  && {
    background-color: #555;
    color: white;
    border: solid 2px white;
  }
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const TableTitle = styled.div`
  font-weight: bold;
`;

export default InflectTable;
