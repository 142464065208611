import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import AIContentCheckerModel, { IAIContentCheckerRef } from '../../../models/AIContentCheckerModel';
import ContentAICheckerRef from '../components/ContentAICheckerRef';

export type IProps = {
  courseId: number;
};

const ContentAIChecker: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new AIContentCheckerModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const handleLoadClick = () => {
    moduleModel.loadItemEdit();
  };

  const refs: IAIContentCheckerRef[] = moduleModel.getItemUpdatedValue('', []);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerRight>
          <EditorRowStyled>
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Checkers>
            {refs.length > 0 &&
              refs.map((checker) => (
                <ContentAICheckerRef
                  key={checker.ref}
                  courseId={props.courseId}
                  refId={checker.ref}
                  name={checker.name}
                  batchSize={checker.batchSize}
                />
              ))}
          </Checkers>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const Checkers = styled.div`
  padding: 10px;
  height: 100%;
  overflow: auto;
`;

export default ContentAIChecker;
