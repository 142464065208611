import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorGap, EditorRow } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import ConjugationBatchModel from '../../../models/ConjugationBatchModel';
import ConjBatchRef from '../components/ConjBatchRef';

export type IProps = {
  courseId: number;
};

const ConjBatch: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new ConjugationBatchModel({ render: forceRender, courseId }), [
    forceRender,
    courseId,
  ]);

  const handleLoadClick = async () => {
    await moduleModel.getResources();
  };

  const handleConjugateClick = async () => {
    await moduleModel.batchConjugate();
  };

  const { resources, loadEnabled, batchConjugateEnabled } = moduleModel;

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <ItemLayout>
          <EditorRowStyled>
            <Button disabled={!loadEnabled} variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
            <EditorGap />
            <Button disabled={!batchConjugateEnabled} variant="contained" size="small" onClick={handleConjugateClick}>
              conjugate
            </Button>
          </EditorRowStyled>
          <ResourcesContainer>
            {resources.map((resource) => (
              <ConjBatchRef key={resource.ref} model={resource} />
            ))}
          </ResourcesContainer>
        </ItemLayout>
        <LoadingItem />
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default ConjBatch;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const ResourcesContainer = styled.div`
  padding: 5px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
