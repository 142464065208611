import React, { useContext } from 'react';
import ModuleContext from '../context/ModuleContext';
import Button from '@material-ui/core/Button';

const ReloadButton: React.FC = () => {
  const module = useContext(ModuleContext);
  return (
    <Button variant="outlined" size="small" onClick={module.reload.bind(module)}>
      reload
    </Button>
  );
};

export default ReloadButton;
