import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { IUserCourseEdit } from 'types/users';
import { AdminCoursePermissions } from './AdminCoursePermissions';

type IProps = {
  keyName: string;
};

export const AdminCourses: React.FC<IProps> = (props) => {
  const [folded, setFolded] = useState([] as boolean[]);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: IUserCourseEdit[] = module.getItemValue.bind(module)(aggregatedKeyName) || [];

  const toggleFolded = (courseId: number) => {
    if (typeof folded[courseId] === 'undefined') {
      folded[courseId] = false;
    } else {
      folded[courseId] = !folded[courseId];
    }
    setFolded([...folded]);
  };

  return (
    <Wrapper>
      {moduleItemValue.map((value: IUserCourseEdit, index) => (
        <KeyContext.Provider value={createKeyPath(parentKey, props.keyName, index)} key={value.id}>
          <Course>
            <CourseTitle onClick={() => toggleFolded(value.id)}>{value.name}</CourseTitle>
            {typeof folded[value.id] !== 'undefined' && !folded[value.id] && (
              <AdminCoursePermissions keyName={'permissions'} />
            )}
          </Course>
        </KeyContext.Provider>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Course = styled.div`
  margin: 30px 10px;
  border: 1px solid #ccc;
  background-color: #eee;
`;

const CourseTitle = styled.div`
  padding: 4px 10px;
  background-color: #ccc;
  cursor: pointer;
`;
