import React, { useContext, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { comparePosition } from '../../../CMFW/grid/comparators/comparePosition';
import { LoadingGrid } from '../../../CMFW/layout/LoadingGrid';
import TextSearch from '../../../components/search/TextSearch';
import { IRefIds } from '../../../constants/refs';
import { useForceRender } from '../../../hooks/useForceRender';
import ReadingModel from '../../../models/ReadingModel';
import { ILessonOutlineCard, ILessonOutlineCycleActivityReading } from '../../../types/lessonOutline';
import { IGridData } from '../../../types/models';
import LessonOutlineActivityReading from '../cards/activities/LessonOutlineActivityReading';
import { WrapperActivity } from '../cards/LessonOutlineActivity';
import LessonOutlineContext from '../context/LessonOutlineContext';

type IProps = {
  courseId: number;
};

const LessonOutlineSearchReading: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const readingModel = useMemo(() => new ReadingModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);
  const lessonOutlineContext = useContext(LessonOutlineContext);

  const gridData = readingModel.gridData.sort((a, b) => comparePosition(a.position, b.position));

  return (
    <Wrapper>
      <ModuleContext.Provider value={readingModel}>
        <TextSearch />
        <Droppable
          isDropDisabled={true}
          droppableId={`${IRefIds.reading}-bank`}
          direction={'vertical'}
          type={ILessonOutlineCard.ACTIVITY}
        >
          {(providedDroppable) => (
            <Wrapper>
              <WrapperResult {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {gridData.map((reading: IGridData, index: number) => (
                  <Draggable
                    isDragDisabled={!lessonOutlineContext.DNDEnabled}
                    draggableId={`${IRefIds.reading}-${reading.id}`}
                    index={index}
                    key={`${IRefIds.reading}-${index}`}
                  >
                    {(provided) => (
                      <WrapperActivity
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        extraWide
                      >
                        <LessonOutlineActivityReading activity={reading as ILessonOutlineCycleActivityReading} />
                      </WrapperActivity>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </WrapperResult>
              <LoadingGrid />
            </Wrapper>
          )}
        </Droppable>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default LessonOutlineSearchReading;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
`;

const WrapperResult = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
`;
