import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IVoc } from './VocabularyModel';

export default class VocCategoryVocsModel extends BaseModel<IVoc, IVoc> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'VocCategoryVocs';
    this.getGridColumns();
  }

  async setLoadingGrid(loading: boolean){
    this.loadingGrid = loading;
    this.render();
  }

  async setGrid(gridData: IVoc[]) {
    this.gridData = gridData;
    this.render();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=0`;
  }
}
