import React from 'react';
import styled from 'styled-components';

export type IProps = {
  similarity: number;
};

const CATSimilarity: React.FC<IProps> = (props) => {
  const getColor = (): string => {
    if (props.similarity >= 100) {
      return '#a8ffa8';
    } else {
      return '#ffaf75';
    }
  };

  if (props.similarity === 0) {
    return null;
  }

  return (
    <Wrapper $color={getColor()}>
      <Text>{props.similarity}</Text>
    </Wrapper>
  );
};

export default CATSimilarity;

const Wrapper = styled.div<{ $color: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$color};
`;

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  color: #525252;
`;
