import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { IPosition } from '../types/content';

export type ICLUtilsVoc = {
  id: number;
  position: IPosition;
  title: string;
  wordClass: string;
  word: string;
  nUses: number;
};
type ICLUtilsPanelSearch = IModuleSearch & {
  lessonPos: number;
};
export default class CLUtilsVocModel extends BaseModel<ICLUtilsVoc, {}> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.vocs;
    this.gridName = 'CLUtilsVoc';
    this.getGridColumns();
  }

  async getGrid(data: ICLUtilsPanelSearch) {
    await super.getGrid();
    this.gridData = await get<ICLUtilsVoc[]>(`groups/${this.courseId}/voc-cl-utils`, data);

    this.loadingGrid = false;

    this.render();
  }

  setItemId(itemId: number) {
    this.itemId = itemId;
  }
}
