import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { IPanelEstructureCard } from '../../../../types/panelEstructure';

type IProps = {};

const PanelEstructureTrash: React.FC<IProps> = () => {
  return (
    <Wrapper>
      <Droppable droppableId={'Trash-Block'} direction={'vertical'} type={IPanelEstructureCard.BLOCK}>
        {(provided, snapshot) => (
          <WrapperDrop isDraggingOver={snapshot.isDraggingOver} {...provided.droppableProps} ref={provided.innerRef}>
            {provided.placeholder}
            <Droppable droppableId={'Trash-Element'} direction={'vertical'} type={IPanelEstructureCard.ELEMENT}>
              {(provided, snapshot) => (
                <WrapperDrop
                  isDraggingOver={snapshot.isDraggingOver}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                </WrapperDrop>
              )}
            </Droppable>
          </WrapperDrop>
        )}
      </Droppable>
    </Wrapper>
  );
};

export default PanelEstructureTrash;

const Wrapper = styled.div`
  height: 40px;
  width: 40px;
  overflow: hidden;
  background-color: pink;
  margin: 10px;
`;

const WrapperDrop = styled.div<{ isDraggingOver: boolean }>`
  height: 40px;
  width: 40px;
  background-color: ${(props) => (props.isDraggingOver ? 'red' : 'none')};
`;
