export function aiReviewRenderer(param: { value: string }) {
  const value = parseInt(param.value);
  console.log('value', value);
  switch (value) {
    case 1:
      return "<span class='fa fa-robot red'></span>";
    case 2:
      return "<span class='fa fa-robot green'></span>";
    default:
      return '';
  }
}
