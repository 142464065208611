import { get } from '../services/request';
import { IPanelIntro } from '../types/panelsIntro';

export default class PanelIntroSelectModel {
  courseId: number;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    return await get<IPanelIntro[]>(`groups/${this.courseId}/panels-intro`);
  }
}
