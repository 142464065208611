import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import BlocksModel, { IBlockSelect } from 'models/BlocksModel';
import React, { useMemo, useState } from 'react';
import { SelectLayout } from './SelectLayout';

type IProps = {
  onChange: (blockId: number) => void;
  className?: string;
  blockId: number;
};

const BlockSelect: React.FC<IProps> = (props) => {
  const [currentBlockId, setCurrentBlockId] = useState(props.blockId);

  const cacheKey = getCacheKey(IRefIds.blockNames);
  const dataModel = useMemo(() => new BlocksModel(), []);
  const selectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (value: IBlockSelect) => {
    const selectedBlockId = value.id;
    setCurrentBlockId(selectedBlockId);
    props.onChange && props.onChange(selectedBlockId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Block"
      optionLabelKey="block"
      currentValue={currentBlockId}
    />
  );
};

export default BlockSelect;
