import { IConceptLinkerVoc } from 'components/conceptLink/TooltipVocsModel';
import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';

type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const GuessedVoc: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.vocs,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerVoc;
  
  if (!data) {
    return null;
  }

  const { title } = data;

  return (
      <div dangerouslySetInnerHTML={{ __html: title }} />
  );
};


export default GuessedVoc;
