import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerRight, EditorRow, FormContainer, TextFieldStyled } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import DialectSelect, { IDialectOption } from '../../../components/selects/DialectSelect';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import AudioDashboardModel, { IAudioDashboardGrid } from '../../../models/AudioDashboardModel';
import Grid from '../../../CMFW/grid/Grid';
import AudioManageArtists from '../modals/AudioManageArtists';
import { IGridData } from '../../../types/models';
import AudioCreateSets from '../modals/AudioCreateSets';

export type IProps = {
  courseId: number;
};

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

const AudioDashboard: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new AudioDashboardModel({ render: forceRender, courseId }), [
    forceRender,
    courseId,
  ]);

  const [startPos, setStartPos] = React.useState(0);
  const [endPos, setEndPos] = React.useState(DEFAULT_END_POS);
  const [startPosLoad, setStartPosLoad] = React.useState(DEFAULT_START_POS);
  const [endPosLoad, setEndPosLoad] = React.useState(DEFAULT_END_POS);
  const [dialectSelect, setDialectSelect] = React.useState(0);
  const [dialectLoad, setDialectLoad] = React.useState(0);
  const [refModal, setRefModal] = React.useState(0);
  const [refNameModal, setRefNameModal] = React.useState('');
  const [showModalArtists, setShowModalArtists] = React.useState(false);
  const [showModalCreateSets, setShowModalCreateSets] = React.useState(false);

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue < DEFAULT_START_POS) {
      newValue = DEFAULT_START_POS;
    }
    setStartPos(newValue);
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue > DEFAULT_END_POS) {
      newValue = DEFAULT_END_POS;
    }
    setEndPos(newValue);
  };

  const handleDialectChange = (dialect: IDialectOption) => {
    setDialectSelect(dialect.dialectId);
  };

  const handleLoadClick = () => {
    moduleModel.getDashboard(dialectSelect, startPos, endPos);
    setDialectLoad(dialectSelect);
    setStartPosLoad(startPos);
    setEndPosLoad(endPos);
  };

  const handleAudioArtistsOpen = () => {
    setShowModalArtists(true);
  };

  const handleCloseModal = () => {
    setShowModalArtists(false);
  };

  const onRowClick = (data: IGridData, selectedRows: IGridData[]) => {
    const row = selectedRows[0] as IAudioDashboardGrid | undefined;
    if (row && row.pending > 0) {
      setRefModal(row.ref);
      setRefNameModal(row.name);
      setShowModalCreateSets(true);
    }
  };

  const handleCloseModalCreateSets = () => {
    setShowModalCreateSets(false);
  };

  const handleReloadDashboard = () => {
    moduleModel.getDashboard(dialectLoad, startPosLoad, endPosLoad);
  };

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <FormContainer>
          <EditorRowStyled>
            <TextFieldPos
              value={startPos}
              variant="outlined"
              label={'Start'}
              type={'number'}
              onChange={handleChangeStarPos}
            />
            <TextFieldPos
              value={endPos}
              variant="outlined"
              label={'End'}
              type={'number'}
              onChange={handleChangeEndPos}
            />
            <DialectSelect onlyTarget handleDialectChange={handleDialectChange} />
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Grid handleRowClick={onRowClick} />
          <EditorRowStyled>
            <Button variant="contained" size="small" onClick={handleAudioArtistsOpen} disabled={dialectLoad === 0}>
              Audio Artists
            </Button>
          </EditorRowStyled>
        </FormContainer>
      </ContainerRight>
      {showModalArtists && (
        <AudioManageArtists onClose={handleCloseModal} courseId={props.courseId} targetDialectId={dialectLoad} />
      )}
      {showModalCreateSets && (
        <AudioCreateSets
          onClose={handleCloseModalCreateSets}
          onReloadDashboard={handleReloadDashboard}
          courseId={props.courseId}
          refId={refModal}
          name={refNameModal}
          targetDialectId={dialectLoad}
          startLesson={startPosLoad}
          endLesson={endPosLoad}
        />
      )}
    </ModuleContext.Provider>
  );
};

export default AudioDashboard;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 66px;
  && {
    margin-right: 10px;
  }
`;
