import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import { IFeedback } from '../../models/FeedbackModel';
import FeedbackList from '../../modules/Feedback/cards/components/FeedbackList';
import FeedbackListItem from '../../modules/Feedback/cards/components/FeedbackListItem';
import TextInput from '../input/TextInput';
import { ModalWrapper } from '../../components/ModalWrapper';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import BooleanIcon from '../input/BooleanIcon';

const VERSION_KEY = 'version';
const VERSION_UPDATED_KEY = 'versionUpdated';
const VERSION_FEEDBACK_KEY = 'feedback';

export const VersionFeedback: React.FC = () => {
  const parentKey = useContext(KeyContext);

  const module = useContext(ModuleContext);
  const version: number = module.getItemValue.bind(module)(createKeyPath(parentKey, VERSION_KEY));
  const versionUpdated: string = module.getItemValue.bind(module)(createKeyPath(parentKey, VERSION_UPDATED_KEY));
  const feedback: IFeedback[] = module.getItemValue.bind(module)(createKeyPath(parentKey, VERSION_FEEDBACK_KEY));

  const [viewVersion, setViewVersion] = useState(version);
  const [showModal, setShowModal] = useState(false);

  const onChangeViewVersion = (e: ChangeEvent<HTMLSelectElement>) => {
    setViewVersion(parseInt(e.target.value));
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onOpenModal = () => {
    setShowModal(true);
  };

  const getVersions = () => {
    const versions: number[] = [version];
    feedback.forEach((f) => {
      if (!versions.includes(f.version)) {
        versions.push(f.version);
      }
    });
    return versions.sort().reverse();
  };
  const getMeanVersion = (): IFeedback => {
    let n = 0;
    let total = 0;
    const feedbacks = feedback.filter((f) => f.version === version);
    feedbacks.forEach((f) => {
      n += f.n;
      total += f.n * f.median;
    });

    return {
      n,
      median: Math.floor(total / (n ? n : 1)),
      version,
      dialectId: 0,
      dialectCode: '',
    };
  };

  const versions = getVersions();
  const itemId = module.getItemId();

  if (itemId <= 0) {
    return null;
  }

  return (
    <Container>
      <TextInputOrder type={'number'} keyName={'version'} label={'Ver.'} />
      <FeedbackAbsolute>
        <FeedbackListItem feedback={getMeanVersion()} courseId={module.courseId} onClick={onOpenModal} />
        <BooleanIcon keyName={'feedbackTracking'} icon={faBullseye} />
      </FeedbackAbsolute>
      {showModal && (
        <ModalWrapper width={150} height={300} title={'Feedback'} onClose={onCloseModal}>
          <P>
            Version: <b>{version}</b>
            <br />
            {versionUpdated}
          </P>
          <FeedbackListContainer>
            <select onChange={onChangeViewVersion}>
              {versions.map((version) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
            </select>
            <FeedbackList feedback={feedback.filter((f) => f.version === viewVersion)} showDialectCode />
          </FeedbackListContainer>
        </ModalWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50px;
  justify-content: center;
  align-items: center;
`;

const TextInputOrder = styled(TextInput)`
  width: 50px;
`;

const FeedbackAbsolute = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -15px;
  z-index: 2;
`;

const FeedbackListContainer = styled.div`
  width: 70px;
`;

const P = styled.p`
  margin-top: 0;
`;
