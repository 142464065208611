import Button from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import { Loading } from 'components/common/Loading';
import { EditorGapGreedyH, EditorRow, ModuleContainer } from 'components/common/styles';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import SaveButton from '../../../CMFW/button/SaveButton';
import TextInput from '../../../CMFW/input/TextInput';
import InflectTables from '../../../components/inflect/table/InflectTables';
import InflectPositionsModel from '../../../models/InflectPositionsModel';

export type IProps = {
  courseId: number;
};

const InflectPositions: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new InflectPositionsModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const [wordClassId, setWordClassId] = useState(0);

  const handleChangeWordClass = (id: number) => {
    setWordClassId(id);
  };

  const handleLoad = () => {
    model.loadWordClass(wordClassId);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Container>
          <EditorRow>
            <WordClassSelect onChange={handleChangeWordClass} />
            <Button variant="contained" size="small" onClick={handleLoad}>
              load
            </Button>
            <EditorGapGreedyH />
            <SaveButton />
          </EditorRow>
          <Scroll>
            <InflectTables cellRenderer={<TextInput type={'number'} keyName={'position'} />} showTrash={false} />
          </Scroll>
          {model.loadingGrid && <Loading />}
        </Container>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-right: 10px;
`;

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export default InflectPositions;
