import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import Flag from 'CMFW/input/Flag';
import TextInput from 'CMFW/input/TextInput';
import Positions from 'components/common/Positions';
import { ContainerLeft, EditorRow } from 'components/common/styles';
import GenderSelect from 'components/selects/GenderSelect';
import React from 'react';
import styled from 'styled-components';
import { VersionFeedback } from '../../../CMFW/layout/VersionFeedback';

const ReadingEdit: React.FC = () => {
  return (
    <ContainerLeftStyled>
      <EditorRow>
        <Flag />
        <Positions keyName={'positions'} />
        <VersionFeedback />
        <GenderSelect readbyKey={'readBy'} />
        <TextInput keyName={'title'} label={'Title'} />
        <SaveButton />
      </EditorRow>
      <DeleteButton />
    </ContainerLeftStyled>
  );
};

export default ReadingEdit;

export const ContainerLeftStyled = styled(ContainerLeft)`
  justify-content: space-between;
`;
