import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import * as WaveSurferRegions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
// @ts-ignore
import * as WaveSurferTimeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';

const REGION_END_PLAY_DURATION = 2;

const KEY = 'audioPath';
const KEY_START = 'audioStart';
const KEY_END = 'audioEnd';

const sToMs = (ms: number) => Math.floor(ms * 1000);

type IProps = {
  isModalOpen?: boolean; // Used for double binding with modal
};

const AudioWaveEditor: React.FC<IProps> = (props) => {
  const waveformRef = React.createRef<HTMLDivElement>();
  const timelineRef = React.createRef<HTMLDivElement>();
  const waveSurfer = useRef<WaveSurfer>();
  const region = useRef<any>();

  const forceRender = useForceRender();

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);
  const aggregatedKeyStartName = createKeyPath(parentKey, KEY_START);
  const aggregatedKeyEndName = createKeyPath(parentKey, KEY_END);

  const module = useContext(ModuleContext);

  const url = module.getItemUpdatedValue(aggregatedKeyName) as string;
  const moduleStart = (module.getItemValue(aggregatedKeyStartName) || 0) as number;
  const moduleStartUpdated = (module.getItemUpdatedValue(aggregatedKeyStartName) || 0) as number;
  const moduleEnd = (module.getItemValue(aggregatedKeyEndName) || 0) as number;
  const moduleEndUpdated = (module.getItemUpdatedValue(aggregatedKeyEndName) || 0) as number;

  useEffect(() => {
    if (waveformRef.current && url) {
      waveSurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        plugins: [
          WaveSurferRegions.create({}),
          WaveSurferTimeline.create({ container: timelineRef.current, height: 10 }),
        ],
      });

      waveSurfer.current.load(url);

      waveSurfer.current.on('ready', () => {
        if (waveSurfer.current) {
          region.current = waveSurfer.current.addRegion({
            start: moduleStartUpdated / 1000,
            end: moduleEndUpdated > 0 ? moduleEndUpdated / 1000 : waveSurfer.current.getDuration(),
            drag: false,
          });
          region.current.on('click', (event: any) => {
            if (waveSurfer.current) {
              const waveformRect = waveSurfer.current.container.getBoundingClientRect();
              const progress = (event.clientX - waveformRect.x) / waveformRect.width;
              waveSurfer.current.seekTo(progress);
            }
          });
        }
      });
    }

    return () => waveSurfer.current && waveSurfer.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isModalOpen, aggregatedKeyName]);

  useEffect(() => {
    if (waveSurfer.current) {
      waveSurfer.current.on('region-update-end', (event) => {
        const startMs = sToMs(Math.max(event.start, 0));
        module.updateItemValue(aggregatedKeyStartName, startMs);
        const endMs = sToMs(event.end);
        module.updateItemValue(aggregatedKeyEndName, endMs);
        forceRender();
      });
    }
  }, [props.isModalOpen, aggregatedKeyEndName, aggregatedKeyStartName, module, forceRender]);

  if (!url) {
    return null;
  }

  const handleTogglePlaybackClick = () => {
    waveSurfer.current && waveSurfer.current.playPause();
  };

  const handlePlayRegionEndClick = () => {
    waveSurfer.current &&
      waveSurfer.current.play(moduleEndUpdated / 1000 - REGION_END_PLAY_DURATION, moduleEndUpdated / 1000);
  };

  const handlePlayRegionClick = () => {
    region.current && region.current.play();
  };

  return (
    <Container $startChanged={moduleStartUpdated !== moduleStart} $endChanged={moduleEndUpdated !== moduleEnd}>
      <WaveformWrapper ref={waveformRef} />
      <Timeline ref={timelineRef} />
      <ButtonStyled onClick={handleTogglePlaybackClick} variant="contained" size="small">
        <PlayPause icon={faPlay} />/<PlayPause icon={faPause} />
      </ButtonStyled>
      <ButtonStyled onClick={handlePlayRegionClick} variant="contained" size="small">
        Play Region
      </ButtonStyled>
      <ButtonStyled onClick={handlePlayRegionEndClick} variant="contained" size="small">
        Play Region End
      </ButtonStyled>
      {props.children}
    </Container>
  );
};

const Container = styled.div<{ $startChanged: boolean; $endChanged: boolean }>`
  ${(props) =>
    props.$startChanged &&
    css`
      & .wavesurfer-handle-start {
        background-color: rgb(251 113 113) !important;
      }
    `}
  ${(props) =>
    props.$endChanged &&
    css`
      & .wavesurfer-handle-end {
        background-color: rgb(251 113 113) !important;
      }
    `}
`;

const WaveformWrapper = styled.div``;

const Timeline = styled.div`
  margin-bottom: 5px;
`;

export const ButtonStyled = styled(Button)`
  height: 30px;
  && {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

const PlayPause = styled(FontAwesomeIcon)`
  margin-left: 4px;
  margin-right: 4px;
`;

export default AudioWaveEditor;
