import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { useForceRender } from '../../../hooks/useForceRender';
import { ListDialects } from '../../../CMFW/layout/ListDialects';
import Label from '../../../CMFW/input/Label';
import CATModel from '../../../models/CATModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogSegmentModel from '../../../models/DialogSegmentModel';

export type IProps = {};

const CATContextDialog: React.FC<IProps> = () => {
  const forceRender = useForceRender();
  const module = useContext(ModuleContext) as CATModel;
  const dialogSegmentModel = useMemo(() => new DialogSegmentModel({ render: forceRender, courseId: module.courseId }), [
    forceRender,
    module.courseId,
  ]);

  const active = module.getActive();

  useEffect(() => {
    if (active) {
      dialogSegmentModel.loadItemEdit(active.itemId);
    }
  }, [dialogSegmentModel, active]);

  if (active === null) {
    return null;
  } else if (dialogSegmentModel.loadingItem) {
    return (
      <Wrapper>
        <CircularProgress size={10} />
      </Wrapper>
    );
  } else {
    return (
      <ModuleContext.Provider value={dialogSegmentModel}>
        <Wrapper>
          <Info>Read by: {dialogSegmentModel.getItemValue('readby')}</Info>
          <ListDialects keyName={'sources'} narrow>
            <Label keyName={'text'} />
          </ListDialects>
        </Wrapper>
      </ModuleContext.Provider>
    );
  }
};

export default CATContextDialog;

const Wrapper = styled.div`
  margin: 4px;
  font-size: 14px !important;
  white-space: pre-wrap;
`;

const Info = styled.p`
  margin: 0;
`;
