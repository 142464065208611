import React, { useContext, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { comparePosition } from '../../../CMFW/grid/comparators/comparePosition';
import { LoadingGrid } from '../../../CMFW/layout/LoadingGrid';
import { IRefIds } from '../../../constants/refs';
import { useForceRender } from '../../../hooks/useForceRender';
import PanelsModel from '../../../models/PanelsModel';
import { ILessonOutlineCard, ILessonOutlineCycleActivityPanel } from '../../../types/lessonOutline';
import { IGridData } from '../../../types/models';
import PanelSearch from '../../panels/PanelSearch';
import LessonOutlineActivityPanel from '../cards/activities/LessonOutlineActivityPanel';
import { WrapperActivity } from '../cards/LessonOutlineActivity';
import LessonOutlineContext from '../context/LessonOutlineContext';

type IProps = {
  courseId: number;
};

const LessonOutlineSearchPanel: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const panelModel = useMemo(() => new PanelsModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);
  const lessonOutlineContext = useContext(LessonOutlineContext);

  const gridData = panelModel.gridData.sort((a, b) => comparePosition(a.position, b.position));

  return (
    <Wrapper>
      <ModuleContext.Provider value={panelModel}>
        <PanelSearch />
        <Droppable
          isDropDisabled={true}
          droppableId={`${IRefIds.panels}-bank`}
          direction={'vertical'}
          type={ILessonOutlineCard.ACTIVITY}
        >
          {(providedDroppable) => (
            <Wrapper>
              <WrapperResult {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {gridData.map((panel: IGridData, index: number) => (
                  <Draggable
                    isDragDisabled={!lessonOutlineContext.DNDEnabled}
                    draggableId={`${IRefIds.panels}-${panel.id}`}
                    index={index}
                    key={`${IRefIds.panels}-${index}`}
                  >
                    {(provided) => (
                      <WrapperActivity
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        extraWide
                      >
                        <LessonOutlineActivityPanel activity={panel as ILessonOutlineCycleActivityPanel} />
                      </WrapperActivity>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </WrapperResult>
              <LoadingGrid />
            </Wrapper>
          )}
        </Droppable>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default LessonOutlineSearchPanel;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
`;

const WrapperResult = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
`;
