import TextInput from 'CMFW/input/TextInput';
import { ContainerLeft, EditorGap, EditorRow } from 'components/common/styles';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import React from 'react';
import styled from 'styled-components';
import ListIds from '../../../components/common/ListIds';
import InflectConfig from '../../../components/inflect/InflectConfig';
import { IRefIds } from '../../../constants/refs';
import PanelKlines from '../components/PanelKlines';
import PanelLevelIndicator from '../components/PanelLevelIndicator';
import PanelStopInflectPanel from '../components/PanelStopInflectPanel';

const Edit: React.FC = () => {
  return (
    <ContainerLeft>
      <PanelKlines />
      <EditorGap />
      <TextInput keyName={'concept'} multiline label={'Concept'} />
      <EditorGap />
      <ListIds keyName={'sentences'} refId={IRefIds.sentences} label={'Sentences'} />
      <EditorGap />
      <ListIds keyName={'segments'} refId={IRefIds.segment} label={'Segments'} />
      <EditorGap />
      <ListIds keyName={'compounds'} refId={IRefIds.compounds} label={'Compounds'} />
      <EditorGap />
      <EditorGap />
      <EditorRow>
        <PanelLevelIndicator keyName={'level'} />
        <WordClassSelect isLinked />
        <TextInputStyled keyName={'item'} label={'Item'} />
      </EditorRow>
      <EditorGap />
      <InflectConfig keyName={'inflects'} />
      <EditorGap />
      <PanelStopInflectPanel keyName={'inflectStopPanel'} />
    </ContainerLeft>
  );
};

const TextInputStyled = styled(TextInput)`
  && {
    margin: 2px 0;
  }
`;

export default Edit;
