import { IPosition } from '../types/content';
import { comparePosition } from '../CMFW/grid/comparators/comparePosition';

type IPositionTitle = { id: number; title: string; position: IPosition };

export const positionLessonToString = (positionLesson: number): string => {
  if (positionLesson === 1000) {
    return 'OUT';
  }

  return positionLesson.toString();
};

const positionCycleToString = (cyclePosition: number): string => {
  return String.fromCharCode(96 + cyclePosition).toUpperCase();
};

const positionActivityToString = (positionPanel: number): string => {
  return positionPanel.toString();
};

export const positionToString = (position: IPosition): string => {
  const pos = [];
  pos.push(positionLessonToString(position.lesson));
  if (typeof position.cycle !== 'undefined' && position.cycle !== 0) {
    pos.push(positionCycleToString(position.cycle));
  }
  if (typeof position.activity !== 'undefined' && position.activity !== 0) {
    pos.push(positionActivityToString(position.activity));
  }
  return pos.join('');
};

const sortPosition = (a: IPositionTitle, b: IPositionTitle): number => {
  return comparePosition(a.position, b.position);
};

export const addPositionTitle = (data: IPositionTitle[]) => {
  return data.sort(sortPosition).map((d) => ({ id: d.id, title: positionToString(d.position) + ' ' + d.title }));
};
