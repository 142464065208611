import { TextareaAutosizeProps } from '@material-ui/core';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { TextAreaStyled } from 'components/common/styles';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import { omit } from 'lodash';
import React, { useContext, useEffect } from 'react';

type IProps = TextareaAutosizeProps & {
  keyName: string;
};

const TextArea: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: string = module.getItemValue.bind(module)(aggregatedKeyName) ?? '';
  const moduleItemValueUpdated: string = module.getItemUpdatedValue(aggregatedKeyName) ?? '';

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, '');

  useEffect(() => {
    setCurrentValue(moduleItemValueUpdated);
  }, [moduleItemValueUpdated, setCurrentValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentValue(event.target.value);
    module.updateItemValue.bind(module)(aggregatedKeyName, event.target.value);
  };

  return (
    <TextAreaStyled
      value={currentValue}
      {...omit(props, ['keyName'])}
      $changed={moduleItemValue !== currentValue}
      onChange={onChange}
    />
  );
};

export default TextArea;
