import { RowNode } from '@ag-grid-enterprise/all-modules';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftHeader, EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import KLineSelect from '../../../components/selects/KlineSelect';
import CLUtilsPanelModel, { ICLUtilsPanel } from '../../../models/CLUtilsPanelModel';
import { TextFieldStyled } from '../../../components/common/SelectInput';
import UsesModal from '../modals/UsesModal';
import { IFields } from '../../../CMFW/grid/fields';
import { underlineRenderer } from '../../../CMFW/grid/cellRenderers/underline';
import PanelViewModal from '../modals/PanelViewModal';

export type IProps = {
  courseId: number;
  lessonPos: number;
};

let kline = 0;
let fText = '';

const CLUtilsPanel: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const [isPanelViewModalOpen, setIsPanelViewModalOpen] = useState(false);
  const [isUsesModalOpen, setIsUsesModalOpen] = useState(false);
  const [selectedKline, setSelectedKline] = useState(0);
  const [filterText, setFilterText] = useState('');
  kline = selectedKline;
  fText = filterText;

  const isFilterActive = () => {
    return kline !== 0 || fText !== '';
  };

  const moduleModel = useMemo(() => new CLUtilsPanelModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  useEffect(() => {
    if (props.lessonPos > 0) {
      moduleModel.getGrid({ lessonPos: props.lessonPos });
    }
  }, [moduleModel, props.lessonPos]);

  const handleKlineChanged = (id: number) => {
    setSelectedKline(id);
  };

  const handleFilterTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterText(event.target.value);
  };

  const isRowVisible = (node: RowNode) => {
    const data: ICLUtilsPanel = node.data;
    const klineMatches = kline === 0 || kline === -1 || data.kLineIds.indexOf(kline) !== -1;
    if (!klineMatches) {
      return false;
    }

    const regExp = new RegExp(fText, 'i');
    return (
      data.title.replace(/<[^>]*>/g, '').match(regExp) !== null ||
      data.concept.match(regExp) !== null ||
      data.id.toString() === fText
    );
  };

  const handleRowClick = (row: IGridData, selectedRows: IGridData[], columnField: string | undefined) => {
    moduleModel.setItemId(row.id);
    if (columnField === 'nUses' && (row as ICLUtilsPanel).nUses > 0) {
      setIsUsesModalOpen(true);
    } else {
      setIsPanelViewModalOpen(true);
    }
  };

  const handleUsesModalClose = () => {
    setIsUsesModalOpen(false);
  };

  const handlePanelViewModalClose = () => {
    setIsPanelViewModalOpen(false);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerLeft>
          <ContainerLeftHeader>
            <EditorRow>
              <KlineSelectStyled allOption={true} onChange={handleKlineChanged} />
              <TextFieldStyled variant="outlined" onChange={handleFilterTextChanged} placeholder="Filter" />
            </EditorRow>
          </ContainerLeftHeader>
          <Grid
            isFilterActive={isFilterActive}
            isRowVisible={isRowVisible}
            handleRowClick={handleRowClick}
            cellRenderers={{
              [IFields.nUses]: underlineRenderer,
            }}
          />
        </ContainerLeft>
        {isUsesModalOpen && <UsesModal onClose={handleUsesModalClose} />}
        {isPanelViewModalOpen && <PanelViewModal onClose={handlePanelViewModalClose} />}
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const KlineSelectStyled = styled(KLineSelect)`
  width: 100%;
`;

export default CLUtilsPanel;
