import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IQAResultPhrase } from './QAResultsModel';

export default class QAResultPhrasesModel extends BaseModel<IQAResultPhrase, {}> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'QAPhrases';
    this.getGridColumns();
  }

  resetPhrases() {
    this.gridData = [];
    this.render();
  }

  setPhrases(phrases: IQAResultPhrase[]) {
    this.gridData = [...phrases];
    this.render();
  }

  async getGridRow(itemId: number) {
    const found = this.gridData.find((data) => data.id === itemId);
    return found as IQAResultPhrase;
  }
}
