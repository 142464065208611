import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useMemo } from 'react';
import TtsVoiceSelectModel from '../../models/TtsVoiceSelectModel';
import { ITtsVoiceSelect } from '../../types/ttsLab';

const KEY = 'voice';

type IProps = {
  isLinked?: boolean;
  className?: string;
  onChange?: (name: string) => void;
  label?: string;
  filter?: string[];
};

const TtsVoiceSelect: React.FC<IProps> = (props) => {
  const { isLinked } = props;

  const module = useContext(ModuleContext);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);

  const moduleItemValue = (module.getItemValue.bind(module)(aggregatedKeyName) || '') as string;

  const [currentVoice, setCurrentVoice] = useCurrentItemValue(moduleItemValue, '', isLinked);
  const cacheKey = getCacheKey(IRefIds.ttsLabVoice);
  const dataModel = useMemo(() => new TtsVoiceSelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    const filter = props.filter || [];
    const data = cacheSelectData.filter((d) => filter.every((f) => d.name.includes(f)));

    return [...data];
  }, [cacheSelectData, props.filter]);

  const handleChange = (value: ITtsVoiceSelect) => {
    const selectedVoiceName = value.voice;
    setCurrentVoice(selectedVoiceName);
    module.updateItemValue.bind(module)(aggregatedKeyName, selectedVoiceName);
    props.onChange && props.onChange(value.voice);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label={props.label || 'Voice'}
      optionLabelKey="name"
      optionValueKey="voice"
      currentValue={currentVoice}
      originalValue={isLinked && moduleItemValue}
    />
  );
};

export default TtsVoiceSelect;
