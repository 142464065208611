import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IConjTemplate } from './ConjugationTemplateModel';

export type IConjugationTableSelect = {
  id: number;
  name: string;
};

export default class ConjugationTemplatesModel {
  ref = IRefIds.klines;
  courseId = 0;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    const templates: IConjugationTableSelect[] = (
      await get<IConjTemplate[]>(`courses/${this.courseId}/conj-template`)
    ).map((template) => ({ id: template.id, name: template.name }));
    return templates.sort((a, b) => a.name.localeCompare(b.name));
  }
}
