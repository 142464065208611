import React from 'react';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import axios from 'axios';

type IProps = {
  url: string;
  label: string;
};

const DownloadLink: React.FC<IProps> = ({ url, label }) => {
  const handleDownload = async () => {
    const response = await axios.get<{ fileName: string; fileData: string }>(url);
    const data = response.data;
    const linkUrl = window.URL.createObjectURL(new Blob([data.fileData]));
    const link = document.createElement('a');
    link.href = linkUrl;
    link.download = data.fileName || 'downloaded-file';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return <LinkStyled onClick={handleDownload}>{label}</LinkStyled>;
};

export default DownloadLink;

const LinkStyled = styled(Link)`
  && {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 20px;
  }
`;
