import React from 'react';
import { IConceptLinkerGuessedTooltip, IConceptLinkerTooltip } from 'types/conceptLinker';

export const initialValue = {
  notification: null as string | null,
  setNotification: (() => {}) as (message: string) => void,
  conceptLinkerTooltip: null as IConceptLinkerTooltip | null,
  setConceptLinkerTooltip: (() => {}) as (tooltip: IConceptLinkerTooltip | null) => void,
  conceptLinkerGuessedTooltip: null as IConceptLinkerGuessedTooltip | null,
  setConceptLinkerGuessedTooltip: (() => {}) as (tooltip: IConceptLinkerGuessedTooltip | null) => void,
};

export const AppContext = React.createContext<typeof initialValue>(initialValue);
