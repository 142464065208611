import Button from '@material-ui/core/Button';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';
import TextEditor from '../../../CMFW/input/TextEditor';

type IProps = {
  quoteSentence: string;
  onClose: () => void;
  onEditQuoteSentence: (quoteSentence: string) => void;
};

const CK_EDITOR_CONFIG = {
  toolbar: [['Undo', 'Redo']],
  toolBarInline: [['Bold', 'lang_target']],
};

const QuoteSentenceEditModal: React.FC<IProps> = (props) => {
  const [quoteSentence, setQuoteSentence] = useState(props.quoteSentence);

  const handleClose = () => {
    props.onClose();
  };

  const handleUpdate = () => {
    props.onEditQuoteSentence(quoteSentence.replace(/<(\/)*p[^>]*>/g, ''));
    props.onClose();
  };

  const onChange = (value: string) => {
    setQuoteSentence(value);
  };

  return (
    <ModalWrapper width={450} height={220} onClose={handleClose} title="Edit QuoteSentence">
      <TextEditor keyName={'none'} text={quoteSentence} config={CK_EDITOR_CONFIG} autoGrow onChangeText={onChange} />
      <Button variant="contained" size="small" color="primary" onClick={handleUpdate}>
        Update
      </Button>
    </ModalWrapper>
  );
};

export default QuoteSentenceEditModal;
