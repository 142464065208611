import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextEditor from 'CMFW/input/TextEditor';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import TableModel from '../../models/TableModel';
import PanelUses from '../../components/common/PanelUses';
import TextSearch from '../../components/search/TextSearch';

export type IProps = {
  courseId: number;
  search?: string;
};

const CK_EDITOR_CONFIG = {
  toolbar: [['Undo', 'Redo'], ['wtable']],
  toolBarInline: [['Bold', 'Italic']],
};

const Table: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const tableModel = useMemo(
    () =>
      new TableModel({
        render: forceRender,
        courseId: props.courseId,
        search: props.search,
      }),
    [props.courseId, props.search, forceRender],
  );
  return (
    <ModuleContainer>
      <ModuleContext.Provider value={tableModel}>
        <Split widthLeft={424}>
          <ContainerLeft>
            <TextSearch />
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'name'} />
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <TextEditor keyName={'html'} config={CK_EDITOR_CONFIG} autoGrow />
              <PanelUses />
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Table;
