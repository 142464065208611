import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { genderValues, getGenderIndex, getReadbyIndex, IGender, IReadby } from './gender';
import { SelectLayout } from './SelectLayout';

const KEY = 'gender';

type IProps = {
  readbyKey: string;
};

const GenderSelect: React.FC<IProps> = (props) => {
  const { readbyKey } = props;

  const module = useContext(ModuleContext);
  const moduleItemGender: string = module.getItemValue(KEY) || '';
  const moduleItemReadby: string = module.getItemValue(readbyKey) || '';

  const moduleItemGenderIndex = getGenderIndex(moduleItemGender);
  const moduleItemReadbyIndex = getReadbyIndex(genderValues[moduleItemGenderIndex], moduleItemReadby);

  const [currentGenderIndex, setCurrentGenderIndex] = useCurrentItemValue(moduleItemGenderIndex, moduleItemGenderIndex);
  const [currentReadbyIndex, setCurrentReadbyIndex] = useCurrentItemValue(moduleItemReadbyIndex, moduleItemReadbyIndex);

  const currentGender = genderValues[currentGenderIndex];
  const currentReadby = currentGender.readby[currentReadbyIndex];

  const handleGenderChange = (gender: IGender) => {
    const genderIndex = getGenderIndex(gender.value);
    setCurrentGenderIndex(genderIndex);
    module.updateItemValue.bind(module)(KEY, gender.value);

    setCurrentReadbyIndex(0);
    const newReadby = genderValues[genderIndex].readby[0].value;
    module.updateItemValue.bind(module)(readbyKey, newReadby);
  };

  const handleReadbyChange = (readby: IReadby) => {
    const readbyIndex = getReadbyIndex(currentGender, readby.value);
    setCurrentReadbyIndex(readbyIndex);
    module.updateItemValue.bind(module)(readbyKey, readby.value);
  };

  return (
    <>
      <SelectLayoutStyled
        data={genderValues}
        onChange={handleGenderChange}
        label="G:"
        optionLabelKey="label"
        optionValueKey="value"
        currentValue={currentGender.value}
        originalValue={moduleItemGender}
      />
      <SelectLayoutStyled
        data={currentGender.readby}
        onChange={handleReadbyChange}
        label="RB:"
        optionLabelKey="label"
        optionValueKey="value"
        currentValue={currentReadby.value}
        originalValue={moduleItemReadby}
        disabled={currentGender.readby.length <= 1}
      />
    </>
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 92px;
  flex: 1;
  flex-shrink: 0;
`;

export default GenderSelect;
