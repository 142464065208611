import ModuleContext from 'CMFW/context/ModuleContext';
import { comparePosition } from 'CMFW/grid/comparators/comparePosition';
import { LoadingGrid } from 'CMFW/layout/LoadingGrid';
import { IRefIds } from 'constants/refs';
import { useForceRender } from 'hooks/useForceRender';
import VocabularyModel from 'models/VocabularyModel';
import React, { useContext, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ILessonOutlineCard, ILessonOutlineCycleActivityVoc } from 'types/lessonOutline';
import { IGridData } from 'types/models';
import VocSearchBar from '../../vocabulary/VocSearchBar';
import LessonOutlineActivityVoc from '../cards/activities/LessonOutlineActivityVoc';
import { WrapperActivity } from '../cards/LessonOutlineActivity';
import LessonOutlineContext from '../context/LessonOutlineContext';

type IProps = {
  courseId: number;
};

const LessonOutlineSearchVoc: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const VocModel = useMemo(() => new VocabularyModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);
  const lessonOutlineContext = useContext(LessonOutlineContext);

  const gridData = VocModel.gridData.sort((a, b) => comparePosition(a.position, b.position));

  return (
    <Wrapper>
      <ModuleContext.Provider value={VocModel}>
        <VocSearchBar />
        <Droppable
          isDropDisabled={true}
          droppableId={`${IRefIds.vocs}-bank`}
          direction={'vertical'}
          type={ILessonOutlineCard.ACTIVITY}
        >
          {(providedDroppable) => (
            <Wrapper>
              <WrapperResult {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {gridData.map((voc: IGridData, index: number) => (
                  <Draggable
                    isDragDisabled={!lessonOutlineContext.DNDEnabled}
                    draggableId={`${IRefIds.vocs}-${voc.id}`}
                    index={index}
                    key={`${IRefIds.vocs}-${index}`}
                  >
                    {(provided) => (
                      <WrapperActivity
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        extraWide
                      >
                        <LessonOutlineActivityVoc activity={voc as ILessonOutlineCycleActivityVoc} />
                      </WrapperActivity>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </WrapperResult>
              <LoadingGrid />
            </Wrapper>
          )}
        </Droppable>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default LessonOutlineSearchVoc;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
`;

const WrapperResult = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
`;
