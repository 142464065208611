import { IGridData } from '../types/models';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IRefIds } from '../constants/refs';
import { put } from '../services/request';
import {
  generateXLSX,
  generateXLSXItems,
  IXLSXData,
  IXLSXImport,
  IXLSXItemsData,
} from '../modules/sourceAdaptation/utils/xlsx';

export type IQAResponseError = {
  conceptId: number;
  conceptTitle: string;
  conceptRef: number;
  conceptRefName: string;
  conceptRefParentName: string;
  conceptRefParent: number;
  conceptIdParent: number;
  conceptRefChild: number;
  conceptIdChildren: string;
  phrase: string;
  source: string;
  conceptSource: string;
  conceptTarget: string;
  verified: number;
};

export type IQAResponse = {
  id: number;
  ref: number;
  refName: string;
  parentId: number;
  finalParentId: number;
  finalParentRef: number;
  errors: IQAResponseError[];
};

export type IQAResultPhrase = {
  id: number;
  ref: number;
  refName: string;
  parentId: number;
  finalParentId: number;
  finalParentRef: number;
  conceptRefParentName: string;
  conceptRefParent: number;
  conceptIdParent: number;
  phrase: string;
  source: string;
  verified: number;
};

export interface IQAResult extends IGridData {
  conceptId: number;
  conceptRef: number;
  conceptRefName: string;
  conceptTitle: string;
  count: number;
  conceptSource: string;
  conceptTarget: string;
  conceptRefChild: number;
  conceptIdChildren: string;
  phrases: IQAResultPhrase[];
}

export default class QAResultsModel extends BaseModel<IQAResult, {}> {
  results: IQAResponse[] = [];
  showVerified = false;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'QAConcepts';
    this.getGridColumns();
    this.loadingGrid = false;
  }

  resetResult() {
    this.results = [];
    this.gridData = [];
    this.render();
  }

  setShowVerified(showVerified: boolean) {
    this.showVerified = showVerified;
    this.recalculateResults();
  }

  recalculateResults() {
    this.gridData = this.processRawResults(this.results);
    this.render();
  }

  addResult(results: IQAResponse[]) {
    this.results = [...this.results, ...results];
    this.gridData = this.processRawResults(this.results);
    this.render();
  }

  processRawResults(results: IQAResponse[]): IQAResult[] {
    let data: IQAResult[] = [];
    results.forEach((result) => {
      result.errors
        .filter((error) => error.verified === 0 || this.showVerified)
        .forEach((error) => {
          let foundDataGrid = data.find((d) => d.conceptId === error.conceptId && d.conceptRef === error.conceptRef);
          const found = foundDataGrid !== undefined;
          if (!foundDataGrid) {
            foundDataGrid = {
              id: data.length,
              conceptId: error.conceptId,
              conceptRef: error.conceptRef,
              conceptRefName: error.conceptRefName,
              conceptTitle: error.conceptTitle,
              count: 0,
              conceptSource: error.conceptSource,
              conceptTarget: error.conceptTarget,
              conceptIdChildren: error.conceptIdChildren,
              conceptRefChild: error.conceptRefChild,
              phrases: [],
            };
          }
          foundDataGrid.count++;
          foundDataGrid.phrases.push({
            id: foundDataGrid.phrases.length,
            ref: result.ref,
            refName: result.refName,
            parentId: result.parentId,
            finalParentId: result.finalParentId,
            finalParentRef: result.finalParentRef,
            phrase: error.phrase,
            source: error.source,
            verified: error.verified,
            conceptRefParentName: error.conceptRefParentName,
            conceptRefParent: error.conceptRefParent,
            conceptIdParent: error.conceptIdParent,
          });
          if (!found) {
            data = [...data, foundDataGrid];
          }
        });
    });
    return [...data];
  }

  async updateSource(ref: number, itemId: number, sourceDialectId: number, source: string) {
    this.gridData.forEach((grid) => {
      grid.phrases.forEach((phrase) => {
        if (phrase.parentId === itemId) {
          phrase.source = source;
        }
      });
    });

    this.results.forEach((result) => {
      if (result.parentId === itemId && result.ref === ref) {
        result.errors.forEach((error) => {
          error.source = source;
        });
      }
    });

    switch (ref) {
      case IRefIds.sentences:
      case IRefIds.dialogSegment:
        await put(`translations/refs/${ref}/refs/${ref}/items/${itemId}/dialects/${sourceDialectId}`, {
          text: source,
        });
        break;
      case IRefIds.items:
        await put(`items/${itemId}/source-dialect/${sourceDialectId}`, { text: source });
        break;
    }
  }

  async updateVerify(
    ref: number,
    id: number,
    conceptRef: number,
    conceptId: number,
    sourceDialect: number,
    status: number,
  ) {
    this.results.forEach((result) => {
      if (result.parentId === id && result.ref === ref) {
        result.errors.forEach((error) => {
          if (error.conceptId === conceptId && error.conceptRef === conceptRef) {
            error.verified = status;
          }
        });
      }
    });

    await put('QA/verify', { ref, id, conceptRef, conceptId, sourceDialect, status });
  }

  async downloadXLSXStep1(sourceDialect: number) {
    const data = this.processRawResults(this.results);
    const xlsData: IXLSXData[] = [];
    data.forEach((d) => {
      if (d.count > 1) {
        d.phrases.forEach((phrase) => {
          xlsData.push({
            id: `r${phrase.ref} d${sourceDialect} ${phrase.parentId}`,
            source: phrase.source,
            concepts: [
              {
                id: `#c r${phrase.conceptRefParent} d${sourceDialect} ${phrase.conceptIdParent}`,
                conceptSource: d.conceptSource,
                conceptTarget: d.conceptTarget,
                phraseTarget: phrase.phrase,
                verified: !!phrase.verified,
              },
            ],
          });
        });
      }
    });
    await generateXLSX(xlsData);
  }

  async downloadXLSXStep2(sourceDialect: number) {
    const xlsData: IXLSXData[] = [];
    this.results.forEach((phrase) => {
      const notVerifiedErrors = phrase.errors
        .filter((error) => error.verified === 0)
        .map((error) => ({
          id: `#c r${error.conceptRefParent} d${sourceDialect} ${error.conceptIdParent}`,
          conceptSource: error.conceptSource,
          conceptTarget: error.conceptTarget,
          phraseTarget: error.phrase,
          verified: !!error.verified,
        }));
      if (notVerifiedErrors.length > 0) {
        xlsData.push({
          id: `r${phrase.ref} d${sourceDialect} ${phrase.parentId}`,
          source: phrase.errors[0].source,
          concepts: notVerifiedErrors,
        });
      }
    });
    await generateXLSX(xlsData);
  }

  async downloadXLSXItems(sourceDialect: number) {
    const data = this.processRawResults(this.results);
    const xlsData: IXLSXItemsData[] = [];
    data.forEach((d) => {
      if (d.conceptRefChild === IRefIds.items) {
        xlsData.push({
          id: `#i r${d.conceptRefChild} d${sourceDialect} ${d.conceptIdChildren}`,
          conceptTarget: d.conceptTarget,
          conceptSource: d.conceptSource,
          phrases: d.phrases
            .filter((phrase) => phrase.verified === 0)
            .map((phrase) => ({
              source: phrase.source,
              target: phrase.phrase,
            }))
            .slice(0, 10),
        });
      }
    });
    await generateXLSXItems(xlsData.filter((d) => d.phrases.length > 0));
  }

  async updateSourceXLSX(source: IXLSXImport) {
    if (source.ids.length === 1) {
      await this.updateSource(source.ref, source.ids[0], source.sourceDialect, source.source);
      await Promise.all(
        source.verified.map((v) => this.updateVerify(source.ref, source.ids[0], v.ref, v.id, source.sourceDialect, 1)),
      );
    }
  }
}
