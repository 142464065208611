import BoardsModel, { IBoardCard } from 'models/BoardsModel';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { renderCard } from './cardRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { tabsOpened } from '../../store/actions/tabs';
import ModuleContext from '../../CMFW/context/ModuleContext';
import { useDispatch } from 'react-redux';

type IProps = {
  title: string;
  cards: IBoardCard[];
  openIcon?: boolean;
};

const Column: React.FC<IProps> = (props) => {
  const { title, cards, children } = props;
  const boardsModel = useContext(ModuleContext) as BoardsModel;
  const dispatch = useDispatch();

  const handleRefClick = () => {
    dispatch(
      tabsOpened({
        refId: props.cards[0].cardRef,
        search: props.cards
          .map((card) => card.cardRefId)
          .join(',')
          .toString(),
        courseId: boardsModel.courseId,
      }),
    );
  };

  if (!cards) {
    return null;
  }

  return (
    <Container>
      <ColumnHeader>
        <ColumnTitle>{title}</ColumnTitle>
        {props.openIcon && cards.length > 0 && <RefButton icon={faExternalLinkSquareAlt} onClick={handleRefClick} />}
      </ColumnHeader>
      {cards.map(renderCard)}
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
  overflow: auto;
  padding-bottom: 50px;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ColumnTitle = styled.span`
  font-weight: bold;
  text-align: center;
`;

const RefButton = styled(FontAwesomeIcon)`
  color: cornflowerblue;
  cursor: pointer;
  margin-left: 10px;
`;

export default Column;
