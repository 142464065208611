import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftHeader, EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ConjugationReportModel from '../../../models/ConjugationReportModel';
import DialectSelect, { IDialectOption } from '../../../components/selects/DialectSelect';

export type IProps = {
  courseId: number;
};

const ConjReport: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const [selectedDialect, setSelectedDialect] = useState(0);
  const [selectedDialectLoad, setSelectedDialectLoad] = useState(0);

  const moduleModel = useMemo(() => new ConjugationReportModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  useEffect(() => {
    moduleModel.loadTarget(selectedDialectLoad);
  }, [moduleModel, selectedDialectLoad]);

  const handleDialectChanged = (dialect: IDialectOption) => {
    setSelectedDialect(dialect.dialectId);
  };

  const handleLoad = () => {
    setSelectedDialectLoad(selectedDialect);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerLeft>
          <ContainerLeftHeader>
            <EditorRowStart>
              <DialectSelect onlyTarget handleDialectChange={handleDialectChanged} />
              <Button disabled={selectedDialect === 0} variant="contained" size="small" onClick={handleLoad}>
                load
              </Button>
            </EditorRowStart>
          </ContainerLeftHeader>
          <Grid />
        </ContainerLeft>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default ConjReport;

const EditorRowStart = styled(EditorRow)`
  justify-content: flex-start;
`;
