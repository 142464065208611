import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftHeader,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Translations from '../../../components/translation/Translations';
import TranslationModel from '../../../models/TranslationModel';
import SearchBarDialectRef from '../../../components/search/SearchBarDialectRef';
import ListIdsBlind from '../../../components/common/ListIdsBlind';
import Button from '@material-ui/core/Button';
import AIReviewPanel from '../../AIReview/components/AIReviewPanel';

export type IProps = {
  courseId: number;
  search?: string;
};

const Translation: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(
    () => new TranslationModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <SearchBarDialectRef onlyCourseId={props.courseId} />
            </ContainerLeftHeader>
            <Grid />
            <EditorRow>
              <Button variant="outlined" size="small" onClick={model.checkTranslations.bind(model)}>
                check
              </Button>
            </EditorRow>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Container>
                <EditorRow>
                  <ListIdsBlind
                    courseId={props.courseId}
                    refId={model.getItemValue('openRef')}
                    ids={[model.getItemValue('openItemId')]}
                    label={model.getItemValue('openRefName')}
                  />
                  <EditorGap />
                  <SaveButton />
                </EditorRow>
                <Scroll>
                  <AIReviewPanel />
                  <Translations keyName={'dialects'} refId={model.getItemValue('ref')} />
                </Scroll>
              </Container>
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Translation;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
`;
