import {
  faArrowRight,
  faCheck,
  faComment,
  faExternalLinkSquareAlt,
  faUser,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import BoardsModel, { IBoardCard } from 'models/BoardsModel';
import CommentsModal from 'modules/boards/modal/CommentsModal';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import styled, { css } from 'styled-components';
import { IState } from '../../models/StateModel';

type IProps = {
  card: IBoardCard;
};

const Card: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const boardsModel = useContext(ModuleContext) as BoardsModel;

  const handleRefClick = () => {
    dispatch(
      tabsOpened({
        refId: props.card.cardRef,
        search: props.card.cardRefId.toString(),
        courseId: boardsModel.courseId,
      }),
    );
  };

  const handleCommentsClick = () => {
    setIsCommentModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalOpen(false);
  };

  const canBeMoved = (): boolean => props.card.state === IState.TODO || props.card.state === IState.DOING;
  const isDone = (): boolean => props.card.state === IState.DONE;

  const handleMoveClick = async () => {
    if (!loading && canBeMoved()) {
      setLoading(true);
      await boardsModel.moveCard(props.card);
      setLoading(false);
    }
  };

  const renderModals = () => {
    if (isCommentModalOpen) {
      return <CommentsModal onClose={handleCommentModalClose} card={props.card} />;
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Cell1>
            <Title dangerouslySetInnerHTML={{ __html: props.card.title }} />
            {props.card.freelanceId !== 0 && (
              <FreelanceName>
                <FreelanceIcon icon={faUser} />
                {props.card.freelanceName}
              </FreelanceName>
            )}
            {props.card.tags.map((tag) => (
              <Tag key={tag.tag} $tagClass={tag.class}>
                {tag.tag}
              </Tag>
            ))}
          </Cell1>
          <Cell2>
            <RefButton icon={faExternalLinkSquareAlt} onClick={handleRefClick} />
            <Comments onClick={handleCommentsClick} $newComments={props.card.newComments} >
              <CommentIcon icon={faComment} />
              {props.card.nComments}
            </Comments>
          </Cell2>
          <Cell3 onClick={handleMoveClick} $isEnabled={canBeMoved()}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <NextButton icon={canBeMoved() ? faArrowRight : isDone() ? faCheck : faFileAlt} />
            )}
          </Cell3>
        </Row>
      </Container>
      {renderModals()}
    </>
  );
};

const Container = styled.div`
  width: 300px;
  margin: 8px auto;
  border-radius: 4px;
  background-color: #fff;
  white-space: normal;
  border: 1px solid #e5e6e9;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
`;

const Cell1 = styled.div`
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;

const Cell2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Cell3 = styled.div<{ $isEnabled: boolean }>`
  display: flex;
  width: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: ${(props) => (props.$isEnabled ? 'pointer' : 'default')};
`;

const Title = styled.div`
  margin: 10px;
  font-size: 16px;
`;

const FreelanceName = styled.div`
  display: inline-block;
  margin: 6px 10px;
  color: #8a36de;
  font-size: 16px;
`;

const FreelanceIcon = styled(FontAwesomeIcon)`
  padding-right: 5px;
`;

const Comments = styled.div<{ $newComments: boolean }>`
  color: ${(props) => (props.$newComments ? '#dc5454' : '#aaaaaa')};
  display: block;
  margin-top: 13px;
  cursor: pointer;
`;

const CommentIcon = styled(FontAwesomeIcon)`
  padding-right: 3px;
`;

const RefButton = styled(FontAwesomeIcon)`
  color: cornflowerblue;
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;

const NextButton = styled(FontAwesomeIcon)`
  line-height: 65px;
  color: green;
  font-size: 20px;
`;

const Tag = styled.div<{ $tagClass: string }>`
  display: inline-block;
  margin: 2px 4px;
  color: white;
  padding: 1px 6px;
  font-size: 12px;
  border-radius: 4px;
  ${(props) => {
    switch (props.$tagClass) {
      case 'tag_green':
        return css`
          background-color: #009c00;
          margin-top: 4px;
          margin-bottom: 4px;
        `;
      case 'tag_orange':
        return css`
          background-color: darkorange;
        `;
      case 'tag_gray':
        return css`
          background-color: #aeaeae;
        `;
    }
  }}
`;

export default Card;
