export function noteRenderer(param: { value: number }) {
  const { value } = param;

  if (value === 0) {
    return '';
  }

  if (value === 1) {
    return "<span class='fa fa-sticky-note grey'></span>";
  }

  if (value === 2) {
    return "<span class='fa fa-sticky-note orange'></span>";
  }

  return '';
}
