import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';
import styled from 'styled-components';

type IProps = {
  keyName?: string;
};

const DEFAULT_VALUE = '';

const ErrorDisplay: React.FC<IProps> = ({ keyName = 'error' }) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: string = module.getItemValue.bind(module)(aggregatedKeyName) || DEFAULT_VALUE;

  if (moduleItemValue === '') {
    return null;
  } else {
    return <Error dangerouslySetInnerHTML={{ __html: moduleItemValue }} />;
  }
};

const Error = styled.div`
  background-color: #ffdede;
  padding: 5px 10px;
  border-radius: 4px;
  color: #780000;
`;

export default ErrorDisplay;
