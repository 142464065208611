import { IRefIds } from '../constants/refs';
import { del, get, post, put } from '../services/request';
import { INote, INoteId } from '../types/notes';
import { IModel } from '../types/models';

export default class NoteModel {
  ref: IRefIds;
  itemId: number;
  notes: INote[];
  loading: boolean;
  render: Function;
  parentModule: IModel;

  constructor(render: Function, parentModule: IModel, ref: IRefIds, itemId: number) {
    this.render = render;
    this.ref = ref;
    this.itemId = itemId;
    this.parentModule = parentModule;
    this.notes = [];
    this.loading = false;
    if (this.itemId !== -1) {
      this.fetchNotes();
    }
  }

  getNotes(): INote[] {
    return this.notes;
  }

  isLoading() {
    return this.loading;
  }

  updateGridRow() {
    this.parentModule.updateGridRow.bind(this.parentModule)(this.itemId);
  }

  async fetchNotes() {
    this.loading = true;
    this.render();
    this.notes = await get<INote[]>(`ref/${this.ref}/refId/${this.itemId}/notes`);
    this.loading = false;
    this.render();
  }

  async closeNote(noteId: number) {
    this.loading = true;
    this.render();
    await put(`note/${noteId}`, { status: 'CLOSED' });
    await this.fetchNotes();
    this.render();
    this.updateGridRow();
  }

  async deleteNote(noteId: number) {
    this.loading = true;
    this.render();
    await del(`note/${noteId}`);
    await this.fetchNotes();
    this.render();
    this.updateGridRow();
  }

  async saveNote(note: string) {
    this.loading = true;
    this.render();
    await post<INoteId>(`ref/${this.ref}/refId/${this.itemId}/notes`, {
      note,
    });
    await this.fetchNotes();
    this.render();
    this.updateGridRow();
  }
}
