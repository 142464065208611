import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import ConjTemplate from './tabs/ConjTemplate';
import ConjRules from './tabs/ConjRules';
import ConjBatch from './tabs/ConjBatch';
import ConjReport from './tabs/ConjReport';

export type IProps = {
  courseId: number;
  search?: string;
};

const Conjugation: React.FC<IProps> = (props) => {
  return (
    <ModuleContainer>
      <ItemTabs tabNames={['Templates', 'Rules', 'Batch Conjugate', 'Voc Report']}>
        <ConjTemplate courseId={props.courseId} search={props.search} />
        <ConjRules courseId={props.courseId} />
        <ConjBatch courseId={props.courseId} />
        <ConjReport courseId={props.courseId} />
      </ItemTabs>
    </ModuleContainer>
  );
};

export default Conjugation;
