import { IColumns } from 'CMFW/types/grid';
import { get } from 'services/request';
import { IPosition } from 'types/content';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IRefIds } from '../constants/refs';

export type IConceptUse = {
  id: number;
  position: IPosition;
  refName: string;
  ref: number;
  refId: number;
  target: string;
  courseId: number;
};

export default class ConceptUseModel extends BaseModel<IConceptUse, {}> {
  courseId: number;
  targetDialectId: number;
  ref: number;
  refId: number;
  gridColumns: IColumns = [];
  loadingGrid = false;

  constructor(options: IModelCourseOptions & { targetDialectId: number; ref: IRefIds; refId: number }) {
    super(options);
    this.render = options.render;
    this.courseId = options.courseId;
    this.targetDialectId = options.targetDialectId;
    this.ref = options.ref;
    this.refId = options.refId;
    this.getColumns(); //load on constructor to have auto-height (ga grid bug?)
  }

  async getUses(sourceDialect: number) {
    this.loadingGrid = true;
    this.render();
    this.gridData = await get<IConceptUse[]>(
      `concepts-linker/course/${this.courseId}/ref/${this.ref}/refId/${this.refId}/targetDialect/${this.targetDialectId}/sourceDialect/${sourceDialect}/concept-uses`,
    );
    this.loadingGrid = false;
    this.render();
  }

  async getColumns() {
    if (this.getColumns.length === 0) {
      this.gridColumns = await get<IColumns>(`grid-columns/ConceptUses`);
    }
  }
}
