import { IRefIds } from 'constants/refs';
import * as lodash from 'lodash';
import { get } from 'services/request';
import { IGridData, IModel, IModelUpdateRow, IModelUpdateRowTypes } from 'types/models';
import { IColumns } from '../CMFW/types/grid';

export type IModelOptions = {
  render: () => void;
  search?: string;
};

export type IModelCourseOptions = IModelOptions & {
  courseId: number;
};

export type IModuleSearch = {
  text?: string;
};

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export class BaseModel<IModelItem extends IGridData = IGridData, IModelItemEdit = {}> implements IModel {
  readonly NEW_CREATE_ID = -1;

  courseId = 0;
  ref = IRefIds.NONE;
  gridColumns = [] as IColumns;
  gridData = [] as IModelItem[];
  gridDataUpdateRow: IModelUpdateRow = { type: 'none', data: null };
  gridName = '';
  render = () => {};
  loadingGrid = false;
  loadingItem = false;
  private item?: IModelItemEdit;
  itemUpdated: any;
  itemUniqueId = new Date().getTime();
  itemId = this.NEW_CREATE_ID;

  constructor(options: Optional<IModelCourseOptions, 'courseId'>) {
    this.render = options.render;

    if (options.courseId !== undefined) {
      this.courseId = options.courseId;
    }

    if (options.search) {
      const id = parseInt(options.search);
      if (!isNaN(id)) {
        this.loadItemEdit(id);
        this.search({ text: options.search });
      } else {
        this.search({ text: options.search });
      }
    }
  }

  async getGrid(data?: IModuleSearch) {
    this.cancelGetGrid();
    this.loadingGrid = true;
    this.render();
  }

  cancelGetGrid() {} //overwritten in get request

  addRow(row: IModelItem) {
    this.gridData.push(row);
    this.updateGridRow(row.id, 'add');
  }

  async getGridRow(itemId: number) {
    return { id: itemId };
  }

  async updateGridRow(itemId: number, type: IModelUpdateRowTypes = 'update') {
    let data: IGridData | null = null;
    if (type === 'add' || type === 'update') {
      data = await this.getGridRow(itemId);
    }
    if (type === 'remove') {
      data = { id: itemId };
    }
    this.gridDataUpdateRow = { type, data };
    this.render();
  }

  getRef() {
    return this.ref;
  }

  getItemId() {
    return this.itemId;
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}`;
  }

  async getGridColumns() {
    this.gridColumns = await get<IColumns>(this.createGridColumnsEndpoint());
    this.render();
  }

  async reload() {
    this.loadingGrid = true;
    this.render();
  }

  async reloadItem() {
    await Promise.all([this.updateGridRow(this.itemId, 'update'), this.loadItemEdit(this.itemId)]);
  }

  async load() {}

  async new() {
    this.loadingItem = true;
    this.itemId = this.NEW_CREATE_ID;
    this.render();
    await new Promise((resolve) => setTimeout(resolve, 0));
    this.itemUniqueId = new Date().getTime();
  }

  async save() {
    this.loadingItem = true;
    this.render();
  }

  async delete() {
    this.loadingItem = true;
    this.render();
  }

  async loadItemEdit(itemId: number) {
    this.loadingItem = true;
    this.itemId = itemId;
    this.render();
    await new Promise((resolve) => setTimeout(resolve, 0));
    this.itemUniqueId = new Date().getTime();
  }

  async setItem(item: IModelItemEdit) {
    this.itemUniqueId = new Date().getTime();
    this.loadingItem = false;
    this.item = item;
    this.itemUpdated = lodash.cloneDeep(item);
  }

  getItemValue(keyName: string) {
    return lodash.get(this.item, keyName);
  }

  getItemUpdatedValue(keyName: string, defaultValue?: any) {
    if (this.itemUpdated && keyName === '') {
      return this.itemUpdated || defaultValue;
    }
    return lodash.get(this.itemUpdated, keyName, defaultValue);
  }

  updateItemValue(keyName: string, value: any) {
    lodash.set((this.itemUpdated as unknown) as object, keyName, value);
  }

  getItemUniqueId(): number {
    return this.itemUniqueId;
  }

  hide() {
    this.itemUpdated = null;
  }

  async search(data: IModuleSearch) {}
}
