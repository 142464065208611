import { del, get, post, put } from 'services/request';
import { IItemId } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions } from './BaseModel';

type IConjRule = IItemId & {
  name: string;
  wordClass: string;
  order: number;
};

type IConjRuleEdit = IItemId & {
  name: string;
  wordClass: number;
  order: number;
  gender: string;
  endingToApply: string;
  endingToRemove: string;
  deletable: boolean;
};

type IModelCourseLanguageOptions = IModelCourseOptions & {
  languageId: number;
};

export default class ConjugationRuleModel extends BaseModel<IConjRule, IConjRuleEdit> {
  readonly DIFFERENT_NEW_ID = -1;
  languageId: number;

  constructor(options: IModelCourseLanguageOptions) {
    super(options);
    this.languageId = options.languageId;
    this.ref = IRefIds.conjugationRule;
    this.gridName = 'ConjugationRule';
    this.getGridColumns();
  }

  //overwritten in ConjRuleEndings
  async onSave(ruleId: number) {}

  getGridData() {
    return get<IConjRule[]>(`languages/${this.languageId}/conj-rules`);
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = (await this.getGridData()).sort((a, b) => {
      if (a.wordClass === b.wordClass) {
        return a.order - b.order;
      } else {
        return a.wordClass.localeCompare(b.wordClass);
      }
    });
    this.loadingGrid = false;

    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IConjRule>(`languages/${this.languageId}/conj-rules/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<IConjRuleEdit>(`conj-rules/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.DIFFERENT_NEW_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const { id } = this.itemUpdated;
    await put(`conj-rules/${id}`, this.itemUpdated);
    await this.onSave(id);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<IItemId>(`languages/${this.languageId}/conj-rules`, {
      ...this.itemUpdated,
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`conj-rules/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<IConjRuleEdit>(`conj-rules/${itemId}`);
    await this.setItem(item);
    this.render();
  }
}
