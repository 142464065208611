import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import Checkbox from 'CMFW/input/Checkbox';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { IUserCoursePermissionEdit } from 'types/users';

type IProps = {
  keyName: string;
};

export const AdminCoursePermissions: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: IUserCoursePermissionEdit[] = module.getItemValue(aggregatedKeyName) || [];

  return (
    <Wrapper>
      {moduleItemValue.map((value: IUserCoursePermissionEdit, index) => (
        <KeyContext.Provider value={createKeyPath(parentKey, props.keyName, index)} key={value.id}>
          <CheckboxStyled keyName={'granted'} label={value.name} />
        </KeyContext.Provider>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px;
`;

const CheckboxStyled = styled(Checkbox)`
  && {
    display: inline-block;
    width: 200px;
  }
`;
