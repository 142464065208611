import { EditorRow, TextFieldStyled } from 'components/common/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import AudioSetsModel from '../../../models/AudioSetsModel';
import AudioSetsItemModel, { IAudioSetsItem } from '../../../models/AudioSetsItemModel';
import { faAngleLeft, faAngleRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { Loading } from '../../../components/common/Loading';
import WaveSurfer from 'wavesurfer.js';

type IProps = {
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
  item: IAudioSetsItem;
  reviewEnabled: boolean;
  index: number;
  total: number;
  modelItems: AudioSetsItemModel;
};

const AudioReviewItem: React.FC<IProps> = (props) => {
  const [note, setNote] = useState(props.item.note);
  const [message, setMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const waveSurfer = useRef<WaveSurfer>();

  const moduleModel = useContext(ModuleContext) as AudioSetsModel;

  const initWave = useCallback(
    (node) => {
      if (node) {
        if (!waveSurfer.current) {
          waveSurfer.current = WaveSurfer.create({
            container: node,
            plugins: [],
          });
          waveSurfer.current.on('ready', () => {
            if (waveSurfer.current) {
              waveSurfer.current.play();
            }
          });
        }

        waveSurfer.current && waveSurfer.current.load(props.item.audioPath + '?dc=' + new Date().getTime());
      }
    },
    [props.item.audioPath],
  );

  useEffect(() => {
    return () => waveSurfer.current && waveSurfer.current.destroy();
  }, []);

  useEffect(() => {
    setNote(props.item.note);
  }, [props.item.id, props.item.note]);

  const reviewItem = async (ok: boolean) => {
    setSaving(true);
    waveSurfer.current && waveSurfer.current.stop();
    const result = await moduleModel.reviewItem(props.item.id, ok, note);
    if (result && result.allReviewed) {
      if (result.newSetId === 0) {
        setMessage('Revised Set');
      } else {
        setMessage('A revision set has been created: ' + result.newSetName);
        await moduleModel.updateGridRow(result.newSetId, 'add');
      }
    } else {
      props.onNext();
    }
    setSaving(false);
    await props.modelItems.updateGridRow(props.item.id);
  };

  const handleOK = async () => {
    await reviewItem(true);
  };

  const handleBad = async () => {
    await reviewItem(false);
  };

  const handlePlay = () => {
    waveSurfer.current && waveSurfer.current.playPause();
  };

  const onNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Review'} width={720} height={380}>
      {message !== '' ? (
        <p>{message}</p>
      ) : (
        <>
          <Container>
            <WaveformWrapper ref={initWave} />
            <ItemText dangerouslySetInnerHTML={{ __html: props.item.text }} />
            <FontAwesomeIconPlay icon={faPlay} onClick={handlePlay} size={'2x'} />
          </Container>
          {props.reviewEnabled && (
            <EditorRow>
              <TextFieldStyled value={note} label={'Note'} variant="outlined" onChange={onNoteChange} />
              <ButtonStyled
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleBad}
                disabled={note.length === 0}
              >
                Bad
              </ButtonStyled>
              <ButtonStyled variant="contained" size="small" color="primary" onClick={handleOK}>
                Good
              </ButtonStyled>
            </EditorRow>
          )}
          <EditorRowProgress>
            <FontAwesomeIconProgress icon={faAngleLeft} onClick={props.onPrev} />
            {props.index + 1}/{props.total}
            <FontAwesomeIconProgress icon={faAngleRight} onClick={props.onNext} />
          </EditorRowProgress>
          {saving && <Loading />}
        </>
      )}
    </ModalWrapper>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const EditorRowProgress = styled(EditorRow)`
  justify-content: flex-end;
  margin: 10px;
`;

const FontAwesomeIconProgress = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
`;

const FontAwesomeIconPlay = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 5px auto;
`;

const ButtonStyled = styled(Button)`
  && {
    margin: 10px;
  }
`;

const WaveformWrapper = styled.div``;

const ItemText = styled.p`
  text-align: center;
`;

export default AudioReviewItem;
