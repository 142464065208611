import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useState } from 'react';
import TextSearch from '../../components/search/TextSearch';
import KLineSelect from '../../components/selects/KlineSelect';

type IProps = {};

const PanelSearch: React.FC<IProps> = (props) => {
  const [keyKLine, setKeyKLine] = useState(new Date().getTime());
  const [keyText, setKeyText] = useState(new Date().getTime());
  const module = useContext(ModuleContext);
  const onChangeKLine = (kLineId: number) => {
    if (kLineId >= 0) {
      module.search({ kLineId });
    } else {
      module.search({});
    }
    setKeyText(new Date().getTime());
  };

  const onSearchText = () => {
    setKeyKLine(new Date().getTime());
  };

  return (
    <>
      <EditorRow>
        <KLineSelect onChange={onChangeKLine} allOption={true} key={keyKLine} />
      </EditorRow>
      <TextSearch key={keyText} onSearch={onSearchText} />
    </>
  );
};

export default PanelSearch;
