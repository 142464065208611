import { BaseModel, IModelCourseOptions } from 'models/BaseModel';
import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export interface IAudioDashboardGrid {
  id: number;
  ref: number;
  name: string;
  pending: number;
  doing: number;
  pendingReview: number;
  done: number;
  donePercent: string;
}

interface IAudioDashboard {
  dashboard: IAudioDashboardGrid[];
}

export default class AudioDashboardModel extends BaseModel {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.audio;
    this.gridName = 'AudioDashboard';
    this.getGridColumns();
  }
  async getDashboard(targetDialect: number, startLesson: number, endLesson: number) {
    this.loadingGrid = true;
    this.render();
    const response = await get<IAudioDashboard>(
      `/courses/${this.courseId}/target-dialects/${targetDialect}/audio-dashboard`,
      {
        startLesson,
        endLesson,
      },
    );
    this.gridData = response.dashboard;
    this.loadingGrid = false;
    this.render();
  }
}
