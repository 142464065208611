import { Button, FormControlLabel, Switch } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftHeader,
  ContainerRight,
  EditorRow,
  ModuleContainer,
  TextFieldStyled,
} from 'components/common/styles';
import DialectSelect, { IDialectOption } from 'components/selects/DialectSelect';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import SourceAdaptationQARef from '../components/SourceAdaptationQARef';
import QAModel, { ISourceAdaptationQARef } from '../../../models/QAModel';
import QAResultsModel, { IQAResult, IQAResultPhrase } from '../../../models/QAResultsModel';
import Grid from '../../../CMFW/grid/Grid';
import { Split } from '../../../components/Split';
import { IGridData } from '../../../types/models';
import { tabsOpened } from '../../../store/actions/tabs';
import { useDispatch } from 'react-redux';
import QAResultPhrasesModel from '../../../models/QAResultPhrasesModel';
import { CellValueChangedEvent, RowNode } from '@ag-grid-enterprise/all-modules';
import QAItemsRelatedModel, { IQARelatedItem } from '../../../models/QAItemsRelatedModel';
import { IFields } from '../../../CMFW/grid/fields';
import { booleanRenderer } from '../../../CMFW/grid/cellRenderers/boolean';
import { checkboxRenderer } from '../../../CMFW/grid/cellRenderers/checkboxRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import SourceAdaptationQAUploadModal from '../modals/SourceAdaptationQAUploadModal';

export type IProps = {
  courseId: number;
};

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

let fText = '';
const SourceAdaptationQA: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const dispatch = useDispatch();

  const moduleModel = useMemo(() => new QAModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const resultPhrasesModel = useMemo(
    () => new QAResultPhrasesModel({ render: forceRender, courseId: props.courseId }),
    [forceRender, props.courseId],
  );

  const resultsModel = useMemo(
    () =>
      new QAResultsModel({
        render: forceRender,
        courseId: props.courseId,
      }),
    [forceRender, props.courseId],
  );

  const relatedItemsModel = useMemo(
    () =>
      new QAItemsRelatedModel({
        render: forceRender,
        courseId: props.courseId,
      }),
    [forceRender, props.courseId],
  );

  const [startPos, setStartPos] = useState(DEFAULT_START_POS);
  const [startPosLoad, setStartPosLoad] = useState(DEFAULT_START_POS);
  const [endPos, setEndPos] = useState(DEFAULT_END_POS);
  const [endPosLoad, setEndPosLoad] = useState(DEFAULT_END_POS);
  const [sourceDialectId, setSourceDialectId] = useState(null as number | null);
  const [sourceDialectLoadId, setSourceDialectLoadId] = useState(null as number | null);
  const [filterText, setFilterText] = useState('');

  fText = filterText;

  const [showVerified, setShowVerified] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue < DEFAULT_START_POS) {
      newValue = DEFAULT_START_POS;
    }
    setStartPos(newValue);
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue > DEFAULT_END_POS) {
      newValue = DEFAULT_END_POS;
    }
    setEndPos(newValue);
  };

  const handleSourceDialectChange = (dialect: IDialectOption) => {
    setSourceDialectId(dialect.dialectId);
  };

  const handleLoadClick = () => {
    setStartPosLoad(startPos);
    setEndPosLoad(endPos);
    setSourceDialectLoadId(sourceDialectId);
    moduleModel.loadItemEdit();
    resultPhrasesModel.resetPhrases();
    resultsModel.resetResult();
  };

  const handleRowConceptsClick = (data: IGridData, selectedRows: IGridData[], columnField: string | undefined) => {
    const use = data as IQAResult;
    if (columnField === 'conceptId') {
      dispatch(tabsOpened({ courseId: props.courseId, refId: use.conceptRef, search: use.conceptId.toString() }));
    } else {
      resultPhrasesModel.setPhrases(use.phrases);
      if (sourceDialectLoadId) {
        relatedItemsModel.loadRelatedItems(use.conceptRef, sourceDialectLoadId, use.conceptId);
      }
    }
  };

  const handleRowPhrasesClick = (data: IGridData, selectedRows: IGridData[], columnField: string | undefined) => {
    const use = data as IQAResultPhrase;
    console.log(use);
    if (columnField === 'finalParentId') {
      dispatch(
        tabsOpened({ courseId: props.courseId, refId: use.finalParentRef, search: use.finalParentId.toString() }),
      );
    }
    if (columnField === 'verified') {
      use.verified = use.verified === 0 ? 1 : 0;
      resultPhrasesModel.updateGridRow(use.id, 'update');
      if (sourceDialectLoadId) {
        resultsModel.updateVerify(
          use.ref,
          use.parentId,
          use.conceptRefParent,
          use.conceptIdParent,
          sourceDialectLoadId,
          use.verified,
        );
      }
    }
  };

  const handleRowRelatedItemsClick = (data: IGridData, selectedRows: IGridData[], columnField: string | undefined) => {
    const use = data as IQARelatedItem;
    if (columnField === 'itemId') {
      dispatch(tabsOpened({ courseId: props.courseId, refId: use.ref, search: use.itemId.toString() }));
    }
  };

  const handleDownloadXLS1Click = async () => {
    if (sourceDialectLoadId) {
      await resultsModel.downloadXLSXStep1(sourceDialectLoadId);
    }
  };

  const handleDownloadXLS2Click = async () => {
    if (sourceDialectLoadId) {
      await resultsModel.downloadXLSXStep2(sourceDialectLoadId);
    }
  };

  const handleDownloadXLSItemsClick = async () => {
    if (sourceDialectLoadId) {
      await resultsModel.downloadXLSXItems(sourceDialectLoadId);
    }
  };

  const onCellValueChanged = async (event: CellValueChangedEvent) => {
    const newValue = event.newValue;
    const itemId = event.data.parentId;
    const ref = event.data.ref;
    if (sourceDialectLoadId) {
      await resultsModel.updateSource(ref, itemId, sourceDialectLoadId, newValue);
    }
  };

  const handleShowUploadModal = async () => {
    setShowUploadModal(true);
  };

  const handleHideUploadModal = async () => {
    setShowUploadModal(false);
  };

  const handleFilterTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterText(event.target.value);
  };

  const isFilterActive = () => {
    return fText !== '';
  };

  const isRowVisible = (node: RowNode) => {
    const data: IQAResult = node.data;
    const regExp = new RegExp(fText, 'i');
    return JSON.stringify(data).match(regExp) !== null;
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={420}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <EditorRowStyled>
                <TextFieldPos
                  value={startPos}
                  variant="outlined"
                  label={'Start'}
                  type={'number'}
                  onChange={handleChangeStarPos}
                />
                <TextFieldPos
                  value={endPos}
                  variant="outlined"
                  label={'End'}
                  type={'number'}
                  onChange={handleChangeEndPos}
                />
                <DialectSelect onlySource handleDialectChange={handleSourceDialectChange} />
                <Button variant="contained" size="small" onClick={handleLoadClick}>
                  load
                </Button>
              </EditorRowStyled>
              <Checkers>
                {sourceDialectLoadId &&
                  moduleModel
                    .getItemUpdatedValue('', [])
                    .map((checker: ISourceAdaptationQARef) => (
                      <SourceAdaptationQARef
                        key={checker.ref}
                        courseId={props.courseId}
                        refId={checker.ref}
                        sourceDialectId={sourceDialectLoadId}
                        name={checker.name}
                        batchSize={50}
                        startLesson={startPosLoad}
                        endLesson={endPosLoad}
                        onGetResult={resultsModel.addResult.bind(resultsModel)}
                      />
                    ))}
              </Checkers>
              <EditorRowStyled>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showVerified}
                      onChange={() => {
                        resultsModel.setShowVerified(!showVerified);
                        setShowVerified(!showVerified);
                      }}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Verified"
                />
                <TextFieldFilter variant="outlined" onChange={handleFilterTextChanged} placeholder="Filter" />
              </EditorRowStyled>
            </ContainerLeftHeader>
            <ModuleContext.Provider value={resultsModel}>
              <GridStyled
                handleRowClick={handleRowConceptsClick}
                isRowVisible={isRowVisible}
                isFilterActive={isFilterActive}
              />
            </ModuleContext.Provider>
            <EditorRowStyled>
              <IconWrapper>
                <DownloadButton icon={faDownload} onClick={handleDownloadXLSItemsClick} />
                Items
              </IconWrapper>
              <IconWrapper>
                <DownloadButton icon={faDownload} onClick={handleDownloadXLS1Click} />
                Step 1
              </IconWrapper>
              <IconWrapper>
                <DownloadButton icon={faDownload} onClick={handleDownloadXLS2Click} />
                Step 2
              </IconWrapper>
              <IconWrapper>
                <UploadButton icon={faUpload} onClick={handleShowUploadModal} />
                Upload
              </IconWrapper>
            </EditorRowStyled>
          </ContainerLeft>
          <ContainerRight>
            <ContainerRightHeader>
              <ModuleContext.Provider value={relatedItemsModel}>
                <GridStyled
                  handleRowClick={handleRowRelatedItemsClick}
                  cellRenderers={{
                    [IFields.orig]: booleanRenderer,
                  }}
                />
              </ModuleContext.Provider>
            </ContainerRightHeader>
            <ModuleContext.Provider value={resultPhrasesModel}>
              <GridStyled
                handleRowClick={handleRowPhrasesClick}
                onCellValueChanged={onCellValueChanged}
                cellRenderers={{
                  [IFields.verified]: checkboxRenderer,
                }}
              />
            </ModuleContext.Provider>
            <LoadingItem />
          </ContainerRight>
        </Split>
        <ModuleContext.Provider value={resultsModel}>
          {showUploadModal && <SourceAdaptationQAUploadModal onClose={handleHideUploadModal} />}
        </ModuleContext.Provider>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  & > div {
    margin: 0 4px;
  }
`;

const Checkers = styled.div`
  padding: 10px;
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 66px;
  && {
    margin-right: 10px;
  }
`;

export const ContainerRightHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 2px 2px;
  height: 300px;
`;

export default SourceAdaptationQA;

const GridStyled = styled(Grid)`
  & div[col-id='conceptId'] {
    text-decoration: underline;
  }
  & div[col-id='finalParentId'] {
    text-decoration: underline;
  }
  & div[col-id='itemId'] {
    text-decoration: underline;
  }
  & .ag-row {
    cursor: pointer;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  float: right;
  margin: 10px 5px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  font-size: 12px;
  align-items: center;
`;

const DownloadButton = styled(Icon)<{ $isDisabled?: boolean }>`
  color: #6aa84f;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
`;

const UploadButton = styled(Icon)<{ $isDisabled?: boolean }>`
  color: #597eaa;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
`;

const TextFieldFilter = styled(TextFieldStyled)`
  flex-grow: 1;
`;
