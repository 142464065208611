import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import FlagModel from 'models/FlagModel';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  keyName?: string;
};

const DEFAULT_FLAG = 1;
const MAX_FLAG = 5;
const CONSOLIDATE_TIMEOUT = 500;

const Flag: React.FC<IProps> = ({ keyName = 'flag' }) => {
  const [consolidateTimer, setConsolidateTimer] = useState(null as number | null);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue = module.getItemValue.bind(module)(aggregatedKeyName) ?? DEFAULT_FLAG;
  const ref = module.getRef();
  const itemId = module.getItemId();
  const flagModel = useMemo(() => new FlagModel(module, ref, itemId), [module, ref, itemId]);

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, DEFAULT_FLAG);

  const consolidateFlag = async (value: number) => {
    setConsolidateTimer(null);
    await flagModel.updateFlag(value);
  };

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    let newValue = currentValue + 1;
    if (newValue > MAX_FLAG) {
      newValue = DEFAULT_FLAG;
    }
    setCurrentValue(newValue);
    module.updateItemValue.bind(module)(aggregatedKeyName, newValue);

    if (consolidateTimer !== null) {
      clearTimeout(consolidateTimer);
    }
    setConsolidateTimer(setTimeout(() => consolidateFlag(newValue), CONSOLIDATE_TIMEOUT));
  };

  return (
    <Wrapper onClick={onClick} className={`flag${currentValue}`} $consolidating={consolidateTimer !== null}>
      {itemId > 0 && <FontAwesomeIcon icon={faFlag} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $consolidating: boolean }>`
  display: inline-block;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: ${(props) => (props.$consolidating ? 0.5 : 1)};
  font-size: 20px;
`;

export default Flag;
