import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import React, { useContext } from 'react';
import ListIdsBlind from './ListIdsBlind';

const DEFAULT_VALUE: number[] = [];

type IProps = {
  keyName: string;
  refId: IRefIds;
  label: string;
};

const ListIds: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: number[] = module.getItemValue.bind(module)(aggregatedKeyName) || DEFAULT_VALUE;

  return <ListIdsBlind courseId={module.courseId} refId={props.refId} ids={moduleItemValue} label={props.label} />;
};

export default ListIds;
