import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import { IConceptLinkerPanel } from './TooltipPanelsModel';

type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const GuessedPanel: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.panels,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerPanel;

  if (!data) {
    return null;
  }

  const { title } = data;

  return <div dangerouslySetInnerHTML={{ __html: title }} />;
};

export default GuessedPanel;
