import { ContainerLeft } from 'components/common/styles';
import React, { useContext, useEffect, useMemo } from 'react';
import PanelEstructureModel from '../../../models/PanelEstructureModel';
import { useForceRender } from '../../../hooks/useForceRender';
import { DragDropContext } from 'react-beautiful-dnd';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import PanelEstructuraDD from '../estructure/PanelEstructuraDD';
import PanelsModel from '../../../models/PanelsModel';
import PanelEstructureContext, { IPanelEstructureContext } from '../estructure/context/PanelEstructureContext';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers/rootReducer';
import { TabsDialectAware } from '../../../CMFW/layout/TabsDialectAware';
import styled from 'styled-components';

export type IProps = {
  panelId?: number;
  editable?: boolean;
};

const Estructure: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const panelModel = useContext(ModuleContext) as PanelsModel;
  const { courseId } = panelModel;
  const itemId = props.panelId ?? panelModel.itemId;
  const courses = useSelector((state: IAppState) => state.courseConfig);
  const courseConfig = courses[courseId];
  const panelEstructureModel = useMemo(() => new PanelEstructureModel({ render: forceRender, courseId }), [
    forceRender,
    courseId,
  ]);

  const [panelEstructureContext, setPanelEstructureContext] = React.useState({
    sourceDialectId: courseConfig.sourceDialectDefaultId,
    editable: !!props.editable,
    updateSourceDialectId: (sourceId: number) => {
      setPanelEstructureContext({
        ...panelEstructureContext,
        sourceDialectId: sourceId,
      });
    },
  } as IPanelEstructureContext);

  useEffect(() => {
    panelEstructureModel.loadBlocks(itemId);
  }, [panelEstructureModel, itemId]);

  return (
    <ContainerLeft>
      <ModuleContext.Provider value={panelEstructureModel}>
        <TabsDialectAwareStyled
          dialects={courseConfig.sourceDialects}
          selectedDialect={panelEstructureContext.sourceDialectId}
          handleSelectedDialect={(event, newSelectedDialect) =>
            panelEstructureContext.updateSourceDialectId(newSelectedDialect)
          }
        />
        <PanelEstructureContext.Provider value={panelEstructureContext}>
          <DragDropContext onDragEnd={panelEstructureModel.onDragEnd.bind(panelEstructureModel)}>
            <PanelEstructuraDD />
          </DragDropContext>
        </PanelEstructureContext.Provider>
      </ModuleContext.Provider>
    </ContainerLeft>
  );
};

export default Estructure;

const TabsDialectAwareStyled = styled(TabsDialectAware)`
  height: auto;
`;
