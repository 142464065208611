import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow } from 'components/common/styles';
import TextSearch from 'components/search/TextSearch';
import PanelSelect from 'components/selects/PanelSelect';
import React, { useContext, useState } from 'react';

type IProps = {};

const SegmentSearch: React.FC<IProps> = (props) => {
  const [keyPanel, setKeyPanel] = useState(new Date().getTime());
  const [keyText, setKeyText] = useState(new Date().getTime());
  const module = useContext(ModuleContext);

  const onChangePanel = (panelId: number) => {
    if (panelId >= 0) {
      module.search({ panelId });
    } else {
      module.search({});
    }
    setKeyText(new Date().getTime());
  };

  const onSearchText = () => {
    setKeyPanel(new Date().getTime());
  };

  return (
    <>
      <EditorRow>
        <PanelSelect allOption onChange={onChangePanel} key={keyPanel} />
      </EditorRow>
      <TextSearch key={keyText} onSearch={onSearchText} />
    </>
  );
};

export default SegmentSearch;
