import { get } from '../services/request';

export type ILesson = {
  id: number;
  title: string;
  position: number;
};

export default class LessonModel {
  courseId: number;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    const lessons = await get<ILesson[]>(`/groups/${this.courseId}/dialects/default/lessons`);
    return lessons.sort(function (a, b) {
      const keyA = a.position,
        keyB = b.position;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }
}
