import React from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementGridVoc } from '../../../../types/panelEstructure';
import PanelBlockElementVoc from './PanelBlockElementVoc';

export type IProps = {
  data: IPanelEstructureElementGridVoc;
};

const PanelBlockElementGridVoc: React.FC<IProps> = ({ data }) => {
  return (
    <Wrapper>
      {data.vocs.map((voc) => (
        <PanelBlockElementVoc key={voc.id} data={voc} />
      ))}
    </Wrapper>
  );
};

export default PanelBlockElementGridVoc;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 33%;
  }
`;
