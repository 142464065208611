import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import { ModalWrapper } from 'components/ModalWrapper';
import PanelEdit from 'modules/panels/PanelEdit';
import React, { useContext, useEffect } from 'react';

type IProps = {
  onClose: () => void;
};

const PanelModal: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext);
  const panelId = moduleModel.getItemId();

  useEffect(() => {
    if (panelId === moduleModel.NEW_CREATE_ID) {
      moduleModel.new();
    } else {
      moduleModel.loadItemEdit(panelId);
    }
  }, [panelId, moduleModel]);

  return (
    <ModalWrapper title="Panel" width={818} height={726} onClose={props.onClose}>
      <PanelEdit />
    </ModalWrapper>
  );
};

export default PanelModal;
