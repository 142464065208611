import { ContainerRight, EditorRow } from 'components/common/styles';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import SaveButton from '../../../CMFW/button/SaveButton';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TextEditor from '../../../CMFW/input/TextEditor';
import { AudioPlayer } from '../../../CMFW/layout/AudioPlayer';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { ListDialects } from '../../../CMFW/layout/ListDialects';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import { useForceRender } from '../../../hooks/useForceRender';
import DialogModel from '../../../models/DialogModel';
import DialogSegmentModel from '../../../models/DialogSegmentModel';
import DialogSpeakerSelect from '../DialogSpeaker/DialogSpeakerSelect';
import Translations from '../../../components/translation/Translations';

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

export type IProps = {
  dialogSegmentId: number;
};

const DialogSegmentEdit: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const dialogModel = useContext(ModuleContext) as DialogModel;

  const dialogSegmentModel = useMemo(() => {
    const dsm = new DialogSegmentModel({ render: forceRender, courseId: dialogModel.courseId });
    if (props.dialogSegmentId) {
      dsm.loadItemEdit(props.dialogSegmentId);
      dsm.dialogModel = dialogModel;
    }
    return dsm;
  }, [forceRender, dialogModel, props.dialogSegmentId]);

  useEffect(() => {
    dialogSegmentModel.hide();
  }, [dialogModel.itemId, dialogSegmentModel]);

  const speaker = dialogSegmentModel.getItemValue('speaker');

  return (
    <ModuleContext.Provider value={dialogSegmentModel}>
      <ContainerRight>
        <ItemLayout>
          <EditorRow>
            <DialogSpeakerSelect dialogId={dialogModel.itemId} />
            <SaveButton />
          </EditorRow>
          <DialectContainer>
            <ListDialects keyName={'targets'}>
              <TargetRow>
                <TextEditor
                  keyName={'html'}
                  clKeyName={'conceptLinkerConcepts'}
                  keyError={'error'}
                  config={CK_EDITOR_CONFIG}
                  autoGrow={true}
                />
                {speaker !== 0 && <AudioPlayer />}
              </TargetRow>
            </ListDialects>
            <Translations keyName={'sources'} refId={dialogSegmentModel.ref} />
          </DialectContainer>
          <EditorRow>
            <DeleteButton />
          </EditorRow>
        </ItemLayout>
        <LoadingItem />
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default DialogSegmentEdit;

export const TargetRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const DialectContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
