import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import TranslationProjectModel from '../../../models/TranslationProjectModel';
import TextInput from '../../../CMFW/input/TextInput';
import UserSelect from '../../../components/selects/UserSelect';
import ReloadButton from '../../../CMFW/button/ReloadButton';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import TranslationProjectPreTranslate from '../components/TranslationProjectPreTranslate';
import TranslationProjectAnalysis from '../components/TranslationProjectAnalysis';

export type IProps = {
  courseId: number;
};

const TranslationProject: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new TranslationProjectModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={600}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Container>
                <EditorRow>
                  <TextInput keyName={'name'} label={'Name'} />
                  <SaveButton />
                </EditorRow>
                <EditorRow>
                  <UserSelect keyName={'freelance'} />
                </EditorRow>
                <ColumnWrapper>
                  <TranslationProjectPreTranslate />
                  <TranslationProjectAnalysis />
                </ColumnWrapper>
                <Container />
                <DeleteButton />
              </Container>
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default TranslationProject;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
