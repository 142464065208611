import { ContainerLeft } from 'components/common/styles';
import React from 'react';
import { TabDialects } from '../../../CMFW/layout/TabDialects';
import Translations from '../../../components/translation/Translations';
import styled from 'styled-components';
import { IRefIds } from '../../../constants/refs';

const Title: React.FC = () => {
  return (
    <ContainerLeft>
      <TabDialects keyName={'targets'}>
        <Wrapper>
          <Translations keyName={'titles'} refId={IRefIds.panelTitle} />
        </Wrapper>
      </TabDialects>
    </ContainerLeft>
  );
};

export default Title;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
