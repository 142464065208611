import React from 'react';

export type IFeedbackContext = {
  onlyTracking: boolean;
  updateOnlyTracking: (value: boolean) => void;
  showCycleRate: boolean;
  updateShowCycleRate: (value: boolean) => void;
};

export const feedbackContextDefault: IFeedbackContext = {
  onlyTracking: false,
  updateOnlyTracking: (value: boolean) => {},
  showCycleRate: true,
  updateShowCycleRate: (value: boolean) => {},
};

const FeedbackContext = React.createContext<IFeedbackContext>(feedbackContextDefault);

export default FeedbackContext;
