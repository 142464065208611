import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { put } from '../../../../services/request';
import { TextFieldStyled } from '../../../../components/common/styles';

export type IProps = {
  courseId: number;
  startPos: number;
  endPos: number;
  onDoing?: () => void;
  onDone?: () => void;
};

const DEFAULT_N_SEGMENTS_PANEL = 3;

const WizardChangeStateToDo: React.FC<IProps> = (props) => {
  const [nSegments, setNSegments] = React.useState(DEFAULT_N_SEGMENTS_PANEL);

  const handleChangeNSegments = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = parseInt(event.target.value);
    setNSegments(newValue);
  };

  const doCreateSegments = async () => {
    props.onDoing && props.onDoing();
    await put(`/courses/${props.courseId}/wizard/create-segments`, {
      startPos: props.startPos,
      endPos: props.endPos,
      nSegments,
    });
    props.onDone && props.onDone();
  };

  return (
    <Wrapper>
      <TextFieldStyled
        value={nSegments}
        variant="outlined"
        label={'Segments per Panel'}
        type={'number'}
        onChange={handleChangeNSegments}
      />
      <ButtonStyled variant="contained" size="small" onClick={doCreateSegments}>
        Create Segments
      </ButtonStyled>
    </Wrapper>
  );
};

export default WizardChangeStateToDo;

const Wrapper = styled.div`
  padding: 0 10px 10px;
`;

const ButtonStyled = styled(Button)`
  && {
    margin-right: 16px;
    margin-top: 15px;
  }
`;
