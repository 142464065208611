import { App } from 'App';
import 'app.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import registerServiceWorker from 'registerServiceWorker';
import store from 'store/store';
import { StyleSheetManager } from 'styled-components';

const render = (Component: React.FC) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <StyleSheetManager disableVendorPrefixes>
          <Component />
        </StyleSheetManager>
      </Provider>
    </AppContainer>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}

registerServiceWorker();
