import { Button } from '@material-ui/core';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { IRefIds } from '../../../constants/refs';
import UserSelect from '../../../components/selects/UserSelect';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TranslationDashboardModel from '../../../models/TranslationDashboardModel';

type IProps = {
  onClose: () => void;
  refId: IRefIds;
  startLesson: number;
  endLesson: number;
  dialectId: number;
};

const TranslationDashboardCreateProjectModal: React.FC<IProps> = (props) => {
  const [freelance, setFreelance] = useState<number | null>(null);

  const moduleModel = useContext(ModuleContext) as TranslationDashboardModel;

  const handleCreate = () => {
    if (freelance) {
      moduleModel.createProject({
        ref: props.refId,
        startLesson: props.startLesson,
        endLesson: props.endLesson,
        freelance: freelance,
        dialect: props.dialectId,
      });
    }
    props.onClose();
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Create Translation Project'} width={400} height={455}>
      <EditorRowStyled>
        <UserSelect onChange={(freelanceId: number) => setFreelance(freelanceId)} />
      </EditorRowStyled>
      <EditorRowStyled>
        <Button variant="contained" size="small" disabled={!freelance} onClick={handleCreate}>
          Create
        </Button>
      </EditorRowStyled>
    </ModalWrapper>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-end;
  margin-top: 10px;
`;

export default TranslationDashboardCreateProjectModal;
