import React, { useState } from 'react';
import styled from 'styled-components';
import BookChapterTextList from '../components/BookChapterTextList';
import BookChapterTextEdit from '../components/BookChapterTextEdit';

const BookChapterText: React.FC = () => {
  const [editText, setEditText] = useState(0);

  const onEditText = (textId: number) => {
    setEditText(textId);
  };

  return (
    <Container>
      <BookChapterTextList onEditText={onEditText} />
      <BookChapterTextEdit bookChapterTextId={editText} />
    </Container>
  );
};

export default BookChapterText;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
