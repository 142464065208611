import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftFooter, ContainerRight, ModuleContainer } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import VocCategoryEdit from './tabs/VocCategoryEdit';
import VocCategoryModel from '../../models/VocCategoryModel';
import VocCategoryVocs from './tabs/VocCategoryVocs';

const VocCategory: React.FC = () => {
  const forceRender = useForceRender();

  const vocCategoryModel = useMemo(() => new VocCategoryModel({ render: forceRender, courseId: 0 }), [forceRender]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={vocCategoryModel}>
        <Split widthLeft={360}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemTabs tabNames={['Vocabulary', 'Edit']}>
              <VocCategoryVocs />
              <VocCategoryEdit />
            </ItemTabs>
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default VocCategory;
