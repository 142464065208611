import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { IBookChapter } from '../../../types/book';
import { CLDetails } from '../../../components/cl/CLDetails';

export type IProps = {
  chapter: IBookChapter;
  index: number;
  dnd: boolean;
  onEditText: Function;
  editing: boolean;
};

const BookChapterListCard: React.FC<IProps> = (props) => {
  const { chapter } = props;

  const onEditSegment = () => {
    props.onEditText(chapter.id);
  };

  return (
    <Draggable isDragDisabled={!props.dnd} draggableId={props.chapter.id.toString()} index={props.index}>
      {(provided) => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onEditSegment}
          editing={props.editing}
        >
          <ChapterDetails>Lesson {chapter.lessonPos}</ChapterDetails>
          <Title dangerouslySetInnerHTML={{ __html: chapter.title.replace(/\n/g, '<br>') }} />
          <RightWrapper>
            <CLDetails details={chapter.clDetails} />
          </RightWrapper>
          <RightWrapper>words: {chapter.nWords}</RightWrapper>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default BookChapterListCard;

const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const RightWrapper = styled.div`
  text-align: right;
  font-size: 12px;
`;

const ChapterDetails = styled.div`
  font-size: 10px;
`;

const Wrapper = styled.div<{ editing: boolean }>`
  border: solid 1px #bfbfbf;
  background-color: ${(props) => (props.editing ? '#e9dec7' : '#f5f5f5')};
  position: relative;
  margin: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;
