import { tooltipLoader } from 'components/conceptLink/TooltipLoader';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IItemId } from 'types/content';
import { ICulturalVocs, ICulturalVocsEdit, ICulturalVocsTargetEdit } from 'types/culturalvocs';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import TranslationManagerModel from './TranslationManagerModel';

export default class CulturalvocModel extends BaseModel<ICulturalVocs, ICulturalVocsEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.cultural;
    this.gridName = 'Culturalvocs';
    this.translations = new TranslationManagerModel({ ref: this.ref });
    this.getGridColumns();
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<ICulturalVocs[]>(`courses/${this.courseId}/culturalvocs`, data);

    this.loadingGrid = false;

    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ICulturalVocs>(`courses/${this.courseId}/culturalvocs/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const [item, targets, sources] = await Promise.all([
      get<ICulturalVocsEdit>(`culturalvocs/new`),
      get<ICulturalVocsTargetEdit[]>(`courses/${this.courseId}/culturalvocs-target/new`),
      this.translations.new(this.courseId),
    ]);
    item.targets = targets;
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }

    this.invalidateTooltip();
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as ICulturalVocsEdit;

    await put<IItemId>(`/culturalvocs/${itemToSave.id}`, lodash.omit(itemToSave, ['sources', 'targets']));

    //save targets after Item because of invalidation of inflection
    await Promise.all([
      ...itemToSave.targets.map((target) =>
        put<IItemId>(`/culturalvocs/${itemToSave.id}/target-dialects/${target.dialectId}`, target),
      ),
    ]);
    await this.translations.update(itemToSave.id, itemToSave.sources);

    await Promise.all([this.updateGridRow(itemToSave.id, 'update'), this.loadItemEdit(itemToSave.id)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as ICulturalVocsEdit;
    const result = await post<IItemId>(
      `/courses/${this.courseId}/culturalvocs`,
      lodash.omit(itemToCreate, ['sources', 'targets']),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all([
        ...itemToCreate.targets.map((target) =>
          put<IItemId>(`/culturalvocs/${newItemId}/target-dialect/${target.dialectId}`, target),
        ),
      ]);
      await this.translations.update(newItemId, itemToCreate.sources);

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`culturalvocs/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateTooltip();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, targets, sources] = await Promise.all([
      get<ICulturalVocsEdit>(`culturalvocs/${itemId}`),
      get<ICulturalVocsTargetEdit[]>(`culturalvocs/${itemId}/targets`),
      this.translations.get(itemId),
    ]);
    item.targets = targets;
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  private invalidateTooltip() {
    tooltipLoader && tooltipLoader.invalidate({ ref: IRefIds.cultural, courseId: this.courseId, id: this.itemId });
  }
}
