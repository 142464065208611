import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextareaAutosize, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ContainerRight = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 28px 0 2px;
`;

export const ContainerLeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 2px 2px;
`;

export const ContainerLeftFooter = styled.div`
  display: flex;
  padding: 2px;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 700px;
  height: 100%;
  box-sizing: border-box;
  padding: 6px 4px 2px 0;
  overflow: hidden;
`;

export const EditorRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  & > div {
    margin: 0 10px;
  }
`;

export const EditorRowWrapping = styled(EditorRow)`
  flex-wrap: wrap;
`;

export const EditorGap = styled.div`
  width: 4px;
  height: 4px;
`;

export const EditorGap10 = styled.div`
  width: 10px;
  height: 10px;
`;

export const EditorGapGreedy = styled.div`
  flex: 1;
`;

export const EditorGapGreedyH = styled.div`
  width: 100%;
`;

export type IChangeAwareProps = { $changed: boolean };

export const THE_COLOR_OF_CHANGE = '#faecea';

export const changeAwareMixin = css<IChangeAwareProps>`
  background-color: ${(props) => (props.$changed ? THE_COLOR_OF_CHANGE : 'transparent')};
`;

export const WrapperChanged = styled.div<IChangeAwareProps>`
  ${changeAwareMixin}
`;

export const WrapperChangedTransparent = styled.div<IChangeAwareProps>`
  background-color: ${(props) => (props.$changed ? THE_COLOR_OF_CHANGE : 'transparent')};
`;

export const TextFieldStyled = styled(TextField)`
  width: 100%;
  & .MuiOutlinedInput-input {
    padding: 8px 6px;
  }
  & .MuiInputLabel-outlined {
    transform: translate(14px, 9px) scale(1);
  }
`;

export const TextFieldChangeAware = styled(TextFieldStyled)<IChangeAwareProps>`
  ${changeAwareMixin}
`;

export const TextAreaStyled = styled(TextareaAutosize)<IChangeAwareProps>`
  ${changeAwareMixin}
  width: 100%;
`;

export const Kline = styled.div`
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
  padding: 2px 4px;
  font-size: 12px;
  background-color: #5f84ef;
  color: white;
`;

export const Garbage = styled(FontAwesomeIcon)`
  color: coral;
  margin-right: 5px;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  display: inline-block;
  width: 250px;
  min-height: 90px;
  border: solid 1px #b9b9b9;
  border-radius: 0 4px 0 4px;
  margin: 5px;
  padding: 0;
  background-color: white;
  position: relative;
  vertical-align: top;
`;

export const CardHeader = styled.div`
  background-color: #f7f7f7;
  border-bottom: solid 1px #a5a5a5;
  border-radius: 4px 4px 0 0;
`;

export const CardId = styled.div`
  text-decoration: underline;
  cursor: pointer;
  float: right;
  background-color: white;
  border-radius: 0 4px 0 4px;
  font-size: 12px;
  padding: 2px 7px;
  opacity: 0.8;
  border-left: solid 1px #b9b9b9;
  border-bottom: solid 1px #b9b9b9;
`;
