import { IRefIds } from 'constants/refs';
import { get, put } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';
import { ICourse, ICourseEdit } from '../types/courses';

export default class CoursesModel extends BaseModel<ICourse, ICourseEdit> {
  constructor(options: IModelOptions) {
    super(options);
    this.ref = IRefIds.courses;
    this.gridName = 'Courses';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ICourse[]>(`courses`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ICourse>(`courses/${itemId}/grid-row`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as ICourseEdit;
    await put(`courses/${item.id}`, item);
    await Promise.all([this.updateGridRow(item.id, 'update'), this.loadItemEdit(item.id)]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ICourseEdit>(`courses/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  async addSourceDialect(dialectId: number, copyDialectId: number) {
    const itemId = this.getItemId();
    await super.save();
    await put(`courses/${itemId}/add-source-dialect`, {
      dialectId,
      copyDialectId,
    });
    await this.loadItemEdit(itemId);
  }

  async addTargetDialect(dialectId: number, copyDialectId: number) {
    const itemId = this.getItemId();
    await super.save();
    await put(`courses/${itemId}/add-target-dialect`, {
      dialectId,
      copyDialectId,
    });
    await this.loadItemEdit(itemId);
  }
}
