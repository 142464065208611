import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IGridData } from 'types/models';
import TableModel, { ITable } from '../../../models/TableModel';
import { EditorRow, TextFieldStyled } from 'components/common/styles';
import Button from '@material-ui/core/Button';

type IProps = {
  onClose: () => void;
  onChooseTable?: (data: ITable) => void;
  onNewTable?: (name: string) => void;
};

const TableSelectNewModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const [name, setName] = useState('');

  const moduleModel = useContext(ModuleContext);

  const tableModel = useMemo(() => new TableModel({ render: forceRender, courseId: moduleModel.courseId }), [
    forceRender,
    moduleModel.courseId,
  ]);

  useEffect(() => {
    tableModel.getGrid();
  }, [tableModel]);

  const handleRowClick = (data: IGridData) => {
    props.onChooseTable && props.onChooseTable(data as ITable);
  };

  const handleNew = () => {
    props.onNewTable && props.onNewTable(name);
  };

  return (
    <ModalWrapper title="Search Table" width={468} height={555} onClose={props.onClose}>
      <ModuleContext.Provider value={tableModel}>
        <EditorRow>
          <p>
            <b>Select Table or create a new one</b>
          </p>
        </EditorRow>
        <Grid handleRowClick={handleRowClick} />
        <EditorRow>
          <TextFieldStyled onChange={(event) => setName(event.target.value)} label={'Name'} />
          <Button variant="contained" size="small" color="primary" onClick={handleNew}>
            new
          </Button>
        </EditorRow>
      </ModuleContext.Provider>
    </ModalWrapper>
  );
};

export default TableSelectNewModal;
