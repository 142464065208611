import { del, get, post, put } from 'services/request';
import { IItemId, IPosition } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';

export type ITable = IItemId & {
  name: string;
  position: IPosition;
  nPanels: number;
};

type ITableEdit = IItemId & {
  name: string;
  html: string;
  panels: number;
  deletable: boolean;
};

export default class TableModel extends BaseModel<ITable, ITableEdit> {
  readonly DIFFERENT_NEW_ID = -1;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.table;
    this.gridName = 'Table';
    this.getGridColumns();
  }

  async getGridSearch(text: string) {
    await super.getGrid();
    this.render();

    this.gridData = await get<ITable[]>(`/courses/${this.courseId}/table?text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  getGridData() {
    return get<ITable[]>(`courses/${this.courseId}/table`);
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await this.getGridData();
    this.loadingGrid = false;

    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ITable>(`courses/${this.courseId}/table/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<ITableEdit>(`table/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.DIFFERENT_NEW_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    await put(`table/${this.itemId}`, this.itemUpdated);
    await Promise.all([this.updateGridRow(this.itemId, 'update'), this.loadItemEdit(this.itemId)]);
  }

  async createItem() {
    const result = await post<IItemId>(`courses/${this.courseId}/table`, {
      ...this.itemUpdated,
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      this.itemId = newItemId;
      await this.updateItem();
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`table/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ITableEdit>(`table/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGridSearch(data.text ?? '');
  }
}
