import InflectTablesModel from 'models/InflectTablesModel';
import { get } from 'services/request';
import BaseInflectsModel, { IInflectBase } from './BaseInflectsModel';

type IInflectId = IInflectBase & {
  value: number;
};

export default class InflectIdsModel extends BaseInflectsModel<IInflectId> {
  withInitials = false;

  async loadWordClass(wordClassId: number) {
    await super.loadItemEdit(wordClassId);

    this.inflectTablesModel = new InflectTablesModel({
      courseId: this.courseId,
      wordClass: wordClassId,
    });

    await Promise.all([
      this.inflectTablesModel.getInflectTables(),
      this.inflectTablesModel.getInflectTableHeaders(),
      this.inflectTablesModel.getInflectTableCells(),
    ]);

    const items = await get<IInflectId[]>(`courses/${this.courseId}/inflect-ids`);
    await this.setItem(this.fillInflects(items));

    this.loadingItem = false;
    this.render();
  }

  protected createEmptyItem(inflectId: number, index = 0): IInflectId {
    return {
      index,
      inflectId,
      value: 1000,
    };
  }
}
