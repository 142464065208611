import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PanelEstructureModel from '../../../../models/PanelEstructureModel';
import ModuleContext from '../../../../CMFW/context/ModuleContext';

export type IProps = {};

const PanelEstructureBlockNew: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as PanelEstructureModel;

  const onNew = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    module.newBlock();
  };

  return (
    <Wrapper onClick={onNew}>
      <FontAwesomeIcon icon={faPlus} />
    </Wrapper>
  );
};

export default PanelEstructureBlockNew;

const Wrapper = styled.div`
  opacity: 0.2;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  margin: 10px;
  display: flex;
  align-items: center;
`;
