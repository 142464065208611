import { get, put } from 'services/request';
import { IItemId } from '../types/content';

type IModelOptions = {
  courseId: number;
  ref: number;
  targetDialect: number;
  render: () => void;
};

export default class InflectTargetBatchRefModel {
  items: number[] = [];
  done: number[] = [];
  courseId: number;
  ref: number;
  targetDialect: number;
  ready = false;
  render: () => void;
  running = false;
  completed = false;

  constructor(options: IModelOptions) {
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.targetDialect = options.targetDialect;
    this.render = options.render;
    this.getItems();
  }

  private async getItems() {
    const elements = await get<IItemId[]>(
      `groups/${this.courseId}/refs/${this.ref}/target-dialects/${this.targetDialect}/batch-target-ref-elements`,
    );

    this.items = elements.map((element) => element.id);
    this.ready = true;
    this.render();
  }

  public toggle() {
    if (this.running) {
      this.stop();
    } else {
      this.start();
    }
  }

  private start() {
    this.running = true;
    this.render();
    this.continue();
  }

  private async continue() {
    if (this.running && !this.completed) {
      const batchIds = this.items.slice(this.done.length, this.done.length + 1);

      if (batchIds.length > 0) {
        const itemId = batchIds[0];
        await put(`/refs/${this.ref}/ref-ids/${itemId}/dialects/${this.targetDialect}/auto-inflect`, {});
        this.done.push(itemId);
        this.render();
        await this.continue();
      } else {
        this.completed = true;
        this.stop();
      }
    }
  }

  private stop() {
    this.running = false;
    this.render();
  }
}
