import { TooltipContainer } from 'components/conceptLink/styles';
import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import styled from 'styled-components';
import { IConceptLinkerCultural } from './TooltipCulturalsModel';

export type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const TooltipCulturals: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.cultural,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerCultural;

  if (!data) {
    return null;
  }

  const { title, definition } = data;

  return (
    <TooltipContainer>
      <Title>{title}</Title>
      <Definition>{definition}</Definition>
    </TooltipContainer>
  );
};

const Title = styled.p`
  font-weight: bold;
  margin: 0;
`;

const Definition = styled.p`
  margin: 0;
`;

export default TooltipCulturals;
