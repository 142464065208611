import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import ModuleContext from '../../CMFW/context/ModuleContext';
import { EditorRow, TextFieldStyled } from '../../components/common/styles';
import FeedbackModel from '../../models/FeedbackModel';
import FeedbackContext from './context/FeedbackContext';

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

const FeedbackTop: React.FC = () => {
  const module = useContext(ModuleContext) as FeedbackModel;
  const [startPos, setStartPos] = React.useState(DEFAULT_START_POS);
  const [endPos, setEndPos] = React.useState(DEFAULT_END_POS);

  const feedbackContext = useContext(FeedbackContext);

  const doLoad = async () => {
    await module.loadLessons(startPos, endPos);
  };

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStartPos(parseInt(event.target.value));
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEndPos(parseInt(event.target.value));
  };

  const handleChangeOnlyTracking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    feedbackContext.updateOnlyTracking(checked);
  };

  const handleChangeShowCycleRate = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    feedbackContext.updateShowCycleRate(checked);
  };

  return (
    <Wrapper>
      <EditorRowLeft>
        <TextFieldPos
          value={startPos}
          variant="outlined"
          label={'From Lesson'}
          type={'number'}
          onChange={handleChangeStarPos}
        />
        <TextFieldPos
          value={endPos}
          variant="outlined"
          label={'To Lesson'}
          type={'number'}
          onChange={handleChangeEndPos}
        />
        <ButtonStyled
          variant="outlined"
          size="small"
          onClick={doLoad}
          disabled={startPos === 0 || endPos === 0 || startPos > endPos}
        >
          load
        </ButtonStyled>
      </EditorRowLeft>
      <FormControlLabel
        control={
          <Switch
            checked={feedbackContext.onlyTracking}
            onChange={handleChangeOnlyTracking}
            color="primary"
            size="small"
          />
        }
        label="Show only Tracking"
      />
      <FormControlLabel
        control={
          <Switch
            checked={feedbackContext.showCycleRate}
            onChange={handleChangeShowCycleRate}
            color="primary"
            size="small"
          />
        }
        label="Show Cycle Rate"
      />
    </Wrapper>
  );
};

export default FeedbackTop;

const Wrapper = styled.div`
  width: 100%;
  border-bottom: solid 1px #2a2f43;
  padding: 10px;
`;

const ButtonStyled = styled(Button)`
  && {
    margin-right: 16px;
  }
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 100px;
  && {
    margin-right: 16px;
  }
`;

const EditorRowLeft = styled(EditorRow)`
  justify-content: flex-start;
`;
