import { BaseModel } from './BaseModel';
import { del, get, post, put } from '../services/request';
import { DropResult } from 'react-beautiful-dnd';
import {
  IPanelEstructure,
  IPanelEstructureBlock,
  IPanelEstructureCard, IPanelEstructureElementParagraph,
} from '../types/panelEstructure';
import { IRefIds } from '../constants/refs';
import { ITranslation } from '../types/Translation';

export default class PanelEstructureModel extends BaseModel {
  blocks: IPanelEstructureBlock[] = [];
  panelId = 0;
  loadingItem = false;

  public async loadBlocks(panelId: number) {
    this.panelId = panelId;
    this.loadingItem = true;
    this.blocks = [];
    this.render();
    await this.getStructure();
    this.render();
  }

  public async onDragEnd(result: DropResult) {
    console.log(result);
    const { destination, source, type, draggableId } = result;
    if (!destination || this.getBank(destination.droppableId) !== null) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (this.isTrash(destination.droppableId)) {
      if (type === IPanelEstructureCard.BLOCK) {
        const blockId = this.getId(draggableId);
        await this.deleteBlock(blockId);
      }

      if (type === IPanelEstructureCard.ELEMENT) {
        const elementId = this.getId(draggableId);
        await this.removeElement(elementId);
      }
    } else {
      if (type === IPanelEstructureCard.BLOCK) {
        const blockId = this.getId(draggableId);
        await this.moveBlock(blockId, destination.index);
      }

      if (type === IPanelEstructureCard.ELEMENT) {
        const elementId = this.getId(draggableId);
        const destinationBlock = this.getId(destination.droppableId);
        await this.moveElement(elementId, destinationBlock, destination.index);
      }
    }
  }

  public async newBlock() {
    this.updatingStructure();
    this.render();
    await post(`/panels/${this.panelId}/block/new`, {});
    await this.getStructure();
    this.loadingItem = false;
    this.render();
  }

  private async moveBlock(blockId: number, newPos: number) {
    this.updatingStructure();
    this.render();
    await put(`/panelBlock/block/${blockId}/move`, {
      destinationPos: newPos,
    });
    await this.getStructure();
    this.render();
  }

  public async updateBlock(blockId: number, block: number, isNote: boolean) {
    this.updatingStructure();
    this.render();
    await post(`/panelBlock/block/${blockId}`, {
      block,
      note: isNote,
    });
    await this.getStructure();
    this.render();
  }

  private async moveElement(elementId: number, destinationBlockId: number, newPos: number) {
    this.updatingStructure();
    this.render();

    await put(`panelBlock/element/${elementId}/move`, {
      destinationBlockId: destinationBlockId,
      destinationPos: newPos,
    });

    await this.getStructure();
    this.render();
  }

  public async addElement(element: {
    block: number;
    ref: IRefIds;
    itemId?: number;
    translationId?: number;
    name?: string;
    data?: string;
  }): Promise<number | undefined> {
    this.updatingStructure();
    this.render();

    const response = await put<{ id: number }>(`panelBlock/block/${element.block}/add-element`, element);

    await this.getStructure();
    this.render();
    return response.data?.id;
  }

  public async updateElement(data: { elementId: number; data: string }) {
    this.updatingStructure();
    this.render();

    await put(`panelBlock/element/${data.elementId}`, {
      data: data.data,
    });

    await this.getStructure();
    this.render();
  }

  public updateParagraphTranslation(translationId: number, dialects: ITranslation[]) {
    for (const block of this.blocks) {
      for (const element of block.elements) {
        if (element.ref === IRefIds.paragraph) {
          const data = element.data as IPanelEstructureElementParagraph;
          if (data.translationId === translationId) {
            data.sources = [...dialects];
          }
        }
      }
    }
    this.render();
  }

  public async updateElementDialect(data: { elementId: number; dialect: number }) {
    this.updatingStructure();
    this.render();

    await put(`/panelBlock/element/${data.elementId}/dialect`, {
      dialect: data.dialect,
    });

    await this.getStructure();
    this.render();
  }

  private async getStructure() {
    if (this.panelId > 0) {
      const estructure = await get<IPanelEstructure>(`panels/${this.panelId}/estructure`);
      this.blocks = estructure.blocks;
    }
    this.loadingItem = false;
  }

  private async deleteBlock(blockId: number) {
    this.updatingStructure();
    this.render();
    await del(`panelBlock/block/${blockId}`);
    await this.getStructure();
    this.render();
  }

  private async removeElement(elementId: number) {
    this.updatingStructure();
    this.render();
    await del(`panelBlock/block/element/${elementId}`);
    await this.getStructure();
    this.render();
  }

  private updatingStructure() {
    this.loadingItem = true;
  }

  private getId(text: string): number {
    return parseInt(text.split('-')[1]);
  }

  private getBank(text: string): number | null {
    const [ref, bank] = text.split('-');
    if (bank === 'bank') {
      return parseInt(ref);
    } else {
      return null;
    }
  }

  private isTrash(text: string): boolean {
    return text.search('Trash') !== -1;
  }
}
