import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IChangeAwareProps, WrapperChangedTransparent } from './styles';

type IProps = {
  keyName: string;
  style?: React.CSSProperties;
};

const SplitSelect: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: number = module.getItemValue(aggregatedKeyName) || 0;
  const moduleItemValueUpdated: number = module.getItemUpdatedValue(aggregatedKeyName) || 0;

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, 0);

  useEffect(() => {
    setCurrentValue(moduleItemValueUpdated);
  }, [moduleItemValueUpdated, setCurrentValue]);

  const handleSplitChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const newValue = event.target.value as number;
    setCurrentValue(newValue);
    module.updateItemValue.bind(module)(aggregatedKeyName, newValue);
  };

  return (
    <Wrapper $changed={moduleItemValue !== currentValue}>
      <Select value={currentValue} onChange={handleSplitChange}>
        <MenuItem value={0}>Don&apos;t split</MenuItem>
        <MenuItem value={1}>Can split</MenuItem>
        <MenuItem value={2}>Can split long</MenuItem>
      </Select>
    </Wrapper>
  );
};

const Wrapper = styled(WrapperChangedTransparent)<IChangeAwareProps>`
  width: 123px;
`;

export default SplitSelect;
