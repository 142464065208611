import { RowNode } from '@ag-grid-enterprise/all-modules';
import { Button, TextField } from '@material-ui/core';
import NewButton from 'CMFW/button/NewButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import { booleanRenderer } from 'CMFW/grid/cellRenderers/boolean';
import { errorRenderer } from 'CMFW/grid/cellRenderers/error';
import { nonZeroRenderer } from 'CMFW/grid/cellRenderers/nonZero';
import { panelLevelsRenderer } from 'CMFW/grid/cellRenderers/panelLevels';
import { IFields } from 'CMFW/grid/fields';
import Grid from 'CMFW/grid/Grid';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerLeftHeader,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import { usePrevious } from 'hooks/usePrevious';
import PanelsFactoryModel from 'models/PanelsFactoryModel';
import PanelModal from 'modules/panelsFactory/PanelModal';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import KLineSelect from '../../components/selects/KlineSelect';

export type IProps = {
  courseId: number;
};

let kline = 0;
let fText = '';

const PanelsFactory: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const [isPanelModalOpen, setIsPanelModalOpen] = useState(false);
  const [selectedKline, setSelectedKline] = useState(0);
  const [filterText, setFilterText] = useState('');
  kline = selectedKline;
  fText = filterText;

  const isFilterActive = () => {
    return kline !== 0 || fText !== '';
  };

  const moduleModel = useMemo(() => new PanelsFactoryModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const previousItemUniqueId = usePrevious(moduleModel.itemUniqueId);

  useEffect(() => {
    const isNewItem = previousItemUniqueId !== undefined && previousItemUniqueId !== moduleModel.itemUniqueId;
    if (isNewItem) {
      setIsPanelModalOpen(true);
    }
  }, [moduleModel.itemUniqueId, previousItemUniqueId]);

  const handleLoadClick = () => {
    moduleModel.load();
  };

  const handleKlineChanged = (id: number) => {
    setSelectedKline(id);
  };

  const handleFilterTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterText(event.target.value);
  };

  const isRowVisible = (node: RowNode) => {
    const klineMatches = kline === 0 || node.data.kLineIds.indexOf(kline) !== -1;
    if (!klineMatches) {
      return false;
    }

    const regExp = new RegExp(fText, 'i');
    return (
      node.data.item.match(regExp) !== null ||
      node.data.title.replace(/<[^>]*>/g, '').match(regExp) !== null ||
      node.data.concept.match(regExp) !== null ||
      node.data.id.toString() === fText
    );
  };

  const handleRowClick = (row: IGridData) => {
    moduleModel.itemId = row.id;
    setIsPanelModalOpen(true);
  };

  const handlePanelModalClose = () => {
    setIsPanelModalOpen(false);
  };

  const renderModals = () => {
    if (isPanelModalOpen) {
      return <PanelModal onClose={handlePanelModalClose} />;
    }
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerLeft>
          <ContainerLeftHeader>
            <EditorRow>
              <Button variant="contained" size="small" onClick={handleLoadClick}>
                load
              </Button>
            </EditorRow>
            <EditorRow>
              <KlineSelectStyled allOption={true} onChange={handleKlineChanged} />
              <TextFieldStyled variant="outlined" onChange={handleFilterTextChanged} placeholder="Filter" />
            </EditorRow>
          </ContainerLeftHeader>
          <Grid
            cellRenderers={{
              [IFields.error]: errorRenderer,
              [IFields.level]: panelLevelsRenderer,
              [IFields.conceptLinkerStatus]: booleanRenderer,
              [IFields.nLockedVoc]: nonZeroRenderer,
              [IFields.nLockedByVoc]: nonZeroRenderer,
              [IFields.nLockedPanel]: nonZeroRenderer,
              [IFields.nLockedByPanel]: nonZeroRenderer,
              [IFields.nTestSentences]: nonZeroRenderer,
              [IFields.nAutoCompounds]: nonZeroRenderer,
              [IFields.stateTestSentences]: 'stateTestSentenceRenderer',
            }}
            disableRowLoadFields={[IFields.stateTestSentences]}
            isFilterActive={isFilterActive}
            isRowVisible={isRowVisible}
            handleRowClick={handleRowClick}
          />
          <ContainerLeftFooter>
            <NewButton />
          </ContainerLeftFooter>
        </ContainerLeft>
        {renderModals()}
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const KlineSelectStyled = styled(KLineSelect)`
  max-width: 450px;
`;

const TextFieldStyled = styled(TextField)`
  flex-grow: 1;
`;

export default PanelsFactory;
