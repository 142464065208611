import { IRefIds } from '../constants/refs';
import { put } from '../services/request';
import { IModel } from '../types/models';

const END_POINT: { [key in IRefIds]?: string } = {
  [IRefIds.glossaries]: 'glossaries',
  [IRefIds.exercise]: 'exercises',
  [IRefIds.segment]: 'segments',
  [IRefIds.dialog]: 'dialogs',
  [IRefIds.reading]: 'readings',
  [IRefIds.book]: 'books',
  [IRefIds.trainer]: 'trainers',
  [IRefIds.ttsLab]: 'ttsLab',
};

export default class FlagModel {
  parentModule: IModel;
  ref: IRefIds;
  itemId: number;
  endPoint: string;

  constructor(parentModule: IModel, ref: IRefIds, itemId: number) {
    this.parentModule = parentModule;
    this.ref = ref;
    this.itemId = itemId;
    if (typeof END_POINT[this.ref] !== 'undefined') {
      this.endPoint = END_POINT[this.ref]!;
    } else {
      throw new Error(`No Flag Endpoint for module ${this.ref}`);
    }
  }

  updateGridRow() {
    this.parentModule.updateGridRow.bind(this.parentModule)(this.itemId);
  }

  async updateFlag(value: number) {
    await put(`${this.endPoint}/${this.itemId}/flag`, { flag: value });
    this.updateGridRow();
  }
}
