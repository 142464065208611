import { IRefIds } from 'constants/refs';
import ConceptLinkerPhraseModel from 'models/ConceptLinkerPhraseModel';
import {
  IConceptLinkerGuessedSetter,
  IConceptLinkerSetter,
  IConceptPhrase,
  IEditorPhrase,
  ILinkedConcept,
} from 'types/conceptLinker';
import { TOOLTIP_WIDTH } from './ConceptLinkerTooltip';
import { tooltipLoader } from './TooltipLoader';

type IEngine_PhraseOptions = {
  rootNode: HTMLElement;
  ckEditorId: string;
  course: number;
  setTooltip: IConceptLinkerSetter;
  setGuessedTooltip: IConceptLinkerGuessedSetter;
  fireChange: () => void;
  ref: IRefIds;
  refId: number;
  targetDialect: number;
  removeAllHighlight: () => void;
};

const TOOLTIP_DELAY = 250;
export const TOOLTIP_DELAY_SECONDARY = 2000;

export class ConceptsLinkerEnginePhrase {
  WORD_WITHOUT_CONCEPTS = 0;
  WORD_WITH_CONCEPTS = 1;
  WORD_LINKED_TO_CONCEPT = 2;
  WORD_CLASS = ['cl--none', 'cl--pending', 'cl--done'];
  WORD_HIGHLIGHT_CLASS = 'cl--highlight';
  private rootNode: HTMLElement;
  private cKEditorId: string;
  private ref: number;
  private refId: number;
  private course: number;
  private targetDialect: number;
  private previousPhrasePlainGuessed: string | null = null;
  private linkedConcepts: ILinkedConcept[] = [];
  private allConcepts: ILinkedConcept[] = [];
  private linked = false;
  editorPhrase: IEditorPhrase | null = null;
  private updated = false;
  private elementMouseOver: HTMLElement | null = null;
  private elementMouseOverTimeout: number | null = null;
  conceptLinkerPhraseModel: ConceptLinkerPhraseModel;
  setTooltip: IConceptLinkerSetter;
  setGuessedTooltip: IConceptLinkerGuessedSetter;
  fireChange: () => void;
  removeOtherHighlight: () => void;

  constructor(options: IEngine_PhraseOptions) {
    this.fireChange = options.fireChange;
    this.rootNode = options.rootNode;
    this.cKEditorId = options.ckEditorId;
    this.course = options.course;
    this.conceptLinkerPhraseModel = new ConceptLinkerPhraseModel();
    this.setTooltip = options.setTooltip;
    this.setGuessedTooltip = options.setGuessedTooltip;
    this.ref = options.ref;
    this.refId = options.refId;
    this.targetDialect = options.targetDialect;
    this.removeOtherHighlight = options.removeAllHighlight;
  }

  getTargetDialectId() {
    return this.targetDialect;
  }

  setPhrase(phrase: IConceptPhrase) {
    this.previousPhrasePlainGuessed = phrase.phrase;
    this.linkedConcepts = phrase.concepts;
  }

  firstLinkEditorPhrase(editorPhrase: IEditorPhrase) {
    this.linkEditorPhrase(editorPhrase);
    if (!this.isDone()) {
      this.guess(editorPhrase.plain, false);
    }
  }

  linkEditorPhrase(editorPhrase: IEditorPhrase) {
    this.linked = true;
    this.editorPhrase = editorPhrase;

    if (this.previousPhrasePlainGuessed === null || this.previousPhrasePlainGuessed !== editorPhrase.plain) {
      this.guess(editorPhrase.plain, false);
    } else {
      this.mapPhraseToEditor();
    }

    this.previousPhrasePlainGuessed = editorPhrase.plain;
  }

  markAsUnLinked() {
    this.linked = false;
  }

  isLinked() {
    return this.linked;
  }

  isEditorPhrase(editorPhraseId: number) {
    return this.editorPhrase !== null && this.editorPhrase.id === editorPhraseId;
  }

  private guess(plain: string, fromScratch: boolean) {
    if (!plain) {
      return;
    }

    this.conceptLinkerPhraseModel
      .guessPhrases({
        ref: this.ref!,
        refId: this.refId!,
        targetDialect: this.targetDialect!,
        phrase: plain,
        fromScratch,
      })
      .then((result) => {
        if (
          !this.editorPhrase ||
          !result ||
          Object.keys(result).length === 0 //request cancelled
        ) {
          return;
        }

        if (result.phrase !== this.editorPhrase.plain) {
          this.guess(this.editorPhrase.plain, false);
        } else {
          this.allConcepts = result.concepts;
          tooltipLoader &&
            tooltipLoader.preloadPhrases([{ concepts: this.allConcepts }], this.course, this.targetDialect);

          if (result.exactMatch) {
            this.linkedConcepts = result.possibleConcepts;
            if (!this.isDone()) {
              this.guess(this.editorPhrase.plain, true);
            }
          } else {
            const previousLinkedConcepts = this.linkedConcepts;
            this.linkedConcepts = [];
            this.processLinkedConcepts(previousLinkedConcepts, result.possibleConcepts);
            this.processPreselected();
          }
          this.mapPhraseToEditor();
        }
      });
  }

  mapPhraseToEditor() {
    if (!this.editorPhrase) {
      return;
    }

    this.fireChange();
    const linkedConceptPositions = this.getLinkedPositions();
    const allConceptPositions = this.getAllConceptPositions();

    this.editorPhrase.words.forEach((word, i) => {
      if (word.includes('___')) {
        return;
      }
      if (!this.editorPhrase) {
        return;
      }
      if (linkedConceptPositions.indexOf(i) === -1) {
        if (allConceptPositions.indexOf(i) === -1) {
          this.markEditorWord(this.editorPhrase.id, i, this.WORD_WITHOUT_CONCEPTS);
        } else {
          this.markEditorWord(this.editorPhrase.id, i, this.WORD_WITH_CONCEPTS);
        }
      } else {
        this.markEditorWord(this.editorPhrase.id, i, this.WORD_LINKED_TO_CONCEPT);
      }
    });
  }

  markEditorWord(phraseId: number, position: number, type: number) {
    const className = this.WORD_CLASS[type];
    const dataCL = phraseId + '-' + position;

    const element = this.rootNode.querySelectorAll('[data-cl="' + dataCL + '"]');
    if (element.length > 0) {
      element[0].removeAttribute('class');
      element[0].setAttribute('class', className);
      element[0].addEventListener('mouseenter', this.elementMouseEnter);
      element[0].addEventListener('mouseleave', this.elementMouseLeave);
    }
  }

  private getTooltipDelay = () => {
    return TOOLTIP_DELAY;
  };

  private elementMouseEnter = (event: any) => {
    this.elementMouseOver = event.target;
    event.stopPropagation();
    const tooltipDelay = this.getTooltipDelay();
    if (this.elementMouseOverTimeout === null && tooltipDelay !== null) {
      this.elementMouseOverTimeout = setTimeout(this.showTooltip, tooltipDelay);
    }
  };

  private elementMouseLeave = () => {
    if (this.elementMouseOverTimeout !== null) {
      clearTimeout(this.elementMouseOverTimeout);
    }
    this.elementMouseOverTimeout = null;
  };

  private destroyTooltip() {
    this.setTooltip(null);
  }

  private destroyAllTooltips = () => {
    this.destroyTooltip();
    this.setGuessedTooltip(null);
    this.removeHighlight();
  };

  private getHoveredElementWordPosition = () => {
    if (!this.elementMouseOver) {
      return null;
    }

    const cl = this.elementMouseOver.dataset['cl'];
    if (!cl) {
      return null;
    }

    return parseInt(cl.split('-')[1]);
  };

  private showTooltip = () => {
    if (!this.elementMouseOver) {
      return;
    }

    const wordPosition = this.getHoveredElementWordPosition();

    if (wordPosition === null) {
      return;
    }

    this.destroyTooltip();

    const ckEditorElement = window.parent.document.getElementById(this.cKEditorId);
    if (!ckEditorElement) {
      return;
    }

    const ckEditorIframe = ckEditorElement.getElementsByTagName('iframe')[0] as HTMLIFrameElement;
    if (ckEditorIframe.contentDocument) {
      ckEditorIframe.contentDocument.removeEventListener('click', this.destroyAllTooltips);
      ckEditorIframe.contentDocument.addEventListener('click', this.destroyAllTooltips);
    }

    const cKEditorBounding = ckEditorIframe.getBoundingClientRect();
    const elementBounding = this.elementMouseOver.getBoundingClientRect();
    let x = cKEditorBounding.x + elementBounding.x;
    if (x + TOOLTIP_WIDTH > window.innerWidth) {
      x -= TOOLTIP_WIDTH;
    }
    const y = cKEditorBounding.y + elementBounding.bottom;

    if (this.isConcept(wordPosition)) {
      this.showConceptTooltip(x, y, wordPosition);
    } else if (this.isGuessed(wordPosition)) {
      this.showGuessedTooltip(x, y, wordPosition);
    }
  };

  private isGuessed = (wordPosition: number) => this.getAllConceptPositions().indexOf(wordPosition) !== -1;

  private isConcept = (wordPosition: number) => this.getLinkedPositions().indexOf(wordPosition) !== -1;

  private showConceptTooltip(x: number, y: number, wordPosition: number) {
    const concept = this.getLinkedConceptByPosition(wordPosition);

    if (concept) {
      this.setTooltip({
        x,
        y,
        engine: this,
        concept,
        showInvalidate: true,
      });

      this.addHighlight(concept.positions);
    }
  }

  private showGuessedTooltip(x: number, y: number, wordPosition: number) {
    const concepts = this.getGuessedConceptsByPosition(wordPosition);

    if (concepts) {
      this.setGuessedTooltip({
        x,
        y,
        engine: this,
        concepts,
        linkedPositions: this.getLinkedPositions(),
      });
    }
  }

  selectConcept(concept: ILinkedConcept) {
    this.addLinkedConcept(concept);
    this.mapPhraseToEditor();
  }

  private getGuessedConceptsByPosition(position: number) {
    const result: ILinkedConcept[] = [];

    this.allConcepts.forEach((guessConcept) => {
      if (guessConcept.positions.indexOf(position) !== -1) {
        result.push(guessConcept);
      }
    });

    return result;
  }

  getCourse() {
    return this.course;
  }

  invalidateConcept(concept: ILinkedConcept) {
    if (!this.editorPhrase) {
      return;
    }

    this.removeConceptFromLinked(concept);
    this.mapPhraseToEditor();
    if (this.allConcepts.length === 0) {
      this.guessFromScratch(this.editorPhrase.plain);
    }
    this.showTooltip();
  }

  private removeConceptFromLinked(removeConcept: ILinkedConcept) {
    this.updated = true;
    let linkedConceptIndex = -1;
    this.linkedConcepts.forEach((linkedConcept, index) => {
      if (
        removeConcept.ref === linkedConcept.ref &&
        removeConcept.refId === linkedConcept.refId &&
        removeConcept.positions.length === linkedConcept.positions.length
      ) {
        if (JSON.stringify(removeConcept.positions.sort()) === JSON.stringify(linkedConcept.positions.sort())) {
          linkedConceptIndex = index;
        }
      }
    });

    if (linkedConceptIndex !== -1) {
      this.linkedConcepts.splice(linkedConceptIndex, 1);
    }
  }

  private guessFromScratch(plain: string) {
    this.guess(plain, true);
  }

  private getLinkedConceptByPosition(position: number) {
    return this.linkedConcepts.find((linkedConcept) => linkedConcept.positions.indexOf(position) !== -1);
  }

  addHighlight(positions: number[]) {
    this.removeOtherHighlight();

    positions.forEach((position) => {
      if (!this.editorPhrase) {
        return;
      }
      const dataCL = this.editorPhrase.id + '-' + position;
      const element = this.rootNode.querySelectorAll('[data-cl="' + dataCL + '"]')[0];
      element.setAttribute('class', element.getAttribute('class') + ' ' + this.WORD_HIGHLIGHT_CLASS);
    });
  }

  public removeHighlight() {
    if (!this.editorPhrase) {
      return;
    }
    for (let i = 0; i < this.editorPhrase.length; i++) {
      const dataCL = this.editorPhrase.id + '-' + i;
      const element = this.rootNode.querySelectorAll('[data-cl="' + dataCL + '"]')[0];
      if (element) {
        const elementClass = element.getAttribute('class');
        if (elementClass !== null) {
          element.setAttribute('class', elementClass.replace(this.WORD_HIGHLIGHT_CLASS, ''));
        }
      }
    }
  }

  getAllConceptPositions() {
    let allConceptPositions: number[] = [];
    for (let i = 0; i < this.allConcepts.length; i++) {
      allConceptPositions = allConceptPositions.concat(this.allConcepts[i].positions);
    }
    return allConceptPositions;
  }

  private processPreselected() {
    this.allConcepts.forEach((concept) => {
      if (concept.preselected === true) {
        this.addLinkedConcept(concept);
      }
    });
  }

  isUpdated() {
    return this.updated;
  }

  isDone() {
    if (!this.editorPhrase) {
      return;
    }

    const linkedConceptPositions = this.getLinkedPositions();

    const linkedConceptPositionsUnique = linkedConceptPositions.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return (
      linkedConceptPositions.length === linkedConceptPositionsUnique.length &&
      linkedConceptPositions.length === this.editorPhrase.length
    );
  }

  private getLinkedPositions() {
    let linkedConceptPositions: number[] = [];
    for (let i = 0; i < this.linkedConcepts.length; i++) {
      linkedConceptPositions = linkedConceptPositions.concat(this.linkedConcepts[i].positions);
    }
    return linkedConceptPositions;
  }

  private processLinkedConcepts(previousLinkedConcepts: ILinkedConcept[], possibleConcepts: ILinkedConcept[]) {
    //1st match LinkedConcepts with AllConcepts
    previousLinkedConcepts.forEach((previousLinkedConcept) => {
      const foundConcept = this.findConcept(previousLinkedConcept, this.allConcepts);
      if (foundConcept !== null) {
        this.addLinkedConcept(foundConcept);
      }
    });

    //2nd match AllConcepts+LinkedConcepts with possibleConcepts
    possibleConcepts.forEach((possibleConcept) => {
      const foundConcept = this.findConcept(possibleConcept, this.allConcepts);
      if (foundConcept !== null) {
        this.addLinkedConcept(foundConcept);
      }
    });
  }

  findConcept(needle: ILinkedConcept, haystack: ILinkedConcept[]) {
    const conceptsSamePositions: ILinkedConcept[] = [];
    const conceptsNoSamePositions: ILinkedConcept[] = [];

    haystack.forEach((concept) => {
      if (
        needle.ref === concept.ref &&
        needle.refId === concept.refId &&
        needle.positions.length === concept.positions.length
      ) {
        if (JSON.stringify(needle.positions.sort()) === JSON.stringify(concept.positions.sort())) {
          conceptsSamePositions.push(concept);
        } else {
          conceptsNoSamePositions.push(concept);
        }
      }
    });

    if (conceptsSamePositions.length === 1) {
      return conceptsSamePositions[0];
    }

    if (conceptsNoSamePositions.length === 1) {
      return conceptsNoSamePositions[0];
    }

    if (conceptsNoSamePositions.length > 1) {
      let closedConcept = conceptsNoSamePositions[0];
      const sumPositions = needle.positions.reduce((a, b) => a + b);
      let minSumDifference = Math.abs(closedConcept.positions.reduce((a, b) => a + b) - sumPositions);
      for (let i = 1; i < conceptsNoSamePositions.length; i++) {
        const sumDifference = Math.abs(conceptsNoSamePositions[i].positions.reduce((a, b) => a + b) - sumPositions);
        if (sumDifference < minSumDifference) {
          minSumDifference = sumDifference;
          closedConcept = conceptsNoSamePositions[i];
        }
      }

      if (minSumDifference < Math.floor(4 * needle.positions.length)) {
        return closedConcept;
      }
    }

    return null;
  }

  addLinkedConcept(concept: ILinkedConcept) {
    this.updated = true;
    let canBeAdded = true;
    const linkedConceptPositions = this.getLinkedPositions();
    concept.positions.forEach((position) => {
      if (linkedConceptPositions.indexOf(position) !== -1) {
        canBeAdded = false;
      }
    });

    if (canBeAdded) {
      this.linkedConcepts.push(concept);
    }
  }

  private getConcepts() {
    return this.linkedConcepts.map((linkedConcept) => ({
      ref: linkedConcept.ref,
      refId: linkedConcept.refId,
      positions: linkedConcept.positions,
      deltaPos: linkedConcept.deltaPos,
      inflectStudied: linkedConcept.inflectStudied,
    }));
  }

  getPhraseConcepts(): IConceptPhrase | null {
    if (!this.editorPhrase) {
      return null;
    }

    return {
      phrase: this.editorPhrase.plain,
      order: this.editorPhrase.order,
      concepts: this.getConcepts(),
    };
  }
}
