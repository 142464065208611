import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { createKeyPath } from '../context/helper/keyContext';
import KeyContext from '../context/KeyContext';

type IProps = {
  icon: IconProp;
  keyName: string;
  onChange?: () => void;
};

const BooleanIcon: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: boolean = module.getItemValue.bind(module)(aggregatedKeyName) || false;
  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, false);

  const onClick = () => {
    const newValue = !currentValue;
    setCurrentValue(newValue);
    module.updateItemValue.bind(module)(aggregatedKeyName, newValue);
    props.onChange && props.onChange();
  };

  return (
    <Wrapper onClick={onClick} $active={currentValue}>
      <FontAwesomeIcon icon={props.icon} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $active: boolean }>`
  display: inline-block;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: ${(props) => (props.$active ? 1 : 0.25)};
  font-size: 20px;
`;

export default BooleanIcon;
