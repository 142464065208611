import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import InflectsModel, { IConjugationState } from 'models/InflectsModel';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import KeyContext from '../../../../CMFW/context/KeyContext';
import { createKeyPath } from '../../../../CMFW/context/helper/keyContext';

const colors = ['transparent', '#008000', 'blue', 'red'];

export const ConjugationButton: React.FC = () => {
  const parentKey = useContext(KeyContext);
  const inflectsModel = useContext(ModuleContext) as InflectsModel;

  const conjWord = inflectsModel.getItemUpdatedValue.bind(inflectsModel)(createKeyPath(parentKey, 'conjWord')) || '';
  const conjState =
    inflectsModel.getItemUpdatedValue.bind(inflectsModel)(createKeyPath(parentKey, 'conjState')) ||
    IConjugationState.NO_CONJ;
  const conjLockState =
    inflectsModel.getItemUpdatedValue.bind(inflectsModel)(createKeyPath(parentKey, 'conjLockState')) ||
    IConjugationState.NO_CONJ;

  const [lockState, setLockState] = useState(conjLockState as IConjugationState);

  const changeLockState = (newLockState: IConjugationState) => {
    inflectsModel.updateItemValue.bind(inflectsModel)(createKeyPath(parentKey, 'conjLockState'), newLockState);
    setLockState(newLockState);
  };

  const handleClick = () => {
    if (conjWord === '') {
      return;
    }

    switch (lockState) {
      case IConjugationState.NO_CONJ:
        changeLockState(IConjugationState.CHANGE);
        break;
      case IConjugationState.REGULAR:
        changeLockState(IConjugationState.CHANGE);
        break;
      case IConjugationState.CHANGE:
        changeLockState(IConjugationState.IRREGULAR);
        break;
      case IConjugationState.IRREGULAR:
        changeLockState(IConjugationState.LOCK_NO_CONJ);
        break;
      case IConjugationState.LOCK_NO_CONJ:
        changeLockState(IConjugationState.NO_CONJ);
        break;
    }
  };

  const isLock = lockState === IConjugationState.CHANGE || lockState === IConjugationState.IRREGULAR;
  const color = isLock ? colors[lockState] : colors[conjState];

  if (conjWord === '') {
    return <Container />;
  }

  return (
    <TooltipStyled title={<TooltipContent dangerouslySetInnerHTML={{ __html: conjWord }} />} arrow placement="right">
      <ContainerActive onClick={handleClick}>
        {isLock ? <Lock icon={faLock} $color={color} /> : <Square $color={color} />}
      </ContainerActive>
    </TooltipStyled>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  padding: 4px;
`;

const ContainerActive = styled(Container)`
  cursor: pointer;
`;

const Square = styled.div<{ $color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${(props) => props.$color};
`;

const Lock = styled(FontAwesomeIcon)<{ $color: string }>`
  color: ${(props) => props.$color};
`;

const TooltipStyled = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    background-color: white;
    font-size: 18px;
  }
`;

const TooltipContent = styled.div`
  font-size: 16px;
  background-color: white;
  padding: 2px;
  color: black;
  & reg {
    color: green;
  }

  & irreg {
    color: red;
  }

  & change {
    color: blue;
  }
`;
