import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SelectLayout } from './SelectLayout';
import TableLiteralsModel, {
  ITableLiteralSelect,
} from '../../models/TableLiteralsModel';

const KEY = 'literal';

type IProps = {
  keyName?: string;
  isLinked?: boolean;
  onChange?: (literalId: number) => void;
  className?: string;
  disabled?: boolean;
  noneOption?: boolean;
};

const ConjLiteralSelect: React.FC<IProps> = (props) => {
  const { isLinked, onChange } = props;
  const key = props.keyName ?? KEY;

  const module = useContext(ModuleContext);
  const moduleItemValue = module.getItemValue(key);

  const [currentLiteralId, setCurrentLiteralId] = useCurrentItemValue(moduleItemValue, 0, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.tableLiterals, courseId);
  const dataModel = useMemo(() => new TableLiteralsModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));
  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }
    if (props.noneOption) {
      return [{ id: 0, name: 'NONE' }, ...cacheSelectData];
    }
    return cacheSelectData;
  }, [cacheSelectData, props.noneOption]);

  const handleChange = (value: ITableLiteralSelect) => {
    const literalId = value.id;
    setCurrentLiteralId(literalId);
    isLinked && module.updateItemValue(key, literalId);
    onChange && onChange(literalId);
  };

  return (
    <SelectLayoutStyled
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Literal"
      optionLabelKey="name"
      currentValue={currentLiteralId}
      originalValue={isLinked && moduleItemValue}
      disabled={props.disabled}
    />
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 275px;
  flex-grow: 0;
`;

export default ConjLiteralSelect;
