import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import { IConceptLinkerCultural } from './TooltipCulturalsModel';

type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const GuessedCulturals: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.cultural,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerCultural;

  if (!data) {
    return null;
  }

  const { title } = data;

  return <div>{title}</div>;
};

export default GuessedCulturals;
