export function createAction<T extends keyof P, P>(type: T) {
  return (payload: P[T]) => ({
    type,
    payload,
  });
}

export function createActionNoPayload<T>(type: T) {
  return () => ({
    type,
  });
}
