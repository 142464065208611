import Button from '@material-ui/core/Button';
import { EditorGap } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';
import { VocCategorySelect } from '../../../components/selects/VocCategorySelect';

type IProps = {
  handleAddCategory: (category: number) => void;
  onClose: () => void;
};

const NO_CAT = 0;

const VocCategoriesAddModal: React.FC<IProps> = (props) => {
  const [cat, setCat] = useState(NO_CAT);

  const handleChangeCategory = (catId: number) => {
    setCat(catId);
  };

  const handleAddCategory = () => {
    props.handleAddCategory(cat);
  };

  return (
    <ModalWrapper title={'Add Category'} onClose={props.onClose} width={500} height={100}>
      <VocCategorySelect onChange={handleChangeCategory} />
      <EditorGap />
      <Button variant="contained" size="small" color="primary" onClick={handleAddCategory} disabled={cat === NO_CAT}>
        add
      </Button>
    </ModalWrapper>
  );
};

export default VocCategoriesAddModal;
