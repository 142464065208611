import { get } from 'services/request';
import { IFetchModel, ITooltipOptions } from 'types/models';

export type IConceptLinkerCultural = {
  id: number;
  nUses: number;
  title: string;
  svg: string;
  definition: string;
};

export class TooltipCulturalsModel implements IFetchModel<IConceptLinkerCultural[], ITooltipOptions> {
  get(options: ITooltipOptions) {
    return get<IConceptLinkerCultural[]>(`tooltips-cultural-voc?culturalVocs=${options.ids.join(',')}`);
  }
}
