import { ModalWrapper } from 'components/ModalWrapper';
import React from 'react';
import { Uses } from '../../../CMFW/layout/Uses';

type IProps = {
  onClose: () => void;
};

const UsesModal: React.FC<IProps> = (props) => {
  return (
    <ModalWrapper title="Uses" width={700} height={700} onClose={props.onClose}>
      <Uses />
    </ModalWrapper>
  );
};

export default UsesModal;
