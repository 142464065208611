import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import DialogModel from '../../../models/DialogModel';
import { IDialogEdit } from '../../../types/dialog';
import DialogSegmentNew from './DialogSegmentNew';
import DialogSegmentListSegment from './DialogSegmentListSegment';
import { FormControlLabel, Switch } from '@material-ui/core';
import { EditorRow } from '../../../components/common/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DialogSegmentAddScript from './DialogSegmentAddScript';

export type IProps = {
  onEditSegment: Function;
};

const DialogSegmentList: React.FC<IProps> = (props) => {
  const [dnd, setDnd] = useState(false);
  const [editSegmentId, setEditSegmentId] = useState(0);
  const module = useContext(ModuleContext) as DialogModel;
  const item = module.itemUpdated as IDialogEdit;

  const onEditSegment = (segmentId: number) => {
    props.onEditSegment(segmentId);
    setEditSegmentId(segmentId);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={module.onDragEnd.bind(module)}>
        <Droppable isDropDisabled={!dnd} droppableId={'Dialog'} direction={'vertical'}>
          {(provided) => (
            <DropWrapper {...provided.droppableProps} ref={provided.innerRef}>
              {item.segments.map((segment, index) => (
                <DialogSegmentListSegment
                  segment={segment}
                  index={index}
                  dnd={dnd}
                  editing={segment.id === editSegmentId}
                  key={`dialogSegment-${segment.id}`}
                  onEditSegment={onEditSegment}
                />
              ))}
            </DropWrapper>
          )}
        </Droppable>
      </DragDropContext>
      <EditorRow>
        <DialogSegmentNew />
        <DialogSegmentAddScript />
        <FormControlLabel
          control={
            <Switch
              checked={dnd}
              onChange={() => setDnd(!dnd)}
              color="primary"
              size="small"
              disabled={item.segments.length < 2}
            />
          }
          label="DND"
        />
      </EditorRow>
    </Wrapper>
  );
};

export default DialogSegmentList;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const DropWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
