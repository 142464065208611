import React, { useContext } from 'react';
import styled from 'styled-components';
import { ILessonOutlineCard, ILessonOutlineCycle } from '../../../types/lessonOutline';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import LessonOutlineActivity from './LessonOutlineActivity';
import LessonOutlineContext from '../context/LessonOutlineContext';

export type IProps = {
  cycle: ILessonOutlineCycle;
  index: number;
};

const LessonOutlineCycle: React.FC<IProps> = (props) => {
  const lessonOutlineContext = useContext(LessonOutlineContext);
  return (
    <Draggable
      isDragDisabled={!lessonOutlineContext.DNDEnabled}
      draggableId={`cycle-${props.cycle.id}`}
      index={props.index}
    >
      {(provided) => (
        <Wrapper {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Droppable
            isDropDisabled={!lessonOutlineContext.DNDEnabled}
            droppableId={`cycle-${props.cycle.id}`}
            direction={'horizontal'}
            type={ILessonOutlineCard.ACTIVITY}
          >
            {(providedDroppable, snapshot) => (
              <WrapperActivities
                isDraggingOver={snapshot.isDraggingOver}
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {props.cycle.activities.map((activity, index) => (
                  <LessonOutlineActivity activity={activity} key={`activity-${activity.activityId}`} index={index} />
                ))}
                {providedDroppable.placeholder}
              </WrapperActivities>
            )}
          </Droppable>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default LessonOutlineCycle;

const Wrapper = styled.div`
  background-color: #f6f6f6;
  margin: 10px 4px;
`;

const WrapperActivities = styled.div<{ isDraggingOver: boolean }>`
  min-height: 20px;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.isDraggingOver ? '#f5f5e8' : 'none')};
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f6f6f6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 3px;
  }
`;
