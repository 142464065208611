import { ContainerRight, EditorRow } from 'components/common/styles';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import CATTable from './components/CATTable';
import ModuleContext from 'CMFW/context/ModuleContext';
import TranslationProjectSelect from '../../components/selects/TranslationProjectSelect';
import { Button } from '@material-ui/core';
import { ITranslationProjectSelect } from '../../models/TranslationProjectsModel';
import { useForceRender } from '../../hooks/useForceRender';
import CATModel from '../../models/CATModel';
import { ITranslationStatus } from '../../types/Translation';
import { LoadingItem } from '../../CMFW/layout/Loading';
import CATDiffViewer from './components/CATDiffViewer';
import CATMemory from './components/CATMemory';
import { Split } from '../../components/Split';
import CATContext from './components/CATContext';
import CATFromId from './components/CATFromId';
import CATActions from './components/CATActions';
import CATAIReview from './components/CATAIReview';

export type IProps = {
  courseId: number;
};

const CAT: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new CATModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const [project, setProject] = useState<number | null>(null);

  const handleChangeProject = (project: ITranslationProjectSelect) => {
    setProject(project.id);
  };

  const handleLoadClick = async () => {
    if (project) {
      await model.loadProject(project);
    }
  };

  const handleUpdateTarget = (projectId: number, target: string, status: ITranslationStatus) => {
    model.updateTarget.bind(model)(projectId, target, status);
  };

  return (
    <ModuleContext.Provider value={model}>
      <ContainerRight>
        <EditorRowStyled>
          <TranslationProjectSelect courseId={props.courseId} onChange={handleChangeProject} />
          <Button variant="contained" size="small" onClick={handleLoadClick} disabled={!project}>
            load
          </Button>
        </EditorRowStyled>
        <Split widthRight={400}>
          <CATTable data={model.data} onUpdateTarget={handleUpdateTarget} />
          <RightColumn>
            <CATActions />
            <CATFromId />
            <CATAIReview />
            <CATDiffViewer />
            <CATMemory />
            <CATContext />
          </RightColumn>
        </Split>
        <LoadingItem />
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default CAT;

const RightColumn = styled.div`
  width: auto;
  height: 100%;
  overflow: auto;
`;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;
