import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IReadingExercise, IReadingExerciseEdit, IReadingExerciseTarget } from '../types/reading';
import * as lodash from 'lodash';
import ReadingModel from './ReadingModel';
import { IRefIds } from '../constants/refs';

export default class ReadingExerciseModel extends BaseModel<IReadingExercise, IReadingExerciseEdit> {
  readingModel: ReadingModel | null = null;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.readingExercise;
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as IReadingExerciseEdit;

    await put(`readings/exercises/${item.id}`, lodash.omit(item, ['targets']));
    await Promise.all(
      item.targets.map((target) => put(`/readings/exercises/${item.id}/target-dialect/${target.dialectId}`, target)),
    );
    await Promise.all([this.loadItemEdit(item.id), this.reloadReadingModel()]);
  }

  async delete() {
    await super.delete();
    const item = this.itemUpdated as IReadingExerciseEdit;
    await del(`readings/exercises/${item.id}`);
    this.itemUpdated = null;
    this.loadingItem = false;
    this.render();
    await this.reloadReadingModel();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets] = await Promise.all([
      get<IReadingExerciseEdit>(`readings/exercises/${itemId}`),
      get<IReadingExerciseTarget[]>(`readings/exercises/${itemId}/targets`),
    ]);

    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async clone() {
    this.loadingItem = true;
    this.render();
    await put(`readings/exercises/${this.itemId}/clone`, {});
    this.loadingItem = false;
    this.render();
    await this.reloadReadingModel();
  }

  async reloadReadingModel() {
    if (this.readingModel) {
      await this.readingModel.forceReloadReadingExercises.bind(this.readingModel)();
    }
  }
}
