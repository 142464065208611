import { TextFieldProps } from '@material-ui/core/TextField';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useEffect } from 'react';
import { Switch } from '@material-ui/core';
import styled from 'styled-components';
import { changeAwareMixin, IChangeAwareProps } from '../../components/common/styles';

type IProps = TextFieldProps & {
  keyName: string;
  disabled?: boolean;
};

const TextInput: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: boolean = module.getItemValue.bind(module)(aggregatedKeyName) ?? false;
  const moduleItemValueUpdated: boolean = module.getItemUpdatedValue(aggregatedKeyName) ?? false;

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, false);

  useEffect(() => {
    setCurrentValue(moduleItemValueUpdated);
  }, [moduleItemValueUpdated, setCurrentValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCurrentValue(checked);
    module.updateItemValue.bind(module)(aggregatedKeyName, checked);
  };

  return (
    <SwitchChangeAware
      checked={currentValue}
      onChange={onChange}
      color="primary"
      size="small"
      disabled={props.disabled}
      $changed={moduleItemValue !== currentValue}
    />
  );
};

export default TextInput;

export const SwitchChangeAware = styled(Switch)<IChangeAwareProps>`
  ${changeAwareMixin}
`;
