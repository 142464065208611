import React, { useContext, useEffect, useState } from 'react';
import CATModel, { ICATMemoryGuest } from '../../../models/CATModel';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { ITranslationStatus } from '../../../types/Translation';
import CATPanel from './CATPanel';
import TranslationMemory from '../../../components/translation/TranslationMemory';

export type IProps = {};

const CATMemory: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [memory, setMemory] = useState<ICATMemoryGuest[] | null>(null);

  useEffect(() => {
    setMemory(module.memoryActiveGuest);
  }, [module, module.memoryActiveGuest]);

  const onSelectMemory = async (row: ICATMemoryGuest) => {
    await module.updateTarget(module.activeId, row.text, ITranslationStatus.TRANSLATION_DOING);
    await module.updateTranslationMemory(module.activeId, row.similarity, row.fromText);
  };

  const renderMemory = () => {
    if (module.getActive() === null) {
      return null;
    } else {
      return <TranslationMemory memory={memory} onSelectMemory={onSelectMemory} />;
    }
  };

  return <CATPanel title={'Memory'}>{renderMemory()}</CATPanel>;
};

export default CATMemory;
