import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type IProps = {
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Close: React.FC<IProps> = (props) => {
  return (
    <Container onClick={props.handleClick}>
      <Button icon={faTimesCircle} />
    </Container>
  );
};

const Container = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Button = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
`;
