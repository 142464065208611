import React, { useContext } from 'react';
import styled from 'styled-components';
import CATModel from '../../../models/CATModel';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { CATCell, CATRow } from './CATTableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { IRefIds } from '../../../constants/refs';
import { tabsOpened } from '../../../store/actions/tabs';
import { useDispatch } from 'react-redux';

export type IProps = {};

const CATQA: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const module = useContext(ModuleContext) as CATModel;

  const openItem = (itemId: string) => {
    dispatch(
      tabsOpened({
        refId: IRefIds.items,
        search: itemId,
        courseId: module.courseId,
      }),
    );
  };

  const active = module.getActive();

  if (!active) {
    return null;
  }

  const renderQA = () => {
    if (!active.QAErrors) {
      return <CircularProgressStyled size={10} />;
    } else if (active.QAErrors.length === 0) {
      return <FontAwesomeIconGreen icon={faCheck} />;
    } else {
      return (
        <>
          {active.QAErrors.map((error, index) => (
            <CATRowStyled key={index}>
              <CATCell>
                <ConceptText>{error.conceptTarget}</ConceptText>
              </CATCell>
              <CATCellStyled>
                <ConceptText>{error.conceptSource}</ConceptText>
              </CATCellStyled>
              <CATCellStyled width={20}>
                {error.conceptRefChild === IRefIds.items && (
                  <FontAwesomeIconEdit icon={faPencilAlt} onClick={() => openItem(error.conceptIdChildren)} />
                )}
              </CATCellStyled>
            </CATRowStyled>
          ))}
        </>
      );
    }
  };

  return renderQA();
};

export default CATQA;

const CATRowStyled = styled(CATRow)`
  border-left: none;
`;

const CATCellStyled = styled(CATCell)`
  border-right: none;
`;

const ConceptText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #000;
`;

const FontAwesomeIconGreen = styled(FontAwesomeIcon)`
  color: #329415;
  font-size: 14px;
  margin: 4px;
`;

const FontAwesomeIconEdit = styled(FontAwesomeIcon)`
  color: #dddddd;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`;

const CircularProgressStyled = styled(CircularProgress)`
  margin: 4px;
`;
