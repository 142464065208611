import React, { useContext } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../CMFW/context/ModuleContext';
import LessonOutlineModel from '../../models/LessonOutlineModel';
import LessonOutlineLesson from './cards/LessonOutlineLesson';
import { Droppable } from 'react-beautiful-dnd';
import { LoadingItem } from '../../CMFW/layout/Loading';
import LessonOutlineLessonNew from './components/LessonOutlineLessonNew';
import { ILessonOutlineCard } from '../../types/lessonOutline';
import LessonOutlineContext from './context/LessonOutlineContext';

export type IProps = {};

const LessonOutlineDashboard: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as LessonOutlineModel;
  const lessonOutlineContext = useContext(LessonOutlineContext);
  if (!module.loaded) {
    return <WrapperRelative />;
  }

  return (
    <WrapperRelative>
      <Droppable
        isDropDisabled={!lessonOutlineContext.DNDEnabled}
        droppableId={'Dashboard'}
        direction={'vertical'}
        type={ILessonOutlineCard.LESSON}
      >
        {(provided) => (
          <Wrapper {...provided.droppableProps} ref={provided.innerRef}>
            {module.getLessons().map((lesson, index) => (
              <LessonOutlineLesson lesson={lesson} key={`lesson-${lesson.id}`} index={index} />
            ))}
            {provided.placeholder}
            {lessonOutlineContext.DNDEnabled && <LessonOutlineLessonNew />}
            <LoadingItem />
          </Wrapper>
        )}
      </Droppable>
    </WrapperRelative>
  );
};

export default LessonOutlineDashboard;

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const WrapperRelative = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: white;
`;
