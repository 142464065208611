import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';
import ConjugationTemplatesModel, { IConjugationTableSelect } from '../../models/ConjugationTemplatesModel';

const KEY = 'templateTable';

type IProps = {
  keyName?: string;
  isLinked?: boolean;
  onChange?: (tableId: number) => void;
  className?: string;
  disabled?: boolean;
  noneOption?: boolean;
};

const ConjTableSelect: React.FC<IProps> = (props) => {
  const { isLinked, onChange } = props;
  const key = props.keyName ?? KEY;

  const module = useContext(ModuleContext);
  const moduleItemValue = module.getItemValue(key);

  const [currentTableId, setCurrentTableId] = useCurrentItemValue(moduleItemValue, 0, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.conjugationTemplate, courseId);
  const dataModel = useMemo(() => new ConjugationTemplatesModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));
  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }
    if (props.noneOption) {
      return [{ id: 0, name: 'NONE' }, ...cacheSelectData];
    }
    return cacheSelectData;
  }, [cacheSelectData, props.noneOption]);

  const handleChange = (value: IConjugationTableSelect) => {
    const tableId = value.id;
    setCurrentTableId(tableId);
    isLinked && module.updateItemValue(key, tableId);
    onChange && onChange(tableId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Conjugation Table"
      optionLabelKey="name"
      currentValue={currentTableId}
      originalValue={isLinked && moduleItemValue}
      disabled={props.disabled}
    />
  );
};

export default ConjTableSelect;
