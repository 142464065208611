import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type ITranslationAICheckerRef = {
  ref: IRefIds;
  name: string;
  batchSize: number;
};

export default class AITranslationCheckerModel extends BaseModel<IGridData, ITranslationAICheckerRef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const checkers = await get<ITranslationAICheckerRef[]>(`/translations/ai/checker-refs`);
    await this.setItem(checkers);
    this.render();
  }
}
