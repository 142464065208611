import React from 'react';
import styled from 'styled-components';

type IProps = {
  deltaPos: number;
};

const DeltaPos: React.FC<IProps> = (props) => {
  const pos = props.deltaPos > 0 ? Math.ceil(props.deltaPos / 10) : Math.floor(props.deltaPos / 10);
  return <Wrapper studied={pos >= 0}>{pos}</Wrapper>;
};

const Wrapper = styled.div<{ studied: boolean }>`
  display: inline-block;
  margin: 0 2px;
  padding: 2px;
  font-size: 14px;
  vertical-align: top;
  width: 24px;
  text-align: center;
  height: fit-content;
  background-color: ${(props) => (props.studied ? '#45f745' : '#f78b8b')};
`;

export default DeltaPos;
