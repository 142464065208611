import { IRefIds } from 'constants/refs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import styled from 'styled-components';

type IProps = {
  courseId: number;
  refId: IRefIds;
  ids: number[];
  label: string;
  onClick?: () => void;
  className?: string;
};

const ListIdsBlind: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const openIds = () => {
    dispatch(
      tabsOpened({
        refId: props.refId,
        search: props.ids.join(','),
        courseId: props.courseId,
      }),
    );
    props.onClick && props.onClick();
  };

  return (
    <Wrapper className={props.className}>
      <span>{props.label}: </span>
      {props.ids.length === 0 ? <span>none</span> : <Link onClick={openIds}>{props.ids.join(', ')}</Link>}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Link = styled.span`
  cursor: pointer;
  color: blue;
`;

export default ListIdsBlind;
