import { AudioPlayer } from 'CMFW/layout/AudioPlayer';
import React from 'react';
import styled from 'styled-components';
import { createKeyPath } from '../../CMFW/context/helper/keyContext';
import KeyContext from '../../CMFW/context/KeyContext';

type IProps = {
  targetCode: string;
  index: number;
};

export const AudioPlayerTarget: React.FC<IProps> = (props) => {
  return (
    <KeyContext.Provider value={createKeyPath('', 'targets', props.index)} key={props.index}>
      <Container>
        <span>{props.targetCode}</span>
        <AudioPlayer />
      </Container>
    </KeyContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;
