import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import BoardsFreelancesModel from 'models/BoardsFreelancesModel';
import BoardsFreelancesUnassignedModel from 'models/BoardsFreelancesUnassignedModel';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import { IFields } from '../../../CMFW/grid/fields';

type IProps = {
  onClose: () => void;
  boardId: number;
};

const FreelancesModal: React.FC<IProps> = (props) => {
  const { boardId } = props;
  const forceRender = useForceRender();

  const freelancesModel = useMemo(() => {
    if (boardId === undefined) {
      return null;
    }
    return new BoardsFreelancesModel({ render: forceRender, boardId });
  }, [boardId, forceRender]);

  const freelancesUnassignedModel = useMemo(() => {
    if (boardId === undefined) {
      return null;
    }
    return new BoardsFreelancesUnassignedModel({ render: forceRender, boardId });
  }, [boardId, forceRender]);

  const handleRowClick = async (data: IGridData) => {
    if (!freelancesModel || !freelancesUnassignedModel) {
      return;
    }
    await freelancesModel.unassignFreelance(data.id);
    await freelancesUnassignedModel.getGrid();
  };
  const handleUnassignedRowClick = async (data: IGridData) => {
    if (!freelancesUnassignedModel || !freelancesModel) {
      return;
    }
    await freelancesUnassignedModel.assignFreelance(data.id);
    await freelancesModel.getGrid();
  };

  if (!freelancesModel || !freelancesUnassignedModel) {
    return null;
  }

  return (
    <ModalWrapper title="Freelances" width={632} height={502} onClose={props.onClose}>
      <Row>
        <ModuleContext.Provider value={freelancesUnassignedModel}>
          <Grid
            handleRowClick={handleUnassignedRowClick}
            cellRenderers={{
              [IFields.freelance]: 'boolean',
            }}
          />
        </ModuleContext.Provider>
        <ModuleContext.Provider value={freelancesModel}>
          <Grid
            handleRowClick={handleRowClick}
            cellRenderers={{
              [IFields.freelance]: 'boolean',
            }}
          />
        </ModuleContext.Provider>
      </Row>
    </ModalWrapper>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export default FreelancesModal;
