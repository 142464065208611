import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';
import { IDialectAware } from '../../types/content';

type IProps = {
  dialects: IDialectAware[];
  selectedDialect: number;
  handleSelectedDialect(event: any, newSelectedDialect: number): void;
  className?: string;
};

export const TabsDialectAware: React.FC<IProps> = (props) => {
  const { dialects, selectedDialect, handleSelectedDialect, className } = props;

  const renderToggle = () => {
    return (
      <ToggleButtonGroupStyled value={selectedDialect} exclusive onChange={handleSelectedDialect}>
        {dialects.map((dialect) => (
          <ToggleButtonStyled value={dialect.dialectId} key={dialect.dialectId}>
            {dialect.dialectCode}
          </ToggleButtonStyled>
        ))}
      </ToggleButtonGroupStyled>
    );
  };

  const renderSelectedTab = () => {
    return <Tab>{props.children}</Tab>;
  };

  return (
    <Wrapper className={className}>
      {renderToggle()}
      {renderSelectedTab()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  && {
    margin: 10px;
  }
`;

const ToggleButtonStyled = styled(ToggleButton)`
  && {
    padding: 4px 20px;
  }
`;
