import React from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementImg } from '../../../../types/panelEstructure';

export type IProps = {
  data: IPanelEstructureElementImg;
};

const PanelBlockElementImg: React.FC<IProps> = ({ data }) => {
  return (
    <Wrapper>
      <img alt={''} src={'https://cdn2.wlingua.com/v3/img/' + data.src} />
    </Wrapper>
  );
};

export default PanelBlockElementImg;

const Wrapper = styled.div``;
