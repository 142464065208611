import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';
import LanguagesSelectModel from '../../models/LanguagesSelectModel';
import { ILanguage } from '../../models/LanguagesModel';
import ModuleContext from '../../CMFW/context/ModuleContext';
import KeyContext from '../../CMFW/context/KeyContext';
import { createKeyPath } from '../../CMFW/context/helper/keyContext';
import { useCurrentItemValue } from '../../hooks/useCurrentItemValue';

const KEY = 'language';

type IProps = {
  onChange?: (languageId: number) => void;
  keyName?: string;
  className?: string;
  disabled?: boolean;
};

const LanguageSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName ?? KEY);
  const moduleItemValue = module.getItemValue.bind(module)(aggregatedKeyName) as number;
  const [currentLanguageId, setCurrentLanguageId] = useCurrentItemValue(moduleItemValue, 0);
  const cacheKey = getCacheKey(IRefIds.languages, 0);
  const dataModel = useMemo(() => new LanguagesSelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    return [...cacheSelectData];
  }, [cacheSelectData]);

  const handleChange = (value: ILanguage) => {
    const selectedLanguageId = value.id;
    setCurrentLanguageId(selectedLanguageId);
    props.onChange && props.onChange(selectedLanguageId);
    module.updateItemValue.bind(module)(aggregatedKeyName, selectedLanguageId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Language"
      optionLabelKey="name"
      currentValue={currentLanguageId}
      disabled={props.disabled}
    />
  );
};

export default LanguageSelect;
