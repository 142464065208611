import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { NONE_OPTION } from 'components/selects/selects';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import HeadingSelectModel from 'models/HeadingSelectModel';
import React, { useContext, useMemo } from 'react';
import { IHeadingSelect } from 'types/headings';

const KEY = 'heading';

type IProps = {
  isLinked?: boolean;
  className?: string;
};

const HeadingSelect: React.FC<IProps> = (props) => {
  const { isLinked } = props;

  const module = useContext(ModuleContext);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);

  const moduleItemValue = (module.getItemValue.bind(module)(aggregatedKeyName) || NONE_OPTION.id) as number;

  const [currentHeadingId, setCurrentHeadingId] = useCurrentItemValue(moduleItemValue, NONE_OPTION.id, isLinked);

  const cacheKey = getCacheKey(IRefIds.heading);
  const dataModel = useMemo(() => new HeadingSelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    return [{ ...NONE_OPTION, heading: NONE_OPTION.title }, ...cacheSelectData];
  }, [cacheSelectData]);

  const handleChange = (value: IHeadingSelect) => {
    const selectedHeadingId = value.id;
    setCurrentHeadingId(selectedHeadingId);
    module.updateItemValue.bind(module)(aggregatedKeyName, selectedHeadingId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Heading"
      optionLabelKey="heading"
      currentValue={currentHeadingId}
      originalValue={isLinked && moduleItemValue}
    />
  );
};

export default HeadingSelect;
