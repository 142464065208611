import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import BooleanIcon from 'CMFW/input/BooleanIcon';
import Flag from 'CMFW/input/Flag';
import TextEditor from 'CMFW/input/TextEditor';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import HeadingSelect from 'components/selects/HeadingSelect';
import PanelSelect from 'components/selects/PanelSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import SegmentModel from 'models/SegmentModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import SegmentSearch from './SegmentSearch';
import { IFields } from '../../CMFW/grid/fields';
import GenderSelect from '../../components/selects/GenderSelect';
import { AudioPlayer } from '../../CMFW/layout/AudioPlayer';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { VersionFeedback } from '../../CMFW/layout/VersionFeedback';
import AIReviewPanel from '../AIReview/components/AIReviewPanel';
import { ListDialects } from '../../CMFW/layout/ListDialects';

export type IProps = {
  courseId: number;
  search?: string;
};

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
    ['BulletedList', 'NumberedList', 'HorizontalRule', 'headings', 'title'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

const Segment: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const segmentModel = useMemo(
    () => new SegmentModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  const isListeningUpdated = segmentModel.getItemUpdatedValue('listening');

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={segmentModel}>
        <Split widthLeft={540}>
          <ContainerLeft>
            <SegmentSearch />
            <Grid
              cellRenderers={{
                [IFields.stateContent]: 'stateSegmentContentRenderer',
              }}
              disableRowLoadFields={[IFields.stateContent]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <Button variant="outlined" size="small" onClick={segmentModel.checkSegments.bind(segmentModel)}>
                check
              </Button>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <Flag />
                <VersionFeedback />
                <BooleanIcon icon={faHeadphones} keyName={'listening'} onChange={forceRender} />
                {isListeningUpdated && <GenderSelect readbyKey={'readBy'} />}
                <PanelSelectStyled isLinked />
                <SaveButton />
              </EditorRow>
              <EditorRow>
                <HeadingSelect isLinked />
              </EditorRow>
              <Scroll>
                <AIReviewPanel />
                <ListDialects keyName={'targets'}>
                  <EditorRowStyled>
                    <TextEditor
                      keyName={'html'}
                      clKeyName={'conceptLinkerConcepts'}
                      keyError={'error'}
                      config={CK_EDITOR_CONFIG}
                    />
                    {isListeningUpdated && <AudioPlayer />}
                  </EditorRowStyled>
                </ListDialects>
              </Scroll>
              <EditorRow>
                <DeleteButton />
              </EditorRow>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Segment;

const PanelSelectStyled = styled(PanelSelect)`
  flex: 3;
`;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
`;

const EditorRowStyled = styled(EditorRow)`
  flex-grow: 1;
`;
