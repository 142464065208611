import React, { useState } from 'react';
import ReadingExerciseList from '../components/ReadingExerciseList';
import ReadingExerciseEdit from '../components/ReadingExerciseEdit';
import styled from 'styled-components';

const ReadingExercise: React.FC = () => {
  const [editExercise, setEditExercise] = useState(0);

  const onEditExercise = (exerciseId: number) => {
    setEditExercise(exerciseId);
  };

  return (
    <Container>
      <ReadingExerciseList onEditExercise={onEditExercise} />
      <ReadingExerciseEdit readingExerciseId={editExercise} />
    </Container>
  );
};

export default ReadingExercise;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
