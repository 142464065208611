import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { TextFieldStyled } from 'components/common/SelectInput';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  onSearch?: any;
};

const TextSearch: React.FC<IProps> = (props) => {
  const [searchText, setSearchText] = useState('');
  const [searchActive, setSearchActive] = useState(false);

  const module = useContext(ModuleContext);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(event.target.value);
  };

  const doSearch = () => {
    module.search({ text: searchText });
    if (searchText !== '') {
      setSearchActive(true);
    }
    props.onSearch && props.onSearch();
  };

  const reset = () => {
    setSearchActive(false);
    setSearchText('');
  };

  const doClear = () => {
    reset();
    module.search({});
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      doSearch();
    }
  };

  return (
    <>
      <EditorRow>
        <TextFieldStyled
          variant="outlined"
          onChange={onChange}
          onKeyDown={handleKeyDown}
          label={'Search'}
          value={searchText}
        />
        {searchActive && (
          <IconWrapper onClick={doClear}>
            <FontAwesomeIcon icon={faTimes} />
          </IconWrapper>
        )}
        <Button variant="outlined" size="small" onClick={doSearch}>
          search
        </Button>
      </EditorRow>
    </>
  );
};

const IconWrapper = styled.div`
  display: inline-block;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 20px;
`;

export default TextSearch;
