import { ConjugationButton } from './ConjugationButton';
import React from 'react';
import TextInput from '../../../../CMFW/input/TextInput';

const InflectTableCellInflectConj: React.FC = () => {
  return (
    <>
      <ConjugationButton />
      <TextInput keyName={'word'} />
    </>
  );
};

export default InflectTableCellInflectConj;
