import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { IItemId } from 'types/content';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import * as lodash from 'lodash';
import { ITranslation } from '../types/Translation';
import TranslationManagerModel from './TranslationManagerModel';

export type ITableLiteral = IItemId & {
  code: string;
  name: string;
};

export type ITableLiteralEdit = IItemId & {
  code: string;
  deletable: boolean;
  sources: ITranslation[];
  tables: number[];
  charts: number[];
  conjTemplates: number[];
};

export default class TableLiteralModel extends BaseModel<ITableLiteral, ITableLiteralEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.tableLiterals;
    this.gridName = 'TableLiterals';
    this.getGridColumns();
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  public getCourseId() {
    return this.courseId;
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ITableLiteral[]>(`courses/${this.courseId}/literals`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ITableLiteral>(`courses/${this.courseId}/literals/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const [item, sources] = await Promise.all([
      get<ITableLiteralEdit>(`literals/new`),
      this.translations.new(this.courseId),
    ]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  async updateItem() {
    const { id, sources } = this.itemUpdated as ITableLiteralEdit;
    await put(`literals/${id}`, lodash.omit(this.itemUpdated, 'sources'));
    await this.translations.update(id, sources);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const { sources } = this.itemUpdated as ITableLiteralEdit;
    const result = await post<IItemId>(`courses/${this.courseId}/literals/`, lodash.omit(this.itemUpdated, 'sources'));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await this.translations.update(newItemId, sources);
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    await super.delete();
    const { id } = this.itemUpdated;

    const result = await del(`literals/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateCache();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.tableLiterals, this.courseId)));
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, sources] = await Promise.all([
      get<ITableLiteralEdit>(`literals/${itemId}`),
      this.translations.get(itemId),
    ]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }
}
