import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IModelCourseOptions } from './BaseModel';
import PanelsModel from './PanelsModel';

export type IPanelFactory = {
  id: number;
  level: number;
  item: string;
  nInflects: number;
  wordClass: string;
  kLines: string;
  kLineIds: number[];
  title: string;
  concept: string;
  nLockedVoc: number;
  nLockedByVoc: number;
  nLockedPanel: number;
  nLockedByPanel: number;
  notes: number;
  nAutoCompounds: number;
};

export default class PanelsFactoryModel extends PanelsModel<IPanelFactory> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.panelsFactory;
    this.gridName = 'PanelsFactory';
    this.getGridColumns();
  }

  async getGridRow(itemId: number) {
    return get<IPanelFactory>(`groups/${this.courseId}/panels-factory/${itemId}`);
  }

  async load() {
    this.cancelGetGrid();
    this.loadingGrid = true;
    this.render();
    this.gridData = await get<IPanelFactory[]>(`groups/${this.courseId}/panels-factory`);
    this.loadingGrid = false;
    this.render();
  }
}
