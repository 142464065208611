import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { Loading } from 'components/common/Loading';
import { EditorRow } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import PanelsModel from 'models/PanelsModel';
import StateModel, { IState, STATE_NAMES } from 'models/StateModel';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BOARD_TYPE_ICON, BOARD_TYPE_NAME, IBoardTypes } from '../../../models/BoardsModel';

export type IStateModal = {
  courseId: number;
  onClose: () => void;
  refId: number;
  type: IBoardTypes;
  state: IState;
};

const StatesModal: React.FC<IStateModal> = (props) => {
  const { refId, type, state } = props;
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext) as PanelsModel;
  const iconClass = BOARD_TYPE_ICON[type];

  const stateModel = useMemo(
    () =>
      new StateModel({
        courseId: props.courseId,
        render: forceRender,
        refId,
        type,
        state,
        updateParentModelGridRow: moduleModel.updateGridRow.bind(moduleModel),
      }),
    [forceRender, type, moduleModel, refId, state, props.courseId],
  );

  const [isForceChangeModalOpen, setIsForceChangeModalOpen] = useState(false);
  const [currentStateSelection, setCurrentStateSelection] = useState(stateModel.state);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage('');
  }, [stateModel.state]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const getExtraForm = useCallback(() => {
    const handleChangeState = async (state: IState, message?: string) => {
      await stateModel.change(state, message);
      props.onClose();
    };
    switch (stateModel.state) {
      case IState.NONE:
        return (
          <EditorRow>
            <Button variant="contained" size="small" onClick={() => handleChangeState(IState.TODO)}>
              Change to To Do
            </Button>
          </EditorRow>
        );
      case IState.REVIEW:
        return (
          <>
            <EditorRow>
              <TextFieldStyled value={message} variant="outlined" onChange={handleMessageChange} />
            </EditorRow>
            <EditorRow>
              <Button variant="contained" size="small" onClick={() => handleChangeState(IState.DOING, message)}>
                Back to Doing
              </Button>
              <Button variant="contained" size="small" onClick={() => handleChangeState(IState.DONE, message)}>
                Change to Done
              </Button>
            </EditorRow>
          </>
        );

      default:
        return null;
    }
  }, [stateModel, message, props]);

  const handleForceChangeClick = () => {
    setIsForceChangeModalOpen(true);
  };

  const handleForceChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setCurrentStateSelection(event.target.value as number);
  };

  const handleForceChangeClose = () => {
    setIsForceChangeModalOpen(false);
  };

  const handleForceChangeState = async () => {
    setIsForceChangeModalOpen(false);
    await stateModel.change(currentStateSelection);
    props.onClose();
  };

  const modalTitle = `State for ${BOARD_TYPE_NAME[type]}`;

  return (
    <ModalWrapper width={318} height={390} title={modalTitle} onClose={props.onClose}>
      <TopContainer>
        <State>
          <Icon className={`fa ${iconClass} state${stateModel.state}`} />
          <span>{STATE_NAMES[stateModel.state]}</span>
        </State>
        {getExtraForm()}
      </TopContainer>
      <ModuleContext.Provider value={stateModel}>
        <Grid />
      </ModuleContext.Provider>
      <Button variant="contained" size="small" onClick={handleForceChangeClick}>
        force change state
      </Button>
      {stateModel.loadingGrid && <Loading />}
      {isForceChangeModalOpen && (
        <ModalWrapper width={200} height={120} title={'Change state'} onClose={handleForceChangeClose}>
          <Select value={currentStateSelection} onChange={handleForceChangeSelect}>
            {Object.keys(STATE_NAMES).map((key) => (
              <MenuItem key={key} value={key}>
                <Icon className={`fa ${iconClass} state${key}`} />
                {STATE_NAMES[parseInt(key) as IState]}
              </MenuItem>
            ))}
          </Select>{' '}
          <ButtonForceChange variant="contained" size="small" onClick={handleForceChangeState}>
            change
          </ButtonForceChange>
        </ModalWrapper>
      )}
    </ModalWrapper>
  );
};

const TextFieldStyled = styled(TextField)`
  width: 100%;
`;

const ButtonForceChange = styled(Button)`
  && {
    margin-top: 20px;
  }
`;

const State = styled.p`
  margin: 0;
`;

const Icon = styled.span`
  margin-right: 4px;
`;

const TopContainer = styled.span`
  flex-direction: column;
  height: 100%;
`;

export default StatesModal;
