import { TextFieldStyled } from 'components/common/styles';
import React, { useContext } from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { Button } from '@material-ui/core';
import CATModel from '../../../models/CATModel';

type IProps = {
  onClose: () => void;
};

const CATReplace: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as CATModel;

  const [search, setSearch] = React.useState('');
  const [replace, setReplace] = React.useState('');

  const handleReplace = () => {
    module.replace(search, replace);
    props.onClose();
  };

  return (
    <ModalWrapper onClose={props.onClose} title={`Search & Replace`} width={400} height={200}>
      <TextFieldStyled value={search} onChange={(event) => setSearch(event.target.value)} label={'Search'} />
      <br />
      <TextFieldStyled value={replace} onChange={(event) => setReplace(event.target.value)} label={'Replace with'} />
      <br />
      <Button variant="contained" size="small" color="primary" onClick={handleReplace}>
        replace
      </Button>
    </ModalWrapper>
  );
};

export default CATReplace;
