import { IGridData } from '../types/models';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { get } from '../services/request';
import { IPosition } from '../types/content';

export interface IQARelatedItem extends IGridData {
  orig: boolean;
  itemId: number;
  position: IPosition;
  ref: number;
  refName: string;
  title: string;
  target: string;
  source: string;
  nUses: number;
  nInflects: number;
}

export default class QAItemsRelatedModel extends BaseModel<IQARelatedItem, {}> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'QARelatedItems';
    this.getGridColumns();
  }

  loadRelatedItems = async (ref: number, sourceDialectId: number, itemId: number) => {
    this.gridData = [];
    this.loadingGrid = true;
    this.render();
    this.gridData = await get(
      `QA/groups/${this.courseId}/refs/${ref}/source-dialects/${sourceDialectId}/items-related/${itemId}`,
      {},
    );
    this.loadingGrid = false;
    this.render();
  };
}
