import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { IReadingText } from '../../../types/reading';
import { CLDetails } from '../../../components/cl/CLDetails';

export type IProps = {
  text: IReadingText;
  index: number;
  dnd: boolean;
  onEditText: Function;
  editing: boolean;
};

const ReadingTextListText: React.FC<IProps> = (props) => {
  const { text } = props;

  const onEditSegment = () => {
    props.onEditText(text.id);
  };

  return (
    <Draggable isDragDisabled={!props.dnd} draggableId={props.text.id.toString()} index={props.index}>
      {(provided) => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onEditSegment}
          editing={props.editing}
        >
          <ReadingDetails>
            <CLDetails details={text.clDetails} />
          </ReadingDetails>
          <ReadingText>{text.title}</ReadingText>
          <Title dangerouslySetInnerHTML={{ __html: text.text.replace(/\n/g, '<br>') }} />
          <ReadingAudio>
            {text.audioStart} - {text.audioEnd}
          </ReadingAudio>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default ReadingTextListText;

const ReadingText = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const ReadingDetails = styled.div`
  display: inline-block;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

const ReadingAudio = styled.div`
  font-weight: bold;
  font-size: 10px;
  text-align: right;
`;

const Title = styled.div`
  min-height: 20px;
  font-size: 14px;
`;

const Wrapper = styled.div<{ editing: boolean }>`
  border: solid 1px #bfbfbf;
  background-color: ${(props) => (props.editing ? '#e9dec7' : '#f5f5f5')};
  position: relative;
  margin: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;
