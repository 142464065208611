import ModuleContext from 'CMFW/context/ModuleContext';
import { FormContainer } from 'components/common/styles';
import React, { useContext } from 'react';

const ItemLayout: React.FC = (props) => {
  const model = useContext(ModuleContext);

  if (!model.itemUpdated) {
    return null;
  }

  return <FormContainer>{props.children}</FormContainer>;
};

export default ItemLayout;
