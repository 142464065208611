import React from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementGridWords } from '../../../../types/panelEstructure';

export type IProps = {
  data: IPanelEstructureElementGridWords;
};

const PanelBlockElementGridWords: React.FC<IProps> = ({ data }) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: data.gridWords.join(', ') }} />;
};

export default PanelBlockElementGridWords;

const Wrapper = styled.div`
  padding-left: 6px;
  font-size: 16px;
`;
