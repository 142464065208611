import React from 'react';
import styled from 'styled-components';
import { IFeedback } from '../../../../models/FeedbackModel';
import { useDispatch } from 'react-redux';
import { IRefIds } from '../../../../constants/refs';
import { tabsOpened } from '../../../../store/actions/tabs';

export type IProps = {
  feedback: IFeedback;
  courseId: number;
  showDialectCode?: boolean;
  refId?: IRefIds;
  itemId?: number;
  className?: string;
  onClick?: () => void;
};

const FeedbackListItem: React.FC<IProps> = ({
  feedback,
  courseId,
  showDialectCode,
  refId,
  itemId,
  className,
  onClick,
}) => {
  const dispatch = useDispatch();

  const getColor = (feedback: IFeedback) => {
    if (feedback.n < 10) {
      return '#CECECE';
    }
    const range = Math.floor(feedback.median / 10);
    switch (range) {
      case 10:
      case 9:
        return '#2cba00';
      case 8:
        return '#4cd44b';
      case 7:
        return '#ffa700';
      default:
        return '#fa2465';
    }
  };

  const openId = () => {
    onClick && onClick();
    if (refId && itemId) {
      dispatch(
        tabsOpened({
          refId: refId,
          search: itemId.toString(),
          courseId: courseId,
        }),
      );
    }
  };

  return (
    <FeedbackRow key={feedback.dialectId} className={className}>
      {showDialectCode && feedback.dialectCode}
      <Feedback bgColor={getColor(feedback)} onClick={openId}>
        {feedback.n > 0 ? feedback.median : '-'}
      </Feedback>
    </FeedbackRow>
  );
};

export default FeedbackListItem;

const FeedbackRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
`;

const Feedback = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  margin: 3px;
  background-color: ${(props) => props.bgColor};
  color: white;
  cursor: pointer;
`;
