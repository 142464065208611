import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import LessonModel, { ILesson } from 'models/LessonModel';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';
import { useCurrentItemValue } from '../../hooks/useCurrentItemValue';
import { NONE_OPTION } from './selects';
import KeyContext from '../../CMFW/context/KeyContext';
import { createKeyPath } from '../../CMFW/context/helper/keyContext';

const KEY = 'lesson';

type IProps = {
  isLinked?: boolean;
  handleLessonChanged?: (id: number, lessonPos: number) => void;
  courseId?: number;
  label?: string;
  className?: string;
};

export const LessonSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const keyContext = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(keyContext, KEY);
  const moduleItemValue = module.getItemValue(aggregatedKeyName);
  const [currentLessonId, setCurrentLessonId] = useCurrentItemValue(moduleItemValue, NONE_OPTION.id, props.isLinked);

  let { courseId } = useContext(ModuleContext);
  if (props.courseId) {
    courseId = props.courseId;
  }
  const cacheKey = getCacheKey(IRefIds.lessons, courseId);
  const dataModel = useMemo(() => new LessonModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (value: ILesson) => {
    const lessonId = value.id;
    setCurrentLessonId(lessonId);
    props.isLinked && module.updateItemValue(aggregatedKeyName, lessonId);
    props.handleLessonChanged && props.handleLessonChanged(lessonId, value.position);
  };

  return (
    <SelectLayout
      className={props.className}
      data={cacheSelectData}
      onChange={handleChange}
      label={props.label ?? 'Lesson'}
      optionLabelKey="title"
      processOptionLabel={(label: string, option?: ILesson) => option?.position + ' - ' + label}
      currentValue={currentLessonId}
      originalValue={props.isLinked && moduleItemValue}
    />
  );
};
