import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyContext from '../../CMFW/context/KeyContext';
import { createKeyPath } from '../../CMFW/context/helper/keyContext';
import { changeAwareMixin, IChangeAwareProps } from '../common/styles';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { TranslationStatusIcon } from './TranslationStatusIcon';
import { ITranslationStatus, TranslationNames } from '../../types/Translation';

const KEY = 'status';

type IProps = {
  status?: ITranslationStatus;
  onChange?: (status: number) => void;
};

const DISABLED_STATUS = [
  ITranslationStatus.TRANSLATION_DOING,
  ITranslationStatus.TRANSLATION_FROM_TARGET,
  ITranslationStatus.TRANSLATION_LEGACY,
];

export const TranslationStatusSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);
  const moduleItemValue = module.getItemValue(aggregatedKeyName);

  const initialStatus = props.status === undefined ? moduleItemValue : props.status;

  const [currentStatus, setCurrentStatusId] = useCurrentItemValue(
    initialStatus,
    props.status ?? 0,
    props.status === undefined,
  );

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const status = event.target.value as number;
    if (!DISABLED_STATUS.includes(status)) {
      setCurrentStatusId(status);
      module.updateItemValue(aggregatedKeyName, status);
      props.onChange && props.onChange(status);
    }
  };

  const disabled = DISABLED_STATUS.includes(initialStatus);

  return (
    <SelectStyled
      value={currentStatus}
      onChange={handleChange}
      $changed={initialStatus !== currentStatus}
      disabled={disabled}
    >
      {Object.keys(TranslationNames).map((status, index) => (
        <MenuItem value={index} key={index}>
          <ListItemIcon>
            <TranslationStatusIcon status={parseInt(status)} />
          </ListItemIcon>
          <ListItemText>{TranslationNames[parseInt(status) as ITranslationStatus]}</ListItemText>
        </MenuItem>
      ))}
    </SelectStyled>
  );
};

const SelectStyled = styled(Select)<IChangeAwareProps>`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 14px;
  overflow: hidden;
  & .MuiSelect-root {
    padding-left: 0;
    height: 0;
  }
  & .MuiSvgIcon-root {
    display: none;
  }
  ${changeAwareMixin}
`;
