import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { AutoSizer, CellMeasurerCache, List } from 'react-virtualized';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import CATTableRow from './CATTableRow';
import CATModel, { ICATData } from '../../../models/CATModel';
import { ITranslationStatus } from '../../../types/Translation';
import CATTableFilter from './CATTableFilter';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATableFooter from './CATTableFooter';

export type IProps = {
  data: ICATData[];
  onUpdateTarget: (id: number, target: string, status: ITranslationStatus) => void;
};

const CATTable: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as CATModel;
  const listRef = useRef<List>(null);
  const [indexToRender, setIndexToRender] = useState<number | null>(null);
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 21,
      }),
    [],
  );

  useEffect(() => {
    setTimeout(() => cache.clearAll(), 100);
  }, [props.data, cache]);

  useEffect(() => {
    listRef.current && listRef.current.scrollToRow(module.findRowByActive());
  }, [module, module.activeId]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (indexToRender !== null) {
        listRef.current && listRef.current.recomputeRowHeights(indexToRender);
        listRef.current && listRef.current.render();
        setIndexToRender(null);
      }
    }, 100);
    return () => {
      clearTimeout(timer1);
    };
  }, [indexToRender]);

  const updateListRowHeights = (index: number) => {
    setIndexToRender(Math.min(indexToRender ?? Infinity, index));
  };

  const renderRow = (row: ListRowProps): ReactNode => {
    const data = props.data[row.index];
    return (
      <CATTableRow
        key={data.id}
        data={data}
        row={row}
        activeId={module.activeId}
        cache={cache}
        onChangeHeight={updateListRowHeights}
        onUpdateTarget={props.onUpdateTarget}
      />
    );
  };

  return (
    <Container>
      <CATTableFilter />
      <Container>
        <AutoSizer onResize={() => cache.clearAll()}>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={cache.rowHeight}
              rowRenderer={renderRow}
              rowCount={props.data.length}
              deferredMeasurementCache={cache}
              ref={listRef}
            />
          )}
        </AutoSizer>
      </Container>
      <CATableFooter />
    </Container>
  );
};

export default CATTable;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
