import { del, get } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';

type IFreelance = {
  id: number;
  boardId: number;
  name: string;
};

export default class BoardsFreelancesModel extends BaseModel {
  boardId: number;

  constructor(options: IModelOptions & { boardId: number }) {
    super(options);
    this.boardId = options.boardId;
    this.gridName = 'Users';
    this.getGridColumns();
    this.getGrid();
  }

  async getGrid() {
    await super.getGrid();

    this.gridData = await get<IFreelance[]>(`boards/${this.boardId}/freelances`);

    this.loadingGrid = false;
    this.render();
  }

  async unassignFreelance(userId: number) {
    this.loadingGrid = true;
    this.render();
    await del(`boards/${this.boardId}/freelances/${userId}`);
    this.getGrid();
  }
}
