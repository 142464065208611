import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { IItemId } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';

export type IConjTemplate = IItemId & {
  name: string;
  showToUser: boolean;
  wordClass: string;
  literal: string;
  order: number;
};

type IConjTemplateEdit = IItemId & {
  name: string;
  html: string;
  showUsers: boolean;
  wordClass: number;
  literal: number;
  order: number;
  deletable: boolean;
};

export default class ConjugationTemplateModel extends BaseModel<IConjTemplate, IConjTemplateEdit> {
  readonly DIFFERENT_NEW_ID = -1;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.conjugationTemplate;
    this.gridName = 'ConjugationTemplate';
    this.getGridColumns();
  }

  async getGridSearch(text: string) {
    await super.getGrid();
    this.render();

    this.gridData = await get<IConjTemplate[]>(`/courses/${this.courseId}/conj-template?text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  getGridData() {
    return get<IConjTemplate[]>(`courses/${this.courseId}/conj-template`);
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = (await this.getGridData()).sort((a, b) => {
      if (a.wordClass === b.wordClass) {
        return a.order - b.order;
      } else {
        return a.wordClass.localeCompare(b.wordClass);
      }
    });
    this.loadingGrid = false;

    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IConjTemplate>(`courses/${this.courseId}/conj-template/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<IConjTemplateEdit>(`conj-template/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.DIFFERENT_NEW_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.conjugationTemplate, this.courseId)));
  }

  async updateItem() {
    const { id } = this.itemUpdated;
    await put(`conj-template/${id}`, this.itemUpdated);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<IItemId>(`courses/${this.courseId}/conj-template`, {
      ...this.itemUpdated,
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`conj-template/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<IConjTemplateEdit>(`conj-template/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGridSearch(data.text ?? '');
  }
}
