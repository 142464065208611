import React, { useContext } from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementParagraph } from '../../../../types/panelEstructure';
import PanelEstructureContext from '../context/PanelEstructureContext';
import { ITranslation } from '../../../../types/Translation';

export type IProps = {
  data: IPanelEstructureElementParagraph;
};

const PanelBlockElementParagraph: React.FC<IProps> = ({ data }) => {
  const panelEstructureContext = useContext(PanelEstructureContext);

  const getSource = (): ITranslation | null => {
    const source = data.sources.find((d) => d.dialectId === panelEstructureContext.sourceDialectId);
    if (source) {
      return source;
    } else {
      return null;
    }
  };

  const source = getSource();
  return <Wrapper dangerouslySetInnerHTML={{ __html: source?.text ?? '' }} />;
};

export default PanelBlockElementParagraph;

const Wrapper = styled.div`
  font-size: 16px;
  & > p {
    margin: 0 0 0 6px;
  }
`;
