import Button from '@material-ui/core/Button';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';
import TextEditor from '../../../CMFW/input/TextEditor';

type IProps = {
  words: string[];
  onClose: () => void;
  onEditWords: (words: string[]) => void;
};

const CK_EDITOR_CONFIG = {
  toolbar: [['Undo', 'Redo']],
  toolBarInline: [['Bold', 'lang_target']],
};

const WordGridEditModal: React.FC<IProps> = (props) => {
  const origWords = props.words.join(', ');
  const [words, setWords] = useState(origWords);

  const handleClose = () => {
    props.onClose();
  };

  const handleUpdate = () => {
    props.onEditWords(
      words
        .replace(/<(\/)*p[^>]*>/g, '')
        .split(',')
        .map((w) => w.trim()),
    );
    props.onClose();
  };

  const onChange = (value: string) => {
    setWords(value);
  };

  return (
    <ModalWrapper width={450} height={220} onClose={handleClose} title="Edit Words Grid">
      <TextEditor keyName={'none'} text={words} config={CK_EDITOR_CONFIG} autoGrow onChangeText={onChange} />
      <Button variant="contained" size="small" color="primary" onClick={handleUpdate}>
        Update
      </Button>
    </ModalWrapper>
  );
};

export default WordGridEditModal;
