import React from 'react';
import styled from 'styled-components';
import { ILessonFeedback } from '../../../models/FeedbackModel';
import FeedbackCycle from './FeedbackCycle';

export type IProps = {
  lesson: ILessonFeedback;
};

const FeedbackLesson: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Position>{props.lesson.position}</Position>
        <Title dangerouslySetInnerHTML={{ __html: props.lesson.title }} />
      </TitleWrapper>

      <WrapperCycles>
        {props.lesson.cycles.map((cycle) => (
          <FeedbackCycle cycle={cycle} key={`cycle-${cycle.id}`} />
        ))}
      </WrapperCycles>
    </Wrapper>
  );
};

export default FeedbackLesson;

const Wrapper = styled.div`
  background-color: white;
  position: relative;
  margin: 20px 4px;
`;

const WrapperCycles = styled.div`
  min-height: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 2px 0;
`;

const Title = styled.p`
  margin: 0;
  flex: 1;
`;

const Position = styled.p`
  margin: 0 4px;
  font-size: 16px;
`;
