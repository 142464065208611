import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerLeft, ContainerLeftFooter, ContainerRight, ModuleContainer } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import { IFields } from '../../CMFW/grid/fields';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import TextSearch from '../../components/search/TextSearch';
import ReadingModel from '../../models/ReadingModel';
import ReadingEdit from './tabs/ReadingEdit';
import ReadingExercise from './tabs/ReadingExercise';
import ReadingText from './tabs/ReadingText';
import Button from '@material-ui/core/Button';

export type IProps = {
  courseId: number;
  search?: string;
};

const Reading: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const readingModel = useMemo(
    () => new ReadingModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={readingModel}>
        <Split widthLeft={500}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.stateContent]: 'stateReadingContentRenderer',
              }}
              disableRowLoadFields={[IFields.stateContent]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <Button variant="outlined" size="small" onClick={readingModel.checkReadings.bind(readingModel)}>
                check
              </Button>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={['Edit', 'Text', 'Exercises']}>
                <ReadingEdit key={'Edit' + readingModel.itemId} />
                <ReadingText key={'Texts' + readingModel.itemId} />
                <ReadingExercise key={'Exercises' + readingModel.itemId} />
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Reading;
