import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import ReadingTextSelectModel from 'models/ReadingTextSelectModel';
import React, { useContext, useMemo } from 'react';
import { IReadingTextSelect } from 'types/reading';

const KEY = 'readingText';

type IProps = {
  readingId: number;
};

const NONE_TEXT: IReadingTextSelect = { id: 0, name: 'ALL' };

const ReadingTextSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);

  const keyContext = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(keyContext, KEY);

  const cacheKey = getCacheKey(IRefIds.readingText, props.readingId);
  const dataModel = useMemo(() => new ReadingTextSelectModel(props.readingId), [props.readingId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    return [NONE_TEXT, ...cacheSelectData];
  }, [cacheSelectData]);

  const moduleItemValue: number = module.getItemValue.bind(module)(aggregatedKeyName) || NONE_TEXT.id;

  const [currentReadingText, setCurrentReadingText] = useCurrentItemValue(moduleItemValue, NONE_TEXT.id);

  const handleChange = (value: IReadingTextSelect) => {
    const readingTextId = value.id;
    setCurrentReadingText(readingTextId);
    module.updateItemValue.bind(module)(aggregatedKeyName, readingTextId);
  };

  return (
    <SelectLayout
      data={selectData}
      onChange={handleChange}
      label="Reading Part"
      optionLabelKey="name"
      currentValue={currentReadingText}
      originalValue={moduleItemValue}
    />
  );
};

export default ReadingTextSelect;
