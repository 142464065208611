import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ITranslationStatus } from '../../../types/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export type IProps = {
  status: ITranslationStatus;
  onUpdateStatus: (status: ITranslationStatus) => void;
};

const CATStatus: React.FC<IProps> = (props) => {
  const [status, setStatus] = useState(props.status);

  useEffect(() => setStatus(props.status), [props.status]);

  const onChange = () => {
    if (status !== ITranslationStatus.TRANSLATION_DONE) {
      setStatus(ITranslationStatus.TRANSLATION_DONE);
      props.onUpdateStatus(ITranslationStatus.TRANSLATION_DONE);
    } else {
      setStatus(ITranslationStatus.TRANSLATION_DOING);
      props.onUpdateStatus(ITranslationStatus.TRANSLATION_DOING);
    }
  };

  if (status === ITranslationStatus.TRANSLATION_DONE) {
    return <FontAwesomeIconGreen icon={faCheck} onClick={onChange} />;
  } else {
    return <FontAwesomeIconRed icon={faCheck} onClick={onChange} />;
  }
};

export default CATStatus;

const FontAwesomeIconGreen = styled(FontAwesomeIcon)`
  color: #329415;
  font-size: 14px;
  cursor: pointer;
`;

const FontAwesomeIconRed = styled(FontAwesomeIcon)`
  color: #ccc;
  font-size: 14px;
  cursor: pointer;
`;
