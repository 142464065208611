import { get } from '../services/request';

export type IVocCategorySelect = {
  id: number;
  name: string;
  nChildren: number;
  fullPath: string;
  parent: number;
};

export default class VocCategorySelectModel {
  dialectId: number;

  constructor(dialectId = 0) {
    this.dialectId = dialectId;
  }

  async get() {
    return await get<IVocCategorySelect[]>(`/voc-categories/dialects/${this.dialectId}`);
  }
}
