import { TextFieldProps } from '@material-ui/core/TextField';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useEffect } from 'react';
import CATEditor from '../../modules/CAT/components/CATEditor';
import CATModel from '../../models/CATModel';

type IProps = TextFieldProps & {
  keyName: string;
};

const cleanText = (text: string): string => {
  if (text) {
    return text.replace(/\t/g, '').trim();
  } else {
    return '';
  }
};

const toModule = (text: string): string => {
  text = cleanText(text);
  const lines = text.split('\n');
  text = '<p>' + lines.join('</p><p>') + '</p>';
  return CATModel.tToTarget(text);
};

const fromModule = (text: string): string => {
  text = CATModel.targetToT(text);
  text = text.replace(/\n\n/g, '\n');
  text = cleanText(text);
  return CATModel.cleanP(text);
};

const HTMLSourceInput: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: string = fromModule(module.getItemValue.bind(module)(aggregatedKeyName) ?? '');
  const moduleItemValueUpdated: string = fromModule(module.getItemUpdatedValue(aggregatedKeyName) ?? '');

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, '');

  useEffect(() => {
    setCurrentValue(moduleItemValueUpdated);
  }, [moduleItemValueUpdated, setCurrentValue]);

  const onChange = (text: string) => {
    setCurrentValue(text);
    module.updateItemValue.bind(module)(aggregatedKeyName, toModule(text));
  };
  const changed = moduleItemValue !== currentValue;
  if (changed) {
    console.log('Module ', moduleItemValue);
    console.log('Current', currentValue);
  }

  return <CATEditor text={currentValue} onChange={onChange} changed={changed} />;
};

export default HTMLSourceInput;
