import InflectTable from './InflectTable';
import InflectsModel, { IInflectTable, IInflectTableCellAugmented } from 'models/InflectsModel';
import { BASE_TABLE_ID, CL_FIX_TABLE_ID, IInflectIdName } from 'models/InflectTablesModel';
import React, { ReactNode, useContext } from 'react';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import KeyContext from '../../../CMFW/context/KeyContext';
import { IInflectBase } from '../../../models/BaseInflectsModel';
import { LoadingItem } from '../../../CMFW/layout/Loading';

type IProps = {
  cellRenderer: ReactNode;
  showTrash: boolean;
};

const extractor = function (arr: IInflectIdName[]) {
  const data: { [key: number]: IInflectIdName } = {};
  arr.forEach(function (item) {
    data[item.id] = item;
  });
  return data;
};

const InflectTables: React.FC<IProps> = (props) => {
  const inflectsModel = useContext(ModuleContext) as InflectsModel;

  const headersById = extractor(inflectsModel.getTableHeaders());
  const tablesById = extractor(inflectsModel.getTables());
  const data: IInflectBase[][] = [];

  inflectsModel.getItemUpdatedValue('', []).forEach((item: IInflectBase) => {
    if (data[item.inflectId]) {
      data[item.inflectId].push(item);
    } else {
      data[item.inflectId] = [item];
    }
  });

  const inflectTables: { [key: number]: IInflectTable } = {};

  inflectsModel.getTableCells().forEach((cell) => {
    const { table: tableId } = cell;
    const augmentedCell: IInflectTableCellAugmented = {
      ...cell,
      rowHeader: headersById[cell.row],
      columnHeader: headersById[cell.column],
      values: data[cell.id] || [{ inflectId: cell.id }],
    };

    if (tablesById[tableId]) {
      if (!inflectTables[tableId]) {
        inflectTables[tableId] = { ...tablesById[tableId], inflects: [] };
      }

      inflectTables[tableId].inflects.push(augmentedCell);
    }
  });

  const tables: IInflectTable[] = [];
  Object.values(inflectTables).forEach((table) => {
    if (table.inflects || table.id === BASE_TABLE_ID || table.id === CL_FIX_TABLE_ID) {
      tables.push(table);
    }
  });

  return (
    <KeyContext.Provider value={''}>
      {tables.map((table) => (
        <InflectTable key={table.id} table={table} cellRenderer={props.cellRenderer} showTrash={props.showTrash} />
      ))}
      <LoadingItem />
    </KeyContext.Provider>
  );
};

export default InflectTables;
