import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { ICheck } from '../components/modal/FailMessageModal';

type IOptions = {
  render: () => void;
  checkDone: () => void;
  publishDone: () => void;
  courseId: number;
  ref: IRefIds;
  name: string;
  batch: number;
};

export default class PublishRefModel {
  checks: ICheck[] = [];
  checking = true;
  checkFailed = false;
  render: () => void;
  checkDone: () => void;
  publishDone: () => void;
  publishing = false;
  completed = false;
  courseId: number;
  ref: IRefIds;
  name: string;
  batch: number;
  items: number[] = [];
  done: number[] = [];

  constructor(options: IOptions) {
    this.render = options.render;
    this.checkDone = options.checkDone;
    this.publishDone = options.publishDone;
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.name = options.name;
    this.batch = options.batch;
  }

  async getCheck() {
    this.checking = true;
    this.checks = [];
    this.checkFailed = false;
    this.render();
    this.checks = await get<ICheck[]>(`groups/${this.courseId}/refs/${this.ref}/publish-check`);
    this.checking = false;
    this.checkFailed = this.checks && this.checks.some((check) => check.details.length > 0);
    this.items = this.checks.map((check) => check.id);
    this.completed = this.items.length === 0;
    this.render();
    this.checkDone();
  }

  async publish() {
    if (!this.completed) {
      this.publishing = true;
      this.render();
      let batchIds: number[] = [];
      if (this.batch !== 0) {
        batchIds = this.items.slice(this.done.length, this.done.length + this.batch);
      }

      await get(`groups/${this.courseId}/refs/${this.ref}/publish?ids=${batchIds.join(',')}`);
      if (batchIds.length === 0) {
        this.done = this.items;
      } else {
        this.done = [...this.done, ...batchIds];
      }
      this.render();

      if (this.done.length === this.items.length) {
        this.completed = true;
        this.publishing = false;
        this.publishDone();
      } else {
        await this.publish();
      }
    }
  }
}
