import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export interface IAudioDashboardGrid {
  id: number;
  ref: number;
  name: string;
  pending: number;
  doing: number;
  pendingReview: number;
  done: number;
  donePercent: string;
}

export interface IAudioWordsCount {
  [key: string]: number;
}

export default class AudioCreateSetModel {
  async getWordsCount(courseId: number, targetDialectId: number, ref: IRefIds, startLesson: number, endLesson: number) {
    return await get<IAudioWordsCount>(
      `/courses/${courseId}/target-dialects/${targetDialectId}/refs/${ref}/audio-words-count`,
      {
        startLesson,
        endLesson,
      },
    );
  }

  async createSets(
    courseId: number,
    targetDialectId: number,
    ref: IRefIds,
    startLesson: number,
    endLesson: number,
    artists: number[],
  ) {
    return await get<IAudioWordsCount>(
      `/courses/${courseId}/target-dialects/${targetDialectId}/refs/${ref}/audio-create-sets`,
      {
        startLesson,
        endLesson,
        artists: artists.join(','),
      },
    );
  }
}
