import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from 'CMFW/context/ModuleContext';
import PanelCard from 'components/common/PanelCard';
import { Garbage } from 'components/common/styles';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IPanel } from '../../../models/PanelsModel';
import PanelSearchModal from '../modal/PanelSearchModal';

export type IProps = {
  keyName: string;
};

const PanelStopInflectPanel: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const stopPanelId = moduleModel.getItemUpdatedValue(props.keyName);

  useEffect(() => {
    setCurrentValue(stopPanelId);
  }, [stopPanelId]);

  const handleClick = () => {
    if (stopPanelId !== 0) {
      moduleModel.updateItemValue(props.keyName, 0);
      setCurrentValue(0);
    } else {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChoosePanel = (panel: IPanel) => {
    moduleModel.updateItemValue(props.keyName, panel.id);
    setCurrentValue(panel.id);
    handleClose();
  };

  return (
    <Container>
      <Button onClick={handleClick}>
        {currentValue ? <Garbage icon={faTrash} /> : <Plus icon={faPlus} />}
        <span>Stop Linking after Panel</span>
      </Button>
      {currentValue !== 0 && <PanelCard id={currentValue} />}
      {openModal && <PanelSearchModal onChoosePanel={handleChoosePanel} onClose={handleClose} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  margin: 10px 0;
  color: gray;
  font-weight: bold;
  cursor: pointer;
`;

const Plus = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export default PanelStopInflectPanel;
