import { faExclamationCircle, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import FailMessageModal from '../../../components/modal/FailMessageModal';
import PublishRefModel from '../../../models/PublishRefModel';

type IProps = {
  model: PublishRefModel;
};

const PublishRef: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleRestartClick = () => {
    props.model.getCheck();
  };

  const handleFailClick = () => {
    setShowModal(true);
  };

  const handleFailModalClose = () => {
    setShowModal(false);
  };

  const { checks, checking, checkFailed, name, publishing } = props.model;

  if (!checking && checks && checks.length === 0) {
    return null;
  }
  const done = props.model.done.length;
  const total = props.model.items.length;
  const percent = (done * 100) / total;

  return (
    <Resource>
      <Name>{name}</Name>
      {checkFailed && (
        <>
          <FailIndicator icon={faExclamationCircle} onClick={handleFailClick} />
          <RestartButton icon={faRedo} onClick={handleRestartClick} />
        </>
      )}
      <ProgressWrapper>
        <Progress variant={checking ? 'indeterminate' : 'determinate'} value={checking ? 0 : percent} />
        <Indicator>{checking ? 'Checking ...' : `${done}/${total}`}</Indicator>
      </ProgressWrapper>
      {publishing && <CircularProgress size={20} />}
      {showModal && (
        <FailMessageModal courseId={props.model.courseId} checks={checks} onClose={handleFailModalClose} />
      )}
    </Resource>
  );
};

export default PublishRef;

const Resource = styled.div`
  width: 450px;
  padding: 10px;
`;

const Name = styled.span`
  width: 180px;
  display: inline-block;
  vertical-align: top;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  cursor: pointer;
  vertical-align: top;
`;

const RestartButton = styled(Icon)`
  color: gray;
`;

const FailIndicator = styled(Icon)`
  color: red;
`;

const ProgressWrapper = styled.div`
  display: inline-block;
  width: 200px;
  position: relative;
  margin-right: 10px;
`;

const Progress = styled(LinearProgress)`
  && {
    height: 20px;
  }
`;
const Indicator = styled.div`
  position: absolute;
  top: 1px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-shadow: 0.1em 0.1em 1px #424242;
`;
