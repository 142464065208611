export const createKeyPath = (parentKey: string, key: string, index = -1) => {
  if (index > -1) {
    key = key + '[' + index + ']';
  }
  if (parentKey === '') {
    return key;
  } else {
    return parentKey + '.' + key;
  }
};
