import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ContainerLeft, ContainerLeftFooter, ContainerRight, ModuleContainer } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import HeadingModel from '../../models/HeadingModel';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import HeadingEdit from './Tabs/HeadingEdit';
import HeadingDashboard from './Tabs/HeadingDashboard';
import Note from '../Note/Note';
import { LoadingItem } from '../../CMFW/layout/Loading';

const Heading: React.FC = () => {
  const forceRender = useForceRender();

  const headingModel = useMemo(() => new HeadingModel({ render: forceRender }), [forceRender]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={headingModel}>
        <Split widthLeft={540}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={['Edit', 'Uses']}>
                <HeadingEdit />
                <HeadingDashboard />
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Heading;
