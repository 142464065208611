import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IDialogSpeaker, IDialogSpeakerEdit, IDialogSpeakerId } from '../types/dialog';

type IDialogSpeakerConstructor = IModelCourseOptions & {
  dialogId: number;
};

export default class DialogSpeakerModel extends BaseModel<IDialogSpeaker, IDialogSpeakerEdit> {
  dialogId = 0;
  constructor(options: IDialogSpeakerConstructor) {
    super(options);
    this.ref = IRefIds.dialogSpeaker;
    this.dialogId = options.dialogId;
    this.gridName = 'DialogSpeaker';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IDialogSpeaker[]>(`dialogs/${this.dialogId}/speakers`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IDialogSpeaker>(`dialogs/${this.dialogId}/speakers/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<IDialogSpeakerEdit>(`dialog-speakers/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  async updateItem() {
    const { id } = this.itemUpdated;
    await put(`dialog-speakers/${id}`, this.itemUpdated);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<IDialogSpeakerId>(`dialogs/${this.dialogId}/speakers`, this.itemUpdated);

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`dialog-speakers/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateCache();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.dialogSpeaker, this.dialogId)));
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<IDialogSpeakerEdit>(`dialog-speakers/${itemId}`);
    await this.setItem(item);
    this.render();
  }
}
