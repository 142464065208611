import { BaseModel, IModelCourseOptions } from 'models/BaseModel';
import { get, put } from 'services/request';
import { IState } from './StateModel';
import { IRefIds } from '../constants/refs';

export type IBoard = {
  id: number;
  type: IBoardTypes;
  name: string;
  nFreelances: number;
};

export type IBoardCardApi = {
  id: number;
  cardRef: IRefIds;
  cardRefId: number;
  title: string;
  state: IState;
  sDate: string;
  freelanceId: number;
  freelanceName: string;
  nComments: number;
  newComments: boolean;
  tags: ITag[];
  folder: string;
};

export type IBoardCard = IBoardCardApi & {
  boardId: number;
};

export type IFreelanceCount = {
  [name: string]: number;
};

export type IFolders = { [date: string]: { cards: IBoardCard[]; freelanceCount: IFreelanceCount } };

type IBoardCards = {
  todo: IBoardCard[];
  doing: IBoardCard[];
  review: IBoardCard[];
  doneFolders: IFolders;
};

type ITag = {
  tag: string;
  class: string;
};

export enum IBoardTypes {
  BOARD_TYPE_PANEL_SENTENCES = 1,
  BOARD_TYPE_PANEL_EXPLANATIONS = 2,
  BOARD_TYPE_SEGMENTS = 3,
  BOARD_TYPE_EXERCISES = 4,
  BOARD_TYPE_READINGS = 5,
  BOARD_TYPE_DIALOGS = 6,
  BOARD_TYPE_PANEL_TEST_SENTENCES = 7,
  BOARD_TYPE_TRAINERS = 8,
}

export const BOARD_TYPE_NAME: { [key in IBoardTypes]: string } = {
  [IBoardTypes.BOARD_TYPE_PANEL_SENTENCES]: 'Panel Sentences',
  [IBoardTypes.BOARD_TYPE_PANEL_EXPLANATIONS]: 'Panel Explanations',
  [IBoardTypes.BOARD_TYPE_SEGMENTS]: 'Segments',
  [IBoardTypes.BOARD_TYPE_EXERCISES]: 'Exercises',
  [IBoardTypes.BOARD_TYPE_READINGS]: 'Readings',
  [IBoardTypes.BOARD_TYPE_DIALOGS]: 'Dialogs',
  [IBoardTypes.BOARD_TYPE_PANEL_TEST_SENTENCES]: 'Panel Test Sentences',
  [IBoardTypes.BOARD_TYPE_TRAINERS]: 'Trainer Segments',
};

export const BOARD_TYPE_ICON: { [key in IBoardTypes]: string } = {
  [IBoardTypes.BOARD_TYPE_PANEL_SENTENCES]: 'fa-bars',
  [IBoardTypes.BOARD_TYPE_PANEL_EXPLANATIONS]: 'fa-align-left',
  [IBoardTypes.BOARD_TYPE_SEGMENTS]: 'fa-bars',
  [IBoardTypes.BOARD_TYPE_EXERCISES]: 'fa-bars',
  [IBoardTypes.BOARD_TYPE_READINGS]: 'fa-bars',
  [IBoardTypes.BOARD_TYPE_DIALOGS]: 'fa-bars',
  [IBoardTypes.BOARD_TYPE_PANEL_TEST_SENTENCES]: 'fa-flask',
  [IBoardTypes.BOARD_TYPE_TRAINERS]: 'fa-bars',
};

export default class BoardsModel extends BaseModel<IBoardCard, IBoardCards> {
  boardType: IBoardTypes = IBoardTypes.BOARD_TYPE_PANEL_SENTENCES;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options: IModelCourseOptions) {
    super(options);
  }

  async getBoards() {
    return await get<IBoard[]>(`courses/${this.courseId}/boards`);
  }

  setBoardType(type: IBoardTypes) {
    this.boardType = type;
  }

  async loadItemEdit(boardId: number) {
    this.loadingItem = true;
    this.itemId = boardId;
    this.render();

    const cards = await get<IBoardCardApi[]>(`boards/${boardId}/cards`);
    cards.sort((a, b) => a.title.localeCompare(b.title));
    const boardCards = this.processCards(boardId, cards);

    await this.setItem(boardCards);
    this.render();
  }

  processCards(
    boardId: number,
    cards: IBoardCardApi[],
    boardCards: IBoardCards = {
      todo: [],
      doing: [],
      review: [],
      doneFolders: {},
    },
  ): IBoardCards {
    const cardsDoneStatus1: IBoardCard[] = Object.values(boardCards.doneFolders)
      .map((folder) => folder.cards)
      .flat();

    cards.forEach((card) => {
      const boardCard = { ...card, boardId };
      switch (card.state) {
        case IState.TODO:
          boardCards.todo.push(boardCard);
          break;

        case IState.DOING:
          boardCards.doing.push(boardCard);
          break;

        case IState.REVIEW:
          boardCards.review.push(boardCard);
          break;

        case IState.DONE:
          cardsDoneStatus1.push(boardCard);
          break;
      }
    });

    cardsDoneStatus1.forEach((card) => {
      if (boardCards.doneFolders[card.folder]) {
        boardCards.doneFolders[card.folder].cards.push(card);
      } else {
        boardCards.doneFolders[card.folder] = { cards: [card], freelanceCount: {} };
      }
    });

    Object.keys(boardCards.doneFolders).forEach((date) => {
      const freelanceCount: { [name: string]: number } = {};

      boardCards.doneFolders[date].cards.forEach((card) => {
        if (typeof freelanceCount[card.freelanceName] === 'undefined') {
          freelanceCount[card.freelanceName] = 0;
        }
        let count = 0;
        if (card.tags.length === 0) {
          count++;
        } else {
          card.tags.forEach((tag) => {
            const parts = tag.tag.split(' ');
            if (parts.length === 2) {
              count += parseInt(parts[0]);
            }
          });
        }

        freelanceCount[card.freelanceName] += count;
      });

      boardCards.doneFolders[date].freelanceCount = freelanceCount;
    });

    return boardCards;
  }

  async moveCard(card: IBoardCard) {
    const data: { state: IState; message?: string } = { state: card.state + 1 };
    await put(`courses/${this.courseId}/board-types/${this.boardType}/ref-ids/${card.cardRefId}/states-change`, data);
    await this.loadItemEdit(card.boardId);
  }

  async updateCard(card: IBoardCard) {
    const updatedCard = await get<IBoardCardApi>(`boards/${card.boardId}/cards/${card.id}`);

    const currentBoardCards = this.itemUpdated as IBoardCards;

    const updateCard = (currentCard: IBoardCard): IBoardCard => {
      if (currentCard.id === card.id) {
        return { ...updatedCard, boardId: card.boardId };
      }
      return currentCard;
    };

    const todo = currentBoardCards.todo.map(updateCard);
    const doing = currentBoardCards.doing.map(updateCard);
    const review = currentBoardCards.review.map(updateCard);

    Object.keys(currentBoardCards.doneFolders).forEach((date) => {
      currentBoardCards.doneFolders[date].cards = currentBoardCards.doneFolders[date].cards.map(updateCard);
    });

    const updatedCards: IBoardCards = { todo, doing, review, doneFolders: currentBoardCards.doneFolders };
    await this.setItem(updatedCards);
    this.render();
  }
}
