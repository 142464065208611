import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorGap, EditorRow } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import PublishModel from '../../models/PublishModel';
import PublishRef from './components/PublishRef';

export type IProps = {
  courseId: number;
};

const Publish: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new PublishModel({ render: forceRender, courseId }), [forceRender, courseId]);

  const handleCheckClick = () => {
    moduleModel.getResources();
  };

  const handlePublishClick = () => {
    moduleModel.publish();
  };

  const { resources, checkEnabled, publishEnabled } = moduleModel;

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <ItemLayout>
          <EditorRowStyled>
            <Button disabled={!checkEnabled} variant="contained" size="small" onClick={handleCheckClick}>
              check
            </Button>
            <EditorGap />
            <Button disabled={!publishEnabled} variant="contained" size="small" onClick={handlePublishClick}>
              publish
            </Button>
          </EditorRowStyled>
          <ResourcesContainer>
            {resources.map((resource) => (
              <PublishRef key={resource.ref} model={resource} />
            ))}
          </ResourcesContainer>
        </ItemLayout>
        <LoadingItem />
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default Publish;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const ResourcesContainer = styled.div`
  padding: 5px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
