import { AppContext } from 'AppContext';
import CKEditor from 'ckeditor/CKEditor';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { ConceptLinkerEngine } from 'components/conceptLink/ConceptLinkerEngine';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import { IItem } from 'models/ItemModel';
import { IPanel } from 'models/PanelsModel';
import { ISentence } from 'models/SentenceModel';
import { IVoc } from 'models/VocabularyModel';
import ItemSearchModal from 'modules/panels/modal/ItemSearchModal';
import PanelSearchModal from 'modules/panels/modal/PanelSearchModal';
import SentenceSearchModal from 'modules/panels/modal/SentenceSearchModal';
import VocSearchModal from 'modules/panels/modal/VocSearchModal';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import { ICourseConfig } from 'store/reducers/courseConfig';
import { IAppState } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import { IModuleRequest } from 'types/module';
import ConjSearchModal, { IConjSearchModal } from '../../modules/panels/modal/ConjSearchModal';

type ICKEditorConfig = {
  toolbar?: string[][];
  toolBarInline?: string[][];
};

type IProps = {
  keyName: string;
  keyError?: string;
  clKeyName?: string;
  config?: ICKEditorConfig;
  width?: number;
  height?: number;
  autoGrow?: boolean;
  showFooter?: boolean;
  className?: string;
  text?: string;
  onChangeText?: (text: string) => void;
};

const DEFAULT_HEIGHT = 50;

const TextEditor: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const module = useContext(ModuleContext);
  const app = useContext(AppContext);

  const dispatch = useDispatch();

  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);
  const aggregatedClKeyName = props.clKeyName ? createKeyPath(parentKey, props.clKeyName) : null;
  const aggregatedKeyError = props.keyError ? createKeyPath(parentKey, props.keyError) : null;
  const aggregatedKeyDialect = createKeyPath(parentKey, 'dialectId');
  const targetDialectId = module.getItemUpdatedValue(aggregatedKeyDialect, 0) as number;

  const CKEditorId = module.ref + '_' + module.courseId + '_' + aggregatedKeyName;

  const [clEngine, setClEngine] = useState(null as ConceptLinkerEngine | null);

  const [currentAggregatedKeyName, setCurrentAggregatedKeyName] = useState('');

  const moduleItemOrigValue: string = module.getItemValue(aggregatedKeyName);
  const moduleItemUpdatedValue: string = module.getItemUpdatedValue(aggregatedKeyName, props.text ?? '');
  const errorValue: string = aggregatedKeyError ? module.getItemUpdatedValue(aggregatedKeyError, '') : '';
  const moduleUniqueId = module.getItemUniqueId();

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemUpdatedValue, '');

  if (aggregatedKeyName !== currentAggregatedKeyName) {
    setCurrentValue(moduleItemUpdatedValue);
    setCurrentAggregatedKeyName(aggregatedKeyName);
  }

  const [isPanelSearchModalOpen, setIsPanelSearchModalOpen] = useState(false);
  const [isConjSearchModalOpen, setIsConjSearchModalOpen] = useState(false);
  const [isVocSearchModalOpen, setIsVocSearchModalOpen] = useState(false);
  const [isItemSearchModalOpen, setIsItemSearchModalOpen] = useState(false);
  const [isSentenceSearchModalOpen, setIsSentenceSearchModalOpen] = useState(false);

  const handlePanelSearchModalClose = () => {
    setIsPanelSearchModalOpen(false);
  };

  const handleConjSearchModalClose = () => {
    setIsConjSearchModalOpen(false);
  };

  const handleVocSearchModalClose = () => {
    setIsVocSearchModalOpen(false);
  };

  const handleItemSearchModalClose = () => {
    setIsItemSearchModalOpen(false);
  };

  const handleSentenceSearchModalClose = () => {
    setIsSentenceSearchModalOpen(false);
  };

  const resolvePromise = useRef<(value?: IGridData) => void>();
  const getPromise = useCallback(
    () =>
      new Promise<IGridData>((resolve) => {
        resolvePromise.current = resolve;
      }),
    [],
  );

  const openPanelSearch = useCallback(() => {
    setIsPanelSearchModalOpen(true);
    return getPromise();
  }, [getPromise]);

  const openConjSearch = useCallback(() => {
    setIsConjSearchModalOpen(true);
    return getPromise();
  }, [getPromise]);

  const openVocSearch = useCallback(() => {
    setIsVocSearchModalOpen(true);
    return getPromise();
  }, [getPromise]);

  const openItemSearch = useCallback(() => {
    setIsItemSearchModalOpen(true);
    return getPromise();
  }, [getPromise]);

  const openSentenceSearch = useCallback(() => {
    setIsSentenceSearchModalOpen(true);
    return getPromise();
  }, [getPromise]);

  const courseConfig: ICourseConfig = useSelector((state: IAppState) => state.courseConfig[module.courseId]);
  const itemId = module.getItemId();
  const ref = module.getRef();
  const courseId = module.courseId;
  const loadingItem = module.loadingItem;

  useMemo(() => {
    const updateConceptLinkerConcepts = (cl: any) => {
      if (aggregatedClKeyName) {
        module.updateItemValue(aggregatedClKeyName, cl);
      }
    };

    if (aggregatedClKeyName && !loadingItem) {
      if (courseConfig.conceptLinker) {
        if (clEngine === null) {
          const clEngineNew: ConceptLinkerEngine = new ConceptLinkerEngine({
            ref: ref,
            refId: itemId,
            course: courseId,
            targetDialect: targetDialectId,
            setTooltip: app.setConceptLinkerTooltip,
            setGuessedTooltip: app.setConceptLinkerGuessedTooltip,
            fireChange: () => updateConceptLinkerConcepts(clEngineNew.getPhraseConcepts()),
            initialClPhrases: module.getItemUpdatedValue(aggregatedClKeyName),
          });

          setClEngine(clEngineNew);
        } else {
          clEngine.restart({
            ref: ref,
            refId: itemId,
            course: courseId,
            targetDialect: targetDialectId,
            initialClPhrases: module.getItemUpdatedValue(aggregatedClKeyName),
            fireChange: () => updateConceptLinkerConcepts(clEngine.getPhraseConcepts()),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    moduleUniqueId, //force restart clEngine
    aggregatedClKeyName,
    courseConfig.conceptLinker,
    clEngine,
    ref,
    itemId,
    courseId,
    targetDialectId,
    app.setConceptLinkerTooltip,
    app.setConceptLinkerGuessedTooltip,
    module,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (event: any) => {
    const data = event.editor.getData();
    module.updateItemValue(aggregatedKeyName, data);
    setCurrentValue(data);
    props.onChangeText && props.onChangeText(data);
  };

  const hideToolbar = typeof props.config === 'undefined' || typeof props.config.toolbar === 'undefined';

  const config = useMemo(() => {
    const additionalConfig = {
      clEngine,
      cKEditorId: 'cke_' + CKEditorId,
      openPanelSearch,
      openConjSearch,
      openVocSearch,
      openItemSearch,
      openSentenceSearch,
      openTab: (module: Omit<IModuleRequest, 'courseId'>) =>
        dispatch(tabsOpened({ refId: module.refId, courseId, search: module.search })),
      conceptLinkerOptions: {
        ref: module.getRef(),
        refId: module.getItemId(),
        targetDialect: targetDialectId,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    const removePlugins = [];

    if (props.autoGrow) {
      additionalConfig.autoGrow_minHeight = props.height ?? DEFAULT_HEIGHT;
    } else {
      removePlugins.push('autogrow');
    }

    if (!aggregatedClKeyName || !courseConfig.conceptLinker) {
      removePlugins.push('wConceptLinker');
    }

    if (!props.showFooter) {
      removePlugins.push('elementspath');
    }

    if (props.config?.toolBarInline) {
      additionalConfig.floatingtools = 'custom';
      additionalConfig.floatingtools_custom = props.config.toolBarInline;
    } else {
      removePlugins.push('floating-tools');
    }

    if (!props.config?.toolbar) {
      additionalConfig.toolbar = [['']]; // Without this, inline toolbar won't work
    }

    additionalConfig.removePlugins = removePlugins.join(',');

    return { ...props.config, ...additionalConfig };
  }, [
    clEngine,
    CKEditorId,
    openPanelSearch,
    openConjSearch,
    openVocSearch,
    openItemSearch,
    openSentenceSearch,
    module,
    targetDialectId,
    props.autoGrow,
    props.showFooter,
    props.config,
    props.height,
    aggregatedClKeyName,
    courseConfig.conceptLinker,
    dispatch,
    courseId,
  ]);

  if (currentValue === undefined || moduleItemUpdatedValue === undefined) {
    return null;
  }

  const cleanText = (text: string): string => {
    if (text) {
      return text
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/\t/g, '')
        .replace(/>[\s]*</g, '><')
        .trim();
    } else {
      return '';
    }
  };

  const changed = cleanText(moduleItemOrigValue) !== cleanText(currentValue);

  const handleChoosePanel = (data: IPanel) => {
    resolvePromise.current && resolvePromise.current(data);
    handlePanelSearchModalClose();
  };

  const handleChooseConj = (data: IConjSearchModal) => {
    resolvePromise.current && resolvePromise.current(data);
    handleConjSearchModalClose();
  };

  const handleChooseVoc = (data: IVoc) => {
    resolvePromise.current && resolvePromise.current(data);
    handleVocSearchModalClose();
  };

  const handleChooseItem = (data: IItem) => {
    resolvePromise.current && resolvePromise.current(data);
    handleItemSearchModalClose();
  };

  const handleChooseSentence = (data: ISentence) => {
    resolvePromise.current && resolvePromise.current(data);
    handleSentenceSearchModalClose();
  };

  const renderModals = () => {
    if (isPanelSearchModalOpen) {
      return <PanelSearchModal onClose={handlePanelSearchModalClose} onChoosePanel={handleChoosePanel} />;
    }
    if (isConjSearchModalOpen) {
      return <ConjSearchModal onClose={handleConjSearchModalClose} onChooseConj={handleChooseConj} />;
    }
    if (isVocSearchModalOpen) {
      return <VocSearchModal onClose={handleVocSearchModalClose} onChooseVoc={handleChooseVoc} />;
    }
    if (isItemSearchModalOpen) {
      return <ItemSearchModal onClose={handleItemSearchModalClose} onChooseItem={handleChooseItem} />;
    }
    if (isSentenceSearchModalOpen) {
      return <SentenceSearchModal onClose={handleSentenceSearchModalClose} onChooseSentence={handleChooseSentence} />;
    }
  };

  return (
    <Wrapper className={props.className}>
      {errorValue !== '' && <ErrorWrapper>{errorValue}</ErrorWrapper>}
      <CKEditor
        className={hideToolbar ? 'no-toolbar' : ''}
        id={CKEditorId}
        data={currentValue}
        config={config}
        autoGrow={props.autoGrow}
        onChange={onChange}
        uniqueId={moduleUniqueId}
        parentKey={parentKey}
        onFocus={() => clEngine && clEngine.setIsFocused(true)}
        onBlur={() => clEngine && clEngine.setIsFocused(false)}
        style={{
          boxShadow: 'none',
          borderColor: changed ? '#ff634763' : 'gray',
          width: props.width || null,
          overflow: 'auto',
        }}
      />
      {renderModals()}
    </Wrapper>
  );
};

export default TextEditor;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ErrorWrapper = styled.div`
  margin: 4px;
  padding: 8px;
  font-size: 14px;
  border: solid 1px red;
  background-color: #ffedee;
`;
