import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import ModuleContext from '../../CMFW/context/ModuleContext';
import { Split } from '../../components/Split';
import { IRefIds } from '../../constants/refs';
import LessonOutlineModel from '../../models/LessonOutlineModel';
import LessonOutlineContext, {
  ILessonOutlineContext,
  lessonOutlineContextDefault,
} from './context/LessonOutlineContext';
import LessonOutlineDashboard from './LessonOutlineDashboard';
import LessonOutlineSearch from './LessonOutlineSearch';
import LessonOutlineTop from './LessonOutlineTop';
import LessonOutlineTrash from './LessonOutlineTrash';

export type IProps = {
  courseId: number;
};

const LessonOutline: React.FC<IProps> = (props) => {
  const [lessonOutlineContext, setLessonOutlineContext] = React.useState({
    ...lessonOutlineContextDefault,
    updateShowActivity: (ref: IRefIds, show: boolean) => {
      setLessonOutlineContext((prevState) => {
        const activities = prevState.showActivity.filter((activity) => activity !== ref);
        if (show) {
          activities.push(ref);
        }
        return {
          ...prevState,
          showActivity: activities,
          DNDEnabled:
            activities.length === lessonOutlineContextDefault.showActivity.length ? prevState.DNDEnabled : false,
        };
      });
    },
    updateDNDEnabled: (value: boolean) => {
      setLessonOutlineContext({
        ...lessonOutlineContext,
        showActivity: value ? lessonOutlineContextDefault.showActivity : lessonOutlineContext.showActivity,
        DNDEnabled: value,
      });
    },
  } as ILessonOutlineContext);

  const forceRender = useForceRender();
  const lessonOutlineModel = useMemo(() => new LessonOutlineModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  return (
    <Wrapper>
      <ModuleContext.Provider value={lessonOutlineModel}>
        <LessonOutlineContext.Provider value={lessonOutlineContext}>
          <LessonOutlineTop />
          <DragDropContext onDragEnd={lessonOutlineModel.onDragEnd.bind(lessonOutlineModel)}>
            <Split widthRight={300}>
              <LessonOutlineDashboard />
              <WrapperRight>
                <LessonOutlineSearch courseId={props.courseId} />
                <LessonOutlineTrash />
              </WrapperRight>
            </Split>
          </DragDropContext>
        </LessonOutlineContext.Provider>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default LessonOutline;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
