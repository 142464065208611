import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';
import styled from 'styled-components';

type IProps = {
  keyName: string;
  className?: string;
};

const Label: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleValueUpdated: string = module.getItemUpdatedValue(aggregatedKeyName) ?? '';

  return <Wrapper className={props.className}>{moduleValueUpdated}</Wrapper>;
};

const Wrapper = styled.div``;

export default Label;
