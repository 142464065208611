import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { Howl } from 'howler';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const KEY = 'audioPath';

type IProps = {
  className?: string;
  audioPath?: string;
};

enum IPlaybackState {
  PAUSED,
  PLAYING,
  ERROR,
}

const getColor = (state: IPlaybackState) => {
  switch (state) {
    case IPlaybackState.PAUSED:
      return 'black';
    case IPlaybackState.PLAYING:
      return 'green';
    case IPlaybackState.ERROR:
      return 'tomato';
  }
};

export const AudioPlayer: React.FC<IProps> = (props) => {
  const [state, setState] = useState(IPlaybackState.PAUSED);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);

  const module = useContext(ModuleContext);
  const src: string = props.audioPath ?? module.getItemValue.bind(module)(aggregatedKeyName) ?? '';

  useEffect(() => {
    if (src === '') {
      setState(IPlaybackState.ERROR);
    }
  }, [src]);

  const sound = useMemo(
    () =>
      new Howl({
        src: [src],
        preload: false,
      }),
    [src],
  );

  useEffect(
    () => () => {
      setState(IPlaybackState.PAUSED);
      sound.unload();
    },
    [sound],
  );

  sound.on('end', () => setState(IPlaybackState.PAUSED));
  sound.on('loaderror', () => setState(IPlaybackState.ERROR));
  sound.on('playerror', () => setState(IPlaybackState.ERROR));

  const handleClick = useCallback(() => {
    switch (state) {
      case IPlaybackState.PAUSED:
        setState(IPlaybackState.PLAYING);
        sound.load();
        sound.play();
        break;

      case IPlaybackState.PLAYING:
        setState(IPlaybackState.PAUSED);
        sound.stop();
        break;

      default:
        break;
    }
  }, [state, sound]);

  return (
    <Container onClick={handleClick} $state={state} className={props.className}>
      <Icon icon={faPlay} $color={getColor(state)} />
    </Container>
  );
};

const Container = styled.div<{ $state: IPlaybackState }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.$state === IPlaybackState.ERROR ? 'default' : 'pointer')};
`;

const Icon = styled(FontAwesomeIcon)<{ $color: string }>`
  color: ${(props) => props.$color};
  && {
    width: 12px;
    height: 12px;
  }
`;
