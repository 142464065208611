import { ConceptsLinkerEnginePhrase } from 'components/conceptLink/ConceptsLinkerEngine_phrase';
import { IRefIds } from 'constants/refs';
import React, { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ILinkedConcept } from 'types/conceptLinker';
import DeltaPos from './components/DeltaPos';
import InflectStudied from './components/InflectStudied';
import { TOOLTIP_WIDTH } from './ConceptLinkerTooltip';
import GuessedCompound from './GuessedCompound';
import GuessedPanel from './GuessedPanel';
import GuessedVoc from './GuessedVoc';
import GuessedCulturals from './GuessedCulturals';

export const TOOLTIP_OFFSET_X = 22;
export const TOOLTIP_OFFSET_Y = 10;

export type IConcept = {
  ref: IRefIds;
  refId: number;
};

type IProps = {
  concept: ILinkedConcept;
  engine: ConceptsLinkerEnginePhrase;
};

const refs: { [key in IRefIds]?: string } = {
  [IRefIds.vocs]: 'V',
  [IRefIds.panels]: 'P',
  [IRefIds.compounds]: 'C',
  [IRefIds.cultural]: 'CV',
};

const ConceptLinkerGuessedConcept: React.FC<IProps> = memo((props) => {
  const { engine, concept } = props;

  const [hoverTimeout, setHoverTimeout] = useState(null as any);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
    };
  }, [hoverTimeout]);

  const showTooltip = () => {
    if (containerRef.current) {
      const elementBounding = containerRef.current.getBoundingClientRect();

      let x = elementBounding.x + TOOLTIP_OFFSET_X;
      if (x + TOOLTIP_WIDTH > window.innerWidth) {
        x -= TOOLTIP_WIDTH;
      }

      engine.setTooltip({
        x,
        y: elementBounding.bottom + TOOLTIP_OFFSET_Y,
        showInvalidate: false,
        concept,
        engine,
      });
    }
  };

  const handleMouseEnter = () => {
    if (hoverTimeout === null) {
      setHoverTimeout(
        setTimeout(() => {
          showTooltip();
        }, 1000),
      );
    }
  };

  const handleMouseLeave = () => {
    if (hoverTimeout !== null) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  const handleSelect = () => {
    engine.selectConcept(concept);
  };

  const renderGuessed = () => {
    switch (concept.ref) {
      case IRefIds.vocs:
        return (
          <GuessedVoc id={concept.refId} courseId={engine.getCourse()} targetDialectId={engine.getTargetDialectId()} />
        );
      case IRefIds.panels:
        return (
          <GuessedPanel
            id={concept.refId}
            courseId={engine.getCourse()}
            targetDialectId={engine.getTargetDialectId()}
          />
        );
      case IRefIds.cultural:
        return (
          <GuessedCulturals
            id={concept.refId}
            courseId={engine.getCourse()}
            targetDialectId={engine.getTargetDialectId()}
          />
        );
      case IRefIds.compounds:
        return (
          <GuessedCompound
            id={concept.refId}
            courseId={engine.getCourse()}
            targetDialectId={engine.getTargetDialectId()}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelect}
      ref={containerRef}
    >
      <Type>{refs[concept.ref]}</Type>
      <InflectStudied studied={concept.inflectStudied} />
      <DeltaPos deltaPos={concept.deltaPos} />
      <Titles>{renderGuessed()}</Titles>
    </Container>
  );
});

const Container = styled.div`
  cursor: pointer;
  padding: 2px 4px;
  min-height: 22px;
  display: flex;
  flex-direction: row;
`;

const Type = styled.div`
  font-weight: bold;
  color: darkgray;
  font-size: 14px;
  float: left;
  margin: 0;
  border: solid 1px gray;
  border-radius: 4px;
  padding: 1px 3px;
  height: fit-content;
`;

const Titles = styled.div`
  display: block;
  flex: 1;
  background-color: #e2e1e1;
  padding: 1px 6px;
  border-radius: 4px;
  color: #403e3e;
  border: solid 1px #c7c7c7;

  & div:not(:last-child) {
    border-bottom: dashed 1px gray;
  }
`;

export default ConceptLinkerGuessedConcept;
