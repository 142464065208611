import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import ModuleContext from '../../CMFW/context/ModuleContext';
import LessonOutlineModel from '../../models/LessonOutlineModel';
import LessonOutlineContext from './context/LessonOutlineContext';
import { IRefIds } from '../../constants/refs';
import { EditorRow } from '../../components/common/styles';
import { TextFieldStyled } from '../../components/common/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import LessonOutlineWizardModal from './modal/LessonOutlineWizardModal';

export type IProps = {};

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

const LessonOutlineTop: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as LessonOutlineModel;
  const [startPos, setStartPos] = React.useState(DEFAULT_START_POS);
  const [endPos, setEndPos] = React.useState(DEFAULT_END_POS);
  const [isOpenModal, setOpenModal] = React.useState(false);

  const lessonOutlineContext = useContext(LessonOutlineContext);

  const doLoad = () => {
    module.loadLessons(startPos, endPos);
  };

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStartPos(parseInt(event.target.value));
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEndPos(parseInt(event.target.value));
  };

  const handleChangeDND = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateDNDEnabled(checked);
  };

  const handleChangeShowVoc = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.vocs, checked);
  };

  const handleChangeShowPanel = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.panels, checked);
  };

  const handleChangeShowPanelIntro = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.panelIntro, checked);
  };

  const handleChangeShowDialog = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.dialog, checked);
  };

  const handleChangeShowReading = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.reading, checked);
  };

  const handleChangeShowExercise = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    lessonOutlineContext.updateShowActivity(IRefIds.exercise, checked);
  };

  return (
    <Wrapper>
      <EditorRowLeft>
        <TextFieldPos
          value={startPos}
          variant="outlined"
          label={'From Lesson'}
          type={'number'}
          onChange={handleChangeStarPos}
        />
        <TextFieldPos
          value={endPos}
          variant="outlined"
          label={'To Lesson'}
          type={'number'}
          onChange={handleChangeEndPos}
        />
        <ButtonStyled
          variant="outlined"
          size="small"
          onClick={doLoad}
          disabled={startPos === 0 || endPos === 0 || startPos > endPos}
        >
          load
        </ButtonStyled>
        <FontAwesomeIconStyled icon={faMagic} color={'#6b6b6b'} onClick={() => setOpenModal(true)} />
      </EditorRowLeft>
      <FormControlLabel
        control={
          <Switch checked={lessonOutlineContext.DNDEnabled} onChange={handleChangeDND} color="secondary" size="small" />
        }
        label="Edit"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.vocs)}
            onChange={handleChangeShowVoc}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Voc"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.panelIntro)}
            onChange={handleChangeShowPanelIntro}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Panel Intro"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.panels)}
            onChange={handleChangeShowPanel}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Panel"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.dialog)}
            onChange={handleChangeShowDialog}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Dialog"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.reading)}
            onChange={handleChangeShowReading}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Reading"
      />
      <FormControlLabel
        control={
          <Switch
            checked={lessonOutlineContext.showActivity.includes(IRefIds.exercise)}
            onChange={handleChangeShowExercise}
            color="primary"
            size="small"
            disabled={lessonOutlineContext.DNDEnabled}
          />
        }
        label="Exercise"
      />
      {isOpenModal && (
        <LessonOutlineWizardModal courseId={module.courseId} title={'Wizard'} onClose={() => setOpenModal(false)} />
      )}
    </Wrapper>
  );
};

export default LessonOutlineTop;

const Wrapper = styled.div`
  width: 100%;
  border-bottom: solid 1px #2a2f43;
  padding: 10px;
`;

const ButtonStyled = styled(Button)`
  && {
    margin-right: 16px;
  }
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 100px;
  && {
    margin-right: 16px;
  }
`;

const EditorRowLeft = styled(EditorRow)`
  justify-content: flex-start;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  padding-right: 30px;
  cursor: pointer;
`;
