import { createAction } from 'store/helpers';
import { ICacheCl, ICacheItems } from 'store/reducers/cache';

export enum ICacheActionTypes {
  ADDED = 'CACHE: ADDED',
  DELETED = 'CACHE: DELETED',
  ADDED_CL = 'CACHE: ADDED_CL',
  DELETED_CL = 'CACHE: DELETED_CL',
}

type ICacheActionPayloads = {
  [ICacheActionTypes.ADDED]: ICacheItems;
  [ICacheActionTypes.DELETED]: string;
  [ICacheActionTypes.ADDED_CL]: ICacheCl;
  [ICacheActionTypes.DELETED_CL]: string;
};

export const cacheAdd = createAction<ICacheActionTypes.ADDED, ICacheActionPayloads>(ICacheActionTypes.ADDED);
export const cacheDelete = createAction<ICacheActionTypes.DELETED, ICacheActionPayloads>(ICacheActionTypes.DELETED);

export const cacheAddCl = createAction<ICacheActionTypes.ADDED_CL, ICacheActionPayloads>(ICacheActionTypes.ADDED_CL);
export const cacheDeleteCl = createAction<ICacheActionTypes.DELETED_CL, ICacheActionPayloads>(
  ICacheActionTypes.DELETED_CL,
);

export type ICacheActions =
  | ReturnType<typeof cacheAdd>
  | ReturnType<typeof cacheDelete>
  | ReturnType<typeof cacheAddCl>
  | ReturnType<typeof cacheDeleteCl>;
