import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { IDialectAware } from 'types/content';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/rootReducer';
import { ICourseConfig } from '../../store/reducers/courseConfig';

type IProps = {
  keyName: string;
  narrow?: boolean;
};

export const ListDialects: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: IDialectAware[] = module.getItemValue.bind(module)(aggregatedKeyName) || [];

  const courses = useSelector((state: IAppState) => state.courseConfig);

  const courseConfig = (courses[module.courseId] as ICourseConfig) ?? null;
  const dialectOrder = courseConfig ? courseConfig.dialectOrder : [];

  const indexes = moduleItemValue.map((v) => v.dialectId);

  const dialectsSorted: IDialectAware[] = [...moduleItemValue]
    .sort((a, b) => dialectOrder.indexOf(a.dialectId) - dialectOrder.indexOf(b.dialectId))
    .map((v) => ({
      dialectId: v.dialectId,
      dialectCode: v.dialectCode,
    }));

  return (
    <Wrapper>
      {dialectsSorted.map((dialect, index) => (
        <KeyContext.Provider
          value={createKeyPath(parentKey, props.keyName, indexes.indexOf(dialect.dialectId))}
          key={index + '.' + dialect.dialectId}
        >
          <List>
            <Dialect $narrow={props.narrow}>{dialect.dialectCode}</Dialect>
            {props.children}
          </List>
        </KeyContext.Provider>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
`;

const Dialect = styled.div<{ $narrow?: boolean }>`
  width: ${(props) => (props.$narrow ? 30 : 50)}px;
  font-weight: bold;
`;
