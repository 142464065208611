import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerLeft, ContainerLeftFooter, ContainerRight, ModuleContainer } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import TextSearch from '../../components/search/TextSearch';
import TrainerModel from '../../models/TrainerModel';
import TrainerEdit from './tabs/TrainerEdit';
import TrainerSegment from './tabs/TrainerSegment';
import Button from '@material-ui/core/Button';
import { IFields } from '../../CMFW/grid/fields';

export type IProps = {
  courseId: number;
  search?: string;
};

const Trainer: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const trainerModel = useMemo(
    () => new TrainerModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={trainerModel}>
        <Split widthLeft={500}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.stateContent]: 'stateTrainerContentRenderer',
              }}
              disableRowLoadFields={[IFields.stateContent]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <Button variant="outlined" size="small" onClick={trainerModel.checkTrainers.bind(trainerModel)}>
                check
              </Button>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={['Edit', 'Segments']}>
                <TrainerEdit key={'Edit' + trainerModel.itemId} />
                <TrainerSegment key={'Segments' + trainerModel.itemId} />
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Trainer;
