import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ListDialects } from 'CMFW/layout/ListDialects';
import { LoadingItem } from 'CMFW/layout/Loading';
import Id from 'components/common/Id';
import PanelUses from 'components/common/PanelUses';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerLeftHeader,
  ContainerRight,
  EditorGap,
  EditorGapGreedyH,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import SearchBarDialect from 'components/search/SearchBarDialect';
import GenderSelect from 'components/selects/GenderSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import SentenceModel from 'models/SentenceModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import TargetDialect from './TargetDialect';
import Translations from '../../components/translation/Translations';
import Illustration from '../compounds/Illustration';
import { IFields } from '../../CMFW/grid/fields';
import { illustrationRenderer } from '../../CMFW/grid/cellRenderers/illustration';

export type IProps = {
  courseId: number;
  search?: string;
};

const Sentence: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(
    () => new SentenceModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <SearchBarDialect />
            </ContainerLeftHeader>
            <Grid
              cellRenderers={{
                [IFields.illustration]: illustrationRenderer,
              }}
            />
            <ContainerLeftFooter>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Container>
                <Container2 style={{ overflow: 'auto' }}>
                  <EditorRow>
                    <Id />
                    <EditorGap />
                    <SaveButton />
                  </EditorRow>
                  <EditorRow>
                    <GenderSelect readbyKey={'readby'} />
                    <EditorGapGreedyH />
                  </EditorRow>
                  <ListDialects keyName={'targets'}>
                    <TargetDialect />
                  </ListDialects>
                  <Translations keyName={'sources'} refId={model.ref} />
                  <EditorRow>
                    <Illustration />
                  </EditorRow>
                  <TextInput keyName={'sourceAdaptationNote'} label="Source Adaptation Note" />
                  <PanelUses />
                </Container2>
                <DeleteButton />
              </Container>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

export default Sentence;
