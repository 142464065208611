import { get } from 'services/request';
import { IDialectAware } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITranslation } from '../types/Translation';
import TranslationManagerModel from './TranslationManagerModel';

type ILessonTitle = {
  id: number;
} & {
  [prop: string]: string;
};

type ILessonTitleEdit = {
  id: number;
  targets: ILessonTargetEdit[];
};

type ILessonTargetEdit = IDialectAware & {
  lessonTargetId: number;
  titles: ITranslation[];
};

export default class LessonsTitleModel extends BaseModel<ILessonTitle, ILessonTitleEdit> {
  translations: TranslationManagerModel[];
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'LessonsTitle';
    this.ref = IRefIds.lessonTitle;
    this.getGridColumns();
    this.getGrid();
    this.translations = [];
  }

  getTranslation(index: number): TranslationManagerModel {
    if (!this.translations[index]) {
      this.translations[index] = new TranslationManagerModel({ ref: this.ref });
    }
    return this.translations[index];
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?courseTitle=${this.courseId}`;
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ILessonTitle[]>(`groups/${this.courseId}/lessons/titles`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ILessonTitle>(`groups/${this.courseId}/lessons/${itemId}/titles`);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const targets = await get<ILessonTargetEdit[]>(`lessons/${itemId}/targets`);
    const targetTitles = await Promise.all(
      targets.map((target, index) => this.getTranslation(index).get(target.lessonTargetId)),
    );
    targets.forEach((target, index) => {
      target.titles = targetTitles[index];
    });

    await this.setItem({
      id: itemId,
      targets,
    });

    this.render();
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as ILessonTitleEdit;
    const itemId = itemToSave.id;

    await Promise.all(
      itemToSave.targets.map((target, index) =>
        this.getTranslation(index).update(target.lessonTargetId, target.titles),
      ),
    );
    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }
}
