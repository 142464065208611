import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type ITranslationSourceAICheckerRef = {
  ref: IRefIds;
  name: string;
  batchSize: number;
};

export default class AITranslationSourceCheckerModel extends BaseModel<IGridData, ITranslationSourceAICheckerRef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const checkers = await get<ITranslationSourceAICheckerRef[]>(`/translation/sources/ai/checker-refs`);
    await this.setItem(checkers);
    this.render();
  }
}
