import { IRefIds } from 'constants/refs';
import { get, put, post } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';
import { IUser, IUserCourseEdit, IUserEdit } from '../types/users';
import { IItemId } from '../types/content';
import * as lodash from 'lodash';

export default class UserModel extends BaseModel<IUser, IUserEdit> {
  constructor(options: IModelOptions) {
    super(options);
    this.ref = IRefIds.NONE;
    this.gridName = 'Users';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IUser[]>(`users`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IUser>(`/users/grid/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<IUserEdit>(`/users/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IUserEdit;
    const itemId = itemToSave.id;

    await Promise.all([
      put(`/users/${itemId}`, lodash.omit(itemToSave, ['courses'])),
      put(`users/${itemId}/permissions`, itemToSave.courses),
    ]);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IUserEdit;

    const result = await post<IItemId>(`users`, lodash.omit(itemToCreate, ['courses']));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await put(`users/${newItemId}/permissions`, itemToCreate.courses);
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, courses] = await Promise.all([
      get<IUserEdit>(`users/${itemId}`),
      get<IUserCourseEdit[]>(`users/${itemId}/permissions`),
    ]);
    item.courses = courses.sort((a, b) => a.name.localeCompare(b.name));
    await this.setItem(item);
    this.render();
  }
}
