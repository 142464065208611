import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type ITranslationDialectAllAICheckerRef = {
  ref: IRefIds;
  name: string;
  batchSize: number;
};

export default class AITranslationDialectAllCheckerModel extends BaseModel<
  IGridData,
  ITranslationDialectAllAICheckerRef[]
> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const checkers = await get<ITranslationDialectAllAICheckerRef[]>(`/ai/content/dialect/all/checker-refs`);
    await this.setItem(checkers);
    this.render();
  }
}
