import ModuleContext from 'CMFW/context/ModuleContext';
import VocabularyModel, { IVoc, IVocTreeEdit } from 'models/VocabularyModel';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Tree } from 'react-organizational-chart';
import VocTreeNode from './VocTreeNode';
import VocTreeNodeItem from './VocTreeNodeItem';
import { Link } from '@material-ui/core';
import VocSearchModal from '../../../panels/modal/VocSearchModal';

const VocTree: React.FC = () => {
  const module = useContext(ModuleContext) as VocabularyModel;
  const moduleItemValue: IVocTreeEdit = module.getItemValue('tree');
  const parent: number | null = module.getItemValue('parent');
  const parentSynonym: number | null = module.getItemValue('parentSynonym');

  const [showModal, setShowModal] = useState<null | 'parent' | 'synonym'>(null);
  const [showChildModal, setShowChildModal] = useState<null | 'parent' | 'synonym'>(null);

  const onCloseModal = () => {
    setShowModal(null);
  };

  const onCloseChildModal = () => {
    setShowChildModal(null);
  };

  const handleParent = async () => {
    if (parent !== null) {
      await module.updateItemParent(0);
    } else {
      setShowModal('parent');
    }
  };

  const handleChild = async () => {
    setShowChildModal('parent');
  };

  const handleParentSynonym = async () => {
    if (parentSynonym !== null) {
      await module.updateItemParentSynonym(0);
    } else {
      setShowModal('synonym');
    }
  };

  const handleChildSynonym = async () => {
    setShowChildModal('synonym');
  };

  const onChooseVoc = async (voc: IVoc) => {
    setShowModal(null);
    if (showModal === 'parent') {
      await module.updateItemParent(voc.id);
    }
    if (showModal === 'synonym') {
      await module.updateItemParentSynonym(voc.id);
    }
  };

  const onChooseChildVoc = async (voc: IVoc) => {
    setShowChildModal(null);
    if (showChildModal === 'parent') {
      await module.updateVocParent(voc.id, module.itemId);
    }
    if (showChildModal === 'synonym') {
      await module.updateVocParentSynonym(voc.id, module.itemId);
    }
  };

  return (
    <Wrapper>
      {parentSynonym === null && (
        <LinkStyled onClick={handleParent}>{parent ? 'Unlink parent' : 'Link to parent'}</LinkStyled>
      )}
      {parentSynonym === null && <LinkStyled onClick={handleChild}>{'Link child'}</LinkStyled>}
      {parentSynonym === null &&
        parent === null &&
        moduleItemValue.children.length === 0 &&
        moduleItemValue.synonyms.length === 0 && (
          <LinkStyled onClick={handleParentSynonym}>{'Link to synonym'}</LinkStyled>
        )}
      {parentSynonym !== null && <LinkStyled onClick={handleParentSynonym}>{'Unlink to synonym'}</LinkStyled>}
      {parent === null && parentSynonym === null && (
        <LinkStyled onClick={handleChildSynonym}>{'Link synonym'}</LinkStyled>
      )}
      <br />
      <br />
      {(moduleItemValue.children.length > 0 || moduleItemValue.synonyms.length > 0) && (
        <Tree
          label={<VocTreeNodeItem item={moduleItemValue} />}
          lineWidth={'1px'}
          lineColor={'#C6C6CA'}
          lineBorderRadius={'4px'}
        >
          {moduleItemValue.children.map((child) => (
            <VocTreeNode item={child} key={child.id} />
          ))}
        </Tree>
      )}
      {showModal !== null && <VocSearchModal onClose={onCloseModal} onChooseVoc={onChooseVoc} />}
      {showChildModal !== null && <VocSearchModal onClose={onCloseChildModal} onChooseVoc={onChooseChildVoc} />}
    </Wrapper>
  );
};

export default VocTree;

const Wrapper = styled.div``;

const LinkStyled = styled(Link)`
  padding: 4px 10px;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-end;
`;
