import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { ILessonOutlineCard } from '../../types/lessonOutline';

type IProps = {};

const LessonOutlineTrash: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      <Droppable droppableId={'Trash-Lesson'} direction={'vertical'} type={ILessonOutlineCard.LESSON}>
        {(provided, snapshot) => (
          <WrapperDrop isDraggingOver={snapshot.isDraggingOver} {...provided.droppableProps} ref={provided.innerRef}>
            {provided.placeholder}
            <Droppable droppableId={'Trash-Cycle'} direction={'vertical'} type={ILessonOutlineCard.CYCLE}>
              {(provided, snapshot) => (
                <WrapperDrop
                  isDraggingOver={snapshot.isDraggingOver}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                  <Droppable droppableId={'Trash-Activity'} direction={'vertical'} type={ILessonOutlineCard.ACTIVITY}>
                    {(provided, snapshot) => (
                      <WrapperDrop
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {provided.placeholder}
                      </WrapperDrop>
                    )}
                  </Droppable>
                </WrapperDrop>
              )}
            </Droppable>
          </WrapperDrop>
        )}
      </Droppable>
    </Wrapper>
  );
};

export default LessonOutlineTrash;

const Wrapper = styled.div`
  height: 100px;
  width: 100%;
  overflow: hidden;
  background-color: pink;
  border: solid 2px red;
`;

const WrapperDrop = styled.div<{ isDraggingOver: boolean }>`
  height: 100px;
  width: 100%;
  background-color: ${(props) => (props.isDraggingOver ? 'red' : 'none')};
`;
