import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerRight, EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import DialectSelect, { IDialectOption } from '../../../components/selects/DialectSelect';
import AIContentTargetCheckerModel, { IContentTargetAICheckerRef } from '../../../models/AIContentTargetCheckerModel';
import ContentTargetAICheckerRef from '../components/ContentTargetAICheckerRef';

export type IProps = {
  courseId: number;
};

const ContentTargetAIChecker: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(
    () => new AIContentTargetCheckerModel({ render: forceRender, courseId: props.courseId }),
    [forceRender, props.courseId],
  );

  const [targetDialectId, setTargetDialectId] = useState(null as number | null);
  const [targetDialectLoadId, setTargetDialectLoadId] = useState(null as number | null);

  const handleTargetDialectChange = (dialect: IDialectOption) => {
    setTargetDialectId(dialect.dialectId);
  };

  const handleLoadClick = () => {
    setTargetDialectLoadId(targetDialectId);
    moduleModel.loadItemEdit();
  };

  const refs: IContentTargetAICheckerRef[] = moduleModel.getItemUpdatedValue('', []);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <ContainerRight>
          <EditorRowStyled>
            <DialectSelect onlyTarget handleDialectChange={handleTargetDialectChange} />
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Checkers>
            {targetDialectLoadId &&
              refs.length > 0 &&
              refs.map((checker) => (
                <ContentTargetAICheckerRef
                  key={checker.ref}
                  courseId={props.courseId}
                  refId={checker.ref}
                  dialectId={targetDialectLoadId}
                  name={checker.name}
                  batchSize={checker.batchSize}
                />
              ))}
          </Checkers>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const Checkers = styled.div`
  padding: 10px;
  height: 100%;
  overflow: auto;
`;

export default ContentTargetAIChecker;
