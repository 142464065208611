import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons';

export type IProps = {
  className?: string;
};

const DragHandle: React.FC<IProps> = (props) => {
  return (
    <Wrapper {...props} className={props.className}>
      <Icon icon={faArrowsAltV} />
    </Wrapper>
  );
};

export default DragHandle;

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  opacity: 0.2;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 10px 5px;
  cursor: pointer;
`;
