import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { ContainerLeft, ContainerLeftFooter, ContainerRight, ModuleContainer } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import TextSearch from '../../components/search/TextSearch';
import BookEdit from './tabs/BookEdit';
import BookChapter from './tabs/BookChapter';
import BookModel from '../../models/BookModel';
import { IFields } from '../../CMFW/grid/fields';
import { illustrationRenderer } from '../../CMFW/grid/cellRenderers/illustration';

export type IProps = {
  courseId: number;
  search?: string;
};

const Book: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const bookModel = useMemo(
    () => new BookModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={bookModel}>
        <Split widthLeft={300}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.illustration]: illustrationRenderer,
              }}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={[bookModel.itemId > 0 ? 'Chapters' : undefined, 'Edit'].filter(Boolean) as string[]}>
                {bookModel.itemId > 0 && <BookChapter key={'Texts' + bookModel.itemId} />}
                <BookEdit key={'Edit' + bookModel.itemId} />
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Book;
