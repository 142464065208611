import React, { useContext } from 'react';
import styled from 'styled-components';
import { ILessonOutlineCycleActivityVoc } from '../../../../types/lessonOutline';
import { positionLessonToString } from '../../../../helpers/positon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store/reducers/rootReducer';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import { convertSourceDialectCodeToApi, convertTargetDialectCodeToApi } from '../../../../helpers/helpers';

export type IProps = {
  activity: ILessonOutlineCycleActivityVoc;
};

const LessonOutlineActivityVoc: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext);
  const sourceDialect = useSelector(
    (state: IAppState) => state.courseConfig[moduleModel.courseId].sourceDialectDefaultCode,
  );
  const targetDialect = useSelector(
    (state: IAppState) => state.courseConfig[moduleModel.courseId].targetDialectDefaultCode,
  );
  const renderVoc = () => {
    const { activity } = props;
    if (activity.target && activity.source) {
      return (
        <>
          {props.activity.repeated && (
            <CLIconWrapper>
              <FontAwesomeIcon icon={faExclamation} />
            </CLIconWrapper>
          )}
          <Target dangerouslySetInnerHTML={{ __html: activity.target }} />
          <Source>{activity.source}</Source>
        </>
      );
    } else {
      return (
        <>
          <Title>
            {activity.position && <span>{positionLessonToString(activity.position.lesson)} - </span>}
            {activity.title}
          </Title>
          <Target
            dangerouslySetInnerHTML={{
              __html: activity[convertTargetDialectCodeToApi(targetDialect)] as string,
            }}
          />
          <Source>{activity[convertSourceDialectCodeToApi(sourceDialect)]}</Source>
        </>
      );
    }
  };

  return <Wrapper>{renderVoc()}</Wrapper>;
};

export default LessonOutlineActivityVoc;

const Wrapper = styled.div`
  background-color: #e6ffe0;
  padding: 4px 2px;
  border-radius: 4px;
`;

const Title = styled.p`
  margin: 0;
`;

const Target = styled.p`
  margin: 0;
`;

const Source = styled.p`
  opacity: 0.5;
  margin: 0;
`;

export const CLIconWrapper = styled.div`
  display: inline-block;
  align-items: center;
  width: 12px;
  height: 16px;
  text-align: center;
  color: red;
`;
