import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import { ContainerRight, EditorRow } from 'components/common/styles';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import SaveButton from '../../../CMFW/button/SaveButton';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import BooleanIcon from '../../../CMFW/input/BooleanIcon';
import TextEditor from '../../../CMFW/input/TextEditor';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import { TabDialects } from '../../../CMFW/layout/TabDialects';
import HeadingSelect from '../../../components/selects/HeadingSelect';
import { useForceRender } from '../../../hooks/useForceRender';
import ReadingExerciseModel from '../../../models/ReadingExerciseModel';
import ReadingModel from '../../../models/ReadingModel';
import ReadingTextSelect from './ReadingTextSelect';

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
    ['BulletedList', 'NumberedList', 'HorizontalRule', 'headings', 'title'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

export type IProps = {
  readingExerciseId: number;
};

const ReadingExerciseEdit: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const readingModel = useContext(ModuleContext) as ReadingModel;

  const readingExerciseModel = useMemo(() => {
    const rem = new ReadingExerciseModel({ render: forceRender, courseId: readingModel.courseId });
    if (props.readingExerciseId) {
      rem.loadItemEdit(props.readingExerciseId);
      rem.readingModel = readingModel;
    }
    return rem;
  }, [forceRender, props.readingExerciseId, readingModel]);

  const handleCloneClick = () => {
    readingExerciseModel.clone();
  };

  return (
    <ModuleContext.Provider value={readingExerciseModel}>
      <ContainerRightStyled>
        <ItemLayout>
          <EditorRow>
            <BooleanIcon icon={faHeadphones} keyName={'listening'} />
            <ReadingTextSelect readingId={readingModel.itemId} />
            <SaveButton />
          </EditorRow>
          <EditorRow>
            <HeadingSelect isLinked />
          </EditorRow>
          <DialectContainer>
            <TabDialects keyName={'targets'}>
              <TextEditor
                keyName={'html'}
                clKeyName={'conceptLinkerConcepts'}
                keyError={'error'}
                config={CK_EDITOR_CONFIG}
                autoGrow={true}
              />
            </TabDialects>
          </DialectContainer>
          <EditorRow>
            <DeleteButton />
            <Button variant="contained" size="small" onClick={handleCloneClick}>
              clone
            </Button>
          </EditorRow>
        </ItemLayout>
        <LoadingItem />
      </ContainerRightStyled>
    </ModuleContext.Provider>
  );
};

export default ReadingExerciseEdit;

const DialectContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const ContainerRightStyled = styled(ContainerRight)`
  width: auto;
  flex: 1;
`;
