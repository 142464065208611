import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import Flag from 'CMFW/input/Flag';
import TextEditor from 'CMFW/input/TextEditor';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { TabDialects } from 'CMFW/layout/TabDialects';
import Positions from 'components/common/Positions';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import TextSearch from 'components/search/TextSearch';
import HeadingSelect from 'components/selects/HeadingSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import ExerciseModel from 'models/ExerciseModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import { IFields } from '../../CMFW/grid/fields';
import Button from '@material-ui/core/Button';
import { VersionFeedback } from '../../CMFW/layout/VersionFeedback';

export type IProps = {
  courseId: number;
  search?: string;
};

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
    ['BulletedList', 'NumberedList', 'HorizontalRule', 'headings', 'title'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

const Exercise: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const exerciseModel = useMemo(
    () => new ExerciseModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={exerciseModel}>
        <Split widthLeft={540}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.stateContent]: 'stateExerciseContentRenderer',
              }}
              disableRowLoadFields={[IFields.stateContent]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <Button variant="outlined" size="small" onClick={exerciseModel.checkExercises.bind(exerciseModel)}>
                check
              </Button>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <Flag />
                <Positions keyName={'positions'} />
                <VersionFeedback />
                <HeadingSelect isLinked />
                <SaveButton />
              </EditorRow>
              <TabDialects keyName={'targets'}>
                <TextEditor
                  keyName={'html'}
                  clKeyName={'conceptLinkerConcepts'}
                  keyError={'error'}
                  config={CK_EDITOR_CONFIG}
                />
              </TabDialects>
              <EditorRow>
                <DeleteButton />
              </EditorRow>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Exercise;
