import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import { AudioPlayer } from 'CMFW/layout/AudioPlayer';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ListDialects } from 'CMFW/layout/ListDialects';
import { LoadingItem } from 'CMFW/layout/Loading';
import Id from 'components/common/Id';
import PanelUses from 'components/common/PanelUses';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerLeftHeader,
  ContainerRight,
  EditorGap,
  EditorGapGreedyH,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import SearchBarDialect from 'components/search/SearchBarDialect';
import GenderSelect from 'components/selects/GenderSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import ItemModel from 'models/ItemModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Sources from './Sources';
import { IFields } from '../../CMFW/grid/fields';
import { booleanRenderer } from '../../CMFW/grid/cellRenderers/boolean';

export type IProps = {
  courseId: number;
  search?: string;
};

const Items: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new ItemModel({ render: forceRender, courseId: props.courseId, search: props.search }), [
    forceRender,
    props.courseId,
    props.search,
  ]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <SearchBarDialect />
            </ContainerLeftHeader>
            <Grid
              cellRenderers={{
                [IFields.itemWithSource]: booleanRenderer,
              }}
            />
            <ContainerLeftFooter>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Container>
                <EditorRow>
                  <Id />
                  <EditorGap />
                  <SaveButton />
                </EditorRow>
                <EditorRow>
                  <GenderSelect readbyKey="readby" />
                  <EditorGapGreedyH />
                </EditorRow>
                <ListDialects keyName={'targets'}>
                  <TextInput keyName="text" />
                  <AudioPlayer />
                </ListDialects>
                <Sources />
                <PanelUses />
              </Container>
              <DeleteButton />
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  overflow: auto;
`;

export default Items;
