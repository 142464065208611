import React, { useContext } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TranslationProjectModel from '../../../models/TranslationProjectModel';
import { ITranslationProjectAnalysis, ITranslationProjectWordCount } from '../../../types/translationProject';

export type IProps = {};

const TranslationProjectAnalysis: React.FC<IProps> = () => {
  const projectModel = useContext(ModuleContext) as TranslationProjectModel;
  const analysis: ITranslationProjectAnalysis = projectModel.getItemValue('analysis');
  return (
    <Wrapper>
      <table>
        <tr>
          <th />
          <th>Items</th>
          <th>Words</th>
          <th>%</th>
        </tr>
        {(Object.values(analysis) as ITranslationProjectWordCount[]).map((v, index) => (
          <tr key={index}>
            <td>{v.title}</td>
            <td>{v.items}</td>
            <td>{v.words}</td>
            <td>{v.percent}</td>
          </tr>
        ))}
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 30px;
  & table {
    border-collapse: collapse;
    width: 350px;
    color: #435569;
    font-size: 14px;
  }
  & th {
    text-align: left;
    background-color: #ddd;
    color: #000;
    vertical-align: middle;
    border: 1px solid #444;
    padding: 4px 10px;
  }
  & td {
    padding: 2px 10px;
    border: 1px solid #444;
  }
`;

export default TranslationProjectAnalysis;
