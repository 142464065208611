import { Button, CircularProgress } from '@material-ui/core';
import FileUpload from 'CMFW/input/FileUpload';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import AudioSetsModel from '../../../models/AudioSetsModel';

type IProps = {
  onClose: () => void;
};

const AudioUploadWav: React.FC<IProps> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');

  const moduleModel = useContext(ModuleContext) as AudioSetsModel;

  const handleFileChosen = (file: File) => {
    setFile(file);
  };

  const handleUploadClick = async () => {
    if (!file) {
      return;
    }
    setIsUploading(true);
    setMessage('');
    const response = await moduleModel.uploadFile(file);

    if (!response) {
      setMessage('Something went wrong, try again or report Wlingua.');
    } else {
      if (response.status === 1) {
        setMessage('Set is ready to be reviewed by Wlingua.');
      } else {
        setMessage(`<p><b>Set with errors:</b></p><p>${response.error}</p>`);
      }
    }
    setIsUploading(false);
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Upload Wav'} width={518} height={455}>
      <EditorRowStyled>
        <FileUploadStyled handleFile={handleFileChosen} />
        <Button variant="contained" size="small" disabled={!file || isUploading} onClick={handleUploadClick}>
          Upload
        </Button>
        {isUploading && <CircularProgress size={20} />}
      </EditorRowStyled>
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </ModalWrapper>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  margin-top: 10px;
`;

const FileUploadStyled = styled(FileUpload)`
  margin-right: 5px;
`;

export default AudioUploadWav;
