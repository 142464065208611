import { IDialectAware, IPanelCard, IPosition } from './content';

export type ILessonOutline = IDialectAware & {
  id: number;
  lessonTargetId: number;
  status: number;
  title: string;
  position: number;
  loading?: boolean;
  cycles: ILessonOutlineCycle[];
};

export type ILessonOutlineCycle = {
  id: number;
  activities: ILessonOutlineCycleActivity[];
};

export type ILessonOutlineCycleActivity = {
  activityId: number;
  ref: number;
  repeated?: boolean;
  position?: IPosition;
} & {
  [prop: string]: string;
};

export type ILessonOutlineCycleActivityExercise = ILessonOutlineCycleActivity & { id: number };
export type ILessonOutlineCycleActivityVoc = ILessonOutlineCycleActivity & {
  id: number;
  title: string;
  source?: string;
  target?: string;
};
export type ILessonOutlineCycleActivityDialog = ILessonOutlineCycleActivity & {
  id: number;
  title: string;
};
export type ILessonOutlineCycleActivityReading = ILessonOutlineCycleActivity & {
  id: number;
  title: string;
};
export type ILessonOutlineCycleActivityPanel = ILessonOutlineCycleActivity & IPanelCard;
export type ILessonOutlineCycleActivityPanelIntro = ILessonOutlineCycleActivity & { id: number; title: string };

export enum ILessonOutlineCard {
  LESSON = 'lesson',
  CYCLE = 'cycle',
  ACTIVITY = 'activity',
}
