import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import { WordClassSelect } from '../../../components/selects/WordClassSelect';
import styled from 'styled-components';
import ConjugationRuleModel from '../../../models/ConjugationRuleModel';
import ConjRuleEndings from '../components/ConjRuleEndings';
import { ICourseConfig } from '../../../store/reducers/courseConfig';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers/rootReducer';

export type IProps = {
  courseId: number;
};

const ConjRules: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const courseConfig: ICourseConfig = useSelector((state: IAppState) => state.courseConfig[props.courseId]);

  const conjRuleModel = useMemo(
    () =>
      new ConjugationRuleModel({
        render: forceRender,
        courseId: props.courseId,
        languageId: courseConfig.languageId,
      }),
    [props.courseId, forceRender, courseConfig],
  );

  const ruleId = conjRuleModel.getItemUpdatedValue('id');
  const wordClassId = conjRuleModel.getItemUpdatedValue('wordClass');

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={conjRuleModel}>
        <Split widthLeft={424}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'name'} />
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <EditorRow>
                <TextInputOrder type={'number'} keyName={'order'} label={'order'} />
                <WordClassSelect isLinked onChange={forceRender} />
                <TextInputOther keyName={'gender'} label={'gender'} />
                <TextInputOther keyName={'endingToApply'} label={'Apply on'} />
                <TextInputOther keyName={'endingToRemove'} label={'Remove'} />
              </EditorRow>
              <ConjRuleEndings courseId={props.courseId} ruleId={ruleId} wordClassId={wordClassId} />
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const TextInputOrder = styled(TextInput)`
  width: 50px;
`;

const TextInputOther = styled(TextInput)`
  width: 100px;
`;

export default ConjRules;
