import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { IQAResponseError } from '../../../models/QAResultsModel';

export type IProps = {
  errors: IQAResponseError[] | null;
};

const CATCellQA: React.FC<IProps> = (props) => {
  return (
    <>
      {props.errors === null ? (
        <FontAwesomeNull icon={faQuestion} />
      ) : props.errors.length > 0 ? (
        <FontAwesomeFail icon={faExclamation} />
      ) : (
        <FontAwesomePass icon={faCheck} />
      )}
    </>
  );
};

export default CATCellQA;

const FontAwesomePass = styled(FontAwesomeIcon)`
  color: #4242ff;
  font-size: 14px;
  cursor: pointer;
`;

const FontAwesomeFail = styled(FontAwesomeIcon)`
  color: purple;
  font-size: 14px;
  cursor: pointer;
`;

const FontAwesomeNull = styled(FontAwesomeIcon)`
  color: #dddddd;
  font-size: 14px;
  cursor: pointer;
`;
