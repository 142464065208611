import { IPosition } from 'types/content';
import { positionToString } from '../../../helpers/positon';

export function positionRenderer(param: { value?: IPosition }) {
  if (param.value?.lesson === undefined) {
    return '';
  }

  return positionToString(param.value);
}
