import { AppContext } from 'AppContext';
import ConceptLinkerGuessedConcept, {
  TOOLTIP_OFFSET_X,
  TOOLTIP_OFFSET_Y,
} from 'components/conceptLink/ConceptLinkerGuessedConcept';
import ConceptLinkerGuessedOption from 'components/conceptLink/ConceptLinkerGuessedOption';
import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { IConceptLinkerGuessedTooltip, ILinkedConcept } from 'types/conceptLinker';
import { TOOLTIP_WIDTH } from './ConceptLinkerTooltip';
import { TOOLTIP_DELAY_SECONDARY } from './ConceptsLinkerEngine_phrase';

export type ISelectedPositions = {
  positions: number[];
  text?: string;
};

type IMousePosition = { x: number; y: number };

let timeoutId: number;

const ConceptLinkerGuessedTooltip: React.FC<IConceptLinkerGuessedTooltip> = memo((props) => {
  const { x, y, engine, concepts, linkedPositions } = props;

  const [selectedPosition, setSelectedPosition] = useState(null as ISelectedPositions | null);
  const [optionClickPosition, setOptionClickPosition] = useState(null as IMousePosition | null);

  const { setConceptLinkerTooltip } = useContext(AppContext);

  const conceptRefs = useRef<HTMLDivElement[]>([]);

  const uniquePositions = useMemo(() => {
    const uniquePositions: string[] = [];
    concepts.forEach((guessedConcept) => {
      const positionsJson = JSON.stringify(guessedConcept.positions.sort((a, b) => a - b));
      if (uniquePositions.indexOf(positionsJson) === -1) {
        uniquePositions.push(positionsJson);
      }
    });

    if (uniquePositions.length === 1) {
      setSelectedPosition(null);
    }

    return uniquePositions
      .sort((a, b) => b.length - a.length)
      .map((uniquePosition): number[] => JSON.parse(uniquePosition));
  }, [concepts]);

  const guessedConceptsByPosition = useMemo(() => {
    if (uniquePositions.length === 1) {
      return concepts;
    }

    if (!selectedPosition) {
      return;
    }

    const positionsJson = JSON.stringify(selectedPosition.positions.sort());

    const result: ILinkedConcept[] = [];
    concepts.forEach((guessConcept) => {
      if (JSON.stringify(guessConcept.positions.sort()) === positionsJson) {
        result.push(guessConcept);
      }
    });

    return result;
  }, [selectedPosition, concepts, uniquePositions]);

  useEffect(() => {
    if (conceptRefs.current.length && optionClickPosition && guessedConceptsByPosition) {
      const elementsWithoutNullsFromPreviousInstance = conceptRefs.current.filter(Boolean);

      elementsWithoutNullsFromPreviousInstance.some((element, index) => {
        const rect = element.getBoundingClientRect();
        if (
          optionClickPosition.x > rect.x &&
          optionClickPosition.x < rect.x + rect.width &&
          optionClickPosition.y > rect.y &&
          optionClickPosition.y < rect.y + rect.height
        ) {
          const conceptUnderMouse = guessedConceptsByPosition[index];
          let x = rect.x + TOOLTIP_OFFSET_X;
          if (x + TOOLTIP_WIDTH > window.innerWidth) {
            x -= TOOLTIP_WIDTH;
          }
          const y = rect.bottom + TOOLTIP_OFFSET_Y;

          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setConceptLinkerTooltip({ showInvalidate: false, x, y, concept: conceptUnderMouse, engine });
          }, TOOLTIP_DELAY_SECONDARY);

          element.addEventListener('mouseleave', () => clearTimeout(timeoutId));

          return true;
        }
        return false;
      });
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [engine, guessedConceptsByPosition, optionClickPosition, setConceptLinkerTooltip]);

  const handlePositionSelected = (selected: ISelectedPositions, event: React.MouseEvent) => {
    setOptionClickPosition({ x: event.clientX, y: event.clientY });
    setSelectedPosition(selected);
  };

  const handleBackClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.stopPropagation();
    setConceptLinkerTooltip(null);
    setSelectedPosition(null);
  };

  const renderOptions = () =>
    uniquePositions.map((positions, index) => (
      <ConceptLinkerGuessedOption
        key={index}
        positions={positions}
        engine={engine}
        handlePositionsSelected={handlePositionSelected}
        linkedPositions={linkedPositions}
      />
    ));

  const renderConcepts = () => (
    <>
      {selectedPosition && (
        <Header onClick={handleBackClicked}>
          {'< '}
          {selectedPosition?.text}
        </Header>
      )}
      {guessedConceptsByPosition &&
        guessedConceptsByPosition.map((concept, index) => (
          <div
            key={concept.ref + '-' + concept.refId}
            ref={(element: HTMLDivElement) => (conceptRefs.current[index] = element)}
          >
            <ConceptLinkerGuessedConcept concept={concept} engine={engine} />
          </div>
        ))}
    </>
  );

  const hasMultiplePositions = uniquePositions.length > 1;
  return (
    <Container $x={x} $y={y}>
      {hasMultiplePositions && !selectedPosition ? renderOptions() : renderConcepts()}
    </Container>
  );
});

const Container = styled.div<{ $x: number; $y: number }>`
  z-index: 90;
  position: absolute;
  top: ${(props) => props.$y}px;
  left: ${(props) => props.$x}px;
  width: 300px;
  border-radius: 2px;
  min-height: 100px;
  max-height: 190px;
  border: solid 1px #b9b9bb;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  overflow: auto;
  background-color: white;
`;

const Header = styled.div`
  border-bottom: solid 1px #c7c7c7;
  background-color: #f1f1f1;
  color: #3c3b3b;
  padding-left: 5px;
  cursor: pointer;
`;

export default ConceptLinkerGuessedTooltip;
