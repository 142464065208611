import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  summary: ReactNode;
};

export const DropDown: React.FC<IProps> = (props) => {
  const { summary, children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Summary onClick={handleClick}>
        <Down icon={faChevronDown} $pointsUp={isOpen} />
        {summary}
      </Summary>
      <Details $isOpen={isOpen}>{children}</Details>
    </Container>
  );
};

const Container = styled.div``;

const Summary = styled.div`
  cursor: pointer;
  padding: 5px;
`;

const Details = styled.div<{ $isOpen: boolean }>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
`;

const Down = styled(FontAwesomeIcon)<{ $pointsUp: boolean }>`
  font-size: 12px;
  color: black;
  transform: rotate(${(props) => (props.$pointsUp ? '180deg' : '0deg')});
  margin-right: 5px;
`;
