import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import LessonOutlineModel from '../../../models/LessonOutlineModel';

export type IProps = {
  lessonId: number;
};

const LessonOutlineCycleNew: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as LessonOutlineModel;

  const onNew = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    module.newCycle(props.lessonId);
  };

  return (
    <Wrapper onClick={onNew}>
      <FontAwesomeIcon icon={faPlus} color={'#bfbfbf'} />
    </Wrapper>
  );
};

export default LessonOutlineCycleNew;

const Wrapper = styled.div`
  display: inline-block;
  margin: 2px;
  opacity: 0.5;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
