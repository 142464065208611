import React, { useContext } from 'react';
import styled from 'styled-components';
import FeedbackModel, { IFeedback } from '../../../../models/FeedbackModel';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store/reducers/rootReducer';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import { ICourseConfig } from '../../../../store/reducers/courseConfig';
import { IRefIds } from '../../../../constants/refs';
import FeedbackListItem from './FeedbackListItem';

export type IProps = {
  feedback: IFeedback[];
  showDialectCode?: boolean;
  refId?: IRefIds;
  itemId?: number;
};

const FeedbackList: React.FC<IProps> = (props) => {
  const courses = useSelector((state: IAppState) => state.courseConfig);
  const module = useContext(ModuleContext) as FeedbackModel;
  const courseConfig = (courses[module.courseId] as ICourseConfig) ?? null;
  const dialectOrder = courseConfig ? courseConfig.dialectOrder : [];

  const feedback = [...props.feedback];

  const dialectFeedbackIds = feedback.map((feedback) => feedback.dialectId);
  const notIncludedDialect = courseConfig.sourceDialects.filter(
    (dialect) => !dialectFeedbackIds.includes(dialect.dialectId),
  );
  notIncludedDialect.forEach((dialect) => {
    const found = courseConfig.sourceDialects.find((d) => d.dialectId === dialect.dialectId);
    if (found) {
      feedback.push({
        dialectId: found.dialectId,
        dialectCode: found.dialectCode,
        n: 0,
        median: 0,
        version: 0,
      });
    }
  });

  const feedbackSorted: IFeedback[] = feedback.sort(
    (a, b) => dialectOrder.indexOf(a.dialectId) - dialectOrder.indexOf(b.dialectId),
  );

  return (
    <WrapperFeedback>
      {feedbackSorted.map((feedback, index) => (
        <FeedbackListItem
          key={index}
          feedback={feedback}
          courseId={module.courseId}
          itemId={props.itemId}
          refId={props.refId}
          showDialectCode={props.showDialectCode}
        />
      ))}
    </WrapperFeedback>
  );
};

export default FeedbackList;

const WrapperFeedback = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30px;
`;
