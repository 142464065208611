import { IConceptLinkerCompound, TooltipCompoundsModel } from 'components/conceptLink/TooltipCompoundsModel';
import { IConceptLinkerCultural, TooltipCulturalsModel } from 'components/conceptLink/TooltipCulturalsModel';
import { IConceptLinkerPanel, TooltipPanelsModel } from 'components/conceptLink/TooltipPanelsModel';
import { IConceptLinkerVoc, TooltipVocsModel } from 'components/conceptLink/TooltipVocsModel';
import { IRefIds } from 'constants/refs';
import { getClTooltipCacheKey } from 'helpers/cache';
import { Dispatch } from 'redux';
import { cacheAddCl, cacheDeleteCl } from 'store/actions/cache';
import { IPhraseConcepts } from 'types/conceptLinker';

export type ITooltipData = IConceptLinkerVoc | IConceptLinkerPanel | IConceptLinkerCompound | IConceptLinkerCultural;
export type IValidRefs = IRefIds.vocs | IRefIds.panels | IRefIds.compounds | IRefIds.cultural;

type ITooltipOptions = { ref: IValidRefs; id: number; courseId: number };

const modelCultural = new TooltipCulturalsModel();
const modelPanel = new TooltipPanelsModel();
const modelCompound = new TooltipCompoundsModel();
const modelVoc = new TooltipVocsModel();

class TooltipLoader {
  loadedTooltipKeys: string[] = [];
  dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  invalidate(tooltip: ITooltipOptions) {
    const key = getClTooltipCacheKey(tooltip.ref, tooltip.courseId, tooltip.id);
    const idIndex = this.loadedTooltipKeys.indexOf(key);
    if (idIndex > -1) {
      this.loadedTooltipKeys.splice(idIndex, 1);
    }
    this.dispatch(cacheDeleteCl(key));
  }

  loadTooltips(ref: IValidRefs, ids: number[], courseId: number, targetDialectId: number) {
    const missingIds = [
      ...new Set(ids.filter((id) => this.loadedTooltipKeys.indexOf(getClTooltipCacheKey(ref, courseId, id)) === -1)),
    ];

    const dispatchItems = (items: ITooltipData[]) => {
      this.dispatch(
        cacheAddCl(items.reduce((its, item) => ({ ...its, [getClTooltipCacheKey(ref, courseId, item.id)]: item }), {})),
      );
    };

    if (missingIds.length) {
      this.loadedTooltipKeys.push(...missingIds.map((id) => getClTooltipCacheKey(ref, courseId, id)));

      const optionsBase = {
        ids: missingIds,
      };

      switch (ref) {
        case IRefIds.vocs:
          const options = {
            ...optionsBase,
            course: courseId,
            defaultTarget: targetDialectId,
          };

          modelVoc.get(options).then(dispatchItems);
          break;

        case IRefIds.panels:
          modelPanel.get(optionsBase).then(dispatchItems);
          break;

        case IRefIds.compounds:
          modelCompound.get(optionsBase).then((items) => {
            dispatchItems(items);

            const vocIds: number[] = [];
            const panelIds: number[] = [];
            items.forEach((item) =>
              item.items.forEach((i) => {
                switch (i.ref) {
                  case IRefIds.vocs:
                    vocIds.push(i.refId);
                    break;
                  case IRefIds.panels:
                    panelIds.push(i.refId);
                    break;
                }
              }),
            );

            this.loadTooltips(IRefIds.vocs, vocIds, courseId, targetDialectId);
            this.loadTooltips(IRefIds.panels, panelIds, courseId, targetDialectId);
          });
          break;

        case IRefIds.cultural:
          modelCultural.get(optionsBase).then(dispatchItems);
          break;
      }
    }
  }

  preloadPhrases(phrases: IPhraseConcepts[], courseId: number, targetDialectId: number) {
    const tooltipIds: { [key in IRefIds]?: number[] } = {};

    phrases.forEach((phrase) =>
      phrase.concepts.forEach((concept) => {
        tooltipIds[concept.ref] = [...(tooltipIds[concept.ref] || []), concept.refId];
      }),
    );

    Object.keys(tooltipIds).forEach(async (r) => {
      const ref = parseInt(r) as IValidRefs;
      const ids = tooltipIds[ref];

      if (ids) {
        this.loadTooltips(ref, ids, courseId, targetDialectId);
      }
    });
  }
}

export let tooltipLoader: TooltipLoader | null = null;

export const initTooltipLoader = (dispatch: Dispatch) => {
  tooltipLoader = new TooltipLoader(dispatch);
  return tooltipLoader;
};
