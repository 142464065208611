export function htmlRenderer(params: { value?: any }) {
  if (typeof params.value !== 'undefined') {
    if (params.value.constructor === Array) {
      return params.value.join();
    } else if (params.value.constructor === String) {
      return params.value.replace(/\r\n|\r|\n/g, '<br />');
    } else {
      return params.value;
    }
  }
}
