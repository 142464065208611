import { IRefIds } from 'constants/refs';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IItemId, IPosition } from 'types/content';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';

export type IItem = {
  id: number;
  gender: string;
  readby: string;
  notesStatus: number;
  itemWithSource: boolean;
  nPanels: number;
  position: IPosition;
} & {
  [prop: string]: string;
};

export type IItemSource = {
  id: number;
  itemId: number;
  dialectCode: string;
  dialectId: number;
  text: string;
  nInflectsSource: number;
  inflectEnabled: boolean;
};

export type IItemTarget = IItemSource & {
  audioPath: string;
};

type IItemEdit = {
  id: number;
  gender: string;
  readby: string;
  panels: number[];
  deletable: boolean;
  type: number;
  itemWithSource: boolean;
  sources: IItemSource[];
  targets: IItemTarget[];
};

type IItemSearch = IModuleSearch & {
  dialect: string;
};

export default class ItemModel extends BaseModel<IItem, IItemEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.items;
    this.gridName = 'Items';
  }

  async getGridSearch(text: string, dialect: string) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    this.gridData = await get<IItem[]>(`groups/${this.courseId}/items?dialect=${dialect}&text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGridRow(itemId: number) {
    return await get<IItem>(`groups/${this.courseId}/items/${itemId}`);
  }

  async new() {
    await super.new();

    const [item, sources, targets] = await Promise.all([
      get<IItemEdit>(`items/new`),
      get<IItemSource[]>(`courses/${this.courseId}/items-source/new`),
      get<IItemTarget[]>(`courses/${this.courseId}/items-target/new`),
    ]);

    item.targets = targets as IItemTarget[];
    item.sources = sources;

    await this.setItem(item);

    this.render();
  }

  async save() {
    await super.save();

    const { id } = this.itemUpdated as IItemEdit;

    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToUpdate = this.itemUpdated as IItemEdit;
    const { id } = itemToUpdate;

    await Promise.all([
      put(`items/${id}`, lodash.omit(itemToUpdate, ['targets', 'sources'])),
      itemToUpdate.sources.map((source: IItemSource) => put(`items/${id}/source-dialect/${source.dialectId}`, source)),
      itemToUpdate.targets.map((target: IItemTarget) => put(`items/${id}/target-dialect/${target.dialectId}`, target)),
    ]);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IItemEdit;

    const result = await post<IItemId>(`groups/${this.courseId}/items`, itemToCreate);

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all([
        itemToCreate.sources.map((source: IItemSource) =>
          put(`items/${newItemId}/source-dialect/${source.id}`, source),
        ),
        itemToCreate.targets.map((target: IItemTarget) =>
          put(`items/${newItemId}/target-dialect/${target.id}`, target),
        ),
        this.updateGridRow(newItemId, 'add'),
        this.loadItemEdit(newItemId),
      ]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated as IItemEdit;

    const result = await del(`items/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, sources, targets] = await Promise.all([
      get<IItemEdit>(`items/${itemId}`),
      get<IItemSource[]>(`items/${itemId}/sources`),
      get<IItemTarget[]>(`items/${itemId}/targets`),
    ]);

    item.targets = targets as IItemTarget[];
    item.sources = sources;

    await this.setItem(item);

    this.render();
  }

  async search(data: IItemSearch) {
    await this.getGridSearch(data.text || '', data.dialect);
  }
}
