import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { IDialog, IDialogEdit, IDialogId, IDialogSegment, IDialogSegmentId } from '../types/dialog';
import { DropResult } from 'react-beautiful-dnd';
import { IItemId } from '../types/content';

export default class DialogModel extends BaseModel<IDialog, IDialogEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.dialog;
    this.gridName = 'Dialogs';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<IDialog[]>(`courses/${this.courseId}/dialogs`, data);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IDialog>(`courses/${this.courseId}/dialogs/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const item = await get<IDialogEdit>(`/dialogs/new`);
    item.segments = [];
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IDialogEdit;
    const itemId = itemToSave.id;

    await put<IDialogId>(`/dialogs/${itemId}`, { ...itemToSave });

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IDialogEdit;
    const result = await post<IDialogId>(`/courses/${this.courseId}/dialogs`, {
      ...itemToCreate,
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`dialogs/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, segments] = await Promise.all([
      get<IDialogEdit>(`dialogs/${itemId}`),
      get<IDialogSegment[]>(`dialogs/${itemId}/segments`),
    ]);
    item.segments = segments.sort((a, b) => a.position - b.position);
    await this.setItem(item);
    this.render();
  }

  async forceReloadItemEdit() {
    await Promise.all([this.loadItemEdit(this.itemId), this.updateGridRow(this.itemId)]);
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  public async newSegment() {
    this.loadingItem = true;
    this.render();
    const dialogItem = this.itemUpdated as IDialogEdit;
    await post<IDialogSegmentId>(`dialogs/${dialogItem.id}/segments/new`, {});
    await this.loadItemEdit(dialogItem.id);
    this.loadingItem = false;
    this.render();
  }

  public async addScript(script: string) {
    this.loadingItem = true;
    this.render();
    const dialogItem = this.itemUpdated as IDialogEdit;
    await post<IDialogSegmentId>(`dialogs/${dialogItem.id}/add-script`, { script });
    await this.loadItemEdit(dialogItem.id);
    this.loadingItem = false;
    this.render();
  }

  public async onDragEnd(result: DropResult) {
    console.log(result);
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const segmentId = parseInt(draggableId);
    await this.moveSegment(segmentId, source.index, destination.index);
  }

  private async moveSegment(segmentId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as IDialogEdit;
    this.arrayMove(item.segments, prevPos, newPos);
    this.render();
    await put(`dialogs/segments/${segmentId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
  }

  async copy(courseId: number) {
    return await post<IItemId>(`/dialogs-copy`, {
      dialog: this.getItemId(),
      toCourseId: courseId,
    });
  }

  private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  async checkDialogs() {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/dialogs/check`);
    this.gridData = await get<IDialog[]>(`courses/${this.courseId}/dialogs/with-error`);
    this.loadingGrid = false;
    this.render();
  }
}
