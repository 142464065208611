import React, { useContext, useEffect, useState } from 'react';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel, { ICATData } from '../../../models/CATModel';
import CATPanel from './CATPanel';
import ListIdsBlind from '../../../components/common/ListIdsBlind';
import styled from 'styled-components';

export type IProps = {};

const CATFromId: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [row, setRow] = useState<ICATData | null>(null);

  useEffect(() => {
    const activeRow = module.getActive();
    setRow(activeRow);
  }, [module, module.activeId]);

  const renderFromId = () => {
    if (!row) {
      return null;
    } else {
      return (
        <ListIdsBlindStyled
          courseId={module.courseId}
          refId={row.contextRef}
          ids={[row.contextItemId]}
          label={'From'}
        />
      );
    }
  };

  return <CATPanel title={'Translation'}>{renderFromId()}</CATPanel>;
};

export default CATFromId;

const ListIdsBlindStyled = styled(ListIdsBlind)`
  & span {
    font-size: 14px;
  }
  margin: 2px;
`;
