import React from 'react';
import { IRefIds } from '../constants/refs';
import { BaseModel } from './BaseModel';
import NoteModel from './NoteModel';

const NoteContext = React.createContext<NoteModel>(
  new NoteModel(() => {}, new BaseModel({ render: () => {} }), IRefIds.NONE, -1),
);

export default NoteContext;
