import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import TtsLabModel from '../../models/TtsLabModel';
import NewButton from '../../CMFW/button/NewButton';
import Flag from '../../CMFW/input/Flag';
import TtsVoiceSelect from '../../components/selects/TtsVoiceSelect';
import DeleteButton from '../../CMFW/button/DeleteButton';
import Note from '../Note/Note';
import GenerateButton from './components/GenerateButton';
import WaveSurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const TtsLab: React.FC = () => {
  const forceRender = useForceRender();

  const ttsLabModel = useMemo(() => new TtsLabModel({ render: forceRender }), [forceRender]);

  const itemId = ttsLabModel.getItemId();

  const audioPath = ttsLabModel.getItemValue('audioPath');

  const waveSurfer = useRef<WaveSurfer>();
  const initWave = useCallback(
    (node) => {
      if (node) {
        waveSurfer.current && waveSurfer.current.destroy();
        waveSurfer.current = WaveSurfer.create({
          container: node,
          plugins: [],
        });

        if (audioPath !== '') {
          waveSurfer.current && waveSurfer.current.load(audioPath + '?dc=' + new Date().getTime());
        }
      }
    },
    [audioPath],
  );

  useEffect(() => {
    return () => {
      waveSurfer.current && waveSurfer.current.destroy();
    };
  }, []);

  const handlePlay = () => {
    waveSurfer.current && waveSurfer.current.playPause();
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={ttsLabModel}>
        <Split widthLeft={420}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <Flag />
                <TextInputName keyName={'name'} label={'Name'} />
                <SaveButton />
              </EditorRow>
              <EditorRow>
                <TtsVoiceSelect isLinked />
              </EditorRow>
              <Scroll>
                <TextInputStyled keyName={'script'} multiline label={'Script'} />
              </Scroll>
              <Footer>
                {audioPath === '' && itemId >= 0 && <GenerateButton />}
                {audioPath !== '' && <FontAwesomeIconPlay icon={faPlay} onClick={handlePlay} size={'1x'} />}
                <WaveformWrapper ref={initWave} />
              </Footer>
              <DeleteButton />
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default TtsLab;

const TextInputName = styled(TextInput)`
  flex-grow: 1;
`;

const TextInputStyled = styled(TextInput)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const Footer = styled.div`
  height: 160px;
`;

const WaveformWrapper = styled.div``;

const FontAwesomeIconPlay = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 5px auto;
`;
