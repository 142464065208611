import Button, { ButtonProps } from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import { changeAwareMixin, IChangeAwareProps } from 'components/common/styles';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import { omit } from 'lodash';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { createKeyPath } from '../context/helper/keyContext';
import KeyContext from '../context/KeyContext';

type IProps = ButtonProps & {
  keyName: string;
};

const TextButton: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: string = module.getItemValue(aggregatedKeyName) ?? '';
  const modelValueUpdated: string = module.getItemUpdatedValue(aggregatedKeyName) ?? '';

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, '');

  useEffect(() => {
    setCurrentValue(modelValueUpdated);
  }, [modelValueUpdated, setCurrentValue]);

  return (
    <>
      <ButtonStyled {...omit(props, ['keyName'])} $changed={moduleItemValue !== currentValue} variant="outlined">
        {currentValue}
      </ButtonStyled>
    </>
  );
};

const ButtonStyled = styled(Button)<IChangeAwareProps>`
  && {
    ${changeAwareMixin}
  }
`;

export default TextButton;
