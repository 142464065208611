import { createAction } from 'store/helpers';
import { ICourseConfigItems } from 'store/reducers/courseConfig';

export enum ICourseConfigActionTypes {
  ADDED = 'COURSE: ADDED',
}

type ICourseConfigActionPayloads = {
  [ICourseConfigActionTypes.ADDED]: ICourseConfigItems;
};

export const courseConfigAdd = createAction<ICourseConfigActionTypes.ADDED, ICourseConfigActionPayloads>(
  ICourseConfigActionTypes.ADDED,
);

export type ICourseConfigActions = ReturnType<typeof courseConfigAdd>;
