import React, { useContext, useEffect, useState } from 'react';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel, { ICATData } from '../../../models/CATModel';
import CATPanel from './CATPanel';

export type IProps = {};

const CATAIReview: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [row, setRow] = useState<ICATData | null>(null);

  useEffect(() => {
    const activeRow = module.getActive();
    setRow(activeRow);
  }, [module, module.activeId]);

  if (!row) {
    return null;
  }

  if (!row.AIReview) {
    return null;
  }

  return (
    <CATPanel title={'AI Review'}>
      <p dangerouslySetInnerHTML={{ __html: row.AIReview }} />
    </CATPanel>
  );
};

export default CATAIReview;
