import { Button, Grid, Paper, TextField } from '@material-ui/core';
import WlinguaBlue from 'assets/WlinguaBlue.svg';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userLogged } from 'store/actions/user';
import styled from 'styled-components';

export const Login: React.FC = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [doingLogin, setDoingLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();

  const doLogin = async () => {
    setDoingLogin(true);
    setErrorMessage(false);
    try {
      await axios.post('/login', { login: userName, password });
      dispatch(userLogged());
      localStorage.setItem('user', userName); // TODO
    } catch (e) {
      setErrorMessage(true);
      setDoingLogin(false);
    }
  };

  return (
    <Wrapper>
      <Paper style={{ padding: 30, width: 280 }}>
        <Logo src={WlinguaBlue} alt="Logo" />
        <Grid>
          <TextField
            id="username"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            label="Username"
            type="email"
            fullWidth
            autoFocus
            required
            error={errorMessage}
          />
        </Grid>
        <Grid style={{ marginTop: 20 }}>
          <TextField
            id="username"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            label="Password"
            type="password"
            fullWidth
            required
            error={errorMessage}
          />
        </Grid>
        <Grid container justify="center" style={{ marginTop: 20 }}>
          <Button onClick={() => doLogin()} variant="contained" color="primary" disabled={doingLogin}>
            Login
          </Button>
        </Grid>
      </Paper>
    </Wrapper>
  );
};

const Logo = styled.img`
  width: 175px;
  height: 48px;
  display: block;
  margin: 10px auto 30px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
