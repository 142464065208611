import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import PanelIntroModel from 'models/PanelIntroModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import Translations from '../../components/translation/Translations';
import styled from 'styled-components';

export type IProps = {
  courseId: number;
};

const PanelIntro: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const panelIntroModel = useMemo(
    () =>
      new PanelIntroModel({
        render: forceRender,
        courseId: props.courseId,
      }),
    [forceRender, props.courseId],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={panelIntroModel}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Wrapper>
                <EditorRow>
                  <EditorGap />
                  <SaveButton />
                </EditorRow>
                <Translations keyName={'sources'} refId={panelIntroModel.ref}/>
              </Wrapper>
              <DeleteButton />
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default PanelIntro;

export const Wrapper = styled.div`
  overflow: auto;
`;
