import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext } from 'react';
import styled from 'styled-components';

const KEY = 'level';

type ILevel = {
  value: number;
  label: string;
};

const options: ILevel[] = [
  { value: 11, label: 'A1.1' },
  { value: 12, label: 'A1.2' },
  { value: 13, label: 'A1.3' },
  { value: 14, label: 'A1.4' },
  { value: 15, label: 'A1.5' },
  { value: 21, label: 'A2.1' },
  { value: 22, label: 'A2.2' },
  { value: 23, label: 'A2.3' },
  { value: 24, label: 'A2.4' },
  { value: 25, label: 'A2.5' },
  { value: 31, label: 'B1.1' },
  { value: 32, label: 'B1.2' },
  { value: 33, label: 'B1.3' },
  { value: 34, label: 'B1.4' },
  { value: 35, label: 'B1.5' },
];

const getLevelIndex = (value: number) => {
  const index = options.findIndex((level) => level.value === value);
  return index === -1 ? 0 : index;
};

const LevelSelect: React.FC = () => {
  const module = useContext(ModuleContext);
  const moduleItemValue: number = module.getItemValue(KEY) || 0;

  const [currentLevelIndex, setCurrentLevelIndex] = useCurrentItemValue(getLevelIndex(moduleItemValue), 0);

  const currentLevel = options[currentLevelIndex];

  const handleChange = (level: ILevel) => {
    const index = getLevelIndex(level.value);
    setCurrentLevelIndex(index);
    module.updateItemValue.bind(module)(KEY, level.value);
  };

  return (
    <SelectLayoutStyled
      data={options}
      onChange={handleChange}
      label="Level"
      optionLabelKey="label"
      optionValueKey="value"
      currentValue={currentLevel.value}
      originalValue={moduleItemValue}
    />
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 100px;
  flex-grow: 0;
`;

export default LevelSelect;
