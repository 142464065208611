import { get } from '../services/request';
import { IPosition } from '../types/content';

export type IPanelSelect = {
  id: number;
  title: string;
};
type IPanelSelectPos = IPanelSelect & {
  position: IPosition;
};

export default class PanelSelectModel {
  courseId: number;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    return await get<IPanelSelectPos[]>(`/groups/${this.courseId}/dialects/default/panels`);
  }
}
