import React, { useContext } from 'react';
import styled from 'styled-components';
import { ITranslationStatus } from '../../../types/Translation';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel from '../../../models/CATModel';
import { CATRow } from './CATTableRow';

export type IProps = {};

const CATableFooter: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;

  if (module.dataLoaded.length === 0) {
    return null;
  }

  const total = module.dataLoaded.length;
  const done = module.dataLoaded.filter((d) => d.status === ITranslationStatus.TRANSLATION_DONE).length;
  return (
    <CATRow>
      <P>
        {done} / {total}
      </P>
    </CATRow>
  );
};

export default CATableFooter;

const P = styled.p`
  color: #8a8a8a;
  font-size: 14px;
  margin: 2px 5px;
`;
