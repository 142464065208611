import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import TextInput from 'CMFW/input/TextInput';
import { EditorRow } from 'components/common/styles';
import InflectViewModal from 'components/inflect/InflectViewModal';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import BooleanIcon from '../../../CMFW/input/BooleanIcon';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IItemSource } from '../../../models/ItemModel';
import { useForceRender } from '../../../hooks/useForceRender';

const ItemSource: React.FC = () => {
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [showInflect, setShowInflect] = useState(false);
  const forceRender = useForceRender();

  const parentKey = useContext(KeyContext);

  const module = useContext(ModuleContext);
  const moduleItemValue: IItemSource = module.getItemUpdatedValue(parentKey);

  useEffect(() => {
    setShowInflect(moduleItemValue.inflectEnabled);
  }, [moduleItemValue.inflectEnabled]);

  const handleCloseShowInflectsSource = () => {
    setShowSourceModal(false);
  };

  const handleInflectSourceClick = () => {
    setShowSourceModal(true);
  };

  return (
    <EditorRowStyled>
      <BooleanIcon onChange={forceRender} icon={faCheckCircle} keyName={'inflectEnabled'} />
      {showInflect && (
        <>
          <InflectIcon
            $off={moduleItemValue.nInflectsSource === 0}
            icon={faListAlt}
            onClick={handleInflectSourceClick}
          />
          <TextInput keyName="text" />
        </>
      )}
      {showSourceModal && <InflectViewModal isSource onClose={handleCloseShowInflectsSource} />}
    </EditorRowStyled>
  );
};

const InflectIcon = styled(FontAwesomeIcon)<{ $off: boolean }>`
  && {
    cursor: pointer;
    color: ${(props) => (props.$off ? 'red' : 'black')};
  }
  padding: 4px;
`;

const EditorRowStyled = styled(EditorRow)`
  height: 35px;
`;

export default ItemSource;
