import StateDialogButton from 'components/StateDialogButton';
import React from 'react';
import { IBoardTypes } from '../../../models/BoardsModel';

export type ICellRendererStateProps = {
  value: number;
  data?: { id: number };
};

export const StateExplanationRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_PANEL_EXPLANATIONS} {...props} />
);

export const StateSentenceRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_PANEL_SENTENCES} {...props} />
);

export const StateTestSentenceRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_PANEL_TEST_SENTENCES} {...props} />
);

export const StateSegmentContentRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_SEGMENTS} {...props} />
);

export const StateExerciseContentRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_EXERCISES} {...props} />
);

export const StateReadingContentRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_READINGS} {...props} />
);

export const StateTrainerContentRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_TRAINERS} {...props} />
);

export const StateDialogContentRenderer: React.FC<ICellRendererStateProps> = (props) => (
  <StateDialogButton type={IBoardTypes.BOARD_TYPE_DIALOGS} {...props} />
);
