import { ContainerRight, EditorRow } from 'components/common/styles';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import SaveButton from '../../../CMFW/button/SaveButton';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TextEditor from '../../../CMFW/input/TextEditor';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import HeadingSelect from '../../../components/selects/HeadingSelect';
import { useForceRender } from '../../../hooks/useForceRender';
import TrainerModel from '../../../models/TrainerModel';
import TrainerSegmentModel from '../../../models/TrainerSegmentModel';
import { ListDialects } from '../../../CMFW/layout/ListDialects';
import AIReviewPanel from '../../AIReview/components/AIReviewPanel';

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
    ['BulletedList', 'NumberedList', 'HorizontalRule'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

export type IProps = {
  trainerSegmentId: number;
};

const TrainerSegmentEdit: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const trainerModel = useContext(ModuleContext) as TrainerModel;

  const trainerSegmentModel = useMemo(() => {
    const rem = new TrainerSegmentModel({ render: forceRender, courseId: trainerModel.courseId });
    if (props.trainerSegmentId) {
      rem.loadItemEdit(props.trainerSegmentId);
      rem.trainerModel = trainerModel;
    }
    return rem;
  }, [forceRender, props.trainerSegmentId, trainerModel]);

  return (
    <ModuleContext.Provider value={trainerSegmentModel}>
      <ContainerRightStyled>
        <ItemLayout>
          <EditorRow>
            <SaveButton />
          </EditorRow>
          <EditorRow>
            <HeadingSelect isLinked />
          </EditorRow>
          <DialectContainer>
            <AIReviewPanel />
            <ListDialects keyName={'targets'}>
              <TextEditor
                keyName={'html'}
                clKeyName={'conceptLinkerConcepts'}
                keyError={'error'}
                config={CK_EDITOR_CONFIG}
                autoGrow={true}
              />
            </ListDialects>
          </DialectContainer>
          <EditorRow>
            <DeleteButton />
          </EditorRow>
        </ItemLayout>
        <LoadingItem />
      </ContainerRightStyled>
    </ModuleContext.Provider>
  );
};

export default TrainerSegmentEdit;

const DialectContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const ContainerRightStyled = styled(ContainerRight)`
  width: auto;
  flex: 1;
`;
