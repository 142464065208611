import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IAIReviewEdit } from '../../../types/aiReview';
import Button from '@material-ui/core/Button';
import { put } from '../../../services/request';
import KeyContext from '../../../CMFW/context/KeyContext';
import { createKeyPath } from '../../../CMFW/context/helper/keyContext';
import AIReviewIcon from './AIReviewIcon';

const KEY = 'aiReview';

type IProps = {
  hidable?: boolean;
};

const AIReviewPanel: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, KEY);

  const moduleModel = useContext(ModuleContext);

  const aiReview = moduleModel.getItemValue(aggregatedKeyName) as IAIReviewEdit | undefined;
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const status = aiReview ? aiReview.status : 0;

  useEffect(() => {
    setToggle(status === 1);
  }, [status]);

  const handleOK = async () => {
    if (aiReview) {
      await put(`/ai/review/${aiReview.id}`, { status: 2 });
      moduleModel.reloadItem();
    }
  };

  const handleInvalidate = async () => {
    if (aiReview && aiReview.translationId && aiReview.dialectId) {
      await put(`/translations/${aiReview.translationId}/dialects/${aiReview.dialectId}/invalidate`, {});
      moduleModel.reloadItem();
    }
  };

  if (!aiReview) {
    return null;
  }

  if (status !== 1 && props.hidable) {
    return null;
  }

  return (
    <Container>
      <ContainerTitle>
        <AIReviewIcon />
        {status === 1 && (
          <Button onClick={handleToggle}>
            {toggle ? (
              <FontAwesomeIcon icon={faArrowUp} size={'1x'} />
            ) : (
              <FontAwesomeIcon icon={faArrowDown} size={'1x'} />
            )}
          </Button>
        )}
      </ContainerTitle>
      {toggle && (
        <>
          <p style={{ userSelect: 'text' }} dangerouslySetInnerHTML={{ __html: aiReview.response }} />
          <div style={{ flexDirection: 'row', display: 'flex', gap: 10 }}>
            {aiReview.translationId && aiReview.dialectId && (
              <Button variant="outlined" size="small" onClick={handleInvalidate} style={{ width: 200 }}>
                Invalidate Translation
              </Button>
            )}
            <Button variant="outlined" size="small" onClick={handleOK} style={{ display: 'flex', flex: 1 }}>
              mark as OK
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px #bfc6cb;
  padding: 10px;
`;

const ContainerTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default AIReviewPanel;
