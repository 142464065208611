import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDataTransaction,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AllModules,
  CellClickedEvent,
  CellValueChangedEvent,
  RowDataUpdatedEvent,
  RowNode,
} from '@ag-grid-enterprise/all-modules';
import {
  StateSentenceRenderer,
  StateExplanationRenderer,
  StateTestSentenceRenderer,
  StateSegmentContentRenderer,
  StateExerciseContentRenderer,
  StateReadingContentRenderer,
  StateDialogContentRenderer,
  StateTrainerContentRenderer,
} from 'CMFW/grid/cellRenderers/StateRenderers';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import ModuleContext from '../context/ModuleContext';
import { ICellRenderers } from '../types/grid';
import { booleanRenderer } from './cellRenderers/boolean';
import { globalRenderers } from './cellRenderers/globalRenderers';
import { htmlRenderer } from './cellRenderers/html';
import { comparePosition } from './comparators/comparePosition';
import { IFields } from './fields';
import { SideBarToggle } from './statusPanels/SideBarToggle';

type IProps = {
  cellRenderers?: ICellRenderers;
  disableRowLoadFields?: IFields[];
  multiSelection?: boolean;
  handleRowClick?: (data: IGridData, selectedRows: IGridData[], columnField: string | undefined) => void;
  isFilterActive?: () => boolean;
  isRowVisible?: (node: RowNode) => boolean;
  showColumns?: string[];
  className?: string;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  defaultColumnWidth?: number;
};

const Grid: React.FC<IProps> = (props) => {
  const [api, setApi] = useState(null as GridApi | null);
  const [columnApi, setColumnApi] = useState(null as ColumnApi | null);

  const model = useContext(ModuleContext);

  const handleGridReady = (event: GridReadyEvent) => {
    setApi(event.api);
    setColumnApi(event.columnApi);
  };

  const getRowNodeId = (data: any) => data.id;

  if (api) {
    if (model.loadingGrid) {
      api.showLoadingOverlay();
    } else {
      api.hideOverlay();
      api.onFilterChanged();
    }
  }

  const gridDataUpdateRow = model.gridDataUpdateRow;
  useEffect(() => {
    if (gridDataUpdateRow.type !== 'none' && api) {
      const rowDataTransaction: RowDataTransaction = {};
      switch (gridDataUpdateRow.type) {
        case 'add':
          rowDataTransaction.add = [gridDataUpdateRow.data];
          break;
        case 'update':
          rowDataTransaction.update = [gridDataUpdateRow.data];
          break;
        case 'remove':
          rowDataTransaction.remove = [gridDataUpdateRow.data];
          break;
      }
      api.applyTransaction(rowDataTransaction);
    }
  }, [gridDataUpdateRow, api]);

  useEffect(() => {
    if (api && model.itemUpdated && model.itemUpdated.id === -1) {
      api.deselectAll();
    }
  }, [api, model.itemUpdated]);

  const options: GridOptions = {
    getRowNodeId,
    rowSelection: props.multiSelection ? 'multiple' : 'single',
    rowMultiSelectWithClick: true,
    enableColResize: true,
    enableSorting: true,
    enableFilter: true,
    animateRows: true,
    suppressCellSelection: true,
    suppressDragLeaveHidesColumns: true,
    suppressMakeColumnVisibleAfterUnGroup: true,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
    },
    frameworkComponents: {
      SideBarToggle: SideBarToggle,
      stateExplanationRenderer: StateExplanationRenderer,
      stateSentenceRenderer: StateSentenceRenderer,
      stateTestSentenceRenderer: StateTestSentenceRenderer,
      stateSegmentContentRenderer: StateSegmentContentRenderer,
      stateExerciseContentRenderer: StateExerciseContentRenderer,
      stateReadingContentRenderer: StateReadingContentRenderer,
      stateDialogContentRenderer: StateDialogContentRenderer,
      stateTrainerContentRenderer: StateTrainerContentRenderer,
    },
    statusBar: {
      statusPanels: [{ statusPanel: 'SideBarToggle' }, { statusPanel: 'agTotalRowCountComponent', align: 'left' }],
    },
    isExternalFilterPresent: props.isFilterActive,
    doesExternalFilterPass: props.isRowVisible,
  };

  const getColumnDefs = useCallback(() => {
    const renderers: ICellRenderers = {
      ...globalRenderers,
      ...props.cellRenderers,
    };

    const determineIfIsColGroupDef = (toBeDetermined: ColDef | ColGroupDef): toBeDetermined is ColGroupDef => {
      return !!(toBeDetermined as ColGroupDef).children;
    };

    const processColumn = (column: ColDef | ColGroupDef): ColDef | ColGroupDef => {
      if (determineIfIsColGroupDef(column)) {
        column.children = column.children.map((child) => processColumn(child));
        return column;
      } else {
        column.autoHeight = true;
        column.cellStyle = { 'white-space': 'normal' };
        const field = column.field as IFields;

        let cellRenderer;
        let comparator;

        if (field) {
          if (field.includes('CL ')) {
            cellRenderer = booleanRenderer;
          }
          if (renderers[field]) {
            cellRenderer = renderers[field];
          }
          if (field === IFields.position) {
            comparator = comparePosition;
          }

          if (props.showColumns && props.showColumns.indexOf(field) > -1) {
            columnApi && columnApi.hideColumn(field, false);
            columnApi && columnApi.moveColumn(field, 0);
          }
        }

        return {
          ...column,
          comparator,
          cellRenderer: cellRenderer ?? htmlRenderer,
          width: column.width ?? props.defaultColumnWidth ?? undefined,
        };
      }
    };

    return model.gridColumns.map((column) => processColumn(column));
  }, [model.gridColumns, props.cellRenderers, props.showColumns, props.defaultColumnWidth, columnApi]);

  const handleRowDataUpdated = (event: RowDataUpdatedEvent) => {
    if (gridDataUpdateRow.type !== 'remove' && gridDataUpdateRow.data && event.type === 'rowDataUpdated') {
      const nodeId = gridDataUpdateRow.data.id;

      if (nodeId !== undefined) {
        const rowNode = event.api.getRowNode(nodeId.toString());

        if (rowNode) {
          event.api.deselectAll();

          let rowIndex;
          event.api.forEachNodeAfterFilterAndSort((row, index) => {
            if (parseInt(row.id) === nodeId) {
              rowIndex = index;
            }
          });

          const rowExists = rowIndex !== undefined;

          if (rowExists) {
            event.api.ensureIndexVisible(rowIndex);
            rowNode.setSelected(true);
          }
        }
      }
    }
  };

  const handleCellClicked = (event: CellClickedEvent) => {
    if (props.handleRowClick) {
      props.handleRowClick(event.data, api?.getSelectedRows() || [], event.colDef.field);
      return;
    }

    const field = (event.column as any).colId as IFields;

    if (!props.disableRowLoadFields || props.disableRowLoadFields.indexOf(field) === -1) {
      model.loadItemEdit.bind(model)(getRowNodeId(event.data));
    }
  };

  const classes = `ag-theme-balham ${props.className}`;

  return (
    <Container className={classes}>
      <AgGridReact
        modules={AllModules}
        columnDefs={getColumnDefs()}
        rowData={model.gridData}
        gridOptions={options}
        onGridReady={handleGridReady}
        onRowDataUpdated={handleRowDataUpdated}
        onCellClicked={handleCellClicked}
        getRowHeight={(params: any) => (params.data ? params.data.rowHeight : 30)}
        onCellValueChanged={props.onCellValueChanged}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default Grid;
