import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import React, { useMemo, useState } from 'react';
import { SelectLayout } from './SelectLayout';
import TranslationProjectsModel, { ITranslationProjectSelect } from '../../models/TranslationProjectsModel';

type IProps = {
  onChange?: (translationProject: ITranslationProjectSelect) => void;
  className?: string;
  courseId: number;
};

const TranslationProjectSelect: React.FC<IProps> = (props) => {
  const [currentTranslationProjectId, setCurrentTranslationProjectId] = useState<number | null>(null);
  const cacheKey = getCacheKey(IRefIds.translationProject, props.courseId);
  const dataModel = useMemo(() => new TranslationProjectsModel(props.courseId), [props.courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (value: ITranslationProjectSelect) => {
    const selectedTranslationProjectId = value.id;
    setCurrentTranslationProjectId(selectedTranslationProjectId);
    props.onChange && props.onChange(value);
  };

  return (
    <SelectLayout
      className={props.className}
      data={cacheSelectData}
      onChange={handleChange}
      label="Translation Project"
      optionLabelKey="name"
      currentValue={currentTranslationProjectId}
    />
  );
};

export default TranslationProjectSelect;
