import { ICompoundType } from 'models/CompoundModel';
import { IKlineSelect } from 'types/klines';
import { IModuleRequest } from 'types/module';
import { IPanelIntro } from 'types/panelsIntro';
import { IRefIds } from '../constants/refs';

export const sortPanelsIntroAlphabetically = (a: IPanelIntro, b: IPanelIntro) => (a.title < b.title ? -1 : 1);

export const sortKlinesAlphabetically = (klines: IKlineSelect[]) => klines.sort((a, b) => (a.title < b.title ? -1 : 1));

export const sortCompoundTypesAlphabetically = (a: ICompoundType, b: ICompoundType) => (a.name < b.name ? -1 : 1);

export const encodeModule = (module: IModuleRequest) => {
  let refId = module.refId;
  if (module.courseId !== 0 && refId === IRefIds.vocs) {
    refId = IRefIds.vocsCourse;
  }
  return module.courseId + '-' + refId;
};

export const getKlineNameFromPath = (option: IKlineSelect) => {
  const parts = option.title.split(' -> ');
  return parts[parts.length - 1];
};

export const convertTargetDialectCodeToApi = (code: string) => code + '(t)';
export const convertSourceDialectCodeToApi = (code: string) => code + '(s)';
