import React from 'react';

export type IPanelEstructureContext = {
  sourceDialectId: number;
  updateSourceDialectId: (sourceId: number) => void;
  editable: boolean;
};

const panelEstructureContextDefault: IPanelEstructureContext = {
  sourceDialectId: 0,
  editable: true,
  updateSourceDialectId: () => {},
};

const PanelEstructureContext = React.createContext<IPanelEstructureContext>(panelEstructureContextDefault);

export default PanelEstructureContext;
