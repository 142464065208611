import React, { useContext } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import {
  ILessonOutlineCycleActivity,
  ILessonOutlineCycleActivityDialog,
  ILessonOutlineCycleActivityExercise,
  ILessonOutlineCycleActivityPanel,
  ILessonOutlineCycleActivityPanelIntro,
  ILessonOutlineCycleActivityReading,
  ILessonOutlineCycleActivityVoc,
} from '../../../types/lessonOutline';
import { IRefIds } from '../../../constants/refs';
import LessonOutlineActivityExercise from './activities/LessonOutlineActivityExercise';
import LessonOutlineActivityPanel from './activities/LessonOutlineActivityPanel';
import LessonOutlineActivityVoc from './activities/LessonOutlineActivityVoc';
import LessonOutlineActivityReading from './activities/LessonOutlineActivityReading';
import LessonOutlineActivityPanelIntro from './activities/LessonOutlineActivityPanelIntro';
import LessonOutlineContext from '../context/LessonOutlineContext';
import LessonOutlineActivityDialog from './activities/LessonOutlineActivityDialog';

export type IProps = {
  activity: ILessonOutlineCycleActivity;
  index: number;
};

const LessonOutlineActivity: React.FC<IProps> = (props) => {
  const lessonOutlineContext = useContext(LessonOutlineContext);

  if (!lessonOutlineContext.showActivity.includes(props.activity.ref)) {
    return null;
  }

  return (
    <Draggable
      isDragDisabled={!lessonOutlineContext.DNDEnabled}
      draggableId={`activity-${props.activity.activityId}`}
      index={props.index}
    >
      {(provided) => (
        <WrapperActivity {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {props.activity.ref === IRefIds.exercise && (
            <LessonOutlineActivityExercise activity={props.activity as ILessonOutlineCycleActivityExercise} />
          )}
          {props.activity.ref === IRefIds.panels && (
            <LessonOutlineActivityPanel activity={props.activity as ILessonOutlineCycleActivityPanel} />
          )}
          {props.activity.ref === IRefIds.panelIntro && (
            <LessonOutlineActivityPanelIntro activity={props.activity as ILessonOutlineCycleActivityPanelIntro} />
          )}
          {props.activity.ref === IRefIds.vocs && (
            <LessonOutlineActivityVoc activity={props.activity as ILessonOutlineCycleActivityVoc} />
          )}
          {props.activity.ref === IRefIds.dialog && (
            <LessonOutlineActivityDialog activity={props.activity as ILessonOutlineCycleActivityDialog} />
          )}
          {props.activity.ref === IRefIds.reading && (
            <LessonOutlineActivityReading activity={props.activity as ILessonOutlineCycleActivityReading} />
          )}
        </WrapperActivity>
      )}
    </Draggable>
  );
};

export default LessonOutlineActivity;

export const WrapperActivity = styled.div<{ extraWide?: boolean }>`
  margin: 4px;
  font-size: 14px;
  min-width: 90px;
  width: ${(props) => (props.extraWide ? 220 : 90)}px;
`;
