import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ModalWrapper } from 'components/ModalWrapper';
import SearchBarDialect from 'components/search/SearchBarDialect';
import { useForceRender } from 'hooks/useForceRender';
import SentenceModel, { ISentence } from 'models/SentenceModel';
import React, { useContext, useMemo } from 'react';
import { IGridData } from 'types/models';

type IProps = {
  onClose: () => void;
  onChooseSentence: (data: ISentence) => void;
};

const SentenceSearchModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext);

  const sentenceModel = useMemo(() => new SentenceModel({ render: forceRender, courseId: moduleModel.courseId }), [
    forceRender,
    moduleModel.courseId,
  ]);

  const handleSearch = (text: string, dialect: string) => {
    sentenceModel.getGridSearch(text, dialect);
  };

  const handleRowClick = (data: IGridData) => {
    props.onChooseSentence(data as ISentence);
  };

  return (
    <ModalWrapper title="Search Sentence" width={468} height={555} onClose={props.onClose}>
      <ModuleContext.Provider value={sentenceModel}>
        <SearchBarDialect handleSearch={handleSearch} />
        <Grid handleRowClick={handleRowClick} />
      </ModuleContext.Provider>
    </ModalWrapper>
  );
};

export default SentenceSearchModal;
