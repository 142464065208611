import { get } from 'services/request';
import { IItemId } from '../types/content';
import { IQAResponse } from './QAResultsModel';

type IModelOptions = {
  courseId: number;
  ref: number;
  sourceDialect: number;
  batchSize: number;
  render: () => void;
  onResults: (results: IQAResponse[]) => void;
  loadItems?: number[];
};

export default class QARefModel {
  items: number[] = [];
  done: number[] = [];
  fail: number[] = [];
  courseId: number;
  ref: number;
  sourceDialect: number;
  batchSize: number;
  ready = false;
  render: () => void;
  onResults: (result: IQAResponse[]) => void;
  running = false;
  completed = false;
  startLesson = 1;
  endLesson = 1000;
  loadItems?: number[] = [];

  constructor(options: IModelOptions & { startLesson: number; endLesson: number }) {
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.sourceDialect = options.sourceDialect;
    this.batchSize = options.batchSize;
    this.render = options.render;
    this.startLesson = options.startLesson;
    this.endLesson = options.endLesson;
    this.onResults = options.onResults;
    this.loadItems = options.loadItems;
    this.getItems();
  }

  private async getItems() {
    if (this.loadItems) {
      this.items = this.loadItems;
    } else {
      const elements = await get<IItemId[]>(
        `QA/groups/${this.courseId}/refs/${this.ref}/source-dialects/${this.sourceDialect}/refs-qa`,
        {
          startLesson: this.startLesson,
          endLesson: this.endLesson,
        },
      );
      this.items = elements.map((element) => element.id);
    }
    this.ready = true;
    this.render();
  }

  public toggle() {
    if (this.running) {
      this.stop();
    } else {
      this.start();
    }
  }

  private start() {
    this.running = true;
    this.render();
    this.continue();
  }

  private async continue() {
    if (this.running && !this.completed) {
      const batchIds = this.items.slice(this.done.length, this.done.length + this.batchSize);

      if (batchIds.length > 0) {
        const results = await get<IQAResponse[]>(
          `QA/groups/${this.courseId}/refs/${this.ref}/source-dialects/${this.sourceDialect}/ref-qa?ids=${batchIds.join(
            ',',
          )}`,
        );
        results.forEach((result) => {
          this.done.push(result.parentId);
          result.errors.forEach((error) => {
            if (error.verified === 0) {
              this.fail.push(result.parentId);
            }
          });
        });
        this.onResults(results);
        this.render();
        await this.continue();
      } else {
        this.completed = true;
        this.stop();
      }
    }
  }

  private stop() {
    this.running = false;
    this.render();
  }
}
