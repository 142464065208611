import { get } from 'services/request';
import { IItemId } from '../types/content';

type ICheckerResult = {
  id: number;
  conceptLinkerDone: boolean;
};

type IModelOptions = {
  courseId: number;
  ref: number;
  targetDialect: number;
  batchSize: number;
  render: () => void;
};

export default class ConceptLinkerCheckerRefModel {
  items: number[] = [];
  fail: number[] = [];
  done: number[] = [];
  courseId: number;
  ref: number;
  targetDialect: number;
  batchSize: number;
  ready = false;
  render: () => void;
  running = false;
  completed = false;
  startLesson = 1;
  endLesson = 1000;

  constructor(options: IModelOptions & { startLesson: number; endLesson: number }) {
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.targetDialect = options.targetDialect;
    this.batchSize = options.batchSize;
    this.render = options.render;
    this.startLesson = options.startLesson;
    this.endLesson = options.endLesson;
    this.getItems();
  }

  private async getItems() {
    const elements = await get<IItemId[]>(
      `concepts-linker/groups/${this.courseId}/refs/${this.ref}/target-dialects/${this.targetDialect}/checker-ref-elements`,
      {
        startLesson: this.startLesson,
        endLesson: this.endLesson,
      },
    );

    this.items = elements.map((element) => element.id);
    this.ready = true;
    this.render();
  }

  public toggle() {
    if (this.running) {
      this.stop();
    } else {
      this.start();
    }
  }

  private start() {
    this.running = true;
    this.render();
    this.continue();
  }

  private async continue() {
    if (this.running && !this.completed) {
      const batchIds = this.items.slice(this.done.length, this.done.length + this.batchSize);

      if (batchIds.length > 0) {
        const results = await get<ICheckerResult[]>(
          `concepts-linker/groups/${this.courseId}/refs/${this.ref}/target-dialects/${
            this.targetDialect
          }/checker-ref-elements-check?ids=${batchIds.join(',')}`,
        );
        results.forEach((result) => {
          this.done.push(result.id);
          if (!result.conceptLinkerDone) {
            this.fail.push(result.id);
          }
        });
        this.render();
        await this.continue();
      } else {
        this.completed = true;
        this.stop();
      }
    }
  }

  private stop() {
    this.running = false;
    this.render();
  }
}
