import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { ModalWrapper } from 'components/ModalWrapper';
import { SelectLayout } from 'components/selects/SelectLayout';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import DialogSpeakerSelectModel from 'models/DialogSpeakerSelectModel';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IDialogSpeaker } from 'types/dialog';
import DialogSpeaker from './DialogSpeaker';

const KEY = 'speaker';

type IProps = {
  dialogId: number;
};

const NONE_SPEAKER: IDialogSpeaker = { id: 0, name: 'DESCRIPTION', gender: 'm' };

const DialogSpeakerSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const [openModal, setOpenModal] = useState(false);

  const keyContext = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(keyContext, KEY);

  const cacheKey = getCacheKey(IRefIds.dialogSpeaker, props.dialogId);
  const dataModel = useMemo(() => new DialogSpeakerSelectModel(props.dialogId), [props.dialogId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    return [NONE_SPEAKER, ...cacheSelectData];
  }, [cacheSelectData]);

  const moduleItemValue: number = module.getItemValue.bind(module)(aggregatedKeyName) || NONE_SPEAKER.id;
  const [currentSpeaker, setCurrentSpeaker] = useCurrentItemValue(moduleItemValue, NONE_SPEAKER.id);

  const handleChange = (value: IDialogSpeaker) => {
    const selectedSpeakerId = value.id;
    setCurrentSpeaker(selectedSpeakerId);
    module.updateItemValue.bind(module)(aggregatedKeyName, selectedSpeakerId);
  };

  return (
    <Wrapper>
      <FontAwesomeIconStyled icon={faEdit} color={'#6b6b6b'} onClick={() => setOpenModal(true)} />
      <SelectLayout
        data={selectData}
        onChange={handleChange}
        label="Speaker"
        optionLabelKey="name"
        currentValue={currentSpeaker}
        originalValue={moduleItemValue}
      />
      {openModal && (
        <ModalWrapper title={'Speakers'} width={500} height={300} onClose={() => setOpenModal(false)}>
          <DialogSpeaker dialogId={props.dialogId} />
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default DialogSpeakerSelect;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  padding-right: 10px;
  cursor: pointer;
`;
