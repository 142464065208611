import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import VocCategorySelectModel from 'models/VocCategorySelectModel';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';

const KEY = 'vocCategory';

type IProps = {
  isLinked?: boolean;
  allOption?: boolean;
  rootOption?: boolean;
  onChange?: (catId: number) => void;
  className?: string;
  dialectId?: number;
  keyName?: string;
};

type ISelectData = { value: number; label: string };

export const VocCategorySelect: React.FC<IProps> = (props) => {
  const { isLinked, onChange } = props;

  const module = useContext(ModuleContext);
  const moduleItemValue = module.getItemValue(props.keyName ?? KEY);

  const [currentVocCategoryId, setCurrentVocCategoryId] = useCurrentItemValue(moduleItemValue, 0, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.vocabularyCategories, courseId);
  const dataModel = useMemo(() => new VocCategorySelectModel(props.dialectId ?? 0), [props.dialectId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData: null | ISelectData[] = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    let data = cacheSelectData
      .filter((vocCategory) => vocCategory.nChildren === 0)
      .map((vocCategory) => ({
        label: vocCategory.fullPath,
        value: vocCategory.id,
      }))
      .sort((a, b) => {
        const labelA = a.label;
        const labelB = b.label;
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });

    if (props.allOption) {
      data = [{ label: 'ALL', value: -1 }, ...data];
    }

    if (props.rootOption) {
      data = [{ label: 'ROOT', value: 0 }, ...data];
    }
    return data;
  }, [cacheSelectData, props.allOption, props.rootOption]);

  const handleChange = (value: ISelectData) => {
    const vocCategoryId = value.value;
    setCurrentVocCategoryId(vocCategoryId);
    isLinked && module.updateItemValue(props.keyName ?? KEY, vocCategoryId);
    onChange && onChange(vocCategoryId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Voc Category"
      optionValueKey="value"
      optionLabelKey="label"
      currentValue={currentVocCategoryId}
      originalValue={isLinked ? moduleItemValue : currentVocCategoryId}
    />
  );
};
