import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow } from 'components/common/styles';
import { SelectLayout } from 'components/selects/SelectLayout';
import BoardsModel, { IBoard } from 'models/BoardsModel';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import FreelancesModal from './modal/FreelancesModal';

const BoardSelect: React.FC = () => {
  const boardsModel = useContext(ModuleContext) as BoardsModel;

  const [options, setOptions] = useState(null as IBoard[] | null);
  const [currentBoard, setCurrentBoard] = useState(null as IBoard | null);
  const [loadedBoard, setLoadedBoard] = useState(null as IBoard | null);

  useEffect(() => {
    (async () => {
      const boards = await boardsModel.getBoards();
      setOptions(boards);
      setCurrentBoard(boards[0]);
    })();
  }, [boardsModel]);

  const handleLoadClick = () => {
    if (currentBoard) {
      setLoadedBoard(currentBoard);
      boardsModel.setBoardType(currentBoard.type);
      boardsModel.loadItemEdit(currentBoard.id);
    }
  };

  const handleChange = (board: IBoard) => {
    setCurrentBoard(board);
  };

  const [isFreelanceModalOpen, setIsFreelanceModalOpen] = useState(false);

  const handleFreelancesClick = () => {
    setIsFreelanceModalOpen(true);
  };

  const handleFreelanceModalClose = () => {
    setIsFreelanceModalOpen(false);
  };

  const renderModals = () => {
    if (isFreelanceModalOpen && loadedBoard) {
      return <FreelancesModal onClose={handleFreelanceModalClose} boardId={loadedBoard.id} />;
    }
  };

  return (
    <>
      <EditorRow>
        <SelectLayoutStyled
          key={currentBoard?.id}
          data={options}
          onChange={handleChange}
          currentValue={currentBoard?.id}
          optionLabelKey="name"
        />
        <Button disabled={!currentBoard} variant="contained" size="small" onClick={handleLoadClick}>
          load
        </Button>
      </EditorRow>
      <FreelancesButton disabled={!loadedBoard} variant="contained" size="small" onClick={handleFreelancesClick}>
        {loadedBoard ? '(' + loadedBoard.nFreelances + ') ' : ''}freelances
      </FreelancesButton>
      {renderModals()}
    </>
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 420px;
`;

const FreelancesButton = styled(Button)`
  && {
    margin-right: 10px;
  }
`;

export default BoardSelect;
