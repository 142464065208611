import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerRight, EditorRow, FormContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useEffect, useMemo } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import Grid from '../../../CMFW/grid/Grid';
import { IGridData } from '../../../types/models';
import HeadingUsesModel, { IHeadingUsesGrid } from '../../../models/HeadingUsesModel';
import { tabsOpened } from '../../../store/actions/tabs';
import { useDispatch } from 'react-redux';
import { IRefIds } from '../../../constants/refs';
import { nonZeroRenderer } from '../../../CMFW/grid/cellRenderers/nonZero';
import { IFields } from '../../../CMFW/grid/fields';

export type IProps = {};

const HeadingDashboard: React.FC<IProps> = () => {
  const forceRender = useForceRender();
  const dispatch = useDispatch();

  const module = useContext(ModuleContext);
  const moduleModel = useMemo(() => new HeadingUsesModel({ render: forceRender }), [forceRender]);

  useEffect(() => {
    moduleModel.reset();
  }, [module.itemId]);

  const handleLoadClick = () => {
    moduleModel.getUses(module.itemId);
  };

  const onRowClick = (data: IGridData, selectedRows: IGridData[], field: string | undefined) => {
    const row = data as IHeadingUsesGrid;
    console.log(row, field);
    switch (field) {
      case 'nSegments':
        dispatch(
          tabsOpened({
            refId: IRefIds.segment,
            search: row['segmentIds'].join(','),
            courseId: row['id'],
          }),
        );
        break;
      case 'nReadingExercises':
        dispatch(
          tabsOpened({
            refId: IRefIds.reading,
            search: row['readingIds'].join(','),
            courseId: row['id'],
          }),
        );
        break;
      case 'nTrainerSegments':
        dispatch(
          tabsOpened({
            refId: IRefIds.trainer,
            search: row['trainerIds'].join(','),
            courseId: row['id'],
          }),
        );
        break;
      case 'nExercises':
        dispatch(
          tabsOpened({
            refId: IRefIds.exercise,
            search: row['exerciseIds'].join(','),
            courseId: row['id'],
          }),
        );
        break;
    }
  };

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <FormContainerStyled>
          <EditorRowStyled>
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Grid
            handleRowClick={onRowClick}
            cellRenderers={{
              [IFields.nSegments]: nonZeroRenderer,
              [IFields.nReadingExercises]: nonZeroRenderer,
              [IFields.nTrainerSegments]: nonZeroRenderer,
              [IFields.nExercises]: nonZeroRenderer,
            }}
          />
        </FormContainerStyled>
      </ContainerRight>
    </ModuleContext.Provider>
  );
};

export default HeadingDashboard;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const FormContainerStyled = styled(FormContainer)`
  max-width: initial;
`;
