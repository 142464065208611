import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';
import { ITtsLab, ITtsLabEdit } from '../types/ttsLab';
import { IItemId } from '../types/content';

export default class TtsLabModel extends BaseModel<ITtsLab, ITtsLabEdit> {
  readonly DIFFERENT_NEW_ID = -1;
  constructor(options: IModelOptions) {
    super(options);
    this.ref = IRefIds.ttsLab;
    this.gridName = 'TtsLab';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ITtsLab[]>(`ttsLab`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ITtsLab>(`ttsLab/${itemId}/grid-row`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<ITtsLabEdit>(`ttsLab/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.DIFFERENT_NEW_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const item = this.itemUpdated as ITtsLabEdit;
    await put(`ttsLab/${item.id}`, item);
    await Promise.all([this.updateGridRow(item.id, 'update'), this.loadItemEdit(item.id)]);
  }

  async createItem() {
    const result = await post<IItemId>(`ttsLab`, {
      ...this.itemUpdated,
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      this.itemId = newItemId;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ITtsLabEdit>(`ttsLab/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`ttsLab/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async generate() {
    const { id } = this.itemUpdated;

    await get(`ttsLab/${id}/generate`);
    await this.loadItemEdit(id);
  }
}
