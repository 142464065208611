import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import LanguagesModel from '../../models/LanguagesModel';
import NewButton from '../../CMFW/button/NewButton';

const Languages: React.FC = () => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new LanguagesModel({ render: forceRender }), [forceRender]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={420}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'code'} label={'code'} />
                <TextInput keyName={'name'} label={'Name'} />
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <div />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Languages;
