import TooltipCompound from 'components/conceptLink/TooltipCompound';
import TooltipPanel from 'components/conceptLink/TooltipPanel';
import TooltipVoc from 'components/conceptLink/TooltipVoc';
import { IRefIds } from 'constants/refs';
import React from 'react';
import TooltipCulturals from './TooltipCulturals';

export type IItem = {
  ref: number;
  refId: number;
  courseId: number;
  targetDialectId: number;
};

export const getTooltip = (item: IItem) => {
  switch (item.ref) {
    case IRefIds.panels:
      return <TooltipPanel id={item.refId} courseId={item.courseId} targetDialectId={item.targetDialectId} />;
    case IRefIds.vocs:
      return <TooltipVoc id={item.refId} courseId={item.courseId} targetDialectId={item.targetDialectId} />;
    case IRefIds.compounds:
      return <TooltipCompound id={item.refId} courseId={item.courseId} targetDialectId={item.targetDialectId} />;
    case IRefIds.cultural:
      return <TooltipCulturals id={item.refId} courseId={item.courseId} targetDialectId={item.targetDialectId} />;
    default:
      return null;
  }
};
