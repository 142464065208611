import React, { forwardRef, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import CATTextColor from './CATTextColor';
import { TextareaAutosize } from '@material-ui/core';
import { ITranslationStatus } from '../../../types/Translation';
import { changeAwareMixin, IChangeAwareProps } from '../../../components/common/styles';

export type IProps = {
  id?: number;
  text: string;
  className?: string;
  focus?: boolean;
  onChangeHeight?: () => void;
  onFocus?: (id: number) => void;
  onUpdateText?: (text: string, status?: ITranslationStatus) => void;
  onChange?: (text: string) => void;
  changed?: boolean;
};

const LINE_HEIGHT = 20;

const CATEditor = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [text, setText] = useState(props.text);

  useEffect(() => {
    setText(props.text);
    setTimeout(() => props.onChangeHeight && props.onChangeHeight(), 1);
  }, [props.text]);

  useEffect(() => {
    props.focus && inputRef.current !== null && inputRef.current.focus();
  }, [inputRef, props.focus]);

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    setTimeout(() => props.onChangeHeight && props.onChangeHeight(), 1);
    props.onChange && props.onChange(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.ctrlKey) {
      props.onUpdateText && props.onUpdateText(text, ITranslationStatus.TRANSLATION_DONE);
      event.stopPropagation();
      event.preventDefault();
    }
    if (event.key === 'Enter' && event.shiftKey) {
      props.onUpdateText && props.onUpdateText(text);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onBlur = () => {
    if (props.text !== text) {
      props.onUpdateText && props.onUpdateText(text);
    }
  };

  const onFocus = () => {
    if (props.id && props.onFocus) {
      props.onFocus(props.id);
    }
    props.onChangeHeight && props.onChangeHeight();
  };

  return (
    <Wrapper className={props.className} ref={ref} $changed={!!props.changed}>
      <CATTextColor text={text} />
      <WrapperAbsolute>
        <Input
          onBlur={onBlur}
          onChange={onChangeInput}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          value={text}
          ref={inputRef}
        />
      </WrapperAbsolute>
    </Wrapper>
  );
});
CATEditor.displayName = 'SDLEditor';
export default CATEditor;

const Wrapper = styled.div<IChangeAwareProps>`
  position: relative;
  font-size: 16px;
  width: 100%;
  ${changeAwareMixin}
`;

const WrapperAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Input = styled(TextareaAutosize)`
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  line-height: ${LINE_HEIGHT}px;
  font-size: 16px;
  font-family: Monospace;
  z-index: 2;
  margin: 0;
  padding: 0;

  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

  color: #0000;
  caret-color: #000;
`;
