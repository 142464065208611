import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IReadingText, IReadingTextEdit, IReadingTextTarget } from '../types/reading';
import ReadingModel from './ReadingModel';
import { IRefIds } from '../constants/refs';
import TranslationManagerModel from './TranslationManagerModel';

export default class ReadingTextModel extends BaseModel<IReadingText, IReadingTextEdit> {
  readingModel: ReadingModel | null = null;
  translations: TranslationManagerModel;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.readingText;
    this.translations = new TranslationManagerModel({ ref: IRefIds.readingText });
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as IReadingTextEdit;

    await Promise.all([
      ...item.targets.map((target) => put(`/readings/texts/${item.id}/target-dialect/${target.dialectId}`, target)),
    ]);
    await this.translations.update(item.id, item.sources);
    await Promise.all([this.loadItemEdit(item.id), this.reloadReadingModel()]);
  }

  async delete() {
    await super.delete();
    const item = this.itemUpdated as IReadingTextEdit;
    await del(`readings/texts/${item.id}`);
    this.itemUpdated = null;
    this.loadingItem = false;
    this.render();
    await this.reloadReadingModel();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets, sources] = await Promise.all([
      get<IReadingTextEdit>(`readings/texts/${itemId}`),
      get<IReadingTextTarget[]>(`readings/texts/${itemId}/targets`),
      this.translations.get(itemId),
    ]);

    item.targets = targets;
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async reloadReadingModel() {
    if (this.readingModel) {
      await this.readingModel.forceReloadReadingTexts.bind(this.readingModel)();
    }
  }
}
