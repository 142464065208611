import { ModalWrapper } from 'components/ModalWrapper';
import React from 'react';
import AudioWaveEditor from './AudioWaveEditor';

type IProps = {
  onClose(): void;
};

const AudioWaveModal: React.FC<IProps> = (props) => {
  return (
    <ModalWrapper title="Audio" height={238} onClose={props.onClose}>
      <AudioWaveEditor />
    </ModalWrapper>
  );
};

export default AudioWaveModal;
