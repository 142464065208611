import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../CMFW/context/ModuleContext';
import FeedbackModel from '../../models/FeedbackModel';
import FeedbackTop from './FeedbackTop';
import FeedbackLesson from './cards/FeedbackLesson';
import { LoadingItem } from '../../CMFW/layout/Loading';
import FeedbackContext, { feedbackContextDefault } from './context/FeedbackContext';

export type IProps = {
  courseId: number;
};

const Feedback: React.FC<IProps> = (props) => {
  const [feedbackContext, setFeedbackContext] = React.useState(feedbackContextDefault);

  const updateOnlyUpdated = (value: boolean) => {
    setFeedbackContext({ ...feedbackContext, onlyTracking: value });
  };
  const updateShowCycleRate = (value: boolean) => {
    setFeedbackContext({ ...feedbackContext, showCycleRate: value });
  };

  const forceRender = useForceRender();
  const feedbackModel = useMemo(() => new FeedbackModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  return (
    <Wrapper>
      <ModuleContext.Provider value={feedbackModel}>
        <FeedbackContext.Provider
          value={{ ...feedbackContext, updateShowCycleRate, updateOnlyTracking: updateOnlyUpdated }}
        >
          <FeedbackTop />
          <WrapperLessons>
            {feedbackModel.getLessons().map((lesson) => (
              <FeedbackLesson lesson={lesson} key={`lesson-${lesson.id}`} />
            ))}
            <LoadingItem />
          </WrapperLessons>
        </FeedbackContext.Provider>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default Feedback;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const WrapperLessons = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  background-color: white;
`;
