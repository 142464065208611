import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { TextFieldStyled } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import BoardsModel, { IBoardCard } from 'models/BoardsModel';
import CardCommentsModel, { IComment } from 'models/CardCommentsModel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  onClose: () => void;
  card: IBoardCard;
};

const CommentsModal: React.FC<IProps> = (props) => {
  const { card } = props;
  const [text, setText] = useState('');

  const cardCommentsModel = useMemo(() => new CardCommentsModel(card.id), [card.id]);
  const boardsModel = useContext(ModuleContext) as BoardsModel;

  const [comments, setComments] = useState([] as IComment[]);

  useEffect(() => {
    (async () => {
      const comments = await cardCommentsModel.getCardComments(card.id);
      setComments(comments);
    })();
  }, [cardCommentsModel, card.id]);

  const handleRemoveClick = async (commentId: number) => {
    const updatedComments = await cardCommentsModel.deleteCardComment(commentId, card.id);
    setComments(updatedComments);
    boardsModel.updateCard(card);
  };

  const handleReadClick = async (comment: IComment) => {
    const updatedComments = await cardCommentsModel.updateCardComment({ ...comment, seen: true, cardId: card.id });
    setComments(updatedComments);
  };

  const handleTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleAddClick = async () => {
    const updatedComments = await cardCommentsModel.addCardComment({
      id: -1,
      cardId: card.id,
      seen: false,
      text,
    });
    setComments(updatedComments);
    setText('');
    boardsModel.updateCard(card);
  };

  return (
    <ModalWrapper title="Comments" width={352} height={502} onClose={props.onClose}>
      <Comments>
        {comments.map((comment) => (
          <Comment key={comment.id}>
            <RemoveButton icon={faTimes} onClick={() => handleRemoveClick(comment.id)} />
            {!comment.seen && !comment.ownComment && (
              <ReadButton icon={faEye} onClick={() => handleReadClick(comment)} />
            )}
            <div>
              <b>{comment.userName}</b>
              <CommentDate>{comment.sDate}</CommentDate>
            </div>
            <div dangerouslySetInnerHTML={{ __html: comment.text }} />
          </Comment>
        ))}
      </Comments>
      <TextFieldRestyled value={text} variant="outlined" multiline rows={4} onChange={handleTextChanged} />
      <Button variant="contained" size="small" color="primary" onClick={handleAddClick}>
        add comment
      </Button>
    </ModalWrapper>
  );
};

const Comments = styled.div`
  margin-bottom: 10px;
  flex: 1;
  overflow: auto;
`;

const Comment = styled.div`
  margin-bottom: 8px;
`;

const RemoveButton = styled(FontAwesomeIcon)`
  color: rgb(255, 182, 182);
  float: right;
  cursor: pointer;
  margin-right: 4px;
`;

const ReadButton = styled(FontAwesomeIcon)`
  color: rgb(77, 128, 84);
  float: right;
  cursor: pointer;
  margin-right: 4px;
`;

const CommentDate = styled.span`
  font-size: 12px;
  margin: 7px 4px;
`;

const TextFieldRestyled = styled(TextFieldStyled)`
  && {
    margin-bottom: 2px;
    height: 90px;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export default CommentsModal;
