import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow } from 'components/common/styles';
import SearchBarDialect from 'components/search/SearchBarDialect';
import { CompoundTypeSelect } from 'components/selects/CompoundTypeSelect';
import CompoundModel from 'models/CompoundModel';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

type IProps = {};

const CompoundSearch: React.FC<IProps> = () => {
  const [isSearchingText, setIsSearchingText] = useState(false);
  const module = useContext(ModuleContext) as CompoundModel;

  const handleSearch = () => {
    setIsSearchingText(true);
  };

  const handleCancel = () => {
    setIsSearchingText(false);
    module.getGridSearch();
  };

  return (
    <>
      <EditorRow>
        <CompoundTypeSelectStyled isSearch isLinked={false} $isHidden={isSearchingText} />
      </EditorRow>
      <SearchBarDialect
        handleSearch={handleSearch}
        hideDialectSelect
        handleCancel={handleCancel}
        showCancelButton={isSearchingText}
      />
    </>
  );
};

const CompoundTypeSelectStyled = styled(CompoundTypeSelect)<{ $isHidden: boolean }>`
  visibility: ${(props) => (props.$isHidden ? 'hidden' : 'visible')};
`;

export default CompoundSearch;
