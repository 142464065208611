import { GridApi } from '@ag-grid-community/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  api: GridApi;
};

export const SideBarToggle: React.FC<IProps> = (props) => {
  const { api } = props;

  const [sideBarVisible, setSideBarVisible] = useState(false);

  useEffect(() => {
    api.setSideBarVisible(sideBarVisible);
  }, [api, sideBarVisible]);

  const handleClick = () => {
    setSideBarVisible(!sideBarVisible);
    api.setSideBarVisible(sideBarVisible);
  };

  return <Container onClick={handleClick}>show panel</Container>;
};

const Container = styled.div`
  display: flex;
  margin: 4px;
  background-color: #f9f9f9;
  padding: 4px;
  border-radius: 4px;
  color: #bbbbbb;
  cursor: pointer;
`;
