import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { CardContainer, CardHeader, CardId } from 'components/common/styles';
import { IRefIds } from 'constants/refs';
import { convertSourceDialectCodeToApi, convertTargetDialectCodeToApi } from 'helpers/helpers';
import { useForceRender } from 'hooks/useForceRender';
import VocCardModel from 'models/VocCardModel';
import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import { IAppState } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { IVocCard } from 'types/content';

type IProps = {
  id: number;
};

const VocCard: React.FC<IProps> = (props) => {
  const { id } = props;

  const forceRender = useForceRender();
  const dispatch = useDispatch();
  const moduleModel = useContext(ModuleContext);
  const sourceDialect = useSelector(
    (state: IAppState) => state.courseConfig[moduleModel.courseId].sourceDialectDefaultId,
  );
  const targetDialect = useSelector(
    (state: IAppState) => state.courseConfig[moduleModel.courseId].targetDialectDefaultId,
  );

  const vocCardModel = useMemo(() => new VocCardModel({ render: forceRender, vocId: id }), [forceRender, id]);

  const cardData = vocCardModel.getItemValue('cardData') as IVocCard | undefined;

  const handleIdClick = () => {
    dispatch(
      tabsOpened({
        refId: IRefIds.vocsCourse,
        search: id.toString(),
        courseId: moduleModel.courseId,
      }),
    );
  };

  return (
    <ModuleContext.Provider value={vocCardModel}>
      <CardContainer key={id}>
        <CardHeader>
          <CardId onClick={handleIdClick}>{id}</CardId>
          <Title>
            <p dangerouslySetInnerHTML={{ __html: cardData?.title || '' }} />
          </Title>
        </CardHeader>
        <Image src={cardData?.svg} />
        {cardData && targetDialect && (
          <Target
            dangerouslySetInnerHTML={{
              __html: cardData[convertTargetDialectCodeToApi(targetDialect.toString())] || '',
            }}
          />
        )}
        {cardData && sourceDialect && (
          <Source>{cardData[convertSourceDialectCodeToApi(sourceDialect.toString())]}</Source>
        )}
        <LoadingItem />
      </CardContainer>
    </ModuleContext.Provider>
  );
};

const Title = styled.div`
  margin: 2px;
  font-size: 15px;
  & p {
    margin: 0;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 91px;
  float: left;
  margin-right: 5px;
`;

const Target = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-top: 5px;
`;

const Source = styled.p`
  margin: 5px 0;
  padding: 0;
`;

export default VocCard;
