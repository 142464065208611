import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import AudioWaveEditor, { ButtonStyled } from './AudioWaveEditor';
import AudioWaveModal from './AudioWaveModal';

const AudioWave: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <AudioWaveEditor isModalOpen={isModalOpen}>
        <ButtonStyled onClick={handleOpenModal} variant="contained" size="small">
          <FontAwesomeIcon icon={faEdit} />
        </ButtonStyled>
      </AudioWaveEditor>
      {isModalOpen && <AudioWaveModal onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default AudioWave;
