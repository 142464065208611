import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type IContentTargetAICheckerRef = {
  ref: IRefIds;
  name: string;
  batchSize: number;
};

export default class AIContentTargetCheckerModel extends BaseModel<IGridData, IContentTargetAICheckerRef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const checkers = await get<IContentTargetAICheckerRef[]>(`/ai/content/targets/checker-refs`);
    await this.setItem(checkers);
    this.render();
  }
}
