import React from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { LessonSelect } from '../../../components/selects/LessonSelect';
import { SelectLayout } from '../../../components/selects/SelectLayout';
import WizardChangeStateToDo from './wizards/WizardChangeStateToDo';
import { Loading } from '../../../components/common/Loading';
import { EditorRow } from '../../../components/common/styles';
import styled from 'styled-components';
import { IBoardTypes } from '../../../models/BoardsModel';
import WizardCreateSegments from './wizards/WizardCreateSegments';

type IProps = {
  title: string;
  courseId: number;
  onClose: () => void;
};

enum IWizard {
  STATE_PANEL_SENTENCES_TODO,
  STATE_PANEL_EXPLANATIONS_TODO,
  STATE_SEGMENTS_TODO,
  STATE_EXERCISES_TODO,
  STATE_READINGS_TODO,
  STATE_DIALOGS_TODO,
  CREATE_SEGMENT,
  STATE_TRAINER,
}

type IWizardSelect = { label: string; value: number };

const WIZARD_DATA: { [key in IWizard]: IWizardSelect } = {
  [IWizard.STATE_PANEL_SENTENCES_TODO]: {
    label: 'Change STATE Panel SENTENCES from NONE to TODO',
    value: IWizard.STATE_PANEL_SENTENCES_TODO,
  },
  [IWizard.STATE_PANEL_EXPLANATIONS_TODO]: {
    label: 'Change STATE Panel EXPLANATIONS from NONE to TODO',
    value: IWizard.STATE_PANEL_EXPLANATIONS_TODO,
  },
  [IWizard.STATE_SEGMENTS_TODO]: {
    label: 'Change STATE SEGMENTS from NONE to TODO',
    value: IWizard.STATE_SEGMENTS_TODO,
  },
  [IWizard.STATE_EXERCISES_TODO]: {
    label: 'Change STATE EXERCISES from NONE to TODO',
    value: IWizard.STATE_EXERCISES_TODO,
  },
  [IWizard.STATE_READINGS_TODO]: {
    label: 'Change STATE READINGS from NONE to TODO',
    value: IWizard.STATE_READINGS_TODO,
  },
  [IWizard.STATE_DIALOGS_TODO]: {
    label: 'Change STATE DIALOGS from NONE to TODO',
    value: IWizard.STATE_DIALOGS_TODO,
  },
  [IWizard.STATE_TRAINER]: {
    label: 'Change STATE TRAINERS from NONE to TODO',
    value: IWizard.STATE_TRAINER,
  },
  [IWizard.CREATE_SEGMENT]: {
    label: 'CREATE empty SEGMENTS',
    value: IWizard.CREATE_SEGMENT,
  },
};

const WIZARD_BOARD_TYPE: { [key in IWizard]?: IBoardTypes } = {
  [IWizard.STATE_PANEL_SENTENCES_TODO]: IBoardTypes.BOARD_TYPE_PANEL_SENTENCES,
  [IWizard.STATE_PANEL_EXPLANATIONS_TODO]: IBoardTypes.BOARD_TYPE_PANEL_EXPLANATIONS,
  [IWizard.STATE_SEGMENTS_TODO]: IBoardTypes.BOARD_TYPE_SEGMENTS,
  [IWizard.STATE_EXERCISES_TODO]: IBoardTypes.BOARD_TYPE_EXERCISES,
  [IWizard.STATE_READINGS_TODO]: IBoardTypes.BOARD_TYPE_READINGS,
  [IWizard.STATE_DIALOGS_TODO]: IBoardTypes.BOARD_TYPE_DIALOGS,
  [IWizard.STATE_TRAINER]: IBoardTypes.BOARD_TYPE_TRAINERS,
};

const LessonOutlineWizardModal: React.FC<IProps> = (props) => {
  const [startPos, setStartPos] = React.useState(0);
  const [endPos, setEndPos] = React.useState(0);
  const [doing, setDoing] = React.useState(false);
  const [wizard, setWizard] = React.useState<IWizard | null>(null);

  const handleChangeStarPos = (id: number, lessonPos: number) => {
    setStartPos(lessonPos);
  };

  const handleChangeEndPos = (id: number, lessonPos: number) => {
    setEndPos(lessonPos);
  };

  const handleChangeWizard = (data: IWizardSelect) => {
    setWizard(data.value);
  };

  const handleDoing = () => {
    setDoing(true);
  };

  const handleDone = () => {
    setDoing(false);
    props.onClose();
  };

  const renderWizard = () => {
    switch (wizard) {
      case IWizard.STATE_PANEL_SENTENCES_TODO:
      case IWizard.STATE_PANEL_EXPLANATIONS_TODO:
      case IWizard.STATE_EXERCISES_TODO:
      case IWizard.STATE_READINGS_TODO:
      case IWizard.STATE_SEGMENTS_TODO:
      case IWizard.STATE_DIALOGS_TODO:
      case IWizard.STATE_TRAINER:
        return (
          <WizardChangeStateToDo
            courseId={props.courseId}
            startPos={startPos}
            endPos={endPos}
            boardType={WIZARD_BOARD_TYPE[wizard] as IBoardTypes}
            onDoing={handleDoing}
            onDone={handleDone}
          />
        );
      case IWizard.CREATE_SEGMENT:
        return (
          <WizardCreateSegments
            courseId={props.courseId}
            startPos={startPos}
            endPos={endPos}
            onDoing={handleDoing}
            onDone={handleDone}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalWrapper title={'Wizard'} onClose={props.onClose} width={500} height={400}>
      <div>
        <EditorRowStyled>
          <LessonSelect label={'From Lesson'} handleLessonChanged={handleChangeStarPos} />
        </EditorRowStyled>
        <EditorRowStyled>
          <LessonSelect label={'To Lesson'} handleLessonChanged={handleChangeEndPos} />
        </EditorRowStyled>
        <EditorRowStyled>
          <SelectLayout
            data={Object.values(WIZARD_DATA)}
            onChange={handleChangeWizard}
            label={'Action'}
            optionLabelKey="label"
            currentValue={wizard}
          />
        </EditorRowStyled>
        {renderWizard()}
      </div>
      {doing && <Loading />}
    </ModalWrapper>
  );
};

export default LessonOutlineWizardModal;

export const EditorRowStyled = styled(EditorRow)`
  margin-top: 15px;
  margin-bottom: 15px;
`;
