import { IRefIds } from 'constants/refs';
import { BaseModel } from 'models/BaseModel';
import { get } from 'services/request';
import PublishRefModel from './PublishRefModel';

type IPublishResource = {
  ref: IRefIds;
  name: string;
  batch: number;
  enabled: boolean;
};

export default class PublishModel extends BaseModel {
  resources: PublishRefModel[] = [];
  allChecked = false;
  itemUpdated = []; //for displaying ItemLayout
  checkEnabled = true;
  publishEnabled = true; //todo, temp fix - should be false

  async getResources() {
    this.loadingItem = true;
    this.checkEnabled = false;
    this.render();
    const resources = await get<IPublishResource[]>(`groups/${this.courseId}/publish-resources`);
    this.resources = resources
      .filter((resource) => resource.enabled)
      .map(
        (resource) =>
          new PublishRefModel({
            render: this.render,
            courseId: this.courseId,
            ref: resource.ref,
            name: resource.name,
            batch: resource.batch,
            checkDone: this.nextCheck.bind(this),
            publishDone: this.nextPublish.bind(this),
          }),
      );
    this.loadingItem = false;
    this.render();
    await this.nextCheck();
  }

  async nextCheck() {
    const pendingChecks = this.resources.filter((resource) => resource.checking);
    if (pendingChecks.length === 0) {
      const fails = this.resources.filter((resource) => resource.checkFailed);
      this.allChecked = fails.length === 0;
      //this.publishEnabled = fails.length === 0; todo: temp fix - uncomment
    } else {
      await pendingChecks[0].getCheck();
    }
    this.render();
  }

  async publish() {
    this.publishEnabled = false;
    await this.nextPublish();
  }

  async nextPublish() {
    const pendingPublish = this.resources.filter((resource) => !resource.completed);
    if (pendingPublish.length === 0) {
    } else {
      await pendingPublish[0].publish();
    }
    this.render();
  }
}
