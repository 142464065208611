import { IConceptLinkerCompound } from 'components/conceptLink/TooltipCompoundsModel';
import { getTooltip } from 'components/conceptLink/tooltips';
import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import styled from 'styled-components';

type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const TooltipCompound: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.compounds,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerCompound;

  if (!data) {
    return null;
  }

  const { items } = data;

  return (
    <Container>
      {items
        .sort((a, b) => a.order - b.order)
        .map((item) => (
          <div key={item.ref + '-' + item.refId}>
            {getTooltip({ ref: item.ref, refId: item.refId, courseId, targetDialectId })}
          </div>
        ))}
    </Container>
  );
};

const Container = styled.div`
  & > div:not(:last-child) {
    border-bottom: dashed 2px gray;
  }
`;

export default TooltipCompound;
