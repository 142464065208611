import InflectTablesModel from 'models/InflectTablesModel';
import { get, put } from 'services/request';
import BaseInflectsModel, { IInflectBase } from './BaseInflectsModel';

type IInflectPosition = IInflectBase & {
  position: string;
};

export default class InflectPositionsModel extends BaseInflectsModel<IInflectPosition> {
  withInitials = false;

  async loadWordClass(wordClassId: number) {
    await super.loadItemEdit(wordClassId);

    this.inflectTablesModel = new InflectTablesModel({
      courseId: this.courseId,
      wordClass: wordClassId,
    });

    await Promise.all([
      this.inflectTablesModel.getInflectTables(),
      this.inflectTablesModel.getInflectTableHeaders(),
      this.inflectTablesModel.getInflectTableCells(),
    ]);

    const items = await get<IInflectPosition[]>(`courses/${this.courseId}/inflect-positions`);
    await this.setItem(this.fillInflects(items));
    console.log(this.fillInflects(items));

    this.loadingItem = false;
    this.render();
  }

  async save() {
    this.loadingItem = true;
    this.render();
    await put(`courses/${this.courseId}/inflect-positions`, {
      positions: JSON.stringify(this.itemUpdated),
    });
    await this.loadWordClass(this.itemId);
  }

  protected createEmptyItem(inflectId: number, index = 0): IInflectPosition {
    return {
      index,
      inflectId,
      position: '',
    };
  }
}
