import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { userNotLogged } from 'store/actions/user';
import store from 'store/store';
import { forEach } from 'lodash';

type IPostFileResult<T> = {
  data?: T;
  message?: string;
};

type IPostResult<T> = IPostFileResult<T> & {
  ok: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs');

let notify: React.Dispatch<React.SetStateAction<string | null>>;

export const initRequestErrorNotification = (setNotification: React.Dispatch<React.SetStateAction<string | null>>) => {
  notify = setNotification;
};

const isResponseOK = (response: AxiosResponse) => response.data.result === 'OK';

const handleError = (error: AxiosError, path: string) => {
  console.log('error.response: ', error.response);

  if (error.response?.status === 500) {
    notify(path + ': ' + error.response?.data.error.message);
  }
};

export async function get<T>(path: string, query: object = {}, cancelToken?: (c: Canceler) => void) {
  try {
    const response = await axios.get<T>(path, {
      params: query,
      timeout: 999999,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      cancelToken: new axios.CancelToken(function executor(c) {
        if (cancelToken) {
          cancelToken(c);
        }
      }),
    });
    const reposeDataLogged = response.data as { logged?: boolean };
    if (typeof reposeDataLogged.logged !== 'undefined') {
      store.dispatch(userNotLogged());
      return ([] as unknown) as T;
    }
    return response.data;
  } catch (error) {
    handleError(error, path);
    return {} as T;
  }
}

export async function post<T extends {}>(path: string, payload: any): Promise<IPostResult<T>> {
  try {
    const response = await axios.post<{ data: T }>(path, payload);

    return {
      ok: isResponseOK(response),
      data: response.data.data,
    };
  } catch (error) {
    handleError(error, path);
    return {
      ok: false,
    };
  }
}

export async function postFile<T extends {}>(path: string, payload: File): Promise<IPostFileResult<T>> {
  try {
    const formData = new FormData();
    formData.append('file', payload);
    const response = await axios.post<T>(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      data: response.data,
    };
  } catch (error) {
    handleError(error, path);
    return {};
  }
}

export async function postMultiFile<T extends {}>(
  path: string,
  payload: { fileName: string; file: File }[],
): Promise<IPostFileResult<T>> {
  try {
    const formData = new FormData();
    payload.forEach((data) => {
      formData.append(data.fileName, data.file);
    });
    const response = await axios.post<T>(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      data: response.data,
    };
  } catch (error) {
    handleError(error, path);
    return {};
  }
}

export async function put<T extends {}>(path: string, payload: any): Promise<IPostResult<T>> {
  try {
    const response = await axios.put<{ data: T }>(path, payload);

    return {
      data: response.data.data,
      ok: isResponseOK(response),
    };
  } catch (error) {
    handleError(error, path);
    return {
      ok: false,
    };
  }
}

export async function del<T extends {}>(path: string): Promise<IPostResult<T>> {
  try {
    const response = await axios.delete(path);

    return {
      data: response.data.data,
      ok: isResponseOK(response),
    };
  } catch (error) {
    handleError(error, path);
    return {
      ok: false,
    };
  }
}
