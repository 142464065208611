import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wlingua from 'assets/Wlingua.svg';
import { LeftMenuNav } from 'components/LeftMenuNav';
import React, { useState } from 'react';
import styled from 'styled-components';

const WIDTH_OPEN = 230;
const WIDTH_CLOSE = 18;

export const LeftMenu: React.FC = () => {
  const [folded, setFolded] = useState(false);
  return (
    <Left style={{ width: folded ? WIDTH_CLOSE : WIDTH_OPEN }}>
      <FontAwesomeIconStyled
        icon={folded ? faAngleDoubleRight : faAngleDoubleLeft}
        onClick={() => setFolded(!folded)}
      />
      {!folded && (
        <>
          <Logo src={Wlingua} alt="Logo" />
          <LeftMenuNav />
        </>
      )}
    </Left>
  );
};

const Left = styled.div`
  display: flex;
  flex-direction: column;
  background: #2a2f43;
  overflow: auto;
  position: relative;
`;

const Logo = styled.img`
  width: 160px;
  height: 44px;
  display: block;
  margin: 20px auto;
  opacity: 0.8;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: white;
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 0.8;
  cursor: pointer;
`;
