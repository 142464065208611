import { get } from 'services/request';
import { IPanelCard } from 'types/content';
import { BaseModel, IModelOptions } from './BaseModel';

export default class PanelCardModel extends BaseModel {
  constructor(options: IModelOptions & { panelId: number }) {
    super(options);
    this.loadItemEdit(options.panelId);
  }

  async loadItemEdit(id: number) {
    await super.loadItemEdit(id);
    const vocCards = await get<IPanelCard[]>(`panels/${id}/card`);
    this.setItem({ cardData: vocCards[0] });
    this.render();
  }
}
