import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CATCell, CATRow } from './CATTableRow';
import { TextFieldStyled } from '../../../components/common/SelectInput';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel from '../../../models/CATModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSortAlphaDown } from '@fortawesome/free-solid-svg-icons';
import { useDebounce } from 'use-debounce';

export type IProps = {};

const CATTableFilter: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [sortFrom, setSortFrom] = useState(false);
  const [sortTo, setSortTo] = useState(false);
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterPending, setFilterPending] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const [filterQA, setFilterQA] = useState(false);

  const handleFilterFromChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterFrom(event.target.value);
  };

  const handleFilterToChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterTo(event.target.value);
  };

  const handleFilterPendingChanged = () => {
    setFilterPending(!filterPending);
  };

  const handleFilterErrorChanged = () => {
    setFilterError(!filterError);
  };

  const handleFilterQAChanged = () => {
    setFilterQA(!filterQA);
  };

  const handleSortToChanged = () => {
    setSortTo(!sortTo);
    if (!sortTo && sortFrom) {
      setSortFrom(false);
    }
  };

  const handleSortFromChanged = () => {
    setSortFrom(!sortFrom);
    if (!sortFrom && sortTo) {
      setSortTo(false);
    }
  };

  const [debouncedFilterFrom] = useDebounce(filterFrom, 500);
  const [debouncedFilterTo] = useDebounce(filterTo, 500);
  useEffect(() => {
    module.setFilter(debouncedFilterFrom, debouncedFilterTo, filterPending, filterError, filterQA);
  }, [module, debouncedFilterFrom, debouncedFilterTo, filterPending, filterError, filterQA]);

  useEffect(() => {
    module.sort(sortFrom, sortTo);
  }, [module, sortTo, sortFrom]);

  return (
    <CATRow>
      <CATCellStyled>
        <FontAwesomeSort icon={faSortAlphaDown} $active={sortFrom} onClick={handleSortFromChanged} />
        <TextFieldStyled variant="outlined" value={filterFrom} onChange={handleFilterFromChanged} placeholder="From" />
      </CATCellStyled>
      <CATCellStyled>
        <FontAwesomeSort icon={faSortAlphaDown} $active={sortTo} onClick={handleSortToChanged} />
        <TextFieldStyled variant="outlined" value={filterTo} onChange={handleFilterToChanged} placeholder="To" />
      </CATCellStyled>
      <CATCell width={30} padding={0} />
      <CATCell width={15}>
        <FontAwesomeIconStyled icon={faFilter} $active={filterPending} onClick={handleFilterPendingChanged} />
      </CATCell>
      <CATCell width={15}>
        <FontAwesomeIconStyled icon={faFilter} $active={filterError} onClick={handleFilterErrorChanged} />
      </CATCell>
      {module.hasQA() && (
        <CATCell width={15}>
          <FontAwesomeIconStyled icon={faFilter} $active={filterQA} onClick={handleFilterQAChanged} />
        </CATCell>
      )}
    </CATRow>
  );
};

export default CATTableFilter;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)<{ $active: boolean }>`
  margin-top: 8px;
  color: ${(props) => (props.$active ? '#0032ff' : '#949494')};
  font-size: 14px;
  cursor: pointer;
`;

const FontAwesomeSort = styled(FontAwesomeIcon)<{ $active: boolean }>`
  margin-top: 8px;
  color: ${(props) => (props.$active ? '#0032ff' : '#949494')};
  font-size: 16px;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
`;

const CATCellStyled = styled(CATCell)`
  padding: 0;
  display: flex;
  flex-direction: row;
`;
