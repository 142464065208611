import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faRobot } from '@fortawesome/free-solid-svg-icons';
import { CLDetails } from '../../../components/cl/CLDetails';
import { ITrainerSegment } from '../../../types/trainer';

export type IProps = {
  segment: ITrainerSegment;
  index: number;
  dnd: boolean;
  onEditSegment: Function;
  editing: boolean;
};

const TrainerSegmentListCard: React.FC<IProps> = (props) => {
  const { segment } = props;

  const onEditSegment = () => {
    props.onEditSegment(segment.id);
  };

  const getAiReviewClass = () => {
    switch (segment.aiReview) {
      case 0:
        return '';
      case 1:
        return 'red';
      case 2:
        return 'green';
    }
  };

  return (
    <Draggable isDragDisabled={!props.dnd} draggableId={props.segment.id.toString()} index={props.index}>
      {(provided) => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onEditSegment}
          editing={props.editing}
        >
          <IconWrapper>
            <ExerciseType>{segment.exerciseType}</ExerciseType>
            {segment.error && <FontAwesomeIcon icon={faExclamation} color={'red'} />}
            {segment.aiReview !== 0 && <FontAwesomeIcon icon={faRobot} className={getAiReviewClass()} />}
            <CLDetails details={segment.clDetails} />
          </IconWrapper>
          <Title dangerouslySetInnerHTML={{ __html: segment.title.replace(/\n/g, '<br>') }} />
        </Wrapper>
      )}
    </Draggable>
  );
};

export default TrainerSegmentListCard;

const Title = styled.div`
  min-height: 20px;
  font-size: 14px;
`;

const Wrapper = styled.div<{ editing: boolean }>`
  border: solid 1px #bfbfbf;
  background-color: ${(props) => (props.editing ? '#e9dec7' : '#f5f5f5')};
  position: relative;
  margin: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  text-align: right;
`;

const ExerciseType = styled.span`
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
`;
