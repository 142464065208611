import { Button } from '@material-ui/core';
import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import DialectSelect, { IDialectOption } from '../../../components/selects/DialectSelect';
import CoursesModel from '../../../models/CoursesModel';

type IProps = {
  onClose: () => void;
  onAdd: (dialectId: number, copyDialectId: number) => void;
  title: string;
  filterIds: number[];
  source?: boolean;
  target?: boolean;
};

const CourseDialectModal: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext) as CoursesModel;
  const [selectNewDialectId, setSelectNewDialectId] = useState(0);
  const [selectCopyDialectId, setSelectCopyDialectId] = useState(0);

  const handleChangeSelectNewDialectId = (dialect: IDialectOption) => {
    setSelectNewDialectId(dialect.dialectId);
  };

  const handleChangeSelectCopyDialectId = (dialect: IDialectOption) => {
    setSelectCopyDialectId(dialect.dialectId);
  };

  const handleAddClick = async () => {
    props.onAdd(selectNewDialectId, selectCopyDialectId);
  };

  return (
    <ModalWrapper title={props.title} width={400} height={250} onClose={props.onClose}>
      <Scroll>
        <b>Add Dialect:</b>
        <DialectSelectStyled
          onlySource={props.source}
          onlyTarget={props.target}
          handleDialectChange={handleChangeSelectNewDialectId}
          filterIds={props.filterIds}
        />
        <br />
        <b>Copy from:</b>
        <DialectSelectStyled
          onlySource={props.source}
          onlyTarget={props.target}
          courseId={moduleModel.itemId}
          handleDialectChange={handleChangeSelectCopyDialectId}
        />
      </Scroll>
      <Footer>
        <Button
          variant="contained"
          size="small"
          disabled={selectNewDialectId === 0}
          color="primary"
          onClick={handleAddClick}
        >
          add
        </Button>
      </Footer>
    </ModalWrapper>
  );
};

const DialectSelectStyled = styled(DialectSelect)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
`;

export default CourseDialectModal;
