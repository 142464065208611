import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { IItemId } from 'types/content';
import { IKline, IKlineEdit } from 'types/klines';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import * as lodash from 'lodash';
import TranslationManagerModel from './TranslationManagerModel';

export default class KLineModel extends BaseModel<IKline, IKlineEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.klines;
    this.gridName = 'KLines';
    this.getGridColumns();
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  public getCourseId() {
    return this.courseId;
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IKline[]>(`groups/${this.courseId}/k-lines`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IKline>(`groups/${this.courseId}/k-lines/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const [item, sources] = await Promise.all([get<IKlineEdit>(`k-lines/new`), this.translations.new(this.courseId)]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  async updateItem() {
    const { id, sources } = this.itemUpdated as IKlineEdit;
    await put(`k-lines/${id}`, lodash.omit(this.itemUpdated, 'sources'));
    await this.translations.update(id, sources);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const { sources } = this.itemUpdated as IKlineEdit;
    const result = await post<IItemId>(`groups/${this.courseId}/k-lines/`, lodash.omit(this.itemUpdated, 'sources'));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await this.translations.update(newItemId, sources);
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    await super.delete();
    const { id } = this.itemUpdated;

    const result = await del(`k-lines/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateCache();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.klines, this.courseId)));
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, sources] = await Promise.all([get<IKlineEdit>(`k-lines/${itemId}`), this.translations.get(itemId)]);
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }
}
