import React, { useState } from 'react';
import TrainerSegmentList from '../components/TrainerSegmentList';
import TrainerSegmentEdit from '../components/TrainerSegmentEdit';
import styled from 'styled-components';

const TrainerSegment: React.FC = () => {
  const [editSegment, setEditSegment] = useState(0);

  const onEditSegment = (exerciseId: number) => {
    setEditSegment(exerciseId);
  };

  return (
    <Container>
      <TrainerSegmentList onEditSegment={onEditSegment} />
      <TrainerSegmentEdit trainerSegmentId={editSegment} />
    </Container>
  );
};

export default TrainerSegment;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
