import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import KlinesModel, { IKLineSelect } from 'models/KlinesModel';
import React, { useContext, useMemo, useState } from 'react';
import { SelectLayout } from './SelectLayout';
import { ALL_OPTION } from './selects';

type IProps = {
  keyName?: string;
  allOption?: boolean;
  onChange?: (kLineId: number) => void;
  className?: string;
};

const KLineSelect: React.FC<IProps> = (props) => {
  const [currentKlineId, setCurrentKlineId] = useState(null as number | null);

  const { courseId } = useContext(ModuleContext);
  const cacheKey = getCacheKey(IRefIds.klines, courseId);
  const dataModel = useMemo(() => new KlinesModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    const data = [];

    if (props.allOption) {
      data.push(ALL_OPTION);
    }

    return [...data, ...cacheSelectData];
  }, [cacheSelectData, props.allOption]);

  const handleChange = (value: IKLineSelect) => {
    const selectedKLineId = value.id;
    setCurrentKlineId(selectedKLineId);
    props.onChange && props.onChange(selectedKLineId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="KLine"
      optionLabelKey="title"
      currentValue={currentKlineId}
    />
  );
};

export default KLineSelect;
