import { TooltipContainer } from 'components/conceptLink/styles';
import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import styled from 'styled-components';
import { IConceptLinkerVoc } from './TooltipVocsModel';

export type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const TooltipVoc: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;


  const data = useTooltipData({
    ref: IRefIds.vocs,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerVoc;

  if (!data) {
    return null;
  }

  const { svg, title, target, source } = data;

  return (
    <TooltipContainer>
      <Image src={svg} />
      <Title>{title}</Title>
      <Target dangerouslySetInnerHTML={{ __html: target }} />
      <Source>{source}</Source>
    </TooltipContainer>
  );
};

const Image = styled.img`
  width: 120px;
  height: 91px;
  float: left;
  margin-right: 5px;
`;

const Title = styled.p`
  margin: 0;
`;

const Target = styled(Title)`
  font-weight: bold;
`;

const Source = styled(Title)``;

export default TooltipVoc;
