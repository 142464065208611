import { Button, CircularProgress } from '@material-ui/core';
import FileUpload from 'CMFW/input/FileUpload';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { getXLSXImport, IXLSXImport } from '../utils/xlsx';
import QAResultsModel from '../../../models/QAResultsModel';

type IProps = {
  onClose: () => void;
};

const SourceAdaptationQAUploadModal: React.FC<IProps> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [notImported, setNotImported] = useState('');
  const [duplicateIds, setDuplicateIds] = useState('');
  const [uploads, setUploads] = useState<IXLSXImport[]>([]);
  const [uploadN, setUploadN] = useState<number>(-1);

  const moduleModel = useContext(ModuleContext) as QAResultsModel;

  useEffect(() => {
    async function update(source: IXLSXImport) {
      await moduleModel.updateSourceXLSX(source);
      setUploadN(uploadN + 1);
      setMessage(`${uploadN + 1} / ${uploads.length}`);
    }

    if (uploadN >= 0 && uploads[uploadN]) {
      setIsUploading(true);
      update(uploads[uploadN]);
    } else {
      setIsUploading(false);
    }
  }, [uploads, uploadN, moduleModel]);

  const handleFileChosen = (file: File) => {
    setFile(file);
  };

  const handleFileLoaded = async (ev: ProgressEvent<FileReader>) => {
    if (ev.target) {
      console.log(ev.target.result);
      const uploads = await getXLSXImport(ev.target.result as ArrayBuffer);
      setUploads(uploads);
      setUploadN(0);
      let idsToImport: number[] = [];
      uploads.forEach((u) => (idsToImport = [...idsToImport, ...u.ids]));
      const duplicates = idsToImport.filter((item, index) => idsToImport.indexOf(item) !== index);
      setDuplicateIds(duplicates.join(','));
      const noImport: string[] = [];
      uploads.forEach((u) => {
        if (u.ids.length > 1) {
          noImport.push(u.ids.join(','));
        }
      });
      setNotImported(noImport.join('<br>'));
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = handleFileLoaded;
    fileReader.readAsArrayBuffer(file);
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Upload File'} width={518} height={455}>
      <EditorRowStyled>
        <FileUploadStyled handleFile={handleFileChosen} />
        <Button variant="contained" size="small" disabled={!file || isUploading} onClick={handleUploadClick}>
          Upload
        </Button>
        {isUploading && <CircularProgress size={20} />}
      </EditorRowStyled>
      {duplicateIds !== '' && (
        <p
          style={{ color: 'orange', width: 400, userSelect: 'text' }}
          dangerouslySetInnerHTML={{ __html: 'duplicates: ' + duplicateIds }}
        />
      )}
      {notImported !== '' && <p style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: notImported }} />}
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </ModalWrapper>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  margin-top: 10px;
`;

const FileUploadStyled = styled(FileUpload)`
  margin-right: 5px;
`;

export default SourceAdaptationQAUploadModal;
