import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { del, get, post, put } from 'services/request';
import { cacheDelete } from 'store/actions/cache';
import store from 'store/store';
import { BaseModel, IModelCourseOptions } from './BaseModel';

export type ICompoundTypeId = {
  id: number;
};

export type ICompoundType = ICompoundTypeId & {
  name: string;
  nCompounds: number;
};

export type ICompoundTypeEdit = ICompoundTypeId & {
  name: string;
  deletable: boolean;
};

export default class CompoundTypeModel extends BaseModel<ICompoundType, ICompoundTypeEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.compoundTypes;
    this.gridName = 'CompoundType';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ICompoundType[]>(`courses/${this.courseId}/compound-types`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ICompoundType>(`courses/${this.courseId}/compound-types/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<ICompoundTypeEdit>(`compound-types/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateCache();
  }

  async updateItem() {
    const { id } = this.itemUpdated;
    await put(`compound-types/${id}`, this.itemUpdated);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<ICompoundTypeId>(`courses/${this.courseId}/compound-types`, this.itemUpdated);

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`compound-types/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateCache();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  private invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.compoundTypes, this.courseId)));
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ICompoundTypeEdit>(`compound-types/${itemId}`);
    await this.setItem(item);
    this.render();
  }
}
