import React, { useContext } from 'react';
import styled from 'styled-components';
import { IRefIds } from '../../../constants/refs';
import {
  ILessonCycleActivityFeedback,
  ILessonCycleActivityFeedbackBase,
  ILessonCycleActivityFeedbackPanel,
} from '../../../models/FeedbackModel';
import FeedbackActivityExercise from './activities/FeedbackActivityExercise';
import FeedbackActivityPanel from './activities/FeedbackActivityPanel';
import FeedbackActivityDialog from './activities/FeedbackActivityDialog';
import FeedbackActivityReading from './activities/FeedbackActivityReading';
import FeedbackContext from '../context/FeedbackContext';

export type IProps = {
  activity: ILessonCycleActivityFeedback;
};

const FeedbackActivity: React.FC<IProps> = (props) => {
  const feedbackContext = useContext(FeedbackContext);
  const onlyUpdated = feedbackContext.onlyTracking;
  if (onlyUpdated) {
    if (props.activity.ref === IRefIds.panels) {
      if (
        (props.activity as ILessonCycleActivityFeedbackPanel).segments.filter((segment) => segment.tracking).length ===
        0
      ) {
        return null;
      }
    } else {
      if (!props.activity.tracking) {
        return null;
      }
    }
  }
  return (
    <WrapperActivity>
      {props.activity.ref === IRefIds.exercise && (
        <FeedbackActivityExercise activity={props.activity as ILessonCycleActivityFeedbackBase} />
      )}
      {props.activity.ref === IRefIds.panels && (
        <FeedbackActivityPanel activity={props.activity as ILessonCycleActivityFeedbackPanel} />
      )}
      {props.activity.ref === IRefIds.dialog && (
        <FeedbackActivityDialog activity={props.activity as ILessonCycleActivityFeedbackBase} />
      )}
      {props.activity.ref === IRefIds.reading && (
        <FeedbackActivityReading activity={props.activity as ILessonCycleActivityFeedbackBase} />
      )}
    </WrapperActivity>
  );
};

export default FeedbackActivity;

const WrapperActivity = styled.div`
  margin: 4px;
  font-size: 14px;
  min-width: 90px;
`;
