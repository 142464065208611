import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import SaveButton from '../../../CMFW/button/SaveButton';
import DialogSpeakerModel from '../../../models/DialogSpeakerModel';

export type IProps = {
  dialogId: number;
};

const DialogSpeaker: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const dialogSpeakerModel = useMemo(
    () => new DialogSpeakerModel({ render: forceRender, courseId: 0, dialogId: props.dialogId }),
    [forceRender, props.dialogId],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={dialogSpeakerModel}>
        <Split widthLeft={170}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'Name'} />
                <TextInput keyName={'gender'} label={'Gender'} />
              </EditorRow>
              <SaveButton />
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default DialogSpeaker;
