import React, { useContext } from 'react';
import styled from 'styled-components';
import FeedbackActivity from './FeedbackActivity';
import { ILessonCycleFeedback } from '../../../models/FeedbackModel';
import FeedbackList from './components/FeedbackList';
import FeedbackContext from '../context/FeedbackContext';

export type IProps = {
  cycle: ILessonCycleFeedback;
};

const FeedbackCycle: React.FC<IProps> = (props) => {
  const feedbackContext = useContext(FeedbackContext);
  return (
    <Wrapper>
      <WrapperActivities>
        <WrapperActivity1>
          {feedbackContext.showCycleRate && (
            <WrapperActivity2>
              <span>Cycle</span>
              <FeedbackList feedback={props.cycle.feedback} showDialectCode />
            </WrapperActivity2>
          )}
        </WrapperActivity1>
        {props.cycle.activities.map((activity) => (
          <FeedbackActivity activity={activity} key={`activity-${activity.id}`} />
        ))}
      </WrapperActivities>
    </Wrapper>
  );
};

export default FeedbackCycle;

const Wrapper = styled.div`
  background-color: #f6f6f6;
  margin: 10px 4px;
`;

const WrapperActivities = styled.div`
  min-height: 20px;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f6f6f6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 3px;
  }
`;
const WrapperActivity1 = styled.div`
  margin: 4px;
  font-size: 14px;
  min-width: 90px;
`;
const WrapperActivity2 = styled.div`
  flex-direction: column;
  padding: 4px 2px;
`;
