import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import Flag from 'CMFW/input/Flag';
import { ContainerLeft, EditorRow } from 'components/common/styles';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { LessonSelect } from '../../../components/selects/LessonSelect';
import Translations from '../../../components/translation/Translations';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TrainerModel from '../../../models/TrainerModel';

const TrainerEdit: React.FC = () => {
  const trainerModel = useContext(ModuleContext) as TrainerModel;

  return (
    <ContainerLeftStyled>
      <EditorRow>
        <Flag />
        <LessonSelect isLinked />
      </EditorRow>
      <Scroll>
        <Translations keyName={'sources'} refId={trainerModel.ref} />
      </Scroll>
      <EditorRow>
        <SaveButton />
      </EditorRow>
      <DeleteButton />
    </ContainerLeftStyled>
  );
};

export default TrainerEdit;

export const ContainerLeftStyled = styled(ContainerLeft)`
  justify-content: space-between;
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;