import { IVocTreeEdit } from 'models/VocabularyModel';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { tabsOpened } from '../../../../store/actions/tabs';
import { useDispatch } from 'react-redux';
import { IRefIds } from '../../../../constants/refs';
import ModuleContext from '../../../../CMFW/context/ModuleContext';

export type IProps = {
  item: IVocTreeEdit;
};

const VocTreeNodeItem: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const module = useContext(ModuleContext);

  const handleIdClick = (item: IVocTreeEdit) => {
    if (!item.current) {
      dispatch(
        tabsOpened({
          refId: IRefIds.vocs,
          search: item.id.toString(),
          courseId: module.courseId,
        }),
      );
    }
  };

  const renderCard = (item: IVocTreeEdit, synonym = false) => (
    <Wrapper $current={item.current} $synonym={synonym} key={item.id}>
      <ID $current={item.current} onClick={() => handleIdClick(item)}>
        {item.id}
      </ID>
      <Title>{item.title}</Title>
    </Wrapper>
  );

  return (
    <>
      {renderCard(props.item)}
      {props.item.synonyms.map((synonym) => renderCard(synonym, true))}
    </>
  );
};

export default VocTreeNodeItem;

const Wrapper = styled.div<{ $current: boolean; $synonym: boolean }>`
  font-size: 12px;
  width: 150px;
  box-sizing: border-box;
  border: solid 1px #c6c6ca;
  position: relative;
  padding: 5px;
  white-space: normal;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => (props.$current ? '#e3f9e4' : 'transparent')};
  border-top-width: ${(props) => (props.$synonym ? 0 : 1)}px;
`;

const Title = styled.p`
  margin: 0;
`;

const ID = styled.p<{ $current: boolean }>`
  margin: 0;
  text-decoration: ${(props) => (props.$current ? 'none' : 'underline')};
  cursor: ${(props) => (props.$current ? 'auto' : 'pointer')};
`;
