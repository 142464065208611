import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import GlossaryModel from 'models/GlossaryModel';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Flag from '../../CMFW/input/Flag';
import { TabDialects } from '../../CMFW/layout/TabDialects';
import Translations from '../../components/translation/Translations';
import { Separator } from '../../CMFW/layout/Separator';

export type IProps = {
  courseId: number;
  search?: string;
};

const Glossary: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const glossaryModel = useMemo(() => {
    const model = new GlossaryModel({
      render: forceRender,
      courseId: props.courseId,
      search: props.search,
    });

    model.getGrid();
    model.getGridColumns();
    return model;
  }, [forceRender, props.courseId, props.search]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={glossaryModel}>
        <Split widthLeft={350}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Wrapper>
                <EditorRow>
                  <Flag />
                  <TextInput keyName={'title'} label={'Title'} />
                  <EditorGap />
                  <SaveButton />
                </EditorRow>
                <TabDialectsStyled keyName={'sources'}>
                  <TextInputStyled keyName={'forms'} multiline label={'Forms (one per line, no comma allowed)'} />
                </TabDialectsStyled>
                <Separator />
                <Translations keyName={'tooltips'} refId={glossaryModel.ref} />
              </Wrapper>
              <DeleteButton />
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Glossary;

const TextInputStyled = styled(TextInput)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const TabDialectsStyled = styled(TabDialects)`
  height: auto;
`;

export const Wrapper = styled.div`
  overflow: auto;
`;
