import { translationProgressRenderer } from './translationProgressRenderer';

export function progressRenderer(param: { value: any }) {
  const { value } = param;
  if (value === -1) {
    return '';
  } else {
    return translationProgressRenderer({ value });
  }
}
