import { faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import PanelCard from 'components/common/PanelCard';
import VocCard from 'components/common/VocCard';
import { IRefIds } from 'constants/refs';
import { ICompoundItem } from 'models/CompoundModel';
import { IPanel } from 'models/PanelsModel';
import { IVoc } from 'models/VocabularyModel';
import PanelSearchModal from 'modules/panels/modal/PanelSearchModal';
import VocSearchModal from 'modules/panels/modal/VocSearchModal';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const Cards: React.FC = () => {
  const module = useContext(ModuleContext);

  const [isPanelModalOpen, setIsPanelModalOpen] = useState(false);
  const [isVocModalOpen, setIsVocModalOpen] = useState(false);

  const modelItems = module.itemUpdated.items as ICompoundItem[];

  const [items, setItems] = useState(modelItems);

  useEffect(() => {
    setItems(modelItems);
  }, [modelItems]);

  const getItemIndexByOrder = (order: number) => items.findIndex((currentItem) => currentItem.order === order);
  const getItemIndex = (item: ICompoundItem) =>
    items.findIndex((currentItem) => currentItem.ref === item.ref && currentItem.refId === item.refId);

  const handleTrashClick = (item: ICompoundItem) => {
    const itemIndex = getItemIndex(item);

    const updatedItems = items.map((currentItem) =>
      currentItem.order > item.order ? { ...currentItem, order: currentItem.order - 1 } : currentItem,
    );
    updatedItems.splice(itemIndex, 1);

    module.updateItemValue(`items`, updatedItems);
    setItems(updatedItems);
  };

  const handleUpClick = (item: ICompoundItem) => {
    if (item.order > 1) {
      const newOrder = item.order - 1;

      const itemIndex = getItemIndex(item);
      const previouslyWithThatOrderItemIndex = getItemIndexByOrder(newOrder);

      const updatedItems = [...items];
      updatedItems[previouslyWithThatOrderItemIndex] = {
        ...updatedItems[previouslyWithThatOrderItemIndex],
        order: item.order,
      };
      updatedItems[itemIndex] = { ...item, order: newOrder };

      module.updateItemValue(`items`, updatedItems);
      setItems(updatedItems);
    }
  };

  const handleDownClick = (item: ICompoundItem) => {
    if (item.order < items.length) {
      const newOrder = item.order + 1;

      const itemIndex = getItemIndex(item);
      const previouslyWithThatOrderItemIndex = getItemIndexByOrder(newOrder);

      const updatedItems = [...items];
      updatedItems[previouslyWithThatOrderItemIndex] = {
        ...updatedItems[previouslyWithThatOrderItemIndex],
        order: item.order,
      };
      updatedItems[itemIndex] = { ...item, order: newOrder };

      module.updateItemValue(`items`, updatedItems);
      setItems(updatedItems);
    }
  };

  const handleAddVocClick = () => {
    setIsVocModalOpen(true);
  };

  const handleAddPanelClick = () => {
    setIsPanelModalOpen(true);
  };

  const addCard = async (id: number, ref: IRefIds) => {
    const updatedItems = [
      ...items,
      {
        ref: ref,
        refId: id,
        order: items.length + 1,
      },
    ];

    setIsVocModalOpen(false);
    setIsPanelModalOpen(false);
    module.updateItemValue(`items`, updatedItems);
    setItems(updatedItems);
  };

  const handleVocClicked = (data: IVoc) => {
    addCard(data.id, IRefIds.vocs);
  };

  const handlePanelClicked = (data: IPanel) => {
    addCard(data.id, IRefIds.panels);
  };

  const renderCard = (item: ICompoundItem) => {
    switch (item.ref) {
      case IRefIds.panels:
        return <PanelCard key={item.ref + '.' + item.refId} id={item.refId} />;
      case IRefIds.vocs:
        return <VocCard key={item.ref + '.' + item.refId} id={item.refId} />;
    }
  };

  const handlePanelModalClose = () => {
    setIsPanelModalOpen(false);
  };

  const handleVocModalClose = () => {
    setIsVocModalOpen(false);
  };

  const renderModals = () => {
    if (isVocModalOpen) {
      return <VocSearchModal onChooseVoc={handleVocClicked} onClose={handleVocModalClose} />;
    }
    if (isPanelModalOpen) {
      return <PanelSearchModal onChoosePanel={handlePanelClicked} onClose={handlePanelModalClose} />;
    }
  };

  return (
    <Container>
      {items
        .sort((a, b) => a.order - b.order)
        .map((item) => (
          <Card key={item.ref + '.' + item.refId}>
            {renderCard(item)}
            <Controls>
              <GarbageButton icon={faTrash} onClick={() => handleTrashClick(item)} />
              <ArrowButton icon={faChevronUp} onClick={() => handleUpClick(item)} $isEnabled={item.order > 1} />
              <span>{item.order}</span>
              <ArrowButton
                icon={faChevronDown}
                onClick={() => handleDownClick(item)}
                $isEnabled={item.order < items.length}
              />
            </Controls>
          </Card>
        ))}
      <Buttons>
        <ButtonStyled
          disabled={module.itemId === module.NEW_CREATE_ID}
          variant="contained"
          size="small"
          onClick={handleAddVocClick}
        >
          add vocabulary
        </ButtonStyled>
        <Button
          disabled={module.itemId === module.NEW_CREATE_ID}
          variant="contained"
          size="small"
          onClick={handleAddPanelClick}
        >
          add panel
        </Button>
      </Buttons>
      {renderModals()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GarbageButton = styled(FontAwesomeIcon)`
  color: red;
  display: block;
  margin: 5px 0 8px 0;
  cursor: pointer;
`;

const ArrowButton = styled(FontAwesomeIcon)<{ $isEnabled: boolean }>`
  color: ${(props) => (props.$isEnabled ? 'black' : '#bababa')};
  display: block;
  margin: 5px 0 8px 0;
  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
`;

const ButtonStyled = styled(Button)`
  && {
    margin-right: 5px;
  }
`;

export default Cards;
