import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import React, { useMemo } from 'react';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { useForceRender } from '../../../hooks/useForceRender';
import AudioArtistModel from '../../../models/AudioArtistModel';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { Split } from '../../../components/Split';
import Grid from '../../../CMFW/grid/Grid';
import ReloadButton from '../../../CMFW/button/ReloadButton';
import NewButton from '../../../CMFW/button/NewButton';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import TextInput from '../../../CMFW/input/TextInput';
import SaveButton from '../../../CMFW/button/SaveButton';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import { LoadingItem } from '../../../CMFW/layout/Loading';

type IProps = {
  onClose: () => void;
  courseId: number;
  targetDialectId: number;
};

const AudioManageArtists: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const audioArtistModel = useMemo(
    () =>
      new AudioArtistModel({ render: forceRender, courseId: props.courseId, targetDialectId: props.targetDialectId }),
    [forceRender, props.courseId, props.targetDialectId],
  );

  return (
    <ModalWrapper onClose={props.onClose} title={'Manage Audio Artists'} width={518} height={455}>
      <ModuleContainer>
        <ModuleContext.Provider value={audioArtistModel}>
          <Split widthLeft={270}>
            <ContainerLeft>
              <Grid />
              <ContainerLeftFooter>
                <ReloadButton />
                <NewButton />
              </ContainerLeftFooter>
            </ContainerLeft>
            <ContainerRight>
              <ItemLayout>
                <EditorRow>
                  <TextInput keyName={'name'} label={'Name'} />
                </EditorRow>
                <EditorRow>
                  <TextInput keyName={'gender'} label={'Gender'} />
                </EditorRow>
                <EditorRow>
                  <TextInput keyName={'userKey'} label={'Key'} disabled />
                </EditorRow>
                <SaveButton />
                <DeleteButton />
              </ItemLayout>
              <LoadingItem />
            </ContainerRight>
          </Split>
        </ModuleContext.Provider>
      </ModuleContainer>
    </ModalWrapper>
  );
};

export default AudioManageArtists;
