import { ITranslationDashboardCell } from '../../../models/TranslationDashboardModel';
import { ITranslationStatus, TranslationColors } from '../../../types/Translation';

function getBar(number: number, total: number, color: string): string {
  if (number === 0) {
    return '';
  }
  const width = (number / total) * 100;
  const minWidth = number < 10 ? 10 : 20;
  return `<div style="width: ${width}%; min-width: ${minWidth}px; text-align: center; background-color: ${color}; font-size: 12px; padding: 0 5px;" >${number}</div>`;
}

export function translationProgressRenderer(param: { value: ITranslationDashboardCell }) {
  const { value } = param;
  const total = value.pending + value.doing + value.done;
  return `<div style="display: flex; cursor: ${
    value.pending > 0 ? 'pointer' : 'default'
  }; flex-direction: row; width: 110px; margin: 7px auto;">${getBar(
    value.pending,
    total,
    TranslationColors[ITranslationStatus.TRANSLATION_PENDING],
  )}${getBar(value.doing, total, 'orange')}${getBar(
    value.done,
    total,
    TranslationColors[ITranslationStatus.TRANSLATION_DONE],
  )}</div>`;
}
