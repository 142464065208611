import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { DropResult } from 'react-beautiful-dnd';
import * as lodash from 'lodash';
import TranslationManagerModel from './TranslationManagerModel';
import { IKlineEdit } from '../types/klines';
import { ITrainer, ITrainerEdit, ITrainerId, ITrainerSegment, ITrainerSegmentId } from '../types/trainer';

export default class TrainerModel extends BaseModel<ITrainer, ITrainerEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.trainer;
    this.gridName = 'Trainers';
    this.getGridColumns();
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<ITrainer[]>(`courses/${this.courseId}/trainers`, data);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ITrainer>(`courses/${this.courseId}/trainers/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();

    const [item, sources] = await Promise.all([
      get<ITrainerEdit>(`trainers/new`),
      this.translations.new(this.courseId),
    ]);
    item.sources = sources;
    item.segments = [];
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as ITrainerEdit;
    const itemId = itemToSave.id;

    const { id, sources } = this.itemUpdated as IKlineEdit;
    await put(`trainers/${id}`, lodash.omit(this.itemUpdated, 'sources'));
    await this.translations.update(id, sources);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const { sources } = this.itemUpdated as IKlineEdit;
    const result = await post<ITrainerId>(
      `courses/${this.courseId}/trainers`,
      lodash.omit(this.itemUpdated, 'sources'),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await this.translations.update(newItemId, sources);
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`trainers/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async getSegments(itemId: number) {
    const segments = await get<ITrainerSegment[]>(`trainers/${itemId}/segments`);
    return segments.sort((a, b) => a.position - b.position);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, segments, sources] = await Promise.all([
      get<ITrainerEdit>(`trainers/${itemId}`),
      this.getSegments(itemId),
      this.translations.get(itemId),
    ]);

    item.sources = sources;
    item.segments = segments.sort((a, b) => a.position - b.position);
    await this.setItem(item);
    this.render();
  }

  async forceReloadTrainerSegments() {
    const segments = await this.getSegments(this.itemId);
    await this.setItem({ ...this.itemUpdated, segments });
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  public async newSegment() {
    this.loadingItem = true;
    this.render();
    const readingItem = this.itemUpdated as ITrainerEdit;
    await post<ITrainerSegmentId>(`trainers/${readingItem.id}/segments/new`, {});
    await this.loadItemEdit(readingItem.id);
    this.loadingItem = false;
    this.render();
  }

  public async onDragEndSegment(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const segmentId = parseInt(draggableId);
    await this.moveSegment(segmentId, source.index, destination.index);
  }

  private async moveSegment(exerciseId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as ITrainerEdit;
    this.arrayMove(item.segments, prevPos, newPos);
    this.render();
    await put(`trainers/segments/${exerciseId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
  }

  private arrayMove(arr: ITrainerSegment[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  async checkTrainers() {
    this.loadingGrid = true;
    this.render();
    const errors = await get<{ id: number; error: boolean }[]>(`/courses/${this.courseId}/trainers/check`);
    const ids = errors.filter(e => e.error).map((e) => e.id);
    if (ids.length === 0) {
      this.gridData = [];
      this.loadingGrid = false;
      this.render();
    } else {
      await this.search({ text: ids.join(',') });
    }
  }
}
