export function errorRenderer(param: { value: boolean | string }) {
  let { value } = param;

  if (typeof value === 'string') {
    value = value === 'true';
  }

  if (value) {
    return "<span class='fa fa-exclamation red'></span>";
  }

  return '';
}
