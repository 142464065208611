import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IKlineSelect } from 'types/klines';

export type IKLineSelect = {
  id: number;
  title: string;
};

export default class KlinesModel {
  ref = IRefIds.klines;
  courseId = 0;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async get() {
    const KLines = await get<IKlineSelect[]>(`groups/${this.courseId}/pi-k-lines`);
    return KLines.sort(function (a, b) {
      const keyA = a.title,
        keyB = b.title;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }
}
