import { IRefIds } from 'constants/refs';
import { get } from 'services/request';

export type IPublishCheckDetail = {
  ref: number;
  refName: string;
  refId: number;
  error: string;
};

export type IPublishCheck = {
  id: number;
  ready: boolean;
  details: IPublishCheckDetail[];
};

type IOptions = {
  render: () => void;
  loadDone: () => void;
  conjugationDone: () => void;
  courseId: number;
  ref: IRefIds;
  name: string;
  batch: number;
};

export default class ConjugationBatchRefModel {
  checks: IPublishCheck[] = [];
  pending = true;
  render: () => void;
  loadDone: () => void;
  conjugationDone: () => void;
  conjugating = false;
  completed = false;
  courseId: number;
  ref: IRefIds;
  name: string;
  batch: number;
  items: number[] = [];
  done: number[] = [];

  constructor(options: IOptions) {
    this.render = options.render;
    this.loadDone = options.loadDone;
    this.conjugationDone = options.conjugationDone;
    this.courseId = options.courseId;
    this.ref = options.ref;
    this.name = options.name;
    this.batch = options.batch;
  }

  async load() {
    this.pending = true;
    this.checks = [];
    this.render();
    this.checks = await get<IPublishCheck[]>(`groups/${this.courseId}/refs/${this.ref}/conjugationBatchApply-check`);
    this.pending = false;
    this.items = this.checks.map((check) => check.id);
    this.completed = this.items.length === 0;
    this.render();
    this.loadDone();
  }

  async conjugate() {
    if (!this.completed) {
      this.conjugating = true;
      this.render();
      let batchIds: number[] = [];
      if (this.batch !== 0) {
        batchIds = this.items.slice(this.done.length, this.done.length + this.batch);
      }

      await get(`groups/${this.courseId}/refs/${this.ref}/conjugationBatchApply?ids=${batchIds.join(',')}`);
      if (batchIds.length === 0) {
        this.done = this.items;
      } else {
        this.done = [...this.done, ...batchIds];
      }
      this.render();

      if (this.done.length === this.items.length) {
        this.completed = true;
        this.conjugating = false;
        this.conjugationDone();
      } else {
        await this.conjugate();
      }
    }
  }
}
