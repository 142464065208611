import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import TableLiteralModel from '../../models/TableLiteralModel';
import Translations from '../../components/translation/Translations';
import styled from 'styled-components';
import { IRefIds } from '../../constants/refs';
import ListIds from '../../components/common/ListIds';

export type IProps = {
  courseId: number;
};

const TableLiterals: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const conjugationTemplateLiteralModel = useMemo(
    () => new TableLiteralModel({ render: forceRender, courseId: props.courseId }),
    [forceRender, props.courseId],
  );

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={conjugationTemplateLiteralModel}>
        <Split widthLeft={424}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'code'} label={'Code'} />
                <SaveButton />
              </EditorRow>
              <EditorRow>
                <ListIds refId={IRefIds.table} label={'Tables'} keyName={'tables'} />
              </EditorRow>
              <EditorRow>
                <ListIds refId={IRefIds.chart} label={'Charts'} keyName={'charts'} />
              </EditorRow>
              <EditorRow>
                <ListIds refId={IRefIds.conjugation} label={'Conj'} keyName={'conjTemplates'} />
              </EditorRow>
              <Scroll>
                <Translations keyName={'sources'} refId={conjugationTemplateLiteralModel.ref} />
              </Scroll>
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default TableLiterals;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;
