import { IRefIds } from 'constants/refs';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IConceptLinkerConcepts } from 'types/conceptLinker';
import { IDialectAware, IItemId } from 'types/content';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { ITranslation } from '../types/Translation';
import TranslationManagerModel from './TranslationManagerModel';
import { IAIReviewEdit } from '../types/aiReview';

export type ISentence = {
  id: number;
  gender: string;
  readby: string;
  notesStatus: number;
} & {
  [prop: string]: string;
};

export type ISentenceEdit = {
  id: number;
  gender: string;
  readby: string;
  panels: number[];
  deletable: boolean;
  sourceAdaptationNote: string;
  targets: ISentenceTarget[];
  sources: ITranslation[];
};

export type ISentenceTarget = IDialectAware & {
  id: number;
  sentenceId: number;
  text: string;
  revision: boolean;
  audioPath: string;
  conceptLinkerConcepts: IConceptLinkerConcepts;
  aiReview: IAIReviewEdit;
};

type ISentenceSearch = IModuleSearch & {
  dialect: string;
};

export default class SentenceModel extends BaseModel<ISentence, ISentenceEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.sentences;
    this.gridName = 'Sentences';
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  async getGridSearch(text: string, dialect: string) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    this.gridData = await get<ISentence[]>(`groups/${this.courseId}/sentences?dialect=${dialect}&text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGridRow(itemId: number) {
    return await get<ISentence>(`groups/${this.courseId}/sentences/${itemId}`);
  }

  async new() {
    await super.new();

    const [item, sources, targets] = await Promise.all([
      get<ISentenceEdit>(`sentences/new`),
      this.translations.new(this.courseId),
      get<ISentenceTarget[]>(`courses/${this.courseId}/sentences-target/new`),
    ]);

    item.targets = targets as ISentenceTarget[];
    item.sources = sources;

    await this.setItem(item);

    this.render();
  }

  async save() {
    await super.save();

    const { id } = this.itemUpdated as ISentenceEdit;

    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToUpdate = this.itemUpdated as ISentenceEdit;
    const { id } = itemToUpdate;
    await put(`sentences/${id}`, lodash.omit(itemToUpdate, ['targets', 'sources']));
    await Promise.all(
      itemToUpdate.targets.map((dialect: ISentenceTarget) =>
        put(`sentences/${id}/target-dialect/${dialect.dialectId}`, dialect),
      ),
    );
    await this.translations.update(id, itemToUpdate.sources);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as ISentenceEdit;

    const result = await post<IItemId>(
      `groups/${this.courseId}/sentences`,
      lodash.omit(itemToCreate, ['targets', 'sources']),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all([
        itemToCreate.targets.map((dialect: ISentenceTarget) =>
          put(`sentences/${newItemId}/target-dialect/${dialect.dialectId}`, dialect),
        ),
        this.translations.update(newItemId, itemToCreate.sources),
        this.updateGridRow(newItemId, 'add'),
        this.loadItemEdit(newItemId),
      ]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated as ISentenceEdit;

    const result = await del(`sentences/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets, sources] = await Promise.all([
      get<ISentenceEdit>(`sentences/${itemId}`),
      get<ISentenceTarget[]>(`sentences/${itemId}/targets`),
      this.translations.get(itemId),
    ]);

    item.targets = targets as ISentenceTarget[];
    item.sources = sources;

    await this.setItem(item);

    this.render();
  }

  async search(data: ISentenceSearch) {
    await this.getGridSearch(data.text || '', data.dialect);
  }
}
