import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITrainerSegment, ITrainerSegmentEdit, ITrainerSegmentTarget } from '../types/trainer';
import * as lodash from 'lodash';
import { IRefIds } from '../constants/refs';
import TrainerModel from './TrainerModel';

export default class TrainerSegmentModel extends BaseModel<ITrainerSegment, ITrainerSegmentEdit> {
  trainerModel: TrainerModel | null = null;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.trainerSegment;
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as ITrainerSegmentEdit;

    await put(`trainers/segments/${item.id}`, lodash.omit(item, ['targets']));
    await Promise.all(
      item.targets.map((target) => put(`/trainers/segments/${item.id}/target-dialect/${target.dialectId}`, target)),
    );
    await Promise.all([this.loadItemEdit(item.id), this.reloadTrainerModel()]);
  }

  async delete() {
    await super.delete();
    const item = this.itemUpdated as ITrainerSegmentEdit;
    await del(`trainers/segments/${item.id}`);
    this.itemUpdated = null;
    this.loadingItem = false;
    this.render();
    await this.reloadTrainerModel();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets] = await Promise.all([
      get<ITrainerSegmentEdit>(`trainers/segments/${itemId}`),
      get<ITrainerSegmentTarget[]>(`trainers/segments/${itemId}/targets`),
    ]);

    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async reloadTrainerModel() {
    if (this.trainerModel) {
      await this.trainerModel.forceReloadTrainerSegments.bind(this.trainerModel)();
    }
  }

  async reloadItem() {
    await Promise.all([this.loadItemEdit(this.itemId), this.reloadTrainerModel()]);
  }
}
