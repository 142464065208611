import { get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IBoardTypes } from './BoardsModel';

export type IStatesHistory = {
  id: number;
  state: number;
  date: string;
  userName: string;
};

export enum IState {
  NONE = 0,
  TODO = 1,
  DOING = 2,
  REVIEW = 3,
  DONE = 4,
}

export const STATE_NAMES: { [key in IState]: string } = {
  [IState.NONE]: 'None',
  [IState.TODO]: 'To Do',
  [IState.DOING]: 'Doing',
  [IState.REVIEW]: 'Review',
  [IState.DONE]: 'Done',
};

export default class StateModel extends BaseModel {
  refId = 0;
  type: IBoardTypes;
  updateParentModelGridRow: (id: number) => void;
  state: IState = IState.NONE;

  constructor(
    options: IModelCourseOptions & {
      refId: number;
      type: IBoardTypes;
      state: number;
      updateParentModelGridRow: (id: number) => void;
    },
  ) {
    super(options);
    this.refId = options.refId;
    this.gridName = 'StatesHistory';
    this.type = options.type;
    this.state = options.state;
    this.updateParentModelGridRow = options.updateParentModelGridRow;
    this.getGridColumns();
    this.getGrid();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IStatesHistory[]>(`board-types/${this.type}/ref-ids/${this.refId}/states-history`);

    this.loadingGrid = false;
    this.render();
  }

  async change(state: IState, message?: string) {
    this.loadingGrid = true;
    this.render();
    const data: { state: number; message?: string } = { state };
    if (message) {
      data.message = message;
    }
    await put(`courses/${this.courseId}/board-types/${this.type}/ref-ids/${this.refId}/states-change`, data);
    this.updateParentModelGridRow(this.refId);
  }
}
