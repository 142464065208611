import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import LessonOutlineModel from '../../../models/LessonOutlineModel';

const LessonOutlineLessonNew: React.FC = () => {
  const module = useContext(ModuleContext) as LessonOutlineModel;

  const onNewFirst = () => {
    module.newLessonFirstRange();
  };

  const onNewLast = () => {
    module.newLessonLastRange();
  };

  return (
    <Container>
      <Wrapper onClick={onNewFirst}>
        <FontAwesomeIcon icon={faPlus} color={'#bfbfbf'} />
        <ActionText>Add Lesson First in Range</ActionText>
      </Wrapper>
      <Wrapper onClick={onNewLast}>
        <FontAwesomeIcon icon={faPlus} color={'#bfbfbf'} />
        <ActionText>Add Lesson Last in Range</ActionText>
      </Wrapper>
    </Container>
  );
};

export default LessonOutlineLessonNew;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  flex: 1;
  margin: 10px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #bfbfbf;
  background-color: #f5f5f5;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const ActionText = styled.span`
  padding-left: 10px;
`;
