import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import Checkbox from 'CMFW/input/Checkbox';
import TextInput from 'CMFW/input/TextInput';
import { AudioPlayer } from 'CMFW/layout/AudioPlayer';
import { EditorRow } from 'components/common/styles';
import InflectViewModal from 'components/inflect/InflectViewModal';
import { IVocDialect } from 'models/VocabularyModel';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import SwitchInput from '../../CMFW/input/SwitchInput';
import AIReviewPanel from '../AIReview/components/AIReviewPanel';
import AIReviewIcon from '../AIReview/components/AIReviewIcon';

const Dialect: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSourceModal, setShowSourceModal] = useState(false);

  const parentKey = useContext(KeyContext);

  const module = useContext(ModuleContext);
  const moduleItemValue: IVocDialect = module.getItemValue(parentKey);

  const handleCloseShowInflects = () => {
    setShowModal(false);
  };

  const handleInflectClick = () => {
    setShowModal(true);
  };

  const handleCloseShowInflectsSource = () => {
    setShowSourceModal(false);
  };

  const handleInflectSourceClick = () => {
    setShowSourceModal(true);
  };

  return (
    <Wrapper>
      <AIReviewPanel hidable />
      <EditorRow>
        <AIReviewIcon />
        <InflectIcon
          $dimmed={moduleItemValue.sourceShaded}
          $hidden={false}
          $off={moduleItemValue.nInflectsSource === 0}
          icon={faListAlt}
          onClick={handleInflectSourceClick}
        />
        <TextInputExtra
          keyName={'source'}
          disabled={!moduleItemValue.sourceEditable}
          $dimmed={moduleItemValue.sourceShaded}
          $hidden={false}
        />
        <InflectIcon
          $dimmed={moduleItemValue.inflectShaded}
          $hidden={moduleItemValue.inflectHidden}
          $off={moduleItemValue.nInflects === 0}
          icon={faListAlt}
          onClick={handleInflectClick}
        />
        <TextInputExtra
          keyName={'target'}
          $dimmed={moduleItemValue.targetShaded}
          $hidden={moduleItemValue.targetHidden}
        />
        <Gender keyName={'gender'} $dimmed={moduleItemValue.genderShaded} $hidden={moduleItemValue.genderHidden} />
        <AudioPlayerStyled $dimmed={moduleItemValue.audioShaded} $hidden={moduleItemValue.audioHidden} />
        <SwitchInputStyled keyName={'targetEnabled'} disabled={!moduleItemValue.targetCanBeDisabled} />
        {showModal && <InflectViewModal onClose={handleCloseShowInflects} />}
        {showSourceModal && <InflectViewModal isSource onClose={handleCloseShowInflectsSource} />}
      </EditorRow>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CheckboxStyled = styled(Checkbox)`
  && {
    margin-right: 0;
  }
  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

const TextInputExtra = styled(TextInput)<{ $dimmed: boolean; $hidden: boolean }>`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.$dimmed ? 0.5 : 1)};
  margin: 0 1px;
`;

const Gender = styled(TextInputExtra)<{ $dimmed: boolean; $hidden: boolean }>`
  width: 230px;
`;

const AudioPlayerStyled = styled(AudioPlayer)<{ $dimmed: boolean; $hidden: boolean }>`
  && {
    visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
    opacity: ${(props) => (props.$dimmed ? 0.5 : 1)};
    margin: 0 4px;
  }
`;

const SwitchInputStyled = styled(SwitchInput)`
  && {
    margin: 0 4px;
  }
`;

const InflectIcon = styled(FontAwesomeIcon)<{ $dimmed: boolean; $hidden: boolean; $off: boolean }>`
  && {
    visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
    cursor: ${(props) => (props.$hidden ? 'default' : 'pointer')};
    opacity: ${(props) => (props.$dimmed ? 0.5 : 1)};
    color: ${(props) => (props.$off ? 'red' : 'black')};
  }
  padding: 4px;
`;

export default Dialect;
