import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { BaseModel, IModelOptions, IModuleSearch } from './BaseModel';

export type IMemoryList = {
  id: number;
  refName: string;
  similarity: string;
} & {
  [prop: string]: string;
};

type IMemoryListOptions = IModelOptions & {
  search?: string;
  dialectId?: string;
};

export default class MemoryListModel extends BaseModel<IMemoryList> {
  constructor(options: IMemoryListOptions) {
    super(options);
    this.ref = IRefIds.memory;
    this.gridName = 'Memory';
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=0`;
  }

  async getGridSearch(text: string, dialect: string) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }
    console.log(text);
    this.gridData = await get<IMemoryList[]>(`/memory?dialect=${dialect}&text=${encodeURIComponent(text)}`);

    this.loadingGrid = false;
    this.render();
  }

  async search(
    data: IModuleSearch & {
      dialect?: string;
    },
  ) {
    if (data.text && data.dialect) {
      await this.getGridSearch(data.text, data.dialect);
    }
  }

  async getGridRow(itemId: number) {
    return await get<IMemoryList>(`/memory/translation/${itemId}`);
  }

  async update(translationId: number) {
    await this.updateGridRow(translationId, 'update');
    this.render();
  }
}
