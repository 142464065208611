import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import WordClassSelectModel, { IWordClassSelect } from 'models/WordClassSelectModel';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SelectLayout } from './SelectLayout';

const KEY = 'wordClass';

type IProps = {
  isLinked?: boolean;
  onChange?: (wordClassId: number) => void;
  className?: string;
  disabled?: boolean;
};

export const WordClassSelect: React.FC<IProps> = (props) => {
  const { isLinked, onChange } = props;

  const module = useContext(ModuleContext);
  const moduleItemValue = module.getItemValue(KEY);

  const [currentWordClassId, setCurrentWordClassId] = useCurrentItemValue(moduleItemValue, 0, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.wordClass, courseId);
  const dataModel = useMemo(() => new WordClassSelectModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (value: IWordClassSelect) => {
    const wordClassId = value.id;
    setCurrentWordClassId(wordClassId);
    isLinked && module.updateItemValue(KEY, wordClassId);
    onChange && onChange(wordClassId);
  };

  return (
    <SelectLayoutStyled
      className={props.className}
      data={cacheSelectData}
      onChange={handleChange}
      label="Word Class"
      optionLabelKey="name"
      currentValue={currentWordClassId}
      originalValue={isLinked && moduleItemValue}
      disabled={props.disabled}
    />
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 175px;
  flex-grow: 0;
`;
