import React, { useContext, useEffect, useRef } from 'react';
import CATEditor from './CATEditor';
import styled from 'styled-components';
import CATTextColor from './CATTextColor';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import CATModel, { ICATData } from '../../../models/CATModel';
import { ITranslationStatus } from '../../../types/Translation';
import CATStatus from './CATStatus';
import CATError from './CATError';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATSimilarity from './CATSimilarity';
import CATCellQA from './CATCellQA';

export type IProps = {
  data: ICATData;
  row: ListRowProps;
  activeId: number;
  cache: CellMeasurerCache;
  onChangeHeight: (index: number) => void;
  onUpdateTarget: (id: number, target: string, status: ITranslationStatus) => void;
};

const CATTableRow: React.FC<IProps> = ({ data, row, activeId, cache, onChangeHeight, onUpdateTarget }) => {
  const module = useContext(ModuleContext) as CATModel;
  const cell1Ref = useRef<HTMLDivElement>(null);
  const cell2Ref = useRef<HTMLDivElement>(null);

  const handleChangeHeight = () => {
    if (cell1Ref.current && cell2Ref.current) {
      const cell1Height = cell1Ref.current.clientHeight;
      const cell2Height = cell2Ref.current.clientHeight;
      const newHeight = Math.max(cell1Height, cell2Height) + 1;
      if (newHeight !== cache.getHeight(row.index, 0)) {
        cache.set(row.index, 0, 0, newHeight);
        onChangeHeight(row.index);
      }
    }
  };

  useEffect(() => {
    if (cell1Ref.current && cell2Ref.current) {
      handleChangeHeight();
    }
  });

  const onUpdateText = (text: string, status?: ITranslationStatus) => {
    onUpdateTarget(data.id, text, status ?? data.status);
  };

  const onUpdateStatus = (status: ITranslationStatus) => {
    onUpdateTarget(data.id, data.toText, status);
  };

  const onFocus = (id: number) => {
    if (id !== activeId) {
      module.setActive.bind(module)(id);
    }
  };

  return (
    <CellMeasurer key={row.key} cache={cache} parent={row.parent} columnIndex={0} rowIndex={row.index}>
      <CATRow style={row.style} $updating={data.updating} $current={data.id === module.activeId}>
        <CATCell>
          <CATTextColor ref={cell1Ref} text={data.fromText} />
        </CATCell>
        <CATCell>
          <CATEditor
            ref={cell2Ref}
            id={data.id}
            text={data.toText}
            onFocus={onFocus}
            onUpdateText={onUpdateText}
            onChangeHeight={handleChangeHeight}
            focus={data.id === module.activeId}
          />
        </CATCell>
        <CATCell width={30} padding={0}>
          <CATSimilarity similarity={data.similarity} />
        </CATCell>
        <CATCell width={15}>
          <CATStatus status={data.status} onUpdateStatus={onUpdateStatus} />
        </CATCell>
        <CATCell width={15}>
          <CATError status={data.status} error={data.error} />
        </CATCell>
        {module.hasQA(data.contextRef) && (
          <CATCell width={15}>
            <CATCellQA errors={data.QAErrors} />
          </CATCell>
        )}
      </CATRow>
    </CellMeasurer>
  );
};

export default CATTableRow;

export const CATRow = styled.div<{ $updating?: boolean; $current?: boolean }>`
  display: flex;
  width: auto;
  flex-direction: row;
  border-top: solid 1px #dddddd;
  border-left: solid 1px #dddddd;
  background-color: ${(props) =>
    props.$updating ? 'rgba(189,255,171,0.25)' : props.$current ? 'rgba(255,235,171,0.25)' : 'transparent'};
`;

export const CATCell = styled.div<{ width?: number; padding?: number }>`
  width: ${(props) => (props.width ? props.width + 'px' : '50%')};
  border-right: solid 1px #dddddd;
  padding-left: ${(props) => props.padding ?? 5}px;
  padding-right: ${(props) => props.padding ?? 5}px;
`;
