import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IBookChapterText, IBookChapterTextEdit, IBookChapterTextTarget } from '../types/book';
import { IRefIds } from '../constants/refs';
import BookChapterModel from './BookChapterModel';

export default class BookChapterTextModel extends BaseModel<IBookChapterText, IBookChapterTextEdit> {
  bookChapterModel: BookChapterModel | null = null;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.bookChapterText;
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as IBookChapterTextEdit;

    await Promise.all([
      ...item.targets.map((target) =>
        put(`books/chapters/texts/${item.id}/target-dialect/${target.dialectId}`, target),
      ),
    ]);
    await Promise.all([this.loadItemEdit(item.id), this.reloadBookChapterModel()]);
  }

  async delete() {
    await super.delete();
    const item = this.itemUpdated as IBookChapterTextEdit;
    await del(`books/chapters/texts/${item.id}`);
    this.itemUpdated = null;
    this.loadingItem = false;
    this.render();
    await this.reloadBookChapterModel();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets] = await Promise.all([
      get<IBookChapterTextEdit>(`books/chapters/texts/${itemId}`),
      get<IBookChapterTextTarget[]>(`books/chapters/texts/${itemId}/targets`),
    ]);

    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async reloadBookChapterModel() {
    if (this.bookChapterModel) {
      await this.bookChapterModel.forceReloadBookChapterTexts.bind(this.bookChapterModel)();
    }
  }
}
