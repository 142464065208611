import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import TextEditor from 'CMFW/input/TextEditor';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { TabDialects } from 'CMFW/layout/TabDialects';
import { ContainerRight, EditorGapGreedyH, EditorRow } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import BookChapterModel from '../../../models/BookChapterModel';
import BookChapterTextModel from '../../../models/BookChapterTextModel';

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'RemoveFormat'],
  ],
  toolBarInline: [['Bold']],
};

export type IProps = {
  bookChapterTextId: number;
};

const BookChapterTextEdit: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const bookChapterModel = useContext(ModuleContext) as BookChapterModel;

  const readingTextModel = useMemo(() => {
    const rem = new BookChapterTextModel({ render: forceRender, courseId: bookChapterModel.courseId });
    if (props.bookChapterTextId) {
      rem.loadItemEdit(props.bookChapterTextId);
      rem.bookChapterModel = bookChapterModel;
    }
    return rem;
  }, [forceRender, props.bookChapterTextId, bookChapterModel]);

  return (
    <ModuleContext.Provider value={readingTextModel}>
      <ContainerRightStyled>
        <ItemLayout>
          <Container>
            <EditorRow>
              <EditorGapGreedyH />
              <SaveButton />
            </EditorRow>
            <DialectContainer>
              <TabDialects keyName={'targets'}>
                <TextEditor
                  keyName={'html'}
                  clKeyName={'conceptLinkerConcepts'}
                  config={CK_EDITOR_CONFIG}
                  autoGrow={true}
                />
              </TabDialects>
            </DialectContainer>
          </Container>
          <EditorRow>
            <DeleteButton />
          </EditorRow>
        </ItemLayout>
        <LoadingItem />
      </ContainerRightStyled>
    </ModuleContext.Provider>
  );
};

export default BookChapterTextEdit;

const DialectContainer = styled.div`
  width: 100%;
`;

const ContainerRightStyled = styled(ContainerRight)`
  width: auto;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  overflow: auto;
`;
