import { IRefIds } from 'constants/refs';
import { get, post, put } from 'services/request';
import { BaseModel, IModelOptions } from './BaseModel';
import { IItemId } from '../types/content';
import store from '../store/store';
import { cacheDelete } from '../store/actions/cache';
import { getCacheKey } from '../helpers/cache';

export type ILanguage = IItemId & {
  name: string;
  code: string;
};

export type ILanguageEdit = IItemId & {
  name: string;
  code: string;
};

export default class LanguagesModel extends BaseModel<ILanguage, ILanguageEdit> {
  constructor(options: IModelOptions) {
    super(options);
    this.ref = IRefIds.languages;
    this.gridName = 'Languages';
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ILanguage[]>(`languages`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ILanguage>(`languages/${itemId}/grid-row`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    LanguagesModel.invalidateCache();
  }

  async new() {
    await super.new();
    const item = await get<ILanguageEdit>(`languages/new`);
    await this.setItem(item);
    this.render();
  }

  async createItem() {
    const result = await post<IItemId>(`languages`, this.itemUpdated);

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async updateItem() {
    const item = this.itemUpdated as ILanguageEdit;
    await put(`languages/${item.id}`, item);
    await Promise.all([this.updateGridRow(item.id, 'update'), this.loadItemEdit(item.id)]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ILanguageEdit>(`languages/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  private static invalidateCache() {
    store.dispatch(cacheDelete(getCacheKey(IRefIds.languages)));
  }
}
