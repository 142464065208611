import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ILessonOutline, ILessonOutlineCard } from '../../../types/lessonOutline';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import LessonOutlineCycleNew from '../components/LessonOutlineCycleNew';
import LessonOutlineCycle from './LessonOutlineCycle';
import LessonOutlineContext from '../context/LessonOutlineContext';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import LessonOutlineModel from '../../../models/LessonOutlineModel';
import LessonOutlineTitleModal from '../modal/LessonOutlineTitleModal';

export type IProps = {
  lesson: ILessonOutline;
  index: number;
};

const LessonOutlineLesson: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as LessonOutlineModel;
  const lessonOutlineContext = useContext(LessonOutlineContext);
  const [showModal, setShowModal] = useState(false);

  const handleChangeTitle = (title: string, status: number) => {
    module.updateLessonTitle(props.lesson.id, title, status);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <Draggable
      isDragDisabled={!lessonOutlineContext.DNDEnabled}
      draggableId={`lesson-${props.lesson.id}`}
      index={props.index}
    >
      {(provided) => (
        <Wrapper isDragging={false} {...provided.draggableProps} ref={provided.innerRef}>
          <TitleWrapper onClick={handleShowModal}>
            <Position {...provided.dragHandleProps}>{props.index + module.starPosition}</Position>
            <Title dangerouslySetInnerHTML={{ __html: props.lesson.title }} />
            {lessonOutlineContext.DNDEnabled && <LessonOutlineCycleNew lessonId={props.lesson.id} />}
          </TitleWrapper>
          <Droppable
            isDropDisabled={!lessonOutlineContext.DNDEnabled}
            droppableId={`lesson-${props.lesson.id}`}
            direction={'vertical'}
            type={ILessonOutlineCard.CYCLE}
          >
            {(providedDroppable, snapshot) => (
              <WrapperCycles
                isDraggingOver={snapshot.isDraggingOver}
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {props.lesson.cycles.map((cycle, index) => (
                  <LessonOutlineCycle cycle={cycle} key={`cycle-${cycle.id}`} index={index} />
                ))}
                {providedDroppable.placeholder}
              </WrapperCycles>
            )}
          </Droppable>
          {props.lesson.loading && <Loading />}
          {showModal && (
            <LessonOutlineTitleModal
              title={props.lesson.title}
              status={props.lesson.status}
              handleChangeTitle={handleChangeTitle}
              onClose={handleCloseModal}
            />
          )}
        </Wrapper>
      )}
    </Draggable>
  );
};

export default LessonOutlineLesson;

const Wrapper = styled.div<{ isDragging: boolean }>`
  background-color: white;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  position: relative;
  margin: 20px 4px;
`;

const WrapperCycles = styled.div<{ isDraggingOver: boolean }>`
  min-height: 20px;
  background-color: ${(props) => (props.isDraggingOver ? '#f5f5e8' : 'none')};
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 245, 245, 0.8);
`;

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 2px 0;
`;

const Title = styled.p`
  margin: 0;
  flex: 1;
`;

const Position = styled.p`
  margin: 0 4px;
  font-size: 16px;
`;
