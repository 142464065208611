import XlsxPopulate from 'xlsx-populate';

export type IXLSXData = {
  id: string;
  source: string;
  concepts: {
    id: string;
    conceptTarget: string;
    conceptSource: string;
    phraseTarget: string;
    verified: boolean;
  }[];
};

export type IXLSXItemsData = {
  id: string;
  conceptTarget: string;
  conceptSource: string;
  phrases: {
    source: string;
    target: string;
  }[];
};

export type IXLSXImport = {
  ref: number;
  ids: number[];
  sourceDialect: number;
  source: string;
  verified: {
    ref: number;
    id: number;
  }[];
};

const styleRow = (row: XlsxPopulate.Row) => {
  row.style('fill', {
    type: 'solid',
    color: 'F3F3F3',
  });
};

const parseNode = (node: ChildNode, rich: any = null): string => {
  let result = '';
  node.childNodes.forEach((child) => {
    if (child.nodeName === 'span') {
      rich && rich.add(parseNode(child), { bold: true });
    } else {
      result += parseNode(child, rich);
    }
  });
  if (node.nodeType === node.TEXT_NODE) {
    if (rich) {
      rich.add(node.textContent);
    } else {
      result += node.textContent;
    }
  }
  return result;
};

const richValue = (value: string): any => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString('<p>' + value + '</p>', 'application/xml');
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const rich = new XlsxPopulate.RichText();
  parseNode(xmlDoc.childNodes[0], rich);

  return rich;
};

export const generateXLSX = async (data: IXLSXData[]) => {
  const workbook = await XlsxPopulate.fromBlankAsync();
  let nRow = 1;

  workbook.activeSheet().column('A').width(15);
  workbook.activeSheet().column('B').width(20);
  workbook.activeSheet().column('C').width(20);
  workbook.activeSheet().column('D').width(80);
  workbook.activeSheet().column('E').width(25);
  workbook.activeSheet().column('F').width(4);

  data.forEach((row) => {
    row.concepts.forEach((concept) => {
      styleRow(workbook.activeSheet().row(nRow));
      workbook.activeSheet().cell(`A${nRow}`).value(concept.id);
      workbook.activeSheet().cell(`B${nRow}`).value(concept.conceptTarget).style('wrapText', true).style('fill', {
        type: 'solid',
        color: 'D9EAD3',
      });
      workbook.activeSheet().cell(`C${nRow}`).value(concept.conceptSource).style('wrapText', true).style('fill', {
        type: 'solid',
        color: '#FCE5CD',
      });
      workbook
        .activeSheet()
        .cell(`D${nRow}`)
        .value(richValue(concept.phraseTarget))
        .style('wrapText', true)
        .style('fill', {
          type: 'solid',
          color: 'D9EAD3',
        });
      workbook
        .activeSheet()
        .cell(`E${nRow}`)
        .value('')
        .style('wrapText', true)
        .style('fill', {
          type: 'solid',
          color: '#FFFFFF',
        })
        .style('border', true);
      workbook
        .activeSheet()
        .cell(`F${nRow}`)
        .value(concept.verified ? 'x' : '');
      workbook.activeSheet();
      nRow++;
    });
    styleRow(workbook.activeSheet().row(nRow));
    styleRow(workbook.activeSheet().row(nRow + 1));
    workbook.activeSheet().cell(`A${nRow}`).value(row.id);
    workbook
      .activeSheet()
      .cell(`D${nRow}`)
      .value(row.source)
      .style('wrapText', true)
      .style('fill', {
        type: 'solid',
        color: 'FFFFFF',
      })
      .style('border', true);
    nRow += 2;
  });

  const output = await workbook.outputAsync('base64');
  window.location.href = 'data:' + XlsxPopulate.MIME_TYPE + ';base64,' + output;
};

export const generateXLSXItems = async (data: IXLSXItemsData[]) => {
  const workbook = await XlsxPopulate.fromBlankAsync();
  let nRow = 1;

  workbook.activeSheet().column('A').width(15);
  workbook.activeSheet().column('B').width(20);
  workbook.activeSheet().column('C').width(20);
  workbook.activeSheet().column('D').width(80);
  workbook.activeSheet().column('E').width(25);
  workbook.activeSheet().column('F').width(4);

  data.forEach((row) => {
    styleRow(workbook.activeSheet().row(nRow));
    workbook.activeSheet().cell(`A${nRow}`).value(row.id);
    workbook.activeSheet().cell(`B${nRow}`).value(row.conceptTarget);
    workbook
      .activeSheet()
      .cell(`C${nRow}`)
      .value(row.conceptSource)
      .style('wrapText', true)
      .style('fill', {
        type: 'solid',
        color: 'FFFFFF',
      })
      .style('border', true);
    workbook
      .activeSheet()
      .cell(`E${nRow}`)
      .value('')
      .style('wrapText', true)
      .style('fill', {
        type: 'solid',
        color: '#FFFFFF',
      })
      .style('border', true);
    nRow++;
    row.phrases.forEach((phrase) => {
      styleRow(workbook.activeSheet().row(nRow));
      workbook.activeSheet().cell(`D${nRow}`).value(richValue(phrase.target)).style('wrapText', true).style('fill', {
        type: 'solid',
        color: 'D9EAD3',
      });
      nRow++;
      styleRow(workbook.activeSheet().row(nRow));
      workbook.activeSheet().cell(`D${nRow}`).value(phrase.source).style('wrapText', true).style('fill', {
        type: 'solid',
        color: 'FCE5CD',
      });
      styleRow(workbook.activeSheet().row(nRow + 1));
      nRow += 2;
    });
  });

  const output = await workbook.outputAsync('base64');
  window.location.href = 'data:' + XlsxPopulate.MIME_TYPE + ';base64,' + output;
};

const getFromId = (id: string): { ref: number; sourceDialect: number; id: number[] } => {
  const parts = id.split(' ');
  return {
    id: parts
      .pop()!
      .split(',')
      .map((i) => parseInt(i)),
    sourceDialect: parseInt(parts.pop()!.replace('d', '')),
    ref: parseInt(parts.pop()!.replace('r', '')),
  };
};

export const getXLSXImport = async (content: ArrayBuffer): Promise<IXLSXImport[]> => {
  const results: IXLSXImport[] = [];

  const workbook = await XlsxPopulate.fromDataAsync(content);

  const cells = workbook.activeSheet().find(/r\d+\sd\d+/g);
  let conceptsVerified: { ref: number; id: number }[] = [];

  cells.forEach((cell) => {
    const row = cell.row();
    const id = cell.value() as string;
    if (id.startsWith('#i')) {
      const item = getFromId(id);
      results.push({
        ids: item.id,
        ref: item.ref,
        sourceDialect: item.sourceDialect,
        source: row.cell('C').value() as string,
        verified: [],
      });
    }

    if (id.startsWith('#c')) {
      const isVerified = ((row.cell('F').value() as string) || '').trim() !== '';
      if (isVerified) {
        const item = getFromId(id);
        conceptsVerified.push({
          id: item.id[0],
          ref: item.ref,
        });
      }
    }

    if (!id.startsWith('#')) {
      const item = getFromId(id);
      const value = row.cell('D').value() as any;
      let valueText = '';
      if (value.text) {
        valueText = value.text();
      } else {
        valueText = value ?? '';
      }

      results.push({
        ids: item.id,
        ref: item.ref,
        sourceDialect: item.sourceDialect,
        source: valueText,
        verified: [...conceptsVerified],
      });
      conceptsVerified = [];
    }
  });

  return results;
};
