import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';
import styled from 'styled-components';

const KEY = 'id';

const Id: React.FC = () => {
  const module = useContext(ModuleContext);

  const id = module.getItemValue(KEY);

  return (
    <SelectableSpan>
      <b>ID: </b>
      {id}
    </SelectableSpan>
  );
};

const SelectableSpan = styled.span`
  user-select: text;
`;

export default Id;
