import { BaseModel, IModelCourseOptions } from './BaseModel';
import { del, get, post, put } from '../services/request';
import { ILessonOutline, ILessonOutlineCard } from '../types/lessonOutline';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import TranslationManagerModel from './TranslationManagerModel';
import { IRefIds } from '../constants/refs';

type IPostResponse = {
  id: number;
};

export default class LessonOutlineModel extends BaseModel {
  lessons: ILessonOutline[] = [];
  cycleMapLesson: number[] = [];
  activityMapLesson: number[] = [];
  loaded = false;
  starPosition = 0;
  translations: TranslationManagerModel;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.translations = new TranslationManagerModel({ ref: IRefIds.lessonTitle });
  }

  public async loadLessons(start: number, end: number) {
    this.starPosition = start;
    this.loadingItem = true;
    this.loaded = true;
    this.lessons = [];
    this.render();
    this.lessons = await get(`groups/${this.courseId}/dialects/default/lessonOutline`, {
      start,
      end,
    });
    this.lessons = this.lessons.sort((a, b) => a.position - b.position);
    this.mapCycleLesson(this.lessons);
    this.loadingItem = false;
    this.render();
  }

  public getLessons() {
    return this.lessons;
  }

  public async onDragEnd(result: DropResult, provided: ResponderProvided) {
    console.log(result);
    const { destination, source, type, draggableId } = result;
    if (!destination || this.getBank(destination.droppableId) !== null) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (this.isTrash(destination.droppableId)) {
      if (type === ILessonOutlineCard.LESSON) {
        const lessonId = this.getId(draggableId);
        await this.deleteLesson(lessonId, source.index);
      }

      if (type === ILessonOutlineCard.CYCLE) {
        const cycleId = this.getId(draggableId);
        await this.deleteCycle(cycleId);
      }

      if (type === ILessonOutlineCard.ACTIVITY) {
        const activityId = this.getId(draggableId);
        await this.removeActivity(activityId);
      }
    } else {
      if (type === ILessonOutlineCard.LESSON) {
        const lessonId = this.getId(draggableId);
        await this.moveLesson(lessonId, source.index, destination.index);
      }

      if (type === ILessonOutlineCard.CYCLE) {
        const cycleId = this.getId(draggableId);
        const sourceLesson = this.getId(source.droppableId);
        const destinationLesson = this.getId(destination.droppableId);
        await this.moveCycle(cycleId, sourceLesson, destinationLesson, source.index, destination.index);
      }

      if (type === ILessonOutlineCard.ACTIVITY) {
        const refBank = this.getBank(source.droppableId);
        const activityId = this.getId(draggableId);
        const destinationCycle = this.getId(destination.droppableId);
        if (refBank !== null) {
          await this.addActivity(activityId, refBank, destinationCycle, destination.index);
        } else {
          const sourceCycle = this.getId(source.droppableId);
          await this.moveActivity(activityId, sourceCycle, destinationCycle, source.index, destination.index);
        }
      }
    }
  }

  private async moveLesson(lessonId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    this.arrayMove(this.lessons, prevPos, newPos);
    this.render();
    newPos = newPos + this.starPosition - 1;
    await put(`lessonOutline/lesson/${lessonId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
  }

  public async newLessonFirstRange() {
    this.loadingItem = true;
    this.render();
    const response = await post<IPostResponse>(`groups/${this.courseId}/lesson/new`, {});
    if (response.data) {
      await this.updateLesson(response.data.id);
      await this.moveLesson(response.data.id, this.lessons.length - 1, 0);
    }
    this.loadingItem = false;
    this.render();
  }

  public async newLessonLastRange() {
    this.loadingItem = true;
    this.render();
    const response = await post<IPostResponse>(`groups/${this.courseId}/lesson/new`, {});
    if (response.data) {
      await this.updateLesson(response.data.id);
      await this.moveLesson(response.data.id, this.lessons.length - 1, this.lessons.length - 1); //move on back
    }
    this.loadingItem = false;
    this.render();
  }

  public async updateLessonTitle(lessonId: number, title: string, status: number) {
    this.updatingLesson(lessonId);
    this.render();
    const lesson = this.getLessons().find((l) => l.id === lessonId);
    if (lesson) {
      await this.translations.update(lesson.lessonTargetId, [
        {
          text: title,
          dialectId: lesson.dialectId,
          dialectCode: lesson.dialectCode,
          status,
        },
      ]);
      await this.updateLesson(lessonId);
    }
    this.render();
  }

  public async newCycle(lessonId: number) {
    this.updatingLesson(lessonId);
    this.render();
    await post(`/lessons/${lessonId}/cycle/new`, {});
    await this.updateLesson(lessonId);
    this.loadingItem = false;
    this.render();
  }

  private async moveCycle(
    cycleId: number,
    sourceLessonId: number,
    destinationLessonId: number,
    prevPos: number,
    newPos: number,
  ) {
    this.updatingLesson(sourceLessonId);
    this.updatingLesson(destinationLessonId);
    this.render();

    await put(`lessonOutline/lessonCycle/${cycleId}/move`, {
      destinationLessonId: destinationLessonId,
      destinationPos: newPos,
    });

    await this.updateLesson(sourceLessonId);
    if (sourceLessonId !== destinationLessonId) {
      await this.updateLesson(destinationLessonId);
    }
    this.render();
  }

  private async moveActivity(
    activityId: number,
    sourceCycleId: number,
    destinationCycleId: number,
    prevPos: number,
    newPos: number,
  ) {
    const sourceLessonId = this.cycleMapLesson[sourceCycleId];
    const destinationLessonId = this.cycleMapLesson[destinationCycleId];
    this.updatingLesson(sourceLessonId);
    this.updatingLesson(destinationLessonId);
    this.render();

    await put(`lessonOutline/lessonCycleActivity/${activityId}/move`, {
      destinationCycleId: destinationCycleId,
      destinationPos: newPos,
    });

    await this.updateLesson(sourceLessonId);
    if (sourceLessonId !== destinationLessonId) {
      await this.updateLesson(destinationLessonId);
    }
    this.render();
  }

  private async addActivity(activityId: number, ref: number, destinationCycleId: number, destinationPos: number) {
    const destinationLessonId = this.cycleMapLesson[destinationCycleId];
    this.updatingLesson(destinationLessonId);
    this.render();

    await put(`lessonOutline/lessonCycle/${destinationCycleId}/add-activity`, {
      destinationPos,
      ref,
      activityId,
    });

    await this.updateLesson(destinationLessonId);
    this.render();
  }

  private async updateLesson(lessonId: number) {
    const lesson = await get<ILessonOutline>(`groups/${this.courseId}/dialects/default/lessonOutline/${lessonId}`);
    this.mapCycleLesson([lesson]);
    let found = false;
    this.lessons = this.lessons.map((l) => {
      if (l.id === lessonId) {
        found = true;
        return lesson;
      } else {
        return l;
      }
    });
    if (!found) {
      this.lessons.push(lesson);
    }
  }

  private async deleteLesson(lessonId: number, position: number) {
    this.loadingItem = true;
    this.arrayDelete(this.lessons, position);
    this.render();
    await del(`lessons/${lessonId}`);
    this.loadingItem = false;
    this.render();
  }

  private async deleteCycle(cycleId: number) {
    const sourceLessonId = this.cycleMapLesson[cycleId];
    this.updatingLesson(sourceLessonId);
    this.render();
    await del(`lessonsCycle/${cycleId}`);
    await this.updateLesson(sourceLessonId);
    this.render();
  }

  private async removeActivity(activityId: number) {
    const sourceLessonId = this.activityMapLesson[activityId];
    this.updatingLesson(sourceLessonId);
    this.render();
    await del(`lessonsCycleActivity/${activityId}`);
    await this.updateLesson(sourceLessonId);
    this.render();
  }

  private updatingLesson(lessonId: number) {
    this.lessons = this.lessons.map((l) => {
      if (l.id === lessonId) {
        l.loading = true;
      }
      return l;
    });
  }

  private mapCycleLesson(lessons: ILessonOutline[]) {
    lessons.forEach((lesson) => {
      lesson.cycles.forEach((cycle) => {
        this.cycleMapLesson[cycle.id] = lesson.id;
        cycle.activities.forEach((activity) => {
          this.activityMapLesson[activity.activityId] = lesson.id;
        });
      });
    });
  }

  private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  private arrayDelete(arr: any[], index: number) {
    arr.splice(index, 1);
  }

  private getId(text: string): number {
    return parseInt(text.split('-')[1]);
  }

  private getBank(text: string): number | null {
    const [ref, bank] = text.split('-');
    if (bank === 'bank') {
      return parseInt(ref);
    } else {
      return null;
    }
  }

  private isTrash(text: string): boolean {
    return text.search('Trash') !== -1;
  }
}
