import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

type IBatchRef = {
  ref: IRefIds;
  name: string;
};

export default class InflectSourceBatchModel extends BaseModel<IGridData, IBatchRef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const batches = await get<IBatchRef[]>(`source-inflect-batch-resources`);
    await this.setItem(batches);
    this.render();
  }
}
