import { IVocTreeEdit } from 'models/VocabularyModel';
import React from 'react';
import { TreeNode } from 'react-organizational-chart';
import VocTreeNodeItem from './VocTreeNodeItem';

export type IProps = {
  item: IVocTreeEdit;
};

const VocTreeNode: React.FC<IProps> = (props) => {
  return (
    <TreeNode label={<VocTreeNodeItem item={props.item} />}>
      {props.item.children.map((child) => (
        <VocTreeNode item={child} key={child.id} />
      ))}
    </TreeNode>
  );
};

export default VocTreeNode;
