import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import ModuleContext from '../context/ModuleContext';

const NewButton: React.FC = () => {
  const module = useContext(ModuleContext);
  const { loadingItem } = module;

  return (
    <Button disabled={loadingItem} variant="outlined" size="small" onClick={module.new.bind(module)}>
      new
    </Button>
  );
};

export default NewButton;
