import ModuleContext from 'CMFW/context/ModuleContext';
import { LoadingItem } from 'CMFW/layout/Loading';
import { CardContainer, CardHeader, CardId, Kline } from 'components/common/styles';
import { IRefIds } from 'constants/refs';
import { useForceRender } from 'hooks/useForceRender';
import PanelCardModel from 'models/PanelCardModel';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import styled from 'styled-components';
import { IPanelCard } from 'types/content';
import { positionToString } from '../../helpers/positon';

type IProps = {
  id: number;
};

const PanelCard: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { id } = props;
  const dispatch = useDispatch();
  const moduleModel = useContext(ModuleContext);

  const cardModel = useMemo(() => new PanelCardModel({ render: forceRender, panelId: id }), [forceRender, id]);

  const cardData = cardModel.getItemValue('cardData') as IPanelCard | undefined;

  const handleIdClick = () => {
    dispatch(
      tabsOpened({
        refId: IRefIds.panels,
        search: props.id.toString(),
        courseId: moduleModel.courseId,
      }),
    );
  };

  if (!cardData) {
    return null;
  }

  return (
    <ModuleContext.Provider value={cardModel}>
      <CardContainer key={props.id}>
        <CardHeader>
          {cardData.kLines.map((kline) => (
            <KlineWithVisibility key={kline.name} $isVisible={kline.visible !== undefined ? kline.visible : true}>
              {kline.name}
            </KlineWithVisibility>
          ))}
          <CardId onClick={handleIdClick}>{props.id}</CardId>
          <Title>
            <span>{positionToString(cardData.position)} - </span>
            <span dangerouslySetInnerHTML={{ __html: cardData.title }} />
          </Title>
        </CardHeader>
        <Body>
          <p dangerouslySetInnerHTML={{ __html: cardData.concept }} />
        </Body>
        <LoadingItem />
      </CardContainer>
    </ModuleContext.Provider>
  );
};

const KlineWithVisibility = styled(Kline)<{ $isVisible: boolean }>`
  background-color: ${(props) => (props.$isVisible ? '#5f84ef' : '#dddddd')};
  color: ${(props) => (props.$isVisible ? 'black' : 'white')};
`;

const Title = styled.div`
  margin: 2px;
  font-size: 15px;
  & p {
    margin: 0;
  }
`;

const Body = styled.div`
  font-size: 14px;
  padding: 4px;
  & p {
    margin: 0;
    padding: 0;
  }
`;

export default PanelCard;
