import { IRefIds } from 'constants/refs';
import { ICompoundType } from 'models/CompoundModel';
import { get } from 'services/request';

export default class CompoundTypeSelectModel {
  ref = IRefIds.compounds;
  courseId = 0;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async getTypes() {
    return await get<ICompoundType[]>(`courses/${this.courseId}/compound-types`);
  }
}
