import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { INote } from 'types/notes';
import NoteContext from '../../models/NoteContext';

type IProps = {
  note: INote;
};

export const NoteView: React.FC<IProps> = (props) => {
  const { note } = props;
  const noteModel = useContext(NoteContext);

  const handleCloseClick = async () => {
    if (note.status === 'OPENED') {
      await noteModel.closeNote(note.id);
    } else if (note.status === 'CLOSED') {
      await noteModel.deleteNote(note.id);
    }
  };

  return (
    <Container $isClosed={note.status === 'CLOSED'}>
      <Header>
        <User>{note.user}</User>
        <Time>{note.time}</Time>
        <Close onClick={handleCloseClick}>
          <FontAwesomeIconStyled icon={faTimes} />
        </Close>
      </Header>
      <Body dangerouslySetInnerHTML={{ __html: note.note }} />
    </Container>
  );
};

const Container = styled.div<{ $isClosed: boolean }>`
  border: solid 1px ${(props) => (props.$isClosed ? '#a4afbb' : '#ffcb6b')};
  background-color: ${(props) => (props.$isClosed ? '#dfe3e8' : '#ffdc9d')};
  margin: 10px 0 5px;
  font-size: 14px;
  color: ${(props) => (props.$isClosed ? '#58636f' : 'black')};
`;

const Header = styled.div`
  padding: 4px;
`;

const User = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const Time = styled.span`
  font-size: 12px;
`;

const Body = styled.div`
  background-color: white;
  padding: 4px;
`;

const Close = styled.div`
  display: inline;
  cursor: pointer;
  float: right;
  width: 16px;
  height: 16px;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: #b97b08;
  font-size: 14px;
`;
