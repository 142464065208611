import ModuleContext from 'CMFW/context/ModuleContext';
import { Loading } from 'components/common/Loading';
import BoardsModel from 'models/BoardsModel';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Column from './Column';
import Folders from './Folders';

const Columns: React.FC = () => {
  const boardsModel = useContext(ModuleContext) as BoardsModel;

  return (
    <Container>
      <Column title="To do" cards={boardsModel.getItemValue('todo')} />
      <Column title="Doing" cards={boardsModel.getItemValue('doing')} openIcon />
      <Column title="Review (Wlingua)" cards={boardsModel.getItemValue('review')} openIcon />
      <Column title="Done" cards={[]}>
        <Folders />
      </Column>
      {boardsModel.loadingItem && <Loading />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export default Columns;
