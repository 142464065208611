import React from 'react';
import styled from 'styled-components';
import { IPanelEstructureElementQuoteSentence } from '../../../../types/panelEstructure';

export type IProps = {
  data: IPanelEstructureElementQuoteSentence;
};

const PanelBlockElementQuoteSentence: React.FC<IProps> = ({ data }) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: data.quoteSentence }} />;
};

export default PanelBlockElementQuoteSentence;

const Wrapper = styled.div`
  padding-left: 6px;
  font-size: 16px;
  font-style: italic;
`;
