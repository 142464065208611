import Button from '@material-ui/core/Button';
import { EditorGap } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import KLineSelect from 'components/selects/KlineSelect';
import React, { useState } from 'react';

type IProps = {
  handleAddKLine: (kLine: number) => void;
  onClose: () => void;
};

const NO_KLINE = 0;

const PanelKlinesAddModal: React.FC<IProps> = (props) => {
  const [kLine, setKLine] = useState(NO_KLINE);

  const handleChangeKLine = (kLineId: number) => {
    setKLine(kLineId);
  };

  const handleAddKLine = () => {
    props.handleAddKLine(kLine);
  };

  return (
    <ModalWrapper title={'Add KLine'} onClose={props.onClose} width={500} height={100}>
      <KLineSelect onChange={handleChangeKLine} />
      <EditorGap />
      <Button variant="contained" size="small" color="primary" onClick={handleAddKLine} disabled={kLine === NO_KLINE}>
        add
      </Button>
    </ModalWrapper>
  );
};

export default PanelKlinesAddModal;
