import { createAction } from 'store/helpers';
import { IModuleRequest } from 'types/module';

export enum ITabsActionTypes {
  NAMES_LOADED = 'TABS: NAMES_LOADED',
  OPENED = 'TABS: OPENED',
  CLOSED = 'TABS: CLOSED',
  CHANGED_ACTIVE = 'TABS: CHANGED_ACTIVE',
}

type ITabsActionPayloads = {
  [ITabsActionTypes.NAMES_LOADED]: { [key: string]: string };
  [ITabsActionTypes.OPENED]: IModuleRequest;
  [ITabsActionTypes.CLOSED]: string;
  [ITabsActionTypes.CHANGED_ACTIVE]: string;
};

export const tabsNamesLoaded = createAction<ITabsActionTypes.NAMES_LOADED, ITabsActionPayloads>(
  ITabsActionTypes.NAMES_LOADED,
);

export const tabsOpened = createAction<ITabsActionTypes.OPENED, ITabsActionPayloads>(ITabsActionTypes.OPENED);

export const tabsClosed = createAction<ITabsActionTypes.CLOSED, ITabsActionPayloads>(ITabsActionTypes.CLOSED);

export const tabsChangedActive = createAction<ITabsActionTypes.CHANGED_ACTIVE, ITabsActionPayloads>(
  ITabsActionTypes.CHANGED_ACTIVE,
);

export type ITabsActions =
  | ReturnType<typeof tabsNamesLoaded>
  | ReturnType<typeof tabsOpened>
  | ReturnType<typeof tabsClosed>
  | ReturnType<typeof tabsChangedActive>;
