import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { IConceptLinkerDetailsInfo } from '../../types/conceptLinker';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/rootReducer';
import ModuleContext from '../../CMFW/context/ModuleContext';
import { ICourseConfig } from '../../store/reducers/courseConfig';

type IProps = {
  details: IConceptLinkerDetailsInfo[];
};
const EMPTY_ARRAY: any[] = [];
export const CLDetails: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const courses = useSelector((state: IAppState) => state.courseConfig);
  const courseConfig = (courses[module.courseId] as ICourseConfig) ?? null;
  const dialectOrder = courseConfig ? courseConfig.dialectOrder : EMPTY_ARRAY;
  const dialectsSorted = useMemo(
    () => [...props.details].sort((a, b) => dialectOrder.indexOf(a.dialectId) - dialectOrder.indexOf(b.dialectId)),
    [props.details, dialectOrder],
  );
  return (
    <>
      {dialectsSorted.map((detail) => (
        <Container key={detail.dialectId}>
          <FontAwesomeIcon
            icon={detail.conceptLinker ? faCheck : faTimes}
            color={detail.conceptLinker ? 'green' : 'red'}
          />
          <Coverage>{detail.conceptLinkerCoverage}</Coverage>
          <Code>{detail.dialectCode}</Code>
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  font-size: 12px;
`;

const Code = styled.span``;

const Coverage = styled.span`
  margin-left: 2px;
  margin-right: 2px;
`;
