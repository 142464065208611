import { get } from 'services/request';

export type IBlockSelect = {
  id: number;
  block: string;
};

export default class BlocksModel {
  async get() {
    const blocks = await get<IBlockSelect[]>(`blocks`);
    return blocks.sort(function (a, b) {
      const keyA = a.block,
        keyB = b.block;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }
}
