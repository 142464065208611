import { del, get, post, put } from 'services/request';

type ICommentNew = {
  cardId: number;
  id: number;
  seen: boolean;
  text: string;
};

type ICommentUpdate = ICommentNew & {
  userName: string;
};

export type IComment = {
  id: number;
  userId: number;
  userName: string;
  text: string;
  sDate: string;
  rDate: string;
  seen: boolean;
  ownComment: boolean;
};

export default class CardCommentsModel {
  comments: IComment[] = [];
  cardId: number;

  constructor(cardId: number) {
    this.cardId = cardId;
  }

  async getCardComments(cardId: number) {
    return get<IComment[]>(`cards/${cardId}/comments`);
  }

  async addCardComment(comment: ICommentNew) {
    await post<IComment[]>(`cards/${comment.cardId}/comments`, comment);
    return this.getCardComments(comment.cardId);
  }

  async deleteCardComment(commentId: number, cardId: number) {
    await del(`comments/${commentId}`);
    return this.getCardComments(cardId);
  }

  async updateCardComment(comment: ICommentUpdate) {
    await put(`comments/${comment.id}`, comment);
    return this.getCardComments(comment.cardId);
  }
}
