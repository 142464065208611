import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import DialogModel from '../../../models/DialogModel';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { EditorGap } from '../../../components/common/styles';
import Button from '@material-ui/core/Button';
import TextEditor from '../../../CMFW/input/TextEditor';

const CK_EDITOR_CONFIG = {
  toolbar: [
    ['Undo', 'Redo'],
    ['Bold', 'Gap', 'RemoveFormat'],
  ],
  toolBarInline: [['Bold', 'Gap']],
};

const DialogSegmentAddScript: React.FC = () => {
  const module = useContext(ModuleContext) as DialogModel;
  const [openModal, setOpenModal] = useState(false);
  const [script, setScript] = useState('');

  const onAdd = () => {
    setOpenModal(false);
    module.addScript(script);
  };

  const onChange = (text: string) => {
    setScript(text);
  };

  return (
    <>
      <Wrapper onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPlus} color={'#bfbfbf'} />
        <ActionText>Script</ActionText>
      </Wrapper>
      {openModal && (
        <ModalWrapper title={'Script'} width={500} height={500} onClose={() => setOpenModal(false)}>
          <TextEditor
            keyName={'none'}
            text={script}
            config={CK_EDITOR_CONFIG}
            autoGrow={true}
            onChangeText={onChange}
          />
          <EditorGap />
          <Button variant="contained" size="small" color="primary" onClick={onAdd}>
            Add Script
          </Button>
        </ModalWrapper>
      )}
    </>
  );
};

export default DialogSegmentAddScript;

const Wrapper = styled.div`
  margin: 5px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #bfbfbf;
  background-color: #f5f5f5;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const ActionText = styled.span`
  padding-left: 10px;
`;
