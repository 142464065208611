import { IRefIds } from 'constants/refs';
import { get, put } from 'services/request';
import { BaseModel } from './BaseModel';
import { ITranslationStatus } from '../types/Translation';
import { IQAResponse, IQAResponseError } from './QAResultsModel';

export type ICATData = {
  id: number;
  projectTranslationId: number;
  itemId: number;
  ref: number;
  fromText: string;
  fromMemoryText: string;
  fromDialect: number;
  toText: string;
  toDialect: number;
  status: ITranslationStatus;
  error: string;
  similarity: number;
  updating: boolean;
  contextRef: number;
  contextItemId: number;
  QAErrors: IQAResponseError[] | null;
  AIReview: string;
};

export type ICATMemoryGuest = {
  id: number;
  course: number;
  text: string;
  similarity: number;
  fromText: string;
};

export default class CATModel extends BaseModel {
  data: ICATData[] = [];
  projectId = 0;
  ref = IRefIds.NONE;
  filterTo = '';
  filterFrom = '';
  filterPending = false;
  filterError = false;
  filterQA = false;
  sortTo = false;
  sortFrom = false;
  dataLoaded: ICATData[] = [];
  memoryActiveGuest: ICATMemoryGuest[] | null = null;
  activeId = -1;
  hasQASaved = false;
  async loadProject(projectId: number) {
    this.projectId = projectId;
    this.loadingItem = true;
    this.activeId = -1;
    this.dataLoaded = [];
    this.memoryActiveGuest = null;
    this.hasQASaved = false;
    this.render();
    this.dataLoaded = await get<ICATData[]>(`translations/projects/${projectId}/CAT`);
    this.dataLoaded = this.dataLoaded.map((d) => ({
      ...d,
      fromText: CATModel.cleanP(CATModel.targetToT(d.fromText)),
      toText: CATModel.cleanP(CATModel.targetToT(d.toText)),
      fromMemoryText: CATModel.cleanP(CATModel.targetToT(d.fromMemoryText)),
      updating: false,
      QAErrors: null,
    }));
    this.filter();
    await this.findNextActive();
    this.loadingItem = false;
    this.render();
  }

  async updateTarget(translationId: number, target: string, status: ITranslationStatus) {
    const row = this.getRow(translationId);
    let loadQA = false;

    if (row !== null) {
      if (row.toText !== target || row.status !== status) {
        loadQA = row.toText !== target;
        row.toText = target;
        row.status = status;
        row.updating = true;
        if (status === ITranslationStatus.TRANSLATION_DONE) {
          await this.findNextActive();
          loadQA = true;
        }
        this.render();
        await put(`/translations/${row.id}/refs/${row.ref}/dialects/${row.toDialect}`, {
          text: CATModel.tToTarget(target),
          status: status,
        });

        await put(`/translations/${row.id}/check`, {});
        if (loadQA) {
          await this.getQA(row);
        }

        await this.reloadRow(row.id);
        this.filter();
        this.render();
      } else if (row.status === ITranslationStatus.TRANSLATION_DONE) {
        await this.findNextActive();
      }
    }
  }

  async reloadRow(translationId: number) {
    const row = this.getRow(translationId);
    if (row) {
      const rowServer = await get<ICATData>(`/translations/projects/translations/${row.projectTranslationId}`);

      row.toText = CATModel.cleanP(CATModel.targetToT(rowServer.toText));
      row.status = rowServer.status;
      row.error = rowServer.error ?? '';
      row.fromMemoryText = CATModel.cleanP(CATModel.targetToT(rowServer.fromMemoryText));
      row.updating = false;
    }
  }

  async updateTranslationMemory(translationId: number, similarity: number, fromMemoryText: string) {
    const row = this.getRow(translationId);

    if (row !== null) {
      row.similarity = similarity;
      this.render();

      await put(`/translations/projects/translations/${row.projectTranslationId}`, {
        fromMemoryText,
        similarity: similarity,
      });

      await this.reloadRow(translationId);
      this.render();
    }
  }

  getRow(translationId: number): ICATData | null {
    return this.dataLoaded.find((d) => d.id === translationId) ?? null;
  }

  filter() {
    this.data = this.dataLoaded.filter((d) => {
      const toReg = new RegExp(this.escapeRegExp(this.filterTo), 'i');
      const fromReg = new RegExp(this.escapeRegExp(this.filterFrom), 'i');
      return (
        (this.filterTo === '' || d.toText.match(toReg)) &&
        (this.filterFrom === '' || d.fromText.match(fromReg)) &&
        (!this.filterPending || d.status !== ITranslationStatus.TRANSLATION_DONE) &&
        (!this.filterError || d.error !== '') &&
        (!this.filterQA || !this.hasQA() || !d.QAErrors || d.QAErrors.length > 0)
      );
    });
    if (this.sortTo || this.sortFrom) {
      this.data = this.data.sort((a, b) => {
        if (this.sortTo) {
          return a.toText.localeCompare(b.toText);
        }

        if (this.sortFrom) {
          return a.fromText.localeCompare(b.fromText);
        }
        return 0;
      });
    }
  }

  setFilter(from: string, to: string, filterPending: boolean, filterError: boolean, filterQA: boolean) {
    this.filterTo = to;
    this.filterFrom = from;
    this.filterPending = filterPending;
    this.filterError = filterError;
    this.filterQA = filterQA;
    this.filter();
    this.render();
  }

  sort(from: boolean, to: boolean) {
    this.sortTo = to;
    this.sortFrom = from;
    this.filter();
    this.render();
  }

  escapeRegExp(s: string): string {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  async findNextActive(): Promise<number> {
    let found = false;
    const noneActive = this.activeId === -1;
    const next = this.data.find((d) => {
      if (!noneActive && !found) {
        found = d.id === this.activeId;
        return false;
      } else {
        return d.status !== ITranslationStatus.TRANSLATION_DONE;
      }
    });
    if (next) {
      await this.setActive(next.id);
    }
    return this.activeId;
  }

  getActive(): ICATData | null {
    const next = this.data.find((d) => d.id === this.activeId);
    return next ?? null;
  }

  async setActive(id: number) {
    this.activeId = id;
    this.render();
    await Promise.all([this.getMemoryGuest(), this.getQA(this.getActive())]);
    this.render();
  }

  findRowByActive(): number {
    let row = 0;
    this.data.some((d, index) => {
      row = index;
      return d.id === this.activeId;
    });
    return row + 5;
  }

  async getMemoryGuest() {
    const active = this.getActive();
    if (active) {
      this.memoryActiveGuest = null;
      this.render();
      const result = await get<{ memory: ICATMemoryGuest[] }>(
        ` /translations/projects/translations/${active.projectTranslationId}/memory-guest`,
      );
      this.memoryActiveGuest = result.memory;
      if (this.memoryActiveGuest) {
        this.memoryActiveGuest = this.memoryActiveGuest.map((m) => ({
          ...m,
          fromText: CATModel.targetToT(m.fromText),
          text: CATModel.targetToT(m.text),
        }));
        if (active.toText === '' && this.memoryActiveGuest.length > 0 && this.memoryActiveGuest[0].similarity >= 100) {
          const currentActive = this.getActive();
          if (currentActive && currentActive.id === active.id) {
            await this.updateTranslationMemory(
              active.id,
              this.memoryActiveGuest[0].similarity,
              this.memoryActiveGuest[0].fromText,
            );
            await this.updateTarget(active.id, this.memoryActiveGuest[0].text, ITranslationStatus.TRANSLATION_DOING);
          }
        }
      }
    }
  }

  async getQA(row: ICATData | null) {
    if (row && this.hasQA(row.contextRef)) {
      row.QAErrors = null;
      this.render();
      const result = await get<IQAResponse[]>(
        `QA/groups/${this.courseId}/refs/${row.contextRef}/source-dialects/${row.toDialect}/ref-qa?ids=${row.itemId}`,
      );
      if (result.length > 0) {
        this.updateQAStatus([{ itemId: row.itemId, errors: result[0].errors }]);
      }
    }
  }

  hasQA(ref?: number) {
    if (ref) {
      this.hasQASaved = ref === IRefIds.sentences || ref === IRefIds.dialog || ref === IRefIds.reading;
    }
    return this.hasQASaved;
  }

  async replace(search: string, replace: string) {
    this.loadingItem = true;
    this.render();
    await put<{ memory: ICATMemoryGuest[] }>(` /translations/projects/${this.projectId}/search-replace`, {
      search,
      replace,
    });
    await this.loadProject(this.projectId);
  }

  async validateAll() {
    this.loadingItem = true;
    this.render();
    await put(`/translations/projects/${this.projectId}/validate-all`, {});
    await this.loadProject(this.projectId);
  }

  async checkFormatAll() {
    this.loadingItem = true;
    this.render();
    await put(`/translations/projects/${this.projectId}/check-format-all`, {});
    await this.loadProject(this.projectId);
  }

  updateQAStatus(data: { itemId: number; errors: IQAResponseError[] }[]) {
    const errors: IQAResponseError[][] = [];
    data.forEach((s) => {
      errors[s.itemId] = s.errors.filter((e) => e.verified !== 1);
    });

    this.dataLoaded.forEach((g) => {
      if (errors[g.itemId]) {
        g.QAErrors = errors[g.itemId];
      }
    });
    this.render();
  }

  getItemIds(): number[] {
    return this.dataLoaded.map((d) => d.itemId);
  }

  getContextRef() {
    if (this.dataLoaded.length > 0) {
      return this.dataLoaded[0].contextRef;
    }
  }

  getSourceDialect() {
    if (this.dataLoaded.length > 0) {
      return this.dataLoaded[0].toDialect;
    }
  }

  static cleanP(text: string): string {
    return text.replace(/<(\/)*p>/gm, '');
  }

  static tToTarget(text: string): string {
    return text.replace(/<(\/*)t[^>]*>/g, '<$1target>').replace(/<(\/*)g[^>]*>/g, '<$1glossary>');
  }

  static targetToT(text: string): string {
    return text.replace(/<(\/*)target[^>]*>/g, '<$1t>').replace(/<(\/*)glossary[^>]*>/g, '<$1g>');
  }
}
