import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type ISourceAdaptationQARef = {
  ref: IRefIds;
  name: string;
};

export default class QAModel extends BaseModel<IGridData, ISourceAdaptationQARef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const qas = await get<ISourceAdaptationQARef[]>(`QA/qa-refs`);
    await this.setItem(qas);
    this.render();
  }
}
