import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { IPosition } from '../types/content';

export type ICLUtilsPanel = {
  id: number;
  position: IPosition;
  kLines: string;
  kLineIds: number[];
  title: string;
  concept: string;
  nUses: number;
};
type ICLUtilsPanelSearch = IModuleSearch & {
  lessonPos: number;
};
export default class CLUtilsPanelModel extends BaseModel<ICLUtilsPanel, {}> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.panels;
    this.gridName = 'CLUtilsPanel';
    this.getGridColumns();
  }

  async getGrid(data: ICLUtilsPanelSearch) {
    await super.getGrid();
    this.gridData = await get<ICLUtilsPanel[]>(`groups/${this.courseId}/panels-cl-utils`, data);

    this.loadingGrid = false;

    this.render();
  }

  setItemId(itemId: number) {
    this.itemId = itemId;
  }
}
