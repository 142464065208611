import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

type IProps = {
  spinnerSize?: number;
};

export const Loading: React.FC<IProps> = (props) => {
  return (
    <Container>
      <CircularProgress size={props.spinnerSize} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3;
`;
