import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { positionToString } from 'helpers/positon';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { IPosition } from 'types/content';

type IProps = {
  keyName?: string;
};

const DEFAULT_VALUE: IPosition[] = [];

const Positions: React.FC<IProps> = ({ keyName = 'flag' }) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: IPosition[] = module.getItemValue.bind(module)(aggregatedKeyName) || DEFAULT_VALUE;

  return <Wrapper>{moduleItemValue.map((value) => positionToString(value)).join(' - ')}</Wrapper>;
};

const Wrapper = styled.div``;

export default Positions;
