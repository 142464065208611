import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import ReadingExerciseNew from './ReadingExerciseNew';
import ReadingExerciseListExercise from './ReadingExerciseListExercise';
import { FormControlLabel, Switch } from '@material-ui/core';
import { EditorRow } from '../../../components/common/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ReadingModel from '../../../models/ReadingModel';
import { IReadingEdit } from '../../../types/reading';

export type IProps = {
  onEditExercise: (exerciseId: number) => void;
};

const ReadingExerciseList: React.FC<IProps> = (props) => {
  const [dnd, setDnd] = useState(false);
  const [editExerciseId, setEditExerciseId] = useState(0);
  const module = useContext(ModuleContext) as ReadingModel;
  const item = module.itemUpdated as IReadingEdit;

  const onEditExercise = (exerciseId: number) => {
    props.onEditExercise(exerciseId);
    setEditExerciseId(exerciseId);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={module.onDragEndExercise.bind(module)}>
        <Droppable isDropDisabled={!dnd} droppableId={'Reading'} direction={'vertical'}>
          {(provided) => (
            <DropWrapper {...provided.droppableProps} ref={provided.innerRef}>
              {item.exercises.map((exercise, index) => (
                <ReadingExerciseListExercise
                  exercise={exercise}
                  index={index}
                  dnd={dnd}
                  editing={exercise.id === editExerciseId}
                  key={`readingExercise-${exercise.id}`}
                  onEditExercise={onEditExercise}
                />
              ))}
            </DropWrapper>
          )}
        </Droppable>
      </DragDropContext>
      <EditorRow>
        <ReadingExerciseNew />
        <FormControlLabel
          control={
            <Switch
              checked={dnd}
              onChange={() => setDnd(!dnd)}
              color="primary"
              size="small"
              disabled={item.exercises.length < 2}
            />
          }
          label="DND"
        />
      </EditorRow>
    </Wrapper>
  );
};

export default ReadingExerciseList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 240px;
`;

const DropWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
