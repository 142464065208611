export function freelanceRenderer(param: { value?: string | boolean }) {
  let value = param.value;

  if (typeof value === 'string') {
    value = value === 'true';
  }

  if (value === true) {
    return 'FL';
  }
  return '';
}
