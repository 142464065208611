import { IRefIds } from 'constants/refs';
import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITranslationProject, ITranslationProjectEdit } from '../types/translationProject';

export default class TranslationProjectModel extends BaseModel<ITranslationProject, ITranslationProjectEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.translation;
    this.gridName = 'TranslationProjects';
    this.getGridColumns();
  }

  public getCourseId() {
    return this.courseId;
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<ITranslationProject[]>(`courses/${this.courseId}/translations/projects`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ITranslationProject>(`courses/${this.courseId}/translations/projects/${itemId}/grid-row`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await this.updateItem();
  }

  async updateItem() {
    const updated = this.itemUpdated as ITranslationProjectEdit;
    const { id } = updated;
    await put(`translations/projects/${id}`, updated);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async delete() {
    await super.delete();
    const { id } = this.itemUpdated;
    const result = await del(`translations/projects/${id}`);
    this.hide();
    this.loadingItem = false;
    this.render();
    if (result.ok) {
      await this.updateGridRow(id, 'remove');
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<ITranslationProjectEdit>(`translations/projects/${itemId}`);
    await this.setItem(item);
    this.render();
  }

  async reloadItem() {
    await this.loadItemEdit(this.itemId);
  }
}
