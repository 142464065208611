import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheAdd } from 'store/actions/cache';
import { IAppState } from 'store/reducers/rootReducer';

export const useCacheData = <T extends unknown[]>(
  cacheKey: string,
  fetchData: () => Promise<T>,
  sort?: (a: any, b: any) => number,
): T | null => {
  const dispatch = useDispatch();
  const cacheData = useSelector((state: IAppState) => state.cache.items[cacheKey]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setFetching(true);
      const result = await fetchData();
      setFetching(false);
      dispatch(cacheAdd({ [cacheKey]: result.sort(sort) }));
    };
    if (cacheData === undefined && !fetching) {
      fetch();
    }
  }, [cacheData, dispatch, cacheKey, fetchData, sort]);

  return cacheData || null;
};
