import blueGrey from '@material-ui/core/colors/blueGrey';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  tabNames: string[];
};

export const ItemTabs: React.FC<IProps> = (props) => {
  const { tabNames, children } = props;

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, index: number) => {
      setActiveTabIndex(index);
    },
    [setActiveTabIndex],
  );

  if (!children || !Array.isArray(children)) {
    console.error('More than one child needs to be contained');
    return null;
  }

  if (tabNames.length !== children.filter(Boolean).length) {
    throw new Error('Number of names not equal to children');
  }

  return (
    <Container>
      <TabsStyled
        value={activeTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabNames.map((tab) => (
          <Tab key={tab} label={<span>{tab}</span>} />
        ))}
      </TabsStyled>
      {children.filter(Boolean).map((child, index) => (
        <ModulesContainer key={tabNames[index]} style={{ display: activeTabIndex === index ? undefined : 'none' }}>
          {child}
        </ModulesContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  overflow: auto;
  & .MuiTabs-root {
    flex-shrink: 0;
    height: 24px;
    min-height: 0;
  }
`;

const TabsStyled = styled(Tabs)`
  background-color: ${blueGrey[50]};
  & .MuiTab-root {
    padding: 0 6px;
    max-width: none;
    min-height: 0;
    min-width: 0 !important;
  }
`;

const ModulesContainer = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;
