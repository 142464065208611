import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TrainerModel from '../../../models/TrainerModel';

const TrainerSegmentNew: React.FC = () => {
  const module = useContext(ModuleContext) as TrainerModel;

  const onNew = () => {
    module.newSegment();
  };

  return (
    <Wrapper onClick={onNew}>
      <FontAwesomeIcon icon={faPlus} color={'#bfbfbf'} />
    </Wrapper>
  );
};

export default TrainerSegmentNew;

const Wrapper = styled.div`
  margin: 10px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #bfbfbf;
  background-color: #f5f5f5;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
