import { get, put } from 'services/request';
import { IItemId } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITranslation, ITranslationStatus } from '../types/Translation';
import { IAIReviewEdit } from '../types/aiReview';
import CATModel, { ICATMemoryGuest } from './CATModel';

export type ITrans = IItemId & {
  ref: IRefIds;
  refName: string;
  error: boolean;
};

type ITranslationEdit = IItemId & {
  ref: IRefIds;
  itemId: number;
  openRef: IRefIds;
  openRefName: string;
  openItemId: number;
  dialects: ITranslation[];
  aiReview: IAIReviewEdit;
};

export default class TranslationModel extends BaseModel<ITrans, ITranslationEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.translation;
    this.gridName = 'Translations';
    this.getGridColumns();
  }

  async getGridSearch(text: string, dialect: number, ref: IRefIds, course: number) {
    await super.getGrid();
    this.render();

    this.gridData = await get<ITrans[]>(`courses/${course}/translations?dialect=${dialect}&text=${text}&ref=${ref}`);

    this.loadingGrid = false;
    this.render();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGridRow(itemId: number) {
    return await get<ITrans>(`translations/${itemId}/grid-row`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const itemToUpdate = this.itemUpdated as ITranslationEdit;
    const { id, ref } = itemToUpdate;

    const dialects = this.getItemValue('dialects');

    const dialectsToUpdate = itemToUpdate.dialects
      .filter((d) => d.status !== ITranslationStatus.TRANSLATION_FROM_TARGET)
      .filter((d: ITranslation, index: number) => JSON.stringify(d) !== JSON.stringify(dialects[index]));

    await Promise.all(
      dialectsToUpdate.map((t: ITranslation) => put(`/translations/${id}/refs/${ref}/dialects/${t.dialectId}`, t)),
    );

    await put(`/translations/${id}/check`, {});

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, dialects] = await Promise.all([
      get<ITranslationEdit>(`translations/${itemId}`),
      get<ITranslation[]>(`translations/${itemId}/dialects`),
    ]);

    await this.setItem({
      ...item,
      dialects,
    });

    this.render();
  }

  async search(data: { text: string; dialect: number; ref: IRefIds; course: number }) {
    await this.getGridSearch(data.text || '', data.dialect, data.ref, data.course ?? this.courseId);
  }

  async checkTranslations() {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/translations/check`);
    this.gridData = await get<ITrans[]>(`courses/${this.courseId}/translations/with-error`);
    this.loadingGrid = false;
    this.render();
  }

  async guestMemory(dialectId: number) {
    const itemToUpdate = this.itemUpdated as ITranslationEdit;
    const { id } = itemToUpdate;

    const memory = await get<ICATMemoryGuest[]>(`/translations/${id}/dialects/${dialectId}/memory-guest`);

    this.itemUpdated.dialects.map((dialect: ITranslation) => {
      if (dialect.dialectId === dialectId) {
        dialect.memory = memory.map((m) => ({
          ...m,
          fromText: CATModel.targetToT(m.fromText),
          text: CATModel.targetToT(m.text),
        }));
      }
    });

    this.render();
  }

  async updateFromGuestMemory(text: string, dialectId: number) {
    const itemToUpdate = this.itemUpdated as ITranslationEdit;

    itemToUpdate.dialects.map((dialect: ITranslation) => {
      if (dialect.dialectId === dialectId) {
        dialect.text = CATModel.tToTarget(text);
      }
    });

    this.render();
  }
}
