import { ModalWrapper } from 'components/ModalWrapper';
import React from 'react';
import Estructure from '../../panels/tabs/Estructure';

type IProps = {
  onClose: () => void;
};

const PanelViewModal: React.FC<IProps> = (props) => {
  return (
    <ModalWrapper title="Panel" width={700} height={700} onClose={props.onClose}>
      <Estructure />
    </ModalWrapper>
  );
};

export default PanelViewModal;
