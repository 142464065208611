import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import CATModel, { ICATData } from '../../../models/CATModel';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { IRefIds } from '../../../constants/refs';
import Estructure from '../../panels/tabs/Estructure';
import CATPanel from './CATPanel';
import CATQA from './CATQA';
import CATContextSentence from './CATContextSentence';
import CATContextGlossary from './CATContextGlossary';
import CATContextDialog from './CATContextDialog';
import CATContextReading from './CATContextReading';

export type IProps = {};

const CATContext: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [row, setRow] = useState<ICATData | null>(null);

  useEffect(() => {
    const activeRow = module.getActive();
    setRow(activeRow);
  }, [module, module.activeId]);

  const renderContext = () => {
    if (!row) {
      return null;
    } else {
      switch (row.contextRef) {
        case IRefIds.panels:
          return (
            <CATPanelStyled title={'Context'}>
              <Estructure panelId={row.contextItemId} />
            </CATPanelStyled>
          );
        case IRefIds.glossaries:
          return (
            <CATPanelStyled title={'Glossary'}>
              <CATContextGlossary />
            </CATPanelStyled>
          );
        case IRefIds.sentences:
          return (
            <>
              <CATPanelStyled title={'QA'}>
                <CATQA />
              </CATPanelStyled>
              <CATPanelStyled title={'Sentence'}>
                <CATContextSentence />
              </CATPanelStyled>
            </>
          );
        case IRefIds.dialog:
          return (
            <>
              <CATPanelStyled title={'QA'}>
                <CATQA />
              </CATPanelStyled>
              <CATPanelStyled title={'Dialog'}>
                <CATContextDialog />
              </CATPanelStyled>
            </>
          );
        case IRefIds.reading:
          return (
            <>
              <CATPanelStyled title={'QA'}>
                <CATQA />
              </CATPanelStyled>
              <CATPanelStyled title={'Reading'}>
                <CATContextReading />
              </CATPanelStyled>
            </>
          );
        default:
          return null;
      }
    }
  };

  return renderContext();
};

export default CATContext;

const CATPanelStyled = styled(CATPanel)`
  & p {
    font-size: 14px !important;
  }
`;
