export enum IFields {
  position = 'position',
  notes = 'notes',
  notesStatus = 'notesStatus',
  flag = 'flag',
  error = 'error',
  level = 'level',
  conceptLinkerStatus = 'conceptLinkerStatus',
  nLockedVoc = 'nLockedVoc',
  nLockedByVoc = 'nLockedByVoc',
  nLockedPanel = 'nLockedPanel',
  nLockedByPanel = 'nLockedByPanel',
  nTestSentences = 'nTestSentences',
  nAutoCompounds = 'nAutoCompounds',
  stateTestSentences = 'stateTestSentences',
  stateSentences = 'stateSentences',
  stateExplanations = 'stateExplanations',
  itemWithSource = 'itemWithSource',
  overwrite = 'overwrite',
  listening = 'listening',
  freelance = 'freelance',
  statusRecording = 'statusRecording',
  status = 'status',
  state = 'state',
  nUses = 'nUses',
  stateContent = 'stateContent',
  showToUser = 'showToUser',
  orig = 'orig',
  verified = 'verified',
  aiReview = 'aiReview',
  svg2 = 'svg2',
  nSegments = 'nSegments',
  nReadingExercises = 'nReadingExercises',
  nTrainerSegments = 'nTrainerSegments',
  nExercises = 'nExercises',
  none = 'none',
  review = 'review',
  done = 'done',
  illustration = 'illustration',
  illustrationAiScore = 'illustrationAiScore',
}
