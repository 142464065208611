import { Button, CircularProgress } from '@material-ui/core';
import { EditorRow } from 'components/common/styles';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import AudioSetsModel from '../../../models/AudioSetsModel';
import TtsVoiceSelect from '../../../components/selects/TtsVoiceSelect';

type IProps = {
  onClose: () => void;
  readBy: 'm' | 'f' | 'mf';
  ssmlCode: string;
};

const AudioTTS: React.FC<IProps> = (props) => {
  const [voiceNameM, setVoiceNameM] = useState('');
  const [voiceNameF, setVoiceNameF] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const moduleModel = useContext(ModuleContext) as AudioSetsModel;

  const handleVoiceChosenM = (voice: string) => {
    setVoiceNameM(voice);
  };

  const handleVoiceChosenF = (voice: string) => {
    setVoiceNameF(voice);
  };

  const handleGenerateClick = async () => {
    setIsGenerating(true);
    await moduleModel.generateTTS(voiceNameM, voiceNameF);
    props.onClose();
  };

  const isButtonEnabled = () => {
    let voiceSelected = false;
    switch (props.readBy) {
      case 'f':
        voiceSelected = voiceNameF !== '';
        break;
      case 'm':
        voiceSelected = voiceNameM !== '';
        break;
      case 'mf':
        voiceSelected = voiceNameM !== '' && voiceNameF !== '';
        break;
    }
    return voiceSelected && !isGenerating;
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'TTS'} width={320} height={200}>
      {props.readBy.includes('m') && (
        <EditorRowStyled>
          <TtsVoiceSelect filter={[props.ssmlCode, '- Male']} label={'Male'} onChange={handleVoiceChosenM} />
        </EditorRowStyled>
      )}
      {props.readBy.includes('f') && (
        <EditorRowStyled>
          <TtsVoiceSelect filter={[props.ssmlCode, '- Female']} label={'Female'} onChange={handleVoiceChosenF} />
        </EditorRowStyled>
      )}
      <EditorRowStyled>
        <Button variant="contained" size="small" disabled={!isButtonEnabled()} onClick={handleGenerateClick}>
          Generate
        </Button>
        {isGenerating && <CircularProgress size={20} />}
      </EditorRowStyled>
    </ModalWrapper>
  );
};

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  margin-top: 10px;
`;

export default AudioTTS;
