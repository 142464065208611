import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow } from 'components/common/styles';
import SearchBarDialect from 'components/search/SearchBarDialect';
import { VocCategorySelect } from 'components/selects/VocCategorySelect';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import VocSearchHelpModal from './VocSearchHelpModal';

type IProps = {
  handleDialectChange?(dialect: string): void;
};

const VocSearchBar: React.FC<IProps> = (props) => {
  const [keyCategories, setKeyCategories] = useState(new Date().getTime());
  const [keyText, setKeyText] = useState(new Date().getTime());
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const module = useContext(ModuleContext);

  const onChangeCategory = (catId: number) => {
    if (catId >= 0) {
      module.search({ catId });
    }
    setKeyText(new Date().getTime());
  };

  const onSearchText = () => {
    setKeyCategories(new Date().getTime());
  };

  const onHelp = () => {
    setIsHelpModalOpen(true);
  };

  const renderModals = () => {
    if (isHelpModalOpen) {
      return <VocSearchHelpModal onClose={() => setIsHelpModalOpen(false)} />;
    }
  };

  return (
    <>
      <EditorRow>
        <VocCategorySelectStyled key={keyCategories} onChange={onChangeCategory} />
      </EditorRow>
      <Container>
        <SearchBarDialectStyled titleOption key={keyText + 'cat'} onSearch={onSearchText} />
        <HelpButton onClick={onHelp} icon={faQuestionCircle} />
      </Container>
      {renderModals()}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const VocCategorySelectStyled = styled(VocCategorySelect)`
  margin-left: 10px;
`;

const SearchBarDialectStyled = styled(SearchBarDialect)`
  flex-grow: 1;
`;

const HelpButton = styled(FontAwesomeIcon)`
  padding: 7px;
  padding-left: 9px;
  cursor: pointer;
`;

export default VocSearchBar;
