import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import PanelsTitleModel from '../../models/PanelsTitleModel';
import Translations from '../../components/translation/Translations';
import { TabDialects } from '../../CMFW/layout/TabDialects';
import { IRefIds } from '../../constants/refs';

export type IProps = {
  courseId: number;
};

const PanelTitle: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const panelsTitleModel = useMemo(() => new PanelsTitleModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={panelsTitleModel}>
        <Split widthLeft={500}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <TabDialects keyName={'targets'}>
                <Wrapper>
                  <Translations keyName={'titles'} refId={IRefIds.panelTitle} />
                </Wrapper>
              </TabDialects>
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default PanelTitle;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
