import React from 'react';
import styled from 'styled-components';
import { ILessonOutlineCycleActivityDialog } from '../../../../types/lessonOutline';
import { positionLessonToString } from '../../../../helpers/positon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { CLIconWrapper } from './LessonOutlineActivityVoc';

export type IProps = {
  activity: ILessonOutlineCycleActivityDialog;
};

const LessonOutlineActivityDialog: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      {props.activity.repeated && (
        <CLIconWrapper>
          <FontAwesomeIcon icon={faExclamation} />
        </CLIconWrapper>
      )}
      {props.activity.position && <span>{positionLessonToString(props.activity.position.lesson)} - </span>}
      <span>{props.activity.title}</span>
    </Wrapper>
  );
};

export default LessonOutlineActivityDialog;

const Wrapper = styled.div`
  background-color: #f7daff;
  padding: 4px 2px;
  border-radius: 4px;
`;
