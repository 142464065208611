import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { DropResult } from 'react-beautiful-dnd';
import {
  IBook,
  IBookChapter,
  IBookChapterTextId,
  IBookChapterEdit,
  IBookChapterText,
  IBookChapterId, IBookEdit,
} from '../types/book';
import * as lodash from 'lodash';
import BookModel from './BookModel';

export default class BookChapterModel extends BaseModel<IBookChapter, IBookChapterEdit> {
  bookModel: BookModel | null = null;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.bookChapter;
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IBookChapterEdit;
    const itemId = itemToSave.id;

    await put<IBook>(`/books/chapters/${itemId}`, { ...itemToSave });

    await Promise.all([this.reloadBookModel(), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IBookChapterEdit;
    const result = await post<IBookChapterId>(
      `books/${this.bookModel?.itemId}/chapters/new`,
      lodash.omit(itemToCreate, ['chapters']),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.reloadBookModel(), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`books/chapters/${itemId}`);
    this.itemUpdated = null;
    await this.reloadBookModel();
  }

  async getTexts(itemId: number) {
    const texts = await get<IBookChapterText[]>(`books/chapters/${itemId}/texts`);
    return texts.sort((a, b) => a.position - b.position);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, texts] = await Promise.all([get<IBookChapterEdit>(`books/chapters/${itemId}`), this.getTexts(itemId)]);

    item.texts = texts.sort((a, b) => a.position - b.position);
    await this.setItem(item);
    this.render();
  }

  public async newText() {
    this.loadingItem = true;
    this.render();
    const bookItem = this.itemUpdated as IBookChapterEdit;
    await post<IBookChapterTextId>(`books/chapters/${bookItem.id}/texts/new`, {});
    await this.loadItemEdit(bookItem.id);
    this.loadingItem = false;
    this.render();
  }

  public async onDragEndText(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const textId = parseInt(draggableId);
    await this.moveText(textId, source.index, destination.index);
  }

  private async moveText(textId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as IBookChapterEdit;
    this.arrayMove(item.texts, prevPos, newPos);
    this.render();
    await put(`books/chapters/texts/${textId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
    await this.forceReloadBookChapterTexts();
  }

  async forceReloadBookChapterTexts() {
    const texts = await this.getTexts(this.itemId);
    await this.setItem({ ...this.itemUpdated, texts });
    this.render();
  }

  private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }

  async reloadBookModel() {
    if (this.bookModel) {
      await this.bookModel.forceReloadBookChapters.bind(this.bookModel)();
    }
  }
}
