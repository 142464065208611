import ModuleContext from 'CMFW/context/ModuleContext';
import { LEVEL_COLORS } from 'constants/levelColors';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const LEVELS = 5;

export type IProps = {
  keyName: string;
};

const PanelLevelIndicator: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext);

  const [currentLevel, setCurrentLevel] = useState(moduleModel.getItemUpdatedValue(props.keyName));

  const handleLevelClicked = (level: number) => {
    setCurrentLevel(level);
    moduleModel.updateItemValue(props.keyName, level);
  };

  return (
    <Container>
      {[...new Array(LEVELS)].map((nothing, index) => (
        <Level
          key={index}
          $isActive={currentLevel === index + 1}
          $color={LEVEL_COLORS[index]}
          onClick={() => handleLevelClicked(index + 1)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  margin: 4px;
  vertical-align: middle;
  white-space: nowrap;
`;

const Level = styled.div<{ $isActive: boolean; $color: string }>`
  display: inline-block;
  opacity: ${(props) => (props.$isActive ? 1 : 0.15)};
  margin: 1px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: ${(props) => props.$color};
`;

export default PanelLevelIndicator;
