import InflectTablesModel, { IInflectIdName, IInflectTableCell } from 'models/InflectTablesModel';
import { get, put } from 'services/request';
import { IModelCourseOptions } from './BaseModel';
import { IRefIds } from '../constants/refs';
import BaseInflectsModel, { IInflectBase } from './BaseInflectsModel';

export enum IConjugationState {
  NO_CONJ,
  REGULAR,
  CHANGE,
  IRREGULAR,
  LOCK_NO_CONJ,
}

export type IInflect = IInflectBase & {
  word: string;
  conjWord: string;
  conjState: IConjugationState;
  conjLockState: IConjugationState;
};

export type IInflectSource = IInflectBase & {
  word: string;
};

type IInflectWordClass = {
  wordClass: number;
};

export type IInflectId = Partial<IInflect> & {
  inflectId: number;
};

export type IInflectValue = {
  inflectId: number;
  value: string;
};

export type IInflectTableCellAugmented = IInflectTableCell & {
  values: IInflectBase[];
  rowHeader: IInflectIdName;
  columnHeader?: IInflectIdName;
};

export type IInflectTable = IInflectIdName & {
  inflects: IInflectTableCellAugmented[];
};

type IInflectConstructor = IModelCourseOptions & {
  courseId: number;
  targetDialectId?: number;
  ref: IRefIds;
  refId: number;
  isSource?: boolean;
};

export default class InflectsModel extends BaseInflectsModel<IInflect | IInflectSource> {
  targetDialectId: number;
  ref: number;
  refId: number;
  inflectTablesModel!: InflectTablesModel;
  isSource: boolean;
  postPath: string;

  constructor(options: IInflectConstructor) {
    super(options);
    this.courseId = options.courseId;
    this.targetDialectId = options.targetDialectId ?? 0;
    this.ref = options.ref;
    this.refId = options.refId;
    this.isSource = !!options.isSource;
    this.postPath = options.isSource ? '-source' : '';
    this.loadItemEdit(this.refId);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const wordClass = await get<IInflectWordClass>(`refs/${this.ref}/ref-ids/${this.refId}/inflects-wordClass`);
    this.inflectTablesModel = new InflectTablesModel({
      courseId: this.courseId,
      wordClass: wordClass.wordClass,
      targetDialectId: this.targetDialectId,
    });
    await Promise.all([
      this.inflectTablesModel.getInflectTables(),
      this.inflectTablesModel.getInflectTableHeaders(),
      this.inflectTablesModel.getInflectTableCells(),
    ]);

    const items = await get<IInflect[]>(
      `refs/${this.ref}/ref-ids/${this.refId}/dialects/${this.targetDialectId}/inflects${this.postPath}`,
    );

    await this.setItem(this.fillInflects(items));
    this.loadingItem = false;
    this.render();
  }

  async save() {
    this.loadingItem = true;
    this.render();
    await put(`refs/${this.ref}/ref-ids/${this.refId}/dialects/${this.targetDialectId}/inflects${this.postPath}`, {
      inflects: JSON.stringify(this.itemUpdated),
    });
    await this.loadItemEdit(this.refId);
  }

  async autoInflect() {
    this.loadingItem = true;
    this.render();
    await put(`refs/${this.ref}/ref-ids/${this.refId}/dialects/${this.targetDialectId}/auto-inflect${this.postPath}`, {
      inflects: '',
    });
    await this.loadItemEdit(this.refId);
  }

  protected createEmptyItem(inflectId: number, index = 0): IInflect | IInflectSource {
    if (this.isSource) {
      return {
        index,
        inflectId,
        word: '',
      };
    } else {
      return {
        index,
        inflectId,
        conjLockState: IConjugationState.NO_CONJ,
        conjState: IConjugationState.NO_CONJ,
        conjWord: '',
        word: '',
      };
    }
  }

  isInflectEmpty(item: IInflect): boolean {
    return item.word === '';
  }
}
