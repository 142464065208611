import { IValidRefs, tooltipLoader } from 'components/conceptLink/TooltipLoader';
import { getClTooltipCacheKey } from 'helpers/cache';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IClData } from 'store/reducers/cache';
import { IAppState } from 'store/reducers/rootReducer';

export function useTooltipData(tooltip: {
  ref: IValidRefs;
  id: number;
  courseId: number;
  targetDialectId: number;
}): IClData | null {
  const { ref, id, courseId, targetDialectId } = tooltip;

  const cachedCl = useSelector((state: IAppState) => state.cache.cl);

  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (!cancelled && tooltipLoader) {
        tooltipLoader.loadTooltips(ref, [id], courseId, targetDialectId);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [courseId, id, ref, targetDialectId]);

  return cachedCl[getClTooltipCacheKey(ref, courseId, id)];
}
