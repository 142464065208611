import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IDialectAware } from 'types/content';
import { TabsDialectAware } from './TabsDialectAware';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers/rootReducer';
import { ICourseConfig } from '../../store/reducers/courseConfig';

type IProps = {
  keyName: string;
  className?: string;
};

const EMPTY_ARRAY: any[] = [];

export const TabDialects: React.FC<IProps> = (props) => {
  const [selectedDialect, setSelectedDialect] = useState(0);

  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: IDialectAware[] = module.getItemValue.bind(module)(aggregatedKeyName) || EMPTY_ARRAY;

  const courses = useSelector((state: IAppState) => state.courseConfig);

  const courseConfig = (courses[module.courseId] as ICourseConfig) ?? null;
  const dialectOrder = courseConfig ? courseConfig.dialectOrder : EMPTY_ARRAY;

  const indexes = moduleItemValue.map((v) => v.dialectId);

  const dialectsSorted: IDialectAware[] = useMemo(
    () =>
      [...moduleItemValue]
        .sort((a, b) => dialectOrder.indexOf(a.dialectId) - dialectOrder.indexOf(b.dialectId))
        .map((v) => ({
          dialectId: v.dialectId,
          dialectCode: v.dialectCode,
        })),
    [moduleItemValue, dialectOrder],
  );
  useEffect(() => {
    if (selectedDialect === 0) {
      setSelectedDialect(dialectsSorted[0].dialectId);
    }
  }, [dialectsSorted, selectedDialect]);

  const handleSelectedDialect = (event: any, newSelectedDialect: number) => {
    if (newSelectedDialect !== null) {
      setSelectedDialect(newSelectedDialect);
    }
  };

  const getDialectIndex = (): number => {
    return indexes.indexOf(selectedDialect);
  };

  if (selectedDialect === 0) {
    return null;
  }
  return (
    <TabsDialectAware
      dialects={dialectsSorted}
      selectedDialect={selectedDialect}
      handleSelectedDialect={handleSelectedDialect}
      className={props.className}
    >
      <KeyContext.Provider value={createKeyPath(parentKey, props.keyName, getDialectIndex())}>
        {props.children}
      </KeyContext.Provider>
    </TabsDialectAware>
  );
};
