import ModuleContext from 'CMFW/context/ModuleContext';
import { EditorRow, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import BoardsModel from 'models/BoardsModel';
import BoardSelect from 'modules/boards/BoardSelect';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Columns from './Columns';

export type IProps = {
  courseId: number;
};

const Boards: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const boardsModel = useMemo(() => new BoardsModel({ render: forceRender, courseId }), [courseId, forceRender]);

  return (
    <Wrapper>
      <ModuleContext.Provider value={boardsModel}>
        <EditorRowStyled>
          <BoardSelect />
        </EditorRowStyled>
        <Columns />
      </ModuleContext.Provider>
    </Wrapper>
  );
};

const Wrapper = styled(ModuleContainer)`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5px;
`;

const EditorRowStyled = styled(EditorRow)`
  width: 100%;
`;

export default Boards;
