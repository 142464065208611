import ModuleContext from 'CMFW/context/ModuleContext';
import { useContext, useState } from 'react';

/**
 * Keeps a state value in sync with module item changes.
 * @param moduleItemValue
 * @param initialValue
 * @param isLinked
 */
export const useCurrentItemValue = <T>(
  moduleItemValue: T,
  initialValue: T,
  isLinked = true,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const module = useContext(ModuleContext);
  const moduleUniqueId = module.getItemUniqueId();

  const [currentValue, setCurrentValue] = useState(initialValue);
  const [uniqueId, setUniqueId] = useState(0);

  if (isLinked && uniqueId !== moduleUniqueId) {
    setCurrentValue(moduleItemValue);
    setUniqueId(moduleUniqueId);
  }

  return [currentValue, setCurrentValue];
};
