import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import GuessedPanel from './GuessedPanel';
import GuessedVoc from './GuessedVoc';
import { IConceptLinkerCompound } from './TooltipCompoundsModel';

type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const GuessedCompound: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;

  const data = useTooltipData({
    ref: IRefIds.compounds,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerCompound;

  if (!data) {
    return null;
  }

  const { items } = data;

  const renderItems = () =>
    items.map((item) => {
      switch (item.ref) {
        case IRefIds.vocs:
          return (
            <GuessedVoc
              key={item.ref + '.' + item.refId}
              id={item.refId}
              targetDialectId={targetDialectId}
              courseId={courseId}
            />
          );
        case IRefIds.panels:
          return (
            <GuessedPanel
              key={item.ref + '.' + item.refId}
              id={item.refId}
              targetDialectId={targetDialectId}
              courseId={courseId}
            />
          );
        default:
          return null;
      }
    });

  return <>{renderItems()}</>;
};

export default GuessedCompound;
