import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerRight, EditorRow, FormContainer, TextFieldStyled } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import Grid from '../../../CMFW/grid/Grid';
import { IGridData } from '../../../types/models';
import TranslationDashboardModel, {
  ITranslationDashboardCell,
  ITranslationDashboardGrid,
} from '../../../models/TranslationDashboardModel';
import { translationProgressRenderer } from '../../../CMFW/grid/cellRenderers/translationProgressRenderer';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers/rootReducer';
import TranslationDashboardCreateProjectModal from '../modals/TranslationDashboardCreateProjectModal';
import { IRefIds } from '../../../constants/refs';

export type IProps = {
  courseId: number;
};

const DEFAULT_START_POS = 1;
const DEFAULT_END_POS = 1000;

const TranslationDashboard: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new TranslationDashboardModel({ render: forceRender, courseId }), [
    forceRender,
    courseId,
  ]);

  const sourceDialects = useSelector((state: IAppState) => state.courseConfig[moduleModel.courseId].sourceDialects);

  const [startPos, setStartPos] = React.useState(DEFAULT_START_POS);
  const [endPos, setEndPos] = React.useState(DEFAULT_END_POS);
  const [startPosLoad, setStartPosLoad] = React.useState(DEFAULT_START_POS);
  const [endPosLoad, setEndPosLoad] = React.useState(DEFAULT_END_POS);

  const [createProjectRef, setCreateProjectRef] = React.useState<IRefIds | null>(null);
  const [createProjectDialect, setCreateProjectDialect] = React.useState<number | null>(null);

  const handleChangeStarPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue < DEFAULT_START_POS) {
      newValue = DEFAULT_START_POS;
    }
    setStartPos(newValue);
  };

  const handleChangeEndPos = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = parseInt(event.target.value);
    if (!newValue || newValue > DEFAULT_END_POS) {
      newValue = DEFAULT_END_POS;
    }
    setEndPos(newValue);
  };

  const handleLoadClick = () => {
    moduleModel.getDashboard(startPos, endPos);
    setStartPosLoad(startPos);
    setEndPosLoad(endPos);
  };

  const onRowClick = (data: IGridData, selectedRows: IGridData[], field: string | undefined) => {
    const row = data as ITranslationDashboardGrid;
    setCreateProjectRef(row.ref);
    if (field && row[field] && row[field].pending > 0) {
      const cell = row[field] as ITranslationDashboardCell;
      setCreateProjectDialect(cell.dialect);
    }
  };

  let cellRenderers = {};
  sourceDialects.forEach((d) => {
    cellRenderers = {
      ...cellRenderers,
      [d.dialectCode + '(s)']: translationProgressRenderer,
    };
  });

  return (
    <ModuleContext.Provider value={moduleModel}>
      <ContainerRight>
        <FormContainerStyled>
          <EditorRowStyled>
            <TextFieldPos
              value={startPos}
              variant="outlined"
              label={'Start'}
              type={'number'}
              onChange={handleChangeStarPos}
            />
            <TextFieldPos
              value={endPos}
              variant="outlined"
              label={'End'}
              type={'number'}
              onChange={handleChangeEndPos}
            />
            <Button variant="contained" size="small" onClick={handleLoadClick}>
              load
            </Button>
          </EditorRowStyled>
          <Grid handleRowClick={onRowClick} cellRenderers={cellRenderers} defaultColumnWidth={120} />
        </FormContainerStyled>
      </ContainerRight>
      {createProjectRef && createProjectDialect && (
        <TranslationDashboardCreateProjectModal
          onClose={() => {
            setCreateProjectRef(null);
            setCreateProjectDialect(null);
          }}
          refId={createProjectRef}
          startLesson={startPosLoad}
          endLesson={endPosLoad}
          dialectId={createProjectDialect}
        />
      )}
    </ModuleContext.Provider>
  );
};

export default TranslationDashboard;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const FormContainerStyled = styled(FormContainer)`
  max-width: initial;
`;

const TextFieldPos = styled(TextFieldStyled)`
  width: 66px;
  && {
    margin-right: 10px;
  }
`;
