import { Canceler } from 'axios';
import { del, get, post, put } from 'services/request';
import { IRefIds } from '../constants/refs';
import { ISegment, ISegmentEdit, ISegmentEditTarget, ISegmentId } from '../types/segments';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import * as lodash from 'lodash';

type ISegmentSearch = IModuleSearch & {
  panelId?: number;
};

export default class SegmentModel extends BaseModel {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.segment;
    this.gridName = 'Segments';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid({ text = '', panelId }: ISegmentSearch) {
    await super.getGrid();
    this.gridData = await get<ISegment[]>(
      `courses/${this.courseId}/segments`,
      { text, panel: panelId },
      (cancel: Canceler) => {
        this.cancelGetGrid = cancel;
      },
    );
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<ISegment>(`courses/${this.courseId}/segments/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const [item, targets] = await Promise.all([
      await get<ISegmentEdit>(`/segments/new`),
      await get<ISegmentEditTarget[]>(`/courses/${this.courseId}/segments-target/new`),
    ]);
    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as ISegmentEdit;
    const itemId = itemToSave.id;
    const targets = itemToSave.targets;

    await put<ISegmentId>(`/segments/${itemId}`, lodash.omit(itemToSave, 'targets'));
    await Promise.all([
      ...targets.map((target) =>
        put<ISegmentId>(`/segments/${itemId}/target-dialect/${target.dialectId}`, {
          ...target,
        }),
      ),
    ]);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as ISegmentEdit;
    const targets = itemToCreate.targets;
    const result = await post<ISegmentId>(`/courses/${this.courseId}/segments`, lodash.omit(itemToCreate, 'targets'));

    if (result.data) {
      const newItemId = result.data.id;

      await Promise.all(
        targets.map((target) =>
          put<ISegmentId>(`/segments/${newItemId}/target-dialect/${target.dialectId}`, {
            ...target,
          }),
        ),
      );

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`segments/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, targets] = await Promise.all([
      get<ISegmentEdit>(`segments/${itemId}`),
      get<ISegmentEditTarget[]>(`/segments/${itemId}/targets`),
    ]);
    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async search(data: ISegmentSearch) {
    await this.getGrid(data);
  }

  async checkSegments() {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/segments/check`);
    this.gridData = await get<ISegment[]>(`courses/${this.courseId}/segments/with-error`);
    this.loadingGrid = false;
    this.render();
  }
}
