import Button from '@material-ui/core/Button';
import { TextFieldChangeAware } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';

type IProps = {
  vocIds: number[];
  onClose: () => void;
  onEditVocIds: (vocIds: number[]) => void;
};

const VocGridEditModal: React.FC<IProps> = (props) => {
  const origVocIds = props.vocIds.join(', ');
  const [vocs, setVocs] = useState(origVocIds);
  const [valid, setValid] = useState(true);

  const handleClose = () => {
    props.onClose();
  };

  const handleUpdate = () => {
    props.onEditVocIds(vocs.split(',').map((v) => parseInt(v)));
    props.onClose();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = event.target.value;
    const isValid = value.split(',').filter((v) => parseInt(v) > 0).length === value.split(',').length;
    setValid(isValid);
    setVocs(value);
  };

  return (
    <ModalWrapper width={450} height={220} onClose={handleClose} title="Edit Voc Grid">
      <TextFieldChangeAware
        value={vocs}
        $changed={vocs !== origVocIds}
        variant="outlined"
        onChange={onChange}
        multiline
      />
      <Button disabled={!valid} variant="contained" size="small" color="primary" onClick={handleUpdate}>
        Update
      </Button>
    </ModalWrapper>
  );
};

export default VocGridEditModal;
