import React from 'react';
import styled from 'styled-components';
import { IDialogSegment } from '../../../types/dialog';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { CLDetails } from '../../../components/cl/CLDetails';

export type IProps = {
  segment: IDialogSegment;
  index: number;
  dnd: boolean;
  onEditSegment: Function;
  editing: boolean;
};

const DialogSegmentListSegment: React.FC<IProps> = (props) => {
  const { segment } = props;

  const onEditSegment = () => {
    props.onEditSegment(segment.id);
  };

  return (
    <Draggable isDragDisabled={!props.dnd} draggableId={props.segment.id.toString()} index={props.index}>
      {(provided) => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onEditSegment}
          editing={props.editing}
        >
          <Speaker>{segment.speaker}</Speaker>
          <Title>{segment.title}</Title>
          <IconWrapper>
            <ExerciseType>{segment.exerciseType}</ExerciseType>
            {segment.error && <FontAwesomeIcon icon={faExclamation} color={'red'} />}
            <CLDetails details={segment.clDetails} />
          </IconWrapper>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default DialogSegmentListSegment;

export const Speaker = styled.div`
  font-weight: bold;
  font-size: 12px;
`;
export const Title = styled.div`
  min-height: 20px;
  font-size: 14px;
`;

export const Wrapper = styled.div<{ editing: boolean }>`
  border: solid 1px #bfbfbf;
  background-color: ${(props) => (props.editing ? '#e9dec7' : '#f5f5f5')};
  position: relative;
  margin: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: inline-block;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
`;

const ExerciseType = styled.span`
  margin-left: 2px;
  margin-right: 2px;
  font-size: 12px;
`;
