import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IAudioArtist, IAudioArtistEdit } from '../types/audio';

type IAudioArtistConstructor = IModelCourseOptions & {
  targetDialectId: number;
};

export default class AudioArtistModel extends BaseModel<IAudioArtist, IAudioArtistEdit> {
  targetDialectId: number;
  constructor(options: IAudioArtistConstructor) {
    super(options);
    this.ref = IRefIds.audio;
    this.gridName = 'AudioArtist';
    this.targetDialectId = options.targetDialectId;
    this.getGrid();
    this.getGridColumns();
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IAudioArtist[]>(
      `courses/${this.courseId}/target-dialects/${this.targetDialectId}/audio-artists`,
    );
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IAudioArtist>(
      `courses/${this.courseId}/target-dialects/${this.targetDialectId}/audio-artists/${itemId}`,
    );
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const item = await get<IAudioArtistEdit>(`audio-artists/new`);
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const { id } = this.itemUpdated;
    await put(`audio-artists/${id}`, this.itemUpdated);
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<IAudioArtistEdit>(
      `courses/${this.courseId}/target-dialects/${this.targetDialectId}/audio-artists`,
      this.itemUpdated,
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`audio-artists/${id}`);
    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const item = await get<IAudioArtistEdit>(`audio-artists/${itemId}`);
    await this.setItem(item);
    this.render();
  }
}
