import { createKeyPath } from 'CMFW/context/helper/keyContext';
import KeyContext from 'CMFW/context/KeyContext';
import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { addPositionTitle } from 'helpers/positon';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import PanelSelectModel, { IPanelSelect } from 'models/PanelSelectModel';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';
import { ALL_OPTION, NONE_OPTION } from './selects';

const KEY = 'panel';

type IProps = {
  isLinked?: boolean;
  onChange?: (panelId: number) => void;
  allOption?: boolean;
  className?: string;
};

const PanelSelect: React.FC<IProps> = (props) => {
  const { isLinked, onChange } = props;

  const module = useContext(ModuleContext);

  const keyContext = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(keyContext, KEY);

  const moduleItemValue = module.getItemValue(aggregatedKeyName);

  const [currentPanelId, setCurrentPanelId] = useCurrentItemValue(moduleItemValue, NONE_OPTION.id, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.panels, courseId);
  const dataModel = useMemo(() => new PanelSelectModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    const data = [NONE_OPTION];

    if (props.allOption) {
      data.push(ALL_OPTION);
    }

    return [...data, ...addPositionTitle(cacheSelectData)];
  }, [cacheSelectData, props.allOption]);

  const handleChange = (value: IPanelSelect) => {
    const selectedPanelId = value.id;
    setCurrentPanelId(selectedPanelId);
    isLinked && module.updateItemValue(aggregatedKeyName, selectedPanelId);
    onChange && onChange(selectedPanelId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Panel"
      optionLabelKey="title"
      currentValue={currentPanelId}
      originalValue={isLinked && moduleItemValue}
    />
  );
};

export default PanelSelect;
