import TextButton from 'CMFW/button/TextButton';
import Checkbox from 'CMFW/input/Checkbox';
import TextInput from 'CMFW/input/TextInput';
import { TabDialects } from 'CMFW/layout/TabDialects';
import React, { useState } from 'react';
import styled from 'styled-components';
import SplitSelect from '../common/SplitSelect';
import { EditorRow } from '../common/styles';
import InflectViewModal from './InflectViewModal';

export type IProps = {
  keyName: string;
};

const InflectConfig: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowInflects = () => {
    setShowModal(true);
  };

  const handleCloseShowInflects = () => {
    setShowModal(false);
  };

  return (
    <TabDialectsStyled keyName={props.keyName}>
      <EditorRow>
        <TextInput keyName={'inflectWord'} label={'Inflect Word'} />
        <SplitSelect keyName={'splitType'} />
        <CheckboxStyled keyName={'preselected'} label="Pre-Selected" />
        <TextButton keyName={'nInflects'} onClick={handleShowInflects} />
      </EditorRow>
      {showModal && <InflectViewModal onClose={handleCloseShowInflects} />}
    </TabDialectsStyled>
  );
};

export default InflectConfig;

const TabDialectsStyled = styled(TabDialects)`
  && {
    height: 160px;
    flex-grow: unset;
  }
`;

const CheckboxStyled = styled(Checkbox)`
  && {
    white-space: nowrap;
  }
`;
