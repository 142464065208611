import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { IItemId } from 'types/content';
import { IGlossary, IGlossaryEdit, IGlossarySourceEdit } from 'types/glossary';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import * as lodash from 'lodash';
import TranslationManagerModel from './TranslationManagerModel';

export default class GlossaryModel extends BaseModel<IGlossary, IGlossaryEdit> {
  translations: TranslationManagerModel;
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.glossaries;
    this.gridName = 'Glossaries';
    this.getGridColumns();
    this.translations = new TranslationManagerModel({ ref: this.ref });
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IGlossary[]>(`courses/${this.courseId}/glossaries`);
    this.loadingGrid = false;

    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IGlossary>(`courses/${this.courseId}/glossaries/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async new() {
    await super.new();
    const [item, sources, tooltips] = await Promise.all([
      get<IGlossaryEdit>(`glossaries/new`),
      get<IGlossarySourceEdit[]>(`/courses/${this.courseId}/glossaries-source/new`),
      this.translations.new(this.courseId),
    ]);
    item.sources = sources;
    item.tooltips = tooltips;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const { id, sources, tooltips } = this.itemUpdated as IGlossaryEdit;
    await Promise.all([
      put(`glossaries/${id}`, lodash.omit(this.itemUpdated, 'sources')),
      ...sources.map((source) =>
        put(`glossaries/${id}/source-dialect/${source.dialectId}`, {
          ...source,
          forms: source.forms.split('\n').join(','),
        }),
      ),
    ]);
    await this.translations.update(id, tooltips);

    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async createItem() {
    const result = await post<IItemId>(`courses/${this.courseId}/glossaries`, lodash.omit(this.itemUpdated, 'sources'));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      const { sources, tooltips } = this.itemUpdated as IGlossaryEdit;

      await Promise.all(
        sources.map((source) =>
          put(`glossaries/${newItemId}/source-dialect/${source.dialectId}`, {
            ...source,
            forms: source.forms.split('\n').join(','),
          }),
        ),
      );
      await this.translations.update(newItemId, tooltips);

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated;

    const result = await del(`glossaries/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, sources, tooltips] = await Promise.all([
      get<IGlossaryEdit>(`glossaries/${itemId}`),
      get<IGlossarySourceEdit[]>(`glossaries/${itemId}/sources`),
      this.translations.get(itemId),
    ]);
    item.sources = sources.map((source) => ({ ...source, forms: source.forms.split(',').join('\n') }));
    item.tooltips = tooltips;
    await this.setItem(item);
    this.render();
  }
}
