import ModuleContext from 'CMFW/context/ModuleContext';
import { convertSourceDialectCodeToApi, convertTargetDialectCodeToApi } from 'helpers/helpers';
import DialectModel, { IDialectDef } from 'models/DialectModel';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICourseConfig, IDialect } from 'store/reducers/courseConfig';
import { IAppState } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { SelectLayout } from './SelectLayout';

const TITLE_OPTION: IDialectOption = {
  dialectId: 0,
  dialectCode: 'title',
};

const ALL_DIALECTS_COURSE_ID = 0;

type IProps = {
  includeSourceDialects?: boolean;
  onlyTarget?: boolean;
  onlySource?: boolean;
  titleOption?: boolean;
  handleDialectChange(dialect: IDialectOption): void;
  className?: string;
  courseId?: number;
  filterIds?: number[];
};

export type IDialectOption = Omit<IDialect, 'byDefault'>;

const DialectSelect: React.FC<IProps> = (props) => {
  const { onlyTarget, onlySource, titleOption, handleDialectChange } = props;

  const [dialects, setDialects] = useState(null as IDialectOption[] | null);
  const [dialect, setDialect] = useState('');

  const courseId = props.courseId ?? useContext(ModuleContext).courseId;
  const courses = useSelector((state: IAppState) => state.courseConfig);

  const courseConfig = courses[courseId] as ICourseConfig | undefined;

  useEffect(() => {
    if (dialect !== '') {
      return;
    }

    let initialValue: IDialectOption | undefined;

    if (titleOption) {
      initialValue = TITLE_OPTION;
    } else if (onlySource) {
      const sourceDialects = courseConfig?.sourceDialects;

      if (sourceDialects) {
        const sourceDialectDefault = sourceDialects.find((source) => source.byDefault);

        if (sourceDialectDefault) {
          initialValue = {
            dialectId: sourceDialectDefault.dialectId,
            dialectCode: convertSourceDialectCodeToApi(sourceDialectDefault.dialectCode),
          };
        }
      }
    } else {
      const targetDialects = courseConfig?.targetDialects;

      if (targetDialects) {
        const targetDialectDefault = targetDialects.find((target) => target.byDefault);

        if (targetDialectDefault) {
          initialValue = {
            dialectId: targetDialectDefault.dialectId,
            dialectCode: convertTargetDialectCodeToApi(targetDialectDefault.dialectCode),
          };
        }
      }
    }

    if (initialValue) {
      setDialect(initialValue.dialectCode);
      handleDialectChange(initialValue);
    }
  }, [dialect, courseConfig, titleOption, handleDialectChange, onlySource]);

  useEffect(() => {
    (async () => {
      let dialects: IDialectOption[] = [];

      if (titleOption) {
        dialects.push(TITLE_OPTION);
      }

      if (courseId === ALL_DIALECTS_COURSE_ID) {
        const promises: Promise<IDialectDef[]>[] = [];
        const dialectModel = new DialectModel(courseId);

        if (!onlySource) {
          promises.push(dialectModel.getTargetDialects());
        }

        if (!onlyTarget) {
          promises.push(dialectModel.getSourceDialects());
        }

        const result = await Promise.all(promises);

        result.forEach((ds) =>
          dialects.push(...ds.map((dialect) => ({ dialectId: dialect.id, dialectCode: dialect.code }))),
        );
      } else if (courseConfig) {
        const { targetDialects, sourceDialects } = courseConfig;

        if (!onlySource) {
          dialects.push(
            ...targetDialects.map((dialect) => ({
              dialectId: dialect.dialectId,
              dialectCode: convertTargetDialectCodeToApi(dialect.dialectCode),
            })),
          );
        }

        if (!onlyTarget) {
          dialects.push(
            ...sourceDialects.map((dialect) => ({
              dialectId: dialect.dialectId,
              dialectCode: convertSourceDialectCodeToApi(dialect.dialectCode),
            })),
          );
        }
      }

      const filterIds = props.filterIds;
      if (filterIds) {
        dialects = dialects.filter((dialect) => filterIds.includes(dialect.dialectId));
      }

      setDialects(dialects);
    })();
  }, [courseConfig, courseId, onlySource, onlyTarget, titleOption, props.filterIds]);

  const handleChange = (selectedDialect: IDialectOption) => {
    setDialect(selectedDialect.dialectCode);
    selectedDialect && props.handleDialectChange(selectedDialect);
  };

  return (
    <SelectLayoutStyled
      className={props.className}
      data={dialects}
      onChange={handleChange}
      label="Dialect"
      optionLabelKey="dialectCode"
      optionValueKey="dialectCode"
      currentValue={dialect}
    />
  );
};

const SelectLayoutStyled = styled(SelectLayout)`
  width: 100px;
  flex-grow: 0;
`;

export default DialectSelect;
