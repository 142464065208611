import { get } from '../services/request';
import { IDialogSpeaker } from '../types/dialog';

export default class DialogSpeakerSelectModel {
  dialogId: number;

  constructor(dialogId: number) {
    this.dialogId = dialogId;
  }

  async get() {
    return await get<IDialogSpeaker[]>(`dialogs/${this.dialogId}/speakers`);
  }
}
