import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ITranslationStatus } from '../../../types/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';

export type IProps = {
  status: ITranslationStatus;
  error: string;
};

const CATError: React.FC<IProps> = (props) => {
  const [status, setStatus] = useState(props.status);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setStatus(props.status);
    setError(props.error);
  }, [props.status, props.error]);
  if (props.error === '') {
    return null;
  }

  return (
    <Tooltip title={<p dangerouslySetInnerHTML={{ __html: error }} />}>
      <span>
        {status === ITranslationStatus.TRANSLATION_DONE ? (
          <FontAwesomeIconRed icon={faExclamation} />
        ) : (
          <FontAwesomeIconGray icon={faExclamation} />
        )}
      </span>
    </Tooltip>
  );
};

export default CATError;

const FontAwesomeIconGray = styled(FontAwesomeIcon)`
  color: #8a8a8a;
  font-size: 14px;
  cursor: pointer;
`;

const FontAwesomeIconRed = styled(FontAwesomeIcon)`
  color: #ff0000;
  font-size: 14px;
  cursor: pointer;
`;
