import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import { IFields } from 'CMFW/grid/fields';
import Grid from 'CMFW/grid/Grid';
import { ContainerLeft, ContainerLeftFooter } from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import PanelsModel from 'models/PanelsModel';
import PanelEdit from 'modules/panels/PanelEdit';
import React, { useMemo } from 'react';
import PanelSearch from './PanelSearch';

export type IProps = {
  courseId: number;
  search?: string;
};

const Panels: React.FC<IProps> = (props) => {
  const { courseId, search } = props;

  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new PanelsModel({ render: forceRender, courseId, search }), [
    forceRender,
    courseId,
    search,
  ]);

  return (
    <ContainerLeft>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={500}>
          <ContainerLeft>
            <PanelSearch />
            <Grid
              cellRenderers={{
                [IFields.stateExplanations]: 'stateExplanationRenderer',
                [IFields.stateSentences]: 'stateSentenceRenderer',
              }}
              disableRowLoadFields={[IFields.stateExplanations, IFields.stateSentences]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <PanelEdit />
        </Split>
      </ModuleContext.Provider>
    </ContainerLeft>
  );
};

export default Panels;
