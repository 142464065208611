import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import React, { useMemo, useState } from 'react';
import { SelectLayout } from './SelectLayout';
import CoursesSelectModel from '../../models/CoursesSelectModel';
import { ICourse } from '../../types/courses';

type IProps = {
  onChange: (courseId: number) => void;
  skipCourseId?: number;
  className?: string;
  courseId?: number;
};

const CourseSelect: React.FC<IProps> = (props) => {
  const [currentCourseId, setCurrentCourseId] = useState<number | null>(props.courseId ?? null);

  const cacheKey = getCacheKey(IRefIds.courses, 0);
  const dataModel = useMemo(() => new CoursesSelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    return [...cacheSelectData.filter((data) => data.id !== props.skipCourseId)];
  }, [cacheSelectData, props.skipCourseId]);

  const handleChange = (value: ICourse) => {
    const selectedCourseId = value.id;
    setCurrentCourseId(selectedCourseId);
    props.onChange && props.onChange(selectedCourseId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Course"
      optionLabelKey="name"
      currentValue={currentCourseId}
    />
  );
};

export default CourseSelect;
