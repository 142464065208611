import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import Note from 'modules/Note/Note';
import React, { useMemo, useState } from 'react';
import Flag from '../../CMFW/input/Flag';
import TextInput from '../../CMFW/input/TextInput';
import Positions from '../../components/common/Positions';
import TextSearch from '../../components/search/TextSearch';
import DialogModel from '../../models/DialogModel';
import DialogSegmentEdit from './components/DialogSegmentEdit';
import DialogSegmentList from './components/DialogSegmentList';
import { Button } from '@material-ui/core';
import DialogCopyModal from './modal/DialogCopyModal';
import { IFields } from '../../CMFW/grid/fields';
import { VersionFeedback } from '../../CMFW/layout/VersionFeedback';

export type IProps = {
  courseId: number;
  search?: string;
};

const Dialog: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);

  const [editSegment, setEditSegment] = useState(0);

  const dialogModel = useMemo(
    () => new DialogModel({ render: forceRender, courseId: props.courseId, search: props.search }),
    [forceRender, props.courseId, props.search],
  );

  const onEditSegment = (segmentId: number) => {
    setEditSegment(segmentId);
  };

  const handleCopyClick = () => {
    setIsCopyDialogOpen(true);
  };

  const handleCopyClose = () => {
    setIsCopyDialogOpen(false);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={dialogModel}>
        <Split widthLeft={400}>
          <ContainerLeft>
            <TextSearch />
            <Grid
              cellRenderers={{
                [IFields.stateContent]: 'stateDialogContentRenderer',
              }}
              disableRowLoadFields={[IFields.stateContent]}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <Button variant="outlined" size="small" onClick={dialogModel.checkDialogs.bind(dialogModel)}>
                check
              </Button>
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <Split widthLeft={300}>
                <ContainerLeft>
                  <EditorRow>
                    <Flag />
                    <Positions keyName={'positions'} />
                    <VersionFeedback />
                    <TextInput keyName={'title'} label={'Title'} />
                    <SaveButton />
                  </EditorRow>
                  <DialogSegmentList onEditSegment={onEditSegment} />
                  <EditorRow>
                    <DeleteButton />
                    <Button
                      disabled={dialogModel.itemId <= 0}
                      variant="contained"
                      size="small"
                      onClick={handleCopyClick}
                    >
                      copy
                    </Button>
                  </EditorRow>
                </ContainerLeft>
                <DialogSegmentEdit dialogSegmentId={editSegment} />
              </Split>
            </ItemLayout>
            <Note />
            <LoadingItem />
            {isCopyDialogOpen && <DialogCopyModal onClose={handleCopyClose} />}
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Dialog;
