import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { EditorRow } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import SearchBarDialect from 'components/search/SearchBarDialect';
import KLineSelect from 'components/selects/KlineSelect';
import { LessonSelect } from 'components/selects/LessonSelect';
import { useForceRender } from 'hooks/useForceRender';
import PanelsModel, { IPanel } from 'models/PanelsModel';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';

type IProps = {
  onClose: () => void;
  onChoosePanel: (data: IPanel) => void;
};

const PanelSearchModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext) as PanelsModel;

  const getPanelModel = useCallback(
    () =>
      new PanelsModel({
        render: forceRender,
        courseId: moduleModel.courseId,
      }),
    [forceRender, moduleModel.courseId],
  );

  const panelsModelTitle = useMemo(() => getPanelModel(), [getPanelModel]);
  const panelsModelLesson = useMemo(() => getPanelModel(), [getPanelModel]);
  const panelsModelKline = useMemo(() => getPanelModel(), [getPanelModel]);

  const handleSearch = (text: string) => {
    panelsModelTitle.getGridSearch(text);
  };

  const handleLessonChanged = (id: number) => {
    panelsModelLesson.getGridLesson(id);
  };

  const handleKlineChanged = (id: number) => {
    panelsModelKline.getGrid({ kLineId: id });
  };

  const handleRowClick = (data: IGridData) => {
    props.onChoosePanel(data as IPanel);
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Search Panel'} width={558} height={455}>
      <ItemTabs tabNames={['Title', 'Lesson', 'KLine']}>
        <ModuleContext.Provider value={panelsModelTitle}>
          <Container>
            <SearchBarDialect hideDialectSelect handleSearch={handleSearch} />
            <Grid handleRowClick={handleRowClick} />
          </Container>
        </ModuleContext.Provider>
        <ModuleContext.Provider value={panelsModelLesson}>
          <Container>
            <EditorRow>
              <LessonSelectStyled handleLessonChanged={handleLessonChanged} />
            </EditorRow>
            <Grid handleRowClick={handleRowClick} />
          </Container>
        </ModuleContext.Provider>
        <ModuleContext.Provider value={panelsModelKline}>
          <Container>
            <EditorRow>
              <KLineSelect onChange={handleKlineChanged} />
            </EditorRow>
            <Grid handleRowClick={handleRowClick} />
          </Container>
        </ModuleContext.Provider>
      </ItemTabs>
    </ModalWrapper>
  );
};

const Container = styled.div`
  flex-grow: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
`;

const LessonSelectStyled = styled(LessonSelect)`
  && {
    width: 100%;
  }
`;

export default PanelSearchModal;
