import { IConceptLinkerCompound } from 'components/conceptLink/TooltipCompoundsModel';
import { IConceptLinkerCultural } from 'components/conceptLink/TooltipCulturalsModel';
import { IConceptLinkerPanel } from 'components/conceptLink/TooltipPanelsModel';
import { IConceptLinkerVoc } from 'components/conceptLink/TooltipVocsModel';
import { ICacheActions, ICacheActionTypes } from 'store/actions/cache';

export type ICacheItems = {
  [key: string]: any;
};

export type IClData = IConceptLinkerCompound | IConceptLinkerCultural | IConceptLinkerPanel | IConceptLinkerVoc;

export type ICacheCl = {
  [key: string]: IClData;
};

const initialState = {
  items: {} as ICacheItems,
  cl: {} as ICacheCl,
};

export type ICache = typeof initialState;

export function cacheReducer(state = initialState, action: ICacheActions): ICache {
  switch (action.type) {
    case ICacheActionTypes.ADDED:
      return { ...state, items: { ...state.items, ...action.payload } };
    case ICacheActionTypes.DELETED:
      delete state.items[action.payload];
      return state;
    case ICacheActionTypes.ADDED_CL:
      return { ...state, cl: { ...state.cl, ...action.payload } };
    case ICacheActionTypes.DELETED_CL:
      delete state.cl[action.payload];
      return state;
    default:
      return state;
  }
}
