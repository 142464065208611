import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModalWrapper } from 'components/ModalWrapper';
import SearchBarDialect from 'components/search/SearchBarDialect';
import { useForceRender } from 'hooks/useForceRender';
import PanelsModel from 'models/PanelsModel';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import { IRefIds } from '../../../constants/refs';
import VocSearchBar from '../../vocabulary/VocSearchBar';
import VocabularyModel from '../../../models/VocabularyModel';
import ConjTableSelect from '../../../components/selects/ConjTableSelect';
import CompoundModel from '../../../models/CompoundModel';
import CompoundSearch from '../../compounds/CompoundSearch';
import { ContainerLeftHeader } from '../../../components/common/styles';

export type IConjSearchModal = {
  ref: IRefIds;
  id: number;
  tableId: number;
};

type IProps = {
  onClose: () => void;
  onChooseConj: (data: IConjSearchModal) => void;
};

const ConjSearchModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext) as PanelsModel;

  const [tableId, setTabledId] = useState(0);

  const getPanelModel = useCallback(
    () =>
      new PanelsModel({
        render: forceRender,
        courseId: moduleModel.courseId,
      }),
    [forceRender, moduleModel.courseId],
  );

  const panelsModelTitle = useMemo(() => getPanelModel(), [getPanelModel]);

  const handleSearch = (text: string) => {
    panelsModelTitle.getGridSearch(text);
  };

  const vocModel = useMemo(() => new VocabularyModel({ render: forceRender, courseId: moduleModel.courseId }), [
    forceRender,
    moduleModel.courseId,
  ]);

  const [vocDialect, setVocDialect] = useState('');

  const compoundModel = useMemo(() => new CompoundModel({ render: forceRender, courseId: moduleModel.courseId }), [
    forceRender,
    moduleModel.courseId,
  ]);

  const handleDialectChange = (dialect: string) => {
    setVocDialect(dialect);
  };

  const handleChangeTable = (tableId: number) => {
    setTabledId(tableId);
  };

  const handlePanelRowClick = (data: IGridData) => {
    props.onChooseConj({
      ref: IRefIds.panels,
      tableId,
      id: data.id,
    });
  };

  const handleVocRowClick = (data: IGridData) => {
    props.onChooseConj({
      ref: IRefIds.vocs,
      tableId,
      id: data.id,
    });
  };

  const handleCompoundRowClick = (data: IGridData) => {
    props.onChooseConj({
      ref: IRefIds.compounds,
      tableId,
      id: data.id,
    });
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Insert Conjugation'} width={558} height={455}>
      <ModuleContext.Provider value={vocModel}>
        <ConjTableSelectStyled onChange={handleChangeTable} />
      </ModuleContext.Provider>
      {tableId !== 0 && (
        <ItemTabs tabNames={['Vocabulary', 'Panel', 'Compound']}>
          <ModuleContext.Provider value={vocModel}>
            <Container>
              <VocSearchBar handleDialectChange={handleDialectChange} />
              <Grid handleRowClick={handleVocRowClick} showColumns={[vocDialect]} />
            </Container>
          </ModuleContext.Provider>
          <ModuleContext.Provider value={panelsModelTitle}>
            <Container>
              <SearchBarDialect hideDialectSelect handleSearch={handleSearch} />
              <Grid handleRowClick={handlePanelRowClick} />
            </Container>
          </ModuleContext.Provider>
          <ModuleContext.Provider value={compoundModel}>
            <Container>
              <ContainerLeftHeader>
                <CompoundSearch />
              </ContainerLeftHeader>
              <Grid handleRowClick={handleCompoundRowClick} />
            </Container>
          </ModuleContext.Provider>
        </ItemTabs>
      )}
    </ModalWrapper>
  );
};

const Container = styled.div`
  flex-grow: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
`;

const ConjTableSelectStyled = styled(ConjTableSelect)`
  margin-bottom: 10px;
`;

export default ConjSearchModal;
