import { Button } from '@material-ui/core';
import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { LoadingItem } from 'CMFW/layout/Loading';
import { Uses } from 'CMFW/layout/Uses';
import { ContainerRight, EditorRow } from 'components/common/styles';
import Note from 'modules/Note/Note';
import PanelCloneModal from 'modules/panels/modal/PanelCloneModal';
import Edit from 'modules/panels/tabs/Edit';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Title from './tabs/Title';
import PanelCopyModal from './modal/PanelCopyModal';
import Estructure from './tabs/Estructure';

const PanelEdit: React.FC = () => {
  const moduleModel = useContext(ModuleContext);

  const [isClonePanelOpen, setIsClonePanelOpen] = useState(false);
  const [isCopyPanelOpen, setIsCopyPanelOpen] = useState(false);

  const handleCloneClick = () => {
    setIsClonePanelOpen(true);
  };

  const handleCloneClose = () => {
    setIsClonePanelOpen(false);
  };

  const handleCopyClick = () => {
    setIsCopyPanelOpen(true);
  };

  const handleCopyClose = () => {
    setIsCopyPanelOpen(false);
  };

  const renderModals = () => {
    if (isClonePanelOpen) {
      return <PanelCloneModal onClose={handleCloneClose} />;
    }
    if (isCopyPanelOpen) {
      return <PanelCopyModal onClose={handleCopyClose} />;
    }
  };

  const modalButtonsDisabled = moduleModel.itemId <= 0;

  return (
    <>
      <ContainerRight>
        <ItemLayout>
          <ItemTabs tabNames={['Edit', 'Title', 'Structure', 'Uses']}>
            <Edit />
            <Title />
            <Estructure editable />
            <Uses />
          </ItemTabs>
          <EditorRow>
            <DeleteButton />
            <ButtonStyled disabled={modalButtonsDisabled} variant="contained" size="small" onClick={handleCloneClick}>
              clone
            </ButtonStyled>
            <ButtonStyled disabled={modalButtonsDisabled} variant="contained" size="small" onClick={handleCopyClick}>
              copy
            </ButtonStyled>
            <SaveButton />
          </EditorRow>
        </ItemLayout>
        <Note />
        <LoadingItem />
      </ContainerRight>
      {renderModals()}
    </>
  );
};

const ButtonStyled = styled(Button)`
  && {
    margin-left: 4px;
  }
`;

export default PanelEdit;
