import blueGrey from '@material-ui/core/colors/blueGrey';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Close } from 'components/Close';
import { Module } from 'modules/Module';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabsChangedActive, tabsClosed } from 'store/actions/tabs';
import { IAppState } from 'store/reducers/rootReducer';
import styled from 'styled-components';

export const TabsDashboard: React.FC = () => {
  const { tabs, activeTabId } = useSelector((state: IAppState) => state.tabs);
  const dispatch = useDispatch();

  const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);

  const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
    const { id } = tabs[index];
    dispatch(tabsChangedActive(id));
  };

  const handleCloseClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    dispatch(tabsClosed(id));
  };

  return (
    <Container>
      <TabsStyled
        value={activeTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab) => (
          <Tab
            key={JSON.stringify(tab)}
            label={<TabLabel handleClick={(event) => handleCloseClick(event, tab.id)}>{tab.id}</TabLabel>}
          />
        ))}
      </TabsStyled>
      <ModulesContainer>
        {tabs.map((tab) => (
          <Module module={tab} key={tab.id} isActive={tab.id === activeTabId} />
        ))}
      </ModulesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  flex: 1;
  overflow: auto;
  & .MuiTabs-root {
    flex-shrink: 0;
    height: 24px;
    min-height: 0;
  }
`;

const TabsStyled = styled(Tabs)`
  background-color: ${blueGrey[50]};
  & .MuiTab-root {
    padding: 0 6px;
    max-width: none;
    min-height: 0;
    min-width: 0 !important;
  }
`;

const ModulesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

type ILabelProps = {
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
};

const TabLabel: React.FC<ILabelProps> = (props: ILabelProps) => {
  return (
    <TabLabelContainer>
      <span>{props.children}</span>
      <Close handleClick={props.handleClick} />
    </TabLabelContainer>
  );
};

const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
