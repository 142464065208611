import { ICourseConfigActions, ICourseConfigActionTypes } from '../actions/courseConfig';

export type IDialect = {
  dialectId: number;
  dialectCode: string;
  byDefault: boolean;
};

export type ICourseConfig = {
  id: number;
  languageId: number;
  conceptLinker: boolean;
  hasVocabulary: boolean;
  sourceDialects: IDialect[];
  sourceDialectDefaultId: number;
  sourceDialectDefaultCode: string;
  targetDialects: IDialect[];
  targetDialectDefaultId: number;
  targetDialectDefaultCode: string;
  dialectOrder: number[];
};

export type ICourseConfigItems = {
  [key: number]: ICourseConfig;
};

const initialState = {};

export type ICache = typeof initialState;

export function courseConfigReducer(state = initialState, action: ICourseConfigActions): ICourseConfigItems {
  switch (action.type) {
    case ICourseConfigActionTypes.ADDED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
