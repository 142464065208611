import { ContainerLeft, ContainerRight, EditorRow, ModuleContainer } from 'components/common/styles';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { IRefIds } from '../../../constants/refs';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { Split } from '../../../components/Split';
import Grid from '../../../CMFW/grid/Grid';
import { IGridData } from '../../../types/models';
import { useForceRender } from '../../../hooks/useForceRender';
import AudioArtistModel from '../../../models/AudioArtistModel';
import { IAudioArtist } from '../../../types/audio';
import Chip from '@material-ui/core/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import AudioCreateSetModel, { IAudioWordsCount } from '../../../models/AudioCreateSetModel';
import CircularProgress from '@material-ui/core/CircularProgress';

type IProps = {
  onClose: () => void;
  onReloadDashboard: () => void;
  courseId: number;
  refId: IRefIds;
  targetDialectId: number;
  name: string;
  startLesson: number;
  endLesson: number;
};

const AudioCreateSets: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const audioArtistModel = useMemo(
    () =>
      new AudioArtistModel({ render: forceRender, courseId: props.courseId, targetDialectId: props.targetDialectId }),
    [forceRender, props.courseId, props.targetDialectId],
  );

  const audioCreateSetModel = useMemo(() => new AudioCreateSetModel(), []);

  const [audioArtists, setAudioArtists] = React.useState([] as IAudioArtist[]);
  const [wordsCount, setWordsCount] = React.useState(null as IAudioWordsCount | null);
  const [creatingSets, setCreatingSets] = React.useState(false);

  useEffect(() => {
    (async () => {
      const words = await audioCreateSetModel.getWordsCount(
        props.courseId,
        props.targetDialectId,
        props.refId,
        props.startLesson,
        props.endLesson,
      );
      setWordsCount(words);
    })();
  }, [audioCreateSetModel, props.courseId, props.targetDialectId, props.refId, props.startLesson, props.endLesson]);

  const onRowClick = (data: IGridData, selectedRows: IGridData[]) => {
    const row = selectedRows[0] as IAudioArtist | undefined;
    if (row && !creatingSets) {
      const found = audioArtists.find((artist) => artist.id === row.id);
      if (!found) {
        setAudioArtists([...audioArtists, row]);
      }
    }
  };

  const handleRemoveArtist = (audioArtist: IAudioArtist) => {
    if (!creatingSets) {
      setAudioArtists(audioArtists.filter((artist) => artist.id !== audioArtist.id));
    }
  };

  const handleCreateSets = async () => {
    setCreatingSets(true);
    await audioCreateSetModel.createSets(
      props.courseId,
      props.targetDialectId,
      props.refId,
      props.startLesson,
      props.endLesson,
      audioArtists.map((audioArtist) => audioArtist.id),
    );
    props.onClose();
    props.onReloadDashboard();
  };

  return (
    <ModalWrapper onClose={props.onClose} title={`Create Sets for ${props.name}`} width={518} height={455}>
      <ModuleContainer>
        <ModuleContext.Provider value={audioArtistModel}>
          <Split widthLeft={270}>
            <ContainerLeft>
              <Grid handleRowClick={onRowClick} />
            </ContainerLeft>
            <ContainerRight>
              <div>
                {wordsCount ? (
                  Object.keys(wordsCount).map((key) => (
                    <p key={key}>
                      {key}: {wordsCount[key]} words
                    </p>
                  ))
                ) : (
                  <CircularProgress size={16} />
                )}
              </div>
              <AudioArtistChipContainer>
                {audioArtists.map((audioArtist) => (
                  <AudioArtistChip
                    key={audioArtist.id}
                    label={`${audioArtist.name} [ ${audioArtist.gender} ]`}
                    onDelete={() => handleRemoveArtist(audioArtist)}
                    deleteIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                    clickable={false}
                  />
                ))}
              </AudioArtistChipContainer>
              <EditorRow>
                {creatingSets ? (
                  <CircularProgress size={16} />
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleCreateSets}
                    disabled={audioArtists.length === 0 || wordsCount === null}
                  >
                    Create Sets
                  </Button>
                )}
              </EditorRow>
            </ContainerRight>
          </Split>
        </ModuleContext.Provider>
      </ModuleContainer>
    </ModalWrapper>
  );
};

const AudioArtistChip = styled(Chip)`
  && {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    background-color: #5f84ef;
    color: white;
    height: auto;
    border-style: solid;
    border-width: 2px;
  }
`;

const AudioArtistChipContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default AudioCreateSets;
