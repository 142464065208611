import Button from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import Label from 'CMFW/input/Label';
import { Loading } from 'components/common/Loading';
import { EditorRow, ModuleContainer } from 'components/common/styles';
import InflectTables from 'components/inflect/table/InflectTables';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import { useForceRender } from 'hooks/useForceRender';
import InflectIdsModel from 'models/InflectIdsModel';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

export type IProps = {
  courseId: number;
};

const InflectIds: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new InflectIdsModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const [wordClassId, setWordClassId] = useState(0);

  const handleChangeWordClass = (id: number) => {
    setWordClassId(id);
  };

  const handleLoad = () => {
    model.loadWordClass(wordClassId);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Container>
          <EditorRowStyled>
            <WordClassSelect onChange={handleChangeWordClass} />
            <Button variant="contained" size="small" onClick={handleLoad}>
              load
            </Button>
          </EditorRowStyled>
          <Scroll>
            <InflectTables cellRenderer={<LabelStyled keyName={'value'} />} showTrash={false} />
          </Scroll>
          {model.loadingGrid && <Loading />}
        </Container>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-right: 10px;
`;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const LabelStyled = styled(Label)`
  font-size: 16px;
  text-align: center;
  width: 100%;
`;

export default InflectIds;
