import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from 'AppContext';
import { getTooltip } from 'components/conceptLink/tooltips';
import { IRefIds } from 'constants/refs';
import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import styled from 'styled-components';
import { IConceptLinkerTooltip } from 'types/conceptLinker';
import DeltaPos from './components/DeltaPos';
import InflectStudied from './components/InflectStudied';

export const TOOLTIP_WIDTH = 300;

const ConceptLinkerTooltip: React.FC<IConceptLinkerTooltip> = memo((props) => {
  const { x, y, engine, concept, showInvalidate } = props;

  const dispatch = useDispatch();
  const app = useContext(AppContext);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    app.setConceptLinkerTooltip(null);
  };

  const handleIdClick = () => {
    app.setConceptLinkerTooltip(null);
    dispatch(
      tabsOpened({
        refId: concept.ref === IRefIds.vocs ? IRefIds.vocsCourse : concept.ref,
        search: concept.refId.toString(),
        courseId: engine.getCourse(),
      }),
    );
  };

  const handleInvalidateClick = () => {
    engine.invalidateConcept.call(engine, concept);
  };

  return (
    <Container $x={x} $y={y} onClick={handleClick}>
      <Header>
        {showInvalidate && <InvalidateButton onClick={handleInvalidateClick} icon={faRedo} />}
        <InflectStudied studied={concept.inflectStudied} />
        <DeltaPos deltaPos={concept.deltaPos} />
        <IdButton onClick={handleIdClick}>{concept.refId}</IdButton>
      </Header>
      {getTooltip({
        ref: concept.ref,
        refId: concept.refId,
        courseId: engine.getCourse(),
        targetDialectId: engine.getTargetDialectId(),
      })}
    </Container>
  );
});

const Container = styled.div<{ $x: number; $y: number }>`
  z-index: 100;
  position: absolute;
  top: ${(props) => props.$y}px;
  left: ${(props) => props.$x}px;
  width: ${TOOLTIP_WIDTH}px;
  border-radius: 2px;
  min-height: 90px;
  max-height: 190px;
  border: solid 1px #b9b9bb;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  overflow: auto;
  background-color: white;
`;

const Header = styled.div`
  height: 20px;
  border-bottom: solid 1px darkgrey;
  background-color: #efefef;
  padding-left: 2px;
`;

const InvalidateButton = styled(FontAwesomeIcon)`
  color: #616161;
  text-align: right;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
  padding: 2px;
  cursor: pointer;
  margin-left: 4px;
`;

const IdButton = styled.div`
  float: right;
  margin: 3px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export default ConceptLinkerTooltip;
