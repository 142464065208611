import { booleanRenderer } from './boolean';

export function statusItemRecordingRenderer(param: { value: number }) {
  const { value } = param;
  if (value === 3) {
    return booleanRenderer({ value: true });
  }
  if (value === 4) {
    return booleanRenderer({ value: false });
  }
  return '';
}
