import { combineReducers } from 'redux';
import { cacheReducer } from 'store/reducers/cache';
import { tabsReducer } from 'store/reducers/tabs';
import { userReducer } from 'store/reducers/user';
import { courseConfigReducer } from './courseConfig';

const rootReducer = combineReducers({
  user: userReducer,
  tabs: tabsReducer,
  cache: cacheReducer,
  courseConfig: courseConfigReducer,
});

export default rootReducer;

export type IAppState = ReturnType<typeof rootReducer>;
