export type IGender = {
  value: string;
  label: string;
  readby: IReadby[];
};

export type IReadby = {
  value: string;
  label: string;
};

export const genderValues: IGender[] = [
  {
    value: '-',
    label: '-',
    readby: [
      { value: 'm', label: 'm' },
      { value: 'f', label: 'f' },
    ],
  },
  { value: 'm', label: 'm', readby: [{ value: 'm', label: 'm' }] },
  { value: 'f', label: 'f', readby: [{ value: 'f', label: 'f' }] },
  {
    value: '--',
    label: '- / -',
    readby: [
      { value: 'mf', label: 'm / f' },
      { value: 'fm', label: 'f / m' },
      { value: 'mm', label: 'm / m' },
      { value: 'ff', label: 'f / f' },
    ],
  },

  {
    value: 'm-',
    label: 'm / -',
    readby: [
      { value: 'mf', label: 'm / f' },
      { value: 'mm', label: 'm / m' },
    ],
  },
  {
    value: '-m',
    label: '- / m',
    readby: [
      { value: 'fm', label: 'f / m' },
      { value: 'mm', label: 'm / m' },
    ],
  },
  {
    value: 'f-',
    label: 'f / -',
    readby: [
      { value: 'fm', label: 'f / m' },
      { value: 'ff', label: 'f / f' },
    ],
  },
  {
    value: '-f',
    label: '- / f',
    readby: [
      { value: 'mf', label: 'm / f' },
      { value: 'ff', label: 'f / f' },
    ],
  },
  { value: 'mf', label: 'm / f', readby: [{ value: 'mf', label: 'm / f' }] },
  { value: 'fm', label: 'f / m', readby: [{ value: 'fm', label: 'f / m' }] },
  { value: 'mm', label: 'm / m', readby: [{ value: 'mm', label: 'm / m' }] },
  { value: 'ff', label: 'f / f', readby: [{ value: 'ff', label: 'f / f' }] },
  { value: '---', label: '>2', readby: [{ value: '---', label: '>2' }] },
];

export const getGenderIndex = (value: string) => {
  const index = genderValues.findIndex((gender) => gender.value === value);
  return index > -1 ? index : 0;
};

export const getReadbyIndex = (gender: IGender, value: string) => {
  const index = gender.readby.findIndex((gender) => gender.value === value);
  return index > -1 ? index : 0;
};
