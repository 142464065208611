export function booleanRenderer(param: { value?: string | boolean }) {
  let value = param.value;

  if (typeof value === 'string') {
    value = value === 'true';
  }

  if (value === true) {
    return '<span class="fa fa-check green"></span>';
  }

  if (value === false) {
    return '<span class="fa fa-times red"></span>';
  }
  return '';
}
