import { ICellRendererStateProps } from 'CMFW/grid/cellRenderers/StateRenderers';
import StatesModal from 'CMFW/layout/modal/StatesModal';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { BOARD_TYPE_ICON, IBoardTypes } from '../models/BoardsModel';
import ModuleContext from '../CMFW/context/ModuleContext';

type IProps = ICellRendererStateProps & {
  type: IBoardTypes;
};

const StateDialogButton: React.FC<IProps> = (props) => {
  const { type, value: state, data } = props;
  const module = useContext(ModuleContext);

  const [isStateModalOpen, setIsStateModalOpen] = useState(false);

  const iconClass = BOARD_TYPE_ICON[type];

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (data) {
      event.stopPropagation();
      setIsStateModalOpen(true);
    }
    console.log(props);
  };

  const handleStatesModalClose = () => {
    setIsStateModalOpen(false);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Container onClick={handleClick} className={`fa ${iconClass} state${state}`} />
      {data && isStateModalOpen && (
        <StatesModal
          courseId={module.courseId}
          refId={data.id}
          state={state}
          type={type}
          onClose={handleStatesModalClose}
        />
      )}
    </>
  );
};

const Container = styled.span`
  cursor: pointer;
`;

export default StateDialogButton;
