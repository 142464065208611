import { faPlus, faTimesCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import ModuleContext from 'CMFW/context/ModuleContext';
import { IChangeAwareProps } from 'components/common/styles';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import KlinesModel from 'models/KlinesModel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PanelKlinesAddModal from '../modal/PanelKlinesAddModal';

const KEY = 'kLines';

const PanelKlines: React.FC = () => {
  const moduleModel = useContext(ModuleContext);

  const modelKlines = moduleModel.getItemValue(KEY) as number[];
  const [klines, setKlines] = useState(modelKlines);
  const [klinesDeleted, setKlinesDeleted] = useState([] as number[]);
  const [showModal, setShowModal] = useState(false);

  const modelValueUpdated = moduleModel.getItemUpdatedValue(KEY) as number[];

  const cacheKey = getCacheKey(IRefIds.klines, moduleModel.courseId);
  const dataModel = useMemo(() => new KlinesModel(moduleModel.courseId), [moduleModel.courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  useEffect(() => {
    setKlines([...new Set([...modelValueUpdated, ...klinesDeleted])]);
  }, [klinesDeleted, modelValueUpdated]);

  useEffect(() => {
    setKlinesDeleted([]);
  }, [modelKlines]);

  const handleAddClick = () => {
    setShowModal(true);
  };

  const handleCloseAdd = () => {
    setShowModal(false);
  };

  const handleAddKline = (klineId: number) => {
    const newKlines = klines.filter((kLine) => kLine !== klineId);
    newKlines.push(klineId);
    moduleModel.updateItemValue.bind(moduleModel)(KEY, newKlines);
    if (klinesDeleted.indexOf(klineId) > -1) {
      setKlinesDeleted(klinesDeleted.filter((id) => id !== klineId));
    } else {
      setKlines(newKlines);
    }
  };

  const handleRemoveKline = (klineId: number) => {
    const isRestoringDeleted = klinesDeleted.indexOf(klineId) > -1;
    if (isRestoringDeleted) {
      handleAddKline(klineId);
      return;
    }

    const newKlines = klines.filter((kline) => kline !== klineId);
    moduleModel.updateItemValue.bind(moduleModel)(KEY, newKlines);
    if (modelKlines.indexOf(klineId) === -1) {
      setKlines(newKlines);
    } else {
      setKlinesDeleted([...klinesDeleted, klineId]);
    }
  };

  const getKLineName = (klineId: number): string => {
    if (cacheSelectData !== null) {
      const kl = cacheSelectData.filter((cache) => cache.id === klineId);
      if (kl.length === 1) {
        return kl[0].title;
      }
    }

    return klineId.toString();
  };

  const klinesSorted = klines.sort();

  return (
    <div>
      <AddButton onClick={handleAddClick}>
        <FontAwesomeIcon icon={faPlus} />
      </AddButton>
      {klinesSorted.map((kline) => {
        const isNewKline = modelKlines.indexOf(kline) === -1;
        const isDeleted = klinesDeleted.indexOf(kline) > -1;
        return (
          <KlineChip
            key={kline}
            label={getKLineName(kline)}
            onDelete={() => handleRemoveKline(kline)}
            $changed={isNewKline}
            $deleted={isDeleted}
            deleteIcon={isDeleted ? <FontAwesomeIcon icon={faUndo} /> : <FontAwesomeIcon icon={faTimesCircle} />}
          />
        );
      })}
      {showModal && <PanelKlinesAddModal handleAddKLine={handleAddKline} onClose={handleCloseAdd} />}
    </div>
  );
};

const AddButton = styled.div`
  display: inline-block;
  margin: 5px;
  color: #5f84ef;
  cursor: pointer;
`;

const KlineChip = styled(Chip)<IChangeAwareProps & { $deleted: boolean }>`
  && {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    background-color: ${(props) => (props.$deleted ? 'tomato' : '#5f84ef')} !important;
    color: white;
    height: auto;
    border-style: solid;
    border-color: ${(props) => (props.$changed ? 'tomato' : 'transparent')};
    border-width: 2px;
  }
`;

export default PanelKlines;
