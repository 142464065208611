import { BaseModel, IModelOptions } from 'models/BaseModel';
import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export type IHeadingUsesGrid = {
  id: number;
  courseName: number;
  nSegments: number;
  segmentIds: number[];
  nReadingExercises: number;
  readingIds: number[];
  nTrainerSegments: number;
  trainerIds: number[];
  nExercises: number;
  exerciseIds: number[];
};

interface IHeadingUses {
  uses: IHeadingUsesGrid[];
}

export default class HeadingUsesModel extends BaseModel {
  constructor(options: IModelOptions) {
    super(options);
    this.ref = IRefIds.heading;
    this.gridName = 'HeadingUses';
    this.getGridColumns();
  }

  async getUses(headingId: number) {
    this.loadingGrid = true;
    this.render();
    const response = await get<IHeadingUses>(`/headings/${headingId}/uses`);
    this.gridData = response.uses;
    this.loadingGrid = false;
    this.render();
  }

  async reset() {
    this.gridData = [];
    this.loadingGrid = false;
    this.render();
  }
}
