import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import { ContainerRight, EditorRow } from 'components/common/styles';
import React from 'react';
import styled from 'styled-components';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import Translations from '../../../components/translation/Translations';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import { IRefIds } from '../../../constants/refs';
import { VocCategoryRootSelect } from '../../../components/selects/VocCategoryRootSelect';

const VocCategoryEdit: React.FC = () => {
  return (
    <ContainerRightStyled>
      <ItemLayout>
        <EditorRow>
          <VocCategoryRootSelect />
          <SaveButton />
        </EditorRow>
        <Scroll>
          <Translations keyName={'sources'} refId={IRefIds.vocabularyCategories} />
        </Scroll>
        <DeleteButton />
      </ItemLayout>
      <LoadingItem />
    </ContainerRightStyled>
  );
};

export default VocCategoryEdit;

export const ContainerRightStyled = styled(ContainerRight)``;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;
