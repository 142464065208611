import React from 'react';
import { IRefIds } from '../../../constants/refs';

export type ILessonOutlineContext = {
  DNDEnabled: boolean;
  updateDNDEnabled: (value: boolean) => void;
  showActivity: IRefIds[];
  updateShowActivity: (ref: IRefIds, show: boolean) => void;
};

export const lessonOutlineContextDefault: ILessonOutlineContext = {
  DNDEnabled: false,
  updateDNDEnabled: (value: boolean) => {},
  showActivity: [IRefIds.panelIntro, IRefIds.panels, IRefIds.exercise, IRefIds.reading, IRefIds.vocs, IRefIds.dialog],
  updateShowActivity: (ref: IRefIds, show: boolean) => {},
};

const LessonOutlineContext = React.createContext<ILessonOutlineContext>(lessonOutlineContextDefault);

export default LessonOutlineContext;
