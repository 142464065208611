import { ConceptsLinkerEnginePhrase } from 'components/conceptLink/ConceptsLinkerEngine_phrase';
import ConceptLinkerPhraseModel from 'models/ConceptLinkerPhraseModel';
import { IConceptLinkerGuessedSetter, IConceptLinkerSetter, IConceptPhrase, IEditorPhrase } from 'types/conceptLinker';
import { tooltipLoader } from './TooltipLoader';
import { IRefIds } from '../../constants/refs';

type IOptions = IOptionsRestart & {
  setTooltip: IConceptLinkerSetter;
  setGuessedTooltip: IConceptLinkerGuessedSetter;
};

type IOptionsRestart = {
  ref: number;
  refId: number;
  targetDialect: number;
  course: number;
  initialClPhrases: IConceptLinkerCL;
  fireChange: () => void;
};

type IConceptLinkerCL = {
  done: boolean;
  update: boolean;
  phrases: IConceptPhrase[];
};

export class ConceptLinkerEngine {
  conceptLinkerModel: ConceptLinkerPhraseModel;
  isFocused = false;
  private cLPhrases: ConceptsLinkerEnginePhrase[] = [];
  private rootNode?: HTMLElement;
  private cKEditorId?: string;
  private ref: number;
  private refId: number;
  private course: number;
  private targetDialect: number;
  NEW_ID = -1;
  private editorPhrases: IEditorPhrase[] = [];
  private firstMappingDone = false;
  private setTooltip: IConceptLinkerSetter;
  private setGuessedTooltip: IConceptLinkerGuessedSetter;
  private fireChange = () => {};
  private initialClPhrases: IConceptLinkerCL;

  constructor(options: IOptions) {
    this.ref = options.ref;
    this.refId = options.refId;
    this.course = options.course;
    this.targetDialect = options.targetDialect;
    this.setTooltip = options.setTooltip;
    this.setGuessedTooltip = options.setGuessedTooltip;
    this.fireChange = options.fireChange;
    this.conceptLinkerModel = new ConceptLinkerPhraseModel();
    this.initialClPhrases = options.initialClPhrases;
  }

  restart(options: IOptionsRestart) {
    this.ref = options.ref;
    this.refId = options.refId;
    this.course = options.course;
    this.targetDialect = options.targetDialect;
    this.conceptLinkerModel = new ConceptLinkerPhraseModel();
    this.fireChange = options.fireChange;
    this.initialClPhrases = options.initialClPhrases;
  }

  parseInitialClPhrases() {
    if (this.initialClPhrases) {
      tooltipLoader && tooltipLoader.preloadPhrases(this.initialClPhrases.phrases, this.course, this.targetDialect);

      this.initialClPhrases.phrases
        .sort((a, b) => {
          const keyA = a.order,
            keyB = b.order;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        })
        .forEach((phrase) => {
          const clp = this.createCLPhrase();
          if (clp) {
            clp.setPhrase(phrase);
          }
        });
    }
  }

  init(rootNode: HTMLElement, cKEditorId: string) {
    this.rootNode = rootNode;
    this.cKEditorId = cKEditorId;
    this.firstMappingDone = false;
    this.cLPhrases = [];
  }

  setEditorPhrases(editorPhrases: IEditorPhrase[]) {
    if (this.refId === this.NEW_ID) {
      return;
    }

    editorPhrases.sort(function (a, b) {
      const keyA = a.order,
        keyB = b.order;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    this.editorPhrases = editorPhrases.map((editorPhrase) => ({ ...editorPhrase, plain: editorPhrase.plain.trim() }));
    if (this.firstMappingDone) {
      this.mapPhrases();
    } else {
      this.initialMapPhrases();
    }
  }

  setIsFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  private createCLPhrase() {
    if (!this.rootNode || !this.cKEditorId || !this.course || !this.ref || !this.refId || !this.targetDialect) {
      return;
    }

    const clp = new ConceptsLinkerEnginePhrase({
      rootNode: this.rootNode,
      ckEditorId: this.cKEditorId,
      course: this.course,
      setTooltip: this.setTooltip,
      setGuessedTooltip: this.setGuessedTooltip,
      fireChange: this.fireChange,
      ref: this.ref,
      refId: this.refId,
      targetDialect: this.targetDialect,
      removeAllHighlight: this.removeAllHighlight.bind(this),
    });

    this.cLPhrases.push(clp);
    return clp;
  }

  private initialMapPhrases() {
    this.parseInitialClPhrases();
    this.firstMappingDone = true;
    if (this.cLPhrases.length === this.editorPhrases.length) {
      for (let i = 0; i < this.cLPhrases.length; i++) {
        this.cLPhrases[i].firstLinkEditorPhrase(this.editorPhrases[i]);
      }
    }
    this.mapPhrases();
  }

  private mapPhrases() {
    this.cLPhrases.forEach((cLPhrase) => cLPhrase.markAsUnLinked());

    this.editorPhrases.forEach((editorPhrase) => {
      const cLPhrase = this.getCLLinked(editorPhrase.id);
      if (cLPhrase !== false) {
        cLPhrase.linkEditorPhrase(editorPhrase);
      } else {
        const clp = this.createCLPhrase();
        if (clp) {
          clp.linkEditorPhrase(editorPhrase);
        }
      }
    });

    for (let i = this.cLPhrases.length - 1; i >= 0; i--) {
      if (!this.cLPhrases[i].isLinked()) {
        this.cLPhrases.splice(i, 1);
      }
    }
  }

  checkSameRoot(rootNode: HTMLElement) {
    return this.rootNode === rootNode;
  }

  private getCLLinked(editorPhraseId: number) {
    for (let i = 0; i < this.cLPhrases.length; i++) {
      if (this.cLPhrases[i].isEditorPhrase(editorPhraseId)) {
        return this.cLPhrases[i];
      }
    }
    return false;
  }

  getPhraseConcepts(): IConceptLinkerCL {
    let isDone = true,
      hasBeenUpdated = this.initialClPhrases.phrases.length !== this.cLPhrases.length;

    hasBeenUpdated = hasBeenUpdated || this.initialClPhrases.update;
    const phrases: IConceptPhrase[] = [];

    this.cLPhrases.forEach((phrase) => {
      isDone = isDone && !!phrase.isDone();
      hasBeenUpdated = hasBeenUpdated || phrase.isUpdated();
      const phraseConcepts = phrase.getPhraseConcepts();
      if (phraseConcepts) {
        phrases.push(phraseConcepts);
      }
    });

    return {
      phrases,
      done: isDone,
      update: hasBeenUpdated,
    };
  }

  removeAllHighlight() {
    this.cLPhrases.forEach((clPhrase) => clPhrase.removeHighlight());
  }

  closeTooltips() {
    this.setTooltip(null);
    this.setGuessedTooltip(null);
    this.removeAllHighlight();
  }

  linkTextInBrackets() {
    return this.ref === IRefIds.readingText;
  }
}
