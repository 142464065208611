import { get } from 'services/request';

export const CL_FIX_TABLE_ID = -1;
export const BASE_TABLE_ID = 0;

const initialTables: IInflectIdName[] = [
  { id: CL_FIX_TABLE_ID, name: 'CL Fix' },
  { id: BASE_TABLE_ID, name: 'Base' },
];

const initialTableHeaders: IInflectIdName[] = [
  { id: -2, name: 'CL Fix' },
  { id: -1, name: 'Base' },
];

const initialTableCells: IInflectTableCell[] = [
  { id: -1, table: -1, row: -2, column: 0 },
  { id: 0, table: 0, row: -1, column: 0 },
];

export type IInflectIdName = {
  id: number;
  name: string;
};

export type IInflectTableCell = {
  id: number;
  table: number;
  row: number;
  column: number;
};

type IOptions = {
  withInitials: boolean;
};


export default class InflectTablesModel {
  courseId: number;
  targetDialectId?: number;
  wordClass: number;
  tables: IInflectIdName[] = [];
  tableHeaders: IInflectIdName[] = [];
  tableCells: IInflectTableCell[] = [];

  constructor(options: { courseId: number; wordClass: number; targetDialectId?: number }) {
    this.courseId = options.courseId;
    this.targetDialectId = options.targetDialectId;
    this.wordClass = options.wordClass;
    if (this.wordClass === -1) {
      //todo, with IDs negative the Back fails, need to be fixed in the back.
      //Other(-1) and None(0) has the same tables
      this.wordClass = 0;
    }
  }

  async getInflectTables() {
    this.tables = await get<IInflectIdName[]>(`inflect-tables`);
  }

  async getInflectTableHeaders() {
    if (this.targetDialectId) {
      this.tableHeaders = await get<IInflectIdName[]>(`inflect-table-headers/dialects/${this.targetDialectId}`);
    } else {
      this.tableHeaders = await get<IInflectIdName[]>(`inflect-table-headers/courses/${this.courseId}`);
    }
  }

  async getInflectTableCells() {
    if (this.targetDialectId) {
      this.tableCells = await get<IInflectTableCell[]>(
        `inflect-table-cells/dialects/${this.targetDialectId}/word-classes/${this.wordClass}`,
      );
    } else {
      this.tableCells = await get<IInflectTableCell[]>(
        `inflect-table-cells/courses/${this.courseId}/word-classes/${this.wordClass}`,
      );
    }
  }

  getTables(options: IOptions) {
    if (options.withInitials) {
      return [...initialTables, ...this.tables];
    } else {
      return this.tables;
    }
  }

  getTableHeaders(options: IOptions) {
    if (options.withInitials) {
      return [...initialTableHeaders, ...this.tableHeaders];
    } else {
      return this.tableHeaders;
    }
  }

  getTableCells(options: IOptions) {
    if (options.withInitials) {
      return [...initialTableCells, ...this.tableCells];
    } else {
      return this.tableCells;
    }
  }
}
