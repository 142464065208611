import React from 'react';
import styled from 'styled-components';
import { ILessonCycleActivityFeedbackBase } from '../../../../models/FeedbackModel';
import FeedbackList from '../components/FeedbackList';
import { IRefIds } from '../../../../constants/refs';

export type IProps = {
  activity: ILessonCycleActivityFeedbackBase;
};

const FeedbackActivityExercise: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      <span>{props.activity.title}</span>
      <FeedbackList
        feedback={props.activity.feedback}
        refId={IRefIds.exercise}
        itemId={props.activity.id}
      />
    </Wrapper>
  );
};

export default FeedbackActivityExercise;

const Wrapper = styled.div`
  flex-direction: column;
  background-color: #ffdcdc;
  padding: 4px 2px;
  border-radius: 4px;
`;
