import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';

type IProps = Omit<React.ComponentProps<typeof TextField>, 'variant'> & {
  changed?: boolean;
};

export const SelectInput: React.FC<IProps> = (props) => {
  const { changed, ...rest } = props;

  return <TextFieldStyled {...rest} $changed={changed} variant="outlined" />;
};

export const TextFieldStyled = styled(TextField)<{ $changed?: boolean }>`
  background-color: ${(props) => (props.$changed ? '#ff634717' : 'white')};
  width: 100%;

  & .MuiInputBase-root {
    height: 30px;
  }

  & .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(1);
  }
`;
