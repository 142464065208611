import { ModalWrapper } from 'components/ModalWrapper';
import React from 'react';
import styled from 'styled-components';

type IProps = {
  onClose: () => void;
};

const VocSearchHelpModal: React.FC<IProps> = (props) => {
  return (
    <ModalWrapper title="Vocabulary Search Help" width={818} height={726} onClose={props.onClose}>
      <PStyled>
        Search is sensitive to accent marks: <CodeStyled>à, â, ï...</CodeStyled>
      </PStyled>
      <Title>*</Title>
      <PStyled>Can be any text to complete a word.</PStyled>
      <PStyled>
        If not <CodeStyled>*</CodeStyled> is indicated, will search full words.
      </PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>*ar</CodeStyled> → Vocabulary entries that have a word that ends in <CodeStyled>ar</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>in*</CodeStyled> → Vocabulary entries that have a word that starts with{' '}
            <CodeStyled>in</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>in*ar</CodeStyled> → Vocabulary entries that have a word that starts with{' '}
            <CodeStyled>in</CodeStyled> and ends in <CodeStyled>ar</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>cat</CodeStyled> → Vocabulary entries that have the word <CodeStyled>cat</CodeStyled>
          </PStyled>
        </li>
      </ul>
      <Title>[C] and [V]</Title>
      <PStyled>
        The letter must be a consonant <CodeStyled>[C]</CodeStyled> or vowel <CodeStyled>[V]</CodeStyled>.
      </PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>*[C]</CodeStyled> → Vocabulary entries that have a word that ends in a{' '}
            <CodeStyled>consonant</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>[V]*</CodeStyled> → Vocabulary entries that have a word that starts with a{' '}
            <CodeStyled>vowel</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>*[C][C]*</CodeStyled> → Vocabulary entries that have a word with two{' '}
            <CodeStyled>consonants</CodeStyled> in a row, at any position in the word.
          </PStyled>
        </li>
      </ul>
      <Title>T:</Title>
      <PStyled>The vocabulary entry must be of the type indicated.</PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>T:NOUN</CodeStyled> → Vocabulary entries of type <CodeStyled>NOUN</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>*[C] T:NOUN</CodeStyled> → Vocabulary entries of type <CodeStyled>NOUN</CodeStyled> that have a
            word that ends in a consonant
          </PStyled>
        </li>
      </ul>
      <Title>G:</Title>
      <PStyled>The vocabulary entry must be of the gender indicated.</PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>T:NOUN G:M</CodeStyled> → Vocabulary entries of type <CodeStyled>NOUN</CodeStyled> that are of
            gender <CodeStyled>M</CodeStyled>
          </PStyled>
        </li>
      </ul>
      <Title>IMG:</Title>
      <PStyled>The vocabulary entry illustration status.</PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>IMG:1</CodeStyled> → Vocabulary entries with illustration
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IMG:0</CodeStyled> → Vocabulary entries without illustration
          </PStyled>
        </li>
      </ul>
      <Title>IMG_AI_S:</Title>
      <PStyled>The vocabulary illustration AI Score, from 1 to 9.</PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>IMG_AI_S&lt;7</CodeStyled> → Vocabulary illustration with score lower than 7
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IMG_AI_S&gt;6</CodeStyled> → Vocabulary illustration with score greater than 6
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IMG_AI_S:9</CodeStyled> → Vocabulary illustration with score of 9
          </PStyled>
        </li>
      </ul>
      <Title>IID:</Title>
      <PStyled>
        The vocabulary entry must have the inflect_id indicated. The searches of text are done in the inflect_id. Only
        one inflect_id can be used.
      </PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>IID:145</CodeStyled> → Vocabulary entries that have inflect in the inflect_id{' '}
            <CodeStyled>145</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IID:145 *aste</CodeStyled> → Vocabulary entries that the inflect_id <CodeStyled>145</CodeStyled>{' '}
            ends in <CodeStyled>aste</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IID:145 T:VERB</CodeStyled> → Vocabulary entries that are a <CodeStyled>verb</CodeStyled> and
            have inflect in the inflect_id <CodeStyled>145</CodeStyled>
          </PStyled>
        </li>
      </ul>
      <Title>ICT:</Title>
      <PStyled>The vocabulary conjugation must be the indicated.</PStyled>
      <PStyled>There are 4 types:</PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>REG</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IRREG</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>CHANGE</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>NO_CONJUGATION</CodeStyled>
          </PStyled>
        </li>
      </ul>
      <PStyled>
        If an <CodeStyled>IID</CodeStyled> is indicated, the conjugation type is checked with the inflect_id, not all
        the inflects.
      </PStyled>
      <PStyled>
        If an <CodeStyled>IID</CodeStyled> is not indicated, the conjugation type is checked with all the inflects. In
        this case:
      </PStyled>
      <ul>
        <li>
          <PStyled>
            <CodeStyled>REG</CodeStyled> → all the inflects are <CodeStyled>REG</CodeStyled> (
            <CodeStyled>NO_CONJUGATION</CodeStyled> is ignored)
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IRREG</CodeStyled> → there is at least one inflect <CodeStyled>IRREG</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>CHANGE</CodeStyled> → there is at least one inflect <CodeStyled>CHANGE</CodeStyled>, but no{' '}
            <CodeStyled>IRREG</CodeStyled> inflect
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>NO_CONJUGATION</CodeStyled> → all the inflects are <CodeStyled>NO_CONJUGATION</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IID:145 ICT:CHANGE</CodeStyled> → Vocabulary entries that have <CodeStyled>CHANGE</CodeStyled>{' '}
            in the inflect_id <CodeStyled>145</CodeStyled>
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>T:VERB ICT:IRREG</CodeStyled> → Vocabulary entries that are <CodeStyled>VERB</CodeStyled> and
            have any <CodeStyled>IRREG</CodeStyled> inflect
          </PStyled>
        </li>
        <li>
          <PStyled>
            <CodeStyled>IID:145 ICT:IRREG *ismo</CodeStyled> → Vocabulary entries that have{' '}
            <CodeStyled>IRREG</CodeStyled> in the inflect_id is <CodeStyled>145</CodeStyled> and the inflect ends in{' '}
            <CodeStyled>ismo</CodeStyled>
          </PStyled>
        </li>
      </ul>
      <Title>!</Title>
      <PStyled>
        It negates a condition. It can be used with <CodeStyled>T:</CodeStyled>, <CodeStyled>G:</CodeStyled> and{' '}
        <CodeStyled>ICT:</CodeStyled>.
      </PStyled>
      <ul>
        <li>
          <CodeStyled>T:VERB IID:145 !ICT:REG</CodeStyled> → Vocabulary entries that are <CodeStyled>VERB</CodeStyled>{' '}
          and are <CodeStyled>not REG</CodeStyled> in the inflect_id <CodeStyled>145</CodeStyled>
        </li>
      </ul>
    </ModalWrapper>
  );
};

export default VocSearchHelpModal;

const Title = styled.h3`
  margin-bottom: 0;
`;

const PStyled = styled.p`
  margin: 0 0 4px;
`;

const CodeStyled = styled.code`
  padding: 2px 3px 1px;
  border: 1px solid #dadada;
  border-radius: 3px;
  background-color: #f6f6f6;
  color: #e01e5a;
`;
