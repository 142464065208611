import { get } from 'services/request';
import { IFetchModel, ITooltipOptions } from 'types/models';

type IConceptLinkerVocBase = {
  id: number;
  nUses: number;
  title: string;
  svg: string;
};

type IConceptLinkerVocApi = IConceptLinkerVocBase & {
  [dialect: string]: string;
};

export type IConceptLinkerVoc = IConceptLinkerVocBase & {
  source: string;
  target: string;
};

export type ITooltipVocsOptions = ITooltipOptions & { course: number; defaultTarget?: number };

export class TooltipVocsModel implements IFetchModel<IConceptLinkerVoc[], ITooltipVocsOptions> {
  defaultTarget = 0;
  defaultSource = 2;

  async get(options: ITooltipVocsOptions) {
    if (options.defaultTarget !== undefined) {
      this.defaultTarget = options.defaultTarget;
    }

    const data = await get<IConceptLinkerVocApi[]>(
      `courses/${options.course}/tooltips-voc?text=${options.ids.join(',')}&dialect=`,
    );
    return this.process(data);
  }

  private process(tooltipsVoc: IConceptLinkerVocApi[]) {
    return Promise.resolve(
      tooltipsVoc.map(
        (tooltipVoc): IConceptLinkerVoc => ({
          id: tooltipVoc.id,
          title: tooltipVoc.title,
          nUses: tooltipVoc.nUses,
          svg: tooltipVoc.svg,
          source: tooltipVoc[this.defaultSource + '(s)'],
          target: tooltipVoc[this.defaultTarget + '(t)'],
        }),
      ),
    );
  }
}
