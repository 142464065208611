import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../ModalWrapper';
import { Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { tabsOpened } from '../../store/actions/tabs';

export type ICheckDetail = {
  ref: number;
  refName: string;
  refId: number;
  error: string;
};

export type ICheck = {
  id: number;
  details: ICheckDetail[];
};

type IProps = {
  checks: ICheck[];
  courseId: number;
  onClose: () => void;
};

const FailMessageModal: React.FC<IProps> = (props) => {
  const { checks } = props;

  const failed = useMemo(() => checks.filter((check) => check.details.length > 0), [checks]);

  const dispatch = useDispatch();

  const openActivity = (detail: ICheckDetail) => {
    dispatch(tabsOpened({ courseId: props.courseId, refId: detail.ref, search: detail.refId.toString() }));
    props.onClose();
  };

  const openAllActivities = () => {
    const activities: { [key: number]: number[] } = [];
    failed.forEach((fail) => {
      fail.details.forEach((detail) => {
        if (activities[detail.ref] === undefined) {
          activities[detail.ref] = [];
        }
        activities[detail.ref].push(detail.refId);
      });
    });

    Object.keys(activities).forEach((key) => {
      dispatch(
        tabsOpened({ courseId: props.courseId, refId: parseInt(key), search: activities[parseInt(key)].join(',') }),
      );
    });
    props.onClose();
  };

  const renderDetail = (detail: ICheck) => {
    return (
      <>
        {detail.details.map((detail, index) => (
          <RowDetail key={index}>
            <Name>{detail.refName}</Name>
            <LinkRef onClick={() => openActivity(detail)}>{detail.refId}</LinkRef>
            <Error>{detail.error}</Error>
          </RowDetail>
        ))}
      </>
    );
  };

  return (
    <ModalWrapper onClose={props.onClose} title={'Fail Message'} width={700} height={402}>
      <LinkAll onClick={openAllActivities}>Open all</LinkAll>
      {failed.map((failedCheck) => renderDetail(failedCheck))}
    </ModalWrapper>
  );
};

const Name = styled.div`
  width: 100px;
`;

const LinkRef = styled(Link)`
  width: 50px;
  cursor: pointer;
  && {
    text-decoration: underline;
  }
`;

const LinkAll = styled(LinkRef)`
  width: 200px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Error = styled.div`
  display: flex;
  flex: 1;
`;

const RowDetail = styled.div`
  display: flex;
  flex-direction: row;
`;

export default FailMessageModal;
