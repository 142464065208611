import { Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useState } from 'react';

const KEY = 'deletable';

const DeleteButton: React.FC = () => {
  const module = useContext(ModuleContext);
  const [disabled, setDisabled] = useState(true);

  const hardDisabled = module.getItemValue(KEY) === false;

  return (
    <div>
      <Switch
        checked={!disabled}
        onChange={() => setDisabled(!disabled)}
        color="secondary"
        size="small"
        disabled={hardDisabled}
      />
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={module.delete.bind(module)}
        disabled={hardDisabled || disabled}
      >
        delete
      </Button>
    </div>
  );
};

export default DeleteButton;
