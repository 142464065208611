import { Canceler } from 'axios';
import { get } from 'services/request';
import { IGuessPhrase } from 'types/conceptLinker';

export type IConceptLinkerConfigOptions = {
  ref: number;
  refId: number;
  targetDialect: number;
};

export default class ConceptLinkerPhraseModel {
  cancelGet = () => {};

  guessPhrases(options: IConceptLinkerConfigOptions & { phrase: string; fromScratch: boolean }) {
    this.cancelGet();
    return get<IGuessPhrase>(
      `concepts-linker/ref/${options.ref}/refId/${options.refId}/target-dialect/${options.targetDialect}/guess`,
      { phrase: options.phrase, fromScratch: options.fromScratch },
      (cancel: Canceler) => {
        this.cancelGet = cancel;
      },
    );
  }
}
