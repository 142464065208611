import { get } from '../services/request';
import { IReadingTextSelect } from '../types/reading';

export default class DialogSpeakerSelectModel {
  readingId: number;

  constructor(readingId: number) {
    this.readingId = readingId;
  }

  async get() {
    const parts = await get<IReadingTextSelect[]>(`readings/${this.readingId}/reading-texts-select`);
    return parts.sort(function (a, b) {
      const keyA = a.name,
        keyB = b.name;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }
}
