import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { IExercise, IExerciseEdit, IExerciseEditTarget, IExerciseId } from 'types/exercises';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import * as lodash from 'lodash';

export default class ExerciseModel extends BaseModel<IExercise, IExerciseEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.exercise;
    this.gridName = 'Exercises';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<IExercise[]>(`groups/${this.courseId}/exercises`, data);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IExercise>(`groups/${this.courseId}/exercises/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const [item, targets] = await Promise.all([
      await get<IExerciseEdit>(`/exercises/new`),
      await get<IExerciseEditTarget[]>(`/courses/${this.courseId}/exercises-target/new`),
    ]);
    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IExerciseEdit;
    const itemId = itemToSave.id;
    const targets = itemToSave.targets;

    await put<IExerciseId>(`/exercises/${itemId}`, lodash.omit(itemToSave, 'targets'));
    await Promise.all([
      ...targets.map((target) =>
        put<IExerciseId>(`/exercises/${itemId}/target-dialect/${target.dialectId}`, {
          ...target,
        }),
      ),
    ]);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IExerciseEdit;
    const targets = itemToCreate.targets;
    const result = await post<IExerciseId>(`/groups/${this.courseId}/exercises`, lodash.omit(itemToCreate, 'targets'));

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all(
        targets.map((target) =>
          put<IExerciseId>(`/exercises/${newItemId}/target-dialect/${target.dialectId}`, {
            ...target,
          }),
        ),
      );

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`exercises/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, targets] = await Promise.all([
      get<IExerciseEdit>(`exercises/${itemId}`),
      get<IExerciseEditTarget[]>(`/exercises/${itemId}/targets`),
    ]);
    item.targets = targets;
    await this.setItem(item);
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  async checkExercises() {
    this.loadingGrid = true;
    this.render();
    await get(`/courses/${this.courseId}/exercises/check`);
    this.gridData = await get<IExercise[]>(`courses/${this.courseId}/exercises/with-error`);
    this.loadingGrid = false;
    this.render();
  }
}
