import { encodeModule } from 'helpers/helpers';
import { ITabsActions, ITabsActionTypes } from 'store/actions/tabs';
import { IModule } from 'types/module';

const initialTabs = {
  activeTabId: null as string | null,
  tabs: [] as IModule[],
  moduleNames: {} as { [key: string]: string },
};

export type ITabs = typeof initialTabs;

export function tabsReducer(state = initialTabs, action: ITabsActions): ITabs {
  switch (action.type) {
    case ITabsActionTypes.NAMES_LOADED:
      return {
        ...state,
        moduleNames: action.payload,
      };

    case ITabsActionTypes.OPENED:
      const { payload: tab } = action;
      const id = state.moduleNames[encodeModule(tab)] as string | undefined;

      if (!id) { // No permission
        return state;
      }

      const existingTab = state.tabs.find((currentTab) => currentTab.id === id);

      if (existingTab) {
        return {
          ...state,
          activeTabId: existingTab.id,
          tabs: state.tabs.map((currentTab) => {
            if (currentTab.id === id) {
              return { ...currentTab, ...tab };
            }
            return currentTab;
          }),
        };
      }

      return {
        ...state,
        tabs: [...state.tabs, { ...tab, id }],
        activeTabId: id,
      };

    case ITabsActionTypes.CLOSED:
      const { payload: closedTabId } = action;
      const closedTabIndex = state.tabs.findIndex((tab) => tab.id === closedTabId);
      const activeTabIndex = state.tabs.findIndex((tab) => tab.id === state.activeTabId);

      const newTabs = [] as IModule[];

      state.tabs.forEach((tab) => {
        const notClosed = tab.id !== closedTabId;
        if (notClosed) {
          newTabs.push(tab);
        }
      });

      let newActiveTabId = null;

      if (newTabs.length) {
        if (activeTabIndex === closedTabIndex) {
          const validActiveTabIndex = Math.min(newTabs.length - 1, activeTabIndex);
          newActiveTabId = newTabs[validActiveTabIndex].id;
        } else if (activeTabIndex > closedTabIndex) {
          newActiveTabId = newTabs[activeTabIndex - 1].id;
        } else {
          newActiveTabId = state.activeTabId;
        }
      }

      return {
        ...state,
        tabs: newTabs,
        activeTabId: newActiveTabId,
      };

    case ITabsActionTypes.CHANGED_ACTIVE:
      const { payload: activeTabId } = action;

      return {
        ...state,
        activeTabId,
      };

    default:
      return state;
  }
}
