import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import Flag from 'CMFW/input/Flag';
import TextInput from 'CMFW/input/TextInput';
import { ContainerLeft, EditorGapGreedy, EditorRow } from 'components/common/styles';
import React from 'react';
import styled from 'styled-components';
import { LessonSelect } from '../../../components/selects/LessonSelect';
import Illustration from '../../compounds/Illustration';

const BookEdit: React.FC = () => {
  return (
    <ContainerLeftStyled>
      <EditorRow>
        <Flag />
        <LessonSelect isLinked />
      </EditorRow>
      <EditorRow>
        <TextInput keyName={'title'} label={'Title'} />
      </EditorRow>
      <EditorRow>
        <TextInput keyName={'author'} label={'By'} />
        <SaveButton />
      </EditorRow>
      <EditorRow>
        <Illustration />
      </EditorRow>
      <EditorGapGreedy />
      <DeleteButton />
    </ContainerLeftStyled>
  );
};

export default BookEdit;

export const ContainerLeftStyled = styled(ContainerLeft)`
  justify-content: space-between;
`;
