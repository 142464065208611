import { get } from 'services/request';
import { IFetchModel, ITooltipOptions } from 'types/models';

export type IConceptLinkerCompound = {
  id: number;
  nUses: number;
  items: IItem[];
};

export type IItem = {
  ref: number;
  refId: number;
  order: number;
};

export class TooltipCompoundsModel implements IFetchModel<IConceptLinkerCompound[], ITooltipOptions> {

  get(options: ITooltipOptions) {
    return get<IConceptLinkerCompound[]>(`tooltips-compound?compounds=${options.ids.join(',')}`);
  }

}
