import React from 'react';
import styled from 'styled-components';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslationStatus, TranslationColors } from '../../types/Translation';

type IProps = {
  status: ITranslationStatus;
};

export const TranslationStatusIcon: React.FC<IProps> = (props) => {
  return <FontAwesomeIconStyled icon={faSquare} $color={TranslationColors[props.status]} />;
};

const FontAwesomeIconStyled = styled(FontAwesomeIcon)<{ $color: string }>`
  padding-right: 30px;
  cursor: pointer;
  color: ${(props) => props.$color};
`;
