import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useMemo } from 'react';
import VocCategorySelectModel from '../../models/VocCategorySelectModel';

const KEY = 'parent';

type IProps = {
  className?: string;
};

type ISelectData = { value: number; label: string };

export const VocCategoryRootSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const moduleItemValue: number = module.getItemValue(KEY);

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, 0);

  const cacheKey = getCacheKey(IRefIds.vocabularyCategories);
  const dataModel = useMemo(() => new VocCategorySelectModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (vocCategory: ISelectData) => {
    setCurrentValue(vocCategory.value);
    module.updateItemValue(KEY, vocCategory.value);
  };

  const selectData: null | ISelectData[] = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    let data = cacheSelectData
      .filter((vocCategory) => vocCategory.parent === 0)
      .map((vocCategory) => ({
        label: vocCategory.fullPath,
        value: vocCategory.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    data = [{ label: 'ROOT', value: 0 }, ...data];

    return data;
  }, [cacheSelectData]);

  return (
    <SelectLayout
      className={props.className}
      data={selectData}
      onChange={handleChange}
      label="Parent"
      optionLabelKey="label"
      optionValueKey="value"
      currentValue={currentValue}
      originalValue={moduleItemValue}
    />
  );
};
