import { IPosition } from 'types/content';

export function comparePosition(valueA: IPosition, valueB: IPosition) {
  let result = 0;

  if (valueA === undefined || valueB === undefined) {
    return result;
  }

  if (valueA.lesson !== valueB.lesson) {
    result = valueA.lesson - valueB.lesson;
  } else if (valueA.cycle !== undefined && valueB.cycle !== undefined) {
    if (valueA.cycle !== valueB.cycle) {
      result = valueA.cycle - valueB.cycle;
    } else if (valueA.activity !== undefined && valueB.activity !== undefined) {
      if (valueA.activity !== valueB.activity) {
        result = valueA.activity - valueB.activity;
      }
    } else {
      result = 0;
    }
  } else {
    result = 0;
  }

  return result;
}
