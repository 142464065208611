import { BaseModel } from './BaseModel';
import { get } from '../services/request';
import { IRefIds } from '../constants/refs';
import { IDialectAware } from '../types/content';

export type IFeedback = IDialectAware & {
  n: number;
  median: number;
  version: number;
};

export type ILessonFeedback = {
  id: number;
  title: string;
  position: number;
  cycles: ILessonCycleFeedback[];
};

export type ILessonCycleFeedback = IDialectAware & {
  id: number;
  position: number;
  feedback: IFeedback[];
  activities: ILessonCycleActivityFeedback[];
};

export type ILessonCycleActivityFeedback = ILessonCycleActivityFeedbackBase | ILessonCycleActivityFeedbackPanel;

export type ILessonCycleActivityFeedbackBase = {
  id: number;
  title: string;
  ref: IRefIds;
  tracking: boolean;
  feedback: IFeedback[];
};

type ILessonCycleActivityFeedbackSegment = {
  id: number;
  tracking: boolean;
  feedback: IFeedback[];
};

export type ILessonCycleActivityFeedbackPanel = {
  id: number;
  title: string;
  ref: IRefIds.panels;
  segments: ILessonCycleActivityFeedbackSegment[];
};

export default class FeedbackModel extends BaseModel {
  lessons: ILessonFeedback[] = [];

  public async loadLessons(start: number, end: number) {
    this.lessons = [];
    this.loadingItem = true;
    this.render();
    this.lessons = await get(`/feedback/courses/${this.courseId}/report`, {
      start,
      end,
    });
    this.lessons = this.lessons.sort((a, b) => a.position - b.position);
    this.loadingItem = false;
    this.render();
  }

  public getLessons() {
    return this.lessons;
  }
}
