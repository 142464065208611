import { IRefIds } from 'constants/refs';
import { del, get, postFile, put } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { IAudioSetsItemReviewResult } from './AudioSetsItemModel';

type IAudioSet = {
  id: number;
  artist: string;
  date: string;
  n: number;
  nGood: number;
  nRepeat: number;
  nProgress: string;
  ref: number;
  revision: number;
  speed: number;
  statusRecording: number;
  setName: string;
  words: number;
};

type IAudioSetEdit = {
  id: number;
  statusRecording: number;
  readBy: 'm' | 'f' | 'mf';
  ssmlCode: string;
  ttsEnabled: boolean;
  deletable: boolean;
};

type IAudioSetSearch = IModuleSearch & {
  dialect: number;
};

type IAudioUpload = {
  status: number;
  error: string;
};

export default class AudioSetsModel extends BaseModel<IAudioSet, IAudioSetEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.audio;
    this.gridName = 'AudioSet';
  }

  async getGridSearch(code: string, dialect: number) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    this.gridData = await get<IAudioSet[]>(`/courses/${this.courseId}/sets?dialect=${dialect}&code=${code}`);

    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IAudioSet>(`/courses/${this.courseId}/sets/${itemId}`);
  }

  async delete() {
    await super.delete();
    const { id } = this.itemUpdated as IAudioSetEdit;

    const result = await del(`audio-sets/${id}`);

    if (result.ok) {
      await this.updateGridRow(id, 'remove');
      this.itemUpdated = null;
      this.loadingItem = false;
      this.render();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const item = await get<IAudioSetEdit>(`audio-sets/${itemId}`);

    await this.setItem(item);

    this.render();
  }

  getDownloadLink(setId: number) {
    return `audio-sets/${setId}/download`;
  }

  async rejectSet(setId: number) {
    await super.save();
    await put(`audio-sets/${setId}/reject`, {});
    await Promise.all([this.updateGridRow(setId, 'update'), this.loadItemEdit(setId)]);
  }

  async uploadFile(file: File) {
    const response = await postFile<IAudioUpload>(`audio-sets/${this.itemId}/upload`, file);
    await Promise.all([this.updateGridRow(this.itemId, 'update'), this.loadItemEdit(this.itemId)]);

    return response.data;
  }

  async generateTTS(nameM: string, nameF: string) {
    const response = await put<IAudioUpload>(`audio-sets/${this.itemId}/tts`, { voiceM: nameM, voiceF: nameF });
    await Promise.all([this.updateGridRow(this.itemId, 'update'), this.loadItemEdit(this.itemId)]);

    return response.data;
  }

  async reviewItem(itemId: number, ok: boolean, note: string) {
    const result = await put<IAudioSetsItemReviewResult>(`/audio-sets/items/${itemId}/review`, { ok, note });
    await Promise.all([this.updateGridRow(this.itemId, 'update'), this.loadItemEdit(this.itemId)]);
    return result.data;
  }

  async search(data: IAudioSetSearch) {
    await this.getGridSearch(data.text || '', data.dialect);
  }
}
