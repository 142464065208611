import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import { freelanceRenderer } from 'CMFW/grid/cellRenderers/freelance';
import { IFields } from 'CMFW/grid/fields';
import Grid from 'CMFW/grid/Grid';
import Checkbox from 'CMFW/input/Checkbox';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import UserModel from 'models/UserModel';
import React, { useMemo } from 'react';
import { AdminCourses } from './components/AdminCourses';

export type IProps = {};

const Admin: React.FC = () => {
  const forceRender = useForceRender();

  const userModel = useMemo(() => new UserModel({ render: forceRender }), [forceRender]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={userModel}>
        <Split widthLeft={280}>
          <ContainerLeft>
            <Grid
              cellRenderers={{
                [IFields.freelance]: freelanceRenderer,
              }}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'Name'} />
                <TextInput keyName={'password'} type="password" label={'Password'} />
                <Checkbox keyName={'freelance'} />
                <SaveButton />
              </EditorRow>
              <AdminCourses keyName={'courses'} />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Admin;
