import ModuleContext from 'CMFW/context/ModuleContext';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { sortCompoundTypesAlphabetically } from 'helpers/helpers';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import CompoundModel, { ICompoundType } from 'models/CompoundModel';
import CompoundTypeSelectModel from 'models/CompoundTypeSelectModel';
import React, { useContext, useMemo, useState } from 'react';
import { SelectLayout } from './SelectLayout';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ModalWrapper } from '../ModalWrapper';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompoundType from '../../modules/compounds/CompoundType';

const KEY = 'compoundType';

type IProps = {
  isLinked?: boolean;
  isSearch?: boolean;
  onChange?(typeId: number): void;
  className?: string;
};

export const CompoundTypeSelect: React.FC<IProps> = (props) => {
  const { isSearch, onChange, isLinked } = props;

  const [openModal, setOpenModal] = useState(false);

  const module = useContext(ModuleContext) as CompoundModel;
  const moduleItemValue = module.getItemValue(KEY);
  const [currentTypeId, setCurrentTypeId] = useCurrentItemValue(moduleItemValue, null, isLinked);

  const { courseId } = module;
  const cacheKey = getCacheKey(IRefIds.compoundTypes, courseId);
  const dataModel = useMemo(() => new CompoundTypeSelectModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.getTypes.bind(dataModel), sortCompoundTypesAlphabetically);

  const selectData = useMemo(() => {
    if (cacheSelectData === null) {
      return null;
    }

    if (isSearch) {
      const additionalOptions = [
        { name: 'not set', id: 0, course: 0 },
        { name: '~ SHOW ALL', id: -1, course: 0 },
      ];
      return [...additionalOptions, ...cacheSelectData];
    }

    return cacheSelectData;
  }, [cacheSelectData, isSearch]);

  const handleChange = (value: ICompoundType) => {
    const compoundTypeId = value.id;
    setCurrentTypeId(compoundTypeId);
    isLinked && module.updateItemValue(KEY, compoundTypeId);
    isSearch && module.setGridCompoundTypeId(compoundTypeId);
    onChange && onChange(compoundTypeId);
  };

  return (
    <Wrapper className={props.className}>
      <FontAwesomeIconStyled icon={faEdit} color={'#6b6b6b'} onClick={() => setOpenModal(true)} />
      <SelectLayout
        key={currentTypeId}
        data={selectData}
        onChange={handleChange}
        label="Compound Type"
        optionLabelKey="name"
        currentValue={currentTypeId}
        originalValue={isSearch ? currentTypeId : isLinked && moduleItemValue}
      />
      {openModal && (
        <ModalWrapper title={'Compound Types'} width={500} height={300} onClose={() => setOpenModal(false)}>
          <CompoundType courseId={courseId} />
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  padding-right: 10px;
  cursor: pointer;
`;
