import { LeftMenu } from 'components/LeftMenu';
import { TabsDashboard } from 'components/TabsDashboard';
import React, { memo } from 'react';
import styled from 'styled-components';

export const Dashboard: React.FC = memo(() => {
  return (
    <Wrapper>
      <LeftMenu />
      <TabsDashboard />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
