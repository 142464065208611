import React, { useContext } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import PanelEstructureModel from '../../../models/PanelEstructureModel';
import { IPanelEstructureCard } from '../../../types/panelEstructure';
import PanelEstructureBlock from './cards/PanelEstructureBlock';
import PanelEstructureBlockNew from './components/PanelEstructureBlockNew';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import PanelEstructureTrash from './components/PanelEstructureTrash';
import PanelEstructureContext from './context/PanelEstructureContext';

export type IProps = {};

const LessonOutlineDashboard: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as PanelEstructureModel;
  const panelEstructureContext = useContext(PanelEstructureContext);
  return (
    <WrapperRelative>
      <Droppable
        isDropDisabled={!panelEstructureContext.editable}
        droppableId={'Dashboard'}
        direction={'vertical'}
        type={IPanelEstructureCard.BLOCK}
      >
        {(provided, snapshot) => (
          <Wrapper {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
            {module.blocks.map((block, index) => (
              <PanelEstructureBlock block={block} key={`block-${block.id}`} index={index} />
            ))}
            {provided.placeholder}
            {panelEstructureContext.editable && (
              <Footer>
                <PanelEstructureBlockNew />
                <PanelEstructureTrash />
              </Footer>
            )}
            <LoadingItem />
          </Wrapper>
        )}
      </Droppable>
    </WrapperRelative>
  );
};

export default LessonOutlineDashboard;

const Wrapper = styled.div<{ isDraggingOver: boolean }>`
  background-color: ${(props) => (props.isDraggingOver ? '#f5f5e8' : 'none')};
  height: 100%;
  overflow: auto;
`;

const WrapperRelative = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
