import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import CATTextColor from '../../modules/CAT/components/CATTextColor';
import { ICATMemoryGuest } from '../../models/CATModel';
import CATSimilarity from '../../modules/CAT/components/CATSimilarity';
import { CATCell, CATRow } from '../../modules/CAT/components/CATTableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IRefIds } from '../../constants/refs';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tabsOpened } from '../../store/actions/tabs';
import { useDispatch } from 'react-redux';

export type IProps = {
  memory: ICATMemoryGuest[] | null;
  onSelectMemory: (memory: ICATMemoryGuest) => void;
};

const TranslationMemory: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const onSelectMemory = async (row: number) => {
    if (props.memory && props.memory[row]) {
      props.onSelectMemory(props.memory[row]);
    }
  };

  const openTranslation = (event: MouseEvent<SVGSVGElement>, courseId: number, translationId: number) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      tabsOpened({
        refId: IRefIds.sourceAdaptation,
        search: translationId.toString(),
        courseId: courseId,
      }),
    );
  };

  const renderMemory = () => {
    if (props.memory === null) {
      return <CircularProgressStyled size={10} />;
    } else {
      return (
        <>
          {props.memory.map((m, index) => (
            <CATRowStyled key={index} onClick={() => onSelectMemory(index)}>
              <CATCell width={30} padding={0}>
                <CATTextColorStyled text={m.course + ''} />
              </CATCell>
              <CATCell>
                <CATTextColorStyled text={m.fromText} />
              </CATCell>
              <CATCell width={30} padding={0}>
                <CATSimilarity similarity={m.similarity} />
              </CATCell>
              <CATCellStyled>
                <CATTextColorStyled text={m.text} />
              </CATCellStyled>
              <CATCellStyled width={20}>
                <FontAwesomeIconEdit icon={faPencilAlt} onClick={(event) => openTranslation(event, m.course, m.id)} />
              </CATCellStyled>
            </CATRowStyled>
          ))}
        </>
      );
    }
  };

  return renderMemory();
};

export default TranslationMemory;

const CATRowStyled = styled(CATRow)`
  cursor: pointer;
  border-left: none;
`;

const CATCellStyled = styled(CATCell)`
  border-right: none;
`;

const CATTextColorStyled = styled(CATTextColor)`
  font-size: 14px;
  line-height: 14px;
`;

const CircularProgressStyled = styled(CircularProgress)`
  margin: 4px;
`;

const FontAwesomeIconEdit = styled(FontAwesomeIcon)`
  color: #dddddd;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`;
