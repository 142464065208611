import Button from '@material-ui/core/Button';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useState } from 'react';
import { TextFieldChangeAware } from '../../../components/common/styles';

type IProps = {
  src: string;
  onClose: () => void;
  onEditSrc: (src: string) => void;
};

const ImgSrcEditModal: React.FC<IProps> = (props) => {
  const [src, setSrc] = useState(props.src);

  const handleClose = () => {
    props.onClose();
  };

  const handleUpdate = () => {
    props.onEditSrc(src);
    props.onClose();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = event.target.value;
    setSrc(value);
  };

  return (
    <ModalWrapper width={450} height={220} onClose={handleClose} title="Edit Img Src">
      <TextFieldChangeAware value={src} $changed={src !== props.src} variant="outlined" onChange={onChange} multiline />
      <Button variant="contained" size="small" color="primary" onClick={handleUpdate}>
        Update
      </Button>
    </ModalWrapper>
  );
};

export default ImgSrcEditModal;
