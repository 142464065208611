import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faHeadphones } from '@fortawesome/free-solid-svg-icons';
import { IReadingExercise } from '../../../types/reading';
import { CLDetails } from '../../../components/cl/CLDetails';

export type IProps = {
  exercise: IReadingExercise;
  index: number;
  dnd: boolean;
  onEditExercise: Function;
  editing: boolean;
};

const ReadingExerciseListExercise: React.FC<IProps> = (props) => {
  const { exercise } = props;

  const onEditSegment = () => {
    props.onEditExercise(exercise.id);
  };

  return (
    <Draggable isDragDisabled={!props.dnd} draggableId={props.exercise.id.toString()} index={props.index}>
      {(provided) => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={onEditSegment}
          editing={props.editing}
        >
          <ReadingText>{exercise.readingText}</ReadingText>
          <Title dangerouslySetInnerHTML={{ __html: exercise.title.replace(/\n/g, '<br>') }} />
          <IconWrapper>
            <ExerciseType>{exercise.exerciseType}</ExerciseType>
            {exercise.error && <FontAwesomeIcon icon={faExclamation} color={'red'} />}
            {exercise.listening && <FontAwesomeIcon icon={faHeadphones} size={'xs'} />}
            <CLDetails details={exercise.clDetails} />
          </IconWrapper>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default ReadingExerciseListExercise;

const ReadingText = styled.div`
  font-weight: bold;
  font-size: 12px;
`;
const Title = styled.div`
  min-height: 20px;
  font-size: 14px;
`;

const Wrapper = styled.div<{ editing: boolean }>`
  border: solid 1px #bfbfbf;
  background-color: ${(props) => (props.editing ? '#e9dec7' : '#f5f5f5')};
  position: relative;
  margin: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: inline-block;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

const ExerciseType = styled.span`
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
`;
