import React from 'react';
import styled from 'styled-components';
import { ILessonCycleActivityFeedbackPanel } from '../../../../models/FeedbackModel';
import FeedbackList from '../components/FeedbackList';
import { IRefIds } from '../../../../constants/refs';

export type IProps = {
  activity: ILessonCycleActivityFeedbackPanel;
};

const FeedbackActivityPanel: React.FC<IProps> = (props) => {
  if (props.activity.segments.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <span dangerouslySetInnerHTML={{ __html: props.activity.title }} />
      <WrapperSegments>
        {props.activity.segments.map((segment) => (
          <WrapperSegment key={segment.id}>
            <FeedbackList
              feedback={segment.feedback}
              refId={IRefIds.segment}
              itemId={segment.id}
            />
          </WrapperSegment>
        ))}
      </WrapperSegments>
    </Wrapper>
  );
};

export default FeedbackActivityPanel;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff6d8;
  padding: 4px 2px;
  border-radius: 4px;
`;

const WrapperSegments = styled.div`
  display: flex;
  flex-direction: row;
`;

const WrapperSegment = styled.div`
  display: flex;
  flex-direction: column;
`;
