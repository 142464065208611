import React, { forwardRef } from 'react';
import styled from 'styled-components';

export type IProps = {
  text: string;
  className?: string;
};

const CATTextColor = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const htmlEnc = (s: string): string => {
    return s
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;');
  };

  const colorText = (text: string): string => {
    text = htmlEnc(text);
    text = text.replace(/&lt;t&gt;(.*?)&lt;\/t&gt;/g, (match): string => {
      return '<SDLtarget>' + match + '</SDLtarget>';
    });
    text = text.replace(/&lt;g&gt;(.*?)&lt;\/g&gt;/g, (match): string => {
      return '<SDLglossary>' + match + '</SDLglossary>';
    });
    text = text.replace(/&lt;b&gt;(.*?)&lt;\/b&gt;/g, (match): string => {
      return '<SDLbold>' + match + '</SDLbold>';
    });
    text = text.replace(/&lt;em&gt;(.*?)&lt;\/em&gt;/g, (match): string => {
      return '<SDLem>' + match + '</SDLem>';
    });
    text = text.replace(/&lt;voc (.*)?&gt;(.*?)&lt;\/voc&gt;/g, (match): string => {
      return '<SDLtarget>' + match + '</SDLtarget>';
    });
    text = text.replace(/&lt;panel (.*)?&gt;(.*?)&lt;\/panel&gt;/g, (match): string => {
      return '<SDLtarget>' + match + '</SDLtarget>';
    });
    //text = text.replace(/ /g, '<span class="non-printable-space"> </span>');
    text = text.replace(/(\r\n|\r|\n)/g, '<br />');

    text = text.replace(/(<br \/>)$/g, '<br /> '); //add extra space to force <p> to render the new empty line
    return text.replace(/<br \/>/g, '<span class="non-printable-newline"></span><br />');
  };

  return (
    <TextColor className={props.className} ref={ref} dangerouslySetInnerHTML={{ __html: colorText(props.text) }} />
  );
});

CATTextColor.displayName = 'SDLTextColor';

export default CATTextColor;

const TextColor = styled.p`
  line-height: 20px;
  color: black;
  font-size: 16px;
  margin: 0;
  font-family: Monospace;
  white-space: pre-wrap;
  user-select: text;
`;
