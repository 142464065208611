import { ICellRenderers } from '../../types/grid';
import { IFields } from '../fields';
import { flagRenderer } from './flag';
import { noteRenderer } from './note';
import { positionRenderer } from './position';
import { errorRenderer } from './error';
import { listeningRenderer } from './listening';
import { stateRenderer } from './state';
import { aiReviewRenderer } from './aiReview';
import { svg2Renderer } from './svg2';

export const globalRenderers: ICellRenderers = {
  [IFields.position]: positionRenderer,
  [IFields.notes]: noteRenderer,
  [IFields.notesStatus]: noteRenderer,
  [IFields.flag]: flagRenderer,
  [IFields.error]: errorRenderer,
  [IFields.listening]: listeningRenderer,
  [IFields.state]: stateRenderer,
  [IFields.aiReview]: aiReviewRenderer,
  [IFields.svg2]: svg2Renderer,
};
