import ModuleContext from 'CMFW/context/ModuleContext';
import { SelectLayout } from 'components/selects/SelectLayout';
import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { sortPanelsIntroAlphabetically } from 'helpers/helpers';
import { useCacheData } from 'hooks/useCacheData';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import PanelIntroSelectModel from 'models/PanelIntroSelectModel';
import React, { useContext, useMemo } from 'react';
import { IPanelIntro } from 'types/panelsIntro';

const KEY = 'panelIntro';

type IProps = {
  className?: string;
};

export const PanelIntroSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const { courseId } = module;
  const moduleItemValue: number = module.getItemValue(KEY);

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, 0);

  const cacheKey = getCacheKey(IRefIds.panelIntro, courseId);
  const dataModel = useMemo(() => new PanelIntroSelectModel(courseId), [courseId]);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel), sortPanelsIntroAlphabetically);

  const handleChange = (panelIntro: IPanelIntro) => {
    setCurrentValue(panelIntro.id);
    module.updateItemValue(KEY, panelIntro.id);
  };

  return (
    <SelectLayout
      className={props.className}
      data={cacheSelectData}
      onChange={handleChange}
      label="Panel Intro"
      optionLabelKey="title"
      currentValue={currentValue}
      originalValue={moduleItemValue}
    />
  );
};
