import { Button } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { comparePosition } from '../../../CMFW/grid/comparators/comparePosition';
import { LoadingGrid } from '../../../CMFW/layout/LoadingGrid';
import { EditorRow } from '../../../components/common/styles';
import { IRefIds } from '../../../constants/refs';
import { useForceRender } from '../../../hooks/useForceRender';
import PanelIntroModel from '../../../models/PanelIntroModel';
import { ILessonOutlineCard, ILessonOutlineCycleActivityPanelIntro } from '../../../types/lessonOutline';
import { IGridData } from '../../../types/models';
import LessonOutlineActivityPanelIntro from '../cards/activities/LessonOutlineActivityPanelIntro';
import { WrapperActivity } from '../cards/LessonOutlineActivity';
import LessonOutlineContext from '../context/LessonOutlineContext';

type IProps = {
  courseId: number;
};

const LessonOutlineSearchPanelIntro: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const panelIntroModel = useMemo(() => new PanelIntroModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);
  const lessonOutlineContext = useContext(LessonOutlineContext);

  const gridData = panelIntroModel.gridData.sort((a, b) => comparePosition(a.position, b.position));

  return (
    <Wrapper>
      <ModuleContext.Provider value={panelIntroModel}>
        <EditorRow>
          <Button variant="outlined" size="small" onClick={() => panelIntroModel.getGrid()}>
            load
          </Button>
        </EditorRow>
        <Droppable
          isDropDisabled={true}
          droppableId={`${IRefIds.panelIntro}-bank`}
          direction={'vertical'}
          type={ILessonOutlineCard.ACTIVITY}
        >
          {(providedDroppable) => (
            <Wrapper>
              <WrapperResult {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {gridData.map((panel: IGridData, index: number) => (
                  <Draggable
                    isDragDisabled={!lessonOutlineContext.DNDEnabled}
                    draggableId={`${IRefIds.panelIntro}-${panel.id}`}
                    index={index}
                    key={`${IRefIds.panelIntro}-${index}`}
                  >
                    {(provided) => (
                      <WrapperActivity
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        extraWide
                      >
                        <LessonOutlineActivityPanelIntro activity={panel as ILessonOutlineCycleActivityPanelIntro} />
                      </WrapperActivity>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </WrapperResult>
              <LoadingGrid />
            </Wrapper>
          )}
        </Droppable>
      </ModuleContext.Provider>
    </Wrapper>
  );
};

export default LessonOutlineSearchPanelIntro;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
`;

const WrapperResult = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  overflow-y: auto;
`;
