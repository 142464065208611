import DeleteButton from 'CMFW/button/DeleteButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import AudioWave from 'CMFW/input/AudioWave';
import TextEditor from 'CMFW/input/TextEditor';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import { TabDialects } from 'CMFW/layout/TabDialects';
import { ContainerRight, EditorGapGreedyH, EditorRow } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import ReadingModel from 'models/ReadingModel';
import ReadingTextModel from 'models/ReadingTextModel';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Translations from '../../../components/translation/Translations';

const CK_EDITOR_CONFIG = {
  toolbar: [['Undo', 'Redo'], ['Bold', 'RemoveFormat'], ['split']],
  toolBarInline: [['Bold']],
};

export type IProps = {
  readingTextId: number;
};

const ReadingTextEdit: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const readingModel = useContext(ModuleContext) as ReadingModel;

  const readingTextModel = useMemo(() => {
    const rem = new ReadingTextModel({ render: forceRender, courseId: readingModel.courseId });
    if (props.readingTextId) {
      rem.loadItemEdit(props.readingTextId);
      rem.readingModel = readingModel;
    }
    return rem;
  }, [forceRender, props.readingTextId, readingModel]);

  return (
    <ModuleContext.Provider value={readingTextModel}>
      <ContainerRightStyled>
        <ItemLayout>
          <Container>
            <EditorRow>
              <EditorGapGreedyH />
              <SaveButton />
            </EditorRow>
            <DialectContainer>
              <TabDialects keyName={'targets'}>
                <AudioWave />
                <EditorGapGreedyH />
                <TextEditor
                  keyName={'html'}
                  clKeyName={'conceptLinkerConcepts'}
                  config={CK_EDITOR_CONFIG}
                  autoGrow={true}
                />
              </TabDialects>
            </DialectContainer>
            <Translations keyName={'sources'} refId={readingModel.ref} />
          </Container>
          <EditorRow>
            <DeleteButton />
          </EditorRow>
        </ItemLayout>
        <LoadingItem />
      </ContainerRightStyled>
    </ModuleContext.Provider>
  );
};

export default ReadingTextEdit;

const DialectContainer = styled.div`
  width: 100%;
`;

const ContainerRightStyled = styled(ContainerRight)`
  width: auto;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  overflow: auto;
`;
