import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import { booleanRenderer } from 'CMFW/grid/cellRenderers/boolean';
import { IFields } from 'CMFW/grid/fields';
import Grid from 'CMFW/grid/Grid';
import Checkbox from 'CMFW/input/Checkbox';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { LoadingItem } from 'CMFW/layout/Loading';
import { Uses } from 'CMFW/layout/Uses';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerLeftHeader,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import InflectConfig from 'components/inflect/InflectConfig';
import { CompoundTypeSelect } from 'components/selects/CompoundTypeSelect';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import CompoundModel from 'models/CompoundModel';
import Cards from 'modules/compounds/Cards';
import CompoundSearch from 'modules/compounds/CompoundSearch';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export type IProps = {
  courseId: number;
  search?: string;
};

const Compounds: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const { courseId } = props;

  const moduleModel = useMemo(() => new CompoundModel({ render: forceRender, courseId, search: props.search }), [
    courseId,
    forceRender,
    props.search,
  ]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={510}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <CompoundSearch />
            </ContainerLeftHeader>
            <Grid
              cellRenderers={{
                [IFields.overwrite]: booleanRenderer,
              }}
            />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemTabs tabNames={['Edit', 'Uses']}>
              <ItemLayout>
                <div style={{ overflow: 'auto' }}>
                  <EditorRowStyled>
                    <CompoundTypeSelect isLinked />
                    <EditorGap />
                    <SaveButton />
                  </EditorRowStyled>
                  <EditorRowStyled>
                    <WordClassSelect isLinked />
                    <TextInput keyName="title" placeholder="title" variant="outlined" />
                  </EditorRowStyled>
                  <EditorRow>
                    <Checkbox keyName="overwriteInflect" label="Overwrite (same) Voc&Panel Inflect" />
                  </EditorRow>
                  <InflectConfig keyName={'inflects'} />
                  <Cards />
                </div>
                <DeleteButton />
              </ItemLayout>
              <Uses />
            </ItemTabs>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const EditorRowStyled = styled(EditorRow)`
  padding-top: 5px;
`;

export default Compounds;
