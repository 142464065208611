import { Button } from '@material-ui/core';
import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import { ModalWrapper } from 'components/ModalWrapper';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import CourseSelect from '../../../components/selects/CourseSelect';
import ListIdsBlind from '../../../components/common/ListIdsBlind';
import { IRefIds } from '../../../constants/refs';
import DialogModel from '../../../models/DialogModel';

type IProps = {
  onClose: () => void;
};

const DialogCopyModal: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext) as DialogModel;
  const [isCoping, setIsCopying] = useState(false);
  const [selectCourseId, setSelectCourseId] = useState(0);
  const [newDialogId, setNewDialogId] = useState(null as number | null);

  const handleChangeSelectCourseId = (courseId: number) => {
    setSelectCourseId(courseId);
  };

  const handleCopyClick = async () => {
    setIsCopying(true);
    const response = await moduleModel.copy(selectCourseId);
    if (response.data) {
      setNewDialogId(response.data.id);
    }
  };

  return (
    <ModalWrapper title="Copy Dialog" width={400} height={150} onClose={props.onClose}>
      <Scroll>
        <CourseSelectStyled onChange={handleChangeSelectCourseId} skipCourseId={moduleModel.courseId} />
        {newDialogId !== null && (
          <ListIdsBlind
            courseId={selectCourseId}
            refId={IRefIds.dialog}
            ids={[newDialogId]}
            label={'Dialog:'}
            onClick={props.onClose}
          />
        )}
      </Scroll>
      <Footer>
        <Button
          variant="contained"
          size="small"
          disabled={isCoping || selectCourseId === 0}
          color="primary"
          onClick={handleCopyClick}
        >
          copy
        </Button>
      </Footer>
    </ModalWrapper>
  );
};

const CourseSelectStyled = styled(CourseSelect)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
`;

export default DialogCopyModal;
