import { get } from 'services/request';
import { IDialectAware } from 'types/content';
import { IRefIds } from '../constants/refs';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITranslation } from '../types/Translation';
import TranslationManagerModel from './TranslationManagerModel';

type IPanelTitle = {
  id: number;
} & {
  [prop: string]: string;
};

type IPanelTitleEdit = {
  id: number;
  targets: IPanelTargetEdit[];
};

type IPanelTargetEdit = IDialectAware & {
  panelTargetId: number;
  titles: ITranslation[];
};

export default class PanelsTitleModel extends BaseModel<IPanelTitle, IPanelTitleEdit> {
  translations: TranslationManagerModel[];
  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'PanelsTitle';
    this.ref = IRefIds.panels;
    this.getGridColumns();
    this.getGrid();
    this.translations = [];
  }

  getTranslation(index: number): TranslationManagerModel {
    if (!this.translations[index]) {
      this.translations[index] = new TranslationManagerModel({ ref: IRefIds.panelTitle });
    }
    return this.translations[index];
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?courseTitle=${this.courseId}`;
  }

  async getGrid() {
    await super.getGrid();
    this.gridData = await get<IPanelTitle[]>(`groups/${this.courseId}/panels/titles`);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IPanelTitle>(`groups/${this.courseId}/panels/${itemId}/titles`);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const targets = await get<IPanelTargetEdit[]>(`panels/${itemId}/targets`);

    const targetTitles = await Promise.all(
      targets.map((target, index) => this.getTranslation(index).get(target.panelTargetId)),
    );
    targets.forEach((target, index) => {
      target.titles = targetTitles[index];
    });

    await this.setItem({
      id: itemId,
      targets,
    });

    this.render();
  }

  async reload() {
    await super.reload();
    await this.getGrid();
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IPanelTitleEdit;
    const itemId = itemToSave.id;
    await Promise.all(
      itemToSave.targets.map((target, index) => this.getTranslation(index).update(target.panelTargetId, target.titles)),
    );
    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }
}
