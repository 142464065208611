import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { TextFieldStyled } from 'components/common/SelectInput';
import { EditorRow } from 'components/common/styles';
import DialectSelect, { IDialectOption } from 'components/selects/DialectSelect';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { IRefIds } from '../../constants/refs';
import { SelectLayout } from '../selects/SelectLayout';
import CourseSelect from '../selects/CourseSelect';

type IProps = {
  onlyTarget?: boolean;
  onlySource?: boolean;
  onlyRef?: IRefIds;
  onlyCourseId?: number;
  className?: string;
};

const refs = [
  { value: IRefIds.NONE, label: 'All' },
  { value: IRefIds.sentences, label: 'Sentence' },
  { value: IRefIds.klines, label: 'KLine' },
  { value: IRefIds.dialogSegment, label: 'Dialog' },
  { value: IRefIds.readingText, label: 'Reading' },
  { value: IRefIds.panelTitle, label: 'Panel Title' },
  { value: IRefIds.lessonTitle, label: 'Lesson Title' },
  { value: IRefIds.panelIntro, label: 'Panel Intro' },
  { value: IRefIds.paragraph, label: 'Paragraph' },
  { value: IRefIds.vocabularyCategories, label: 'Voc Category' },
  { value: IRefIds.heading, label: 'Heading' },
  { value: IRefIds.glossaries, label: 'Glossary' },
  { value: IRefIds.tableLiterals, label: 'Table Literal' },
  { value: IRefIds.cultural, label: 'Cultural Voc' },
  { value: IRefIds.trainer, label: 'Trainer Title' },
];

const SearchBarDialectRef: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext);

  const [text, setText] = useState('');
  const [dialect, setDialect] = useState(0);
  const [ref, setRef] = useState(props.onlyRef ?? IRefIds.NONE);
  const [course, setCourse] = useState(props.onlyCourseId ?? moduleModel.courseId);

  const module = useContext(ModuleContext);

  const search = () => {
    module.search({
      text,
      dialect,
      ref,
      course
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleDialectChange = useCallback((selectedDialect: IDialectOption) => {
    setDialect(selectedDialect.dialectId);
  }, []);

  const handleRefChange = (selectedRef: { value: IRefIds }) => {
    setRef(selectedRef.value);
  };

  const handleCourseChange = (courseId: number) => {
    setCourse(courseId);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const doSearch = () => {
    search();
  };

  return (
    <EditorRow className={props.className}>
      <TextFieldRestyled variant="outlined" onChange={onChange} onKeyDown={handleKeyDown} label={'Search'} />
      {!props.onlyRef && (
        <SelectLayoutStyled
          data={refs}
          onChange={handleRefChange}
          label="Ref"
          optionLabelKey="label"
          optionValueKey="value"
          currentValue={ref}
        />
      )}
      <DialectSelectStyled
        handleDialectChange={handleDialectChange}
        onlyTarget={props.onlyTarget}
        onlySource={props.onlySource}
      />
      {!props.onlyCourseId && <CourseSelectStyled onChange={handleCourseChange} courseId={course} />}
      <SearchButton variant="outlined" size="small" onClick={doSearch}>
        search
      </SearchButton>
    </EditorRow>
  );
};

export default SearchBarDialectRef;

const TextFieldRestyled = styled(TextFieldStyled)`
  width: auto;
  flex-grow: 1;
  && {
    margin-right: 0;
    margin-left: 4px;
  }
  & .MuiInputBase-root {
    height: 33px;
  }
`;

const DialectSelectStyled = styled(DialectSelect)`
  width: 100px;
  && {
    margin-right: 0;
    margin-left: 4px;
  }
`;

const CourseSelectStyled = styled(CourseSelect)`
  flex-grow: 2;
`;

const SearchButton = styled(Button)`
  && {
    height: 33px;
  }
`;

const SelectLayoutStyled = styled(SelectLayout)`
  width: 120px;
  flex-grow: 0;
  && {
    margin-right: 0;
    margin-left: 4px;
  }
`;
