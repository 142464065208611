import { get } from 'services/request';

export type IDialectDef = {
  code: string;
  default: boolean;
  id: number;
  name: string;
};

export default class DialectModel {
  courseId: number;

  constructor(courseId: number) {
    this.courseId = courseId;
  }

  async getTargetDialects() {
    return await get<IDialectDef[]>(`courses/${this.courseId}/dialects-target`);
  }

  async getSourceDialects() {
    return await get<IDialectDef[]>(`courses/${this.courseId}/dialects-source`);
  }
}
