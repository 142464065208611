import ModuleContext from 'CMFW/context/ModuleContext';
import Checkbox from 'CMFW/input/Checkbox';
import { ListDialects } from 'CMFW/layout/ListDialects';
import { IItemSource } from 'models/ItemModel';
import React, { useContext, useEffect, useState } from 'react';
import ItemSource from './components/ItemSource';

const Sources: React.FC = () => {
  const [showSources, setShowSources] = useState(false);

  const module = useContext(ModuleContext);
  const sources: IItemSource[] = module.getItemValue('sources') || [];
  const withSources: boolean = module.getItemUpdatedValue('itemWithSource');

  useEffect(() => {
    setShowSources(withSources);
  }, [withSources]);

  const handleCheckboxChange = (value: boolean) => {
    if (!value) {
      const updatedSources = sources.map((source) => ({ ...source, text: '' }));
      module.updateItemValue.bind(module)('sources', updatedSources);
    }
    setShowSources(value);
  };

  return (
    <>
      <Checkbox keyName="itemWithSource" label="with Sources" onChange={handleCheckboxChange} />
      {showSources && (
        <ListDialects keyName={'sources'}>
          <ItemSource />
        </ListDialects>
      )}
    </>
  );
};

export default Sources;
