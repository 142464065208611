import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import ModuleContext from '../../CMFW/context/ModuleContext';
import NoteContext from '../../models/NoteContext';
import NoteModel from '../../models/NoteModel';
import NoteNew from './NoteNew';
import { NoteView } from './NoteView';

const Note: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const module = useContext(ModuleContext);
  const handleToggleClick = () => setIsOpen(!isOpen);
  const forceRender = useForceRender();
  const ref = module.getRef();
  const itemId = module.getItemId();
  const noteModel = useMemo(() => new NoteModel(forceRender, module, ref, itemId), [forceRender, ref, itemId, module]);
  if (module.getItemId() === module.NEW_CREATE_ID) {
    if (isOpen) {
      setIsOpen(false);
    }
    return null;
  }

  return (
    <Wrapper $isOpen={isOpen}>
      <Toggle onClick={handleToggleClick}>
        <FontAwesomeIconStyled icon={isOpen ? faAngleDoubleRight : faAngleDoubleLeft} />
      </Toggle>
      {isOpen && (
        <NoteContext.Provider value={noteModel}>
          <NoteNew />
          {noteModel.getNotes().map((note, index) => (
            <NoteView note={note} key={index} />
          ))}
        </NoteContext.Provider>
      )}
      {noteModel.isLoading() && <LoadingNote />}
    </Wrapper>
  );
};

export default Note;

const WIDTH = 240;
const TOGGLE_WIDTH = 16;
const TRANSITION_DURATION_MS = 100;

const Wrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => (props.$isOpen ? WIDTH : TOGGLE_WIDTH)}px;
  padding: 4px;
  background-color: #fffbe7;
  border-left: solid 1px #ffa500;
  transition: all ${TRANSITION_DURATION_MS}ms;
  ${(props) =>
    props.$isOpen &&
    css`
      box-shadow: #0000006b -1px 0px 20px;
    `}
`;

const Toggle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${TOGGLE_WIDTH}px;
  box-sizing: border-box;
  padding-left: 2px;
  cursor: pointer;
  z-index: 10;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: #b97b08;
  font-size: 14px;
`;

const LoadingNote = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;
