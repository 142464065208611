import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IPanelEstructureBlock, IPanelEstructureCard } from '../../../../types/panelEstructure';
import PanelEstructureElement from './PanelEstructureElement';
import DragHandle from '../DragHandle';
import BlockEditModal from '../../modal/BlockEditModal';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import PanelEstructureModel from '../../../../models/PanelEstructureModel';
import PanelEstructureElementNew from '../components/PanelEstructureElementNew';
import PanelEstructureContext from '../context/PanelEstructureContext';

export type IProps = {
  block: IPanelEstructureBlock;
  index: number;
};

const PanelEstructureBlock: React.FC<IProps> = (props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const module = useContext(ModuleContext) as PanelEstructureModel;
  const panelEstructureContext = useContext(PanelEstructureContext);

  const onShowModal = () => {
    panelEstructureContext.editable && setShowEditModal(true);
  };

  const onHideModal = () => {
    setShowEditModal(false);
  };

  const onEditBlock = async (type: number, isNote: boolean) => {
    await module.updateBlock(props.block.id, type, isNote);
  };

  return (
    <Draggable
      isDragDisabled={!panelEstructureContext.editable}
      draggableId={`block-${props.block.id}`}
      index={props.index}
    >
      {(provided) => (
        <Wrapper isNote={props.block.note} {...provided.draggableProps} ref={provided.innerRef}>
          <BlockSide isNote={props.block.note}>
            {panelEstructureContext.editable && (
              <DragHandle className={'hover_drag_handle'} {...provided.dragHandleProps} />
            )}
            <BlockName editable={panelEstructureContext.editable} onClick={onShowModal}>
              {props.block.name}
            </BlockName>
          </BlockSide>
          <Droppable
            isDropDisabled={!panelEstructureContext.editable}
            droppableId={`block-${props.block.id}`}
            direction={'vertical'}
            type={IPanelEstructureCard.ELEMENT}
          >
            {(providedDroppable, snapshot) => (
              <WrapperElements
                isDraggingOver={snapshot.isDraggingOver}
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {props.block.elements.map((element, index) => (
                  <PanelEstructureElement element={element} key={`element-${element.elementId}`} index={index} />
                ))}
                {providedDroppable.placeholder}
                {panelEstructureContext.editable && <PanelEstructureElementNew blockId={props.block.id} />}
              </WrapperElements>
            )}
          </Droppable>
          {showEditModal && (
            <BlockEditModal
              blockId={props.block.block}
              isNote={props.block.note}
              onClose={onHideModal}
              onEditItem={onEditBlock}
            />
          )}
        </Wrapper>
      )}
    </Draggable>
  );
};

export default PanelEstructureBlock;

const Wrapper = styled.div<{ isNote: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.isNote ? '#ffe8bd' : '#f5f5f5')};
  margin: 4px;
  & :hover .hover_drag_handle {
    opacity: 1;
  }
  border-radius: 4px 0 0 4px;
`;

const BlockSide = styled.div<{ isNote: boolean }>`
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  background-color: ${(props) => (props.isNote ? '#ffe8bd' : '#f5f5f5')};
`;

const BlockName = styled.p<{ editable: boolean }>`
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  width: 1px;
  word-wrap: break-word;
  font-family: monospace;
  padding-right: 8px;
  line-height: 14px;
  cursor: ${(props) => (props.editable ? 'pointer' : 'default')};
  margin: 0 0 10px 0;
`;

const WrapperElements = styled.div<{ isDraggingOver: boolean }>`
  position: relative;
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => (props.isDraggingOver ? '#f5f5e8' : 'none')};
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f6f6f6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 3px;
  }
`;
