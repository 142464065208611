import { Kline } from 'components/common/styles';
import { TooltipContainer } from 'components/conceptLink/styles';
import { IConceptLinkerPanel } from 'components/conceptLink/TooltipPanelsModel';
import { IRefIds } from 'constants/refs';
import { useTooltipData } from 'hooks/useTooltipData';
import React from 'react';
import styled from 'styled-components';

export type IProps = {
  id: number;
  courseId: number;
  targetDialectId: number;
};

const TooltipPanel: React.FC<IProps> = (props) => {
  const { id, courseId, targetDialectId } = props;


  const data = useTooltipData({
    ref: IRefIds.panels,
    id,
    courseId,
    targetDialectId,
  }) as IConceptLinkerPanel;

  if (!data) {
    return null;
  }

  const { kLines, title, concept } = data;

  return (
    <TooltipContainer>
      <Klines>{kLines.map((kline) => (kline.visible ? <Kline key={kline.name}>{kline.name}</Kline> : null))}</Klines>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Concept dangerouslySetInnerHTML={{ __html: concept }} />
    </TooltipContainer>
  );
};

const Title = styled.div`
  font-weight: bold;
`;

const Concept = styled.div``;

const Klines = styled.div``;

export default TooltipPanel;
