import { tooltipLoader } from 'components/conceptLink/TooltipLoader';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IDialectAware, IItemId, IPosition } from 'types/content';
import { IGridData } from 'types/models';
import { IRefIds } from '../constants/refs';
import { IInflectConfig } from '../types/inflect';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { ITranslation } from '../types/Translation';
import TranslationManagerModel from './TranslationManagerModel';

const ALL_PANELS_ID = 0;

export type IPanel = {
  id: number;
  stateExplanation: number;
  stateContent: number;
  error: boolean;
  position: IPosition;
  kLines: string;
  level: number;
  item: string;
  wordClass: string;
  title: string;
  segments: number;
  notes: number;
};

export type IPanelEdit = {
  id: number;
  html: string;
  firstBlockPs: string;
  level: number;
  item: string;
  concept: string;
  wordClass: number;
  inflectStopPanel: number;
  kLines: number[];
  vocsLocked: number[];
  byVocsLocked: number[];
  panelsLocked: number[];
  byPanelsLocked: number[];
  compoundVocs: number[];
  compoundPanels: number[];
  compoundInflects: number[];
  autoCompoundType: number;
  autoCompoundWordClass: number;
  autoCompoundApplyOnlyNew: boolean;
  autoCompoundFirstPanel: boolean;
  autoCompoundOverwrite: boolean;
  deletable: boolean;
  targets: IPanelTargetEdit[];
  inflects: IInflectConfig[];
  clones: IClone[];
  sentences: number[];
  segments: number[];
  compounds: number[];
};

type IPanelTargetEdit = IDialectAware & {
  panelTargetId: number;
  titles: ITranslation[];
};

export type IKlinePanel = {
  id: number;
  panelId: number;
  name: string;
  showName: string;
  enabledWithDialectTarget: number;
  enabledWithDialectTargetCode: string;
  position: number;
  visible: boolean;
};

export type IClone = {
  title: string;
  concept: string;
  item: string;
  inflectWord: string;
};

type IPanelSearch = {
  text?: string;
  kLineId?: number;
};

export const getInitialClone = (): IClone => Object.assign({}, { title: '', concept: '', item: '', inflectWord: '' });

export default class PanelsModel<T extends IGridData = IPanel> extends BaseModel<T, IPanelEdit> {
  NEW_ID_PANEL = -1;
  translations: TranslationManagerModel[];

  constructor(options: IModelCourseOptions) {
    super(options);
    this.gridName = 'Panels';
    this.ref = IRefIds.panels;
    this.getGridColumns();
    this.translations = [];
  }

  getTranslation(index: number): TranslationManagerModel {
    if (!this.translations[index]) {
      this.translations[index] = new TranslationManagerModel({ ref: IRefIds.panelTitle });
    }
    return this.translations[index];
  }

  async getGrid(data: IPanelSearch) {
    await super.getGrid();
    this.gridData = await get<T[]>(
      `groups/${this.courseId}/panels${data.kLineId !== ALL_PANELS_ID ? `?kLine=${data.kLineId}` : ''}`,
    );

    this.loadingGrid = false;
    this.render();
  }

  async getGridSearch(text: string) {
    await super.getGrid();

    this.gridData = await get<T[]>(`groups/${this.courseId}/panels?text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  async getGridLesson(lesson: number) {
    await super.getGrid();

    this.gridData = await get<T[]>(`groups/${this.courseId}/panels?lesson=${lesson}`);

    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<T>(`groups/${this.courseId}/panels/${itemId}`);
  }

  async new() {
    await super.new();
    const [item, inflects, targets] = await Promise.all([
      get<IPanelEdit>(`panels/new`),
      get<IInflectConfig[]>(`courses/${this.courseId}/inflects-config/new`),
      get<IPanelTargetEdit[]>(`courses/${this.courseId}/panel-targets-title/new`),
    ]);

    const targetTitles = await Promise.all(
      targets.map((target, index) => this.getTranslation(index).new(this.courseId)),
    );
    targets.forEach((target, index) => {
      target.titles = targetTitles[index];
    });

    await this.setItem({
      ...item,
      inflects,
      targets,
      clones: [getInitialClone()],
    });

    this.render();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, inflects, targets] = await Promise.all([
      get<IPanelEdit>(`panels/${itemId}`),
      get<IInflectConfig[]>(`panels/${itemId}/inflects-config`),
      get<IPanelTargetEdit[]>(`panels/${itemId}/targets`),
    ]);

    const targetTitles = await Promise.all(
      targets.map((target, index) => this.getTranslation(index).get(target.panelTargetId)),
    );
    targets.forEach((target, index) => {
      target.titles = targetTitles[index];
    });

    await this.setItem({
      ...item,
      inflects,
      targets,
      clones: [getInitialClone()],
    });

    this.render();
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;

    if (id === this.NEW_ID_PANEL) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
    this.invalidateTooltip();
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IPanelEdit;
    const itemId = itemToSave.id;

    await put<IItemId>(`/panels/${itemId}`, lodash.omit(itemToSave, ['inflects', 'clones']));

    await this.savePanel(itemId);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToSave = this.itemUpdated as IPanelEdit;

    const result = await post<IItemId>(
      `/groups/${this.courseId}/panels`,
      lodash.omit(itemToSave, ['inflects', 'clones']),
    );

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await this.savePanel(newItemId);

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async savePanel(panelId: number) {
    const itemToSave = this.itemUpdated as IPanelEdit;

    const promises: Promise<any>[] = [];
    itemToSave.inflects.forEach((inflect) =>
      promises.push(put(`/panels/${panelId}/target-dialects/${inflect.dialectId}/inflects-config`, inflect)),
    );

    await Promise.all(promises);

    await Promise.all(
      itemToSave.targets.map((target, index) => this.getTranslation(index).update(target.panelTargetId, target.titles)),
    );
  }

  async delete() {
    const { id } = this.itemUpdated as IPanelEdit;

    const result = await del(`panels/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateTooltip();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  clone() {
    const all = this.itemUpdated as IPanelEdit;
    const itemsToClone = all.clones as IClone[];

    const resolvers: ((value?: unknown) => void)[] = [];
    const promises = itemsToClone.map((clone, index) => new Promise((resolve) => (resolvers[index] = resolve)));

    const requests = itemsToClone.map((item, index) =>
      post<IItemId>(`/groups/${this.courseId}/panels-clone`, {
        ...item,
        id: index + 1,
        panel: this.getItemId(),
      }),
    );

    requests.forEach((request, index) => {
      request.then((result) => {
        if (result.ok && result.data) {
          const newItemId = result.data.id;
          this.updateGridRow(newItemId, 'add');
          resolvers[index]();
        }
      });
    });

    return promises;
  }

  async copy(courseId: number, copySentences: boolean) {
    return await post<IItemId>(`/groups/${this.courseId}/panels-copy`, {
      panel: this.getItemId(),
      toCourseId: courseId,
      copySentences: copySentences,
    });
  }

  async search(data: IPanelSearch) {
    if (data.kLineId) {
      await this.getGrid({ kLineId: data.kLineId });
    } else if (data.text) {
      await this.getGridSearch(data.text);
    } else {
      await this.getGrid({ kLineId: ALL_PANELS_ID });
    }
  }

  private invalidateTooltip() {
    tooltipLoader && tooltipLoader.invalidate({ ref: IRefIds.panels, courseId: this.courseId, id: this.itemId });
  }
}
