/*global CKEDITOR*/
import PropTypes from 'prop-types';
import React from 'react';

class CKEditor extends React.Component {
  constructor(props) {
    super(props);

    this.element = null;
    this.editor = null;
    this.state = {
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    this._initEditor();
  }

  componentWillUnmount() {
    this._destroyEditor();
  }

  componentDidUpdate(prevProps) {
    const { props, editor } = this;

    /* istanbul ignore next */
    if (!editor) {
      return;
    }

    if (prevProps.parentKey !== props.parentKey || (prevProps.data !== props.data && editor.getData() !== props.data)) {
      editor.setData(props.data);
      this._checkCuratedData();
    }

    if (prevProps.readOnly !== props.readOnly) {
      editor.setReadOnly(props.readOnly);
    }

    if (editor.container && prevProps.style !== props.style) {
      editor.container.setStyles(props.style);
    }

    this._attachEventHandlers(prevProps);
  }

  render() {
    return <div id={this.props.id} style={this.props.style} ref={(ref) => (this.element = ref)}></div>;
  }

  _initEditor() {
    const { config, readOnly, type, onBeforeLoad, style, data, autoGrow } = this.props;
    config.readOnly = readOnly;

    const constructor = type === 'inline' ? 'inline' : 'replace';

    if (onBeforeLoad) {
      onBeforeLoad(CKEDITOR);
    }

    const editor = (this.editor = CKEDITOR[constructor](this.element, config));

    this._attachEventHandlers();

    // We must force editability of the inline editor to prevent `element-conflict` error.
    // It can't be done via config due to CKEditor 4 upstream issue (#57, ckeditor/ckeditor4#3866).
    if (type === 'inline' && !readOnly) {
      editor.on(
        'instanceReady',
        () => {
          editor.setReadOnly(false);
        },
        null,
        null,
        -1,
      );
    }

    const _setSize = () => {
      if (!autoGrow && this.element) {
        this.editor.resize('auto', this.element.parentNode.clientHeight, false);
      } else {
        try {
          this.editor.resize();
          if (autoGrow) {
            setTimeout(() => {
              this.editor.execCommand('autogrow');
            }, 0);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    if (style && type !== 'inline') {
      editor.on('loaded', () => {
        editor.container.setStyles(style);
        if (this.props.className) {
          editor.container.$.className += ' ' + this.props.className;
        }
      });
    }

    editor.on(
      'instanceReady',
      () => {
        editor.setReadOnly(false);

        if (!config.toolbar || !config.toolbar.length) {
          document.getElementById(`${editor.id}_top`).remove();
        }

        _setSize();
        this._checkCuratedData();
      },
      null,
      null,
      -1,
    );

    if (data) {
      editor.setData(data);
      this._checkCuratedData();
    }
  }

  _checkCuratedData() {
    setTimeout(() => {
      if (this.props.data !== this.editor.getData()) {
        this.props.onChange({ editor: this.editor });
      }
    }, 100);
  }

  _attachEventHandlers(prevProps = {}) {
    const props = this.props;

    Object.keys(this.props).forEach((propName) => {
      if (!propName.startsWith('on') || prevProps[propName] === props[propName]) {
        return;
      }

      this._attachEventHandler(propName, prevProps[propName]);
    });
  }

  _attachEventHandler(propName, prevHandler) {
    const evtName = `${propName[2].toLowerCase()}${propName.substr(3)}`;

    if (prevHandler) {
      this.editor.removeListener(evtName, prevHandler);
    }

    this.editor.on(evtName, this.props[propName]);
  }

  _destroyEditor() {
    if (this.editor) {
      this.editor.destroy();
    }

    this.editor = null;
    this.element = null;
  }
}

CKEditor.propTypes = {
  type: PropTypes.oneOf(['classic', 'inline']),
  data: PropTypes.string,
  config: PropTypes.object,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBeforeLoad: PropTypes.func,
  onBlockEditDialogClicked: PropTypes.func,
};

CKEditor.defaultProps = {
  type: 'classic',
  data: '',
  config: {},
  readOnly: false,
};

CKEditor.displayName = 'CKEditor';

export default CKEditor;
