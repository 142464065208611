import React from 'react';
import styled from 'styled-components';

export type IProps = {
  title: string;
  className?: string;
};

const CATPanel: React.FC<IProps> = (props) => {
  return (
    <Container className={props.className}>
      <Title>{props.title}</Title>
      {props.children}
    </Container>
  );
};

export default CATPanel;

const Container = styled.div`
  display: flex;
  width: calc(100% - 4px);
  flex-direction: column;
  margin-bottom: 20px;
  border: solid 1px #dddddd;
  min-height: 36px;
`;

const Title = styled.div`
  background-color: #dddddd;
  color: grey;
  font-size: 12px;
  padding: 3px 10px;
  text-transform: uppercase;
`;
