import { IRefIds } from 'constants/refs';
import { getCacheKey } from 'helpers/cache';
import { useCacheData } from 'hooks/useCacheData';
import UsersModel, { IUserSelect } from 'models/UsersModel';
import React, { useContext, useMemo } from 'react';
import { SelectLayout } from './SelectLayout';
import KeyContext from '../../CMFW/context/KeyContext';
import { createKeyPath } from '../../CMFW/context/helper/keyContext';
import ModuleContext from '../../CMFW/context/ModuleContext';
import { useCurrentItemValue } from '../../hooks/useCurrentItemValue';
import { NONE_OPTION } from './selects';

type IProps = {
  keyName?: string;
  onChange?: (userId: number) => void;
  className?: string;
};

const UserSelect: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);
  const keyContext = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(keyContext, props.keyName ?? '');
  const moduleItemValue = module.getItemValue(aggregatedKeyName);

  const [currentUserId, setCurrentUserId] = useCurrentItemValue(moduleItemValue, NONE_OPTION.id, !!props.keyName);
  const cacheKey = getCacheKey(IRefIds.users);
  const dataModel = useMemo(() => new UsersModel(), []);
  const cacheSelectData = useCacheData(cacheKey, dataModel.get.bind(dataModel));

  const handleChange = (value: IUserSelect) => {
    const selectedUserId = value.id;
    setCurrentUserId(selectedUserId);
    props.onChange && props.onChange(selectedUserId);
    !!props.keyName && module.updateItemValue(aggregatedKeyName, selectedUserId);
  };

  return (
    <SelectLayout
      className={props.className}
      data={cacheSelectData}
      onChange={handleChange}
      label="User"
      optionLabelKey="name"
      currentValue={currentUserId}
    />
  );
};

export default UserSelect;
