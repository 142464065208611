import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import styled from 'styled-components';
import AudioDashboard from './tabs/AudioDashboard';
import AudioSets from './tabs/AudioSets';

export type IProps = {
  courseId: number;
};

const Audio: React.FC<IProps> = (props) => {
  return (
    <Container>
      <ItemTabs tabNames={['Dashboard', 'Sets']}>
        <AudioDashboard courseId={props.courseId} />
        <AudioSets courseId={props.courseId} />
      </ItemTabs>
    </Container>
  );
};

const Container = styled(ModuleContainer)`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5px;
`;

export default Audio;
