import { IRefIds } from 'constants/refs';
import { BaseModel } from 'models/BaseModel';
import { get } from 'services/request';
import ConjugationBatchRefModel from './ConjugationBatchRefModel';

type IConjugationBatchResource = {
  ref: IRefIds;
  name: string;
  batch: number;
};

export default class ConjugationBatchModel extends BaseModel {
  resources: ConjugationBatchRefModel[] = [];
  allChecked = false;
  itemUpdated = []; //for displaying ItemLayout
  loadEnabled = true;
  batchConjugateEnabled = true;

  async getResources() {
    this.loadingItem = true;
    this.loadEnabled = false;
    this.render();
    const resources = await get<IConjugationBatchResource[]>(`conjugationBatchApply-resources`);
    this.resources = resources.map(
      (resource) =>
        new ConjugationBatchRefModel({
          render: this.render,
          courseId: this.courseId,
          ref: resource.ref,
          name: resource.name,
          batch: resource.batch,
          loadDone: this.nextLoad.bind(this),
          conjugationDone: this.nextBatchConjugate.bind(this),
        }),
    );
    this.loadingItem = false;
    this.render();
    await this.nextLoad();
  }

  async nextLoad() {
    const pendingLoads = this.resources.filter((resource) => resource.pending);
    if (pendingLoads.length === 0) {
      this.batchConjugateEnabled = true;
    } else {
      await pendingLoads[0].load();
    }
    this.render();
  }

  async batchConjugate() {
    this.batchConjugateEnabled = false;
    await this.nextBatchConjugate();
  }

  async nextBatchConjugate() {
    const pendingConjugate = this.resources.filter((resource) => !resource.completed);
    if (pendingConjugate.length === 0) {
    } else {
      await pendingConjugate[0].conjugate();
    }
    this.render();
  }
}
