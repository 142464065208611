import MaterialUICheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModuleContext from 'CMFW/context/ModuleContext';
import { WrapperChangedTransparent } from 'components/common/styles';
import { useCurrentItemValue } from 'hooks/useCurrentItemValue';
import React, { useContext, useEffect } from 'react';
import { createKeyPath } from '../context/helper/keyContext';
import KeyContext from '../context/KeyContext';

type IProps = {
  keyName: string;
  inverted?: boolean;
  label?: string;
  onChange?: (value: boolean) => void;
  className?: string;
};

const Checkbox: React.FC<IProps> = (props) => {
  const parentKey = useContext(KeyContext);
  const aggregatedKeyName = createKeyPath(parentKey, props.keyName);

  const module = useContext(ModuleContext);
  const moduleItemValue: boolean = module.getItemValue(aggregatedKeyName) || false;
  const moduleItemValueUpdated: boolean = module.getItemUpdatedValue(aggregatedKeyName) || false;

  const [currentValue, setCurrentValue] = useCurrentItemValue(moduleItemValue, false);

  useEffect(() => {
    setCurrentValue(moduleItemValueUpdated);
  }, [moduleItemValueUpdated, setCurrentValue]);

  const handleChange = () => {
    const newValue = !currentValue;
    setCurrentValue(newValue);
    props.onChange && props.onChange(newValue);
    module.updateItemValue.bind(module)(aggregatedKeyName, newValue);
  };

  return (
    <WrapperChangedTransparent className={props.className} $changed={moduleItemValue !== currentValue}>
      <FormControlLabel
        control={
          <MaterialUICheckbox
            checked={props.inverted ? !currentValue : currentValue}
            onChange={handleChange}
            color="primary"
          />
        }
        label={props.label ?? props.keyName}
      />
    </WrapperChangedTransparent>
  );
};

export default Checkbox;
