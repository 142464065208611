import Button from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useState } from 'react';
import TtsLabModel from '../../../models/TtsLabModel';
import CircularProgress from '@material-ui/core/CircularProgress';

const GenerateButton: React.FC = () => {
  const module = useContext(ModuleContext) as TtsLabModel;
  const [doing, setDoing] = useState(false);

  const onGenerate = () => {
    module.generate.bind(module)();
    setDoing(true);
  };

  if (doing) {
    return <CircularProgress />;
  }

  return (
    <Button variant="contained" size="small" color="secondary" onClick={onGenerate}>
      generate
    </Button>
  );
};

export default GenerateButton;
