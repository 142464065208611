import ModuleContext from 'CMFW/context/ModuleContext';
import { ContainerLeft, ContainerLeftHeader, ContainerRight, ModuleContainer } from 'components/common/styles';
import { useForceRender } from 'hooks/useForceRender';
import React, { useEffect, useMemo, useState } from 'react';
import AudioSetsModel from '../../../models/AudioSetsModel';
import { Split } from '../../../components/Split';
import SearchBarDialect from '../../../components/search/SearchBarDialect';
import Grid from '../../../CMFW/grid/Grid';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import styled from 'styled-components';
import { IFields } from '../../../CMFW/grid/fields';
import { statusRecordingRenderer } from '../../../CMFW/grid/cellRenderers/statusRecording';
import AudioSetsItemModel, { IAudioSetsItem } from '../../../models/AudioSetsItemModel';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@material-ui/core';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import { statusItemRecordingRenderer } from '../../../CMFW/grid/cellRenderers/statusItemRecording';
import AudioUploadWav from '../modals/AudioUploadWav';
import { IGridData } from '../../../types/models';
import AudioReviewItem from '../modals/AudioReviewItem';
import DownloadLink from '../../../components/common/DownloadLink';
import AudioTTS from '../modals/AudioTTS';

export type IProps = {
  courseId: number;
};

const DOING = 1;
const REVIEWING = 2;

const AudioSets: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const model = useMemo(() => new AudioSetsModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const modelItems = useMemo(() => new AudioSetsItemModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  const status = model.getItemUpdatedValue('statusRecording');
  const readBy = model.getItemUpdatedValue('readBy');
  const ssmlCode = model.getItemUpdatedValue('ssmlCode');
  const ttsEnabled = model.getItemUpdatedValue('ttsEnabled');

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showTTSModal, setShowTTSModal] = useState(false);
  const [reviewItemIndex, setReviewItemIndex] = useState(-1);
  const [reviewItems, setReviewItems] = useState([] as IAudioSetsItem[]);

  useEffect(() => {
    setReviewItems(modelItems.gridData);
  }, [modelItems.gridData]);

  useEffect(() => {
    if (model.itemId > 0) {
      modelItems.getSet(model.itemId);
    }
  }, [modelItems, model.itemId]);

  const rejectSet = async () => {
    await model.rejectSet(model.itemId);
    await modelItems.getSet(model.itemId);
  };

  const uploadWav = () => {
    setShowUploadModal(true);
  };

  const closeUploadWav = () => {
    setShowUploadModal(false);
  };

  const showTTS = () => {
    setShowTTSModal(true);
  };

  const closeTTS = () => {
    setShowTTSModal(false);
  };

  const selectReviewItem = (row: IGridData) => {
    if (status > DOING) {
      const itemId = row.id;
      let selectedIndex = -1;
      reviewItems.forEach((item, index) => {
        if (item.id === itemId) {
          selectedIndex = index;
        }
      });
      setReviewItemIndex(selectedIndex);
    }
  };

  const nextItem = () => {
    let selectedIndex = reviewItemIndex + 1;
    if (selectedIndex >= reviewItems.length) {
      selectedIndex = 0;
    }
    setReviewItemIndex(selectedIndex);
  };

  const prevItem = () => {
    let selectedIndex = reviewItemIndex - 1;
    if (selectedIndex < 0) {
      selectedIndex = reviewItems.length - 1;
    }
    setReviewItemIndex(selectedIndex);
  };

  const closeReviewItem = () => {
    setReviewItemIndex(-1);
  };

  const renderStatus = () => {
    if (status === 1) {
      return (
        <div>
          <p>
            <FontAwesomeIcon icon={faMicrophone} className={'state1'} /> DOING
            <DownloadLink url={model.getDownloadLink(model.itemId)} label={'download txt'} />
            <LinkStyled onClick={uploadWav}>upload wav</LinkStyled>
            {ttsEnabled && <LinkStyled onClick={showTTS}>TTS</LinkStyled>}
          </p>
        </div>
      );
    }

    if (status === 2) {
      return (
        <div>
          <p>
            <FontAwesomeIcon icon={faMicrophone} className={'state2'} /> PENDING REVIEW
            <LinkStyled onClick={rejectSet}>reject</LinkStyled>
          </p>
        </div>
      );
    }

    if (status === 3) {
      return (
        <div>
          <p>
            <FontAwesomeIcon icon={faMicrophone} className={'state3'} /> DONE
          </p>
        </div>
      );
    }
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={model}>
        <Split widthLeft={460}>
          <ContainerLeft>
            <ContainerLeftHeader>
              <SearchBarDialect onlyTarget />
            </ContainerLeftHeader>
            <Grid
              cellRenderers={{
                [IFields.statusRecording]: statusRecordingRenderer,
              }}
            />
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              {renderStatus()}
              <ModuleContext.Provider value={modelItems}>
                <Grid
                  cellRenderers={{
                    [IFields.status]: statusItemRecordingRenderer,
                  }}
                  handleRowClick={selectReviewItem}
                />
              </ModuleContext.Provider>
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRight>
        </Split>
        {showUploadModal && <AudioUploadWav onClose={closeUploadWav} />}
        {showTTSModal && <AudioTTS ssmlCode={ssmlCode} readBy={readBy} onClose={closeTTS} />}
        {reviewItemIndex >= 0 && (
          <AudioReviewItem
            onClose={closeReviewItem}
            onNext={nextItem}
            onPrev={prevItem}
            item={reviewItems[reviewItemIndex]}
            reviewEnabled={status === REVIEWING}
            index={reviewItemIndex}
            total={reviewItems.length}
            modelItems={modelItems}
          />
        )}
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default AudioSets;

const LinkStyled = styled(Link)`
  && {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 20px;
  }
`;
