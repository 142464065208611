import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PanelEstructureModel from '../../../../models/PanelEstructureModel';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { MenuItem, Popover } from '@material-ui/core';
import { IRefIds } from '../../../../constants/refs';
import VocSearchModal from '../../modal/VocSearchModal';
import { IVoc } from '../../../../models/VocabularyModel';
import SentenceSearchModal from '../../modal/SentenceSearchModal';
import { ISentence } from '../../../../models/SentenceModel';
import ConjSearchModal, { IConjSearchModal } from '../../modal/ConjSearchModal';
import TableSelectNewModal from '../../modal/TableSelectNewModal';
import { ITable } from '../../../../models/TableModel';
import { IChart } from '../../../../models/ChartModel';
import ChartSelectNewModal from '../../modal/ChartSelectNewModal';
import ItemSearchModal from '../../modal/ItemSearchModal';
import { IItem } from '../../../../models/ItemModel';
import ParagraphSelectNewModal from '../../modal/ParagraphSelectNewModal';

export type IProps = {
  blockId: number;
};

const PanelEstructureElementNew: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext) as PanelEstructureModel;
  const [modal, setModal] = useState(IRefIds.NONE);

  const showModal = (ref: IRefIds) => {
    setModal(ref);
  };
  const hideModal = () => {
    setModal(IRefIds.NONE);
  };

  const onAddVoc = async (data: IVoc) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.vocs,
      itemId: data.id,
    });
  };

  const onAddSentence = async (data: ISentence) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.sentences,
      itemId: data.id,
    });
  };

  const onAddItem = async (data: IItem) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.items,
      itemId: data.id,
    });
  };

  const onAddConj = async (data: IConjSearchModal) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.conjugation,
      data: [data.tableId, data.ref, data.id].join(','),
    });
  };

  const onAddTable = async (data: ITable) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.table,
      itemId: data.id,
    });
  };

  const onNewTable = async (name: string) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.table,
      name,
    });
  };

  const onAddChart = async (data: IChart) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.chart,
      itemId: data.id,
    });
  };

  const onNewChart = async (name: string) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.chart,
      name,
    });
  };

  const onChooseTranslation = async (translationId: number) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref: IRefIds.paragraph,
      translationId,
    });
  };

  const onNewElement = async (ref: IRefIds) => {
    hideModal();
    await module.addElement({
      block: props.blockId,
      ref,
    });
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Wrapper>
            <FontAwesomeIcon icon={faPlus} {...bindTrigger(popupState)} />
          </Wrapper>
          <Popover {...bindPopover(popupState)}>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.vocs);
                popupState.close();
              }}
            >
              Vocabulary
            </MenuItem>
            <MenuItem
              onClick={() => {
                onNewElement(IRefIds.grid_voc);
                popupState.close();
              }}
            >
              Grid Vocabulary
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.items);
                popupState.close();
              }}
            >
              Item
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.sentences);
                popupState.close();
              }}
            >
              Sentence
            </MenuItem>
            <MenuItem
              onClick={() => {
                onNewElement(IRefIds.sentences);
                popupState.close();
              }}
            >
              New Sentence
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.paragraph);
                popupState.close();
              }}
            >
              Paragraph
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.conjugation);
                popupState.close();
              }}
            >
              Conjugation
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.table);
                popupState.close();
              }}
            >
              Table
            </MenuItem>
            <MenuItem
              onClick={() => {
                showModal(IRefIds.chart);
                popupState.close();
              }}
            >
              Chart
            </MenuItem>
            <MenuItem
              onClick={() => {
                onNewElement(IRefIds.grid_words);
                popupState.close();
              }}
            >
              x Grid Words
            </MenuItem>
            <MenuItem
              onClick={() => {
                onNewElement(IRefIds.quote_sentence);
                popupState.close();
              }}
            >
              x Quote Sentence
            </MenuItem>
            <MenuItem
              onClick={() => {
                onNewElement(IRefIds.panel_img);
                popupState.close();
              }}
            >
              x Img
            </MenuItem>
          </Popover>
          {modal === IRefIds.vocs && <VocSearchModal onClose={hideModal} onChooseVoc={onAddVoc} />}
          {modal === IRefIds.sentences && <SentenceSearchModal onClose={hideModal} onChooseSentence={onAddSentence} />}
          {modal === IRefIds.items && <ItemSearchModal onClose={hideModal} onChooseItem={onAddItem} />}
          {modal === IRefIds.conjugation && <ConjSearchModal onClose={hideModal} onChooseConj={onAddConj} />}
          {modal === IRefIds.table && (
            <TableSelectNewModal onClose={hideModal} onChooseTable={onAddTable} onNewTable={onNewTable} />
          )}
          {modal === IRefIds.chart && (
            <ChartSelectNewModal onClose={hideModal} onChooseChart={onAddChart} onNewChart={onNewChart} />
          )}
          {modal === IRefIds.paragraph && (
            <ParagraphSelectNewModal
              onClose={hideModal}
              onChooseTranslation={onChooseTranslation}
              onNewParagraph={() => {
                onNewElement(IRefIds.paragraph);
                popupState.close();
              }}
            />
          )}
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default PanelEstructureElementNew;

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  margin: 10px;
  display: flex;
  align-items: center;
`;
