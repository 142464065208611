import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { LoadingItem } from 'CMFW/layout/Loading';
import { Uses } from 'CMFW/layout/Uses';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import InflectConfig from 'components/inflect/InflectConfig';
import { WordClassSelect } from 'components/selects/WordClassSelect';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import CulturalvocModel from 'models/CulturalVocModel';
import { AudioPlayerTarget } from 'modules/Culturalvocs/AudioPlayerTarget';
import Note from 'modules/Note/Note';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ICulturalVocsTargetEdit } from 'types/culturalvocs';
import TextSearch from '../../components/search/TextSearch';
import Translations from '../../components/translation/Translations';

export type IProps = {
  courseId: number;
  search?: string;
};

const CulturalVoc: React.FC<IProps> = (props) => {
  const { courseId } = props;

  const forceRender = useForceRender();

  const moduleModel = useMemo(
    () =>
      new CulturalvocModel({
        render: forceRender,
        courseId,
        search: props.search,
      }),
    [courseId, forceRender, props.search],
  );

  const targets = moduleModel.getItemValue('targets');

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={450}>
          <ContainerLeft>
            <TextSearch />
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <ItemTabs tabNames={['Edit', 'Uses']}>
                <Container>
                  <Container2>
                    <EditorRow>
                      <WordClassSelect isLinked />
                      <EditorGap />
                      <SaveButton />
                    </EditorRow>
                    <EditorRow>
                      <TextInput keyName={'name'} label={'Name'} />
                      <TextInputGender keyName={'gender'} />
                    </EditorRow>
                    <EditorRow>
                      <PlayerContainer>
                        {targets &&
                          targets.map((target: ICulturalVocsTargetEdit, index: number) => (
                            <AudioPlayerTarget
                              key={target.id + '-' + target.dialectId}
                              targetCode={target.dialectCode}
                              index={index}
                            />
                          ))}
                      </PlayerContainer>
                    </EditorRow>
                    <Scroll>
                      <InflectConfig keyName={'targets'} />
                      <Translations keyName={'sources'} refId={moduleModel.ref} />
                    </Scroll>
                  </Container2>
                  <DeleteButton />
                </Container>
                <Uses />
              </ItemTabs>
            </ItemLayout>
            <Note />
            <LoadingItem />
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  overflow: hidden;
`;

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const TextInputGender = styled(TextInput)`
  width: 70px;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default CulturalVoc;
