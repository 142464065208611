import { CircularProgress, LinearProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ConjugationBatchRefModel from '../../../models/ConjugationBatchRefModel';

type IProps = {
  model: ConjugationBatchRefModel;
};

const ConjBatchRef: React.FC<IProps> = (props) => {
  const { checks, pending, name, conjugating } = props.model;

  if (!pending && checks && checks.length === 0) {
    return null;
  }
  const done = props.model.done.length;
  const total = props.model.items.length;
  const percent = (done * 100) / total;

  return (
    <Resource>
      <Name>{name}</Name>
      <ProgressWrapper>
        <Progress variant={pending ? 'indeterminate' : 'determinate'} value={pending ? 0 : percent} />
        <Indicator>{pending ? 'Loading ...' : `${done}/${total}`}</Indicator>
      </ProgressWrapper>
      {conjugating && <CircularProgress size={20} />}
    </Resource>
  );
};

export default ConjBatchRef;

const Resource = styled.div`
  width: 450px;
  padding: 10px;
`;

const Name = styled.span`
  width: 180px;
  display: inline-block;
  vertical-align: top;
`;

const ProgressWrapper = styled.div`
  display: inline-block;
  width: 200px;
  position: relative;
  margin-right: 10px;
`;

const Progress = styled(LinearProgress)`
  && {
    height: 20px;
  }
`;
const Indicator = styled.div`
  position: absolute;
  top: 1px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-shadow: 0.1em 0.1em 1px #424242;
`;
