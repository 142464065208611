import { tooltipLoader } from 'components/conceptLink/TooltipLoader';
import { IRefIds } from 'constants/refs';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IItemId, IPosition } from 'types/content';
import { IInflectConfig } from '../types/inflect';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';

export type ICompoundType = {
  id: number;
  name: string;
  course: number;
};

export type ICompound = {
  id: number;
  position: IPosition;
  compoundType: string;
  title: string;
  overwrite: boolean;
  notes: number;
};

export type ICompoundEdit = {
  compoundType: number;
  id: number;
  items: ICompoundItem[];
  overwriteInflect: boolean;
  wordClass: number;
  title: string;
  inflects: IInflectConfig[];
};

export type ICompoundItem = {
  ref: number;
  refId: number;
  order: number;
};

export default class CompoundModel extends BaseModel<ICompound, ICompoundEdit> {
  gridCompoundTypeId = 0;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.compounds;
    this.gridName = 'Compounds';
  }

  setGridCompoundTypeId(id: number) {
    this.gridCompoundTypeId = id;
    this.getGridSearch();
  }

  async getGridSearch(text?: string) {
    await super.getGrid();
    this.render();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    const query = text ? `text=${text}` : `compoundType=${this.gridCompoundTypeId}`;
    this.gridData = await get<ICompound[]>(`groups/${this.courseId}/compounds?${query}`);

    this.loadingGrid = false;
    this.render();
  }

  getKlineId() {
    return undefined;
  }

  async getGridRow(itemId: number) {
    return await get<ICompound>(`groups/${this.courseId}/compounds/${itemId}`);
  }

  async new() {
    await super.new();

    Promise.all([
      get<ICompoundEdit>(`compounds/new`),
      get<IInflectConfig[]>(`courses/${this.courseId}/compound-targets/new`),
    ]).then(async ([compound, inflects]) => {
      await this.setItem({
        ...compound,
        inflects,
      });

      this.render();
    });
  }

  async loadItemEdit(id: number) {
    await super.loadItemEdit(id);

    Promise.all([get<ICompoundEdit>(`compounds/${id}`), get<IInflectConfig[]>(`compounds/${id}/targets`)]).then(
      async ([compound, targets]) => {
        await this.setItem({
          ...compound,
          inflects: targets,
        });

        this.render();
      },
    );
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated as ICompoundEdit;

    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }

    this.invalidateTooltip();
  }

  async updateItem() {
    const itemToSave: ICompoundEdit = this.itemUpdated;
    const itemId = itemToSave.id;

    await Promise.all([
      put<IItemId>(`/compounds/${itemId}`, lodash.omit(itemToSave, 'inflects')),
      ...itemToSave.inflects.map((inflect) =>
        put<IItemId>(`/compounds/${itemId}/target-dialects/${inflect.dialectId}`, inflect),
      ),
    ]);

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate: ICompoundEdit = this.itemUpdated;

    const result = await post<IItemId>(`/groups/${this.courseId}/compounds`, {
      ...lodash.omit(itemToCreate, 'inflects'),
      id: this.NEW_CREATE_ID,
    });

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all([
        ...itemToCreate.inflects.map((inflect) =>
          put<IItemId>(`/compounds/${newItemId}/target-dialects/${inflect.dialectId}`, inflect),
        ),
      ]);

      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async reload() {
    this.getGridSearch();
  }

  async delete() {
    const { id } = this.itemUpdated as ICompoundEdit;

    const result = await del(`compounds/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateTooltip();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async search(data: IModuleSearch) {
    this.getGridSearch(data.text);
  }

  private invalidateTooltip() {
    tooltipLoader && tooltipLoader.invalidate({ ref: IRefIds.compounds, courseId: this.courseId, id: this.itemId });
  }
}
