import { del, get, put } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
import { IDialogSegment, IDialogSegmentEdit, IDialogSegmentTarget } from '../types/dialog';
import DialogModel from './DialogModel';
import { IRefIds } from '../constants/refs';
import * as lodash from 'lodash';
import TranslationManagerModel from './TranslationManagerModel';

export default class DialogSegmentModel extends BaseModel<IDialogSegment, IDialogSegmentEdit> {
  dialogModel: DialogModel | null = null;
  translations: TranslationManagerModel;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.dialog;
    this.translations = new TranslationManagerModel({ ref: IRefIds.dialogSegment });
  }

  async save() {
    await super.save();
    await this.updateItem();
  }

  async updateItem() {
    const item = this.itemUpdated as IDialogSegmentEdit;

    const sources = item.sources;
    const targets = item.targets;

    await Promise.all([
      put(`dialogs/segments/${item.id}`, lodash.omit(item, ['targets', 'sources'])),
      ...targets.map((target) => put(`/dialogs/segments/${item.id}/target-dialect/${target.dialectId}`, target)),
    ]);
    await this.translations.update(item.id, sources);
    await Promise.all([this.loadItemEdit(item.id), this.reloadDialogModel()]);
  }

  async delete() {
    await super.delete();
    const item = this.itemUpdated as IDialogSegmentEdit;
    await del(`dialogs/segments/${item.id}`);
    this.itemUpdated = null;
    this.render();
    await this.reloadDialogModel();
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    const [item, targets, sources] = await Promise.all([
      get<IDialogSegmentEdit>(`dialogs/segments/${itemId}`),
      get<IDialogSegmentTarget[]>(`dialogs/segments/${itemId}/targets`),
      this.translations.get(itemId),
    ]);

    item.targets = targets;
    item.sources = sources;
    await this.setItem(item);
    this.render();
  }

  async reloadDialogModel() {
    if (this.dialogModel) {
      await this.dialogModel.forceReloadItemEdit.bind(this.dialogModel)();
    }
  }
}
