import { get } from 'services/request';
import { IRefIds } from '../constants/refs';

export type ITranslationProjectSelect = {
  id: number;
  name: string;
  ref: IRefIds;
};

export default class TranslationProjectsModel {
  courseId = 0;

  constructor(courseId: number) {
    this.courseId = courseId;
  }
  async get() {
    const projects: ITranslationProjectSelect[] = await get<ITranslationProjectSelect[]>(
      `/courses/${this.courseId}/translations/projects/select`,
    );
    return projects.sort((a, b) => a.name.localeCompare(b.name));
  }
}
