import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import { ItemTabs } from '../../CMFW/layout/ItemTabs';
import ConceptLinkerChecker from './tabs/ConceptLinkerChecker';

export type IProps = {
  courseId: number;
};

const ConceptLinker: React.FC<IProps> = (props) => {
  return (
    <ModuleContainer>
      <ItemTabs tabNames={['Checker', '']}>
        <ConceptLinkerChecker courseId={props.courseId} />
        <div />
      </ItemTabs>
    </ModuleContainer>
  );
};

export default ConceptLinker;
