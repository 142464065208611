import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { IGridData } from '../types/models';
import { BaseModel } from './BaseModel';

export type IAIContentCheckerRef = {
  ref: IRefIds;
  name: string;
  batchSize: number;
};

export default class AIContentCheckerModel extends BaseModel<IGridData, IAIContentCheckerRef[]> {
  async loadItemEdit(itemId = 0) {
    await super.loadItemEdit(itemId);
    const checkers = await get<IAIContentCheckerRef[]>(`/ai/content/checker-refs`);
    await this.setItem(checkers);
    this.render();
  }
}
