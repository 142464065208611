import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { FormControlLabel, Switch } from '@material-ui/core';
import { EditorGapGreedy, EditorRow } from '../../../components/common/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IReadingEdit } from '../../../types/reading';
import BookChapterModel from '../../../models/BookChapterModel';
import BookChapterTextListCard from './BookChapterTextListCard';
import BookNewTextButton from './BookNewTextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { LessonSelect } from '../../../components/selects/LessonSelect';
import TextInput from '../../../CMFW/input/TextInput';
import SaveButton from '../../../CMFW/button/SaveButton';
import DeleteButton from '../../../CMFW/button/DeleteButton';
import ItemLayout from '../../../CMFW/layout/ItemLayout';

export type IProps = {
  onEditText: (textId: number) => void;
};

const BookChapterTextList: React.FC<IProps> = (props) => {
  const [dnd, setDnd] = useState(false);
  const [editTextId, setEditTextId] = useState(0);
  const module = useContext(ModuleContext) as BookChapterModel;
  const item = module.itemUpdated as IReadingEdit;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (module.loadingItem && openModal) {
      setOpenModal(false);
    }
  }, [module.loadingItem, openModal]);

  const onEditText = (textId: number) => {
    props.onEditText(textId);
    setEditTextId(textId);
  };

  return (
    <Wrapper>
      <ItemLayout>
        <ChapterWrapper>
          <FontAwesomeIconStyled icon={faEdit} color={'#6b6b6b'} onClick={() => setOpenModal(true)} />
          {item.title}
        </ChapterWrapper>
        <DragDropContext onDragEnd={module.onDragEndText.bind(module)}>
          <Droppable isDropDisabled={!dnd} droppableId={'Reading'} direction={'vertical'}>
            {(provided) => (
              <DropWrapper {...provided.droppableProps} ref={provided.innerRef}>
                {item &&
                  item.texts &&
                  item.texts.map((text, index) => (
                    <BookChapterTextListCard
                      text={text}
                      index={index}
                      dnd={dnd}
                      editing={text.id === editTextId}
                      key={`readingText-${text.id}`}
                      onEditText={onEditText}
                    />
                  ))}
              </DropWrapper>
            )}
          </Droppable>
        </DragDropContext>
        <EditorRow>
          <BookNewTextButton />
          <FormControlLabel
            control={
              <Switch
                checked={dnd}
                onChange={() => setDnd(!dnd)}
                color="primary"
                size="small"
                disabled={item && item.texts && item.texts.length < 2}
              />
            }
            label="DND"
          />
        </EditorRow>
        {openModal && (
          <ModalWrapper title={'Edit Chapter'} width={500} height={300} onClose={() => setOpenModal(false)}>
            <EditorRow>
              <LessonSelect isLinked />
            </EditorRow>
            <EditorRow>
              <TextInput keyName={'title'} label={'Title'} />
              <SaveButton />
            </EditorRow>
            <EditorGapGreedy />
            <DeleteButton />
          </ModalWrapper>
        )}
      </ItemLayout>
    </Wrapper>
  );
};

export default BookChapterTextList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 180px;
  border-style: solid;
  border-color: #bfbfbf;
  border-width: 0;
  border-right-width: 1px;
`;

const ChapterWrapper = styled.div`
  font-size: 12px;
  border: solid 1px #bfbfbf;
  background-color: #e9dec7;
  position: relative;
  margin: 0 2px;
  padding: 2px 4px;
`;

const DropWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  padding-right: 10px;
  cursor: pointer;
`;
