import ModuleContext from 'CMFW/context/ModuleContext';
import { DropDown } from 'components/DropDown';
import BoardsModel, { IFolders, IFreelanceCount } from 'models/BoardsModel';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { renderCard } from './cardRender';

const Folders: React.FC = () => {
  const boardModel = useContext(ModuleContext) as BoardsModel;
  const folders = boardModel.getItemValue('doneFolders') as IFolders;

  if (!folders) {
    return null;
  }

  const renderFolderSummary = (freelanceCount: IFreelanceCount) => {
    return Object.keys(freelanceCount).map((name) => (
      <p key={name}>
        {name}: {freelanceCount[name]}
      </p>
    ));
  };

  return (
    <>
      {Object.keys(folders).map((date) => (
        <DropDown
          key={date}
          summary={
            <span>
              <b>{date}</b>&nbsp;[{folders[date].cards.length}]
            </span>
          }
        >
          <Folder>
            <FolderSummary>{renderFolderSummary(folders[date].freelanceCount)}</FolderSummary>
            {folders[date].cards.map(renderCard)}
          </Folder>
        </DropDown>
      ))}
    </>
  );
};

const FolderSummary = styled.div`
  margin: 0 10px 10px 20px;
  border-bottom: solid 1px;
`;

const Folder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default Folders;
