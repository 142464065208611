import { IDialectAware } from 'types/content';
import { IAIReviewEdit } from './aiReview';
import { ICATMemoryGuest } from '../models/CATModel';

export type ITranslation = IDialectAware & {
  text: string;
  status: number;
  aiReview?: IAIReviewEdit;
  error?: string;
  memory?: ICATMemoryGuest[];
};

export enum ITranslationStatus {
  TRANSLATION_PENDING = 0,
  TRANSLATION_DOING = 1,
  TRANSLATION_DONE = 2,
  TRANSLATION_FROM_TARGET = 3,
  TRANSLATION_LEGACY = 4,
}

export const TranslationNames: { [key in ITranslationStatus]: string } = {
  [ITranslationStatus.TRANSLATION_PENDING]: 'Pending',
  [ITranslationStatus.TRANSLATION_DOING]: 'Doing',
  [ITranslationStatus.TRANSLATION_DONE]: 'Done',
  [ITranslationStatus.TRANSLATION_FROM_TARGET]: 'Target',
  [ITranslationStatus.TRANSLATION_LEGACY]: 'Legacy',
};

export const TranslationColors: { [key in ITranslationStatus]: string } = {
  [ITranslationStatus.TRANSLATION_PENDING]: '#ff7d7d',
  [ITranslationStatus.TRANSLATION_DOING]: '#ffa64e',
  [ITranslationStatus.TRANSLATION_DONE]: '#89ff89',
  [ITranslationStatus.TRANSLATION_FROM_TARGET]: '#8484ff',
  [ITranslationStatus.TRANSLATION_LEGACY]: '#cb80ff',
};
