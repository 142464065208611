import DeleteButton from 'CMFW/button/DeleteButton';
import NewButton from 'CMFW/button/NewButton';
import ReloadButton from 'CMFW/button/ReloadButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo } from 'react';
import SaveButton from '../../CMFW/button/SaveButton';
import CompoundTypeModel from '../../models/CompoundTypeModel';
import styled from 'styled-components';

export type IProps = {
  courseId: number;
};

const CompoundType: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const compoundTypeModel = useMemo(() => new CompoundTypeModel({ render: forceRender, courseId: props.courseId }), [
    forceRender,
    props.courseId,
  ]);

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={compoundTypeModel}>
        <Split widthLeft={250}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
              <NewButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRightStyled>
            <ItemLayout>
              <EditorRow>
                <TextInput keyName={'name'} label={'Name'} />
              </EditorRow>
              <SaveButton />
              <DeleteButton />
            </ItemLayout>
            <LoadingItem />
          </ContainerRightStyled>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default CompoundType;

const ContainerRightStyled = styled(ContainerRight)`
  padding: 0;
`;
