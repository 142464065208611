import { IRefIds } from 'constants/refs';
import { get } from 'services/request';
import { BaseModel, IModelCourseOptions } from './BaseModel';
export type IConjugationReport = {
  id: number;
};

export default class ConjugationReportModel extends BaseModel<IConjugationReport> {
  targetDialect: number;

  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.panelsFactory;
    this.gridName = 'ConjugationsReport';
    this.targetDialect = 0;
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async loadTarget(targetDialect: number) {
    this.targetDialect = targetDialect;
    await this.load();
  }

  async load() {
    this.cancelGetGrid();
    this.loadingGrid = true;
    this.render();
    this.gridData = await get<IConjugationReport[]>(`target-dialects/${this.targetDialect}/conjugations-report`);
    this.loadingGrid = false;
    this.render();
  }
}
