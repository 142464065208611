import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

type IProps = {
  handleTrashClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<SVGSVGElement>) => void;
  onlyHover?: boolean;
  label: ReactNode;
  showTrash: boolean;
};

export const TrashLabel: React.FC<IProps> = (props) => {
  return (
    <HeaderWrapper>
      {props.label}
      {props.showTrash && props.label && (
        <Trash icon={faTrash} onClick={props.handleTrashClick} $onlyHover={!!props.onlyHover} onFocus={props.onFocus} />
      )}
    </HeaderWrapper>
  );
};

const Trash = styled(FontAwesomeIcon)<{ $onlyHover: boolean }>`
  color: #e4b4b4;
  padding: 4px;
  cursor: pointer;
  visibility: ${(props) => (props.$onlyHover ? 'hidden' : 'visible')};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover ${Trash} {
    visibility: visible;
  }
`;
