import { IRefIds } from 'constants/refs';
import { del, get, post, put } from 'services/request';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { DropResult } from 'react-beautiful-dnd';
import { IBook, IBookChapter, IBookChapterTextId, IBookEdit, IBookId } from '../types/book';
import * as lodash from 'lodash';

export default class BookModel extends BaseModel<IBook, IBookEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.book;
    this.gridName = 'Books';
    this.getGridColumns();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGrid(data: IModuleSearch) {
    await super.getGrid();
    this.gridData = await get<IBook[]>(`courses/${this.courseId}/books`, data);
    this.loadingGrid = false;
    this.render();
  }

  async getGridRow(itemId: number) {
    return await get<IBook>(`courses/${this.courseId}/books/${itemId}`);
  }

  async reload() {
    await super.reload();
    await this.getGrid({});
  }

  async new() {
    await super.new();
    const item = await get<IBookEdit>(`/books/new`);
    item.chapters = [];
    await this.setItem(item);
    this.render();
  }

  public async newChapter() {
    this.loadingItem = true;
    this.render();
    const bookItem = this.itemUpdated as IBookEdit;
    await post<IBookChapterTextId>(`books/${bookItem.id}/chapters/new`, {});
    await this.loadItemEdit(bookItem.id);
    this.loadingItem = false;
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IBookEdit;
    const itemId = itemToSave.id;

    await put<IBook>(`/books/${itemId}`, { ...itemToSave });

    await Promise.all([this.updateGridRow(itemId, 'update'), this.loadItemEdit(itemId)]);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IBookEdit;
    const result = await post<IBookId>(`/courses/${this.courseId}/books`, lodash.omit(itemToCreate, ['chapters']));

    if (result.ok && result.data) {
      const newItemId = result.data.id;
      await Promise.all([this.updateGridRow(newItemId, 'add'), this.loadItemEdit(newItemId)]);
    }
  }

  async delete() {
    const itemId = this.itemUpdated.id;
    await del(`books/${itemId}`);
    await Promise.all([this.updateGridRow(itemId, 'remove'), this.new()]);
  }

  async getChapters(itemId: number) {
    const texts = await get<IBookChapter[]>(`books/${itemId}/chapters`);
    return texts.sort((a, b) => a.position - b.position);
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);
    const [item, chapters] = await Promise.all([get<IBookEdit>(`books/${itemId}`), this.getChapters(itemId)]);

    item.chapters = chapters.sort((a, b) => a.position - b.position);
    await this.setItem(item);
    this.render();
  }

  async forceReloadBookChapters() {
    const chapters = await this.getChapters(this.itemId);
    await this.setItem({ ...this.itemUpdated, chapters });
    this.render();
  }

  async search(data: IModuleSearch) {
    await this.getGrid(data);
  }

  public async onDragEndChapter(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const textId = parseInt(draggableId);
    await this.moveChapter(textId, source.index, destination.index);
  }

  private async moveChapter(chapterId: number, prevPos: number, newPos: number) {
    this.loadingItem = true;
    const item = this.itemUpdated as IBookEdit;
    this.arrayMove(item.chapters, prevPos, newPos);
    this.render();
    await put(`books/chapters/${chapterId}/move`, { destinationPos: newPos });
    this.loadingItem = false;
    this.render();
    await this.forceReloadBookChapters();
  }

  private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  }
}
