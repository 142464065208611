import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import TranslationAIChecker from '../AIReview/tabs/TranslationAIChecker';
import ContentAIChecker from './tabs/ContentAIChecker';
import TranslationDashboard from './tabs/AIReviewDashboard';
import TranslationSourceAIChecker from './tabs/TranslationSourceAIChecker';
import ContentTargetAIChecker from './tabs/ContentTargetAIChecker';
import TranslationDialectAllAIChecker from './tabs/TranslationDialectAllAIChecker';

export type IProps = {
  courseId: number;
};

const AIReview: React.FC<IProps> = (props) => {
  return (
    <ModuleContainer>
      <ItemTabs tabNames={['Dashboard', 'Translations', 'Sources', 'Targets', 'Source&Target', 'Other']}>
        <TranslationDashboard courseId={props.courseId} />
        <TranslationAIChecker courseId={props.courseId} />
        <TranslationSourceAIChecker courseId={props.courseId} />
        <ContentTargetAIChecker courseId={props.courseId} />
        <TranslationDialectAllAIChecker courseId={props.courseId} />
        <ContentAIChecker courseId={props.courseId} />
      </ItemTabs>
    </ModuleContainer>
  );
};

export default AIReview;
