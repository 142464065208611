import { ItemTabs } from 'CMFW/layout/ItemTabs';
import { ModuleContainer } from 'components/common/styles';
import React from 'react';
import InflectIds from './tabs/InflectIds';
import InflectPositions from './tabs/InflectPositions';
import InflectBatchTarget from './tabs/InflectBatchTarget';
import InflectBatchSource from './tabs/InflectBatchSource';

export type IProps = {
  courseId: number;
};

const Inflects: React.FC<IProps> = (props) => {
  return (
    <ModuleContainer>
      <ItemTabs tabNames={['Ids', 'Position', 'Batch Target', 'Batch Source']}>
        <InflectIds courseId={props.courseId} />
        <InflectPositions courseId={props.courseId} />
        <InflectBatchTarget courseId={props.courseId} />
        <InflectBatchSource courseId={props.courseId} />
      </ItemTabs>
    </ModuleContainer>
  );
};

export default Inflects;
