import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { FormControlLabel, Switch } from '@material-ui/core';
import { EditorRow } from '../../../components/common/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BookChapterListCard from './BookChapterListCard';
import { IBookEdit } from '../../../types/book';
import BookModel from '../../../models/BookModel';
import BookNewChapterButton from './BookNewChapterButton';

export type IProps = {
  onEditChapter: (chapterId: number) => void;
};

const BookChapterList: React.FC<IProps> = (props) => {
  const [dnd, setDnd] = useState(false);
  const [editChapterId, setEditChapterId] = useState(0);
  const module = useContext(ModuleContext) as BookModel;
  const item = module.itemUpdated as IBookEdit;

  const onEditChapter = (textId: number) => {
    props.onEditChapter(textId);
    setEditChapterId(textId);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={module.onDragEndChapter.bind(module)}>
        <Droppable isDropDisabled={!dnd} droppableId={'Reading'} direction={'vertical'}>
          {(provided) => (
            <DropWrapper {...provided.droppableProps} ref={provided.innerRef}>
              {item.chapters.map((chapter, index) => (
                <BookChapterListCard
                  chapter={chapter}
                  index={index}
                  dnd={dnd}
                  editing={chapter.id === editChapterId}
                  key={`bookChapter-${chapter.id}`}
                  onEditText={onEditChapter}
                />
              ))}
            </DropWrapper>
          )}
        </Droppable>
      </DragDropContext>
      <EditorRow>
        <BookNewChapterButton />
        <FormControlLabel
          control={
            <Switch
              checked={dnd}
              onChange={() => setDnd(!dnd)}
              color="primary"
              size="small"
              disabled={item.chapters.length < 2}
            />
          }
          label="DND"
        />
      </EditorRow>
    </Wrapper>
  );
};

export default BookChapterList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 150px;
  border-style: solid;
  border-color: #bfbfbf;
  border-width: 0;
  border-right-width: 1px;
`;

const DropWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
