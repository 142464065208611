import { ISelectedPositions } from 'components/conceptLink/ConceptLinkerGuessedTooltip';
import { ConceptsLinkerEnginePhrase } from 'components/conceptLink/ConceptsLinkerEngine_phrase';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type IProps = {
  positions: number[];
  engine: ConceptsLinkerEnginePhrase;
  handlePositionsSelected: (selected: ISelectedPositions, event: React.MouseEvent) => void;
  linkedPositions: number[];
};

const ConceptLinkerGuessedOption: React.FC<IProps> = (props) => {
  const { positions, engine, linkedPositions } = props;
  const { editorPhrase } = engine;

  const enabled = useMemo(() => {
    let enabled = true;
    positions.forEach((position) => {
      if (linkedPositions.indexOf(position) !== -1) {
        enabled = false;
      }
    });
    return enabled;
  }, [positions, linkedPositions]);

  const text = useMemo(() => {
    if (!editorPhrase) {
      return '';
    }
    let positionsText = editorPhrase.words[positions[0]];
    let previousPosition = positions[0];

    for (let i = 1; i < positions.length; i++) {
      if (positions[i] !== previousPosition + 1) {
        positionsText = positionsText + ' ... ';
      } else {
        positionsText = positionsText + ' ';
      }
      previousPosition = positions[i];
      positionsText = positionsText + editorPhrase.words[positions[i]];
    }
    return positionsText;
  }, [positions, editorPhrase]);

  const handleMouseEnter = () => {
    engine.addHighlight(positions);
  };

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.stopPropagation();
    if (enabled) {
      props.handlePositionsSelected({ positions, text }, event);
    }
  };

  if (!editorPhrase) {
    return null;
  }

  return (
    <Container $enabled={enabled} onMouseEnter={handleMouseEnter} onClick={handleClick}>
      {text}
    </Container>
  );
};

const Container = styled.p<{ $enabled: boolean }>`
  text-decoration: ${(props) => (props.$enabled ? 'underline' : 'line-through')};
  cursor: pointer;
  padding-left: 5px;
`;

export default ConceptLinkerGuessedOption;
