import TextField from '@material-ui/core/TextField';
import Autocomplete, { RenderInputParams } from '@material-ui/lab-old/Autocomplete';
import { WrapperChanged } from 'components/common/styles';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

const DEFAULT_OPTION_VALUE_KEY = 'id';

type IProps = {
  data: any[] | null;
  onChange(value: any): void;
  label?: string;
  optionLabelKey: string;
  processOptionLabel?(label: string, option?: any): string;
  optionValueKey?: string;
  currentValue: any;
  originalValue?: any;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
};

export const SelectLayout: React.FC<IProps> = memo((props) => {
  const {
    data,
    onChange,
    currentValue,
    originalValue,
    label,
    optionLabelKey,
    optionValueKey,
    processOptionLabel,
    disabled,
  } = props;

  const selectData = data || [];

  const selectedOption = useMemo(
    () => selectData.find((select) => select[optionValueKey || DEFAULT_OPTION_VALUE_KEY] === currentValue),
    [currentValue, optionValueKey, selectData],
  );

  const getOptionLabel = (option: any) => {
    const label = option ? option[optionLabelKey].replace(/(<([^>]+)>)/gi, '') : '';

    return processOptionLabel ? processOptionLabel(label, option) : label;
  };

  const renderOption = (option: any) => <span dangerouslySetInnerHTML={{ __html: getOptionLabel(option) }} />;

  const loading = data === null;

  return (
    <Wrapper $changed={originalValue != null && currentValue !== originalValue} className={props.className}>
      <AutoCompleteStyled
        key={loading ? 'not loaded' : 'loaded'}
        renderInput={(params: RenderInputParams) => (
          <TextFieldStyled {...params} size="small" variant={'outlined'} label={label} />
        )}
        onChange={(event: React.ChangeEvent<{}>, value: any) => onChange(value)}
        autoSelect
        options={selectData}
        value={props.multiple ? undefined : selectedOption}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        disableClearable
        disabled={!!disabled}
        loading={loading}
        loadingText={<span>Loading...</span>}
        multiple={props.multiple}
      />
    </Wrapper>
  );
});

const Wrapper = styled(WrapperChanged)`
  flex-grow: 1;
`;

const AutoCompleteStyled = styled(Autocomplete)`
  width: 100%;
  & .MuiInputBase-input {
    height: 12px;
  }
  & .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 8px) scale(1);
  }
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
    padding-right: 35px;
  }
`;

const TextFieldStyled = styled(TextField)`
  width: 100%;
`;
