import React, { useContext, useEffect, useState } from 'react';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel, { ICATData } from '../../../models/CATModel';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import CATPanel from './CATPanel';

export type IProps = {};

const CATDiffViewer: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;
  const [row, setRow] = useState<ICATData | null>(null);

  useEffect(() => {
    const activeRow = module.getActive();
    setRow(activeRow);
  }, [module, module.activeId]);

  const renderDiff = () => {
    if (row === null || row.fromMemoryText === '' || row.fromMemoryText === row.fromText) {
      return null;
    } else {
      return (
        <ReactDiffViewer
          oldValue={row.fromMemoryText}
          newValue={row.fromText}
          showDiffOnly
          compareMethod={DiffMethod.WORDS}
          splitView={false}
          hideLineNumbers
          styles={{
            wordDiff: {
              display: 'inline',
            },
            diffRemoved: {
              display: 'none',
            },
            diffAdded: {
              display: 'none',
            },
            content: {
              display: 'block!important',
              fontSize: 12,
            },
            diffContainer: {
              overflow: 'hidden',
            },
          }}
        />
      );
    }
  };

  return <CATPanel title={'Diff'}>{renderDiff()}</CATPanel>;
};

export default CATDiffViewer;
